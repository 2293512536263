import React, { Component } from 'react';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import { withTranslation } from 'react-i18next';
import {
  ContentLoader,
  Modal,
  Notification,
} from 'shared';

class FactorForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      factor: {
        name: '',
      },
      isLoading: false,
    };

    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    const {
      factorId,
    } = this.props;

    if (factorId > 0) {
      this.getFactor();
    }
  }

  getFactor = () => {
    const {
      factorId,
      companyId,
    } = this.props;

    this.setState({ isLoading: true });
    api
      .get(`/api/v1/reports/factors/${factorId}/?company=${companyId}`)
      .then(({ data }) => {
        this.setState({
          isLoading: false,
          factor: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSave() {
    const { factor } = this.state;
    const {
      factorId,
      companyId,
      t,
    } = this.props;

    factor.company = companyId;

    if (factorId > 0) {
      api.patch(`/api/v1/reports/factors/${factorId}/?company=${companyId}`, factor)
        .then(() => {
          this.handleClose(true);
          return Notification('success', t('page_content.automatic_reports.factors.success_update'));
        })
        .catch(() => {
          return Notification('error', 'An error occurred', t('page_content.automatic_reports.request_error'));
        });
      // eslint-disable-next-line
    } else {
      api.post('/api/v1/reports/factors/', factor)
        .then(() => {
          this.handleClose(true);
          return Notification('success', t('page_content.automatic_reports.factors.success_create'));
        })
        .catch(() => {
          return Notification('error', 'An error occurred', t('page_content.automatic_reports.request_error'));
        });
    }
  }

  updateField = (field, value) => {
    const { factor } = this.state;
    factor[field] = value;
    this.setState({ factor });
  }

  handleClickOutside = (e) => {
    if (this.node.contains(e.target) || e.target.classList.contains('rt-td')) {
      return;
    }
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    this.props.handleClose(false);
  }

  handleClose = (shouldReload) => {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    this.props.handleClose(shouldReload);
  }

  render() {
    const {
      isLoading,
      factor,
    } = this.state;

    const {
      factorId,
      t,
    } = this.props;

    const modalTitle = (factorId > 0)
      ? t('page_content.automatic_reports.factors.edit')
      : t('page_content.automatic_reports.factors.create');

    return (
      // eslint-disable-next-line no-return-assign
      <div ref={(node) => (this.node = node)}>
        <Modal
          handleClose={this.props.handleClose}
          handleSave={this.handleSave}
          saveText={modalTitle}
          isOpen
          style={{ content: { padding: '0', top: '20px', left: '0', right: '0', bottom: 'initial' } }}
          title={modalTitle}
        >
          {
            isLoading &&
            <ContentLoader />
          }
          {!isLoading &&
            <div className="default-form">
              <table>
                <tbody>
                  <tr>
                    <td className="label">{t('page_content.automatic_reports.name')}</td>
                    <td className="input"><input
                      id="name"
                      onChange={(e) => this.updateField('name', e.target.value)}
                      type="text"
                      value={factor.name}
                    /></td>
                  </tr>
                </tbody>
              </table>
            </div>}
        </Modal>
      </div>
    );
  }
}

FactorForm.propTypes = {
  companyId: PropTypes.number.isRequired,
  factorId: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(FactorForm);
