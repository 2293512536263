import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconTime } from 'shared/Icons';
import { refreshRates } from './constants';
import './styles.scss';

class IntervalPicker extends Component {
  constructor() {
    super();
    this.state = {
      showMenu: false,
    };
  }

  cleanComponentState = () => {
    this.setState({ showMenu: false });
    document.removeEventListener('mousedown', this.handleClose, false);
  }

  handleIntervalChange = (interval) => {
    const { onSetInterval } = this.props;
    onSetInterval({ interval });
    this.cleanComponentState();
  }

  handleClose = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.cleanComponentState();
  }

  showMenu = () => {
    this.setState({
      showMenu: true,
    }, () => {
      document.addEventListener('mousedown', this.handleClose, false);
    });
  }

  render() {
    const { showMenu } = this.state;
    const { interval } = this.props;

    return (
      // eslint-disable-next-line no-return-assign
      <div className={`IntervalPicker ${showMenu ? 'IntervalPicker-active' : ''}`} ref={(node) => (this.node = node)}>
        <div className="IntervalPicker-label" onClick={this.showMenu}>
          <IconTime
            height="16px"
            width="16px"
          />
          <span>
            {interval} s
          </span>
        </div>
        {
          showMenu && (
            <div className="IntervalPicker-menu">
              {
                refreshRates.map((rate) => (
                  <button
                    className={this.props.interval === rate ? 'selected' : ''}
                    onClick={() => this.handleIntervalChange(rate)}
                  >
                    {rate} seconds
                  </button>
                ))
              }
            </div>
          )
        }
      </div>
    );
  }
}

IntervalPicker.defaultProps = {
  interval: 60,
};

IntervalPicker.propTypes = {
  interval: PropTypes.number,
  onSetInterval: PropTypes.func.isRequired,
};

export default IntervalPicker;
