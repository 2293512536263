import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isArray, get } from 'lodash';
import { Gauge } from 'react-advanced-gauge';
import Tooltip from 'rc-tooltip';
import { IconWarning } from '../../../Icons';
import { colors } from '../../../colors';
import WidgetPlaceholder from '../../WidgetPlaceholder';
import './styles.scss';

const defaultColors = [colors.red, colors.yellow, colors.green];
class OurGauge extends Component {
  constructor(props) {
    super(props);
    this.widgetRef = React.createRef();
  }

  componentDidUpdate() {
    const { height } = this.props;
    // const gauge = document.querySelectorAll('.gauge svg > g > text');
    const gaugeElement = this.widgetRef.current;
    if (gaugeElement !== null) {
      const gauge = gaugeElement.querySelectorAll('svg > g > text');
      if (gauge && gauge.length) {
        const text = gauge[0].textContent;

        // this is for unit text
        if (gauge[1]) {
          if (height > 0 && height < 200) {
            gauge[1].style.fontSize = '15px';
          }
          if (height > 200 && height < 350) {
            gauge[1].style.fontSize = '18px';
          }
          if (height > 350 && height < 450) {
            gauge[1].style.fontSize = '20px';
          }
          if (height > 450) {
            gauge[1].style.fontSize = '24px';
          }
        }

        if (text.length > 8) {
          gauge[0].style.fontSize = '12px';
        } else if (text.length > 5) {
          gauge[0].style.fontSize = '16px';
        } else {
          gauge[0].style.fontSize = '26px';
        }
      }
    }
  }

  render() {
    const {
      // config,
      data,
      height,
      settings,
      widgetData,
      width,
    } = this.props;

    if (!get(widgetData, 'length')) {
      return <WidgetPlaceholder height={height} type="gauge" />;
    }

    const unit = settings && settings.unit_text ? settings.unit_text : '';
    let value = data ? typeof data.value !== 'undefined' ? data.value : 'N/A' : '-';
    const wColors = get(settings, 'customColors') ? get(settings, 'colors') : [];
    const rawThresholds = get(settings, 'threshold', '');

    const thresholds = rawThresholds ? rawThresholds.split(',') : [];

    if (thresholds.length === 0) {
      wColors.push(colors.green);
    } else if (thresholds.length + 1 > wColors.length) {
      while (thresholds.length + 1 > wColors.length) {
        wColors.push(defaultColors[Math.min(wColors.length, 2)]);
      }
    } else if (thresholds.length + 1 < wColors.length) {
      wColors.splice(1 + thresholds.length);
    }

    let showLimitIcon = false;

    if (settings) {
      if (value && value !== 'undefined' && value !== 'N/A' && value !== '-') {
        if (settings.format === 'float') {
          const decimalPlaces = settings.decimal_places ? settings.decimal_places : 2;
          if (decimalPlaces && value) {
            value = parseFloat(value).toFixed(decimalPlaces);
          }
        } else if (settings.format === 'integer') {
          value = Math.round(value);
        } else if (typeof value === 'number') {
          const valueSegments = value.toString().split('.');
          value = (valueSegments.length === 2) ? `${valueSegments[0]}.${valueSegments[1].slice(0, 2)}` : `${valueSegments[0]}`;
        }
        if (settings.limitToMinMax !== 'undefined' && settings.limitToMinMax === true) {
          value = (settings && settings.format === 'float')
            ? Math.min(Math.max(value, settings.min), settings.max).toFixed(2)
            : Math.min(Math.max(value, settings.min), settings.max);
          showLimitIcon = true;
        }
      }
    }

    // String(number).match(/\d/g).length

    return (
      <Fragment>
        <div className="gauge" ref={this.widgetRef} style={{ backgroundColor: settings && settings.appearance && settings.appearance.backgroundColor ? settings.appearance.backgroundColor : 'white' }}>
          {showLimitIcon === true && <span className="gauge__limit-icon">
            <Tooltip
              id="tooltip-limit-gauge"
              trigger={['hover']}
              placement="bottom"
              overlay={<span>Please check shift times for more accurate results.</span>}
              overlayClassName="gauge__tooltip"
            >
              <span aria-describedby="tooltip-limit-gauge">
                <IconWarning color={colors.red} height="16px" width="16px" />
              </span>
            </Tooltip>
          </span>}
          <Gauge
            colors={wColors}
            height={height - 20}
            min={Number(settings.min)}
            max={Number(settings.max)}
            thresholds={isArray(thresholds) ? thresholds.map((t) => Number(t)) : []}
            unit={unit}
            showDomain
            showUnit={get(settings, 'showUnit')}
            value={value}
            valueMatchColor
            width={width}
          />
        </div>
        <div
          className="gauge-label"
          style={{ backgroundColor: settings && settings.appearance && settings.appearance.backgroundColor ? settings.appearance.backgroundColor : 'white' }}
        >
          {
            get(widgetData, '[0].label')
          }
        </div>
      </Fragment>
    );
  }
}

OurGauge.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  height: PropTypes.any,
  settings: PropTypes.object,
  widgetData: PropTypes.array,
  width: PropTypes.any,
};

export default OurGauge;
