import React from 'react';
import Base from './Base';

export default (props) => (<Base viewBox="0 0 50 50" {...props}>
  <circle cx="8.24" cy="13.01" r="3.58" />
  <rect x="15.99" y="10.51" width="28.03" height="5" />
  <circle cx="8.43" cy="25.01" r="3.58" />
  <rect x="16.2" y="22.51" width="28.03" height="5" />
  <circle cx="8.43" cy="36.97" r="3.58" />
  <rect x="16.2" y="34.47" width="28.03" height="5" />
</Base>);
