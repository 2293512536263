import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { get } from 'lodash';

import { Table, Button, Notification } from 'shared';
import { ordersCreatedAndUpdated } from 'shared/constants';
import './style.scss';
import { getProductionLines, launchLine } from '../../actions';
import LaunchModal from './components/LaunchModal';

const LinePlans = ({
  t,
  locationId,
  getOrderId,
  isReadOnly,
  orderLinePlans,
  getLocationName,
  companyShortCode,
  getOrderLinePlans,
  isLoadingLinePlans,
}) => {
  const [showLaunchModal, setShowLaunchModal] = useState(false);
  const [lines, setLines] = useState([]);

  const fetchLines = async () => {
    if (companyShortCode && locationId) {
      const resp = await getProductionLines(companyShortCode, locationId);
      setLines(get(resp, 'data.results', []));
    }
  };

  useEffect(() => {
    fetchLines();
  }, [companyShortCode, locationId]);

  const handleLaunchSave = async (line, date) => {
    const orderId = getOrderId();
    try {
      await launchLine(line.id, date, orderId);
      setShowLaunchModal(false);
      getOrderLinePlans();
      Notification('success', t('page_content.orders.order_details.line_plans_tab.plan_launched_successfully'));
    } catch (error) {
      Notification('error', t('page_content.orders.order_details.line_plans_tab.error_launching_plan'));
    }
  };

  return (
    <div>
      <div className="launch_button">
        <Button type="success" disabled={isReadOnly} onClick={() => setShowLaunchModal(true)}>{t('page_content.orders.order_details.line_plans_tab.launch_modal_title')}</Button>
      </div>
      <Table
        style={{ userSelect: 'text' }}
        data={orderLinePlans || []}
        minRows={0}
        showPagination={false}
        defaultPageSize={50}
        nextText={t('pagination_table.next')}
        previousText={t('pagination_table.previous')}
        loading={isLoadingLinePlans}
        sortable
        manual={false}
        defaultSorted={[{ id: 'planned_start', desc: true }]}
        columns={[
          {
            Header: () => <span>{t('page_content.orders.order_details.line_plans_tab.table_column_name')}</span>,
            accessor: 'line.name',
            Cell: (row) => (row.value ? row.value : '-'),
          },
          {
            Header: () => <span>{t('page_content.orders.order_details.line_plans_tab.table_column_code')}</span>,
            accessor: 'line.code',
            Cell: (row) => (row.value ? row.value : '-'),
          },
          {
            Header: () => <span>{t('page_content.orders.order_details.line_plans_tab.table_column_locationId')}</span>,
            accessor: 'line.location',
            Cell: (row) => (row.value ? getLocationName(row.value) : '-'),
          },
          {
            Header: () => <span>{t('page_content.orders.order_details.line_plans_tab.table_column_note')}</span>,
            accessor: 'note',
            Cell: (row) => (row.value ? row.value : '-'),
          },
          {
            Header: () => <span>{t('page_content.orders.order_details.line_plans_tab.table_column_planned_start')}</span>,
            accessor: 'planned_start',
            Cell: (row) => (row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
          },
          {
            Header: () => <span>{t('page_content.orders.order_details.line_plans_tab.table_column_planned_end')}</span>,
            accessor: 'planned_end',
            Cell: (row) => (row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
          },
          {
            Header: () => <span>{t('page_content.orders.order_details.line_plans_tab.table_column_actual_start')}</span>,
            accessor: 'actual_start',
            Cell: (row) => (row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
          },
          {
            Header: () => <span>{t('page_content.orders.order_details.line_plans_tab.table_column_actual_end')}</span>,
            accessor: 'actual_end',
            Cell: (row) => (row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
          },
        ]}
      />
      <LaunchModal t={t} showLaunchModal={showLaunchModal} handleClose={() => setShowLaunchModal(false)} lines={lines} handleSave={handleLaunchSave} />
    </div>
  );
};

LinePlans.propTypes = {
  getOrderId: PropTypes.func,
  locationId: PropTypes.number,
  t: PropTypes.func.isRequired,
  orderLinePlans: PropTypes.array,
  getOrderLinePlans: PropTypes.func,
  companyShortCode: PropTypes.string,
  isReadOnly: PropTypes.bool.isRequired,
  getLocationName: PropTypes.func.isRequired,
  isLoadingLinePlans: PropTypes.bool.isRequired,
};

export default withTranslation()(LinePlans);
