import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const parseUri = () => {
  return window.location.pathname.replaceAll('/', '-').substring(1);
};

const Page = ({ children, classes }) => (
  <div className={`page ${classes.join(' ')}`} data-uri={parseUri()}>
    {children}
  </div>
);

Page.propTypes = {
  classes: PropTypes.array,
  children: PropTypes.any,
};

Page.defaultProps = {
  classes: [],
};

export default Page;
