import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, Modal, Button } from 'shared';
import { ReactTableDefaults } from 'react-table';
import { IconSearch } from 'shared/Icons';
import { get } from 'lodash';
import Select from 'react-select';
import { selectStyles } from 'styles/modules/reactSelect';
import { withTranslation } from 'react-i18next';
import { defaultDateTimeFormat, modalSizes } from 'shared/constants';
import { checkAccessOnPage, redirectToHomePage, checkModules } from 'industry/helpers';
import { getProjectParts, saveOrder, importParts, verifyUpload, getProductBoms, getPartLots, getPaginatedProjectParts, getPartCategoryGroups } from './../actions';
import FileUploadModal from './FileUploadModal';
import ProjectPartsModal from './ProjectPartsModal';
import TableButtons from '../../../../shared/TableButtons/index';

class ProjectPartsTab extends Component {
  constructor() {
    super();
    this.fileInputRef = React.createRef();
    this.timerRef = React.createRef();
    this.state = {
      parts: [],
      partsOriginal: [],
      selectedParts: [],
      filters: {},
      modalIsOpen: false,
      isLoadingParts: true,
      bomTableModal: false,
      importModal: false,
      uploadLoader: false,
      uploadResponse: undefined,
      isLoadingBom: false,
      newImportData: [],
      importDataId: null,
      partNameQuery: '',
      productNameQuery: '',
      lotFilter: [],
      bomData: [],
      lotData: [],
      categoryGroups: [],
      facadeFilters: [],
      orderGroupFilters: [],
      fatherBreadcrumb: '',
    };
  }

  componentDidMount() {
    const {
      companyId,
      locationId,
    } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Projects');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    this.fetchParts();
    this.fetchPartLots();
    this.fetchPartCategoryGroups();
  }

  fetchParts = () => {
    const {
      projectId,
      companyId,
      locationId,
    } = this.props;

    const {
      partsOriginal,
    } = this.state;

    this.setState({
      isLoadingParts: true,
    });

    getProjectParts(locationId, companyId, projectId)
      .then((res) => {
        this.setState({
          isLoadingParts: false,
          parts: get(res, 'data.results'),
          count: get(res, 'data.count'),
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
        });
        if (partsOriginal.length === 0) {
          this.setState({ partsOriginal: get(res, 'data.results') });
        }
      });
  }

  fetchPaginatedProjectParts = (url) => {
    this.setState({
      isLoadingParts: true,
    });
    getPaginatedProjectParts(url)
      .then((re) => {
        this.setState({
          parts: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingParts: false,
        });
      })
      .catch((e) => console.error('Error while fetching orders', e));
  }

  selectPart = (e) => {
    e.stopPropagation();
    const { selectedParts } = this.state;
    let selectedPartsNew = selectedParts;
    const partId = parseInt(e.target.value, 10);
    if (e.target.checked === true) {
      if (selectedPartsNew.includes(partId) === false) {
        selectedPartsNew.push(partId);
      }
    } else {
      selectedPartsNew = selectedPartsNew.filter((x) => parseInt(x, 10) !== partId);
    }
    this.setState({ selectedParts: selectedPartsNew });
  }

  deselectPart = (partId) => {
    const { selectedParts } = this.state;
    let selectedPartsNew = selectedParts;
    selectedPartsNew = selectedPartsNew.filter((x) => parseInt(x, 10) !== partId);
    this.setState({
      selectedParts: selectedPartsNew,
    });
    if (document.querySelector(`input[value="${partId}"]`) !== undefined) {
      document.querySelector(`input[value="${partId}"]`).checked = false;
    }
  }

  resetSelectionParts = () => {
    [...document.querySelectorAll('input[type="checkbox"]')].forEach((elem) => { elem.checked = false; });
    this.setState({ selectedParts: [] });
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  }

  openBomModal = (productType) => {
    this.setState({ bomTableModal: true, isLoadingBom: true });
    this.fetchBomInfo(productType);
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  closeBomModal = () => {
    this.setState({ bomTableModal: false });
  }

  openImportModal = () => {
    this.setState({ importModal: true });
  }

  closeImportModal = () => {
    this.setState({ importModal: false, newImportData: [] }, () => {
      this.fetchParts();
      this.fetchPartLots();
      this.fetchPartCategoryGroups();
    });
  }

  createOrder = () => {
    const {
      projectId,
      companyId,
    } = this.props;

    const {
      selectedParts,
    } = this.state;

    const data = {
      order: {
        project: projectId,
        company: companyId,
      },
      parts: selectedParts,
    };

    saveOrder(data)
      .then(() => {
        this.resetSelectionParts();
        this.closeModal();
        this.fetchParts();
        this.fetchPartLots();
        this.fetchPartCategoryGroups();
      });
  }

  handleUploadWindow = () => {
    this.fileInputRef.current.click();
  }

  handleFileUpload = (files) => {
    const { projectId } = this.props;
    if (files.length === 0) {
      return;
    }
    this.setState({
      uploadLoader: true,
    });
    const file = files[0];
    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel') {
      importParts(file, projectId, 'parts')
        .then((re) => {
          if (re.status >= 200 && re.status < 300) {
            verifyUpload(re.data.id)
              .then((verify) => {
                if (verify.status >= 200 && verify.status < 300) {
                  this.traverseThroughVerifiedData(verify.data.items);
                  this.setState({
                    uploadResponse: verify.data,
                    importDataId: re.data.id,
                  });
                } else {
                  this.setState({
                    uploadLoader: false,
                  });
                }
              });
          } else {
            this.setState({
              uploadLoader: false,
            });
          }
        });
    }
  }

  traverseThroughVerifiedData = (items) => {
    const newArray = [];
    if (items && items.length > 0) {
      for (const key in items) {
        if (items[key].name !== null && items[key].external_id !== null) {
          newArray.push(items[key]);
        }
      }
    }
    this.setState({
      newImportData: newArray,
      uploadLoader: false,
    });
  }

  clearUploadStatus = () => {
    this.setState({
      uploadResponse: [],
    });
  }

  fetchBomInfo = (productType) => {
    const { locationId, companyId } = this.props;
    getProductBoms(companyId, locationId, null, productType)
      .then((res) => {
        this.setState({
          bomData: get(res, 'data.results'),
          isLoadingBom: false,
        });
      });
  }

  fetchPartLots = () => {
    const { projectId, locationId, companyId } = this.props;
    getPartLots(locationId, companyId, projectId)
      .then((res) => {
        this.setState({
          lotData: get(res, 'data.lots'),
        });
      });
  }

  fetchPartCategoryGroups = () => {
    const { companyId } = this.props;
    getPartCategoryGroups(companyId)
      .then((res) => {
        const categoryPool = get(res, 'data.results', []);
        const categoryGroupsArray = categoryPool.map((item) => ({
          name: item.name,
          categoryNames: item.project_part_categories.map((category) => ({
            id: category.id,
            name: category.name,
          })),
        }));

        this.setState({
          categoryGroups: categoryGroupsArray,
        });
      });
  }

  handleLotFilterChange = (value) => {
    this.setState({
      lotFilter: value,
    }, () => {
      this.handleStateQueryChange();
    });
  }

  handleFacadeChange = (value) => {
    this.setState({
      facadeFilters: value,
    }, () => {
      this.handleStateQueryChange();
    });
  }

  handleOrderGroupChange = (value) => {
    this.setState({
      orderGroupFilters: value,
    }, () => {
      this.handleStateQueryChange();
    });
  }

  handleQueryChange = (key, e) => {
    const value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleQueryClear = (key) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: '',
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleStateQueryChange = () => {
    const { partNameQuery, productNameQuery, lotFilter, facadeFilters, orderGroupFilters } = this.state;
    const { companyId, projectId, locationId } = this.props;
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      if (partNameQuery.length < 1 || partNameQuery.length >= 2 || productNameQuery.length < 1 || productNameQuery.length >= 2 || lotFilter.length > 0 || facadeFilters.length > 0 || orderGroupFilters.length > 0) {
        this.setState({
          isLoadingParts: true,
        });
        getProjectParts(locationId, companyId, projectId, partNameQuery, productNameQuery, lotFilter, facadeFilters, orderGroupFilters)
          .then((res) => {
            this.setState({
              isLoadingParts: false,
              parts: get(res, 'data.results'),
              count: get(res, 'data.count'),
              next: get(res, 'data.next'),
              previous: get(res, 'data.previous'),
            });
          });
      }
    }, 600);
  }

  findObjectByCategoryGroupName = (arr, targetCategoryGroupName) => {
    for (const obj of arr) {
      if (obj.category_group_name === targetCategoryGroupName) {
        return obj.name;
      }
    }
    return '-';
  }

  findObjectByFacadeType = (arr, targetCategoryGroupName) => {
    for (const obj of arr) {
      if (obj.name === targetCategoryGroupName) {
        return obj.categoryNames.sort((a, b) => a.name.localeCompare(b.name));
      }
    }
    return [];
  }

  render() {
    const {
      t,
      companyId,
      locationId,
      projectId,
      isReadOnly,
    } = this.props;
    const {
      parts,
      partsOriginal,
      selectedParts,
      modalIsOpen,
      isLoadingParts,
      next,
      previous,
      bomTableModal,
      importModal,
      newImportData,
      uploadLoader,
      bomData,
      isLoadingBom,
      importDataId,
      partNameQuery,
      productNameQuery,
      lotData,
      uploadResponse,
      categoryGroups,
      fatherBreadcrumb,
      count,
    } = this.state;

    const selectedPartsObj = partsOriginal.filter((x) => selectedParts.includes(parseInt(x.id, 10)));

    const dynamicColumns = categoryGroups.map((column) => ({
      Header: () => <span>{t(`page_content.projects.table_column_${column.name}`)}</span>,
      accessor: 'part_categories',
      Cell: (row) => (
        Array.isArray(row.value)
          ? this.findObjectByCategoryGroupName(row.value, column.name)
          : '-'
      ),
    }));

    const infoColumns = [
      {
        width: 40,
        Cell: (row) => (
          <span>
            <input
              type="checkbox"
              onChange={this.selectPart}
              value={row.original.id}
              checked={(selectedParts !== null && selectedParts.length > 0 && selectedParts.includes(row.original.id))}
              disabled={(row.original.boms <= 0) || (row.original.order_item !== null && row.original.order_item !== undefined) || (selectedPartsObj && selectedPartsObj.length > 0 && selectedPartsObj[0].lot !== row.original.lot)}
            />
          </span>
        ),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_part')}</span>,
        accessor: 'name',
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_product')}</span>,
        accessor: 'product_type_name',
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_order')}</span>,
        // accessor: 'order',
        Cell: (row) => ((row.original.order_item !== null && row.original.order_item !== undefined)
          ? <span
              style={{ textDecoration: 'underline', color: 'blue' }}
              onClick={() => {
                window.open(`/${companyId}/industry/location/${locationId}/orders/${row.original.order_item.order.id}`);
              }}
          >{row.original.order_item.order.external_id}</span>
          : '-'
        ),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_created')}</span>,
        accessor: 'created_at',
        Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_updated')}</span>,
        accessor: 'updated_at',
        Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_status')}</span>,
        accessor: 'status',
        width: 110,
        Cell: (row) => (
          row.value ?
            <div className="status_wrapper">
              <span className={`order-list__status ${row.value}`}>{t([`page_content.orders.statuses.${row.value}`])}</span>
            </div> : ''
        ),
      },
      {
        Header: () => <span>BOM</span>,
        accessor: 'product_type',
        width: 110,
        Cell: (row) => (<button
          className={`${row.original.boms > 0 ? 'bomStatus_button' : 'bomStatus_missing'}`}
          onClick={() => {
            if (row.original.boms > 0) {
              this.openBomModal(row.value);
              this.setState({
                fatherBreadcrumb: parts[row.index].product_type_name,
              });
            }
          }}
        >{row.original.boms > 0 ? t('page_content.projects.show_details_button') : 'BOM Missing'}</button>),
      },
    ];

    const specsColumns = [
      {
        Header: () => <span>{t('page_content.projects.table_column_lot')}</span>,
        accessor: 'lot',
        width: 70,
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_group')}</span>,
        accessor: 'metadata',
        width: 70,
        Cell: (row) => (Object.prototype.hasOwnProperty.call(row.value, 'Group')
          ? <span>{row.value.Group}</span>
          : '-'
        ),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_type')}</span>,
        accessor: 'metadata',
        width: 70,
        Cell: (row) => (Object.prototype.hasOwnProperty.call(row.value, 'Type')
          ? <span>{row.value.Type}</span>
          : '-'
        ),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_width')}</span>,
        accessor: 'metadata',
        width: 80,
        Cell: (row) => (row.value.Width ? row.value.Width : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_height')}</span>,
        accessor: 'metadata',
        width: 80,
        Cell: (row) => (row.value.Height ? row.value.Height : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_level')}</span>,
        accessor: 'metadata',
        width: 70,
        Cell: (row) => (Object.prototype.hasOwnProperty.call(row.value, 'Level')
          ? <span>{row.value.Level}</span>
          : '-'
        ),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_column')}</span>,
        accessor: 'metadata',
        width: 80,
        Cell: (row) => (Object.prototype.hasOwnProperty.call(row.value, 'Column')
          ? <span>{row.value.Column}</span>
          : '-'
        ),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_elevation')}</span>,
        accessor: 'metadata',
        width: 90,
        Cell: (row) => (Object.prototype.hasOwnProperty.call(row.value, 'Elevation')
          ? <span>{row.value.Elevation}</span>
          : '-'
        ),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_hoist')}</span>,
        accessor: 'metadata',
        width: 70,
        Cell: (row) => (Object.prototype.hasOwnProperty.call(row.value, 'Hoist')
          ? <span>{row.value.Hoist}</span>
          : '-'
        ),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_tie')}</span>,
        accessor: 'metadata',
        width: 80,
        Cell: (row) => (Object.prototype.hasOwnProperty.call(row.value, 'Tie')
          ? <span>{row.value.Tie}</span>
          : '-'
        ),
      },
      {
        Header: () => <span>{t('page_content.projects.table_column_order_procedure')}</span>,
        accessor: 'metadata',
        Cell: (row) => (Object.prototype.hasOwnProperty.call(row.value, 'OrderProcedure')
          ? <span>{row.value.OrderProcedure}</span>
          : '-'
        ),
      },
    ];

    const combinedColumns = [...infoColumns, ...dynamicColumns, ...specsColumns];

    const columnDefaults = { ...ReactTableDefaults.column, headerClassName: 'project_parts_table_header', className: 'project_parts_table_header' };

    return (
      <div className="project-parts">
        <div className="project-parts_toolbar">
          <div className="project-parts_toolbar_input_container">
            <input onChange={(e) => this.handleQueryChange('partNameQuery', e)} placeholder={t('page_content.projects.filter_byName')} value={partNameQuery} />
            {
              partNameQuery &&
              <button
                className="project_parts_clear_button"
                onClick={() => {
                  this.handleQueryClear('partNameQuery');
                }}
              >&times;</button>
            }
            <div className="project-parts_toolbar_icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <div className="project-parts_toolbar_input_container">
            <input onChange={(e) => this.handleQueryChange('productNameQuery', e)} placeholder={t('page_content.projects.filter_byProductName')} value={productNameQuery} />
            {
              productNameQuery &&
              <button
                className="project_parts_clear_button"
                onClick={() => {
                  this.handleQueryClear('productNameQuery');
                }}
              >&times;</button>
            }
            <div className="project-parts_toolbar_icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <Select
            styles={selectStyles}
            components={{
              IndicatorSeparator: () => null,
            }}
            isClearable
            isMulti
            menuPosition="fixed"
            options={lotData.sort((a, b) => a.localeCompare(b))}
            getOptionLabel={(option) => option}
            getOptionValue={(option) => option}
            onChange={(value) => this.handleLotFilterChange(value)}
            placeholder={t('page_content.projects.filter_lotValue')}
          />
          <Select
            styles={selectStyles}
            components={{
              IndicatorSeparator: () => null,
            }}
            isClearable
            isMulti
            menuPosition="fixed"
            options={this.findObjectByFacadeType(categoryGroups, 'FacadeType')}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={(facade) => this.handleFacadeChange(facade)}
            placeholder={t('page_content.projects.filter_facade')}
          />
          <Select
            styles={selectStyles}
            components={{
              IndicatorSeparator: () => null,
            }}
            isClearable
            isMulti
            menuPosition="fixed"
            options={this.findObjectByFacadeType(categoryGroups, 'OrderGroup')}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={(orderGroup) => this.handleOrderGroupChange(orderGroup)}
            placeholder={t('page_content.projects.filter_orderGroup')}
          />
          <div className="project-parts__filterArea">
            <Button
              disabled={(selectedParts.length <= 0 && isReadOnly) || isReadOnly}
              type="add"
              onClick={this.openModal}
            >
              Create orders
            </Button>

            <Button
              disabled={isReadOnly}
              onClick={this.openImportModal}
            >
              {t('page_content.projects.import_button')}
            </Button>
          </div>
        </div>
        <input
          type="file"
          ref={this.fileInputRef}
          style={{ display: 'none' }}
          accept=".xlsx, .xls"
          onChange={(e) => {
            this.handleFileUpload(e.target.files);
            e.target.value = '';
          }}
        />
        <Table
          style={{ userSelect: 'text' }}
          columns={combinedColumns}
          data={parts}
          minRows={1}
          defaultPageSize={30}
          noDataText=" "
          trClassByProp="order_item"
          showPagination={false}
          sortable={false}
          loading={isLoadingParts}
          column={columnDefaults}
          stickyHeader
        />

        <div>
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchPaginatedProjectParts} count={count} />
        </div>

        <Modal
          isOpen={modalIsOpen}
          handleClose={this.closeModal}
          handleSave={this.createOrder}
          className="project-parts-modal"
          size={modalSizes.large}
          title="Selected Parts"
        >
          <div className="project-parts-modal__content">
            <h3>Are you sure you want to create an order for these parts?</h3>
            <div className="project-parts-modal__list">
              {selectedPartsObj.map((part, partIdx) => {
                return (<div key={`part_${partIdx}`} className="project-parts-modal__list-item">
                  {part.name}
                  {selectedParts.length > 1 &&
                    <button
                      className="btn normal"
                      onClick={() => { this.deselectPart(part.id); }}
                    />}
                </div>);
              })}
            </div>
          </div>
        </Modal>
        <ProjectPartsModal
          modalStatus={bomTableModal}
          closeModal={this.closeBomModal}
          data={bomData}
          isLoading={isLoadingBom}
          companyId={companyId}
          locationId={locationId}
          projectId={projectId}
          fatherBreadcrumb={fatherBreadcrumb}

        />
        <FileUploadModal
          modalStatus={importModal}
          closeImportModal={this.closeImportModal}
          handleUploadWindow={this.handleUploadWindow}
          uploadResponse={newImportData}
          uploadFileType={uploadResponse && uploadResponse.file_type ? uploadResponse.file_type : ''}
          uploadStatus={uploadResponse}
          uploadLoader={uploadLoader}
          uploadDataId={importDataId}
          projectId={projectId}
          clearUploadStatus={this.clearUploadStatus}
        />
      </div>
    );
  }
}

ProjectPartsTab.propTypes = {
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  projectId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

export default (withTranslation()(ProjectPartsTab));
