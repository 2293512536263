import api from 'helpers/api';
import { Notification } from 'shared';

export const fetchOperatorData = (assetId) => api.get(`/api/v1/dashboards/asset/?asset_id=${assetId}`);
export const fetchAsset = (assetId) => api.get(`/api/v1/dashboards/asset/?asset_id=${assetId}`);
export const setIsDefect = (data) => {
  return api.post('/api/v1/production_data_resolve/', data);
};

export const fetchAssetItems = async (assetId) => {
  try {
    const res = await api
      .get(`/api/v1/dashboards/asset-items/?asset_id=${assetId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching asset items', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const fetchItemDetails = async (assetId, ts) => {
  try {
    const res = await api
      .get(`/api/v1/dashboards/asset-data/?asset_id=${assetId}&ts=${ts}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching item details', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteEvents = async (metricId, ts) => {
  try {
    const res = await api
      .delete(`/api/v1/dashboards/operator-delete-item/?metric_id=${metricId}&ts=${ts}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while removing event', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
