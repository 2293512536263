import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import Select from 'react-select';
import api from 'helpers/api';
import { pickerStyles } from '../colors';

class OperatorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOperator: props.operator,
      options: [],
    };
  }

  componentDidMount() {
    const { companyId } = this.props;
    api.get(`/api/v1/company_members/?company=${companyId}`)
      .then((json) => {
        this.setState({
          options: get(json, 'data.results'),
        });
      });
  }

  componentDidUpdate(prevProps) {
    const { operator } = this.props;
    if (prevProps.operator !== operator) {
      // eslint-disable-next-line
      this.setState({ selectedOperator: operator });
    }
  }

  handleChange = (operator) => {
    const { handleChange } = this.props;
    this.setState({ selectedOperator: operator });
    handleChange(operator);
  }

  render() {
    const { selectedOperator, options } = this.state;
    // eslint-disable-next-line no-unused-vars
    const { disabled, htmlId, onBlur, customClassName, t } = this.props;

    return (
      <Select
        cacheOptions
        isClearable
        isDisabled={disabled}
        id={htmlId}
        options={options}
        getOptionLabel={(option) => `${option.email}`}
        getOptionValue={(option) => option.id}
        onBlur={onBlur}
        onChange={this.handleChange}
        placeholder={t('shared.operator_picker.placeholder')}
        value={selectedOperator ? (options === undefined ? [] : options).find((option) => option.email === selectedOperator.email) : null}
        className="OperatorPicker"
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        styles={pickerStyles}
      />
    );
  }
}

OperatorPicker.propTypes = {
  operator: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  htmlId: PropTypes.string,
  onBlur: PropTypes.func,
  customClassName: PropTypes.string,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

OperatorPicker.defaultProps = {
  disabled: false,
  customClassName: '',
};

export default withTranslation()(OperatorPicker);
