import React from 'react';
import PropTypes from 'prop-types';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import 'brace/theme/monokai';
import './style.scss';

const JsonEditor = ({ value, onChange, theme, isError }) => {
  const themeSwitcher = (themeVariant) => {
    switch (themeVariant) {
      case 'light': return 'ace/theme/github';
      case 'dark': return 'ace/theme/monokai';
      default: return 'ace/theme/github';
    }
  };

  return (
    <Editor
      value={value}
      ace={ace}
      theme={themeSwitcher(theme)}
      mode="code"
      navigationBar={false}
      onChange={onChange}
      onValidationError={isError}
    />
  );
};

JsonEditor.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.string,
  isError: PropTypes.func,
};

JsonEditor.defaultProps = {
  value: {},
  theme: 'light',
  isError: (() => {}),
};

export default JsonEditor;
