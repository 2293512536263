import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import './style.scss';

import ChangePassword from './ChangePassword/index';
import TwoFactorAuth from './2FA/index';
import EventsAndSubscribersTabs from '../company/CompanySettings/components/EventsAndSubscribersTabs/index';

class UserProfile extends React.Component {
  render() {
    const { currentUser, t, companyConfig, companyId, locationId } = this.props;

    return (
      <div className="industry-tab user-profile fullscreen">
        <Tabs>

          <TabList>
            <Tab>{t('user_profile_page.reset_password_tab')}</Tab>
            <Tab>{t('user_profile_page.two_factor_tab')}</Tab>
            <Tab>{t('settings.tabs.events_and_subscribers')}</Tab>
          </TabList>

          <main>
            <TabPanel>
              <ChangePassword
                t={t}
                currentUser={currentUser}
                companyConfig={companyConfig}
              />
            </TabPanel>

            <TabPanel>
              <TwoFactorAuth
                currentUser={currentUser}
              />
            </TabPanel>

            <TabPanel>
              <EventsAndSubscribersTabs companyId={companyId} locationId={locationId} isUserProfile />
            </TabPanel>
          </main>

        </Tabs>
      </div>
    );
  }
}

UserProfile.propTypes = {
  t: PropTypes.func.isRequired,
  companyConfig: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state.app,
    currentUser: state.currentUser,
    companyConfig: state.app && state.app.companyConfig ? state.app.companyConfig : {},
  };
};

export default connect(mapStateToProps, null)(withTranslation()(UserProfile));
