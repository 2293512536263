import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import ReactDatePicker from 'react-datepicker';
import { getLocale } from 'shared/DatePicker/constants';
import { withTranslation } from 'react-i18next';
import { Modal, ConfirmationModal } from 'shared';
import './style.scss';

class ShiftTimeForm extends React.Component {
  constructor(props) {
    super();
    let date = moment().format('YYYY-MM-DD');
    let shift_begin = null;
    let shift_end = null;
    let shift = '';
    let day_of_week = 7;

    if (props.shiftTime) {
      const { shiftTime } = props;
      date = shiftTime.date;
      shift_begin = shiftTime.shift_begin;
      shift_end = shiftTime.shift_end;
      shift = shiftTime.shift;
      day_of_week = shiftTime.day_of_week;
    }

    this.state = {
      date,
      shift_begin,
      shift_end,
      shift,
      day_of_week,
      error_shift_begin: false,
      error_shift_end: false,
      showConfirmationDialog: false,
    };
  }

  handleShowConfirmationModal = () => {
    this.setState({ showConfirmationDialog: true });
  }

  handleDelete = () => {
    const { shiftTime, deleteShiftTime, locationId } = this.props;
    let id;
    if (shiftTime && shiftTime.id) {
      id = shiftTime.id;
    }
    deleteShiftTime(id, locationId);
  }

  handleSave = () => {
    const { date, shift_begin, shift_end, shift, day_of_week } = this.state;
    const { locationId, saveShiftTime, shiftTime } = this.props;

    if (!shift_begin || !shift_end || !shift) {
      if (!shift_begin || !shift_end || !shift) {
        this.setState({
          error_shift_begin: !shift_begin,
          error_shift_end: !shift_end,
          error_shift: !shift,
        });
        return;
      }
    }

    const shift_id = shift ? typeof shift === 'object' ? shift.id : shift : '';

    let id;
    let isEdit;
    if (shiftTime && shiftTime.id) {
      id = shiftTime.id;
      isEdit = true;
    }

    saveShiftTime(id, {
      shift: shift_id,
      date,
      shift_begin,
      shift_end,
      is_default_shift: true,
      day_of_week,
    }, locationId, isEdit);
    setTimeout(() => {
      this.setState({
        shift_begin: null,
        shift_end: null,
        shift: '',
        date: null,
      });
    }, 1000);
  }

  changeField = (key, value) => {
    const error_key = `error_${key}`;
    this.setState({
      [key]: value,
      [error_key]: false,
    });
  }

  render() {
    const { isOpen, closeModal, shiftTime, isLoadingState, shifts, t, daysOfWeek } = this.props;
    const { date, shift_begin, shift_end, shift, error_shift, error_shift_begin, error_shift_end, day_of_week } = this.state;

    const shiftStyle = {};
    if (error_shift) {
      shiftStyle.borderColor = 'red';
    }
    const endStyle = {
      width: '100%',
    };
    if (error_shift_end) {
      endStyle.borderColor = 'red';
    }
    const beginStyle = {
      width: '100%',
    };
    if (error_shift_begin) {
      beginStyle.borderColor = 'red';
    }

    const selectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '100%',
        minHeight: '34px',
        height: '36px',
        padding: 0,
        fontSize: '13px',
        color: '#555',
        marginRight: '5px',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0 10px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: 10,
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: '100%',
        borderRadius: 0,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        handleSave={() => { this.handleSave(); }}
        handleClose={closeModal}
        handleDelete={shiftTime ? this.handleShowConfirmationModal : null}
        title={shiftTime ? t('settings.shifts.shift_time_form.edit_shift_time') : t('settings.shifts.shift_time_form.add_shift_time')}
      >
        {
          isLoadingState &&
          <div
            className="reason-form-loader"
            style={{
              borderLeftColor: 'transparent',
            }}
          />
        }
        <div className="default-form">
          <table>
            <tbody>
              <tr>
                <td className="label">
                  {t('settings.shifts.shift_time_form.date')}
                </td>
                <td className="value">
                  <ReactDatePicker
                    className="shift-date-picker"
                    placeholderText=""
                    dateFormat="dd.MM.yyyy"
                    selected={date ? moment(date).toDate() : moment().format('YYYY-MM-DD')}
                    onChange={(e) => this.changeField('date', moment(e).format('YYYY-MM-DD'))}
                    showTimeSelect={false}
                    locale={getLocale(t)}
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('settings.shifts.shift_time_form.day_of_week')}
                </td>
                <td className="value">
                  <Select
                    className="shift-modal-select"
                    options={daysOfWeek}
                    getOptionLabel={(day) => day.name}
                    getOptionValue={(day) => day.value}
                    isSearchable
                    menuPosition="fixed"
                    onChange={(e) => this.changeField('day_of_week', e.value)}
                    value={daysOfWeek.find((day) => day.value === day_of_week) || null}
                    styles={selectStyles}
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('settings.shifts.shift_time_form.begin')}*
                </td>
                <td className="datePicker_tableWrapper">
                  <ReactDatePicker
                    selected={shift_begin ? moment(shift_begin, 'HH:mm').toDate() : null}
                    onChange={(time) => this.changeField('shift_begin', moment(time).format('HH:mm'))}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeCaption={t('date_picker_locale.time_translation')}
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('settings.shifts.shift_time_form.end')}*
                </td>
                <td className="datePicker_tableWrapper">
                  <ReactDatePicker
                    selected={shift_end ? moment(shift_end, 'HH:mm').toDate() : null}
                    onChange={(time) => this.changeField('shift_end', moment(time).format('HH:mm'))}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeCaption={t('date_picker_locale.time_translation')}
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('settings.shifts.shift_time_form.shift')}*
                </td>
                <td className="value">
                  <Select
                    className="shift-modal-select"
                    options={shifts}
                    getOptionLabel={(s) => s.name}
                    getOptionValue={(s) => s.id}
                    isSearchable
                    menuPosition="fixed"
                    onChange={(e) => this.changeField('shift', e)}
                    value={shift}
                    styles={selectStyles}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ConfirmationModal
          itemName={`${shiftTime?.shift?.name} (${shiftTime?.shift_begin}-${shiftTime?.shift_end})`}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDelete}
          type="warning"
        />
      </Modal>
    );
  }
}

ShiftTimeForm.propTypes = {
  shiftTime: PropTypes.object,
  shifts: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  saveShiftTime: PropTypes.func.isRequired,
  deleteShiftTime: PropTypes.func.isRequired,
  isLoadingState: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  daysOfWeek: PropTypes.array.isRequired,
};

ShiftTimeForm.defaultProps = {
  shifts: [],
  showAll: false,
};

export default withTranslation()(ShiftTimeForm);
