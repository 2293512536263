import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shared';
import { withTranslation } from 'react-i18next';
import './styles.scss';

const TableButtons = ({
  previous,
  next,
  fetchFunction,
  t,
  count,
  showCount,
}) => {
  const params = count && (next !== null || previous !== null) ? new URLSearchParams(next) : undefined;
  const offset = count && params && params.get('offset') ? parseInt(params.get('offset'), 10) : count;
  const limit = count && params && (params.get('limit') || new URL(next || previous).searchParams.get('limit'));
  const itemsPerPage = parseInt(limit, 10);

  const calcCurentPage = (numberOfItems, itemsOnPage) => {
    if (numberOfItems && itemsOnPage) {
      return Math.ceil(numberOfItems / itemsOnPage);
    } return 1;
  };

  const calcMaxNumberOfPages = (maxNumberOfItems, itemsOnPage) => {
    if (maxNumberOfItems && itemsOnPage) {
      return Math.ceil(maxNumberOfItems / itemsOnPage);
    } return 1;
  };

  return (
    <div className="table_pagination_buttons_wrapper">
      {(count && count > 0) ? (
        <div>
          {showCount && `${t('pagination_table.count')}: ${count} | `}
          {`${t('pagination_table.page')}: ${calcCurentPage(offset, itemsPerPage)} / ${calcMaxNumberOfPages(count, itemsPerPage)}`}
        </div>
      ) : null}
      <Button
        disabled={!previous}
        onClick={() => fetchFunction(previous)}
      >
        {t('page_content.previous')}
      </Button>
      <Button
        disabled={!next}
        onClick={() => fetchFunction(next)}
      >
        {t('page_content.next')}
      </Button>
    </div>
  );
};

TableButtons.defaultProps = {
  showCount: true,
};

TableButtons.propTypes = {
  previous: PropTypes.string,
  next: PropTypes.string,
  fetchFunction: PropTypes.func,
  t: PropTypes.func,
  count: PropTypes.number,
  showCount: PropTypes.bool,
};

export default withTranslation()(TableButtons);
