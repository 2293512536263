import api from 'helpers/api';
import { get } from 'lodash';
import { Notification } from 'shared';
import * as actionTypes from './actionTypes';

/*
  Dashboards
*/

const fetchDashboardsSuccess = (aTypes) => (res) => {
  return {
    type: aTypes.fetchDashboardsSuccess,
    payload: res,
    latestUpdate: Date.now(),
  };
};

const fetchDashboardsError = (aTypes) => () => {
  return {
    type: aTypes.fetchDashboardsError,
  };
};

export const addOrUpdateDashboardSuccess = (res) => {
  return {
    type: actionTypes.addOrUpdateDashboardSuccess,
    payload: res,
  };
};

const initFetchDashboards = (aTypes) => () => {
  return {
    type: aTypes.fetchDashboards,
  };
};

export const fetchDashboards = ({ locationId }) => (dispatch) => {
  dispatch(initFetchDashboards(actionTypes)());
  return api.get(`/api/v1/dashboards/dashboard/?location=${locationId}`)
    .then(
      (res) => {
        if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data || !res.data.results) {
          return (
            Notification('error', 'An error occurred while fetching dashboard', 'We could not perform your request, please try again.'),
            dispatch(fetchDashboardsError(actionTypes)())
          );
        }
        dispatch(fetchDashboardsSuccess(actionTypes)(get(res, 'data.results')));
      },
    );
};

const fetchFaultsSuccess = (aTypes) => (res) => {
  const flatted = res;
  return {
    type: aTypes.fetchFaultsSuccess,
    payload: flatted,
  };
};

const fetchFaultsError = (aTypes) => () => {
  return {
    type: aTypes.fetchFaultsError,
  };
};

const initfetchFaults = (aTypes) => () => {
  return {
    type: aTypes.fetchFaults,
  };
};

export const fetchFaults = () => (dispatch) => {
  dispatch(initfetchFaults(actionTypes)());
  return api.get('/api/v1/faults/?resolved=0&limit=200')
    .then(
      (res) => dispatch(fetchFaultsSuccess(actionTypes)(res.data.results)),
      () => dispatch(fetchFaultsError(actionTypes)()),
    );
};

const fetchFaultsBackgroundSuccess = (aTypes) => (res) => {
  return {
    type: aTypes.fetchFaultsBackgroundSuccess,
    payload: res,
  };
};

const fetchFaultsBackgroundError = (aTypes) => () => {
  return {
    type: aTypes.fetchFaultsBackgroundError,
  };
};

const initFetchFaultsBackground = (aTypes) => () => {
  return {
    type: aTypes.fetchFaultsBackground,
  };
};

export const fetchFaultsBackground = (latestUpdate) => (dispatch) => {
  dispatch(initFetchFaultsBackground(actionTypes)());
  return api.get(`/api/v1/faults/?resolved=0&limit=200&modified_since=${new Date(latestUpdate).toISOString()}`)
    .then(
      (res) => dispatch(fetchFaultsBackgroundSuccess(actionTypes)(get(res, 'data.results'))),
      () => dispatch(fetchFaultsBackgroundError(actionTypes)()),
    );
};

export const resetContainer = () => ({
  type: actionTypes.resetContainer,
});
