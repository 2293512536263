import api from 'helpers/api';
import { Notification } from 'shared';

export const updateEvent = (companyId, locationId, data) => {
  return api.put(`/api/v1/industry/events/${data.id}/?company=${companyId}&location=${locationId}`, data)
    .then((re) => {
      Notification('success', 'Save successful', 'Event was successfully updated.');
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
    });
};

export const getAllEvents = async (url, limit = 50) => {
  try {
    const res = await api
      .get(`${url}&limit=${limit}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching events', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAssets = async (companyId, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/assets/?location=${locationId}&company=${companyId}&fat=1`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching assets', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getReasons = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/reasons/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching stoppage reasons', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteEvent = async (eventId, companyId, locationId) => {
  try {
    const res = await api
      .delete(`/api/v1/industry/events/${eventId}/?company=${companyId}&location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while removing event', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
