/* eslint-disable react/prop-types */
/* eslint-disable no-tabs */
import React from 'react';
import Base from './Base';

export default (props) => (<Base viewBox="0 0 510 510" {...props}>
  <g>
    <g>
      <g>
        <rect x="149.333" y="213.333" width="42.667" height="42.667" fill={props.fill} />
        <path
          d="M448,21.333h-21.333C426.667,9.557,417.131,0,405.333,0C393.536,0,384,9.557,384,21.333H128
				C128,9.557,118.464,0,106.667,0C94.869,0,85.333,9.557,85.333,21.333H64c-35.285,0-64,28.715-64,64v21.333h512V85.333
				C512,50.048,483.285,21.333,448,21.333z"
          fill={props.fill}
        />
        <rect x="149.333" y="362.667" width="42.667" height="42.667" fill={props.fill} />
        <rect x="320" y="213.333" width="42.667" height="42.667" fill={props.fill} />
        <path
          d="M0,448c0,35.285,28.715,64,64,64h270.72c-34.539-22.955-57.387-62.165-57.387-106.667s22.848-83.712,57.387-106.667
				h-36.053c-11.797,0-21.333-9.557-21.333-21.333V192c0-11.776,9.536-21.333,21.333-21.333H384
				c11.797,0,21.333,9.557,21.333,21.333v85.333c44.501,0,83.712,22.848,106.667,57.387V149.333H0V448z M106.667,192
				c0-11.776,9.536-21.333,21.333-21.333h85.333c11.797,0,21.333,9.557,21.333,21.333v85.333c0,11.776-9.536,21.333-21.333,21.333
				H128c-11.797,0-21.333-9.557-21.333-21.333V192z M106.667,341.333c0-11.776,9.536-21.333,21.333-21.333h85.333
				c11.797,0,21.333,9.557,21.333,21.333v85.333c0,11.776-9.536,21.333-21.333,21.333H128c-11.797,0-21.333-9.557-21.333-21.333
				V341.333z"
          fill={props.fill}
        />
        <path
          d="M405.333,320C358.272,320,320,358.272,320,405.333c0,47.061,38.272,85.333,85.333,85.333
				c47.061,0,85.333-38.272,85.333-85.333C490.667,358.272,452.395,320,405.333,320z M426.667,426.667h-21.333
				c-11.797,0-21.333-9.557-21.333-21.333v-42.667c0-11.776,9.536-21.333,21.333-21.333c11.797,0,21.333,9.557,21.333,21.333V384
				c11.797,0,21.333,9.557,21.333,21.333S438.464,426.667,426.667,426.667z"
          fill={props.fill}
        />
      </g>
    </g>
  </g>
</Base>);
