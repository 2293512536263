import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { withTranslation } from 'react-i18next';
import { Modal } from 'shared';
import { modalSizes } from 'shared/constants';
import { addComment } from '../../../actions';
import '../style.scss';

class CommentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAsset: '',
      comment: '',
    };
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  onSaveComment = () => {
    const { selectedAsset, comment } = this.state;
    const { getOrderId, closeCommentModal, currentUser, fetchAuditLogs } = this.props;

    const orderId = getOrderId();

    const data = {
      asset: selectedAsset,
      order: orderId,
      log_type: 'comment',
      log_text: comment,
      user_id: currentUser && currentUser.id ? currentUser.id : '',
    };

    addComment(data, orderId)
      .then(() => {
        closeCommentModal();
        fetchAuditLogs();
      });
  }

  render() {
    const { selectedAsset, comment } = this.state;
    const {
      t,
      isCommentModalOpen,
      closeCommentModal,
      assets,
    } = this.props;

    return (
      <Modal
        isOpen={isCommentModalOpen}
        handleSave={this.onSaveComment}
        handleClose={() => closeCommentModal()}
        size={modalSizes.medium}
        title={t('page_content.orders.order_details.audit_log_tab.enter_comment')}
      >
        <div className="audit_log_comment_modal">
          <div className="modal_row_wrapper">
            <div className="left_text">{t('page_content.orders.order_details.audit_log_tab.asset')}</div>
            <div className="selector_wrapper">
              <Select
                options={assets}
                getOptionLabel={(asset) => asset.name}
                getOptionValue={(asset) => asset.id}
                isSearchable
                isClearable
                placeholder=""
                menuPosition="fixed"
                onChange={(value) => this.onFilterChange('selectedAsset', value ? value.id : null)}
                value={(assets.find((a) => a.id === selectedAsset)) || null}
                styles={selectModalStyles}
              />
            </div>
          </div>
          <div className="modal_row_wrapper">
            <div className="left_text">{t('page_content.orders.order_details.audit_log_tab.comment')}</div>
            <div className="text_input">
              <textarea
                type="text"
                value={comment || ''}
                onChange={(e) => { this.onFilterChange('comment', e.target.value); }}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

CommentModal.propTypes = {
  t: PropTypes.func.isRequired,
  isCommentModalOpen: PropTypes.bool.isRequired,
  closeCommentModal: PropTypes.func.isRequired,
  assets: PropTypes.array.isRequired,
  getOrderId: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  fetchAuditLogs: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(CommentModal));
