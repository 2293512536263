import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';

class ReasonGroupModal extends Component {
  constructor(props) {
    super();
    let name = '';
    let source = '';
    let maintenanceOnly = false;
    if (props.selectedReasonGroup) {
      const { selectedReasonGroup } = props;
      name = selectedReasonGroup.name;
      source = selectedReasonGroup.source;
      maintenanceOnly = selectedReasonGroup.maintenance_only;
    }
    this.state = {
      name,
      source,
      maintenanceOnly,
    };
  }

  onFieldChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  handleSaveOrEditReasonGroup = () => {
    const { name, source, maintenanceOnly } = this.state;
    const { handleSaveOrEditReasonGroup, companyId, locationId, selectedReasonGroup } = this.props;

    const data = {
      name,
      company: companyId,
      location: locationId,
      maintenance_only: maintenanceOnly,
      source,
    };
    const id = selectedReasonGroup && selectedReasonGroup.id ? selectedReasonGroup.id : null;

    handleSaveOrEditReasonGroup(data, id);
  }

  render() {
    const { name, source, maintenanceOnly } = this.state;
    const {
      handleCloseModal,
      isReasonGroupModalOpen,
      isLoadingModal,
      t,
    } = this.props;

    const source_options = [
      { value: 'machine' },
      { value: 'process' },
      { value: 'material' },
    ];

    return (
      <React.Fragment>
        <Modal
          size={modalSizes.medium}
          isOpen={isReasonGroupModalOpen}
          handleClose={handleCloseModal}
          handleSave={this.handleSaveOrEditReasonGroup}
          disableSave={!name}
        >
          {
            isLoadingModal ?
              <ContentLoader /> :
              <div className="default-form">
                <table>
                  <tbody>
                    <tr>
                      <td className="label">
                        {t('page_content.maintenance.reason_groups.name')}*
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('name', e.target.value)}
                          value={name}
                          type="text"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.maintenance.reason_groups.source')}
                      </td>
                      <td className="input">
                        <select style={{ width: '100%' }} value={source} onChange={(e) => this.onFieldChange('source', e.target.value)}>
                          <option key="" value="">----</option>
                          {
                            source_options.map((option) => <option key={option.value} value={option.value}>{t([`page_content.maintenance.reason_groups.source_options.${option.value}`])}</option>)
                          }
                        </select>
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.maintenance.reason_groups.maintenance_only')}
                      </td>
                      <td>
                        <input className="checkbox" type="checkbox" checked={maintenanceOnly} onChange={(e) => this.setState({ maintenanceOnly: e.target.checked })} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          }

        </Modal>
      </React.Fragment>
    );
  }
}

ReasonGroupModal.propTypes = {
  t: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isReasonGroupModalOpen: PropTypes.bool.isRequired,
  isLoadingModal: PropTypes.bool.isRequired,
  selectedReasonGroup: PropTypes.object.isRequired,
  handleSaveOrEditReasonGroup: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

export default ReasonGroupModal;
