import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { IconCalendar } from 'shared/Icons';
import { Button } from 'shared';
import { getLocale } from 'shared/DatePicker/constants';
import './styles.scss';

const DateFilters = ({ filterOptions, t, selectedFilterProp, clearFilters, defaultDate = null }) => {
  const menuRef = useRef();
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterLabel, setFilterLabel] = useState(null);
  const [menuElement, setMenuElement] = useState(null);

  const handleMenuPosition = (element) => {
    const refElement = menuRef.current;
    const elementWidth = element?.offsetWidth;
    const domRect = refElement.getBoundingClientRect();
    const windowWidth = window.visualViewport.width;

    if ((domRect.left + elementWidth) > windowWidth) {
      const overflow = windowWidth - (domRect.left + elementWidth);
      element.style.left = `${overflow - 30}px`;
    } else {
      element.style.left = '0';
    }
  };

  const setDefaultDates = () => {
    const label = filterOptions.find((value) => value.id === (selectedFilter === null ? defaultDate.filters : selectedFilter.id));
    if (label && label?.showTime !== null) {
      const timedifference = -+(new Date().getTimezoneOffset() / 60);
      const startDate = moment.utc(defaultDate.start).utcOffset(timedifference, true).toDate();
      const endDate = moment.utc(defaultDate.end).utcOffset(timedifference, true).toDate();
      setStart(start === null ? startDate !== null && startDate : start);
      setEnd(end === null ? endDate !== null && endDate : end);
      setSelectedFilter(label);
      setFilterLabel(`${label.label}:
      ${defaultDate.start && moment(startDate).format(label.showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY')} -
      ${defaultDate.end && moment(endDate).format(label.showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY')}`);
    }
  };

  useEffect(() => {
    if (defaultDate?.start && defaultDate?.end && defaultDate?.filters) {
      setDefaultDates();
    }
  });

  useEffect(() => {
    if (showMenu) {
      const element = document.querySelector('.DateFilters-menu');
      setMenuElement(element);
      handleMenuPosition(element);
    }
  }, [showMenu]);

  useEffect(() => {
    const handleResize = () => {
      if (menuElement) {
        handleMenuPosition(menuElement);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuElement]);

  const handleClearFilter = () => {
    setStart(null);
    setEnd(null);
    setSelectedFilter(null);
    setFilterLabel(null);
  };

  useEffect(() => {
    handleClearFilter();
  }, [clearFilters]);

  const handleSelectFilter = (value) => {
    setSelectedFilter(value);
  };

  const handleClose = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      document.removeEventListener('mousedown', handleClose);
      setShowMenu(false);
    }
  };

  const handleApplyFilters = () => {
    setFilterLabel(`${selectedFilter.label}:
    ${start && moment(start).format(selectedFilter.showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY')} -
    ${end && moment(end).format(selectedFilter.showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY')}`);
    selectedFilterProp(selectedFilter.id, start, end);

    document.removeEventListener('mousedown', handleClose);
    setShowMenu(false);
  };

  const handleShowMenu = () => {
    document.addEventListener('mousedown', handleClose);
    setShowMenu((prevState) => !prevState);
  };

  return (
    <div
      className={`DateFilters ${showMenu ? 'DateFilters-active' : ''}`}
      ref={menuRef}
    >
        <div className="DateFilters-label" onClick={handleShowMenu}>
          <IconCalendar
            width="16px"
            height="16px"
          />
          <span>{!filterLabel ?
            t('shared.date_filters.no_filters_label') :
            filterLabel}
            </span>
        </div>
        {showMenu &&
          <div className="DateFilters-menu">
            <main>
                <div style={{ marginBottom: 10, fontSize: 12 }}>{t('shared.date_filters.filter_by')}</div>
                    <div className="flexContainer">
                        {filterOptions.map((option) => {
                          return <div key={option.id} className="option_button">
                            <Button
                              style={{ fontWeight: 'normal', border: '1px solid transparent' }}
                              type={(selectedFilter !== null && selectedFilter.id !== null) ?
                                (selectedFilter.id === option?.id ? 'dark-blue' : null) : null}
                              onClick={() => handleSelectFilter(option)}
                            >
                        {option.label}
                        </Button></div>;
                        })}
                </div>

            <div className="custom-picker">
          <div>
            <span style={{ fontSize: 12 }}>{t('shared.date_filters.from')}</span>
            <DatePicker
              name="datepicker"
              className="datepicker_input"
              placeholderText={t('page_content.orders.start_date_placeholder')}
              dateFormat={selectedFilter?.showTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'}
              selected={start}
              showTimeSelect={selectedFilter?.showTime}
              onChange={(d) => setStart(d)}
              locale={getLocale(t)}
              maxDate={end}
            />
          </div>
             <div>
             <span style={{ fontSize: 12 }}>{t('shared.date_filters.to')}</span>
            <DatePicker
              name="datepicker"
              className="datepicker_input"
              placeholderText={t('page_content.orders.end_date_placeholder')}
              dateFormat={selectedFilter?.showTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'}
              selected={end}
              showTimeSelect={selectedFilter?.showTime}
              onChange={(d) => setEnd(d)}
              locale={getLocale(t)}
              minDate={start}
            />
          </div>
            </div>
            </main>
            <footer>
                <Button
                  disabled={!start || !selectedFilter}
                  onClick={handleApplyFilters}
                  type="success"
                >
                  {t('page_content.dashboards.time_range_dropdown.apply_button')}
                </Button>
              </footer>
            </div>}
    </div>
  );
};

DateFilters.propTypes = {
  filterOptions: PropTypes.array,
  t: PropTypes.func,
  selectedFilterProp: PropTypes.func,
  clearFilters: PropTypes.bool,
  defaultDate: PropTypes.object,
};

export default (withTranslation()(DateFilters));
