/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment';
import leftChevron from 'shared/Icons/left-chevron.png';
import { IconRemove } from 'shared/Icons';
import imageLogo from 'shared/Icons/image-logo.png';
import Folder from 'shared/Icons/Folder.png';
import { modalSizes, defaultDateTimeFormat } from 'shared/constants';
import { checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import { ContentLoader, Modal, Table, Button, Notification, ConfirmationModal } from 'shared';
import { fetchOperatorViewLast, fetchOperatorViewItems, fetchItemDetails, setIsDefect, deleteEvents } from './actions';
import './style.scss';

class SimpleOperatorInterface extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showAssetItems: false,
      selectedTs: null,
      currentAssetId: null,
      showConfirmationDialog: false,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { companyId, locationId, viewId },
      },
    } = this.props;

    if (this.state.assetData === undefined && this.state.currentAssetId === null) {
      this.backToRootUrl();
    }

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    fetchOperatorViewLast(viewId)
      .then((response) => {
        const assetData = get(response, 'data[0]');
        this.setState({
          assetData: ((assetData && assetData !== undefined) ? assetData : {}),
          isLoading: false,
        });
        const urlParams = new URLSearchParams(window.location.search);
        const ts = urlParams.get('ts');

        if (ts) {
          // eslint-disable-next-line
          this.setState({
            selectedTs: ts,
          });

          this.fetchData(this.state.assetData.event.asset, ts);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      });

    fetchOperatorViewItems(viewId)
      .then((response) => {
        const assetItems = get(response, 'data');
        this.setState({
          assetItems: ((assetItems && assetItems !== undefined) ? assetItems : []),
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      });
  }

  componentDidUpdate() {
    const { selectedTs, assetData, currentAssetId } = this.state;

    const urlParams = new URLSearchParams(window.location.search);
    const ts = urlParams.get('ts');
    if (ts) {
      if (selectedTs) {
        if (selectedTs !== ts) {
          // eslint-disable-next-line
          this.setState({
            selectedTs: ts,
            showAssetItems: false,
          }, () => {
            this.fetchData((currentAssetId && currentAssetId !== null) ? currentAssetId : assetData.asset.id, ts);
          });
        }
      } else {
        // eslint-disable-next-line
        this.setState({
          selectedTs: ts,
          showAssetItems: false,
        }, () => {
          this.fetchData((currentAssetId && currentAssetId !== null) ? currentAssetId : assetData.event.asset, ts);
        });
      }
    } else if (selectedTs) {
      // eslint-disable-next-line
      this.setState({
        showAssetItems: false,
        selectedTs: null,
      });
    }
  }

  setTs = (ts) => {
    if (!ts.original) {
      const urlPart = window.location.href;
      const url = new URL(urlPart);
      const base = url.pathname;
      const selectedTsUrl = `${base}?ts=${ts.ts}`;

      const { history } = this.props;
      history.push(selectedTsUrl);
      this.setState({
        currentAssetId: ts.metric.asset.id,
      });
    } else {
      const urlPart = window.location.href;
      const url = new URL(urlPart);
      const base = url.pathname;
      const selectedTsUrl = `${base}?ts=${ts.original.ts}`;

      const { history } = this.props;
      history.push(selectedTsUrl);
      this.setState({
        currentAssetId: ts.original.metric.asset.id,
      });
    }
  }

  backToRootUrl = () => {
    const {
      match: {
        params: { viewId },
      },
    } = this.props;
    const { history } = this.props;

    const urlPart = window.location.href.split('?ts')[0];
    const url = new URL(urlPart);
    const base = url.pathname;

    window.operatorTS = null;
    const latestTsUrl = `${base}`;
    history.push(latestTsUrl);
    fetchOperatorViewLast(viewId)
      .then((response) => {
        const assetData = get(response, 'data[0]');
        this.setState({
          assetData: ((assetData && assetData !== undefined) ? assetData : {}),
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      });
  }

  fetchData = (assetId, ts) => {
    fetchItemDetails(assetId, ts)
      .then((res) => {
        const dataTs = get(res, 'data');
        this.setState(() => {
          return {
            isLoading: false,
            assetData: dataTs,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  }

  toggleDisplayAssetItems = (showAssetItems) => {
    this.setState({
      showAssetItems,
    });
  }

  resolveDefect = (isDefect) => {
    const { assetData, assetItems } = this.state;
    let payload = {};

    if (!assetData || !assetData.ts || !assetData.item_snowflake || !assetData.metric || !assetData.metric.asset || !assetData.metric.asset.external_id) {
      if (!assetData || !assetData.ts || !assetData.item_snowflake || !assetData.asset || !assetData.asset.external_id) {
        Notification('error', 'Data missing', 'This item is missing data and defect cannot be resolved.');
        return;
      }
      payload = {
        ts: assetData.ts,
        value: isDefect,
        external_id: assetData.asset.external_id,
        snowflake_id: assetData.item_snowflake,
        comment: '',
      };
    } else {
      payload = {
        ts: assetData.ts,
        value: isDefect,
        external_id: assetData.metric.asset.external_id,
        snowflake_id: assetData.item_snowflake,
        comment: '',
      };
    }

    setIsDefect(payload)
      .then(() => {
        if (isDefect) {
          Notification('success', 'Damage confirmed', 'The damage was successfully confirmed.');
        } else {
          Notification('success', 'Issue resolved', 'The issue was successfully resolved.');
          let index = assetItems.map((e) => e.ts).indexOf(assetData.ts);

          if (index !== 0) {
            index -= 1;
            const nextTs = assetItems[index];
            this.setTs(nextTs);
          }
        }
        this.performCurrentFetch();
      })
      .catch((error) => { console.log(error); });
  }

  performCurrentFetch = () => {
    const {
      match: {
        params: { viewId },
      },
    } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    const ts = urlParams.get('ts');

    if (ts) {
      this.setState({
        selectedTs: ts,
      });

      this.fetchData(this.state.assetData.event.asset, ts);
    }

    fetchOperatorViewItems(viewId)
      .then((response) => {
        const assetItems = get(response, 'data');
        this.setState({
          assetItems: ((assetItems && assetItems !== undefined) ? assetItems : []),
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      });
  }

  handleShowDeleteConfirmation = (operator_view_event, ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState({ deleteData: operator_view_event, showConfirmationDialog: true });
  }

  handleDeleteEvent = () => {
    const {
      selectedTs,
      assetItems,
      assetData,
      deleteData,
    } = this.state;

    const {
      match: {
        params: { viewId },
      },
    } = this.props;

    const operator_view_event = deleteData;

    if (operator_view_event && operator_view_event.original && operator_view_event.original.ts && operator_view_event.original.metric && operator_view_event.original.metric.id) {
      let metricId = operator_view_event.original.metric.id;
      let ts = operator_view_event.original.ts;

      deleteEvents(metricId, ts)
        .then(() => {
          metricId = '';
          ts = '';
          fetchOperatorViewItems(viewId)
            .then((response) => {
              const newAssetItems = get(response, 'data');
              this.setState({
                assetItems: ((newAssetItems && newAssetItems !== undefined) ? newAssetItems : []),
                isLoading: false,
              });
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                isLoading: false,
              });
            });
          return Notification('success', 'Delete successful', 'Event was successfuly deleted.');
        })
        .catch((err) => {
          console.log(err);
          return Notification('error', 'An error occurred while removing event', 'We could not perform your request, please try again.');
        });
      if (operator_view_event && operator_view_event.original && operator_view_event.original.ts === selectedTs) {
        let index = assetItems.map((e) => e.ts).indexOf(assetData.ts);
        if (index !== 0) {
          index -= 1;
          const nextTs = assetItems[index];
          this.setTs(nextTs);
        }
      }
      this.performCurrentFetch();
    } else {
      return Notification('error', 'An error occurred while removing event', 'We could not perform your request, please try again.');
    }
    this.setState({ showConfirmationDialog: false });
  }

  render() {
    const { t } = this.props;
    const {
      isLoading,
      showAssetItems,
      assetData,
      assetItems,
      selectedTs,
    } = this.state;

    const urlPart = window.location.href.split('View/')[0];
    const url = new URL(urlPart);
    const base = url.pathname;
    const backLocation = `${base}`;

    const orderInfoError = (assetData && assetData.event && assetData.event.description) ? assetData.event.description : 'error';
    const imageInfoError = (assetData && assetData.event && assetData.event.image) ? assetData.event.image : 'error';

    if (isLoading) {
      return <ContentLoader />;
    }
    return (
      <div className="operator">
        <Modal
          className="Modal"
          size={modalSizes.large}
          isOpen={showAssetItems}
          handleClose={() => { this.toggleDisplayAssetItems(false); }}
        >
          <Table
            columns={[
              {
                Header: () => <span>{t('page_content.operator_view.simple_operator_interface.modal.table_column_asset_name')}</span>,
                accessor: 'metric.asset.name',
              },
              {
                Header: () => <span>{t('page_content.operator_view.simple_operator_interface.modal.table_column_value')}</span>,
                accessor: 'value',
                Cell: (row) => Math.round(row.value),
              },
              {
                Header: () => <span>{t('page_content.operator_view.simple_operator_interface.modal.table_column_time')}</span>,
                accessor: 'ts',
                Cell: (row) => moment(row.value).format(defaultDateTimeFormat),
              },
              {
                Header: () => <span>{t('page_content.operator_view.simple_operator_interface.modal.table_column_label')}</span>,
                accessor: 'metric.label',
              },
              {
                Header: () => <span>{t('page_content.operator_view.simple_operator_interface.modal.table_column_delete')}</span>,
                Cell: (operator_view_event) => <Button type="delete" className="remove-btn" onClick={(ev) => this.handleShowDeleteConfirmation(operator_view_event, ev)}><IconRemove width="12px" height="12px" /></Button>,
              },
            ]}
            data={assetItems || []}
            defaultPageSize={100}
            handleClick={(row) => { this.setTs(row); }}
            minRows={0}
            noDataText=" "
            manual={false}
            showPagination={false}
          />
        </Modal>
        <div className="operator__companyInfo">
          <div className="operator__companyInfo__left">
            <div className="operator__companyInfo__Backimg" />
            <Link to={backLocation}>
              <img
                src={leftChevron}
                alt="<"
                style={{ height: '35px', width: '27px', marginBottom: '-2px' }}
              />
            </Link>
            <div className="operator__companyInfo__title">
              <span>{(selectedTs !== null && assetData && assetData.asset && assetData.asset.name) ? `${assetData.asset.name} > ` : (assetData && assetData.metric && assetData.metric.asset && assetData.metric.asset.name) ? `${assetData.metric.asset.name} > ` : ''}
                {(selectedTs !== null && assetData && assetData.asset && assetData.asset.external_id) ? assetData.asset.external_id : (assetData && assetData.metric && assetData.metric.asset && assetData.metric.asset.external_id) ? assetData.metric.asset.external_id : ''}</span>
            </div>
          </div>
          <div className="operator__companyInfo__right">
            {
              selectedTs ?
                <div className="operator__back-to-latest">
                  <Button
                    onClick={this.backToRootUrl}
                  >
                    {t('page_content.operator_view.simple_operator_interface.back_to_latest_button')}
                  </Button>
                </div> : ''
            }
            <div className="operator__companyinfo__folder">
              <img
                src={Folder}
                alt="folder"
                onClick={() => { this.toggleDisplayAssetItems(true); }}
              />
            </div>
          </div>

        </div>
        <div className="operator__order_details">
          <div className="operator__order_details_data">
            <div className="operator__order">
              <div className="operator__titles">
                <strong>{t('page_content.operator_view.simple_operator_interface.order')}</strong>
              </div>
              <div
                className="column"
              >
                <div className={`operator__order__description ${orderInfoError}`}>
                  <p className="operator__data-title">{t('page_content.operator_view.simple_operator_interface.description')}</p>
                  <p className="operator__data-content">
                    {(assetData && assetData.event && assetData.event.description) ? assetData.event.description : 'No Data'}
                  </p>
                </div>
                <div className="operator__order__counter">
                  <p className="operator__data-title">{t('page_content.operator_view.simple_operator_interface.counter')}</p>
                  <div className="operator__data__counter">
                    <p>{(assetData && assetData.value) ? Math.round(assetData.value) : ''}</p>
                  </div>
                </div>
                <div className="operator__order__time">
                  <p className="operator__data-title">{t('page_content.operator_view.simple_operator_interface.time')}</p>
                  <div className="operator__data__time">
                    <p>{(assetData && assetData.ts) ? moment(assetData.ts).format(defaultDateTimeFormat) : 'No Data'}</p>
                  </div>
                </div>
                {(assetData && assetData.event) ?
                  <div className="operator__resolve-defect">
                    <span
                      className="defect-button"
                    >
                      <Button
                        onClick={() => { this.resolveDefect(1); }}
                      >
                        {t('page_content.operator_view.simple_operator_interface.defect_button')}
                      </Button>
                    </span>
                    <span
                      className="continue-button"
                    >
                      <Button
                        onClick={() => { this.resolveDefect(0); }}
                      >
                        {t('page_content.operator_view.simple_operator_interface.continue_button')}
                      </Button>
                    </span>
                  </div> : ''}
              </div>
              <div className="column">
                <div className={`operator__order__defected ${imageInfoError}`}>
                  <p className="operator__data-title">{t('page_content.operator_view.simple_operator_interface.defected')}</p>
                  <div
                    className={`operator__order__defected__imgBackground${imageInfoError}`}
                    style={{ height: '83%', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
                  >
                    {(assetData && assetData.event && assetData.event.image) ?
                      <img
                        src={assetData.event.image}
                        alt="asset"
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          display: 'block',
                          marginTop: '3%',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      /> :
                      <img
                        className="logo"
                        src={imageLogo}
                        alt="asset"
                        style={{ height: '160px', width: '200px', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: '25%' }}
                      />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModal
          itemName={this.state.deleteData?.metric?.asset?.name}
          description={t('page_content.human_resources.departments.modal_workplaces_warning_message')}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDeleteEvent}
          type="warning"
        />
      </div>
    );
  }
}

SimpleOperatorInterface.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SimpleOperatorInterface);
