import api from 'helpers/api';
import { Notification } from 'shared';

export const fetchDevices = (companyId, locationId) => api.get(`/api/v1/industry/devices/?location=${locationId}&company=${companyId}`);

export const fetchAssets = async (url) => {
  try {
    const res = await api
      .get(`${url}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching assets', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAssetModel = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/asset_models/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching models', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const fetchMetrics = async (locationId, assetId, sortOrder) => {
  const urlString = '/api/v1/industry/metrics/';
  let urlParams = `?limit=10&location=${locationId}`;
  if (assetId) {
    urlParams += `&asset=${assetId}`;
  }
  if (sortOrder) {
    urlParams += sortOrder;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api
      .get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching models', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const fetchMetricsUrl = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching models', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAssetTypes = async (companyId, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/asset_types/?account_type=industry&company=${companyId}&location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAssetGroups = async (locationId, companyId) => {
  const urlString = '/api/v1/industry/asset_groups/';
  let urlParams = '?order_by=name';
  if (locationId) {
    urlParams += `&location=${locationId}`;
  }
  if (companyId) {
    urlParams += `&company=${companyId}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching asset groups.', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAssetStatuses = async () => {
  try {
    const res = await api
      .get('/api/v1/industry/all_statuses');
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching asset statuses', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const updateAsset = (assetId, assetData) => {
  return api.patch(`/api/v1/industry/assets/${assetId}/`, assetData)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return { response: res, notification: Notification('success', 'Save successful', 'Asset was successfully updated.') };
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== ''));
    });
};

export const createAsset = (companyId, assetData) => {
  return api.post(`/api/v1/industry/assets/?companyId=${companyId}`, assetData)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return { response: res, notification: Notification('success', 'Save successful', 'Asset was successfully created.') };
    })
    .catch((error) => {
      return Notification('error', 'An error occurred while trying to create an asset', (error && error.message && error.message !== ''));
    });
};

export const createAssetType = (assetData) => {
  return api.post('/api/v1/asset_types/', assetData)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return { response: res, notification: Notification('success', 'Save successful', 'Asset type was successfully created.') };
    })
    .catch((error) => {
      return Notification('error', 'An error occurred while trying to create an asset type', (error && error.message && error.message !== ''));
    });
};

export const createAssetGroup = (assetData) => {
  return api.post('/api/v1/industry/asset_groups/', assetData)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return { response: res, notification: Notification('success', 'Save successful', 'Asset group was successfully created.') };
    })
    .catch((error) => {
      return Notification('error', 'An error occurred while trying to create an asset group', (error && error.message && error.message !== ''));
    });
};

export const getAssetGroupTypes = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/asset_group_types/?company=${companyId}
      `);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching asset group types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
