/* eslint-disable import/prefer-default-export */
import api from 'helpers/api';
import { Notification } from 'shared';

export const getDmsDocumentFiles = async (documentId) => {
  const urlString = '/api/v1/dms/document_files/';
  let urlParams = '?limit=30';
  if (documentId) {
    urlParams += `&document=${documentId}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching document files', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const importDmsDocumentFiles = (data, documentId) => {
  const formData = new FormData();
  if (data) {
    formData.append('file', data);
  }
  if (documentId) {
    formData.append('document', documentId);
  }
  return api.post('/api/v1/dms/document_files/', formData)
    .then((re) => {
      return re;
    })
    .catch((err) => {
      if (err.response.data.file) {
        Notification('error', err.response.data.file[0]);
        return;
      }
      Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
    });
};

export const approveDmsDocumentFile = async (payload) => {
  return api.post('/api/v1/dms/approve_revision/', payload);
};

export const deleteDmsDocumentFile = async (fileId, documentId) => {
  return api.delete(`/api/v1/dms/document_files/${fileId}/?document=${documentId}`);
};
