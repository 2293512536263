import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'shared';
import { withTranslation } from 'react-i18next';

const PartnersModal = (props) => {
  const [missingFields, setMissingFields] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const {
    handleModalClose,
    modalState,
    handlePartnerData,
    partnerData,
    handleCreateOrUpdatePartner,
    title,
    t,
  } = props;

  useEffect(() => {
    if (Object.values(missingFields).some((element) => element === true) || !submitted) {
      return;
    }
    handleCreateOrUpdatePartner();
  }, [missingFields, submitted]);

  return (
    <Modal
      isOpen={modalState}
      handleClose={() => {
        handleModalClose();
      }}
      handleSave={() => {
        setMissingFields((prevState) => ({ ...prevState, name: !partnerData.name, external_id: !partnerData.external_id }));
        setSubmitted(true);
      }}
      title={title}
      disabled
    >
      <form className="default-form partner-details-form">
        <table>
          <tbody>
            <tr>
              <td className="label">
                {`${t('page_content.partners.table_column_externalId')} *`}
              </td>
              <td className="input">
                <input
                  type="text"
                  onChange={(e) => { handlePartnerData(e.target.value, 'external_id'); }}
                  value={partnerData.external_id}
                  style={missingFields.external_id ? { borderColor: 'red' } : {}}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {`${t('page_content.partners.table_column_name')} *`}
              </td>
              <td className="input">
                <input
                  type="text"
                  onChange={(e) => { handlePartnerData(e.target.value, 'name'); }}
                  value={partnerData.name}
                  style={missingFields.name ? { borderColor: 'red' } : {}}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.partners.table_column_city')}
              </td>
              <td className="input">
                <input
                  type="text"
                  onChange={(e) => { handlePartnerData(e.target.value, 'city'); }}
                  value={partnerData.city}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.partners.table_column_address')}
              </td>
              <td className="input">
                <input
                  type="text"
                  onChange={(e) => { handlePartnerData(e.target.value, 'address'); }}
                  value={partnerData.address}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.partners.table_column_zipcode')}
              </td>
              <td className="input">
                <input
                  type="text"
                  onChange={(e) => { handlePartnerData(e.target.value, 'zipcode'); }}
                  value={partnerData.zipcode}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.partners.table_column_country')}
              </td>
              <td className="input">
                <input
                  type="text"
                  onChange={(e) => { handlePartnerData(e.target.value, 'country'); }}
                  value={partnerData.country}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.partners.table_column_mobile')}
              </td>
              <td className="input">
                <input
                  type="number"
                  onChange={(e) => { handlePartnerData(e.target.value, 'mobile_phone'); }}
                  value={partnerData.mobile_phone}
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.partners.table_column_isCooperator')}
              </td>
              <td className="input">
              <input type="checkbox" defaultChecked={partnerData.is_cooperant} onChange={(e) => handlePartnerData(e.target.checked, 'is_cooperant')} />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.partners.table_column_isCustomer')}
              </td>
              <td className="input">
              <input type="checkbox" defaultChecked={partnerData.is_customer} onChange={(e) => handlePartnerData(e.target.checked, 'is_customer')} />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.partners.table_column_isSupplier')}
              </td>
              <td className="input">
                <input type="checkbox" defaultChecked={partnerData.is_supplier} onChange={(e) => handlePartnerData(e.target.checked, 'is_supplier')} />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </Modal>
  );
};

PartnersModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  modalState: PropTypes.bool.isRequired,
  handlePartnerData: PropTypes.func.isRequired,
  partnerData: PropTypes.object.isRequired,
  handleCreateOrUpdatePartner: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PartnersModal);
