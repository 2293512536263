import React from 'react';
import Base from './Base';

export default function Icon(props) {
  // eslint-disable-next-line react/prop-types
  const { color, ...otherProps } = props;

  return (
    <Base {...otherProps} viewBox="0 0 23 23" fill={color || 'black'}>
      <path d="M19.5 19.5H4.5V4.5H11.25V3H4.5C3.67157 3 3 3.67157 3 4.5V19.5C3 20.3284 3.67157 21 4.5 21H19.5C20.3284 21 21 20.3284 21 19.5V12.75H19.5V19.5Z" fill={color} />
      <path d="M14.5312 3V4.4375H18.5491L12.375 10.6116L13.3884 11.625L19.5625 5.45094V9.46875H21V3H14.5312Z" fill={color} />
    </Base>
  );
}
