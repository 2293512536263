import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { get } from 'lodash';
import { checkAccessOnPage, redirectToHomePage, checkModules } from 'industry/helpers';
import { getReportScores } from './actions';
import AggregateAnalytics from './components/AggregateAnalytics';
import DetailedAnalytics from './components/DetailedAnalytics';
import './styles.scss';

class Industry5SDetails extends Component {
  constructor(props) {
    super(props);

    let isReadOnly = false;

    if (window.resourceReadOnly && window.resourceReadOnly.length) {
      window.resourceReadOnly.forEach((resource) => {
        if (window.location.href.includes(resource)) {
          isReadOnly = true;
        }
      });
    }

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      isReadOnly,
      isLoading: false,
      reportScores: null,
      scoreCardData: [],
      reportId: null,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === '5S');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            isReadOnly: true,
          });
        }
      });

    this.getReportScores();
  }

  getReportScores = () => {
    const {
      companyId,
      match: {
        params: {
          entryId,
        },
      } } = this.props;

    this.setState({
      isLoading: true,
    });

    getReportScores(entryId, companyId)
      .then((res) => {
        this.setState({
          reportScores: get(res, 'data', {}),
          reportId: get(res, 'data.report_id', null),
        }, () => {
          this.transformScoreCardData();
        });
      });
  }

  transformScoreCardData = () => {
    const { reportScores } = this.state;

    const score_card = get(reportScores, 'score_card', {});

    const scoreCardData = Object.keys(score_card).map((key) => ({
      id: key,
      report: key,
      count: score_card[key].count,
      sum: score_card[key].sum,
      avg: score_card[key].avg,
      0: score_card[key]['0'],
      '05': score_card[key]['05'],
      1: score_card[key]['1'],
    }));

    this.setState({
      scoreCardData,
      isLoading: false,
    });
  }

  render() {
    const { isLoading, scoreCardData, reportId } = this.state;
    const { t } = this.props;

    return (
      <div className="industry-tab industry-location-5S fullscreen">
        <Tabs>
          <TabList>
            <Tab>{t('page_content.5S.aggregate_analytics')}</Tab>
            <Tab>{t('page_content.5S.detailed_analytics')}</Tab>
          </TabList>

          <TabPanel>
            <AggregateAnalytics
              t={t}
              isLoading={isLoading}
              scoreCardData={scoreCardData}
            />
          </TabPanel>

          <TabPanel>
            <DetailedAnalytics t={t} reportId={reportId} />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

Industry5SDetails.propTypes = {
  match: PropTypes.object,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
});

export default withRouter(connect(mapStateToProps, null)(withTranslation()(Industry5SDetails)));
