import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { get } from 'lodash';

import { Button, Table, Modal, TableButtons, DateFilters, ConfirmationModal } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { IconEdit, IconRemove, IconSearch } from 'shared/Icons';
import { ordersCreatedAndUpdated } from 'shared/constants';

import { addCategory, deleteCategory, getCategories, getPaginatedCategories, updateCategory } from './actions';

const CategoriesTab = ({ t, companyId, reFetchCategories }) => {
  const debounceTimeoutRef = useRef(null);
  const [categoryInput, setCategoryInput] = useState('');
  const [categoryCodeInput, setCategoryCodeInput] = useState('');
  const [categoryWhoCanStartSurvey, setCategoryWhoCanStartSurvey] = useState(null);
  const [editData, setEditData] = useState(null);
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [tableData, setTableData] = useState({
    categories: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [query, setQuery] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [sortData, setSortData] = useState({ desc: true, id: 'updated_at' });
  const [clearDateFilters, setClearDateFilters] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const fetchPaginatedCategories = async (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const res = await getPaginatedCategories(url);
    setTableData({
      categories: get(res, 'data.results', []),
      next: get(res, 'data.next'),
      previous: get(res, 'data.previous'),
      count: get(res, 'data.count'),
      isLoading: false,
    });
  };

  const fetchCategories = async () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    let filters = '';

    if (sortData) {
      const asc = sortData.desc ? '-' : '';
      const column = sortData.id;
      filters += `&order_by=${asc}${column || 'updated_at'}`;
    }

    if (query) {
      filters += `&name=${query}`;
    }
    if (dateFilter?.start && selectedFilter?.date) {
      filters += `&${selectedFilter.date}_after=${moment(dateFilter.start).format('YYYY-MM-DD')}`;
    }
    if (dateFilter?.start && dateFilter?.end && selectedFilter?.date) {
      filters += `&${selectedFilter.date}_before=${moment(dateFilter.end).format('YYYY-MM-DD')}`;
    }

    const res = await getCategories(companyId, filters);
    setTableData({
      categories: get(res, 'data.results', []),
      next: get(res, 'data.next'),
      previous: get(res, 'data.previous'),
      count: get(res, 'data.count'),
      isLoading: false,
    });
  };

  const handleSaveCategory = async () => {
    const data = { name: categoryInput, company: companyId, who_can_start_survey: categoryWhoCanStartSurvey, code: categoryCodeInput };
    if (editData) {
      await updateCategory(editData?.original?.id, data, companyId);
    } else {
      await addCategory(data);
    }
    setIsAddCategory(false);
    setEditData(null);
    setCategoryInput('');
    setCategoryCodeInput('');
    setCategoryWhoCanStartSurvey(null);
    fetchCategories();
    reFetchCategories();
  };

  const handleEditButton = (e, row) => {
    e.stopPropagation();
    setCategoryInput(row?.original?.name || '');
    setCategoryCodeInput(row?.original?.code || '');
    setCategoryWhoCanStartSurvey(row?.original?.who_can_start_survey || null);
    setIsAddCategory(true);
    setEditData(row);
  };

  const handleCloseModal = () => {
    setEditData(null);
    setCategoryInput('');
    setCategoryCodeInput('');
    setCategoryWhoCanStartSurvey(null);
    setIsAddCategory(false);
  };

  const handleShowConfirmationModal = (e, row) => {
    e.stopPropagation();
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleRemoveButton = async () => {
    const id = deleteData?.original?.id;
    await deleteCategory(id, companyId);
    reFetchCategories();
    fetchCategories();
    setShowConfirmationDialog(false);
  };

  const clearAllFilters = () => {
    setSelectedFilter(null);
    setDateFilter(null);
    setQuery('');
    setClearDateFilters((prevState) => !prevState);
  };

  useEffect(() => {
    if (query !== null) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        fetchCategories();
      }, 300);
    }
  }, [query]);

  useEffect(() => {
    fetchCategories();
  }, [selectedFilter, dateFilter, sortData]);

  const handleSorting = (sortingData) => {
    setSortData({ desc: sortingData.desc, id: sortingData.id });
  };

  const handleSelectedDateFilter = (filter, start, end) => {
    setSelectedFilter((prevState) => ({
      ...prevState,
      date: filter.id,
    }));
    setDateFilter({
      start, end,
    });
  };

  const whoCanStartSurveyOptions = [
    { label: t('page_content.human_resources.forms.who_can_start_survey_worker'), value: 'worker' },
    { label: t('page_content.human_resources.forms.who_can_start_survey_manager'), value: 'lead' },
    { label: t('page_content.human_resources.forms.who_can_start_survey_hr'), value: 'hr' },
  ];

  const tableColumns = [
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_category_name')}</span>,
      accessor: 'name',
      Cell: (row) => (row?.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_category_code')}</span>,
      accessor: 'code',
      Cell: (row) => (row?.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_who_can_start_survey')}</span>,
      accessor: 'who_can_start_survey',
      Cell: (row) => (row?.value ? whoCanStartSurveyOptions?.find((_) => _.value === row.value).label : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_created_at')}</span>,
      accessor: 'created_at',
      Cell: (row) => (row?.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_last_update')}</span>,
      accessor: 'updated_at',
      Cell: (row) => (row?.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_edit')}</span>,
      width: 100,
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button style={{ width: 55, padding: '5px 8px' }} onClick={(e) => handleEditButton(e, row)}>
        <IconEdit
          height="14px"
          width="14px"
          fill="#4285F4"
        />
      </Button>
    </div>),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_delete')}</span>,
      width: 100,
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button type="delete" style={{ width: 55, padding: '5px 8px' }} onClick={(e) => handleShowConfirmationModal(e, row)}>
        <IconRemove
          height="14px"
          width="14px"
        />
      </Button>
    </div>),
    },
  ];

  return (
    <div>
      <div className="filters_container">
      <div className="filters">
          <div className="input_container_search">
            <div className="input_field_style">
              <input onChange={(e) => setQuery(e.target.value)} placeholder={t('page_content.human_resources.forms.search_by_name')} value={query || ''} />
              {query && <button
                onClick={() => setQuery('')}
              >&times;</button>}
            </div>
          <div className="icon_container">
            <IconSearch
              color="#555"
              height="26px"
              width="26px"
            />
            </div>
          </div>
          <DateFilters
            selectedFilterProp={handleSelectedDateFilter}
            filterOptions={[
              { label: t('page_content.human_resources.forms.table_column_form_templates_created_at'), id: 'created_at' },
              { label: t('page_content.human_resources.forms.table_column_form_templates_last_update'), id: 'updated_at' }]}
            clearFilters={clearDateFilters}
          />
          <div className="clear_button">
              <Button
                type="plain"
                onClick={clearAllFilters}
              >
                {t('page_content.orders.clear_all_button')}
              </Button>
            </div>
          <div style={{ marginLeft: 'auto' }}>
          <Button type="add" onClick={() => setIsAddCategory(true)}>{t('page_content.human_resources.forms.button_add_category')}</Button>
        </div>
        </div>
        </div>
    <Table
      style={{ userSelect: 'text' }}
      columns={tableColumns}
      data={tableData?.categories || []}
      loading={tableData.isLoading}
      noDataText=" "
      showPagination={false}
      minRows={0}
      defaultPageSize={30}
      selectedRow={null}
      defaultSorted={[{ id: 'updated_at', desc: true }]}
      onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
    />
    <TableButtons count={tableData.count} next={tableData.next} previous={tableData.previous} fetchFunction={fetchPaginatedCategories} />
    <Modal title={t('page_content.human_resources.forms.add_category_title')} isOpen={isAddCategory} handleClose={handleCloseModal} handleSave={handleSaveCategory} disableSave={!categoryInput || !categoryWhoCanStartSurvey}>
      <div style={{ padding: 20 }}>
        <div className="modal_row">
          <div className="left_text">
            {t('page_content.human_resources.forms.add_category_label')}*
          </div>
          <div className="right_input">
            <input type="text" value={categoryInput} onChange={(e) => { setCategoryInput(e?.target?.value || ''); setCategoryCodeInput(e?.target?.value?.toLowerCase()?.replace(/ /g, '-') || ''); }} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">
            {t('page_content.human_resources.forms.add_category_code')}
          </div>
          <div className="right_input">
            <input type="text" readOnly value={categoryCodeInput} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">
            {t('page_content.human_resources.forms.add_category_who_can_start_survey')}*
          </div>
          <div className="right_select">
            <Select
              options={whoCanStartSurveyOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              placeholder={t('page_content.human_resources.forms.add_category_who_can_start_survey_select')}
              isSearchable
              menuPosition="fixed"
              onChange={(e) => setCategoryWhoCanStartSurvey(e.value)}
              value={whoCanStartSurveyOptions?.find((a) => a.value === categoryWhoCanStartSurvey) || ''}
              styles={selectModalStyles}
            />
          </div>
        </div>
      </div>
    </Modal>
      <ConfirmationModal
        itemName={deleteData?.original?.name}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleRemoveButton}
        type="warning"
      />
    </div>
  );
};

CategoriesTab.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number,
  reFetchCategories: PropTypes.func,
};

export default (withRouter(withTranslation()(CategoriesTab)));
