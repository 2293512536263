import React, { Component } from 'react';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Notification,
  ConfirmationModal,
} from 'shared';
import { deleteReportMetric } from '../../actions';

class ReportMetricForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      metricId: (props) ? props.metricId : 0,
      deleteData: null,
      showConfirmationDialog: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.isSaveDisabled = this.isSaveDisabled.bind(this);
  }

  isSaveDisabled = () => {
    const {
      metricId,
    } = this.state;

    if (parseInt(metricId, 10) > 0) {
      return false;
    }

    return true;
  }

  handleSave() {
    const {
      metricId,
    } = this.state;

    const {
      reportMetricId,
      reportId,
      reloadEntries,
      t,
    } = this.props;

    const reportMetric = {
      metric: metricId,
      report: reportId,
    };

    if (reportMetricId > 0) {
      reportMetric.id = reportMetricId;
      api.patch(`/api/v1/reports/report_metrics/${reportMetricId}/?report=${reportId}`, reportMetric)
        .then(() => {
          reloadEntries(true);
          return Notification('success', t('page_content.automatic_reports.report_metrics.success_update'));
        })
        .catch(() => {
          return Notification('error', 'An error occurred', t('page_content.automatic_reports.request_error'));
        });
      // eslint-disable-next-line
    } else {
      api.post('/api/v1/reports/report_metrics/', reportMetric)
        .then(() => {
          reloadEntries(true);
          return Notification('success', t('page_content.automatic_reports.report_metrics.success_create'));
        })
        .catch(() => {
          return Notification('error', 'An error occurred', t('page_content.automatic_reports.request_error'));
        });
    }
  }

  handleShowConfirmationModal = (reportMetricId) => {
    this.setState({ showConfirmationDialog: true, deleteData: reportMetricId });
  }

  handleDelete() {
    const {
      reloadEntries,
      reportId,
      t,
    } = this.props;
    const id = this.state.deleteData;
    deleteReportMetric(id, reportId)
      .then(() => {
        reloadEntries(true);
        return Notification('success', 'Delete successful', t('page_content.automatic_reports.report_metrics.success_delete'));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => this.setState({ showConfirmationDialog: false }));
  }

  changeMetric = (e) => {
    this.setState({ metricId: e.target.value });
  }

  render() {
    const {
      metricId,
    } = this.state;

    const {
      reportMetricId,
      metrics,
      t,
    } = this.props;

    const isSaveDisabled = this.isSaveDisabled();

    return (
      <tbody>
        <tr>
          <td className="label">{t('page_content.automatic_reports.metric')}</td>
          <td className="input"><select onChange={this.changeMetric} className="" required>
            <option value={null} disabled selected>{t('page_content.automatic_reports.select_metric')}</option>
            {metrics.map((metric) => (
              <option key={metric.id} value={metric.id} selected={(metricId > 0) ? metricId === metric.id : false}>
                {metric.label}
              </option>
            ))}
          </select></td>
          <td className="actions">
            <Button
              type={isSaveDisabled ? 'plain' : 'success'}
              size="small"
              disabled={isSaveDisabled}
              onClick={this.handleSave}
            >
              {(reportMetricId > 0)
                ? t('page_content.automatic_reports.report_metrics.edit')
                : t('page_content.automatic_reports.report_metrics.create')}
            </Button>

            {reportMetricId > 0 &&
              <Button
                size="small"
                type="delete"
                onClick={() => this.handleShowConfirmationModal(reportMetricId)}
              >
                {t('page_content.automatic_reports.delete')}
              </Button>}
          </td>
        </tr>
        <ConfirmationModal
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDelete}
          type="warning"
        />
      </tbody>
    );
  }
}

ReportMetricForm.propTypes = {
  reportMetricId: PropTypes.number.isRequired,
  metricId: PropTypes.number.isRequired,
  reportId: PropTypes.number.isRequired,
  metrics: PropTypes.array.isRequired,
  reloadEntries: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ReportMetricForm);
