import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { IconEdit, IconRemove } from 'shared/Icons';
import { Button, Table, Modal, ContentLoader, ConfirmationModal } from 'shared';
import { getWorkProcesses, addOrEditProgram } from '../../../actions';

const Programs = ({ t, companyId, isReadOnly }) => {
  const [programs, setPrograms] = useState({
    isLoading: true,
    data: [],
    workProcess: null,
  });

  const [modal, setModal] = useState({
    isLoading: false,
    isOpen: false,
  });

  const [formData, setFormData] = useState({
    isEdit: false,
    name: '',
    width: '',
    height: '',
    planks: '',
    columns: '',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  const transformDataToArray = (data) => {
    if (data) {
      return Object.entries(data).map(([key, value]) => ({
        name: key,
        width: value.width,
        height: value.height,
        planks: value.planks,
        columns: value.columns,
      }));
    }
    return [];
  };

  const fetchData = () => {
    setPrograms((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getWorkProcesses(companyId)
      .then((res) => {
        setPrograms({
          isLoading: false,
          data: transformDataToArray(get(res, 'data.results[0].choices.programs', [])) || [],
          workProcess: get(res, 'data.results[0]', null),
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openProgramModal = () => {
    setModal((prevState) => ({
      ...prevState,
      isOpen: true,
    }));
  };

  const handleClose = () => {
    setModal((prevState) => ({
      ...prevState,
      isOpen: false,
      isLoading: false,
    }));

    setFormData({
      isEdit: false,
      name: '',
      width: '',
      height: '',
      planks: '',
      columns: '',
    });
  };

  const handleSave = async () => {
    setModal((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let payload = {};

    if (formData?.isEdit) {
      const existingPrograms = programs?.workProcess?.choices?.programs;

      // Check if the program to edit exists
      if (formData?.name in existingPrograms) {
        // Update the existing program's properties
        existingPrograms[formData?.name] = {
          width: Number(formData.width),
          height: Number(formData.height),
          planks: Number(formData.planks),
          columns: Number(formData.columns),
        };

        payload = {
          choices: {
            ...programs.workProcess.choices,
            programs: existingPrograms, // Update with modified programs object
          },
        };
      }
    } else {
      payload = {
        choices: {
          ...programs.workProcess.choices,
          programs: {
            ...programs.workProcess.choices.programs,
            [formData.name]: {
              width: Number(formData.width),
              height: Number(formData.height),
              planks: Number(formData.planks),
              columns: Number(formData.columns),
            },
          },
        },
      };
    }

    await addOrEditProgram(programs?.workProcess?.id, companyId, payload);

    handleClose();
    fetchData();
  };

  const handleModalChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleEditItem = (row) => {
    setFormData({
      isEdit: true,
      name: get(row, 'original.name', ''),
      width: get(row, 'original.width', ''),
      height: get(row, 'original.height', ''),
      planks: get(row, 'original.planks', ''),
      columns: get(row, 'original.columns', ''),
    });

    setModal((prevState) => ({
      ...prevState,
      isOpen: true,
    }));
  };

  const handleShowDeleteDialog = (row) => {
    setDeleteData(row);
    setShowConfirmationDialog(true);
  };

  const handleDeleteItem = async () => {
    const { name } = deleteData?.original;

    setModal((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const updatedChoices = { ...programs.workProcess.choices };

    if (updatedChoices.programs && name) {
      updatedChoices.programs = Object.fromEntries(
        Object.entries(updatedChoices.programs).filter(([key]) => key !== name),
      );

      const payload = {
        choices: updatedChoices,
      };

      await addOrEditProgram(programs.workProcess.id, companyId, payload);

      handleClose();
      fetchData();
    }
    setShowConfirmationDialog(false);
  };

  return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            <Button type="add" onClick={openProgramModal} disabled={isReadOnly}>
            {t('page_content.upload.step_four.add_new_program')}
            </Button>
            </div>

            <Table
              columns={[
                {
                  Header: () => <span>{t('page_content.upload.step_four.name')}</span>,
                  accessor: 'name',
                  Cell: (row) => (row?.value || '-'),
                },
                {
                  Header: () => <span>{t('page_content.upload.step_four.width')}</span>,
                  accessor: 'width',
                  Cell: (row) => (row?.value || '-'),
                },
                {
                  Header: () => <span>{t('page_content.upload.step_four.height')}</span>,
                  accessor: 'height',
                  Cell: (row) => (row?.value || '-'),
                },
                {
                  Header: () => <span>{t('page_content.upload.step_four.planks')}</span>,
                  accessor: 'planks',
                  Cell: (row) => (row?.value || '-'),
                },
                {
                  Header: () => <span>{t('page_content.upload.step_four.columns')}</span>,
                  accessor: 'columns',
                  Cell: (row) => (row?.value || '-'),
                },
                {
                  Header: () => '',
                  Cell: (row) => <Button disabled={isReadOnly} className="remove-btn" onClick={(e) => handleEditItem(row, e)}><IconEdit width="12px" height="12px" /></Button>,
                },
                {
                  Header: () => '',
                  Cell: (row) => <Button type="delete" disabled={isReadOnly} className="remove-btn" onClick={(e) => handleShowDeleteDialog(row, e)}><IconRemove width="12px" height="12px" /></Button>,
                },
              ]}
              data={programs.data}
              showPagination={false}
              sortable
              minRows={0}
              noDataText=" "
              style={{ userSelect: 'text' }}
              manual={false}
              defaultPageSize={50}
              loading={programs.isLoading}
              selectedRow={null}
            />

            <Modal
              isOpen={modal.isOpen}
              handleClose={handleClose}
              handleSave={handleSave}
              title="Add new program"
            >
                {
                    modal.isLoading ?
                    <ContentLoader /> :
                    <div className="modal_container">
                    <div className="modal_row">
                        <div className="modal_row">
                            <div className="left_text">{t('page_content.upload.step_four.name')}</div>
                            <div className="right_input">
                                <input disabled={formData?.isEdit} type="text" onChange={(e) => handleModalChange('name', e.target.value)} value={formData.name || ''} />
                            </div>
                        </div>
                    </div>

                    <div className="modal_row">
                        <div className="modal_row">
                            <div className="left_text">{t('page_content.upload.step_four.width')}</div>
                            <div className="right_input">
                                <input type="number" onChange={(e) => handleModalChange('width', e.target.value)} value={formData.width || ''} />
                            </div>
                        </div>
                    </div>

                    <div className="modal_row">
                        <div className="modal_row">
                            <div className="left_text">{t('page_content.upload.step_four.height')}</div>
                            <div className="right_input">
                                <input type="number" onChange={(e) => handleModalChange('height', e.target.value)} value={formData.height || ''} />
                            </div>
                        </div>
                    </div>

                    <div className="modal_row">
                        <div className="modal_row">
                            <div className="left_text">{t('page_content.upload.step_four.planks')}</div>
                            <div className="right_input">
                                <input type="number" onChange={(e) => handleModalChange('planks', e.target.value)} value={formData.planks || ''} />
                            </div>
                        </div>
                    </div>

                    <div className="modal_row">
                        <div className="modal_row">
                            <div className="left_text">{t('page_content.upload.step_four.columns')}</div>
                            <div className="right_input">
                                <input type="number" onChange={(e) => handleModalChange('columns', e.target.value)} value={formData.columns || ''} />
                            </div>
                        </div>
                    </div>
                </div>
                }
            </Modal>
            <ConfirmationModal
              itemName={deleteData?.original?.name}
              showModal={showConfirmationDialog}
              handleCloseModal={() => setShowConfirmationDialog(false)}
              handleConfirmModal={handleDeleteItem}
              type="warning"
            />
        </div>
  );
};

Programs.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default Programs;
