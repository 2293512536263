import api from 'helpers/api';
import { Notification } from 'shared';

export const addCategory = async (data) => {
  const url = '/api/v1/surveys/categories/';
  try {
    const res = await api.post(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while adding category', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const updateCategory = async (id, data, companyId) => {
  const url = `/api/v1/surveys/categories/${id}/?company=${companyId}`;
  try {
    const res = await api.put(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while adding category', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteCategory = async (id, companyId) => {
  const url = `/api/v1/surveys/categories/${id}/?company=${companyId}`;
  try {
    const res = await api.delete(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while deleting category', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getForms = async (companyId, filters = '') => {
  const url = `/api/v1/surveys/surveys/?limit=15&company=${companyId}${filters}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting forms', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedForms = async (url) => {
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting forms', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const saveNewForm = async (data) => {
  const url = '/api/v1/surveys/surveys/';
  try {
    const res = await api.post(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving form', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const saveFormQuestions = async (data) => {
  const url = '/api/v1/surveys/questions/';
  try {
    const res = await api.post(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving form questions', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteQuestion = async (id, surveyId) => {
  const url = `/api/v1/surveys/questions/${id}/?survey=${surveyId}`;
  try {
    const res = await api.delete(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while deleting question', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const saveFormQuestionOptions = async (data) => {
  const url = '/api/v1/surveys/question_options/';
  try {
    const res = await api.post(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving form answers', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getQuestions = async (formId) => {
  const url = `/api/v1/surveys/questions/?survey=${formId}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting questions', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getQuestionOptions = async (questionId) => {
  const url = `/api/v1/surveys/question_options/?question=${questionId}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting question options', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const editFormQuestions = async (id, data, formId) => {
  const url = `/api/v1/surveys/questions/${id}/?survey=${formId}`;
  try {
    const res = await api.put(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving form questions', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const editFormQuestionOptions = async (id, data, questionId) => {
  const url = `/api/v1/surveys/question_options/${id}/?question=${questionId}`;
  try {
    const res = await api.put(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving form answers', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const editFormDataValues = async (id, companyId, data) => {
  const url = `/api/v1/surveys/surveys/${id}/?company=${companyId}`;
  try {
    const res = await api.put(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving form', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getDepartments = async (locationId) => {
  const url = `/api/v1/departments/?location=${locationId}&limit=9999&order_by=name`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching departments', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getCategories = async (companyId, filters = '') => {
  const url = `/api/v1/surveys/categories/?company=${companyId}&limit=30${filters}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting categories', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAllCategories = async (companyId) => {
  const url = `/api/v1/surveys/categories/?company=${companyId}&limit=9999&order_by=name`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting categories', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedCategories = async (url) => {
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting categories', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteQuestionOptions = (id, questionId) => {
  return api.delete(`/api/v1/surveys/question_options/${id}/?question=${questionId}`)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return 200;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const saveDepartmentSurveys = async (data) => {
  const url = '/api/v1/surveys/assign_survey/';
  try {
    const res = await api.post(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving department surveys', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const saveWorkerSurveys = async (data) => {
  const url = '/api/v1/surveys/worker_surveys/';
  try {
    const res = await api.post(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while saving worker surveys', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAllWorkers = async (locationId, companyId, filter = '') => {
  const urlString = `/api/v1/workforce/workers/?limit=9999&company=${companyId}&location=${locationId}&order_by=name${filter}`;
  try {
    const res = await api.get(urlString);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching workers', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getWorkerSurveys = async (surveyId) => {
  const urlString = `/api/v1/surveys/worker_surveys/?survey=${surveyId}`;
  try {
    const res = await api.get(urlString);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching survey workers', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getWorkerDepartments = async (surveyId) => {
  const urlString = `/api/v1/surveys/worker_surveys/?survey=${surveyId}`;
  try {
    const res = await api.get(urlString);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching survey workers', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getFilledForms = async (companyId, filters = '') => {
  const url = `/api/v1/surveys/worker_surveys/?limit=15&company=${companyId}${filters}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting forms', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedFilledForms = async (url) => {
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting forms', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getWorkerAnswers = async (surveyId, workerId) => {
  const url = `/api/v1/surveys/worker_answers/?worker_survey=${surveyId}&worker=${workerId}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting worker answers', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const updateWorkerSurvey = async (workerSurveyId, data) => {
  const url = `/api/v1/surveys/worker_surveys/${workerSurveyId}/`;
  try {
    const res = await api.put(url, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting worker answers', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getSurveyFiles = async (companyId, workerSurveyId) => {
  const url = `/api/v1/surveys/survey_files/?company=${companyId}&worker_survey=${workerSurveyId}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting worker answers', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getTemplateOptionGroups = async (companyId) => {
  const url = `/api/v1/surveys/template_option_groups/?company=${companyId}&limit=9999&order_by=-name`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting template option groups', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getTemplateOptions = async (groupId) => {
  const url = `/api/v1/surveys/template_options/?group=${groupId}&limit=9999&order_by=-order`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting template options', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
