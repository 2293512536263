/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  IconAutomaticReports,
  IconBarChart,
  IconFactory,
  IconLayers,
  IconDevice,
  IconEvent,
  IconHome,
  IconAsset,
  IconGauge,
  IconUpload,
  IconOperatorView,
  IconPhotoLibrary,
  IconWorkforce,
  IconMaintenance,
  IconShifts,
  IconWarehouse,
  IconMicroPlanning,
  IconProductionRecords,
  IconReportsForSalary,
  Icon5S,
  IconProjects,
  IconDMS,
  IconOEE,
} from 'shared/Icons';
import { Notification, AccessControlledNavLink } from 'shared';
import api from 'helpers/api';
import { checkAccess, checkModules } from 'industry/helpers';
import { getCompanyData } from './actions';
import './styles.scss';

class IndustryMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProjectBased: false,
      permissionList: {},
      modules: {
        Upload: false,
        'Plant floor': false,
        Orders: false,
        'Operator view': false,
        Events: false,
        Efficiency: false,
        Devices: false,
        Assets: false,
        'Quality Inspection': false,
        Workforce: false,
        'Maintenance module': false,
        'Reports for salary': false,
        'Automatic Reports': false,
        Dashboards: false,
        Warehouse: false,
        'Micro Planning': false,
        'Production records': false,
        '5S': false,
        Projects: false,
        DMS: false,
        OEE: false,
        'Human resources': false,
      },
    };
  }

  componentDidMount() {
    const { companyId } = this.props;

    getCompanyData(companyId)
      .then((res) => {
        const project_based = !!(res && res.data && res.data.config && res.data.config.project_based);
        this.setState({
          isProjectBased: project_based,
        });
      });

    this.getPermissionList();

    checkModules(companyId)
      .then((re) => {
        const moduleAccess = re.data;
        const { modules } = this.state;
        Object.keys(modules).forEach((key) => {
          const moduleExists = moduleAccess ? moduleAccess.find((m) => m.name === key) : [];
          if (moduleExists) {
            modules[key] = moduleExists.is_active;
          }
        });
        this.setState({
          modules,
        });
      });
  }

  getPermissionList = () => {
    const { companyId } = this.props;

    api.get(`/api/v1/permission_list/?company=${companyId}`)
      .then((res) => {
        this.setState({
          permissionList: res.data || {},
        });
      })
      .catch((error) => Notification('error', 'An error occured while fetching permission list', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.'));
  }

  getPermissionForModule = (module) => {
    const { locationId, companyId } = this.props;
    const { permissionList } = this.state;

    let path = `/${companyId}/industry/location/${locationId}`;

    if (module) {
      path += `/${module}`;
    }

    return permissionList[path] ? permissionList[path] : 0;
  }

  handleUserNavigation = (e) => {
    e.preventDefault();
    const { companyId, history } = this.props;
    const source = e.target || e.srcElement;

    const getHref = (el) => {
      if (el.href) {
        return el.href;
      }
      return getHref(el.parentElement);
    };
    const url = new URL(getHref(source));

    let resource;
    if (url.pathname.includes('parquet-counting')) {
      resource = url.pathname.replace('parquet-counting', 'upload');
    } else {
      resource = url.pathname;
    }

    checkAccess(resource, companyId)
      .then((re) => {
        const data = re.data;
        const access = data.access;
        if (access === 0) {
          Notification('error', 'Access denied', 'You do not have the authorization to access this resource.');
        } else if (access === 10 || access === 5) {
          // full access
          history.push(url.pathname, { forced: true });
        } else if (access === 1) {
          // read only
          if (!window.resourceReadOnly) {
            window.resourceReadOnly = [];
          }

          window.resourceReadOnly.push(resource);
          history.push(url.pathname, { forced: true });
        }
      });
  }

  render() {
    const {
      companyId,
      locationId,
      userIsCompanyAdmin,
      t,
      currentUser,
      showSidebar,
    } = this.props;

    const {
      modules,
      isProjectBased,
      permissionList,
    } = this.state;

    return (
      <nav className={`industry-menu ${!showSidebar && 'mobile-sidebar'}`} style={{ boxShadow: '3px 0 5px -2px rgb(0 0 0 / 10%)' }}>
        <AccessControlledNavLink
          companyId={companyId}
          locationId={locationId}
          modules={modules}
          permissionList={permissionList}
          module=""
          moduleUrlName=""
          linkTo={`/${companyId}/industry/location/${locationId}`}
          navText={t('sidebar.home')}
          checkAccessToModule={false}
          icon={<IconHome height="25px" width="25px" />}
        />

        <AccessControlledNavLink
          companyId={companyId}
          locationId={locationId}
          modules={modules}
          permissionList={permissionList}
          module=""
          moduleUrlName=""
          linkTo={`/${companyId}/industry/location/${locationId}/shifts`}
          navText={t('sidebar.shifts')}
          checkAccessToModule={false}
          icon={<IconShifts height="24px" width="24px" />}
        />

        <AccessControlledNavLink
          companyId={companyId}
          locationId={locationId}
          modules={modules}
          permissionList={permissionList}
          module="Events"
          moduleUrlName="events"
          linkTo={`/${companyId}/industry/location/${locationId}/events`}
          navText={t('sidebar.events')}
          checkAccessToModule
          icon={<IconEvent height="28px" width="28px" />}
        />

        {
          isProjectBased &&
          <AccessControlledNavLink
            companyId={companyId}
            locationId={locationId}
            modules={modules}
            permissionList={permissionList}
            module="Projects"
            moduleUrlName="projects"
            linkTo={`/${companyId}/industry/location/${locationId}/projects`}
            navText={t('sidebar.projects')}
            checkAccessToModule
            icon={<IconProjects height="30px" width="30px" />}
          />
        }

        <AccessControlledNavLink
          companyId={companyId}
          locationId={locationId}
          modules={modules}
          permissionList={permissionList}
          module="Orders"
          moduleUrlName="orders"
          linkTo={`/${companyId}/industry/location/${locationId}/orders`}
          navText={t('sidebar.orders')}
          checkAccessToModule
          icon={<IconLayers height="28px" width="28px" />}
        />

        <AccessControlledNavLink
          companyId={companyId}
          locationId={locationId}
          modules={modules}
          permissionList={permissionList}
          module="DMS"
          moduleUrlName="dms"
          linkTo={`/${companyId}/industry/location/${locationId}/dms`}
          navText={t('sidebar.dms')}
          checkAccessToModule
          icon={<IconDMS height="30px" width="30px" />}
        />

        <AccessControlledNavLink
          companyId={companyId}
          locationId={locationId}
          modules={modules}
          permissionList={permissionList}
          module="OEE"
          moduleUrlName="oee"
          linkTo={`/${companyId}/industry/location/${locationId}/oee`}
          navText={t('sidebar.oee')}
          checkAccessToModule
          icon={<IconOEE height="30px" width="30px" />}
        />

        <AccessControlledNavLink
          companyId={companyId}
          locationId={locationId}
          modules={modules}
          permissionList={permissionList}
          module="Efficiency"
          moduleUrlName="efficiency"
          linkTo={`/${companyId}/industry/location/${locationId}/efficiency`}
          navText={t('sidebar.efficiency')}
          checkAccessToModule
          icon={<IconGauge height="28px" width="28px" />}
        />

        <AccessControlledNavLink
          companyId={companyId}
          locationId={locationId}
          modules={modules}
          permissionList={permissionList}
          module="Devices"
          moduleUrlName="devices"
          linkTo={`/${companyId}/industry/location/${locationId}/devices`}
          navText={t('sidebar.devices')}
          checkAccessToModule
          icon={<IconDevice height="30px" width="30px" />}
        />

        <AccessControlledNavLink
          companyId={companyId}
          locationId={locationId}
          modules={modules}
          permissionList={permissionList}
          module="Assets"
          moduleUrlName="assets"
          linkTo={`/${companyId}/industry/location/${locationId}/assets`}
          navText={t('sidebar.assets')}
          checkAccessToModule
          icon={<IconAsset height="30px" width="30px" />}
        />

        <AccessControlledNavLink
          companyId={companyId}
          locationId={locationId}
          modules={modules}
          permissionList={permissionList}
          module="Dashboards"
          moduleUrlName="dashboards"
          linkTo={`/${companyId}/industry/location/${locationId}/dashboards`}
          navText={t('sidebar.dashboards')}
          checkAccessToModule
          icon={<IconBarChart height="28px" width="28px" />}
        />

        <AccessControlledNavLink
          companyId={companyId}
          locationId={locationId}
          modules={modules}
          permissionList={permissionList}
          module="Operator view"
          moduleUrlName="operator"
          linkTo={`/${companyId}/industry/location/${locationId}/operator`}
          navText={t('sidebar.operator_view')}
          checkAccessToModule
          icon={<IconOperatorView color="#555" height="28px" width="28px" />}
        />

        <AccessControlledNavLink
          companyId={companyId}
          locationId={locationId}
          modules={modules}
          permissionList={permissionList}
          module="Upload"
          moduleUrlName="upload"
          linkTo={`/${companyId}/industry/location/${locationId}/upload`}
          navText={t('sidebar.color_coding')}
          checkAccessToModule
          icon={<IconUpload color="#555" height="30px" width="30px" />}
        />

        <AccessControlledNavLink
          companyId={companyId}
          locationId={locationId}
          modules={modules}
          permissionList={permissionList}
          module="Quality Inspection"
          moduleUrlName="quality-inspection"
          linkTo={`/${companyId}/industry/location/${locationId}/quality-inspection`}
          navText={t('sidebar.product_library')}
          checkAccessToModule
          icon={<IconPhotoLibrary color="#555" height="30px" width="30px" />}
        />

        <AccessControlledNavLink
          companyId={companyId}
          locationId={locationId}
          modules={modules}
          permissionList={permissionList}
          module="Workforce"
          moduleUrlName="workforce-management"
          linkTo={`/${companyId}/industry/location/${locationId}/workforce-management`}
          navText={t('sidebar.workforce_management')}
          checkAccessToModule
          icon={<IconWorkforce height="30px" width="30px" />}
        />

        {
          // eslint-disable-next-line eqeqeq
          modules['Plant floor'] && currentUser && currentUser.is_admin && ((companyId == '9') || (companyId == '10') || (companyId == '11')) ?
            <NavLink
              exact
              to={`/${companyId}/industry/location/${locationId}/factory-floor-jm`}
              activeClassName="active"
              onClick={this.handleUserNavigation}
            >
              <span>
                <IconFactory
                  color="#555"
                  height="30px"
                  width="30px"
                />
              </span>
              {t('sidebar.plant_floor')}
            </NavLink> :
            modules['Plant floor'] && currentUser && currentUser.is_admin ?
              <NavLink
                exact
                to={`/${companyId}/industry/location/${locationId}/factory-floor`}
                activeClassName="active"
                onClick={this.handleUserNavigation}
              >
                <span>
                  <IconFactory
                    color="#555"
                    height="30px"
                    width="30px"
                  />
                </span>
                {t('sidebar.plant_floor')}
              </NavLink> :
              modules['Plant floor'] &&
                // eslint-disable-next-line eqeqeq
                ((companyId == '9') || (companyId == '10') || (companyId == '11')) ?
                <NavLink
                  exact
                  to={`/${companyId}/industry/location/${locationId}/factory-floor-jm`}
                  activeClassName="active"
                  onClick={this.handleUserNavigation}
                >
                  <span>
                    <IconFactory
                      color="#555"
                      height="30px"
                      width="30px"
                    />
                  </span>
                  {t('sidebar.plant_floor')}
                </NavLink> :
                modules['Plant floor'] && (window.userIsCompanyAdmin || userIsCompanyAdmin) ?
                  <NavLink
                    exact
                    to={`/${companyId}/industry/location/${locationId}/factory-floor`}
                    activeClassName="active"
                    onClick={this.handleUserNavigation}
                  >
                    <span>
                      <IconFactory
                        color="#555"
                        height="30px"
                        width="30px"
                      />
                    </span>
                    {t('sidebar.plant_floor')}
                  </NavLink> :
                  modules['Plant floor'] && this.getPermissionForModule('factory-floor') !== 0 ?
                    <NavLink
                      exact
                      to={`/${companyId}/industry/location/${locationId}/factory-floor`}
                      activeClassName="active"
                      onClick={this.handleUserNavigation}
                    >
                      <span>
                        <IconFactory
                          color="#555"
                          height="30px"
                          width="30px"
                        />
                      </span>
                      {t('sidebar.plant_floor')}
                    </NavLink> : ''
        }

        <AccessControlledNavLink
          modules={modules}
          permissionList={permissionList}
          module="Maintenance module"
          moduleUrlName="maintenance-module"
          linkTo={`/${companyId}/industry/location/${locationId}/maintenance-module`}
          navText={t('sidebar.maintenance_module')}
          checkAccessToModule
          icon={<IconMaintenance height="30px" width="30px" />}
        />

        <AccessControlledNavLink
          modules={modules}
          permissionList={permissionList}
          module="Reports for salary"
          moduleUrlName="reports-for-salary"
          linkTo={`/${companyId}/industry/location/${locationId}/reports-for-salary`}
          navText={t('sidebar.reports_for_salary')}
          checkAccessToModule
          icon={<IconReportsForSalary height="30px" width="30px" />}
        />

        <AccessControlledNavLink
          modules={modules}
          permissionList={permissionList}
          module="Automatic Reports"
          moduleUrlName="automatic-reports"
          linkTo={`/${companyId}/industry/location/${locationId}/automatic-reports`}
          navText={t('sidebar.automatic_reports')}
          checkAccessToModule
          icon={<IconAutomaticReports height="30px" width="30px" />}
        />

        <AccessControlledNavLink
          modules={modules}
          permissionList={permissionList}
          module="Warehouse"
          moduleUrlName="warehouse"
          linkTo={`/${companyId}/industry/location/${locationId}/warehouse`}
          navText={t('sidebar.warehouse')}
          checkAccessToModule
          icon={<IconWarehouse height="30px" width="30px" />}
        />

        <AccessControlledNavLink
          modules={modules}
          permissionList={permissionList}
          module="Micro Planning"
          moduleUrlName="micro-planning"
          linkTo={`/${companyId}/industry/location/${locationId}/micro-planning`}
          navText={t('sidebar.micro_planning')}
          checkAccessToModule
          icon={<IconMicroPlanning height="30px" width="30px" />}
        />

        <AccessControlledNavLink
          modules={modules}
          permissionList={permissionList}
          module="Production records"
          moduleUrlName="production-records"
          linkTo={`/${companyId}/industry/location/${locationId}/production-records`}
          navText={t('sidebar.production_records')}
          checkAccessToModule
          icon={<IconProductionRecords height="30px" width="30px" />}
        />

        <AccessControlledNavLink
          modules={modules}
          permissionList={permissionList}
          module="5S"
          moduleUrlName="5S"
          linkTo={`/${companyId}/industry/location/${locationId}/5S`}
          navText="5S"
          checkAccessToModule
          icon={<Icon5S height="30px" width="30px" />}
        />

        <AccessControlledNavLink
          modules={modules}
          permissionList={permissionList}
          module="Human resources"
          moduleUrlName="human-resources"
          linkTo={`/${companyId}/industry/location/${locationId}/human-resources`}
          navText={t('sidebar.human_resources')}
          checkAccessToModule
          icon={<IconWorkforce height="30px" width="30px" />}
        />
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userIsCompanyAdmin: state.app.userIsCompanyAdmin,
    company: state.app.company,
    currentUser: state.currentUser,
  };
};

IndustryMenu.propTypes = {
  history: PropTypes.object.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  userIsCompanyAdmin: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  showSidebar: PropTypes.bool.isRequired,
};

export default withRouter(connect(mapStateToProps, null)((withTranslation()(IndustryMenu))));
