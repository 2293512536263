import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props} viewBox="0 0 512 512">
  <path d="M148 65.5502H97.5001V221.763H148V65.5502Z" />
  <path d="M324.001 178.721V95.8052H269V189.5C281.057 182.839 309.367 179.085 324.001 178.721Z" />
  <path d="M390.099 205.971V126.06H349.647V181.842C365.272 186.131 379.185 194.6 390.099 205.971Z" />
  <path d="M459.825 0H35.9999C35.9999 0 35.9997 15.051 35.9997 33.618L35.9999 144.5V257.164L35.9997 287.418H240.004C238.487 280.935 237.676 274.187 237.676 267.249C237.676 263.839 237.876 260.475 238.254 257.164H73.5002V30.2549H422.877V257.163H414.208C414.584 260.475 414.785 263.839 414.785 267.249C414.785 274.187 413.974 280.935 412.457 287.418H426.206C444.772 287.418 459.825 287.418 459.825 287.418V33.618C459.824 15.0516 459.825 0 459.825 0Z" />
  <path d="M420.324 446.856V384.958C420.324 366.391 405.272 351.34 386.706 351.34H265.755C247.188 351.34 232.137 366.391 232.137 384.958V444.765H90.7471L90.7474 478.382C90.7474 496.949 108.284 512.001 126.851 512.001H408.629C460 512.001 460 465.423 460 446.856H420.324Z" />
  <path d="M326.231 344.598C368.95 344.598 403.58 309.968 403.58 267.249C403.58 224.53 368.95 189.9 326.231 189.9C283.513 189.9 248.882 224.53 248.882 267.249C248.882 309.968 283.513 344.598 326.231 344.598Z" />
  <path d="M242 221.765V143.658H168V221.765H208.5H242Z" />  </Base>);
