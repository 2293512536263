import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class WarehouseDropdown extends Component {
  render() {
    const {
      changeWarehouse,
      selectedWarehouse,
      listOfWarehouses,
      t,
    } = this.props;
    return (
      <React.Fragment>
        <select onChange={changeWarehouse} value={selectedWarehouse} className="filters_warehouse_dropdown">
          <option value="" disabled>{t('page_content.warehouse.warehouse_filter_placeholder')}</option>
          <option value="all" selected={selectedWarehouse === 'all'}>{t('page_content.warehouse.warehouse_all_select')}</option>
          {listOfWarehouses.map((warehouse) => (
            <option key={warehouse.id} value={warehouse.id}>
              {warehouse.name}
            </option>
          ))}
        </select>
      </React.Fragment>
    );
  }
}

WarehouseDropdown.propTypes = {
  changeWarehouse: PropTypes.func.isRequired,
  selectedWarehouse: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  listOfWarehouses: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(WarehouseDropdown);
