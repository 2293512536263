import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const PageContent = ({ children, isSettings }) => {
  return (
    <div className={`page-content${isSettings ? '-settings' : ''}`}>
      {children}
    </div>
  );
};

PageContent.propTypes = {
  children: PropTypes.any,
  isSettings: PropTypes.bool,
};

export default PageContent;
