/* eslint-disable */
import api from 'helpers/api';
import apiAuthorize from 'helpers/apiAuth';
import * as actionTypes from './actionTypes';
import localStorageTokenKey from './../app/AuthContainer/constants';

export const getCurrentUserSuccess = (curentUser) => {
  return {
    type: actionTypes.getCurrentUserSuccess,
    payload: curentUser,
  };
};

export const getCurrentUserError = () => {
  return {
    type: actionTypes.getCurrentUserError,
  };
};

export const getCurrentUser = (companyId = '') => (dispatch) => {
  return new Promise((resolve, reject) => {
    let filters = '';
    if (companyId) {
      filters += `?company=${companyId}`
    }
    return api.get(`/api/v1/me/${filters}`)
      .then((res) => {
        dispatch(getCurrentUserSuccess(res.data));
        resolve(res.data);
      })
      .catch(() => {
        dispatch(getCurrentUserError());
        reject(null);
      });
  });
};

export const login = (payload) => {
  return new Promise((resolve, reject) => {
    return apiAuthorize.post('/api-token-auth/', payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logout = () => () => {
  localStorage.removeItem(localStorageTokenKey);
  window.location.href = '/login';
  return api.get('/api/v1/logout');
};

export const forgotPassword = (payload) => {
  return new Promise((resolve, reject) => {
    return apiAuthorize.post('api/v1/send_token/', payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resetPassword = (payload) => {
  let reset_payload = {};
  if (payload) {
    reset_payload = {
      email: payload.email,
      token: payload.token,
      password: payload.new_password,
    };
  }

  return new Promise((resolve, reject) => {
    return apiAuthorize.post('/api/v1/validate_and_change/', reset_payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
