/* eslint-disable react/no-unescaped-entities */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import axios from 'axios';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import {
  Button,
} from 'shared';
// import { defaultDateFormat } from 'shared/constants';
// import { timeRangeEnum, timeRangeConfig } from 'shared/DatePicker/constants';
import { getData, getProductionData } from './api';
import { excludeWidgetTypesFromCSVDownloadList } from './constants';
import './style.scss';
import { defaultDateFormat } from '../../constants';
import { getLocale } from '../../DatePicker/constants';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

class CSVModal extends Component {
  constructor(props) {
    super(props);

    const initState = {
      range: { start: moment(), end: moment() },
      rangeSelected: 'day',
      isLoading: false,
    };

    if (props.dateRange.label === 'custom') {
      initState.rangeSelected = 'custom';
      initState.range.start = props.dateRange.start;
      initState.range.end = props.dateRange.end;
    } else if (props.dateRange.label.indexOf('week') >= 0) {
      initState.rangeSelected = 'week';
    } else if (props.dateRange.label.indexOf('month') >= 0) {
      initState.rangeSelected = 'month';
    } else if (props.dateRange.label.indexOf('year') >= 0) {
      initState.rangeSelected = 'year';
    } else {
      initState.rangeSelected = 'day';
    }

    this.state = initState;

    this.updateDate(initState.rangeSelected);
  }

  getDatapointMeta(
    {
      metric,
      group_by: groupBy,
      operation,
    },
    daterange,
  ) {
    const to = daterange.end;
    const from = daterange.start;

    return {
      id: (metric && metric.id) || metric,
      daterange,
      groupBy,
      operation,
      axiosSource: source,
      from: from && from.toISOString(),
      to: from && to.toISOString(),
    };
  }

  getCSV = (widget, title) => {
    this.setState({
      isLoading: true,
    });
    const {
      widgetsData,
    } = this.props;

    const { widgets } = widgetsData;
    const daterange = this.state.range;
    const wData = widgets.find((w) => w.widgetData.find((wD) => wD.id === widget.id));
    if (!wData) {
      this.setState({ isLoading: false });
      return;
    }
    const type = wData.type;
    const adjusted_value = wData.settings.adjusted_value;
    // let adjusted_value;
    // if (adjustedValue) {
    //   if (widget.metric.id === adjustedValue.metricId) {
    //     adjusted_value = adjustedValue.value;
    //   }
    // }

    switch (type) {
      case 'gauge':
        getData(Object.assign(
          this.getDatapointMeta(widget, daterange),
          { limit: 1 },
          { adjusted_value },
        )).then((re) => {
          const hiddenElement = document.createElement('a');
          hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(re)}`;
          hiddenElement.target = '_blank';
          hiddenElement.download = `${title}.csv`;
          hiddenElement.click();
          this.setState({ isLoading: false });
        });
        break;
      case 'line':
      case 'step':
      case 'scatter':
      case 'histogram':
      case 'composed':
        if (widget.metric && widget.metric.data_storage === 'productiondata') {
          getProductionData(
            Object.assign(
              this.getDatapointMeta(widget, daterange),
            ),
          ).then((re) => {
            const hiddenElement = document.createElement('a');
            hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(re)}`;
            hiddenElement.target = '_blank';
            hiddenElement.download = `${title}.csv`;
            hiddenElement.click();
            this.setState({ isLoading: false });
          });
        } else {
          getData(
            Object.assign(
              this.getDatapointMeta(widget, daterange),
              { adjusted_value },
            ),
          ).then((re) => {
            const hiddenElement = document.createElement('a');
            hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(re)}`;
            hiddenElement.target = '_blank';
            hiddenElement.download = `${title}.csv`;
            hiddenElement.click();
            this.setState({ isLoading: false });
          });
        }
        break;
      case 'map':
        getData({ ...this.getDatapointMeta(widget, daterange), limit: 500, adjusted_value });
        break;
      case 'scalar':
        getData({

          ...this.getDatapointMeta(widget, daterange),
          limit: 1,
        }, { adjusted_value }).then((re) => {
          const hiddenElement = document.createElement('a');
          hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(re)}`;
          hiddenElement.target = '_blank';
          hiddenElement.download = `${title}.csv`;
          hiddenElement.click();
          this.setState({ isLoading: false });
        });
        break;
      case 'pie':
        getData(
          {

            ...this.getDatapointMeta(widget, daterange),
            limit: 1,
            adjusted_value,
          },
        ).then((re) => {
          const hiddenElement = document.createElement('a');
          hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(re)}`;
          hiddenElement.target = '_blank';
          hiddenElement.download = `${title}.csv`;
          hiddenElement.click();
          this.setState({ isLoading: false });
        });
        break;
      case 'bar':
        if (widget.metric && widget.metric.data_storage === 'productiondata') {
          getProductionData(
            Object.assign(
              this.getDatapointMeta(widget, daterange),
            ),
          ).then((re) => {
            const hiddenElement = document.createElement('a');
            hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(re)}`;
            hiddenElement.target = '_blank';
            hiddenElement.download = `${title}.csv`;
            hiddenElement.click();
            this.setState({ isLoading: false });
          });
        } else {
          getData({ ...this.getDatapointMeta(widget, daterange), adjusted_value }).then((re) => {
            const hiddenElement = document.createElement('a');
            hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(re)}`;
            hiddenElement.target = '_blank';
            hiddenElement.download = `${title}.csv`;
            hiddenElement.click();
            this.setState({ isLoading: false });
          });
        }
        break;
      case 'area':
        if (widget.metric && widget.metric.data_storage === 'productiondata') {
          getProductionData(
            Object.assign(
              this.getDatapointMeta(widget, daterange),
            ),
          ).then((re) => {
            const hiddenElement = document.createElement('a');
            hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(re)}`;
            hiddenElement.target = '_blank';
            hiddenElement.download = `${title}.csv`;
            hiddenElement.click();
            this.setState({ isLoading: false });
          });
        } else {
          getData({ ...this.getDatapointMeta(widget, daterange), adjusted_value }).then((re) => {
            const hiddenElement = document.createElement('a');
            hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(re)}`;
            hiddenElement.target = '_blank';
            hiddenElement.download = `${title}.csv`;
            hiddenElement.click();
            this.setState({ isLoading: false });
          });
        }
        break;
      case 'table':
        getData({ ...this.getDatapointMeta(widget, daterange), adjusted_value }).then((re) => {
          const hiddenElement = document.createElement('a');
          hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(re)}`;
          hiddenElement.target = '_blank';
          hiddenElement.download = `${title}.csv`;
          hiddenElement.click();
          this.setState({ isLoading: false });
        });
        break;
      default:
        return null;
    }
  }

  updateDate = (range) => {
    const newState = this.state;
    if (range !== 'custom') {
      newState.range.start = moment().startOf(range);
      newState.range.end = moment().endOf(range);
    }
    newState.rangeSelected = range;
    this.setState(newState);
  }

  updateDateField = (field, newDate) => {
    const prevState = this.state;
    prevState.rangeSelected = 'custom';
    prevState.range[field] = newDate;
    this.setState(prevState);
  }

  render() {
    const {
      handleRequestClose,
      isOpen,
      widgetsData,
      t,
    } = this.props;
    const { isLoading } = this.state;

    const { widgets } = widgetsData;

    let datapoints = [];
    widgets
      .filter((w) => excludeWidgetTypesFromCSVDownloadList.includes(w.type) === false)
      .forEach((widget) => { datapoints = datapoints.concat(widget.widgetData); });

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={handleRequestClose}
        style={{ content: { padding: '0', top: '20px', left: '0', right: '0', bottom: 'initial' } }}
        shouldCloseOnOverlayClick={false}
      >
        <header className="modal-header">
          {t('page_content.dashboards.export_csv_modal.modal_title')}
          {
            isLoading &&
            <div
              className="csv-loader"
              style={{
                borderLeftColor: 'transparent',
              }}
            />
          }
        </header>
        <div className="csv-widget-modal">
          <div className="csv-widget-header">
            <div className="csv-widget-header-left">
              {this.state.rangeSelected !== 'custom' && <h3>{t('page_content.dashboards.export_csv_modal.selected_time_range')}:</h3>}
              {this.state.rangeSelected === 'custom' && <h3>{t('page_content.dashboards.export_csv_modal.select_time_range')}:</h3>}
              <div className="csv-range">
                {this.state.rangeSelected !== 'custom' &&
                  <Fragment>
                    {this.state.range !== null && this.state.range.start !== null &&
                      <div>
                        <div className="csv-range-container">
                          <input className="csv-range-display" value={moment(this.state.range.start).format(defaultDateFormat)} />
                        </div>
                      </div>}
                    {this.state.range !== null && this.state.range.end !== null &&
                      <div>
                        <div className="csv-range-container">
                          <input className="csv-range-display" value={moment(this.state.range.end).format(defaultDateFormat)} />
                        </div>
                      </div>}
                  </Fragment>}
                {this.state.rangeSelected === 'custom' &&
                  <Fragment>
                    {this.state.range && this.state.range.start &&
                      <ReactDatePicker
                        placeholderText="Select start date"
                        dateFormat="dd.MM.yyyy."
                        selected={moment(this.state.range.start, 'dd.MM.yyyy.').toDate()}
                        selectsStart
                        maxDate={moment(this.state.range.end).toDate()}
                        onChange={(val) => { this.updateDateField('start', val); }}
                        showTimeSelect={false}
                        disabledKeyboardNavigation
                        locale={getLocale(t)}
                      />}
                    {this.state.range && this.state.range.end &&
                      <ReactDatePicker
                        placeholderText="Select end date"
                        dateFormat="dd.MM.yyyy."
                        selected={moment(this.state.range.end, 'dd.MM.yyyy.').toDate()}
                        selectsEnd
                        minDate={moment(this.state.range.start).toDate()}
                        onChange={(val) => { this.updateDateField('end', val); }}
                        showTimeSelect={false}
                        disabledKeyboardNavigation
                        locale={getLocale(t)}
                      />}
                  </Fragment>}
              </div>
            </div>
            <div className="csv-widget-header-right">
              <h3>{t('page_content.dashboards.export_csv_modal.available_ranges')}:</h3>
              <div>
                <button className={`btn small plain ${(this.state.rangeSelected === 'custom') ? 'active' : ''}`} type="button" onClick={() => { this.updateDate('custom'); }}>{t('page_content.dashboards.export_csv_modal.custom_range')}</button>
                <button className={`btn small plain ${(this.state.rangeSelected === 'day') ? 'active' : ''}`} type="button" onClick={() => { this.updateDate('day'); }}>{t('page_content.dashboards.export_csv_modal.today')}</button>
                <button className={`btn small plain ${(this.state.rangeSelected === 'week') ? 'active' : ''}`} type="button" onClick={() => { this.updateDate('week'); }}>{t('page_content.dashboards.export_csv_modal.this_week')}</button>
                <button className={`btn small plain ${(this.state.rangeSelected === 'month') ? 'active' : ''}`} type="button" onClick={() => { this.updateDate('month'); }}>{t('page_content.dashboards.export_csv_modal.this_month')}</button>
                <button className={`btn small plain ${(this.state.rangeSelected === 'year') ? 'active' : ''}`} type="button" onClick={() => { this.updateDate('year'); }}>{t('page_content.dashboards.export_csv_modal.this_year')}</button>
              </div>
            </div>
          </div>
          <ul className="csv-widget-list">
            {datapoints.map((d) => {
              return (
                <li className="csv-widget-list-item">
                  <button className="btn small plain csv-widget-download-btn" type="button" onClick={() => { this.getCSV(d, d.label); }}>{t('page_content.dashboards.export_csv_modal.get_csv_button')}</button>{d.label}
                </li>
              );
            })}
          </ul>
        </div>
        <footer className="modal-footer">
          <Button
            onClick={handleRequestClose}
          >
            Close
          </Button>
        </footer>
      </Modal>
    );
  }
}

CSVModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dateRange: PropTypes.object.isRequired,
  handleRequestClose: PropTypes.func.isRequired,
  widgetsData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dateRange: get(state, 'app.daterange'),
});

// export default CSVModal;
export default connect(mapStateToProps)(withTranslation()(CSVModal));
