/* eslint-disable no-mixed-operators */
import React from 'react';
import './styles.scss';

let t;
class JohnsonMattheyPlantFloor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steel: '',
      hydr_el: '',
      hydr_co2: '',
      welding_el: '',
      welding_co2: '',
      furnace_el: '',
      furnace_gas: '',
      furnace_co2: '',
      wrapping_el: '',
      wrapping_co2: '',
      forming_el: '',
      forming_co2: '',
      pgms_co2: '',
      total_co2: '',
    };
  }

  componentDidMount() {
    t = Math.random() * (100 - 1) + 1;

    this.update();

    this.dummyInterval = setInterval(this.update, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.dummyInterval);
  }

  getRand = (min, max) => {
    return Math.random() * (max - min) + min;
  }

  update = () => {
    t += 1;

    const steel = `${(t * 2 * 11.1).toFixed(2)} kg`;

    const hydr_el = `${(t * this.getRand(0.008, 0.015)).toFixed(2)} kWh`;

    const hydr_co2 = `${(parseFloat(hydr_el) * 0.309).toFixed(2)} kg`;

    const welding_el = `${(t * this.getRand(0.002, 0.0027)).toFixed(2)} kWh`;
    const welding_co2 = `${(parseFloat(welding_el) * 0.309).toFixed(2)} kg`;

    const pgms_co2 = `${(t * (33 + 25) * this.getRand(6, 7) / 1000).toFixed(2)} t`;

    const furnace_el = `${(t * this.getRand(0.0002, 0.00027)).toFixed(2)} kWh`;
    const furnace_gas = `${(t * this.getRand(0.005, 0.006) / 6).toFixed(2)} m3`;
    const furnace_co2 = `${(t * 16.8 / 6).toFixed(2)} kg`;

    const wrapping_el = `${(t * this.getRand(0.005, 0.01)).toFixed(2)} kWh`;
    const wrapping_co2 = `${(parseFloat(wrapping_el) * 0.309).toFixed(2)} kg`;

    const forming_el = `${(t * this.getRand(0.005, 0.01)).toFixed(2)} kWh`;
    const forming_co2 = `${(parseFloat(forming_el) * 0.309).toFixed(2)} kg`;

    const total_co2 = `${((parseFloat(forming_co2) + parseFloat(wrapping_co2) + parseFloat(furnace_co2) + parseFloat(pgms_co2) * 1000 + parseFloat(welding_co2) + parseFloat(hydr_co2) + parseFloat(steel)) / 1000).toFixed(2)} t`;
    this.setState({
      steel,
      hydr_el,
      hydr_co2,
      welding_el,
      welding_co2,
      furnace_el,
      furnace_gas,
      furnace_co2,
      wrapping_el,
      wrapping_co2,
      forming_el,
      forming_co2,
      pgms_co2,
      total_co2,
    });
  }

  render() {
    const {
      steel,
      hydr_el,
      hydr_co2,
      welding_el,
      welding_co2,
      furnace_el,
      furnace_gas,
      furnace_co2,
      wrapping_el,
      wrapping_co2,
      forming_el,
      forming_co2,
      pgms_co2,
      total_co2,
    } = this.state;
    return (
      <div className="industry-tab fullscreen">
        <main style={{ position: 'relative' }}>
          <div style={{ display: 'flex' }}>
            <div className="johnson-matthey-resources col">
              <div className="resource resource--raw">
                Raw resources <span />
              </div>
              <div className="resource resource--machinery">
                Machinery <span />
              </div>
              <div className="resource resource--process">
                Temporary storage <span />
              </div>
              <p style={{ display: 'inline-block', width: '160px', border: '1px solid #bbb', fontSize: '.9em', padding: '10px 5px' }}>
                Simulated dataset
              </p>
            </div>
            <table className="johnson-matthey-pf col" style={{ width: '80%' }}>
              <colgroup>
                <col span="1" />
                <col span="1" style={{ width: '10%' }} />
                <col span="1" />
                <col span="1" style={{ width: '10%' }} />
              </colgroup>
              <tbody style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <tr>
                  <td className="station" style={{ background: '#85C1E9' }}>
                    <div className="station__title" style={{ fontSize: '20px', fontWeight: '800' }}>Stainless steel sheet</div>
                    <div className="station--inner" style={{ fontWeight: '800' }}>
                      <span>CO2: </span>
                      <span id="steel_co2">{steel}</span>
                    </div>
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '48px', fontWeight: '400', color: '#34495E', fontStyle: 'normal' }}>⬇</span>
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td className="station" rowSpan="3">
                    <div className="station__title" style={{ fontSize: '20px', fontWeight: '800' }}>Hydraulic press</div>
                    <div className="station--inner" style={{ fontWeight: '800' }}>
                      <span>Electricity: </span>
                      <span id="hydr_el">{hydr_el}</span>
                    </div>
                    <div className="station--inner" style={{ fontWeight: '800' }}>
                      <span>CO2: </span>
                      <span id="hydr_co2">{hydr_co2}</span>
                    </div>
                  </td>
                  <td />
                  <td className="station" rowSpan="3">
                    <div className="station__title" style={{ fontSize: '20px', fontWeight: '800' }}>Welding &amp; sanding</div>
                    <div className="station--inner" style={{ fontWeight: '800' }}>
                      <span>Electricity: </span>
                      <span id="welding_el">{welding_el}</span>
                    </div>
                    <div className="station--inner" style={{ fontWeight: '800' }}>
                      <span>CO2: </span>
                      <span id="welding_co2">{welding_co2}</span>
                    </div>
                  </td>
                  <td />
                  <td />
                </tr>
                <tr style={{ height: '10px' }}>
                  <td>
                    <span style={{ fontSize: '48px', fontWeight: '400', color: '#34495E', fontStyle: 'normal' }}>⮕</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '48px', fontWeight: '400', color: '#34495E', fontStyle: 'normal' }}>⮕</span>
                  </td>
                  <td className="station" style={{ background: '#808B96', verticalAlign: 'middle' }}>
                    <div className="station__title" style={{ fontSize: '20px', fontWeight: '800' }}>Warehouse</div>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td />
                  <td />
                </tr>
                <tr />
                <tr>
                  <td className="station" style={{ background: '#85C1E9', verticalAlign: 'middle' }}>
                    <div className="station__title" style={{ fontSize: '20px', fontWeight: '800' }}>Ceramic substrate</div>
                  </td>
                  <td>
                    <span style={{ fontSize: '48px', fontWeight: '400', color: '#34495E', fontStyle: 'normal' }}>⮕</span>
                  </td>
                  <td className="station" rowSpan="3">
                    <div className="station__title" style={{ fontSize: '20px', fontWeight: '800' }}>Furnace</div>
                    <div className="station--inner" style={{ fontWeight: '800' }}>
                      <span>Gas: </span>
                      <span id="furnace_gas">{furnace_gas}</span>
                    </div>
                    <div className="station--inner" style={{ fontWeight: '800' }}>
                      <span>Electricity: </span>
                      <span id="furnace_el">{furnace_el}</span>
                    </div>
                    <div className="station--inner" style={{ fontWeight: '800' }}>
                      <span>CO2: </span>
                      <span id="furnace_co2">{furnace_co2}</span>
                    </div>
                  </td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td />
                  <td />
                  <td>
                    <span style={{ fontSize: '48px', fontWeight: '400', color: '#34495E', fontStyle: 'normal' }}>⮕</span>
                  </td>
                  <td className="station" style={{ background: '#808B96', verticalAlign: 'middle' }}>
                    <div className="station__title" style={{ fontSize: '20px', fontWeight: '800' }}>Cured substrate</div>
                  </td>
                </tr>
                <tr>
                  <td className="station" style={{ background: '#85C1E9' }}>
                    <div className="station__title" style={{ fontSize: '20px', fontWeight: '800' }}>PGMs</div>
                    <div className="station--inner" style={{ fontWeight: '800' }}>
                      <span>CO2: </span>
                      <span id="pgms_co2">{pgms_co2}</span>
                    </div>
                  </td>
                  <td>
                    <span style={{ fontSize: '48px', fontWeight: '400', color: '#34495E', fontStyle: 'normal' }}>⮕</span>
                  </td>
                  <td />
                  <td />
                </tr>
                <tr style={{ height: '10px' }} />
                <tr>
                  <td className="station" rowSpan="3">
                    <div className="station__title" style={{ fontSize: '20px', fontWeight: '800' }}>Wrapping &amp; inserting</div>
                    <div className="station--inner" style={{ fontWeight: '800' }}>
                      <span>Electricity: </span>
                      <span id="wrapping_el">{wrapping_el}</span>
                    </div>
                    <div className="station--inner" style={{ fontWeight: '800' }}>
                      <span>CO2: </span>
                      <span id="wrapping_co2">{wrapping_co2}</span>
                    </div>
                  </td>
                  <td />
                  <td className="station" rowSpan="3">
                    <div className="station__title" style={{ fontSize: '20px', fontWeight: '800' }}>Forming &amp; verification</div>
                    <div className="station--inner" style={{ fontWeight: '800' }}>
                      <span>Electricity: </span>
                      <span id="forming_el">{forming_el}</span>
                    </div>
                    <div className="station--inner" style={{ fontWeight: '800' }}>
                      <span>CO2: </span>
                      <span id="forming_co2">{forming_co2}</span>
                    </div>
                  </td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '48px', fontWeight: '400', color: '#34495E', fontStyle: 'normal' }}>⮕</span>
                  </td>
                  <td>
                    <span style={{ fontSize: '48px', fontWeight: '400', color: '#34495E', fontStyle: 'normal' }}>⮕</span>
                  </td>
                  <td className="station" style={{ background: '#A569BD' }}>
                    <div className="station__title" style={{ fontSize: '20px', fontWeight: '800' }}>Finished part</div>
                    <div className="station--inner" style={{ fontWeight: '800' }}>
                      <span>Total CO2: </span>
                      <span id="total_co2">{total_co2}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td />
                  <td />
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      </div>
    );
  }
}

export default JohnsonMattheyPlantFloor;
