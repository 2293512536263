const metrics = [
  {
    id: 4,
    label: 'on balcony',
    name: 'Temperature outside',
    unit: '°C',
    decimalPoints: 1,
    operation: 'last',
    groupBy: 'm',
    minValue: -10,
    maxValue: 50,
    threshold: 35,
  },
  {
    id: 3,
    label: 'in office',
    name: 'Temperature inside',
    unit: '°C',
    decimalPoints: 1,
    operation: 'last',
    groupBy: 'm',
    minValue: 10,
    maxValue: 35,
    threshold: 28,
  },
  {
    id: 5,
    label: 'ADS_inner',
    name: 'ADS inner temperature',
    unit: '°C',
    decimalPoints: 1,
    operation: 'last',
    groupBy: 'm',
    minValue: 0,
    maxValue: 50,
    threshold: 35,
  },
];

export default metrics;
