import React from 'react';
import Base from './Base';

export default (props) => (<Base viewBox="0 0 50 50" {...props}>
  <path d="M43.93,16H6.07a2,2,0,0,1,0-4H43.93a2,2,0,0,1,0,4Z" />
  <path d="M43.93,27H6.07a2,2,0,0,1,0-4H43.93a2,2,0,1,1,0,4Z" />
  <path d="M43.93,38H6.07a2,2,0,0,1,0-4H43.93a2,2,0,1,1,0,4Z" />
  <circle cx="34.63" cy="14" r="3.97" style={{ fill: '#fff' }} />
  <path d="M34.63,19a5,5,0,1,1,5-5A5,5,0,0,1,34.63,19Zm0-7.94a3,3,0,1,0,3,3A3,3,0,0,0,34.63,11Z" />
  <circle cx="14.86" cy="25" r="3.97" style={{ fill: '#fff' }} />
  <path d="M14.86,30a5,5,0,1,1,5-5A5,5,0,0,1,14.86,30Zm0-7.94a3,3,0,1,0,3,3A3,3,0,0,0,14.86,22Z" />
  <circle cx="25" cy="36" r="3.97" style={{ fill: '#fff' }} />
  <path d="M25,41a5,5,0,1,1,5-5A5,5,0,0,1,25,41ZM25,33a3,3,0,1,0,3,3A3,3,0,0,0,25,33Z" />
</Base>);
