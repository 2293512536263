import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { checkAccessOnPage } from 'industry/helpers';
import {
  DashboardWidget,
  EventListWidget,
} from './components';
import './styles.scss';

class IndustryLocationDashboard extends React.Component {
  componentDidMount() {
    const { companyId, history } = this.props;
    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          history.push('/', { forced: true });
        } else if (access === 1) {
          // read only
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            isReadOnly: true,
          });
        }
      });
  }

  render() {
    const {
      companyId,
      locationId,
      dashboards,
    } = this.props;

    const sharedProps = {
      companyId,
      locationId,
    };

    return (
      <section className="industry-tab industry-location-dashboard fullscreen">
        <div className="col">
          <DashboardWidget {...sharedProps} dashboards={dashboards} />
        </div>
        <div className="col col-2">
          <EventListWidget {...sharedProps} />
        </div>
      </section>
    );
  }
}

IndustryLocationDashboard.defaultProps = {
  dashboards: [],
};

IndustryLocationDashboard.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationId: PropTypes.number.isRequired,
  dashboards: PropTypes.array,
  history: PropTypes.object.isRequired,
};

export default withRouter(IndustryLocationDashboard);
