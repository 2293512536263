import api from 'helpers/api';
import { Notification } from 'shared';

export const getDmsDocuments = async (locationId, filters) => {
  const urlString = '/api/v1/dms/documents/';
  let urlParams = '?limit=30';
  if (locationId) {
    urlParams += `&location=${locationId}`;
  }
  let targetUrl = urlString + urlParams;
  if (filters) {
    targetUrl += `${filters}`;
  }
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching documents', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedDmsDocuments = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching document', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getDmsDocumentTypes = async (locationId) => {
  try {
    const res = await api
      .get(`/api/v1/dms/document_types/?location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching document types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProjects = async (companyId, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/projects/projects/?company=${companyId}&location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching projects', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const createDmsDocument = async (documentData) => {
  try {
    return await api.post('/api/v1/dms/documents/', documentData);
  } catch (error) {
    Notification('error', error.response.data.name[0]);
    return { error };
  }
};

export const getProductTypes = async (companyId, filter) => {
  const urlString = '/api/v1/product_types/';
  let urlParams = '?limit=100';
  if (companyId) {
    urlParams += `&company=${companyId}`;
  }
  if (filter) {
    urlParams += `&name_contains=${filter}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching product types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAssets = async (companyId, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/assets/?company=${companyId}&location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching assets', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const importDmsDocumentFiles = (data, documentId) => {
  const formData = new FormData();
  if (data) {
    formData.append('file', data);
  }
  if (documentId) {
    formData.append('document', documentId);
  }
  return api.post('/api/v1/dms/document_files/', formData)
    .then((re) => {
      return re;
    })
    .catch((err) => {
      if (err.response.data.file) {
        Notification('error', err.response.data.file[0]);
        return;
      }
      Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
    });
};

export const checkRevision = async (filename, type) => {
  const url = `/api/v1/dms/check_revision/?filename=${filename}&document_type=${type}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while checking revision', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
