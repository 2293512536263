import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { selectStyles } from 'styles/modules/reactSelect';
import ReactDatePicker from 'react-datepicker';
import { checkModules, checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import { Table, Button, TableButtons, Notification } from 'shared';
import { get } from 'lodash';
import moment from 'moment';
import { IconSearch } from 'shared/Icons';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { ordersCreatedAndUpdated } from 'shared/constants';
import { getLocale } from 'shared/DatePicker/constants';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import { getDmsDocuments, getDmsDocumentTypes, getProjects, getPaginatedDmsDocuments, getAssets } from './actions';
import CreateDocumentModal from './components/CreateDocumentModal';

class IndustryLocationDMS extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    this.state = {
      dmsDocuments: [],
      next: null,
      previous: null,
      count: null,
      dmsDocumentTypes: [],
      projects: [],
      isLoadingDMS: false,
      documentNameQuery: '',
      filterDocumentType: {},
      filterProjects: {},
      filterApproved: {},
      startTime: '',
      endTime: '',
      createDocumentModalStatus: false,
      assets: [],
      selectedSort: 'updated_at',
      selectedAscDesc: 'desc',
    };
  }

  componentDidMount() {
    const { locationId, companyId, isProjectBased } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'DMS');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          this.setState({
            isReadOnly: true,
          });
        }
      });
    this.fetchDmsDocuments();
    this.fetchDmsDocumentTypes();

    if (isProjectBased) {
      this.fetchProjects();
    }
    getAssets(companyId, locationId)
      .then((resp) => {
        this.setState({
          assets: get(resp, 'data.results', []),
        });
      });
  }

  redirectToDetails = (row) => {
    const { companyId, locationId, history } = this.props;
    const projectId = row.original.id || null;
    history.push(`/${companyId}/industry/location/${locationId}/dms/${projectId}`);
  }

  fetchDmsDocuments = () => {
    const { locationId } = this.props;
    const { documentNameQuery, filterDocumentType, filterProjects, filterApproved, startTime, endTime, selectedAscDesc, selectedSort } = this.state;
    this.setState({
      isLoadingDMS: true,
    });

    let filters = '';

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort}`;

    if (documentNameQuery) {
      filters += `&name=${documentNameQuery}`;
    }
    if (filterDocumentType && Object.keys(filterDocumentType).length > 0) {
      filters += `&${filterDocumentType.filterKey}=${filterDocumentType.id}`;
    }
    if (filterProjects && Object.keys(filterProjects).length > 0) {
      filters += `&${filterProjects.filterKey}=${filterProjects.id}`;
    }
    if (filterApproved && Object.keys(filterApproved).length > 0) {
      filters += `&${filterApproved.filterKey}=${filterApproved.value}`;
    }
    if (startTime) {
      filters += `&created_at_after=${startTime.toISOString()}`;
    }
    if (endTime) {
      filters += `&created_at_before=${endTime.toISOString()}`;
    }

    getDmsDocuments(locationId, filters)
      .then((re) => {
        this.setState({
          dmsDocuments: get(re, 'data.results') || [],
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isLoadingDMS: false,
        });
      });
  }

  fetchPaginatedDmsDocuments = (url) => {
    this.setState({
      isLoadingDMS: true,
    });
    getPaginatedDmsDocuments(url)
      .then((re) => {
        this.setState({
          dmsDocuments: get(re, 'data.results') || [],
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingDMS: false,
        });
      })
      .catch((e) => console.error('Error while fetching documents', e));
  }

  fetchDmsDocumentTypes = () => {
    this.setState({
      isLoadingDMS: true,
    });
    const { locationId } = this.props;
    getDmsDocumentTypes(locationId)
      .then((re) => {
        this.setState({
          dmsDocumentTypes: get(re, 'data.results') || [],
          isLoadingDMS: false,
        });
      });
  }

  fetchProjects = () => {
    this.setState({
      isLoadingDMS: true,
    });
    const { locationId, companyId } = this.props;
    getProjects(companyId, locationId)
      .then((re) => {
        this.setState({
          projects: get(re, 'data.results') || [],
          isLoadingDMS: false,
        });
      });
  }

  handleQueryOrFilterChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleQueryClear = (key) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: '',
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleStateQueryChange = () => {
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      this.fetchDmsDocuments();
    }, 600);
  }

  openCreateDocumentModal = () => {
    const { dmsDocumentTypes } = this.state;
    const { t } = this.props;
    if (!dmsDocumentTypes.length) {
      Notification('error', t('page_content.dms.create_document_modal_error'));
      return;
    }
    this.setState({
      createDocumentModalStatus: true,
    });
  }

  closeCreateDocumentModal = () => {
    this.setState({
      createDocumentModalStatus: false,
    });
  }

  handleSorting = (sortData) => {
    let column = sortData.id;

    if (column === 'user') {
      column = 'user__last_name';
    }

    if (column === 'document_type.name' || column === 'project.name') {
      column = column.replace('.', '__');
    }

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchDmsDocuments();
    });
  }

  render() {
    const { t, locationId, companyId, isProjectBased } = this.props;
    const { assets, dmsDocuments, isLoadingDMS, dmsDocumentTypes, documentNameQuery, filterDocumentType, projects, filterProjects, filterApproved, startTime, endTime, next, previous, createDocumentModalStatus, count } = this.state;

    const queryOptions = { queryKey: 'documentNameQuery', value: documentNameQuery, placeholder: t('page_content.dms.query_name') };

    const documentTypeFilter = [
      ...dmsDocumentTypes.sort((a, b) => a.name.localeCompare(b.name)).map((docType) => ({
        filterKey: 'document_type',
        name: docType.name,
        id: docType.id,
        defaultExtension: docType.default_extension,
        line_based: docType.line_based,
      })),
    ];

    const projectsFilter = [
      ...projects.sort((a, b) => a.name.localeCompare(b.name)).map((project) => ({
        filterKey: 'project',
        name: project.name,
        id: project.id,
      })),
    ];

    const isApprovedFilter = [
      { filterKey: 'is_approved', value: true, name: t('page_content.dms.filter_option_document_approved') },
      { filterKey: 'is_approved', value: false, name: t('page_content.dms.filter_option_document_not_approved') },
    ];

    const tableColumnConfig = [
      {
        Header: () => <span>{t('page_content.dms.table_column_document_name')}</span>,
        accessor: 'name',
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        Header: () => <span>{t('page_content.dms.table_column_document_type')}</span>,
        accessor: 'document_type.name',
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        Header: () => <span>{t('page_content.dms.table_column_user')}</span>,
        accessor: 'user',
        Cell: (row) => (row.value && (row.value.first_name || row.value.last_name) ? `${row.value.last_name || ''} ${row.value.first_name || ''}` : '-'),
      },
      {
        Header: () => <span>{t('page_content.dms.table_column_project')}</span>,
        accessor: 'project.name',
        Cell: (row) => (row.value ? row.value : '-'),
        show: isProjectBased,
      },
      {
        Header: () => <span>{t('page_content.dms.table_column_product_type')}</span>,
        accessor: 'product_types',
        Cell: (row) => (row.value && row.value.length ? row.value.map((p, i) => { if (i < (row.value.length - 1)) { return `${p.name}, `; } return `${p.name}`; }) : '-'),
        sortable: false,
      },
      {
        Header: () => <span>{t('page_content.dms.table_column_created')}</span>,
        accessor: 'created_at',
        Cell: (row) => (row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
      },
      {
        Header: () => <span>{t('page_content.dms.table_column_updated')}</span>,
        accessor: 'updated_at',
        Cell: (row) => (row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
      },
      {
        Header: () => <span>{t('page_content.dms.table_column_is_approved')}</span>,
        accessor: 'is_approved',
        width: 100,
        Cell: (row) => (<div style={{ margin: 'auto' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>),
      }];

    return (
      <div className="industry-tab dms_main_container fullscreen">
        <div className="dms_toolbar_area">
          <div className="dms_toolbar_input_container">
            <input onChange={(e) => this.handleQueryOrFilterChange(queryOptions.queryKey, e.target.value)} placeholder={queryOptions.placeholder} value={queryOptions.value} />
            {queryOptions.value &&
              <button
                onClick={() => {
                  this.handleQueryClear(queryOptions.queryKey);
                }}
              >
                &times;
              </button>}
            <div className="dms_toolbar_icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <Select
            options={documentTypeFilter}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            isClearable
            placeholder={t('page_content.dms.filter_document_type')}
            onChange={(value) => this.handleQueryOrFilterChange('filterDocumentType', value ?? '')}
            value={(documentTypeFilter.find((docuType) => docuType.id === filterDocumentType.id)) || ''}
            styles={selectStyles}
          />
          {
            isProjectBased ?
              <Select
                options={projectsFilter}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                isSearchable
                isClearable
                placeholder={t('page_content.dms.filter_project')}
                onChange={(value) => this.handleQueryOrFilterChange('filterProjects', value ?? '')}
                value={(projectsFilter.find((proj) => proj.id === filterProjects.id)) || ''}
                styles={selectStyles}
              /> : ''
          }

          <Select
            options={isApprovedFilter}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            isSearchable
            isClearable
            placeholder={t('page_content.dms.filter_document_approval')}
            onChange={(value) => this.handleQueryOrFilterChange('filterApproved', value ?? '')}
            value={(isApprovedFilter.find((appType) => appType.value === filterApproved.value)) || ''}
            styles={selectStyles}
          />

          <div className="dms_datepicker">
            <ReactDatePicker
              placeholderText={t('page_content.dms.filter_start_time')}
              dateFormat="dd.MM.yyyy"
              selected={startTime ? moment(startTime).toDate() : null}
              selectsStart
              disabledKeyboardNavigation
              onChange={(e) => this.handleQueryOrFilterChange('startTime', e)}
              showTimeSelect={false}
              timeFormat="HH:mm"
              timeIntervals={1}
              timeCaption={t('date_picker_locale.time_translation')}
              locale={getLocale(t)}
            />
          </div>
          <div className="dms_datepicker">
            <ReactDatePicker
              placeholderText={t('page_content.dms.filter_end_time')}
              dateFormat="dd.MM.yyyy"
              selected={endTime ? moment(endTime).toDate() : null}
              selectsStart
              disabledKeyboardNavigation
              onChange={(e) => this.handleQueryOrFilterChange('endTime', e)}
              showTimeSelect={false}
              timeFormat="HH:mm"
              timeIntervals={1}
              timeCaption={t('date_picker_locale.time_translation')}
              locale={getLocale(t)}
            />
          </div>

          <div className="dms_toolbar_clear_all_button">
            <Button
              onClick={() => {
                this.setState({
                  documentNameQuery: '',
                  filterDocumentType: {},
                  filterProjects: {},
                  filterApproved: {},
                  startTime: '',
                  endTime: '',
                }, () => {
                  this.handleStateQueryChange();
                });
              }}
            >
              {t('page_content.dms.button_clear_all')}
            </Button>
          </div>
          <div className="dms_toolbar_create_document_button">
            <Button
              onClick={() => {
                this.openCreateDocumentModal();
              }}
              type="add"
            >
              {t('page_content.dms.button_create_document')}
            </Button>
          </div>
        </div>
        <div className="dms_table_area">
          <Table
            style={{ userSelect: 'text' }}
            columns={tableColumnConfig}
            data={dmsDocuments}
            minRows={0}
            defaultPageSize={30}
            noDataText=" "
            showPagination={false}
            sortable
            loading={isLoadingDMS}
            handleClick={(rowInfo) => this.redirectToDetails(rowInfo)}
            defaultSorted={[{ id: 'updated_at', desc: true }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <TableButtons previous={previous} next={next} fetchFunction={this.fetchPaginatedDmsDocuments} count={count} />
        </div>
        <CreateDocumentModal
          fetchDmsDocuments={this.fetchDmsDocuments}
          modalStatus={createDocumentModalStatus}
          closeModal={this.closeCreateDocumentModal}
          documentTypes={documentTypeFilter}
          projects={projectsFilter}
          locationId={locationId}
          companyId={companyId}
          isProjectBased={isProjectBased}
          assets={assets}
          data={dmsDocuments}
        />
      </div>
    );
  }
}

IndustryLocationDMS.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isProjectBased: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isProjectBased: !!(state.app && state.app.companyConfig && state.app.companyConfig.config && state.app.companyConfig.config.project_based && state.app.companyConfig.config.project_based === true),
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(IndustryLocationDMS)));
