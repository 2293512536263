import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import '../styles.scss';

class ProjectDetailsTab extends Component {
  render() {
    const {
      project,
      t,
    } = this.props;

    return (
      <div className="project_details">
        <div
          className="project_details-table"
          style={{ background: 'white' }}
        >
          <div className="project_details-table__title">
            {t('page_content.projects.general_information')}
          </div>

          {typeof project === 'object' && Object.prototype.hasOwnProperty.call(project, 'category') && Object.prototype.hasOwnProperty.call(project, 'owner') &&
            <Fragment>
              <div className="project_details-table__row">
                <span className="project_details-table__label">
                  {t('page_content.projects.name')}
                </span>
                <span className="project_details-table__value">
                  {project.name}
                </span>
              </div>

              <div className="project_details-table__row">
                <span className="project_details-table__label">
                  {t('page_content.projects.owner')}
                </span>
                <span className="project_details-table__value">
                  {project.owner !== null &&
                    <span>
                      {project.owner.first_name} {project.owner.last_name}
                    </span>}
                </span>
              </div>

              <div className="project_details-table__row">
                <span className="project_details-table__label">
                  {t('page_content.projects.category')}
                </span>
                <span className="project_details-table__value">
                  {project.category !== null &&
                    <span>
                      {project.category.name}
                    </span>}
                </span>
              </div>

              <div className="project_details-table__row">
                <span className="project_details-table__label">
                  {t('page_content.projects.code')}
                </span>
                <span className="project_details-table__value">
                  {project.code}
                </span>
              </div>
            </Fragment>}
        </div>

        <div
          className="project_details-table"
          style={{ background: 'white' }}
        >
          <div className="project_details-table__title">
            Timeline
          </div>
        </div>
      </div>
    );
  }
}

ProjectDetailsTab.propTypes = {
  t: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};

export default ProjectDetailsTab;
