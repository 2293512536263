import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { get } from 'lodash';
import moment from 'moment';

import { modalSizes } from 'shared/constants';
import { Modal, ContentLoader } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { getOrderCancellationReasons, saveOrderCancellationReason } from '../../actions';
import './styles.scss';

const OrderCancellationModal = ({
  t,
  locationId,
  isOrderCancellationModalOpen,
  closeOrderCancellationModal,
  getOrderId,
  currentUser,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [cancellationReasons, setCancellationReasons] = useState([]);
  const [reason, setReason] = useState(null);

  const fetchData = async () => {
    try {
      const res = await getOrderCancellationReasons(locationId);
      setCancellationReasons(get(res, 'data.results', []));
      setIsLoading(false);
    } catch (error) {
      setCancellationReasons([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onReasonChange = (value) => {
    setReason(value);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const orderId = getOrderId();
      const data = {
        cancellation_time: moment().utc().toISOString(),
        cancellation_reason: reason,
        cancellation_user: get(currentUser, 'id', ''),
        status: 'cancelled',
      };
      await saveOrderCancellationReason(orderId, data);
      setCancellationReasons([]);
      setReason(null);
      setIsLoading(false);
      closeOrderCancellationModal();
    } catch (error) {
      setCancellationReasons([]);
      setIsLoading(false);
      closeOrderCancellationModal();
    }
  };

  return (
    <Modal
      isOpen={isOrderCancellationModalOpen}
      handleSave={handleSave}
      disableSave={!reason}
      handleClose={closeOrderCancellationModal}
      size={modalSizes.medium}
      title={t('page_content.orders.order_details.order_cancellation')}
    >
      {
        isLoading ?
          <ContentLoader /> :
          <div className="cancellationReason__modal_container">
            <div className="cancellationReason__modal_row">
              <div className="cancellationReason__left_text">{`${t('page_content.orders.order_details.reason')}*:`}</div>
              <div className="cancellationReason__right_select">
                <Select
                  options={cancellationReasons}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  placeholder=""
                  menuPosition="fixed"
                  onChange={(value) => onReasonChange(value.id)}
                  value={(cancellationReasons.find((r) => r.id === reason)) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
          </div>
      }
    </Modal>
  );
};

OrderCancellationModal.propTypes = {
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  isOrderCancellationModalOpen: PropTypes.bool.isRequired,
  closeOrderCancellationModal: PropTypes.func.isRequired,
  getOrderId: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, null)(OrderCancellationModal);
