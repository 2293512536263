import { isString } from 'lodash';

// eslint-disable-next-line
export const filterAssets = (assets, query) => {
  if (!query) {
    return assets;
  }
  return assets.filter((asset) => {
    return (isString(asset.name) && asset.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  });
};
