import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { Table, Button, Notification, TableButtons, ConfirmationModal } from 'shared';
import { selectStyles, selectModalStyles } from 'styles/modules/reactSelect';
import { IconRemove } from 'shared/Icons';
import { setDateRange } from 'app/actions';
import * as industryDashboardActions from 'industry/containers/IndustryLocationDashboard/actions';
import { defaultDateTimeFormat } from 'shared/constants';
import { getLocale } from 'shared/DatePicker/constants';
import { renderEventIcon, checkAccessOnPage, redirectToHomePage, checkModules, checkActiveFilters } from 'industry/helpers';
import 'react-table/react-table.css';
import { saveSortingAndFiltering } from 'industry/actions';
import { getCurrentUserSuccess } from 'user/actions';
import EventForm from './components/EventForm';
import {
  getAllEvents, getAssets, getReasons, updateEvent, deleteEvent,
} from './actions';
import './styles.scss';

class IndustryLocationEventList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteData: null,
      showConfirmationDialog: false,
      showConfirmationDialogMultiple: false,
    };

    let isReadOnly = false;

    if (window.resourceReadOnly && window.resourceReadOnly.length) {
      window.resourceReadOnly.forEach((resource) => {
        if (window.location.href.includes(resource)) {
          isReadOnly = true;
        }
      });
    }

    const sortingAndFiltering = props && props.currentUser && props.currentUser.data ? props.currentUser.data : {};
    let startTime = null;
    let endTime = null;
    let selectedLogLevel = 'all';
    let selectedAsset = 'all';
    let selectedAscDesc = false;
    let selectedStoppageAsset = selectedAsset;
    let selectedSort = 'ts';

    this.defaultFilters = {
      selectedLogLevel,
      selectedAsset,
      startTime,
      endTime,
    };

    let filtersActive = false;
    if (sortingAndFiltering.eventsList) {
      const sAndF = sortingAndFiltering.eventsList;
      startTime = sAndF.startTime ? moment(sAndF.startTime) : startTime;
      endTime = sAndF.endTime ? moment(sAndF.endTime) : endTime;
      selectedLogLevel = sAndF.selectedLogLevel || selectedLogLevel;
      selectedSort = sAndF.selectedSort || selectedSort;
      selectedAscDesc = sAndF.selectedAscDesc === 'desc' ? true : false || selectedAscDesc;
      selectedStoppageAsset = sAndF.selectedStoppageAsset || selectedStoppageAsset;
      selectedAsset = sAndF.selectedAsset || selectedAsset;
      filtersActive = checkActiveFilters(this.defaultFilters, sAndF);
    }

    this.state = {
      events: [],
      eventsSelected: [],
      togglerSelected: false,
      isLoadingEvents: false,
      isLoadingStoppageEvents: false,
      previous: null,
      next: null,
      count: null,
      assets: [],
      reasons: [],
      stoppageEvents: [],
      selectedLogLevel,
      selectedAsset,
      selectedStoppageAsset,
      selectedSort,
      selectedAscDesc,
      startTime,
      endTime,
      isEventFormOpen: false,
      selectedEvent: null,
      isReadOnly,
      filtersActive,
      isLoadingExport: false,
      isLoadingEventUpdate: false,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Events');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    // ---------------------

    const {
      selectedAsset,
      startTime,
      endTime,
      selectedLogLevel,
      selectedSort,
      selectedAscDesc,
    } = this.state;

    let url = `/api/v1/industry/events/?company=${companyId}&location=${locationId}`;

    if (selectedLogLevel && selectedLogLevel !== 'all') {
      url = `${url}&log_level__in=${selectedLogLevel}`;
    }

    if (selectedAsset && selectedAsset !== 'all') {
      url = `${url}&asset=${selectedAsset}`;
    }

    if (startTime && endTime) {
      const sTime = startTime ? startTime.toISOString() : null;
      const eTime = endTime ? endTime.toISOString() : null;
      if (sTime) {
        url = `${url}&ts_after=${sTime}`;
      }
      if (eTime) {
        url = `${url}&ts_before=${eTime}`;
      }
    }

    if (selectedSort) {
      const ascDesc = selectedAscDesc === 'desc' ? '-' : '';
      url = `${url}&order_by=${ascDesc}${selectedSort}`;
    }

    this.fetchData(url);

    // ---------------------

    getAssets(companyId, locationId)
      .then((res) => {
        this.setState({
          assets: get(res, 'data.results') || [],
        });
      })
      .catch((e) => console.error('Error fetching assets', e));

    getReasons(companyId)
      .then((res) => {
        this.setState({
          reasons: get(res, 'data.results') || [],
        });
      })
      .catch((e) => console.error('Error fetching reasons', e));

    this.fetchStoppageEvents();
  }

  componentWillUnmount() {
    this.saveSortingAndFiltering();
  }

  onStoppageAssetChange = (ev) => {
    const { companyId, locationId } = this.props;
    const assetId = ev.target.value;
    let url;

    this.setState({
      isLoadingStoppageEvents: true,
    });

    if (assetId === 'all') {
      url = `/api/v1/industry/events/?company=${companyId}&location=${locationId}&event_type__in=stoppage&closed=false`;
    } else {
      url = `/api/v1/industry/events/?company=${companyId}&location=${locationId}&event_type__in=stoppage&closed=false&asset=${assetId}`;
    }

    getAllEvents(url)
      .then((res) => {
        this.setState({
          stoppageEvents: get(res, 'data.results') || [],
          isLoadingStoppageEvents: false,
          selectedStoppageAsset: assetId,
        });
      })
      .catch((e) => console.error('Error fetching stoppage events', e));
    this.saveSortingAndFiltering();
  }

  onAssetChange = (e) => {
    const { companyId, locationId } = this.props;
    const { selectedLogLevel, startTime, endTime } = this.state;
    const assetId = e.target.value;
    let url;
    if (assetId === 'all') {
      url = `/api/v1/industry/events/?company=${companyId}&location=${locationId}`;
    } else {
      url = `/api/v1/industry/events/?company=${companyId}&location=${locationId}&asset=${assetId}`;
    }
    if (selectedLogLevel !== 'all') {
      url = `${url}&log_level__in=${selectedLogLevel}`;
    }

    const sTime = startTime ? startTime.toISOString() : null;
    const eTime = endTime ? endTime.toISOString() : null;
    if (sTime) {
      url = `${url}&ts_after=${sTime}`;
    } else if (eTime) {
      url = `${url}&ts_before=${eTime}`;
    }

    this.fetchData(url);
    this.setState({
      selectedAsset: assetId,
    }, () => {
      this.resetMultipleSelection();
      this.saveSortingAndFiltering();
    });
  }

  onLogLevelChange = (e) => {
    const { companyId, locationId } = this.props;
    const { selectedAsset, startTime, endTime } = this.state;
    const logLevel = e.target.value;
    let url;
    if (logLevel === 'all') {
      url = `/api/v1/industry/events/?company=${companyId}&location=${locationId}`;
    } else {
      url = `/api/v1/industry/events/?company=${companyId}&location=${locationId}&log_level__in=${logLevel}`;
    }
    if (selectedAsset !== 'all') {
      url = `${url}&asset=${selectedAsset}`;
    }

    const sTime = startTime ? startTime.toISOString() : null;
    const eTime = endTime ? endTime.toISOString() : null;
    if (sTime) {
      url = `${url}&ts_after=${sTime}`;
    }
    if (eTime) {
      url = `${url}&ts_before=${eTime}`;
    }

    this.fetchData(url);
    this.setState({
      selectedLogLevel: logLevel,
    }, () => {
      this.resetMultipleSelection();
      this.saveSortingAndFiltering();
    });
  }

  onChangeStart = (startTime) => {
    const { updateManualRefresh } = this.props;
    const formatedDate = moment(startTime);
    this.setState({
      startTime: formatedDate,
    }, () => updateManualRefresh(true));
  }

  onChangeEnd = (endTime) => {
    const { updateManualRefresh } = this.props;
    const formatedDate = moment(endTime);
    this.setState({
      endTime: formatedDate,
    }, () => updateManualRefresh(true));
  }

  onStoppageReasonSelect = (e, event) => {
    const { stoppageEvents } = this.state;
    const reason = e;
    if (!reason) return;

    const newStoppageEvents = [];
    const newStoppageEventsAfterUpdate = [];
    stoppageEvents.forEach((sE) => {
      if (sE.id === event.id) {
        sE.reason = reason;
        sE.closed = true;
        const { companyId, locationId } = this.props;
        updateEvent(companyId, locationId, sE);
      } else {
        newStoppageEventsAfterUpdate.push(sE);
      }

      newStoppageEvents.push(sE);
    });

    // Stari setter koji se koristio dok nije bio react table. Ostavljeno zbog implementacije na react table.
    // this.setState({
    //   stoppageEvents: newStoppageEvents,
    // }, () => {
    //   document.getElementById(rowId).classList.add('blink-bg');
    //   setTimeout(() => {
    //     document.getElementById(rowId).classList.remove('blink-bg');
    //     this.setState({
    //       stoppageEvents: [],
    //     }, () => {
    //       this.setState({
    //         stoppageEvents: newStoppageEventsAfterUpdate,
    //       });
    //     });
    //   }, 2000);
    // });

    this.setState({
      stoppageEvents: newStoppageEvents,
    }, () => {
      setTimeout(() => {
        this.setState({
          isLoadingStoppageEvents: true,
          stoppageEvents: [],
        }, () => {
          this.setState({
            stoppageEvents: newStoppageEventsAfterUpdate,
            isLoadingStoppageEvents: false,
          });
        });
      }, 1000);
    });
  }

  saveSortingAndFiltering = () => {
    const {
      startTime,
      endTime,
      selectedAscDesc,
      selectedLogLevel,
      selectedSort,
      selectedAsset,
    } = this.state;

    const {
      currentUser,
      setCurrentUser,
    } = this.props;

    if (currentUser) {
      currentUser.data.eventsList = {
        startTime,
        endTime,
        selectedAscDesc: selectedAscDesc ? 'desc' : 'asc',
        selectedLogLevel,
        selectedSort,
        selectedAsset,
      };
      setCurrentUser(currentUser);
      saveSortingAndFiltering({ data: currentUser.data });
      this.checkActiveSortingAndFiltering(currentUser.data.eventsList);
    }
  }

  checkActiveSortingAndFiltering = (userFilters) => {
    const filtersActive = checkActiveFilters(this.defaultFilters, userFilters);
    this.setState({ filtersActive });
  }

  handleUpdateEvent = (event) => {
    this.setState({
      isLoadingEventUpdate: true,
    });
    const { companyId, locationId } = this.props;
    updateEvent(companyId, locationId, event)
      .then(() => {
        this.setState({
          isLoadingEventUpdate: false,
          isEventFormOpen: false,
          selectedEvent: null,
        });
      })
      .catch((err) => console.log('Error updating event comment', err));
  }

  fetchData = (url) => {
    const { selectedAscDesc, selectedSort } = this.state;
    this.setState({
      isLoadingEvents: true,
    });
    if (!url.includes('order_by')) {
      url += `&order_by=${selectedAscDesc ? '-' : ''}${selectedSort}`;
    } else {
      const urlBase = url.split('&order_by')[0];
      url = `${urlBase}&order_by=${selectedAscDesc ? '-' : ''}${selectedSort}`;
    }
    getAllEvents(url)
      .then((res) => {
        this.setState({
          events: get(res, 'data.results') || [],
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          count: get(res, 'data.count'),
          currentUrl: url,
          isLoadingEvents: false,
        });
      })
      .catch((e) => console.error('Error fetching events', e));
  }

  fetchStoppageEvents = () => {
    const { locationId, companyId } = this.props;
    this.setState({
      isLoadingStoppageEvents: true,
    });

    getAllEvents(`/api/v1/industry/events/?company=${companyId}&location=${locationId}&event_type__in=stoppage&closed=false`)
      .then((res) => {
        this.setState({
          stoppageEvents: get(res, 'data.results') || [],
          isLoadingStoppageEvents: false,
        });
      })
      .catch((e) => console.error('Error fetching stoppage events', e));
  }

  applyTimeFilter = () => {
    const { companyId, locationId } = this.props;
    const {
      selectedAsset,
      selectedLogLevel,
      startTime,
      endTime,
    } = this.state;

    const sTime = startTime ? startTime.toISOString() : null;
    const eTime = endTime ? endTime.toISOString() : null;

    let url = `/api/v1/industry/events/?company=${companyId}&location=${locationId}`;
    if (sTime) {
      url = `${url}&ts_after=${sTime}`;
    }
    if (eTime) {
      url = `${url}&ts_before=${eTime}`;
    }
    if (selectedAsset !== 'all') {
      url = `${url}&asset=${selectedAsset}`;
    }
    if (selectedLogLevel !== 'all') {
      url = `${url}&log_level__in=${selectedLogLevel}`;
    }
    this.fetchData(url);
    this.resetMultipleSelection();
    this.saveSortingAndFiltering();
  }

  clearAllFilters = () => {
    const { companyId, locationId } = this.props;
    this.setState(this.defaultFilters);
    this.checkActiveSortingAndFiltering(this.defaultFilters);
    const url = `/api/v1/industry/events/?company=${companyId}&location=${locationId}`;
    this.fetchData(url);
    this.resetMultipleSelection();
  }

  openEventForm = (event) => {
    this.setState({
      isEventFormOpen: true,
      selectedEvent: event,
    });
  }

  closeEventForm = () => {
    this.setState({
      isEventFormOpen: false,
      selectedEvent: null,
    });
  }

  handleSorting = (sortData) => {
    const { currentUrl } = this.state;
    this.setState({ isLoadingEvents: true });
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: !!sortData.desc,
    }, () => {
      this.fetchData(currentUrl);
      this.saveSortingAndFiltering();
    });
  }

  handleShowConfirmationDialog = (eventId, e) => {
    e.stopPropagation();
    this.setState({ showConfirmationDialog: true, deleteData: eventId });
  }

  handleDeleteConfirm = () => {
    const eventId = this.state.deleteData;
    this.handleDeleteEvent(eventId);
    this.setState({ showConfirmationDialog: false });
  }

  handleDeleteEvent = (eventId) => {
    const { companyId, locationId } = this.props;

    deleteEvent(eventId, companyId, locationId)
      .then(() => {
        const { currentUrl } = this.state;

        this.fetchData(currentUrl);
        return Notification('success', 'Delete successful', 'Event was successfuly deleted.');
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
        this.resetMultipleSelection();
      });
  }

  handleSelectAllEvents = (e) => {
    e.stopPropagation();

    const eventElems = document.querySelectorAll('[name="event[]"]');
    Array.prototype.forEach.call(eventElems, (eventElem) => {
      eventElem.checked = e.target.checked;
    });
    this.setState({
      togglerSelected: e.target.checked,
    });

    this.updateMultipleSelection();
  }

  handleSelectEvent = (event, e) => {
    e.stopPropagation();
    this.updateMultipleSelection();
  }

  updateMultipleSelection = () => {
    const inputsElemsSelected = document.querySelectorAll('[name="event[]"]:checked');
    const listElemsSelected = {};
    Array.prototype.forEach.call(inputsElemsSelected, (inputElemSelected, idx) => {
      listElemsSelected[inputElemSelected.value] = this.state.events[idx];
    });
    this.setState({
      eventsSelected: listElemsSelected,
    });
  }

  resetMultipleSelection = () => {
    this.setState({
      eventsSelected: {},
      togglerSelected: false,
    });
  }

  handleShowConfirmationDialogMultiple = (e) => {
    e.stopPropagation();
    this.setState({ showConfirmationDialogMultiple: true });
  }

  submitMultipleSelection = () => {
    Object.entries(this.state.eventsSelected).forEach((eventSelected) => {
      this.setState({
        isLoading: true,
      });
      this.handleDeleteEvent(eventSelected[0]);
      this.resetMultipleSelection();
    });
    this.setState({ showConfirmationDialogMultiple: false });
  }

  exportPdf = () => {
    const { companyId, locationId, t } = this.props;
    const {
      selectedAsset,
      selectedLogLevel,
      startTime,
      endTime,
    } = this.state;

    this.setState({
      isLoadingExport: true,
    });

    const sTime = startTime ? startTime.toISOString() : null;
    const eTime = endTime ? endTime.toISOString() : null;

    let url = `/api/v1/industry/events/?company=${companyId}&location=${locationId}`;
    if (sTime) {
      url = `${url}&ts_after=${sTime}`;
    }
    if (eTime) {
      url = `${url}&ts_before=${eTime}`;
    }
    if (selectedAsset !== 'all') {
      url = `${url}&asset=${selectedAsset}`;
    }
    if (selectedLogLevel !== 'all') {
      url = `${url}&log_level__in=${selectedLogLevel}`;
    }
    getAllEvents(url, 99999)
      .then((res) => {
        const events = get(res, 'data.results') || [];
        const unit = 'pt';
        const size = 'A4';
        const orientation = 'landscape';

        const marginLeft = 40;
        // eslint-disable-next-line new-cap
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(12);

        const title = '';
        const headers = [[
          t('page_content.events.events.table_column_title'),
          t('page_content.events.events.table_column_description'),
          t('page_content.events.events.table_column_asset'),
          t('page_content.events.events.table_column_time'),
          t('page_content.events.events.table_column_status'),
          t('page_content.events.events.table_column_comment')]];

        const data = events.map((elt) => [
          elt.title,
          elt.description,
          elt.asset.name,
          moment(elt.ts).format(defaultDateTimeFormat),
          elt.status,
          elt.comment]);

        const content = {
          startY: 50,
          head: headers,
          body: data,
          showHead: 'firstPage',
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save('Events_report.pdf');
        this.setState({
          isLoadingExport: false,
        });
      });
  }

  render() {
    const {
      events,
      eventsSelected,
      togglerSelected,
      isLoadingEvents,
      next,
      previous,
      assets,
      selectedAsset,
      selectedStoppageAsset,
      selectedLogLevel,
      startTime,
      endTime,
      isLoadingStoppageEvents,
      stoppageEvents,
      reasons,
      isEventFormOpen,
      selectedEvent,
      isLoadingEventUpdate,
      isReadOnly,
      count,
      isLoadingExport,
      showConfirmationDialog,
      showConfirmationDialogMultiple,
      // filtersActive,
    } = this.state;

    const { t } = this.props;

    stoppageEvents.forEach((sE) => {
      if (!sE.reason) {
        sE.reason = null;
      }
    });

    // const filterTogglerClass = (filtersActive) ? 'filter-active' : '';

    const defaultEvents = [{ value: 'all', label: t('page_content.events.events.all') },
      { value: 'info', label: t('page_content.events.events.info') },
      { value: 'warning', label: t('page_content.events.events.warning') },
      { value: 'error', label: t('page_content.events.events.error') }];

    const defaultAssets = [{ id: 'all', name: assets.length ? t('page_content.events.events.all') : t('page_content.events.stoppage_events.dropdown_loading') }, ...assets];

    const stoppageTableColumns = [
      {
        accessor: 'title',
        Header: () => <span>{t('page_content.events.stoppage_events.table_column_title')}</span>,
        Cell: (row) => (<span>{row.original.log_level ? renderEventIcon(row.original.log_level) : ''}{row.value ? row.value : ''}</span>),
      },
      {
        Header: () => <span>{t('page_content.events.stoppage_events.table_column_asset')}</span>,
        accessor: 'asset',
        Cell: (row) => (row.value.name ? row.value.name : '-'),
      },
      {
        accessor: 'ts',
        Header: () => <span>{t('page_content.events.stoppage_events.table_column_time')}</span>,
        Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
      },
      {
        accessor: 'description',
        Header: () => <span>{t('page_content.events.stoppage_events.table_column_description')}</span>,
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        accessor: 'comment',
        Header: () => <span>{t('page_content.events.stoppage_events.table_column_comment')}</span>,
        Cell: (row) => (row.value ? row.value : '-'),
      },
      {
        accessor: 'reason',
        Header: () => <span>{t('page_content.events.stoppage_events.table_column_stoppage_reason')}</span>,
        Cell: (row) => (<div>
          <Select
            options={reasons}
            getOptionLabel={(r) => r.name}
            getOptionValue={(r) => r.id}
            isSearchable
            onChange={(ev) => this.onStoppageReasonSelect(ev.id, stoppageEvents[row.index])}
            value={reasons.find((reason) => reason.id === stoppageEvents.id)}
            styles={selectModalStyles}
          />
        </div>),
      },
    ];

    return (
      <div className="industry-tab industry-location-device-list events_list_container fullscreen">
        {
          isEventFormOpen && selectedEvent ?
            <EventForm
              event={selectedEvent}
              isOpen
              closeModal={this.closeEventForm}
              saveEvent={this.handleUpdateEvent}
              isLoadingState={isLoadingEventUpdate}
              isReadOnly={isReadOnly}
            /> : ''
        }
        <main>
          <Tabs>
            <TabList>
              <Tab>
                {t('page_content.events.tabs.events')}
              </Tab>
              <Tab>
                {t('page_content.events.tabs.stoppage_events')}
              </Tab>
            </TabList>
            <TabPanel>
              <div className="events_tab_container">
                <div className="filter_row">
                  <div className="selector_wrapper">
                    {`${t('page_content.events.events.log_level')}:`}
                    <Select
                      options={defaultEvents}
                      getOptionLabel={(event) => event.label}
                      getOptionValue={(event) => event.value}
                      isSearchable
                      onChange={(e) => { this.onLogLevelChange({ target: e }); }}
                      value={defaultEvents.find((d) => d.value === selectedLogLevel)}
                      styles={selectStyles}
                    />
                  </div>
                  <div className="selector_wrapper">
                    {`${t('page_content.events.events.asset')}:`}
                    <Select
                      options={defaultAssets}
                      getOptionLabel={(asset) => asset.name}
                      getOptionValue={(asset) => asset.id}
                      isSearchable
                      disabled={!defaultAssets.length}
                      onChange={(e) => { this.onAssetChange({ target: { value: e.id } }); }}
                      value={defaultAssets.find((a) => a.id === selectedAsset) || defaultAssets[0]}
                      styles={selectStyles}
                    />
                  </div>
                  <div className="datepicker_wrapper">
                    <div>
                      <ReactDatePicker
                        className="datepicker_input"
                        placeholderText={t('page_content.events.events.start_date_placeholder')}
                        dateFormat="dd.MM.yyyy HH:mm"
                        selected={startTime ? moment(startTime).toDate() : null}
                        selectsStart
                        onChange={this.onChangeStart}
                        showTimeSelect
                        timeFormat="HH:mm"
                        disabledKeyboardNavigation
                        timeIntervals={1}
                        locale={getLocale(t)}
                        timeCaption={t('date_picker_locale.time_translation')}
                      />
                    </div>
                    <div>
                      <ReactDatePicker
                        className="datepicker_input"
                        placeholderText={t('page_content.events.events.end_date_placeholder')}
                        dateFormat="dd.MM.yyyy HH:mm"
                        selected={endTime ? moment(endTime).toDate() : null}
                        selectsEnd
                        disabledKeyboardNavigation
                        onChange={this.onChangeEnd}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={1}
                        locale={getLocale(t)}
                        timeCaption={t('date_picker_locale.time_translation')}
                      />
                    </div>
                    <Button
                      disabled={!startTime && !endTime}
                      onClick={this.applyTimeFilter}
                    >
                      {t('page_content.events.events.apply_time_filter_button')}
                    </Button>
                  </div>
                  <Button
                    onClick={this.clearAllFilters}
                  >
                    {t('page_content.events.events.clear_all_button')}
                  </Button>
                  <div className="export_button">
                    <Button
                      onClick={this.exportPdf}
                      loading={isLoadingExport}
                      type="export"
                    >
                      {t('page_content.events.events.export_to_pdf_button')}
                    </Button>
                  </div>
                </div>
                <div>
                  <Table
                    columns={[
                      {
                        Header: () => {
                          return (
                            <span className="center-elements">
                              {events.length > 0 &&
                                <input type="checkbox" checked={togglerSelected} onChange={(e) => this.handleSelectAllEvents(e)} />}
                              {Object.keys(eventsSelected).length > 0 &&
                                <button type="button" disabled={isReadOnly} className="btn small" onClick={(e) => this.handleShowConfirmationDialogMultiple(e)}>
                                  ({Object.keys(eventsSelected).length}) <IconRemove color="#364252" width="12px" height="12px" />
                                </button>}
                            </span>
                          );
                        },
                        Cell: (row) => {
                          return (
                            <span className="center-elements">
                              <input type="checkbox" checked={Object.prototype.hasOwnProperty.call(eventsSelected, row.original.id)} value={get(row, 'original.id')} name="event[]" onChange={(e) => this.handleSelectEvent(row, e)} />
                            </span>
                          );
                        },
                        sortable: false,
                      },
                      {
                        Header: () => <span>{t('page_content.events.events.table_column_title')}</span>,
                        accessor: 'title',
                        Cell: (row) => {
                          return (
                            <span className="center-elements">
                              {renderEventIcon(get(row, 'original.log_level'))}
                              {row.value}
                            </span>
                          );
                        },
                      },
                      {
                        Header: () => <span>{t('page_content.events.events.table_column_description')}</span>,
                        accessor: 'description',
                      },
                      {
                        Header: () => <span>{t('page_content.events.events.table_column_asset')}</span>,
                        accessor: 'asset.name',
                      },
                      {
                        Header: () => <span>{t('page_content.events.events.table_column_time')}</span>,
                        accessor: 'ts',
                        Cell: (row) => moment(row.value).format(defaultDateTimeFormat),
                      },
                      {
                        Header: () => <span>{t('page_content.events.events.table_column_status')}</span>,
                        accessor: 'status',
                      },
                      {
                        Header: () => <span>{t('page_content.events.events.table_column_comment')}</span>,
                        accessor: 'comment',
                      },
                      {
                        Header: () => <span>{t('page_content.events.events.table_column_delete')}</span>,
                        Cell: (event) => <Button disabled={isReadOnly} className="remove-btn" onClick={(e) => this.handleShowConfirmationDialog(event.original.id, e)}><IconRemove width="12px" height="12px" /></Button>,
                        sortable: false,
                      },
                    ]}
                    // data={events.sort(eventSort)}
                    data={events}
                    defaultPageSize={500}
                    loading={isLoadingEvents}
                    handleClick={(rowInfo) => !isReadOnly && this.openEventForm(rowInfo.original)}
                    minRows={0}
                    noDataText=" "
                    showPagination={false}
                    sortable
                    defaultSorted={[{ id: this.state.selectedSort, desc: this.state.selectedAscDesc }]}
                    onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
                  />
                  <TableButtons previous={previous} next={next} fetchFunction={this.fetchData} count={count} />
                </div>
              </div>

            </TabPanel>
            <TabPanel>
              <div className="stoppage_tab_container">
                <div className="filter_row">
                  {`${t('page_content.events.stoppage_events.asset')}:`}
                  <Select
                    options={defaultAssets}
                    getOptionLabel={(asset) => asset.name}
                    getOptionValue={(asset) => asset.id}
                    isSearchable
                    disabled={!assets.length}
                    onChange={(e) => { this.onStoppageAssetChange({ target: { value: e.id } }); }}
                    value={defaultAssets.find((a) => a.id === selectedStoppageAsset) || defaultAssets[0]}
                    styles={selectStyles}
                  />
                </div>
                <Table
                  columns={stoppageTableColumns}
                  data={stoppageEvents}
                  defaultPageSize={100}
                  loading={isLoadingStoppageEvents}
                  minRows={0}
                  noDataText=" "
                  showPagination={false}
                  sortable
                  manual={false}
                />
              </div>
            </TabPanel>
          </Tabs>
        </main>
        <ConfirmationModal
          showModal={showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDeleteConfirm}
          type="warning"
        />
        <ConfirmationModal
          customTitle={t('page_content.events.events.confirm_delete_msg', { number: Object.keys(this.state.eventsSelected).length })}
          showModal={showConfirmationDialogMultiple}
          handleCloseModal={() => this.setState({ showConfirmationDialogMultiple: false })}
          handleConfirmModal={this.submitMultipleSelection}
          type="warning"
        />
      </div>
    );
  }
}

IndustryLocationEventList.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationId: PropTypes.number.isRequired,
  updateManualRefresh: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  dispatchDateRange: setDateRange,
  updateManualRefresh: industryDashboardActions.updateManualRefresh,
  setCurrentUser: getCurrentUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(IndustryLocationEventList)));
