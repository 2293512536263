import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, ConfirmationModal } from 'shared';
import { IconEdit, IconRemove } from 'shared/Icons';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';

class ProductionRecordsEditTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmationDialog: false,
      deleteData: {},
    };
  }

  handleShowDeleteConfirmation = (record) => {
    this.setState({ showConfirmationDialog: true, deleteData: record });
  };

  handleDeleteRecordConfirmed = () => {
    this.props.handleDeleteRecord(this.state.deleteData);
    this.setState({ showConfirmationDialog: false });
  }

  render() {
    const {
      t,
      isReadOnly,
      listOfRecords,
      isLoadingRecords,
      mapAssetIdsToNames,
      handleSelectedRecord,
    } = this.props;

    return (
      <div>
      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span>{t('page_content.production_records.table_column_title')}</span>,
            accessor: 'title',
            Cell: (row) => (row && row.value ? row.value : '-'),
          },
          {
            Header: () => <span>{t('page_content.production_records.asset')}</span>,
            accessor: 'assets',
            Cell: (row) => (
              <span style={{ wordBreak: 'word-break' }}>
                {row.value && row.value.length !== 0
                  ? mapAssetIdsToNames(row.value).join(', ')
                  : '-'}
              </span>
            ),
          },
          {
            Header: () => <span>{t('page_content.production_records.table_column_is_active')}</span>,
            accessor: 'is_active',
            width: 100,
            Cell: (row) => (<div style={{ margin: 'auto' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>),
          },
          {
            Header: () => <span>{t('page_content.production_records.table_column_edit')}</span>,
            sortable: false,
            width: 100,
            Cell: (record) => <div style={{ margin: 'auto' }}><Button disabled={isReadOnly} onClick={() => handleSelectedRecord(record)}><IconEdit width="12px" height="12px" /></Button></div>,
          },
          {
            Header: () => <span>{t('page_content.production_records.table_column_delete')}</span>,
            sortable: false,
            width: 100,
            Cell: (record) => <div style={{ margin: 'auto' }}><Button disabled={isReadOnly} type="delete" onClick={() => this.handleShowDeleteConfirmation(record)}><IconRemove width="12px" height="12px" /></Button></div>,
          },
        ]}
        data={listOfRecords || []}
        loading={isLoadingRecords}
        noDataText=" "
        showPagination={false}
        defaultPageSize={30}
        minRows={0}
        manual={false}
        defaultSorted={[{ id: 'title', desc: false }]}
      />
       <ConfirmationModal
         itemName={this.state.deleteData?.original?.title}
         showModal={this.state.showConfirmationDialog}
         handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
         handleConfirmModal={() => this.handleDeleteRecordConfirmed()}
         type="warning"
       />
    </div>
    );
  }
}

ProductionRecordsEditTable.propTypes = {
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  listOfRecords: PropTypes.array.isRequired,
  isLoadingRecords: PropTypes.bool.isRequired,
  handleDeleteRecord: PropTypes.func.isRequired,
  mapAssetIdsToNames: PropTypes.func.isRequired,
  handleSelectedRecord: PropTypes.func.isRequired,
};

export default ProductionRecordsEditTable;
