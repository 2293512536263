import { Responsive } from 'react-grid-layout';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sizeMe from 'react-sizeme';
import 'react-grid-layout/css/styles.css';
// eslint-disable-next-line
import 'react-resizable/css/styles.css';
import Widget from '../Widget/index';
import './style.scss';
import { sizeMeHack } from './helpers';
import { getCompanyData } from './actions';
import { ContentLoader } from '../../index';

const ResponsiveReactGridLayout = sizeMe()(sizeMeHack(Responsive));
const RowHeight = 50;
const minWidth = 4;
const minHeight = 4;
const minScalarHeight = 1.5;
const minScalarWidth = 2;
const minRealtimeWidgetHeight = 6;
const minRealtimeWidgetWidth = 3;
const minGanttHeight = 3;
const minGanttWidth = 3;
const minLabelHeight = 1.5;

const WidgetWithSizes = sizeMe()(Widget);

class WidgetGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resizingWidget: null,
      currentBreakpoint: 'lg',
      dashboard_grid_density: 24,
      isLoadingGrid: true,
      company_short_code: null,
    };
  }

  componentDidMount() {
    const { companyId } = this.props;
    getCompanyData(companyId)
      .then((re) => {
        const dashboard_grid_density = re && re.data && re.data.config && re.data.config.dashboard_grid_density ? re.data.config.dashboard_grid_density : 24;
        const company_short_code = re && re.data && re.data.short_code ? re.data.short_code : null;
        this.setState({
          dashboard_grid_density,
          company_short_code,
          isLoadingGrid: false,
        });
      });
  }

  getLayoutConfig = (layout, key) => {
    const { currentBreakpoint } = this.state;
    const layouts = layout[currentBreakpoint] || [];

    return layouts.find((x) => x.i === key);
  }

  handleBreakPointChange = (breakpoint) => {
    this.setState({ currentBreakpoint: breakpoint });
  }

  handleLayoutChange = (currentLayout, allLayouts) => {
    const { layouts } = this.props;
    const different = JSON.stringify(allLayouts) !== JSON.stringify(layouts);

    if (!different) {
      return;
    }

    if (this.props.onLayoutChange) {
      this.props.onLayoutChange(allLayouts);
    }
  }

  handleResize = (layout, oldLayoutItem, layoutItem, placeholder) => {
    const { widgets } = this.props;
    const id = layoutItem.i;
    const w = widgets.find((wdg) => wdg.id === id);
    const num_of_metrics = w && w.widgetData ? (w.widgetData.length + 1) < 3 ? 3 : w.widgetData.length + 1 : minGanttHeight;

    if (w.type === 'gantt') {
      if (w.settings && w.settings.appearance && !w.settings.appearance.oneline_events) {
        if (layoutItem.h < num_of_metrics && layoutItem.w < minGanttWidth) {
          layoutItem.h = num_of_metrics;
          layoutItem.w = minGanttWidth;
          placeholder.h = num_of_metrics;
          placeholder.w = minGanttWidth;
        } else if (layoutItem.w < minGanttWidth) {
          layoutItem.w = minGanttWidth;
          placeholder.w = minGanttWidth;
        } else if (layoutItem.h < num_of_metrics) {
          layoutItem.h = num_of_metrics;
          placeholder.h = num_of_metrics;
        }
      } else if (layoutItem.h < minGanttHeight && layoutItem.w < minGanttWidth) {
        layoutItem.h = minGanttHeight;
        layoutItem.w = minGanttWidth;
        placeholder.h = minGanttHeight;
        placeholder.w = minGanttWidth;
      } else if (layoutItem.w < minGanttWidth) {
        layoutItem.w = minGanttWidth;
        placeholder.w = minGanttWidth;
      } else if (layoutItem.h < minGanttHeight) {
        layoutItem.h = minGanttHeight;
        placeholder.h = minGanttHeight;
      }
    } else if (w.type === 'realtime') {
      if (layoutItem.h < minRealtimeWidgetHeight && layoutItem.w < minRealtimeWidgetWidth) {
        layoutItem.h = minRealtimeWidgetHeight;
        layoutItem.w = minRealtimeWidgetWidth;
        placeholder.h = minRealtimeWidgetHeight;
        placeholder.w = minRealtimeWidgetWidth;
      } else if (layoutItem.w < minRealtimeWidgetWidth) {
        layoutItem.w = minRealtimeWidgetWidth;
        placeholder.w = minRealtimeWidgetWidth;
      } else if (layoutItem.h < minRealtimeWidgetHeight) {
        layoutItem.h = minRealtimeWidgetHeight;
        placeholder.h = minRealtimeWidgetHeight;
      }
    } else if (w.type === 'scalar') {
      if (layoutItem.h < minScalarHeight && layoutItem.w < minScalarWidth) {
        layoutItem.h = minScalarHeight;
        layoutItem.w = minScalarWidth;
        placeholder.h = minScalarHeight;
        placeholder.w = minScalarWidth;
      } else if (layoutItem.w < minScalarWidth) {
        layoutItem.w = minScalarWidth;
        placeholder.w = minScalarWidth;
      } else if (layoutItem.h < minScalarHeight) {
        layoutItem.h = minScalarHeight;
        placeholder.h = minScalarHeight;
      }
    } else if (w.type === 'label') {
      const minWidthLabel = w.settings.labelText.length * 0.2;
      if (w.settings && w.settings.appearance && !w.settings.appearance.oneline_events) {
        if (layoutItem.h < minLabelHeight && layoutItem.w < minWidthLabel) {
          layoutItem.h = minLabelHeight;
          layoutItem.w = minWidthLabel;
          placeholder.h = minLabelHeight;
          placeholder.w = minWidthLabel;
        } else if (layoutItem.w < minWidthLabel) {
          layoutItem.w = minWidthLabel;
          placeholder.w = minWidthLabel;
        } else if (layoutItem.h < minLabelHeight) {
          layoutItem.h = minLabelHeight;
          placeholder.h = minLabelHeight;
        }
      } else if (layoutItem.h < minLabelHeight && layoutItem.w < minWidthLabel) {
        layoutItem.h = minLabelHeight;
        layoutItem.w = minWidthLabel;
        placeholder.h = minLabelHeight;
        placeholder.w = minWidthLabel;
      } else if (layoutItem.w < minWidthLabel) {
        layoutItem.w = minWidthLabel;
        placeholder.w = minWidthLabel;
      } else if (layoutItem.h < minLabelHeight) {
        layoutItem.h = minLabelHeight;
        placeholder.h = minLabelHeight;
      }
    } else {
      // eslint-disable-next-line
      if (layoutItem.h < minHeight && layoutItem.w < minWidth) {
        layoutItem.h = minHeight;
        layoutItem.w = minWidth;
        placeholder.h = minHeight;
        placeholder.w = minWidth;
      } else if (layoutItem.w < minWidth) {
        layoutItem.w = minWidth;
        placeholder.w = minWidth;
      } else if (layoutItem.h < minHeight) {
        layoutItem.h = minHeight;
        placeholder.h = minHeight;
      }
    }
  }

  handleResizeStart = (layout, oldItem) => {
    const { i } = oldItem;
    this.setState({ resizingWidget: i });
  }

  handleResizeStop = () => {
    this.setState({ resizingWidget: null });
  }

  renderWidgets = () => {
    const { resizingWidget, isLoadingGrid, company_short_code } = this.state;
    const {
      isLocked,
      layouts,
      widgets,
      onRemoveWidget,
      onCopyWidget,
      onEditWidget,
      widgetMeta,
      dashboardId,
      locationId,
      manualRefresh,
      updateManualRefresh,
      setDateRange,
      globalFilters,
      companyId,
    } = this.props;

    if (!widgets) {
      return;
    }

    if (isLoadingGrid) {
      return;
    }

    return widgets.map((x) => {
      const layoutSetting = this.getLayoutConfig(layouts, x.id);
      const widgetHeight = layoutSetting ? layoutSetting.h : 1;
      // eslint-disable-next-line
      const height = RowHeight * widgetHeight + widgetHeight * 10 - 50;

      return (
        <div
          key={x.id}
          id={x.id}
        >
          <WidgetWithSizes
            isLocked={isLocked}
            key={x.id}
            height={height}
            onRemove={onRemoveWidget}
            onCopy={onCopyWidget}
            onEdit={onEditWidget}
            meta={widgetMeta}
            isResizing={x.id === resizingWidget}
            company_short_code={company_short_code}
            dashboardId={dashboardId}
            locationId={locationId}
            companyId={companyId}
            manualRefresh={manualRefresh}
            updateManualRefresh={updateManualRefresh}
            setDateRange={setDateRange}
            globalFilters={globalFilters}
            {...x}
          />
        </div>
      );
    });
  }

  render() {
    const {
      isLocked,
      layouts,
    } = this.props;

    const { dashboard_grid_density, isLoadingGrid } = this.state;

    if (isLoadingGrid) {
      return (
        <div className="loaderWrapper">
          <ContentLoader />
        </div>
      );
    }

    return (
      // eslint-disable-next-line react/no-unused-class-component-methods
      <div style={{ height: '100%', width: '100%' }} ref={(input) => { this.widget = input; }}>
        {
          !isLoadingGrid &&
          <ResponsiveReactGridLayout
            isDraggable={!isLocked}
            isResizable={!isLocked}
            breakpoints={{ lg: 768, md: 450, s: 0 }}
            cols={{ lg: dashboard_grid_density, md: 1, s: 1 }}
            className="layout"
            layouts={layouts}
            rowHeight={RowHeight}
            onLayoutChange={this.handleLayoutChange}
            onBreakpointChange={this.handleBreakPointChange}
            draggableHandle=".widget-header"
            onResize={this.handleResize}
            onResizeStart={this.handleResizeStart}
            onResizeStop={this.handleResizeStop}
          >
            {this.renderWidgets()}
          </ResponsiveReactGridLayout>
        }
      </div>
    );
  }
}

WidgetGrid.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  globalFilters: PropTypes.object,
  layouts: PropTypes.object.isRequired,
  onRemoveWidget: PropTypes.func,
  onCopyWidget: PropTypes.func,
  onLayoutChange: PropTypes.func,
  onEditWidget: PropTypes.func,
  widgetMeta: PropTypes.shape({
    interval: PropTypes.number,
    selectedRange: PropTypes.number,
  }),
  locationId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
  updateManualRefresh: PropTypes.func.isRequired,
  manualRefresh: PropTypes.bool,
  isLocked: PropTypes.bool,
  widgets: PropTypes.any,
  setDateRange: PropTypes.any,
};

WidgetGrid.defaultProps = {
  isLocked: false,
  widget: [],
  updateManualRefresh: () => { },
  manualRefresh: false,
};

export default WidgetGrid;
