import update from 'immutability-helper';
import { timeRangeEnum, timeRangeConfig } from 'shared/DatePicker/constants';
import moment from 'moment';
import * as actionTypes from './actionTypes';
import { lsGet, lsSet } from './helpers';

const ItemTypeEnum = {
  drone: 'drone',
  company: 'company',
  location: 'location',
};

// eslint-disable-next-line
const MaxDuration = 365 / 2 * 24 * 60 * 60 * 1000;

const initialState = {
  isLoadingModuleAccess: true,
  user: null,
  userIsCompanyAdmin: false,
  interval: 30,
  rangePickerVisible: false,
  selectedRange: 60,
  sidebarCollapsed: lsGet('sidebarCollapsed') || false,
  company: null,
  location: null,
  drone: null,
  sidebarItems: [],
  sidebarItemType: ItemTypeEnum.company,
  daterange: {
    start: timeRangeConfig()[timeRangeEnum.last_24_hours].startDate,
    end: timeRangeConfig()[timeRangeEnum.last_24_hours].endDate,
    label: timeRangeEnum.last_24_hours,
  },
  companyConfig: null,
  isLoadingCompanyConfig: false,
  companyConfigError: null,
  locationConfig: null,
  isLoadingLocationConfig: false,
  locationConfigError: null,
};

const getItemType = ({ company, location, drone }) => {
  if (!!drone || !!location) {
    return ItemTypeEnum.drone;
  }
  if (company) {
    return ItemTypeEnum.location;
  }

  return ItemTypeEnum.company;
};

const zoomOut = (state) => {
  const {
    daterange: {
      start,
      end,
      label,
    },
  } = state;

  let diffStart = start;
  let diffEnd = end;
  if (label !== timeRangeEnum.custom && label !== timeRangeEnum.nth_week && label !== timeRangeEnum.customYesterday && label !== timeRangeEnum.customToday) {
    diffStart = timeRangeConfig()[label].startDate;
    diffEnd = timeRangeConfig()[label].endDate;
  }

  let duration = moment.duration(diffEnd.diff(diffStart));
  const durationMiliseconds = duration.asMilliseconds();

  if (durationMiliseconds === 0) {
    duration = 1000;
  }

  const newEnd = moment.min(diffEnd.clone().add(durationMiliseconds, 'ms'), moment());
  const newStart = moment.max(diffStart.clone().subtract(durationMiliseconds, 'ms'), newEnd.clone().subtract(MaxDuration, 'ms'));

  const newDateRange = {
    label: timeRangeEnum.custom,
    start: newStart.startOf('second'),
    end: newEnd.startOf('second'),
  };

  return newDateRange;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.fetchPageMetaInit:
      return { ...state, sidebarLoading: true };

    case actionTypes.setUserIsCompanyAdmin:
      return { ...state, userIsCompanyAdmin: action.payload };

    case actionTypes.fetchPageMetaSuccess:
      const {
        company,
        location,
        drone,
        items,
      } = action.payload;
      const itemType = getItemType(action.payload);
      return {
        ...state,
        company,
        drone,
        sidebarLoading: false,
        location,
        sidebarItems: [...items],
        sidebarItemType: itemType,
      };

    case actionTypes.fetchPageMetaError:
      return { ...state, sidebarLoading: false };

    case actionTypes.setInterval:
      return { ...state, interval: action.payload.interval };

    case actionTypes.toggleSidebar:
      lsSet({ sidebarCollapsed: !state.sidebarCollapsed });
      return { ...state, sidebarCollapsed: !state.sidebarCollapsed };

    case actionTypes.patchCompany:
      return update(state, {
        company: {
          name: {
            $set: action.payload.name,
          },
        },
      });

    case actionTypes.patchLocation:
      return update(state, {
        location: {
          name: {
            $set: action.payload.name,
          },
        },
      });

    case actionTypes.postLocation:
      return update(state, {
        sidebarItems: {
          $push: [action.payload],
        },
      });

    case actionTypes.setDateRange:
      return update(state, {
        daterange: {
          $set: {
            ...action.payload,
          },
        },
      });

    case actionTypes.zoomOut:
      return update(state, {
        daterange: {
          $set: {
            ...zoomOut(state),
          },
        },
      });

    case actionTypes.fetchCompanyConfigRequest:
      return {
        ...state,
        isLoadingCompanyConfig: true,
        companyConfigError: null,
      };
    case actionTypes.fetchCompanyConfigSuccess:
      return {
        ...state,
        isLoadingCompanyConfig: false,
        companyConfig: action.payload,
      };
    case actionTypes.fetchCompanyConfigFailure:
      return {
        ...state,
        isLoadingCompanyConfig: false,
        companyConfigError: action.payload,
      };

    case actionTypes.fetchLocationConfigRequest:
      return {
        ...state,
        isLoadingLocationConfig: true,
        locationConfigError: null,
      };
    case actionTypes.fetchLocationConfigSuccess:
      return {
        ...state,
        isLoadingLocationConfig: false,
        locationConfig: action.payload,
      };
    case actionTypes.fetchLocationConfigFailure:
      return {
        ...state,
        isLoadingLocationConfig: false,
        locationConfigError: action.payload,
      };

    default:
      return state;
  }
}
