import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import { Notification } from 'shared';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ShiftTimes from './Shifts/ShiftTimes';
import { getShifts } from './actions';
import './style.scss';
import Timetable from './Timetable';
import ShiftAuditLogs from './AuditLogs';

class ShiftsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shifts: [],
      isReadOnly: false,
    };
  }

  componentDidMount() {
    const {
      companyId,
      locationId,
    } = this.props;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    getShifts(locationId)
      .then((re) => {
        this.setState({ shifts: get(re, 'data.results') });
      })
      .catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  render() {
    const {
      t,
      locationId,
      companyId,
    } = this.props;

    const {
      shifts,
      isReadOnly,
    } = this.state;

    return (
      <div className="shifts_module fullscreen tabs">
        <Tabs>
          <TabList>
            <Tab>{t('settings.shifts.timetable_tab')}</Tab>
            <Tab>{t('settings.shifts.shifts_tab')}</Tab>
            <Tab>{t('settings.shifts.shift_audit_logs')}</Tab>
          </TabList>
          <TabPanel>
            <Timetable
              shifts={shifts}
              companyId={companyId}
              locationId={locationId}
            />
          </TabPanel>
          <TabPanel>
            <div className="shifts_tab">
              <ShiftTimes
                locationId={locationId}
                shifts={shifts}
                isReadOnly={isReadOnly}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <ShiftAuditLogs
              companyId={companyId}
              locationId={locationId}
              t={t}
            />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

ShiftsTab.propTypes = {
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
};

export default withTranslation()(ShiftsTab);
