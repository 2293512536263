import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { get } from 'lodash';
import moment from 'moment';

import { multiSelectStylesAutoHeight } from 'styles/modules/reactSelect';
import { getLocale } from 'shared/DatePicker/constants';
import { Modal, ContentLoader } from 'shared';
import { IconX } from 'shared/Icons';
import './styles.scss';

import { deleteUploadedFile, finishSurvey, getQuestionOptions, getQuestions, getSurveyAnswers, getUploadedFiles, saveSurveyFiles, saveWorkerAnswer, createWorkerSurvey } from '../../actions';

const FormModal = ({ formData, showModal, handleClose, companyId, currentUser, t, isRefill }) => {
  const debounceTimeoutRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formQuestions, setFormQuestions] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});
  const [refillAnswers, setRefillAnswers] = useState({});

  const fetchSurveyAnswers = async (questionsData) => {
    const res = await getSurveyAnswers('', formData?.worker?.id, `&worker_survey=${formData?.id}`);
    const surveyAnswersData = get(res, 'data.results', []);
    const newData = questionsData?.map((question) => {
      const data = question;
      const foundQuestion = surveyAnswersData?.find((q) => q?.question?.id === question?.id);
      if (foundQuestion?.question?.question_type === 'choice') {
        const foundIndex = question?.answers.findIndex((a) => a?.value === foundQuestion?.answer);
        data.answer = foundQuestion.answer;
        data.answers[foundIndex].answer = true;
      }
      if (foundQuestion?.question?.question_type === 'select') {
        data.answer = foundQuestion.answer;
      }
      if (foundQuestion?.question?.question_type === 'number') {
        data.answer = foundQuestion.answer;
      }
      if (foundQuestion?.question?.question_type === 'free_text') {
        data.text_answer = foundQuestion.text_answer;
      }
      if (foundQuestion?.question?.question_type === 'date') {
        data.date_answer = foundQuestion.date_answer;
        setSelectedDates(prevSelectedDates => ({
          ...prevSelectedDates,
          [question.id]: foundQuestion.date_answer ? new Date(foundQuestion.date_answer) : null
        }));
      }
      return data;
    });

    setFormQuestions(newData);
  };

  const fetchQuestionsAndAnswers = async () => {
    setIsLoading(true);
    const res = await getQuestions(!isRefill && formData?.survey?.id || isRefill && formData?.id);
    const questionsData = get(res, 'data.results', []);
    await Promise.all(questionsData.map(async (data, i) => {
      const resp = await getQuestionOptions(data.id);
      const respData = get(resp, 'data.results', []);
      if (respData?.length) {
        questionsData[i].answers = respData;
      }
    }));
    if (!isRefill && formData?.survey?.id) fetchSurveyAnswers(questionsData);
    else if (isRefill) setFormQuestions(questionsData);
    setIsLoading(false);
  };

  const fetchUploadedFiles = async () => {
    const res = await getUploadedFiles(companyId, formData.id);
    const uploadedFilesData = get(res, 'data.results', []);
    setSelectedFiles(uploadedFilesData);
    setUploadedFiles(uploadedFilesData);
  };

  useEffect(() => {
    if ((!isRefill && formData?.survey?.id) || (isRefill && formData?.id)) {
      fetchQuestionsAndAnswers();
      if (!isRefill && formData?.survey?.id) fetchSurveyAnswers();
      if (!isRefill && formData?.survey?.file_upload) {
        fetchUploadedFiles();
      }
    }
  }, [formData]);

  const handleChangeChoice = async (answerValue, index, question) => {
    const questionFound = formQuestions.find((q) => q.id === question.id);
    let answerId = null;

    const updatedAnswers = await Promise.all(questionFound.answers.map(async (a, i) => {
      const data = {};
      const ans = a;
      if (i === index) {
        data.worker_survey = !isRefill ? formData?.id : null;
        data.question = question?.id;
        data.answer = answerValue;
        if (isRefill) setRefillAnswers((prevState) => ({ ...prevState, [question?.id]: { ...data } }));
        else await saveWorkerAnswer(data);
        ans.answer = true;
        answerId = a.id;
      } else {
        ans.answer = false;
      }
      return ans;
    }));
    const updatedFormQuestions = formQuestions.map((q) => {
      if (q.id === question.id) {
        return { ...q, answer: answerId, answers: updatedAnswers };
      }
      return q;
    });
    setFormQuestions(updatedFormQuestions);
  };

  const onChangeAttachments = (e) => {
    const files = e.target.files;
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    const processedFiles = Array.from(files).map((file) => {
      const fileName = file.name.split('.').slice(0, -1).join('.');
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
      let isImage = false;
      if (acceptedImageTypes.includes(file?.type)) {
        isImage = true;
      }
      const fileNameExists = selectedFiles.find((f) => f.name === fileName);
      if (fileNameExists) {
        return null;
      }
      const documentInfo = {
        name: file?.name,
        extension,
        isImage,
        file,
        worker_survey: !isRefill ? formData.id : null,
      };
      return documentInfo;
    });
    const filterNullValues = processedFiles.filter((f) => f !== null);
    const currentFiles = [...selectedFiles, ...filterNullValues];
    setSelectedFiles(currentFiles);
  };

  const handleRemoveFile = async (file) => {
    if (!formData.is_finished) {
      const fileFound = uploadedFiles.find((f) => f.name === file.name);
      if (fileFound) {
        await deleteUploadedFile(fileFound.id);
      }
      setSelectedFiles((prevState) => {
        const files = [...prevState];
        const newFiles = files.filter((f) => f.name !== file.name);
        return newFiles;
      });
    }
  };

  const handleInputChange = (value, question) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    const updatedQuestions = [...formQuestions];
    const foundQuestion = updatedQuestions.find((q) => q.id === question.id);

    const data = {};
    data.worker_survey = !isRefill ? formData?.id : null;
    data.question = question?.id;
    data.answer = value;
    if (foundQuestion && question.question_type === 'free_text') {
      foundQuestion.text_answer = value;
    }
    if (foundQuestion && question.question_type === 'number') {
      foundQuestion.answer = value;
    }
    if (question.question_type === 'number' &&
          (question.number_limit_from || question.number_limit_to) &&
          (value < question.number_limit_from || value > question.number_limit_to)) {
            foundQuestion.number_not_valid = true;
            setFormQuestions(updatedQuestions);
            return
          }
          else {
            foundQuestion.number_not_valid = false;
            setFormQuestions(updatedQuestions);
          }

    debounceTimeoutRef.current = setTimeout(async () => {
      if (value && !isRefill) {
        await saveWorkerAnswer(data);
      } else {
        setRefillAnswers((prevState) => ({ ...prevState, [question?.id]: { ...data } }));
      }
    }, 1000);
    setFormQuestions(updatedQuestions);
  };

  const handleDateChange = async (value, question) => {
    setSelectedDates({
      ...selectedDates,
      [question.id]: value,
    });
    const updatedQuestions = [...formQuestions];
    const foundQuestion = updatedQuestions.find((q) => q.id === question.id);
    const data = {};
    data.worker_survey = !isRefill ? formData?.id : null;
    data.question = question?.id;
    data.answer = moment(value).format('YYYY-MM-DD');
    foundQuestion.date_answer = value;
    if (isRefill) setRefillAnswers((prevState) => ({ ...prevState, [question?.id]: { ...data } }));
    else await saveWorkerAnswer(data);
  };

  const handleSelectChange = async (values, question) => {
    const data = {};
    let answers = '';

    if (question?.multi_select) {
      answers = values?.length ? values.map((val) => val.value).join(',') : '';
    } else {
      answers = values?.value || '';
    }
  
    if (values?.length || values) {
      data.worker_survey = !isRefill ? formData?.id : null;
      data.question = question?.id;
      data.answer = answers;
      if (isRefill) setRefillAnswers((prevState) => ({ ...prevState, [question?.id]: { ...data } }));
      else await saveWorkerAnswer(data);
    }
  
    const foundIndex = formQuestions.findIndex((q) => q?.id === question?.id);
    const updatedQuestions = [...formQuestions];
  
    if (foundIndex !== -1) {
      updatedQuestions[foundIndex].answer = answers || '';
    }
  
    setFormQuestions(updatedQuestions);
  };

  const handleSaveAnswers = async () => {
    if (!isRefill) {
      if (selectedFiles.length) {
        for (const file of selectedFiles) {
          const fileFound = uploadedFiles.find((f) => f.name === file.name);
          if (!fileFound) {
            const data = file;
            data.company = companyId;
            data.worker_survey = file.worker_survey;
            data.file = file.file;
            const newFormData = new FormData();
            newFormData.append('file', file.file);
            newFormData.append('worker_survey', file.worker_survey);
            newFormData.append('name', file.name);
            newFormData.append('user', currentUser.id);
            saveSurveyFiles(newFormData);
          }
        }
      }
      await finishSurvey({ worker_survey: formData?.id }).then(() => handleClose()).catch((error) => console.error(error));
    } else if (isRefill) {
      const payload = {
        is_seen: true,
        start_survey: moment().toISOString(),
        finish_time: moment().toISOString(),
        user: currentUser?.id,
        survey: formData?.id,
        worker: formData?.workerId,
      };
      await createWorkerSurvey(payload)
        .then(async (res) => {
          const result = get(res, 'data', []);
          const refillAnswersArray = Object.values(refillAnswers);
          await Promise.all(refillAnswersArray.map(async (q) => await saveWorkerAnswer({ ...q, answer: q?.answer || null, worker_survey: result?.id })))
            .then(async () => {
              if (selectedFiles.length) {
                const uploadPromises = selectedFiles.map(async (file) => {
                  const fileFound = uploadedFiles.find((f) => f.name === file.name);
                  if (!fileFound) {
                    const data = file;
                    data.company = companyId;
                    data.worker_survey = result?.id;
                    data.file = file.file;
                    const newFormData = new FormData();
                    newFormData.append('file', file.file);
                    newFormData.append('worker_survey', result?.id);
                    newFormData.append('name', file.name);
                    newFormData.append('user', currentUser.id);
                    await saveSurveyFiles(newFormData);
                  }
                });
    
                await Promise.all(uploadPromises);
              }
              await finishSurvey({ worker_survey: result?.id });
              handleClose();
              setRefillAnswers({});
            })
            .catch((error) => {
              console.error(error);
              setIsLoading(false);
            });
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  const handleCloseModal = () => {
    setSelectedFiles([]);
    setFormQuestions([]);
    setRefillAnswers({});
    handleClose();
  };

  let allAnswers = true;
  formQuestions?.forEach((q) => {
    if (!q.text_answer && q.question_type === 'free_text' && q.is_required) {
      allAnswers = false;
      return;
    }
    if (!q.answer && (q.question_type === 'choice' || q.question_type === 'select' || q.question_type === 'number') && q.is_required) {
      allAnswers = false;
    }
  });

  return (
    <Modal
      closeText={t('page_content.human_resources.forms.modal_close_text')}
      saveText={t('page_content.human_resources.forms.modal_save_text')}
      title={isRefill ? formData?.name : formData?.survey?.name}
      isOpen={showModal}
      handleClose={handleCloseModal}
      handleSave={!formData?.is_finished && handleSaveAnswers}
      disableSave={!allAnswers}
    >
    <div>
        {isLoading ? <ContentLoader /> :
        <div className="form_modal_content">
          {
            ((!isRefill && formData?.survey?.description) || (isRefill && formData?.description)) && (
              <div className="form_modal_description">{isRefill ? formData?.description : formData?.survey?.description}</div>
            )
          }
            {formQuestions?.map((q) => {
              return <div className="question_forms_container" key={q.id}>
                <div className="question_title">
                    <div className="question_index">{q.order}</div>
                    <div className="question_question">{q.question}</div>
                    <div className="question_is_required">{q?.is_required ?
                      t('page_content.human_resources.forms.answer_required') :
                      t('page_content.human_resources.forms.answer_optional')}
                    </div>
                </div>
                <div className="question_answer_content">
                    {q?.answers?.length && (q?.question_type === 'choice' || q?.question_type === 'select') ? <div>
                        {q?.question_type === 'choice' && <div>
                                {q.answers.map((a, i) => {
                                  return <div key={a.id} className="radio_answer_inline">
                                        <div className="radio_input"><input disabled={formData?.is_finished} checked={a.answer || false} onChange={() => handleChangeChoice(a.value, i, q)} type="radio" /></div>
                                        <span className="checkbox_label">{a.label}</span>
                                    </div>;
                                })}
                            </div>}
                        {q?.question_type === 'select' && <div>
                        <Select
                          options={q.answers}
                          getOptionLabel={(type) => type.label}
                          getOptionValue={(type) => type.value}
                          placeholder={t('page_content.human_resources.forms.placeholder_select_answers')}
                          menuPosition="fixed"
                          onChange={(value) => handleSelectChange(value, q)}
                          isDisabled={formData?.is_finished}
                          isSearchable
                          isMulti={q?.multi_select}
                          value={
                            q?.multi_select
                              ? q?.answers?.filter((a) => q?.answer?.split(',').some((ans) => a.value === ans)) || ''
                              : q?.answers?.find((a) => a.value === q?.answer) || ''
                          }
                          styles={multiSelectStylesAutoHeight}
                        />
                        </div>}
                    </div> :
                      q?.question_type === 'date' ?
                    <div className="date_field_answer">
                      <DatePicker
                        className="datepicker_input"
                        placeholderText={t('page_content.human_resources.forms.placeholder_date_picker')}
                        dateFormat="dd.MM.yyyy"
                        selected={selectedDates[q.id]}
                        disabled={formData?.is_finished}
                        onChange={(date) => handleDateChange(date, q)}
                        locale={getLocale(t)}
                      />
                  </div> :
                    <div className="question_answer_content-input">
                      <input
                        type="text"
                        disabled={formData?.is_finished}
                        value={(q?.question_type === 'number' ? q.answer : q.text_answer) || ''}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const regex = /^\d*$/;
                          if (q?.question_type === 'number' && regex.test(inputValue)) handleInputChange(inputValue, q);
                          else if (q?.question_type === 'free_text') handleInputChange(inputValue, q);
                        }}
                      />
                      {q.number_not_valid &&
                        <div className="validation_number_error">
                          {`${t('page_content.human_resources.forms.number_not_in_range')}
                           (${q.number_limit_from}-${q.number_limit_to})`}</div>
                      }
                      </div>}
                </div>
                </div>;
            })}
            {((!isRefill && formData?.survey?.file_upload) || (isRefill && formData?.file_upload)) && <div className="file_upload_container">
                <span>{t('page_content.human_resources.forms.upload_files_label')}</span>
                <div className="input_container">
                    <input type="file" disabled={formData?.is_finished} multiple onChange={onChangeAttachments} />
                </div>
                {selectedFiles?.length ? <div className="selected_file_names">
                        {selectedFiles.map((f) => {
                          return <span key={f?.name} className="selected_files_inline">
                            {!formData.is_finished && <div onClick={() => handleRemoveFile(f)} className="icon_remove_file">
                                <IconX width="20px" height="20px" /></div>}
                                <span className="file_name_text">{f?.name}</span>
                                </span>;
                        })}</div> : ''}
                </div>}
        </div>}
    </div>
</Modal>
  );
};

FormModal.propTypes = {
  t: PropTypes.func.isRequired,
  formData: PropTypes.object,
  showModal: PropTypes.bool,
  handleClose: PropTypes.func,
  companyId: PropTypes.number,
  currentUser: PropTypes.object,
  isRefill: PropTypes.bool.isRequired,
};

export default (withTranslation()(FormModal));
