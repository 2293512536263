import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props} viewBox="0 0 32 32" fill="none">
  <path opacity="0.2" d="M25.6633 8.88501C25.3078 9.01121 24.9239 9.03409 24.556 8.95099C24.1881 8.86789 23.8512 8.68223 23.5845 8.41551C23.3178 8.14879 23.1321 7.81195 23.049 7.44402C22.9659 7.0761 22.9888 6.69216 23.115 6.3367C20.634 4.51001 17.5454 3.70757 14.4889 4.09558C11.4325 4.48359 8.64237 6.03232 6.69646 8.42101C4.75055 10.8097 3.79794 13.8553 4.03592 16.9271C4.27391 19.9989 5.68425 22.8614 7.97486 24.9219V24.9218C8.72778 23.4405 9.87609 22.1966 11.2926 21.3279C12.709 20.4592 14.3384 19.9996 16 20C15.0111 20 14.0444 19.7068 13.2222 19.1574C12.3999 18.6079 11.759 17.8271 11.3806 16.9134C11.0022 15.9998 10.9032 14.9945 11.0961 14.0246C11.289 13.0546 11.7652 12.1637 12.4645 11.4645C13.1637 10.7652 14.0546 10.289 15.0246 10.0961C15.9945 9.90315 16.9998 10.0022 17.9134 10.3806C18.8271 10.759 19.6079 11.3999 20.1574 12.2222C20.7068 13.0444 21 14.0111 21 15C21 16.3261 20.4732 17.5979 19.5355 18.5355C18.5979 19.4732 17.3261 20 16 20C17.6616 19.9996 19.291 20.4592 20.7074 21.3279C22.1239 22.1966 23.2722 23.4405 24.0252 24.9218C26.2404 22.9291 27.6351 20.1836 27.9379 17.2194C28.2407 14.2552 27.4299 11.2844 25.6633 8.88501Z" fill="none" />
  <path d="M16 20C18.7614 20 21 17.7614 21 15C21 12.2386 18.7614 10 16 10C13.2386 10 11 12.2386 11 15C11 17.7614 13.2386 20 16 20Z" strokeWidth="2" strokeMiterlimit="10" fill="none" />
  <path d="M7.97488 24.9218C8.72815 23.4408 9.87653 22.1971 11.2929 21.3284C12.7093 20.4598 14.3384 20 16 20C17.6615 20 19.2907 20.4598 20.7071 21.3284C22.1234 22.1971 23.2718 23.4407 24.0251 24.9217" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
  <path d="M25 9C26.1046 9 27 8.10457 27 7C27 5.89543 26.1046 5 25 5C23.8954 5 23 5.89543 23 7C23 8.10457 23.8954 9 25 9Z" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M25 5V3.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M23.268 6L21.9689 5.25" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M23.268 8L21.9689 8.75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M25 9V10.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M26.7321 8L28.0311 8.75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M26.7321 6L28.0311 5.25" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M27.9148 14.5625C27.9717 15.0396 28.0001 15.5196 28 16C28 18.3734 27.2962 20.6935 25.9776 22.6668C24.6591 24.6402 22.7849 26.1783 20.5922 27.0866C18.3995 27.9948 15.9867 28.2324 13.6589 27.7694C11.3312 27.3064 9.19295 26.1635 7.51472 24.4853C5.83649 22.8071 4.6936 20.6689 4.23058 18.3411C3.76756 16.0133 4.0052 13.6005 4.91345 11.4078C5.8217 9.21509 7.35977 7.34094 9.33316 6.02236C11.3066 4.70379 13.6266 4 16 4C16.3755 4 16.7467 4.01699 17.1136 4.05098" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" />
</Base>);
