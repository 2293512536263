import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Tooltip from 'rc-tooltip';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Timeline, { TimelineMarkers, CursorMarker, CustomMarker, TimelineHeaders, DateHeader } from 'react-calendar-timeline';

import { IconWarning } from 'shared/Icons';
import { ContentLoader, Button } from 'shared';
import { defaultDateFormat } from 'shared/constants';
import { selectModalStyles } from 'styles/modules/reactSelect';

import { defaultHeaderFormats } from 'shared/helpers';
import { getDepartmentSchedule, getAllDepartments } from '../../../actions';
import WorkerExceptionModal from './WorkerExceptionModal';

let visibleStart = moment().startOf('isoWeek').startOf('day');
let visibleEnd = moment().endOf('isoWeek').endOf('day');

const WorkersAvailability = ({
  t,
  i18n,
  companyId,
  locationId,
  managerDepartments,
  currentUserRole,
}) => {
  const timelineRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [departmentSchedule, setDepartmentSchedule] = useState([]);
  const [groups, setGroups] = useState([]);
  const [exceptionModal, setExceptionModal] = useState({ isOpen: false });
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [filters, setFilters] = useState({
    departmentFilter: null,
  });

  const dayTypes = [
    { id: 'working', label: t('page_content.human_resources.workers.working') },
    { id: 'vacation', label: t('page_content.human_resources.workers.vacation') },
    { id: 'holiday', label: t('page_content.human_resources.workers.holiday') },
    { id: 'sick', label: t('page_content.human_resources.workers.sick') },
    { id: 'long-sick', label: t('page_content.human_resources.workers.long-sick') },
  ];

  const processWorkerSchedules = (data) => {
    const extractedGroups = [];
    let groupId = 1;

    const items = Object.keys(data).flatMap((workerId) => {
      const worker = data[workerId].worker;
      extractedGroups.push({
        id: groupId,
        title: `${worker.name} ${worker.last_name}`,
      });

      const workerData = data[workerId].data;
      const workerItems = Object.keys(workerData).map((date) => {
        const item = workerData[date];
        const startTime = item?.begin_time
          ? moment(`${date} ${item?.begin_time}`).toDate()
          : moment(date).startOf('day').toDate();
        let endTime = item?.end_time
          ? moment(`${date} ${item?.end_time}`).toDate()
          : moment(date).endOf('day').toDate();

        if (endTime < startTime) {
          endTime = moment(endTime).add(1, 'day').toDate();
        }

        const beginBreak = item?.begin_break
          ? moment(`${date} ${item?.begin_break}`).toDate()
          : moment(date).startOf('day').toDate();
        const endBreak = item?.end_break
          ? moment(`${date} ${item?.end_break}`).toDate()
          : moment(date).endOf('day').toDate();

        return {
          id: `${workerId}-${date}`,
          workerId,
          group: groupId,
          date,
          start_time: startTime,
          end_time: endTime,
          day_type: item?.day_type,
          beginBreak,
          endBreak,
          shiftId: item?.shift_id,
        };
      });

      groupId++;
      return workerItems;
    });

    extractedGroups.sort((a, b) => a.title.localeCompare(b.title));

    setGroups(extractedGroups);
    return items;
  };

  const fetchDepartmentSchedule = () => {
    if (!filters?.departmentFilter) {
      return;
    }

    setShowOverlay(true);

    const apiFilters = `&from_date=${moment(visibleStart).format('YYYY-MM-DD')}&to_date=${moment(visibleEnd).format('YYYY-MM-DD')}`;

    getDepartmentSchedule(filters?.departmentFilter?.id, apiFilters)
      .then((res) => {
        setDepartmentSchedule(processWorkerSchedules(res?.data || []));
        setIsLoading(false);
        setShowOverlay(false);
      })
      .catch(() => {
        setDepartmentSchedule(null);
        setIsLoading(false);
        setShowOverlay(false);
      });
  };

  const fetchDepartments = async () => {
    if (currentUserRole === 'Voditelj') {
      setDepartmentOptions(managerDepartments?.sort((a, b) => a.name.localeCompare(b.name) || []));
      setFilters((prevState) => ({
        ...prevState,
        departmentFilter: managerDepartments?.sort((a, b) => a.name.localeCompare(b.name))[0],
      }));
    } else {
      const res = await getAllDepartments(locationId);
      const sortedDepartments = get(res, 'data.results', []).sort((a, b) => a.name.localeCompare(b.name));
      setDepartmentOptions(sortedDepartments);
      if (res?.data?.results?.length > 0) {
        setFilters((prevState) => ({
          ...prevState,
          departmentFilter: res?.data?.results[0],
        }));
      } else {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  useEffect(() => {
    fetchDepartmentSchedule();
  }, [filters]);

  const getStyleByDayType = (day_type) => {
    const styles = {
      working: {
        background: 'rgba(0, 128, 0, 0.4)',
        color: 'darkgreen',
        border: '1px solid darkgreen',
        overflow: 'hidden',
      },
      vacation: {
        background: 'rgba(128, 128, 128, 0.4)',
        color: 'grey',
        border: '1px solid grey',
        overflow: 'hidden',
      },
      holiday: {
        background: 'rgba(128, 0, 128, 0.4)',
        color: 'purple',
        border: '1px solid purple',
        overflow: 'hidden',
      },
      sick: {
        background: 'rgba(255, 140, 0, 0.4)',
        color: 'darkorange',
        border: '1px solid darkorange',
        overflow: 'hidden',
      },
      'long-sick': {
        background: 'rgba(255, 0, 0, 0.4)',
        color: 'darkred',
        border: '1px solid darkred',
        overflow: 'hidden',
      },
    };

    return styles[day_type] || {};
  };

  const itemRenderer = ({ item, itemContext, getItemProps }) => {
    let styles = {};

    if (!itemContext.selected) {
      styles = getStyleByDayType(item?.day_type);
    }

    return (
      <Tooltip
        id={(item.id).toString()}
        trigger={['hover']}
        placement="top"
        overlay={
          !itemContext.dragging ?
            <div className="reacttooltip">
              <span>{item?.day_type ? `${item?.date ? moment(item.date).format(defaultDateFormat) : ''} ${t(`page_content.human_resources.workers.${item.day_type}`)}` : ''}</span><br />
              {
                item?.day_type === 'working' ?
                <>
                <span>
                    <label>{t('page_content.human_resources.workers.shift')}: </label>
                    {item?.start_time ? moment(item.start_time).format('HH:mm') : ''} - {item?.end_time ? moment(item.end_time).format('HH:mm') : ''}
                </span>
                <br />
                <span>
                    <label>{t('page_content.human_resources.workers.break')}: </label>
                    {item?.beginBreak ? moment(item.beginBreak).format('HH:mm') : ''} - {item?.endBreak ? moment(item.endBreak).format('HH:mm') : ''}
                </span>
                </>
                  : null
              }
            </div> : null
        }
      >
        <div
          {...getItemProps({
            style: {
              ...styles,
              zIndex: '999 !important',
            },
          })}
        >

          <div
            style={{
              height: itemContext.dimensions.height,
              overflow: 'hidden',
              paddingLeft: 3,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              position: 'relative',
            }}
          >
            {t(`page_content.human_resources.workers.${item?.day_type}`)}
          </div>
        </div>
      </Tooltip>
    );
  };

  const onTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
    updateScrollCanvas(moment(visibleStart).valueOf(), moment(visibleEnd).valueOf());
  };

  const labelFormater = (
    [timeStart],
    unit,
    labelWidth,
    formatOptions = defaultHeaderFormats,
  ) => {
    moment.locale(i18n.language);
    let format;
    if (labelWidth >= 150) {
      format = formatOptions[unit].long;
    } else if (labelWidth >= 100) {
      format = formatOptions[unit].mediumLong;
    } else if (labelWidth >= 50) {
      format = formatOptions[unit].medium;
    } else {
      format = formatOptions[unit].short;
    }

    return timeStart.format(format);
  };

  const handleNavigationButton = (direction) => {
    setShowOverlay(true);
    if (direction === 'previous') {
      visibleStart = moment(visibleStart).subtract(1, 'week');
      visibleEnd = moment(visibleEnd).subtract(1, 'week');
    }

    if (direction === 'next') {
      visibleStart = moment(visibleStart).add(1, 'week');
      visibleEnd = moment(visibleEnd).add(1, 'week');
    }

    fetchDepartmentSchedule();
    timelineRef.current.updateScrollCanvas(visibleStart.valueOf(), visibleEnd.valueOf());
  };

  const handleCloseExceptionModal = () => {
    setExceptionModal({ isOpen: false, data: null });
  };

  const closeExceptionModalAndRefetch = () => {
    handleCloseExceptionModal();
    fetchDepartmentSchedule();
  };

  const selectItem = (itemId) => {
    const item = departmentSchedule?.find((x) => x.id === itemId);
    setExceptionModal({ isOpen: true, data: item });
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  if (isLoading) {
    return <ContentLoader />;
  }

  if (!departmentOptions?.length) {
    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <IconWarning
          color="#ee5253"
          height="18px"
          width="18px"
        />{t('page_content.human_resources.workers.no_departments_error')}</div>;
  }

  const defaultTimeRange = visibleEnd - visibleStart;

  return (
    <div className="department-schedules">
        <div className="department-schedules__filters">
          <Select
            className="select-style"
            options={departmentOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.filter_by_department_placeholder')}
            isSearchable
            onChange={(e) => handleFilterChange('departmentFilter', e)}
            value={departmentOptions.find((a) => a.id === filters?.departmentFilter?.id) || ''}
            styles={selectModalStyles}
            minZoom={defaultTimeRange}
            maxZoom={defaultTimeRange}
          />
        </div>
      <div className="department-schedules__info">
        <div className="department-schedules__legend">
          {dayTypes.map((type) => (
            <div key={type.id} className="department-schedules__legend-item">
              <div className={`department-schedules__legend-color__type-${type.id}`} />
              <span className="department-schedules__legend-text">{type.label}</span>
            </div>
          ))}
        </div>
        <div className="department-schedules__controls">
          <div className="department-schedules__navigation-buttons">
            <Button
              onClick={() => handleNavigationButton('previous')}
              className="department-schedules__navigation-button"
            >{t('page_content.human_resources.workers.previous')}</Button>
            <Button
              onClick={() => handleNavigationButton('next')}
              className="department-schedules__navigation-button"
            >{t('page_content.human_resources.workers.next')}</Button>
          </div>
        </div>
      </div>
      <div className="department-schedules__timeline">
      {showOverlay && <div id="overlay"><ContentLoader /></div>}
          <Timeline
            ref={timelineRef}
            groups={groups}
            items={departmentSchedule || []}
            itemRenderer={itemRenderer}
            defaultTimeStart={visibleStart}
            defaultTimeEnd={visibleEnd}
            onTimeChange={onTimeChange}
            onItemDoubleClick={(itemId) => { selectItem(itemId); }}
            buffer={1}
            stackItems
            fullUpdate={false}
            lineHeight={55}
            itemHeightRatio={0.75}
            canResize={false}
            canChangeGroup={false}
            canMove={false}
          >
            <TimelineMarkers>
              <CustomMarker date={moment().valueOf()}>
                {({ styles }) => <div style={{ ...styles, backgroundColor: 'red', width: '2px' }} />}
              </CustomMarker>
              <CursorMarker>
                {({ styles }) => <div style={{ ...styles, backgroundColor: 'gray' }} />}
              </CursorMarker>
            </TimelineMarkers>
            <TimelineHeaders className="sticky">
                <DateHeader unit="primaryHeader" />
                <DateHeader labelFormat={labelFormater} />
              </TimelineHeaders>
          </Timeline>
          </div>

          {
            exceptionModal?.isOpen &&
            <WorkerExceptionModal
              dayTypes={dayTypes}
              companyId={companyId}
              isOpen={exceptionModal?.isOpen}
              department={filters?.departmentFilter}
              initialValues={exceptionModal?.data}
              handleCloseModal={handleCloseExceptionModal}
              closeModalAndRefetch={closeExceptionModalAndRefetch}
            />
          }
    </div>
  );
};

WorkersAvailability.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  locationId: PropTypes.number.isRequired,
  workerId: PropTypes.number.isRequired,
  workerDetails: PropTypes.object.isRequired,
  i18n: PropTypes.object,
  isReadOnly: PropTypes.bool,
  managerDepartments: PropTypes.array,
  currentUserRole: PropTypes.string,
};

export default withTranslation()(WorkersAvailability);
