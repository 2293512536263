import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Field,
  Form,
  Formik,
  ErrorMessage,
} from 'formik';
import { Notification } from 'shared';
import { ToastContainer } from 'react-toastify';
import * as userActions from 'user/actions';
import * as actions from '../../actions';
import './../style.scss';

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  render() {
    const {
      currentUser,
    } = this.props;

    const reset_email = localStorage.getItem('reset_email');

    const token = window.location.href.split('token=')[1];

    const validateConfirmPassword = (pass, value) => {
      let error = '';
      if (pass && value) {
        if (pass !== value) {
          error = 'Password not matched';
        }
      }
      return error;
    };

    return (
      <div className={`Auth ${currentUser ? 'authed' : ''}`}>
        <Formik
          initialValues={{ token, new_password: '', confirmation_password: '', email: reset_email }}
          validate={(values) => {
            const errors = {};
            if (!values.token) {
              errors.token = 'Required';
            }
            if (!values.new_password) {
              errors.new_password = 'Required';
            }
            if (!values.confirmation_password) {
              errors.confirmation_password = 'Required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            userActions.resetPassword(values)
              .then((re) => {
                setSubmitting(false);
                if (re.response && re.response.status && re.response.status !== 200) {
                  Notification('error', 'Error', re.response.data.non_field_errors);
                } else {
                  window.location.href = '/login';
                  localStorage.removeItem('reset_email');
                }
              })
              .catch((error) => {
                Notification('error', 'Error', error.response.data.non_field_errors);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            isSubmitting,
          }) => (
            <div className="form-container login-form-container">
              <Form ref={this.formRef} className="form-class">
                <img src="/ascalia_logo.svg" alt="Ascalia logo" width="140px" />
                <header>
                  <h1>Reset Password</h1>
                </header>
                <div>
                  <Field
                    type="password"
                    name="new_password"
                    placeholder="New password"
                    required
                    value={values.new_password}
                  />
                  <ErrorMessage className="error" name="new_password" component="p" />
                </div>
                <div>
                  <Field
                    type="password"
                    name="confirmation_password"
                    placeholder="Password confirmation"
                    required
                    validate={(value) => validateConfirmPassword(values.new_password, value)}
                  />
                  <ErrorMessage className="error" name="confirmation_password" component="p" />
                </div>
                <p id="forgot-password-link">
                  <button type="button" onClick={() => { localStorage.removeItem('reset_email'); window.location.href = '/login'; }}>Back to Login</button>
                </p>
                <section className="bottom">
                  <button
                    type="submit"
                    className="submit-button"
                    disabled={isSubmitting}
                  >
                    Reset Password
                  </button>
                </section>
              </Form>
            </div>
          )}
        </Formik>
        <ToastContainer
          autoClose={5000}
          closeButton={false}
          toastClassName="Notification-container"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.app,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  fetchPageMeta: actions.fetchPageMeta,
  getCurrentUser: userActions.getCurrentUser,
};

ResetPasswordPage.propTypes = {
  currentUser: PropTypes.any,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage));
