import api from 'helpers/api';
import { Notification } from 'shared';

export const getProjects = async (companyId, locationId, filterOrder = '') => {
  try {
    const res = await api
      .get(`/api/v1/projects/projects/?company=${companyId}&location=${locationId}${filterOrder}&limit=30`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching projects', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProjectsUrl = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching projects', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProjectDestinations = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/projects/destinations/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching projects destinations', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProjectCategories = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/projects/categories/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching projects categories', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addNewProject = async (data) => {
  try {
    const res = await api
      .post('/api/v1/projects/projects/', data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while adding new project', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
