import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Table, Button } from 'shared';
import moment from 'moment';
import i18n from 'i18next';
import { ordersCreatedAndUpdated } from 'shared/constants';
import { numberSeparatorFormat } from 'industry/helpers';

const OrderBatchStepsTable = ({ t, batchSteps, handleReturnButton, selectedBatch, isLoadingBatches, handleSortingSteps }) => {
  const [customColumns, setCustomColumns] = useState([]);

  const handleCustomColumns = () => {
    const result = [];
    let customColumnsTemp = [];
    let custom;

    batchSteps.forEach((step) => {
      const dataValues = step.values;
      if (dataValues) {
        // eslint-disable-next-line guard-for-in
        for (const key in dataValues) {
          result.push([key, dataValues[key]]);
        }
      }
      const customKeys = [];
      custom = result.map((r) => {
        const keyFound = customKeys.find((k) => k === r[0]);

        if (!keyFound) {
          customKeys.push(r[0]);
          return ({
            Header: () => <span>{r[0].charAt(0).toUpperCase() + r[0].slice(1)}</span>,
            Cell: (row) => row.value || '-',
            accessor: `values.${r[0]}`,
            style: {
              cursor: 'default',
            },
            sortable: false,
          });
        }
        return null;
      });
    });

    if (custom) {
      customColumnsTemp = custom.filter((c) => c !== null) || [];
    }
    setCustomColumns(customColumnsTemp);
  };

  useEffect(() => {
    handleCustomColumns();
  }, [batchSteps]);

  return (
    <div className="order-booking-items">
      <div style={{ display: 'flex', columnGap: '10px', paddingBottom: '10px' }}>

        <Button
          type="plain"
          onClick={handleReturnButton}
        >
          {t('page_content.orders.order_details.bookings_tab.return_button')}
        </Button>
      </div>

      <div className="booking_item_info">
        <p className="key">
          {t('page_content.orders.order_details.batches_tab.table_column_batch_nr')} :
          <span className="value">
            {!isLoadingBatches && ` ${selectedBatch.original.batch_nr}`}
          </span>
        </p>
      </div>

      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span>#</span>,
            accessor: 'step.step_id',
            Cell: (row) => row.value || '-',
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.orders.order_details.batches_tab.table_column_batch_step')}</span>,
            accessor: 'step.name',
            Cell: (row) => row.value || '-',
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_batch')}</span>,
            accessor: 'batch',
            Cell: (row) => row.value || '-',
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.orders.order_details.batches_tab.table_column_batch_step_start')}</span>,
            accessor: 'step_start',
            Cell: (row) => (row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.orders.order_details.batches_tab.table_column_batch_step_end')}</span>,
            accessor: 'step_end',
            Cell: (row) => (row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.orders.order_details.batches_tab.table_column_batch_quantity')}</span>,
            accessor: 'quantity',
            Cell: (row) => numberSeparatorFormat(i18n.language, row.value, 2, 2, false) || '-',
            style: {
              cursor: 'default',
            },
          },
          ...customColumns,
        ]}
        data={batchSteps || []}
        minRows={0}
        defaultPageSize={100}
        noDataText=" "
        showPagination={false}
        sortable
        selectedRow={null}
        loading={isLoadingBatches}
        defaultSorted={[{ id: 'step', desc: false }]}
        onSortedChange={(newSorted) => { handleSortingSteps(newSorted[0]); }}
      />
    </div>
  );
};

OrderBatchStepsTable.propTypes = {
  t: PropTypes.func.isRequired,
  handleReturnButton: PropTypes.func.isRequired,
  batchSteps: PropTypes.array,
  selectedBatch: PropTypes.object,
  isLoadingBatches: PropTypes.bool,
  handleSortingSteps: PropTypes.func,
};

export default withTranslation()(OrderBatchStepsTable);
