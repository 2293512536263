import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import Select from 'react-select';
import { Modal, AssetPicker, Table } from 'shared';
import { getLocale } from 'shared/DatePicker/constants';
import { modalSizes } from 'shared/constants';
import { ContentLoader } from '../../../../shared/index';

class MaintenanceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      missingFields: {},
      submitted: false,
    };
  }

  componentDidUpdate() {
    const { missingFields, submitted } = this.state;
    const { handleMaintenanceSave } = this.props;

    if (Object.values(missingFields).some((element) => element === true) || !submitted) {
      return;
    }
    handleMaintenanceSave();
  }

  getDuration = (row) => {
    const start_time = row.original.check_in || '';
    const end_time = row.original.last_check_out || '';

    if (start_time && end_time) {
      const diff = moment.duration(moment(end_time).diff(moment(start_time)));
      return moment.utc(diff.asMilliseconds()).format('HH:mm');
    }
    return '';
  }

  render() {
    const {
      t,
      maintenance,
      workers,
      isLoadingModal,
      reasonsFilteredByAsset,
      isMaintenanceModalOpen,
      locationId,
      closeEditMaintenanceModal,
      changeAsset,
      handleMaintenanceChange,
      handleMaintenanceDateChange,
    } = this.props;

    const { missingFields } = this.state;

    // React-select custom styles
    const selectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '300px',
        minHeight: '34px',
        height: '34px',
        padding: 0,
        fontSize: '14px',
        color: '#555',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0 10px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: '0',
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: 300,
        borderRadius: 0,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    const options = reasonsFilteredByAsset.map((reason) => ({
      value: reason.id,
      label: `${reason.group.name} - ${reason.name}`,
    }));

    const selectedReason = reasonsFilteredByAsset.find(
      (reason) => reason.id === maintenance.reported_reason,
    );

    return (
      <React.Fragment>
        <Modal
          size={modalSizes.medium}
          isOpen={isMaintenanceModalOpen}
          handleSave={() => {
            this.setState((prevState) => ({
              missingFields: {
                ...prevState.missingFields,
                asset: !maintenance.asset,
                reason: !maintenance.reported_reason || maintenance.reported_reason === ' ',
              },
              submitted: true,
            }));
          }}
          handleClose={closeEditMaintenanceModal}
          title={maintenance && maintenance.id ? t('page_content.maintenance.maintenance_modal.edit_title') : t('page_content.maintenance.maintenance_modal.add_title')}
        >
          {
            isLoadingModal &&
            <ContentLoader />
          }
          {
            !isLoadingModal &&
            <div className="maintenance_form default-form">
              <table>
                <tbody>
                  <tr>
                    <td className="label">
                      {`${t('page_content.maintenance.maintenance_modal.asset')} *`}
                    </td>
                    <td className="input">
                      <AssetPicker
                        changeAsset={(val) => changeAsset(val)}
                        resetMetric={() => { }}
                        asset={maintenance.asset || null}
                        locationId={locationId}
                        hasError={missingFields.asset}
                        customStyles={selectStyles}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      {`${t('page_content.maintenance.maintenance_modal.reason')} *`}
                    </td>
                    <td className="input">
                      <Select
                        value={options.find((option) => option.value === maintenance.reported_reason)}
                        options={options}
                        isSearchable
                        menuPosition="fixed"
                        onChange={(reason) => handleMaintenanceChange('reported_reason', reason.value)}
                        placeholder={t('page_content.maintenance.maintenance_modal.reason_placeholder')}
                        styles={selectStyles}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      {t('page_content.maintenance.maintenance_modal.reason_comment')}
                    </td>
                    <td>
                      <textarea
                        disabled={selectedReason && selectedReason.allow_comment === false}
                        className="maintenance_modal_textArea"
                        value={maintenance.reason_comment || ''}
                        onChange={(e) => handleMaintenanceChange('reason_comment', e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">{t('page_content.maintenance.maintenance_modal.reporter')}</td>
                    <td className="value">
                      <Select
                        options={workers}
                        getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                        getOptionValue={(option) => option.id}
                        isSearchable
                        placeholder=""
                        menuPosition="fixed"
                        onChange={(worker) => handleMaintenanceChange('reporter', worker.id)}
                        value={workers.find((w) => w.id === maintenance.reporter) || ''}
                        styles={selectStyles}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      {t('page_content.maintenance.maintenance_modal.notes')}
                    </td>
                    <td>
                      <textarea className="maintenance_modal_textArea" value={maintenance.note || ''} onChange={(e) => handleMaintenanceChange('note', e.target.value)} />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">{t('page_content.maintenance.maintenance_modal.event_start')}</td>
                    <td className="value">
                      <ReactDatePicker
                        placeholderText="Select event start date"
                        dateFormat="dd.MM.yyyy. HH:mm"
                        selected={maintenance.event_start ? moment(maintenance.event_start).toDate() : ''}
                        selectsStart
                        showTimeSelect
                        onChange={(e) => handleMaintenanceDateChange('event_start', e)}
                        timeFormat="HH:mm"
                        disabledKeyboardNavigation
                        locale={getLocale(t)}
                        timeCaption={t('date_picker_locale.time_translation')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">{t('page_content.maintenance.maintenance_modal.event_end')}</td>
                    <td className="value">
                      <ReactDatePicker
                        placeholderText={t('page_content.maintenance.maintenance_modal.event_end_date_placeholder')}
                        dateFormat="dd.MM.yyyy. HH:mm"
                        selected={maintenance.event_end ? moment(maintenance.event_end).toDate() : ''}
                        selectsStart
                        showTimeSelect
                        onChange={(e) => handleMaintenanceDateChange('event_end', e)}
                        timeFormat="HH:mm"
                        disabledKeyboardNavigation
                        locale={getLocale(t)}
                        timeCaption={t('date_picker_locale.time_translation')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">{t('page_content.maintenance.maintenance_modal.status')}</td>
                    <td className="value">
                      <select
                        value={maintenance.status}
                        onChange={(e) => handleMaintenanceChange('status', e.target.value)}
                      >
                        <option value="created">{t('page_content.maintenance.maintenance_statuses.created')}</option>
                        <option value="updated">{t('page_content.maintenance.maintenance_statuses.updated')}</option>
                        <option value="closed">{t('page_content.maintenance.maintenance_statuses.closed')}</option>
                        <option value="resolved">{t('page_content.maintenance.maintenance_statuses.resolved')}</option>
                        <option value="approved">{t('page_content.maintenance.maintenance_statuses.approved')}</option>
                        <option value="denied">{t('page_content.maintenance.maintenance_statuses.denied')}</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>

              {
                maintenance.is_malfunction &&
                <Table
                  columns={[
                    {
                      Header: () => <span>{t('page_content.maintenance.table_column_first_name')}</span>,
                      accessor: 'first_name',
                      Cell: (row) => <span>{row.value || '-'}</span>,
                    },
                    {
                      Header: () => <span>{t('page_content.maintenance.table_column_last_name')}</span>,
                      accessor: 'last_name',
                      Cell: (row) => <span>{row.value || '-'}</span>,
                    },
                    {
                      Header: () => <span>{t('page_content.maintenance.table_column_check_in')}</span>,
                      accessor: 'check_in',
                      Cell: (row) => (row.value ? moment(row.value).format('DD.MM.YYYY. HH:mm') : '-'),
                    },
                    {
                      Header: () => <span>{t('page_content.maintenance.table_column_last_check_out')}</span>,
                      accessor: 'last_check_out',
                      Cell: (row) => (row.value ? moment(row.value).format('DD.MM.YYYY. HH:mm') : '-'),
                    },
                    {
                      Header: () => <span>{t('page_content.maintenance.table_column_duration')}</span>,
                      Cell: (row) => this.getDuration(row),
                    },
                  ]}
                  data={maintenance.operators || []}
                  defaultPageSize={100}
                  minRows={0}
                  noDataText=""
                  showPagination={false}
                  selectedRow={null}
                  manual={false}
                />
              }
            </div>
          }
        </Modal>
      </React.Fragment>
    );
  }
}

MaintenanceModal.propTypes = {
  t: PropTypes.func.isRequired,
  maintenance: PropTypes.object.isRequired,
  workers: PropTypes.array.isRequired,
  isLoadingModal: PropTypes.bool.isRequired,
  reasonsFilteredByAsset: PropTypes.array.isRequired,
  isMaintenanceModalOpen: PropTypes.bool.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleMaintenanceSave: PropTypes.func.isRequired,
  closeEditMaintenanceModal: PropTypes.func.isRequired,
  changeAsset: PropTypes.func.isRequired,
  handleMaintenanceChange: PropTypes.func.isRequired,
  handleMaintenanceDateChange: PropTypes.func.isRequired,
};

export default MaintenanceModal;
