/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'shared';
import { withTranslation } from 'react-i18next';
import { modalSizes } from 'shared/constants';

class PDFPreviewModal extends Component {
  render() {
    const { modalStatus, pdfObjectInfo, closeModal, t } = this.props;
    return (
      <Modal
        closeText={t('shared.modal.close_button_alt')}
        isOpen={modalStatus}
        size={modalSizes.max}
        title={pdfObjectInfo && pdfObjectInfo.name}
        handleClose={() => {
          closeModal();
        }}
      ><div style={{ minHeight: '70vh', position: 'relative' }}>
          <iframe
            src={pdfObjectInfo && pdfObjectInfo.url}
            frameBorder={0}
            style={{
              width: '100%', height: '100%', left: 0, top: 0, position: 'absolute',
            }}
          /></div>
      </Modal>
    );
  }
}

PDFPreviewModal.propTypes = {
  t: PropTypes.func.isRequired,
  modalStatus: PropTypes.bool.isRequired,
  pdfObjectInfo: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};
export default (withTranslation()(PDFPreviewModal));
