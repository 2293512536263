import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import moment from 'moment';
import { defaultDateTimeFormat, modalSizes } from 'shared/constants';
import { Button, Table, Modal, Notification, MultiClassPicker } from 'shared';
import { IconSearch } from 'shared/Icons';
import { getLocale } from 'shared/DatePicker/constants';
import exportFromJSON from 'export-from-json';
import { getUploadsHistory, getUploadsHistoryURL } from '../../actions';
import Programs from './components/Programs';
import '../../style.scss';

class UploadList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploads: [],
      showImageModal: false,
      imageUrl: '',
      query: '',
      previous: '',
      next: '',
      isLoadingUploads: false,
      selectedProgram: null,
      start_date: null,
      end_date: null,
      selectedClasses: [],
    };
  }

  componentDidMount() {
    this.getUploadsHistory();
  }

  onProgramChange = (e) => {
    this.setState({
      selectedProgram: e.target.value,
    }, () => {
      this.getUploadsHistory();
    });
  }

  getUploadsHistory = () => {
    const { workProcess } = this.props;
    const { start_date, end_date, selectedProgram, query, selectedClasses } = this.state;

    this.setState({
      isLoadingUploads: true,
    });
    let filters = '&limit=50';

    if (query) {
      filters += `&file_name=${query}`;
    }

    if (selectedProgram) {
      filters += `&program=${selectedProgram}`;
    }

    if (start_date) {
      filters += `&created_at_after=${moment(start_date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`;
    }

    if (end_date) {
      filters += `&created_at_before=${moment(end_date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`;
    }

    if (selectedClasses) {
      filters += `&input_class=${selectedClasses}`;
    }
    getUploadsHistory(workProcess?.id, filters)
      .then((uploads) => {
        this.setState({
          uploads: uploads.data.results,
          next: uploads.data.next,
          previous: uploads.data.previous,
          isLoadingUploads: false,
        });
      })
      .catch((error) => {
        Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
        this.setState({
          uploads: [],
          next: null,
          previous: null,
          isLoadingUploads: false,
        });
      });
  }

  getColorPercent = (obj) => {
    const values = Object.values(obj);
    const sum = values.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);

    const keys = Object.keys(obj).filter((color) => color !== 'WHITE');

    const colors_percent = [];

    keys.map((key) => {
      let result = ((obj[key] / sum) * 100).toFixed(2);

      if (Number.isNaN(result)) {
        result = 0;
      }
      return (
        colors_percent.push(`${result}%`)
      );
    });

    return colors_percent;
  }

  navigateNext = () => {
    const { next } = this.state;

    if (!next) return;

    this.setState({
      isLoadingUploads: true,
    });

    getUploadsHistoryURL(next)
      .then((uploads) => {
        this.setState({
          uploads: uploads.data.results,
          next: uploads.data.next,
          previous: uploads.data.previous,
          isLoadingUploads: false,
        });
      });
  }

  navigatePrevious = () => {
    const { previous } = this.state;

    if (!previous) return;

    this.setState({
      isLoadingUploads: true,
    });

    getUploadsHistoryURL(previous)
      .then((uploads) => {
        this.setState({
          uploads: uploads.data.results,
          next: uploads.data.next,
          previous: uploads.data.previous,
          isLoadingUploads: false,
        });
      });
  }

  handleRowClick = (upload) => {
    const { showCalculation, resetUploads } = this.props;
    resetUploads()
      .then(() => {
        showCalculation(upload.upload.id, upload.upload.file_name, true);
      });
  }

  handleChange = (e) => {
    this.setState({ query: e.target.value }, () => { this.getUploadsHistory(); });
  }

  displayImage = (imageUrl) => {
    this.setState({
      showImageModal: true,
      imageUrl,
    });
  }

  handleChangeStartDate = (t) => {
    const formatedDate = moment(t);
    this.setState({
      start_date: formatedDate._d,
    }, () => {
      this.getUploadsHistory();
    });
  }

  handleChangeEndDate = (t) => {
    const formatedDate = moment(t);
    this.setState({
      end_date: formatedDate._d,
    }, () => {
      this.getUploadsHistory();
    });
  }

  clearAllFilters = () => {
    this.setState({
      selectedProgram: null,
      start_date: null,
      end_date: null,
      query: '',
      selectedClasses: [],
    }, () => {
      this.getUploadsHistory();
    });
  }

  handleClearQuery = () => {
    this.setState({
      query: '',
    }, () => {
      this.getUploadsHistory();
    });
  }

  handleClassChange = (classValue) => {
    this.setState({ selectedClasses: classValue }, () => { this.getUploadsHistory(); });
  }

  exportToExcel = () => {
    const { workProcess } = this.props;
    const { start_date, end_date, selectedProgram, query, selectedClasses } = this.state;

    if (!start_date && !end_date) {
      return Notification('error', 'No selected time range', 'Please enter filter for desired time range');
    }
    this.setState({
      isLoadingUploads: true,
    });

    let filters = '';

    if (query) {
      filters += `&file_name=${query}`;
    }

    if (selectedProgram) {
      filters += `&program=${selectedProgram}`;
    }

    if (start_date) {
      filters += `&created_at_after=${moment(start_date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`;
    }

    if (end_date) {
      filters += `&created_at_before=${moment(end_date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`;
    }

    if (selectedClasses) {
      filters += `&input_class=${selectedClasses}`;
    }

    filters += '&limit=1000000';

    getUploadsHistory(workProcess.id, filters)
      .then((re) => {
        if (re && re.data && re.data.results && re.data.results.length > 0) {
          const exportUploads = re.data.results;

          for (let i = 0; i < exportUploads.length; i++) {
            delete exportUploads[i].id;
            delete exportUploads[i].elapsed;
            delete exportUploads[i].status_code;
            delete exportUploads[i].status_msg;

            const keys = exportUploads[i].results && exportUploads[i].results.result && exportUploads[i].results.result.result ? Object.keys(exportUploads[i].results.result.result) : [];
            const values_class = exportUploads[i].results && exportUploads[i].results.result && exportUploads[i].results.result.result ? Object.values(exportUploads[i].results.result.result) : [];

            for (let y = 0; y < keys.length; y++) {
              if (keys[y] === 'Class 13') {
                keys[y] = 'Class 13 - Yellow';
              }
              if (keys[y] === 'Class 14') {
                keys[y] = 'Class 14 - Red';
              }
              if (keys[y] === 'Class 34/35') {
                keys[y] = 'Class 34/35 - Blue';
              }
              if (keys[y] === 'Class 45/46') {
                keys[y] = 'Class 45/46 - Green';
              }
              if (keys[y] === 'RE-CUTTING:') {
                keys[y] = 'RE-CUTTING - Not marked';
              }
              if (keys[y] === 'OWN_PRODUCTION') {
                keys[y] = 'OWN_PRODUCTION - Black';
              }
            }

            const keys_percentage = exportUploads[i].results && exportUploads[i].results.result && exportUploads[i].results.result.colors ? Object.keys(exportUploads[i].results.result.colors).filter((color) => color !== 'WHITE') : [];
            const values_percentage = exportUploads[i].results && exportUploads[i].results.result && exportUploads[i].results.result.colors ? this.getColorPercent(exportUploads[i].results.result.colors) : [];

            exportUploads[i].class = exportUploads[i].upload && exportUploads[i].upload.parameters && exportUploads[i].upload.parameters.selected_classes ? exportUploads[i].upload.parameters.selected_classes : null;
            exportUploads[i].program = exportUploads[i].upload && exportUploads[i].upload.parameters && exportUploads[i].upload.parameters.program ? exportUploads[i].upload.parameters.program : null;
            exportUploads[i].created_at = exportUploads[i].created_at ? moment(exportUploads[i].created_at).format('DD.MM.YYYY. - HH:mm') : null;
            exportUploads[i].upload = exportUploads[i].upload.file_name;
            // exportUploads[i].colors_results = exportUploads[i].results && exportUploads[i].results.result && exportUploads[i].results.result.colors ? exportUploads[i].results.result.colors : null;
            for (let x = 0; x < keys.length; x++) {
              exportUploads[i][keys[x]] = values_class[x];
            }
            for (let y = 0; y < keys.length; y++) {
              exportUploads[i][keys_percentage[y]] = values_percentage[y];
            }
            // exportUploads[i].colors_results_percent = exportUploads[i].results && exportUploads[i].results.result && exportUploads[i].results.result.colors ? this.getColorPercent(exportUploads[i].results.result.colors) : null;
            // exportUploads[i].class_results = exportUploads[i].results && exportUploads[i].results.result && exportUploads[i].results.result.result ? exportUploads[i].results.result.result : null;
            delete exportUploads[i].results;
          }

          let fileName = 'uploads';
          if (start_date && end_date) {
            fileName = `uploads_from${moment(start_date).format('YYYY-MM-DD')}_to_${moment(end_date).format('YYYY-MM-DD')}`;
          }
          const exportType = 'xls';

          exportFromJSON({ data: exportUploads, fileName, exportType });
          this.setState({
            isLoadingUploads: false,
          });
        } else {
          this.setState({
            isLoadingUploads: false,
          });
          return Notification('error', 'An error occurred while downloading uploads', 'There is no data for selected time range');
        }
      });
  }

  render() {
    const {
      toUpload,
      t,
      workProcess,
      companyId,
      isReadOnly,
    } = this.props;

    const {
      uploads, showImageModal, imageUrl, query,
      previous, next, isLoadingUploads, selectedProgram,
      start_date, end_date, selectedClasses,
    } = this.state;

    const formattedPrograms = [];
    const programs = workProcess && workProcess.choices && workProcess.choices.programs ? workProcess.choices.programs : [];

    Object.keys(programs).forEach((key) => {
      formattedPrograms.push({
        name: key,
        ...programs[key],
      });
    });
    return (
      <div className="uploads-list">
        <Tabs>
          <TabList>
            <Tab>{t('page_content.upload.step_four.uploads_list')}</Tab>
            <Tab>{t('page_content.upload.step_four.programs')}</Tab>
          </TabList>
          <TabPanel>
            <p className="uploads-list__back">
              <Button onClick={toUpload}>
                {t('page_content.upload.step_four.back_to_upload_button')}
              </Button>
            </p>
            <div>
              <form className="search" onSubmit={(e) => { e.preventDefault(); this.getUploadsHistory(); }}>
                <input
                  onChange={this.handleChange}
                  placeholder={t('page_content.upload.step_four.search_placeholder')}
                  value={query}
                  type="text"
                />
                <button
                  className="submit"
                  type="submit"
                >
                  <IconSearch
                    color="#555"
                    height="24px"
                    width="24px"
                  />
                </button>
                {
                  query &&
                  <button className="clear" onClick={this.handleClearQuery}>&times;</button>
                }
              </form>
              <div style={{ display: 'flex', padding: '10px 0' }}>
                <select style={{ height: '38px', width: '200px', marginRight: '10px', border: '1px solid #eee' }} onChange={this.onProgramChange}>
                  <option value={null} selected={!selectedProgram} disabled>
                    {t('page_content.upload.step_two.choose_an_option_placeholder')}
                  </option>
                  {
                    formattedPrograms.map((program) => <option value={program.name} selected={selectedProgram === program.name} key={program.name}>{program.name} ({program.width} mm x {program.height} mm)</option>)
                  }
                </select>
                <div className="class-multipicker" style={{ width: '200px' }}>
                  <MultiClassPicker
                    classValue={selectedClasses || null}
                    handleChange={(val) => this.handleClassChange(val)}
                    htmlId="type"
                    isMultiSelect
                    showAllOptions
                  />
                </div>
                <div className="range_picker">
                  <ReactDatePicker
                    placeholderText="Select start date"
                    dateFormat="dd.MM.yyyy"
                    selected={start_date ? moment(start_date).toDate() : null}
                    selectsStart
                    onChange={this.handleChangeStartDate}
                    disabledKeyboardNavigation
                    showTimeSelect
                    timeFormat="HH:mm"
                    locale={getLocale(t)}
                    timeCaption={t('date_picker_locale.time_translation')}
                  />
                  <ReactDatePicker
                    placeholderText="Select end date"
                    dateFormat="dd.MM.yyyy"
                    selected={end_date ? moment(end_date).toDate() : null}
                    selectsStart
                    onChange={this.handleChangeEndDate}
                    disabledKeyboardNavigation
                    showTimeSelect
                    timeFormat="HH:mm"
                    locale={getLocale(t)}
                    timeCaption={t('date_picker_locale.time_translation')}
                  />
                </div>
                <Button
                  style={{ margin: '0 10px 0 10px' }}
                  onClick={this.clearAllFilters}
                >{t('page_content.upload.step_four.clear_button')}
                </Button>
                <Button
                  type="export"
                  onClick={this.exportToExcel}
                >
                  {t('page_content.upload.step_four.export_to_excel_button')}
                </Button>
              </div>

            </div>
            <Table
              data={uploads}
              showPagination={false}
              sortable={false}
              minRows={0}
              noDataText=" "
              style={{ userSelect: 'text' }}
              defaultPageSize={50}
              loading={isLoadingUploads}
              handleClick={(row) => this.handleRowClick(row.original)}
              columns={[
                {
                  Header: () => <span>{t('page_content.upload.step_four.table_column_name')}</span>,
                  accessor: 'upload.file_name',
                },
                {
                  Header: () => <span>{t('page_content.upload.step_four.table_column_uploads')}</span>,
                  accessor: 'upload.created_at',
                  Cell: (row) => moment(row.value).format(defaultDateTimeFormat),
                },
                {
                  Header: () => <span>{t('page_content.upload.step_four.table_column_class')}</span>,
                  accessor: 'upload.parameters.selected_classes',
                  Cell: (row) => (row && row.value && row.value.length > 0 ? row.value : '-'),
                },
                {
                  Header: () => <span>{t('page_content.upload.step_four.table_column_program')}</span>,
                  accessor: 'upload.parameters.program',
                  Cell: (row) => row.value || '-',
                },
                {
                  Header: () => <span>{t('page_content.upload.step_four.table_column_image')}</span>,
                  accessor: 'upload.file',
                  Cell: (row) => (<Button onClick={(e) => { e.stopPropagation(); this.displayImage(row.value); }}>{t('page_content.upload.step_four.table_column_image_button')}</Button>),
                },
              ]}
            />
            <div style={{ marginTop: '10px' }}>
              <span style={{ float: 'right' }}>
                <Button
                  disabled={!previous}
                  onClick={() => this.navigatePrevious(previous)}
                >
                  {t('page_content.previous')}
                </Button>
                <Button
                  disabled={!next}
                  onClick={() => this.navigateNext(next)}
                >
                  {t('page_content.next')}
                </Button>
              </span>
            </div>
          </TabPanel>
          <TabPanel>
            <Programs
              t={t}
              companyId={companyId}
              isReadOnly={isReadOnly}
            />
          </TabPanel>
        </Tabs>
        <Modal
          isOpen={showImageModal}
          handleClose={() => { this.setState({ showImageModal: false, imageUrl: null }); }}
          size={modalSizes.large}
        >
          <img src={imageUrl} alt="" style={{ width: '100%', height: 'auto' }} />
        </Modal>
      </div>
    );
  }
}

UploadList.propTypes = {
  workProcess: PropTypes.object,
  showCalculation: PropTypes.func.isRequired,
  toUpload: PropTypes.func.isRequired,
  resetUploads: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default withTranslation()(UploadList);
