import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props}>
  <path
    d="M50,28.5c-17.7,0-32.1,14.4-32.1,32.1c0,3.8,0.7,7.5,1.9,10.9h21.7c0-3.9,2.5-7.1,6.1-8.1L50,40.6l2.4,22.7
    c3.5,1.1,6.1,4.3,6.1,8.1h21.7c1.2-3.4,1.9-7.1,1.9-10.9C82.1,42.9,67.7,28.5,50,28.5z M23.8,61.5h-2.1c-0.7,0-1.3-0.6-1.3-1.3
    c0-0.7,0.6-1.3,1.3-1.3h2.1c0.7,0,1.3,0.6,1.3,1.3C25.1,60.9,24.5,61.5,23.8,61.5z M26.9,51c-0.2,0.5-0.7,0.8-1.2,0.8
    c-0.2,0-0.3-0.1-0.5-0.1l-1.9-0.8c-0.7-0.3-1-1-0.7-1.7s1-1,1.7-0.7l1.9,0.8C26.8,49.6,27.1,50.3,26.9,51z M32.4,42.6
    c-0.3,0.3-0.6,0.4-0.9,0.4s-0.7-0.1-0.9-0.4l-1.4-1.5c-0.5-0.5-0.5-1.3,0-1.8c0.5-0.5,1.3-0.5,1.8,0l1.5,1.5
    C32.9,41.3,32.9,42.2,32.4,42.6z M40.2,37.3c-0.2,0.1-0.4,0.1-0.5,0.1c-0.5,0-1-0.3-1.2-0.8l-0.8-1.9c-0.3-0.7,0-1.4,0.7-1.7
    c0.7-0.3,1.4,0,1.7,0.7l0.8,1.9C41.1,36.3,40.8,37.1,40.2,37.3z M51.3,34.1c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3V32
    c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3V34.1z M61.7,34.5l-0.8,1.9c-0.2,0.5-0.7,0.8-1.2,0.8c-0.2,0-0.3-0.1-0.5-0.1
    c-0.7-0.3-1-1-0.7-1.7l0.8-1.9c0.3-0.7,1-1,1.7-0.7C61.7,33.1,62,33.8,61.7,34.5z M70.9,41.1l-1.5,1.5c-0.3,0.3-0.6,0.4-0.9,0.4
    c-0.3,0-0.7-0.1-0.9-0.4c-0.5-0.5-0.5-1.3,0-1.8l1.5-1.5c0.5-0.5,1.3-0.5,1.8,0C71.4,39.9,71.4,40.7,70.9,41.1z M74.1,51.2
    c-0.5,0-1-0.3-1.2-0.8c-0.3-0.7,0-1.4,0.7-1.7l1.9-0.8c0.7-0.3,1.4,0,1.7,0.7c0.3,0.7,0,1.4-0.7,1.7l-1.9,0.8
    C74.4,51.2,74.2,51.2,74.1,51.2z M78.2,61.5h-2.1c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3h2.1c0.7,0,1.3,0.6,1.3,1.3
    C79.5,60.9,79,61.5,78.2,61.5z"
  />
</Base>);
