import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props}>
  <g>
    <path d="M96.3,83H2.6c-0.3,0-0.5-0.2-0.5-0.5v-62h1V82h93.2V83z" />
    <path
      d="M92,34.1c-1.1,0-1.9,0.9-1.9,1.9c0,0.1,0,0.2,0,0.3L71.3,53c-0.2-0.1-0.5-0.1-0.7-0.1c-0.5,0-0.9,0.2-1.2,0.5
      l-18.1-6c-0.2-0.8-1-1.5-1.9-1.5c-1.1,0-1.9,0.9-1.9,1.9c0,0.2,0,0.4,0.1,0.5L28.4,69.4c-0.2-0.1-0.4-0.1-0.6-0.1
      c-0.3,0-0.6,0.1-0.8,0.2L9,59.6c0-1-0.9-1.8-1.9-1.8c-1.1,0-1.9,0.9-1.9,1.9c0,1.1,0.9,1.9,1.9,1.9c0.3,0,0.7-0.1,0.9-0.3l17.8,9.8
      c0,0,0,0,0,0.1c0,1.1,0.9,1.9,1.9,1.9c1.1,0,1.9-0.9,1.9-1.9c0-0.1,0-0.2,0-0.3L49,49.7c0.1,0,0.2,0,0.4,0c0.5,0,0.9-0.2,1.2-0.5
      l18.1,6c0.2,0.8,1,1.5,1.9,1.5c1.1,0,1.9-0.9,1.9-1.9c0-0.1,0-0.1,0-0.2l19-16.7c0.2,0.1,0.4,0.1,0.6,0.1c1.1,0,1.9-0.9,1.9-1.9
      C93.9,35,93.1,34.1,92,34.1z"
    />
  </g>
</Base>);
