import React from 'react';
import Base from './Base';

export default (props) => (<Base viewBox="0 0 32 32" {...props}>
  <g transform="matrix(1.15363,0,0,1.42539,-1.94127,-2.28333)">
    <rect x="2.499" y="2.282" width="25.988" height="4.231" />
  </g>
  <g transform="matrix(1.43414,0,0,1.048,-10.462,1.18324)">
    <rect x="8.035" y="7.388" width="20.891" height="21.031" />
  </g>
</Base>);
