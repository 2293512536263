import React, { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';
import { addQualification, editQualification } from '../../../actions';

const QualificationsModal = ({
  t,
  isOpen,
  companyId,
  handleCloseModal,
  initialValues = {},
  closeModalAndRefetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      ...formValues,
      company: companyId,
      short_code: formValues?.name?.replace(/ /g, '-').toLowerCase(),
    };

    if (formValues?.id) {
      await editQualification(companyId, formValues?.id, payload);
    } else {
      await addQualification(companyId, payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  return (
    <Modal
      isOpen={isOpen}
      handleSave={handleSave}
      size={modalSizes.medium}
      handleClose={handleCloseModal}
      disableSave={!formValues?.name}
    >
      {
        isLoading ?
        <ContentLoader /> :
          <div className="asset_form_modal_container">
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.workers.name')}:*</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'name', '')} onChange={(e) => onChange(e.target.value, 'name')} />
              </div>
            </div>
          </div>
      }
    </Modal>
  );
};

QualificationsModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  companyId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(QualificationsModal));
