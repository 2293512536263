import api from 'helpers/api';
import { Notification } from 'shared';

export const changePassword = (payload) => {
  return api.post('/api/v1/change-password/', payload)
    .then((res) => {
      if (res.status === 200) {
        Notification('success', 'Password changed successfully');
        return res;
      }
      throw new Error('Failed to change password');
    })
    .catch((error) => {
      let errorMessage = 'An error occurred while changing the password';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      Notification('error', 'Error changing password', errorMessage);
      throw new Error(errorMessage);
    });
};

export const enable2FA = async () => {
  try {
    const res = await api
      .get('/api/v1/enable-2fa/');
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while enabling 2FA', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getQRCode = async (otp_uri) => {
  try {
    const res = await api
      .get(`/api/v1/otpqr.png/?otp_uri=${otp_uri}`, { responseType: 'arraybuffer' });
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while getting QR', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const validateToken = (data) => {
  return api.post('/api/v1/enable-2fa/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Two-Factor Authentication is enabled');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'Token is not valid, please try again.');
    });
};

export const disable2FA = (data) => {
  return api.post('/api/v1/disable-2fa/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Two-Factor Authentication is disabled');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'Token is not valid, please try again.');
    });
};

export const getCompanyData = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/companies/${companyId}/`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching company data', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
