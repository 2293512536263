/* eslint-disable no-tabs */
import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props} viewBox="0 0 50 50">

			<path
  fill="none"
  stroke="#6B6C6E"
  strokeWidth="2"
  strokeLinecap="round"
  strokeLinejoin="round"
  d="
			M30.7,45.1l-0.1-0.6c-0.1-3,4.5-5,4.5-7.3v-18c0-2.3-3.7-2.75-5.1-2.75v3.65v-3.65c0,0-0.533-2.75-6.133-1.45v5v-5
			c-0.1-0.1-1.467-2-3.867-2s-3.9,1.7-3.9,1.7V20V4c0-2.2-1.8-3.9-4-3.9s-4,1.8-4,3.9v25v-5.9c-4.5,2.3-8.7-0.2-8,5.6
			c1.6,12,7.8,14,10,16.4"
			/>

			<path
  id="Shape_5_"
  fill="none"
  stroke="#6B6C6E"
  strokeWidth="2"
  strokeLinecap="round"
  strokeLinejoin="round"
  d="
			M13,45.1H9.8c-2.1,0-3.7,0.1-3.7,2.4v12.2C6.1,62,7.8,62,9.8,62h19.6c2.1,0,3.7-0.1,3.7-2.3V47.5c0-2.3-1.7-2.4-3.7-2.4H26"
			/>
</Base>);
