import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getCurrentUserSuccess } from 'user/actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { checkModules, checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './styles.scss';
import Pallets from './components/Pallets';
import WarehouseStock from './components/WarehouseStock';
import WarehousePosition from './components/WarehousePosition';
import GoodsMovement from './components/GoodsMovement';

class IndustryLocationWarehouse extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { locationId, companyId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Warehouse');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          this.setState({
            isReadOnly: true,
          });
        }
      });
  }

  render() {
    const { isReadOnly } = this.state;
    const { companyId, locationId, t, showPallets, currentUser, setCurrentUser } = this.props;

    return (
      <div className="warehouse_list fullscreen industry-tab">
        <Tabs>
          <TabList>
            <Tab>{t('page_content.warehouse.tab_stock')}</Tab>
            {showPallets === true && <Tab>{t('page_content.warehouse.tab_pallets')}</Tab>}
            {showPallets === true && <Tab>{t('page_content.warehouse.tab_warehousePositions')}</Tab>}
            {showPallets === true && <Tab>{t('page_content.warehouse.tab_goodsMovement')}</Tab>}
          </TabList>

          <TabPanel>
            <WarehouseStock companyId={companyId} locationId={locationId} isReadOnly={isReadOnly} showPallets={showPallets} />
          </TabPanel>
          {showPallets === true && <TabPanel>
            <Pallets companyId={companyId} locationId={locationId} isReadOnly={isReadOnly} />
          </TabPanel>}
          {showPallets === true && <TabPanel>
            <WarehousePosition setCurrentUser={setCurrentUser} currentUser={currentUser} companyId={companyId} locationId={locationId} />
          </TabPanel>}
          {showPallets === true && <TabPanel>
            <GoodsMovement companyId={companyId} locationId={locationId} />
          </TabPanel>}
        </Tabs>
      </div>
    );
  }
}

IndustryLocationWarehouse.propTypes = {
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  showPallets: PropTypes.bool,
  currentUser: PropTypes.object,
  setCurrentUser: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    setCurrentUser: getCurrentUserSuccess,
    showPallets: state.app.location.config.pallets_enabled,
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(IndustryLocationWarehouse)));
