import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { ContentLoader, Table, Button } from 'shared';
import MaintenanceHistogram from '../../../../shared/widgets/charts/MaintenanceHistogram/MaintenanceHistogram';

const MaintenanceDashboard = ({
  maintenances,
  t,
  isSearching,
  topMaintenancesFilter,
  handleFilterChange,
}) => {
  const [exportInProgress, setExportInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countData, setCountData] = useState([]);
  const [durationData, setDurationData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const prepareTableData = (count, duration) => {
    const newDuration = duration.map((item) => ({ duration: item.value }));
    const preparedTableData = count.map((item, i) => ({ ...item, ...newDuration[i] }));

    setTableData(preparedTableData);
    setLoading(false);
  };

  const formatMaintenanceData = () => {
    setCountData([]);
    setDurationData([]);
    setTableData([]);

    if (maintenances.length) {
      const counts = maintenances.reduce((p, c) => {
        const name = c && c.reported_reason && c.reported_reason.name ? c.reported_reason.name : '-';
        const duration = c && c.total_duration ? c.total_duration : null;

        if (!Object.prototype.hasOwnProperty.call(p, name)) {
          p[name] = { count: 0, duration: 0 };
        }

        p[name].count++;
        p[name].duration += duration;
        return p;
      }, {});

      const countsExtended = Object.keys(counts).map((k) => {
        return { name: k, value: counts[k].count };
      }).sort((a, b) => b.value - a.value);

      const durationExtended = Object.keys(counts).map((k) => {
        return { name: k, value: counts[k].duration };
      }).sort((a, b) => b.value - a.value);

      setCountData(countsExtended);
      setDurationData(durationExtended);
      prepareTableData(countsExtended, durationExtended);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    formatMaintenanceData();
  }, [maintenances]);

  useEffect(() => {
    if (isSearching) {
      setLoading(true);
    }
  }, [isSearching]);

  const convertSecondsToDDHHMMSS = (seconds) => {
    const days = Math.floor(seconds / 86400); // Number of seconds in a day (24 * 60 * 60)
    const remainingSeconds = seconds % 86400;

    const hours = Math.floor(remainingSeconds / 3600);
    const minutes = Math.floor((remainingSeconds % 3600) / 60);
    const secondsLeft = remainingSeconds % 60;

    const formattedTime = [
      String(days).padStart(2, '0'),
      [String(hours).padStart(2, '0'), String(minutes).padStart(2, '0'), String(secondsLeft).padStart(2, '0')].join(':'),
    ].join(' - ');

    return formattedTime;
  };

  const convertDashboardToImage = (id) => {
    const dashboard = document.getElementById(id);

    if (dashboard) {
      html2canvas(dashboard, {
        scale: 1,
      }).then((canvas) => {
        const pageHeight = 295;

        const ratio = canvas.height / canvas.width;

        // eslint-disable-next-line
        const pdf = new jsPDF({
          unit: 'mm',
        });

        let position = 0;

        const width = pdf.internal.pageSize.getWidth();
        let height = pdf.internal.pageSize.getHeight();
        height = ratio * width;
        let heightLeft = height;

        pdf.addImage(canvas.toDataURL(), 'PNG', 5, 10, width, height);
        pdf.setFont('helvetica');

        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = (heightLeft - height) + 9;
          pdf.addPage();
          pdf.addImage(canvas.toDataURL(), 'PNG', 5, position, width, height);
          heightLeft -= pageHeight;
        }

        pdf.save('maintenance_dashboard.pdf');

        setExportInProgress(false);
      });
    }
  };

  const exportDashboard = (id) => {
    setExportInProgress(true);
    convertDashboardToImage(id);
  };

  return (
    <div className="maintenance_dashboard">
      <Tabs>
        <TabList>
          <Tab>{t('page_content.maintenance.table')}</Tab>
          <Tab>{t('page_content.maintenance.graphs')}</Tab>
        </TabList>
        <TabPanel>
          <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="export"
              loading={exportInProgress}
              onClick={() => exportDashboard('maintenance_dashboard-table')}
            >
              {t('page_content.maintenance.export_to_pdf')}
            </Button>
          </div>
          <div id="maintenance_dashboard-table">
            <Table
              style={{ userSelect: 'text' }}
              columns={[
                {
                  Header: () => <span>{t('page_content.maintenance.name')}</span>,
                  accessor: 'name',
                },
                {
                  Header: () => <span>{t('page_content.maintenance.value')}</span>,
                  accessor: 'value',
                  Cell: (row) => (row && row.value ? row.value : '-'),
                },
                {
                  Header: () => <span>{t('page_content.maintenance.duration')}</span>,
                  accessor: 'duration',
                  Cell: (row) => (row && row.value ? convertSecondsToDDHHMMSS(row.value) : '-'),
                },
              ]}
              loading={loading}
              data={tableData || []}
              noDataText=" "
              showPagination={false}
              minRows={0}
              defaultPageSize={1000}
              selectedRow={null}
              sortable
              manual={false}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="export"
              loading={exportInProgress}
              onClick={() => exportDashboard('maintenance_dashboard-charts')}
            >
              {t('page_content.maintenance.export_to_pdf')}
            </Button>
          </div>
          <div id="maintenance_dashboard-charts">
            <div className="toolbar_area">
              <input
                type="checkbox"
                checked={topMaintenancesFilter}
                onChange={(e) => handleFilterChange('topMaintenancesFilter', e.target.checked)}
                style={{ height: '20px', width: '20px' }}
              />
              {t('page_content.maintenance.top_maintenances_filter')}
            </div>
            {
              loading ? (
                <ContentLoader rowVariant text="" />
              ) :
                <React.Fragment>
                  <MaintenanceHistogram
                    data={topMaintenancesFilter ? countData.slice(0, 10) : countData}
                    height={500}
                    chartTitle={t('page_content.maintenance.count_title')}
                  />
                  <MaintenanceHistogram
                    data={topMaintenancesFilter ? durationData.slice(0, 10) : durationData}
                    height={500}
                    chartTitle={t('page_content.maintenance.duration_title')}
                    showValuesAsDate
                  />
                </React.Fragment>
            }
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

MaintenanceDashboard.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  maintenances: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  topMaintenancesFilter: PropTypes.bool.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
};

export default MaintenanceDashboard;
