/* eslint-disable react/jsx-indent */
import React, { Component } from 'react';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Notification } from 'shared';
import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';
import { getAssets, getShifts, saveShift, editShift, deleteShift, getShiftsPaginated } from './actions';
import './style.scss';
import Shifts from './components/Shifts/index';

class ShiftsTab extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    isFetchingShifts: true,
  };

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToSettingsPage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    this.getShifts();

    getAssets(companyId)
      .then((re) => {
        this.setState({
          assets: get(re, 'data.results', []),
        });
      }).catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  getPaginatedShifts = (url) => {
    this.setState({
      isFetchingShifts: true,
    });

    getShiftsPaginated(url)
      .then((re) => {
        this.setState({
          shifts: get(re, 'data.results', []),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isFetchingShifts: false,
        });
      });
  }

  getShifts = () => {
    const { locationId } = this.props;

    getShifts(locationId)
      .then((re) => {
        this.setState({
          shifts: get(re, 'data.results', []),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isFetchingShifts: false,
        });
      });
  }

  deleteShift = (shift) => {
    this.setState({
      isFetchingShifts: true,
    });

    deleteShift(shift)
      .then(() => {
        this.getShifts();
      });
  }

  saveShift = (shift) => {
    this.setState({
      isFetchingShifts: true,
    });

    if (shift.id) {
      editShift(shift)
        .then(() => {
          this.getShifts();
        });
    } else {
      saveShift(shift)
        .then(() => {
          this.getShifts();
        });
    }
  }

  render() {
    const { locationId } = this.props;
    const { assets, shifts, isReadOnly, isFetchingShifts, next, previous, count } = this.state;

    return (
      <div className="default-form shifts-tab">
        <Shifts
          next={next}
          count={count}
          shifts={shifts}
          assets={assets}
          previous={previous}
          locationId={locationId}
          isReadOnly={isReadOnly}
          saveShift={this.saveShift}
          deleteShift={this.deleteShift}
          isFetchingShifts={isFetchingShifts}
          fetchShifts={this.getPaginatedShifts}
        />
      </div>);
  }
}

ShiftsTab.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  //   t: PropTypes.func.isRequired,
};

export default withTranslation()(ShiftsTab);
