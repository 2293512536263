import React, { Component } from 'react';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import { get } from 'lodash';
import { Button, ContentLoader, Modal, Notification, Table, ConfirmationModal } from 'shared';
import { addOrderPallets, getOrderPallets } from '../../actions';
import './style.scss';

class OrderWarehouseRelocation extends Component {
  constructor() {
    super();
    this.state = {
      orderPallets: [],
      listOfNewPallets: [],
      isLoadingOrderPallets: true,
      isChangePalletModalOpen: false,
      isAddPalletModalOpen: false,
      isLoadingChangeOrderPalletsModal: true,
      changePalletRow: null,
      changePalletShelf: null,
      changePalletNumber: null,
      changePalletData: {},
      deleteData: {},
      showConfirmationDialog: false,
    };
  }

  componentDidMount() {
    const { getOrderId } = this.props;

    const orderId = getOrderId();
    getOrderPallets(orderId)
      .then((res) => {
        this.setState({
          orderPallets: get(res, 'data.results') || [],
          isLoadingOrderPallets: false,
        });
      })
      .catch((e) => console.log('Error while fetching order pallets', e));
  }

  checkForDuplicatePositions = () => {
    const { orderPallets, listOfNewPallets, changePalletRow, changePalletShelf, changePalletNumber, changePalletData } = this.state;

    let combinedArray = orderPallets.concat(listOfNewPallets);

    combinedArray = [...combinedArray, { row: changePalletRow, shelf: changePalletShelf, number: changePalletNumber, location: changePalletData.location }];

    const filteredCombinedArray = combinedArray.filter((pallet) => pallet.row !== '' || pallet.shelf !== '');

    // Helper function to check if two objects have the same location, row, and shelf
    function areObjectsEqual(obj1, obj2) {
      return obj1.location === obj2.location &&
        obj1.row === obj2.row &&
        obj1.shelf === obj2.shelf;
    }

    // Iterate over the array
    for (let i = 0; i < filteredCombinedArray.length; i++) {
      for (let j = i + 1; j < filteredCombinedArray.length; j++) {
        // Check if the current objects have the same location, row, and shelf
        if (areObjectsEqual(filteredCombinedArray[i], filteredCombinedArray[j])) {
          Notification('error', 'An error occurred', 'You have entered position(row/shelf) that already exists in this location. Positions must be unique!');
          return true; // A match is found, return true
        }
      }
    }

    // No matching objects found, return false
    return false;
  }

  handleChangePallet = (palletData) => {
    this.setState({
      isChangePalletModalOpen: true,
      changePalletRow: null,
      changePalletShelf: null,
      changePalletNumber: null,
      isLoadingChangeOrderPalletsModal: false,
      changePalletData: palletData,
    });
  }

  closeOrderPalletsModal = () => {
    this.setState({
      isChangePalletModalOpen: false,
    });
  }

  saveChangesOrderPallets = () => {
    const { changePalletData, changePalletRow, changePalletShelf, changePalletNumber } = this.state;
    const { order } = this.props;
    const newPalletData = { ...changePalletData };
    if (changePalletRow) {
      newPalletData.row = changePalletRow;
    }
    if (changePalletShelf) {
      newPalletData.shelf = changePalletShelf;
    }
    if (changePalletNumber) {
      newPalletData.number = changePalletNumber;
    }

    const isDuplicate = this.checkForDuplicatePositions();

    if (isDuplicate) {
      return;
    }
    if (order && order.id && newPalletData && newPalletData.id) {
      api.put(`/api/v1/orders/order_pallets/${newPalletData.id}/?order=${order.id}`, newPalletData)
        .then(() => {
          this.setState({
            isChangePalletModalOpen: false,
          });
          if (order && order.id) {
            this.setState({
              isLoadingOrderPallets: true,
            });
            getOrderPallets(order.id)
              .then((res) => {
                this.setState({
                  orderPallets: get(res, 'data.results') || [],
                  isLoadingOrderPallets: false,
                });
              })
              .catch((e) => console.log('Error while fetching order pallets', e));
          }
        })
        .catch((error) => {
          return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
        });
    }

    this.setState({
      isChangePalletModalOpen: false,
    });
  }

  openAddPalletsModal = () => {
    const { locationId, order } = this.props;

    this.setState({
      isAddPalletModalOpen: true,
      listOfNewPallets: [{
        order: order && order.id ? order.id : null,
        location: locationId,
        row: '',
        shelf: '',
        number: 1,
      }],
    });
  }

  addNewPallet = () => {
    const { listOfNewPallets } = this.state;
    const { locationId, order } = this.props;

    const newPallet = {
      order: order && order.id ? order.id : null,
      location: locationId,
      row: '',
      shelf: '',
      number: 1,
    };

    const isDuplicate = this.checkForDuplicatePositions();

    if (isDuplicate) {
      return;
    }

    const updatedListOfPallets = [...listOfNewPallets, newPallet];

    this.setState({
      listOfNewPallets: updatedListOfPallets,
    });
  }

  closeAddPalletsModal = () => {
    this.setState({
      isAddPalletModalOpen: false,
    });
  }

  saveAddOrderPallets = () => {
    const { orderPallets, listOfNewPallets } = this.state;
    const { order } = this.props;

    const allPalletsData = orderPallets.concat(listOfNewPallets);
    const filteredAllPalletsData = allPalletsData.filter((pallet) => pallet.row !== '' || pallet.shelf !== '');

    const isDuplicate = this.checkForDuplicatePositions();
    if (isDuplicate) {
      return;
    }

    if (order && order.id) {
      addOrderPallets(order.id, filteredAllPalletsData)
        .then(() => {
          this.setState({
            isAddPalletModalOpen: false,
            isLoadingOrderPallets: true,
          });

          getOrderPallets(order.id)
            .then((res) => {
              this.setState({
                orderPallets: get(res, 'data.results') || [],
                isLoadingOrderPallets: false,
                listOfNewPallets: [],
              });
            })
            .catch((e) => console.log('Error while fetching order pallets', e));
        });
    }
  }

  handleNewPalletRow = (row, index) => {
    const { listOfNewPallets } = this.state;

    const updatedListOfPallets = listOfNewPallets;
    updatedListOfPallets[index].row = row;

    this.setState({
      listOfNewPallets: updatedListOfPallets,
    });
  }

  handleNewPalletShelf = (shelf, index) => {
    const { listOfNewPallets } = this.state;

    const updatedListOfPallets = listOfNewPallets;
    updatedListOfPallets[index].shelf = shelf;

    this.setState({
      listOfNewPallets: updatedListOfPallets,
    });
  }

  handleNewPalletNumber = (number, index) => {
    const { listOfNewPallets } = this.state;

    const updatedListOfPallets = listOfNewPallets;
    updatedListOfPallets[index].number = number;

    this.setState({
      listOfNewPallets: updatedListOfPallets,
    });
  }

  handleShowConfirmationDialog = (pallet) => {
    this.setState({ deleteData: pallet, showConfirmationDialog: true });
  }

  handleRemovePallet = () => {
    const { listOfNewPallets, deleteData } = this.state;
    const pallet = deleteData;
    const index = listOfNewPallets.indexOf(pallet);
    if (index > -1) {
      listOfNewPallets.splice(index, 1);
    }

    const showModal = listOfNewPallets.length !== 0;
    this.setState({
      listOfNewPallets,
      isAddPalletModalOpen: showModal,
      showConfirmationDialog: false,
    });
  }

  render() {
    const {
      orderPallets,
      isLoadingOrderPallets,
      isChangePalletModalOpen,
      isLoadingChangeOrderPalletsModal,
      changePalletData,
      isAddPalletModalOpen,
      listOfNewPallets,
      showConfirmationDialog,
    } = this.state;
    const {
      t,
      getLocationName,
    } = this.props;

    return (
      <div className="warehouse_relocation_tab">

        {isLoadingOrderPallets && <ContentLoader />}

        {isChangePalletModalOpen &&
          <Modal
            isOpen={isChangePalletModalOpen}
            handleSave={this.saveChangesOrderPallets}
            handleClose={this.closeOrderPalletsModal}
            title={t('page_content.orders.order_details.pallet_relocation_tab.change_pallet_modal.modal_title')}
          >
            {isLoadingChangeOrderPalletsModal && <div
              className="reason-form-loader"
              style={{
                borderLeftColor: 'transparent',
              }}
            />}
            {changePalletData && <div>
              <div className="pallet_modal">
                <div className="pallet_current">
                  <div>
                    <p className="pallet_current_title">{t('page_content.orders.order_details.pallet_relocation_tab.change_pallet_modal.current_row')}</p>
                    <p className="pallet_current_data">{changePalletData.row || '-'}</p>
                  </div>
                  <div>
                    <p className="pallet_current_title">{t('page_content.orders.order_details.pallet_relocation_tab.change_pallet_modal.current_shelf')}</p>
                    <p className="pallet_current_data">{changePalletData.shelf || '-'}</p>
                  </div>
                  <div>
                    <p
                      className="pallet_current_title"
                      style={{
                        width: '130px',
                      }}
                    >{t('page_content.orders.order_details.pallet_relocation_tab.change_pallet_modal.current_number_of_pallets')}</p>
                    <p className="pallet_current_data">{changePalletData.number || '-'}</p>
                  </div>
                </div>
                <div className="pallet_new">
                  <div>
                    <p className="pallet_new_title">{t('page_content.orders.order_details.pallet_relocation_tab.change_pallet_modal.new_row')}</p>
                    <input
                      className="pallet_new_data"
                      type="text"
                      onChange={(e) => this.setState({
                        changePalletRow: e.target.value,
                      })}
                    />
                  </div>
                  <div>
                    <p className="pallet_new_title">{t('page_content.orders.order_details.pallet_relocation_tab.change_pallet_modal.new_shelf')}</p>
                    <input
                      className="pallet_new_data"
                      type="text"
                      onChange={(e) => this.setState({
                        changePalletShelf: e.target.value,
                      })}
                    />
                  </div>
                  <div>
                    <p className="pallet_new_title">{t('page_content.orders.order_details.pallet_relocation_tab.change_pallet_modal.new_number_of_pallets')}</p>
                    <input
                      className="pallet_new_data"
                      type="number"
                      onChange={(e) => this.setState({
                        changePalletNumber: e.target.value,
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>}
          </Modal>}

        {isAddPalletModalOpen &&
          <Modal
            isOpen={isAddPalletModalOpen}
            handleSave={this.saveAddOrderPallets}
            handleClose={this.closeAddPalletsModal}
            title={t('page_content.orders.order_details.pallet_relocation_tab.add_new_pallet_modal.modal_title')}
          >
            <div className="wrapper_new_pallet">
              {listOfNewPallets && listOfNewPallets.length && listOfNewPallets.map((pallet, index) => {
                return (<div key={index}>
                  <p className="pallet_name">{t('page_content.orders.order_details.pallet_relocation_tab.add_new_pallet_modal.pallete')} {index + 1}</p>
                  <div className="pallet_modal">
                    <div className="pallet_add_new">
                      <div>
                        <p className="pallet_add_new_title">{t('page_content.orders.order_details.pallet_relocation_tab.add_new_pallet_modal.new_row')}</p>
                        <input className="pallet_add_new_data" type="text" onChange={(e) => this.handleNewPalletRow(e.target.value, index)} value={pallet.row || ''} />
                      </div>
                      <div>
                        <p className="pallet_add_new_title">{t('page_content.orders.order_details.pallet_relocation_tab.add_new_pallet_modal.new_shelf')}</p>
                        <input className="pallet_add_new_data" type="text" onChange={(e) => this.handleNewPalletShelf(e.target.value, index)} value={pallet.shelf || ''} />
                      </div>
                      <div>
                        <p className="pallet_add_new_title">{t('page_content.orders.order_details.pallet_relocation_tab.add_new_pallet_modal.new_number_of_pallets')}</p>
                        <input className="pallet_add_new_data" type="number" onChange={(e) => this.handleNewPalletNumber(e.target.value, index)} value={pallet.number || ''} />
                      </div>
                      <div>
                        <Button
                          style={{
                            marginTop: '20px',
                          }}
                          type="delete"
                          onClick={() => this.handleShowConfirmationDialog(pallet)}
                        >
                          {t('page_content.orders.order_details.pallet_relocation_tab.add_new_pallet_modal.remove_pallet_button')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>);
              })}
            </div>
            <p className="add_new_pallet_description">{t('page_content.orders.order_details.pallet_relocation_tab.add_new_pallet_modal.add_pallet_description')}</p>
            <Button
              style={{ marginLeft: '20px' }}
              onClick={this.addNewPallet}
              type="add"
            >
              {t('page_content.orders.order_details.pallet_relocation_tab.add_new_pallet_modal.add_new_pallet_button')}
            </Button>
          </Modal>}

        {!isLoadingOrderPallets &&
          <Button
            style={{
              marginBottom: '25px',
            }}
            type="add"
            onClick={this.openAddPalletsModal}
          >
            {t('page_content.orders.order_details.pallet_relocation_tab.add_pallet_button')}
          </Button>}

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => (<span>{t('page_content.orders.order_details.pallet_relocation_tab.pallet_location_table.location')}</span>),
              accessor: 'location',
              Cell: (row) => <span>{row.value ? getLocationName(row.value) : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => (<span>{t('page_content.orders.order_details.pallet_relocation_tab.pallet_location_table.row')}</span>),
              accessor: 'row',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => (<span>{t('page_content.orders.order_details.pallet_relocation_tab.pallet_location_table.shelf')}</span>),
              accessor: 'shelf',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => (<span>{t('page_content.orders.order_details.pallet_relocation_tab.pallet_location_table.number_of_pallets')}</span>),
              accessor: 'number',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => (<span>{t('page_content.orders.order_details.pallet_relocation_tab.pallet_location_table.added_by')}</span>),
              accessor: 'user',
              Cell: (row) => (<span>
                {row.value && row.value.first_name && row.value.last_name ? `${row.value.first_name} ${row.value.last_name}`
                  : row.value && row.value.email ? row.value.email : '-'}
              </span>),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => (<span>{t('page_content.orders.order_details.pallet_relocation_tab.pallet_location_table.change_pallet_location')}</span>),
              accessor: 'pallet',
              sortable: false,
              Cell: (row) => (
                <Button
                  type="dark-blue"
                  onClick={() => this.handleChangePallet(row.original)}
                >
                  {t('page_content.orders.order_details.pallet_relocation_tab.pallet_location_table.change_pallet_location_button')}
                </Button>),
              style: {
                cursor: 'default',
              },
            },
          ]}
          data={orderPallets || []}
          minRows={0}
          defaultPageSize={100}
          noDataText=" "
          showPagination={false}
          sortable
          defaultSorted={[{ id: 'number', desc: false }]}
          manual={false}
          loading={isLoadingOrderPallets}
        />
         <ConfirmationModal
           showModal={showConfirmationDialog}
           handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
           handleConfirmModal={this.handleRemovePallet}
           type="warning"
         />
      </div>
    );
  }
}

OrderWarehouseRelocation.propTypes = {
  t: PropTypes.func.isRequired,
  getOrderId: PropTypes.func.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  getLocationName: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

export default OrderWarehouseRelocation;
