import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'shared';
import Select from 'react-select';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { withTranslation } from 'react-i18next';
import './style.scss';

class NotificationsForm extends Component {
  constructor(props) {
    super();
    this.state = {
      notificationObject: {
        api_key: '',
        notification_type: props.notificationTypeOptions.find((tOption) => tOption.id === 'sms_infobip').id,
        is_enabled: false,
        trigger_source: props.triggerSourceOptions[0].id,
        template: '',
        company: props.companyId,
      },
    };
    if (props.notification) {
      const { notification } = props;
      const updatedNotificationObject = {
        ...this.state.notificationObject,
        api_key: notification.api_key || this.state.notificationObject.api_key,
        notification_type: notification.notification_type || this.state.notificationObject.notification_type,
        is_enabled: notification.is_enabled || this.state.notificationObject.is_enabled,
        trigger_source: notification.trigger_source || this.state.notificationObject.trigger_source,
        template: notification.template || this.state.notificationObject.template,
        company: notification.company || this.state.notificationObject.company,
        id: notification.id,
      };
      this.state = { notificationObject: updatedNotificationObject };
    }
  }

  handleModalChange = (key, value) => {
    this.setState((prevState) => ({
      notificationObject: {
        ...prevState.notificationObject,
        [key]: value,
      },
    }));
  }

  handleTemplateResize = (e) => {
    e.target.style.height = '100px';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  handleSave = () => {
    const { notificationObject } = this.state;
    const { saveNotification } = this.props;
    saveNotification(notificationObject);
  }

  render() {
    const { isOpen, onClose, t, notificationTypeOptions, triggerSourceOptions } = this.props;
    const { notificationObject } = this.state;

    return (
      <Modal isOpen={isOpen} handleClose={onClose} handleSave={this.handleSave} title={!notificationObject.id ? t('settings.notifications.modal_title_add') : t('settings.notifications.modal_title_edit')}>
        <div className="modal_container">
          <div className="modal_row">
            <div className="modal_row">
              <div className="left_text">{`${t('settings.notifications.table_column_apikey')}:`}</div>
              <div className="right_input">
                <input onChange={(e) => this.handleModalChange('api_key', e.target.value)} value={notificationObject.api_key} />
              </div>
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{`${t('settings.notifications.table_column_notificationType')}:`}</div>
            <div className="right_select">
              <Select
                options={notificationTypeOptions}
                getOptionLabel={(nTypeOption) => nTypeOption.name}
                getOptionValue={(nTypeOption) => nTypeOption.id}
                isSearchable
                placeholder=""
                menuPosition="fixed"
                onChange={(value) => this.handleModalChange('notification_type', value.id)}
                value={(notificationTypeOptions.find((u) => u.id === notificationObject.notification_type)) || ''}
                styles={selectModalStyles}
              />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{`${t('settings.notifications.table_column_enabled')}:`}</div>
            <div className="right_checkbox">
              <input type="checkbox" onChange={(e) => this.handleModalChange('is_enabled', e.target.checked)} checked={notificationObject.is_enabled} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{`${t('settings.notifications.table_column_triggerSource')}:`}</div>
            <div className="right_select">
              <Select
                options={triggerSourceOptions}
                getOptionLabel={(tSourceOption) => tSourceOption.name}
                getOptionValue={(tSourceOption) => tSourceOption.id}
                isSearchable
                placeholder=""
                menuPosition="fixed"
                onChange={(value) => this.handleModalChange('trigger_source', value.id)}
                value={(triggerSourceOptions.find((u) => u.id === notificationObject.trigger_source)) || ''}
                styles={selectModalStyles}
              />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{`${t('settings.notifications.table_column_template')}:`}</div>
            <div className="right_textarea">
              <textarea onChange={(e) => this.handleModalChange('template', e.target.value)} onInput={this.handleTemplateResize} value={notificationObject.template} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

NotificationsForm.propTypes = {
  notification: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  notificationTypeOptions: PropTypes.object.isRequired,
  triggerSourceOptions: PropTypes.object.isRequired,
  saveNotification: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default withTranslation()(NotificationsForm);
