import React from 'react';
import PropTypes from 'prop-types';

import { modalSizes } from 'shared/constants';
import { Modal } from 'shared';
import '../styles.scss';

const ProfilePictureModal = ({
  isOpen,
  profilePicture,
  handleCloseModal,
}) => {
  return (
    <Modal
      size={modalSizes.large}
      handleClose={handleCloseModal}
      isOpen={isOpen}
    >
      <div className="profile-picture-modal" style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
          src={profilePicture}
          className="profile-picture-modal__image"
          alt="profile"
        />
      </div>
    </Modal>
  );
};

ProfilePictureModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  profilePicture: PropTypes.string,
  handleCloseModal: PropTypes.func.isRequired,
};

export default ProfilePictureModal;
