import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';
import { bomImport } from '../../../actions';

const ImportBomModal = ({
  t,
  isImportBomModalOpen,
  handleCloseBomModal,
  getOrderId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  const handleSave = async () => {
    setIsLoading(true);

    const orderId = getOrderId();

    const formData = new FormData();
    formData.append('file', file);

    await bomImport(orderId, formData);
    setIsLoading(false);
    handleCloseBomModal();
  };

  const handleClose = () => {
    handleCloseBomModal();
  };

  const onChangeFile = (e) => {
    const fileValue = e.target.files;

    if (fileValue) {
      setFile(get(fileValue, '[0]', null));
    }
  };

  return (
    <Modal
      size={modalSizes.medium}
      isOpen={isImportBomModalOpen}
      handleClose={handleClose}
      handleSave={handleSave}
      disableSave={!file}
    >
      {
        isLoading ?
          <ContentLoader /> :
          <div className="default-form">
            <table>
              <tbody>
                <tr>
                  <td className="label">
                    {t('page_content.orders.order_details.order_groups_tab.file')}*:
                  </td>
                  <td className="file_input">
                    <input
                      style={{ border: 'none', width: '200px' }}
                      type="file"
                      onChange={onChangeFile}
                      multiple={false}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      }

    </Modal>

  );
};

ImportBomModal.propTypes = {
  t: PropTypes.func.isRequired,
  handleCloseBomModal: PropTypes.func.isRequired,
  getOrderId: PropTypes.func.isRequired,
  isImportBomModalOpen: PropTypes.bool.isRequired,
};

export default ImportBomModal;
