import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import {
  Button,
} from 'shared';
import { widgetTypes } from '../constants';
import { getCompanyShortCode } from './actions';
import './styles.scss';

class AddWidgetModal extends Component {
  constructor() {
    super();
    this.state = {
      selected: null,
      company_short_code: null,
    };
    this.addWidget = this.addWidget.bind(this);
    this.generateWidgets = this.generateWidgets.bind(this);
  }

  componentDidMount() {
    const { companyId } = this.props;

    getCompanyShortCode(companyId)
      .then((re) => {
        this.setState({
          company_short_code: re.data.short_code || null,
        });
      });
  }

  generateWidgets(widgets) {
    return widgets
      .filter((x) => x.type !== 'map')
      .map((widget, index) => (
        <div
          className={this.state.selected === widget.type ? 'selected' : ''}
          onClick={() => this.setState({ selected: widget.type })}
          // eslint-disable-next-line
          tabIndex="1"
          key={`widget-type-${index}`}
        >
          {widget.icon}
          <span>
            {widget.name}
          </span>
        </div>
      ));
  }

  addWidget() {
    this.props.onAddWidget(this.state.selected);
    this.props.handleRequestClose();
  }

  render() {
    const { company_short_code } = this.state;
    const {
      handleRequestClose,
      isOpen,
      t,
      currentUser,
    } = this.props;

    let filteredWidgetTypes = widgetTypes;

    if (!currentUser || !currentUser.is_admin || company_short_code !== 'omco') {
      filteredWidgetTypes = widgetTypes.filter((obj) => {
        return obj.type !== 'realtime';
      });
    }

    if (!currentUser || !currentUser.is_admin) {
      filteredWidgetTypes = widgetTypes.filter((obj) => {
        return obj.type !== 'orderstable';
      });
    }

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={handleRequestClose}
        style={{ content: { padding: '0', top: '20px', left: '0', right: '0', bottom: 'initial' } }}
        shouldCloseOnOverlayClick={false}
      >
        <header>
          {t('page_content.dashboards.add_widget_modal.modal_title')}
        </header>
        <div className="add-widget-modal">
          <div className="widget-types">
            {this.generateWidgets(filteredWidgetTypes)}
          </div>
        </div>
        <footer className="modal-footer">
          <Button
            disabled={!this.state.selected}
            onClick={this.addWidget}
            type="add"
          >
            Add widget
          </Button>
          <Button
            onClick={handleRequestClose}
          >
            Cancel
          </Button>
        </footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

AddWidgetModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAddWidget: PropTypes.func.isRequired,
  handleRequestClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default connect(mapStateToProps, null)((withTranslation()(AddWidgetModal)));
