import React, { Component } from 'react';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import api from 'helpers/api';
import PropTypes from 'prop-types';
import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';
import { Button, Table, Notification, TableButtons, ConfirmationModal } from 'shared';
import { IconRemove } from 'shared/Icons';
import {
  getOperatorViews,
  editOperatorView,
  saveOperatorView,
} from '../../actions';
import OperatorViewForm from './components/OperatorViewForm/index';
import './style.scss';

class OperatorViewTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingOperatorView: true,
      isOperatorViewFormOpen: false,
      isSavingOperatorView: false,
      selectedOperatorView: null,
      next: null,
      previous: null,
      count: 0,
      currentUrl: '',
      selectedAscDesc: 'desc',
      selectedSort: 'name',
      deleteData: null,
      showConfirmationDialog: false,
    };
  }

  componentDidMount() {
    const {
      locationId,
      companyId,
    } = this.props;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToSettingsPage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    this.fetchOperatorViews();
  }

  handleSorting = (sortData) => {
    this.setState({
      selectedSort: sortData.id,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchOperatorViews();
    });
  };

  fetchOperatorViews = () => {
    this.setState({ isLoadingOperatorView: true });
    const { locationId } = this.props;
    const { selectedAscDesc, selectedSort } = this.state;

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const url = `/api/v1/dashboards/operator-views/?location=${locationId}&order_by=${asc}${selectedSort}`;

    getOperatorViews(url)
      .then((re) => {
        this.setState({
          operatorViews: get(re, 'data.results'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isLoadingOperatorView: false,
          currentUrl: url,
        });
      })
      .catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  handleSaveOperatorView = (data) => {
    const { currentUrl } = this.state;

    this.setState({
      isSavingOperatorView: true,
      isLoadingOperatorView: true,
    });
    saveOperatorView(data)
      .then(() => {
        this.setState({
          isOperatorViewFormOpen: false,
          isSavingOperatorView: false,
          isLoadingOperatorView: false,
        });
        getOperatorViews(currentUrl)
          .then((re) => {
            this.setState({
              operatorViews: get(re, 'data.results'),
              next: get(re, 'data.next'),
              previous: get(re, 'data.previous'),
              count: get(re, 'data.count'),
              isLoadingOperatorView: false,
            });
          })
          .catch((error) => {
            return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
          });
      });
  }

  handleEditOperatorView = (data) => {
    const { selectedOperatorView, currentUrl } = this.state;
    this.setState({
      isSavingOperatorView: true,
      isLoadingOperatorView: true,
    });
    editOperatorView(data, selectedOperatorView).then(() => {
      const { operatorViews } = this.state;
      operatorViews.forEach((r) => {
        if (r.id === data.id) {
          r.name = data.name;
          r.view_type = data.view_type;
        }
      });
      getOperatorViews(currentUrl)
        .then((re) => {
          this.setState({
            operatorViews: get(re, 'data.results'),
            next: get(re, 'data.next'),
            previous: get(re, 'data.previous'),
            count: get(re, 'data.count'),
            isLoadingOperatorView: false,
          });
        })
        .catch((error) => {
          return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
        });
      this.setState({
        operatorViews,
        isOperatorViewFormOpen: false,
        isSavingOperatorView: false,
        isLoadingOperatorView: false,
        selectedOperatorView: null,
      });
    });
  };

  handleShowConfirmationModal = (operator_view, e) => {
    e.stopPropagation();
    this.setState({ showConfirmationDialog: true, deleteData: operator_view });
  }

  handleDeleteOperatorView = () => {
    const { currentUrl, deleteData } = this.state;

    const id = deleteData.original.id;
    const location = deleteData.original.location;

    return api
      .delete(`/api/v1/dashboards/operator-views/${id}/?location=${location}`)
      .then(() => {
        getOperatorViews(currentUrl)
          .then((re) => {
            this.setState({
              operatorViews: get(re, 'data.results'),
              next: get(re, 'data.next'),
              previous: get(re, 'data.previous'),
              count: get(re, 'data.count'),
              isLoadingOperatorView: false,
            });
          });
        Notification('success', 'Delete successful', 'Operator view was successfuly deleted.');
      })
      .catch(() => {
        Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
      })
      .finally(() => this.setState({ showConfirmationDialog: false }));
  }

  openOperatorViewForm = () => {
    this.setState({
      isOperatorViewFormOpen: true,
    });
  }

  handleOperatorViewRowClick = (operatorView) => {
    const { isReadOnly } = this.state;

    if (!isReadOnly) {
      this.setState({
        selectedOperatorView: operatorView,
      }, () => {
        this.openOperatorViewForm();
      });
    }
  }

  closeOperatorViewFormModal = () => {
    this.setState({
      isOperatorViewFormOpen: false,
      selectedOperatorView: null,
    });
  }

  fetchData = (url) => {
    const { selectedSort, selectedAscDesc } = this.state;
    this.setState({ isLoadingOperatorView: true });
    let currentUrl = url;

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const tableFilter = `&order_by=${asc}${selectedSort}`;
    currentUrl += tableFilter;

    getOperatorViews(currentUrl)
      .then((re) => {
        this.setState({
          operatorViews: get(re, 'data.results'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isLoadingOperatorView: false,
          currentUrl: url,
        });
      })
      .catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  };

  render() {
    const {
      operatorViews,
      isLoadingOperatorView,
      isOperatorViewFormOpen,
      isSavingOperatorView,
      selectedOperatorView,
      isReadOnly,
      next,
      previous,
      count,
    } = this.state;

    const { locationId, t } = this.props;

    return (
      <div className="default-form operator-view">
        <div>
          <Button
            onClick={this.openOperatorViewForm}
            style={{ marginBottom: '10px' }}
            disabled={isReadOnly}
            type="add"
          >
          {t('settings.operator_view.add_new_operator_view_button')}
        </Button>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('settings.operator_view.table_column_name')}</span>,
              accessor: 'name',
            },
            {
              Header: () => <span>{t('settings.operator_view.table_column_type')}</span>,
              accessor: 'view_type',
            },
            {
              Header: () => <span>{t('settings.operator_view.table_column_delete')}</span>,
              Cell: (operator_view) => (
                <Button
                  disabled={isReadOnly}
                  className="remove-btn"
                  type="delete"
                  onClick={(e) => this.handleShowConfirmationModal(operator_view, e)}
                ><IconRemove width="12px" height="12px" /></Button>),
              sortable: false,
            },
          ]}
          data={operatorViews || []}
          loading={isLoadingOperatorView}
          minRows={0}
          F
          noDataText=" "
          showPagination={false}
          defaultPageSize={100}
          handleClick={(rowInfo) => this.handleOperatorViewRowClick(get(rowInfo, 'original'))}
          defaultSorted={[{ id: 'name', desc: true }]}
          onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
        />
        <span style={{ float: 'right' }}>
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchData} count={count} />
        </span>
        {
          isOperatorViewFormOpen && <OperatorViewForm
            isOpen={isOperatorViewFormOpen}
            closeModal={this.closeOperatorViewFormModal}
            isLoadingState={isSavingOperatorView}
            locationId={locationId}
            saveOperatorView={selectedOperatorView ? this.handleEditOperatorView : this.handleSaveOperatorView}
            operatorView={selectedOperatorView || null}
          />
        }
        <ConfirmationModal
          itemName={this.state.deleteData?.original?.name || ''}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDeleteOperatorView}
          type="warning"
        />
      </div>);
  }
}

OperatorViewTab.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(OperatorViewTab);
