/* eslint-disable import/prefer-default-export */
import api from 'helpers/api';
import { Notification } from 'shared';

export const getDepartmentTechnologies = async (locationId, departmentId, filter = '') => {
  const urlString = '/api/v1/department_technologies/';
  let urlParams = '?limit=30';

  if (locationId) {
    urlParams += `&location=${locationId}`;
  }
  if (departmentId) {
    urlParams += `&department=${departmentId}`;
  }
  if (filter) {
    urlParams += `${filter}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching department technologies', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getTechnologyDepartments = async (locationId, limit = 30, filter) => {
  let url = `/api/v1/departments/?location=${locationId}&limit=${limit}`;
  if (filter) {
    url += `&${filter}`;
  }

  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching departments', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedTechnologies = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching technologies', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addDepartmentTechnology = async (technologyData) => {
  return api.post('/api/v1/department_technologies/', technologyData);
};

export const editDepartmentTechnology = async (externalId, locationId, technologyData) => {
  return api.put(`/api/v1/department_technologies/${externalId}/?location=${locationId}`, technologyData);
};

export const deleteDepartmentTechnology = async (externalId, locationId) => {
  return api.delete(`/api/v1/department_technologies/${externalId}/?location=${locationId}`);
};

export const addTechnologyStep = async (techId, techData) => {
  return api.post(`/api/v1/operation_technologies/?technology=${techId}`, techData)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const editTechnologyStep = async (techId, techStepId, data) => {
  return api.put(`/api/v1/operation_technologies/${techStepId}/?technology=${techId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getTechnologySteps = async (technologyId) => {
  try {
    const res = await api.get(`/api/v1/operation_technologies/?technology=${technologyId}&limit=9999&order_by=name`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching department steps', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteTechnologyStep = (techStepId, technologyId, isBulkDelete = false) => {
  return api.delete(`/api/v1/operation_technologies/${techStepId}/?technology=${technologyId}`)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      if (!isBulkDelete) Notification('success', 'Step deleted', 'Step was successfully deleted.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};
