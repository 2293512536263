import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import api from 'helpers/api';
import { modalSizes } from 'shared/constants';
import { IconRemove, IconEdit } from 'shared/Icons';
import { Modal, Notification, Table, TableButtons, Button, ContentLoader, ConfirmationModal } from 'shared';
import { importFile } from '../../actions';

const KFKModal = ({
  selectedCompanyTable,
  handleClose,
  isOpen,
  t,
  isReadOnly,
}) => {
  const [currentView, setCurrentView] = useState('table');

  const [tableData, setTableData] = useState({
    isLoading: false,
    data: [],
    count: 0,
    next: null,
    previous: null,
    currentUrl: null,
  });

  const [selectedRow, setSelectedRow] = useState(null);
  const [isSelectedRowUpdated, setIsSelectedRowUpdated] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const [isImporting, setIsImporting] = useState(false);
  const [importData, setImportData] = useState(null);
  const [selectedSort, setSelectedSort] = useState('material');
  const [selectedAscDesc, setSelectedAscDesc] = useState('asc');

  const getTable = (endpoint) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const tableFilter = `&order_by=${asc}${selectedSort}`;

    api.get(`${endpoint}?limit=15${tableFilter}`)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      })
      .catch(() => {
        return Notification('error', 'An error occurred');
      });
  };

  useEffect(() => {
    if (selectedCompanyTable && selectedCompanyTable.endpoint) {
      getTable(selectedCompanyTable.endpoint);
    }
  }, [selectedCompanyTable, selectedAscDesc, selectedSort]);

  const onChange = (value, field) => {
    setSelectedRow({
      ...selectedRow,
      [field]: value,
    });
    setIsSelectedRowUpdated(true);
  };

  const onChangeImport = (value, field) => {
    setImportData({
      ...importData,
      [field]: value,
    });
  };

  const onChangeAttachment = (e) => {
    const file = e.target.files;
    if (file) {
      setImportData({
        ...importData,
        file: file && file[0] ? file[0] : null,
      });
    }
  };

  const fetchPaginatedTable = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    api.get(`${url}`)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
          currentUrl: url,
        });
      })
      .catch(() => {
        return Notification('error', 'An error occurred');
      });
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setCurrentView('table');
    setIsSelectedRowUpdated(false);
    handleClose();
  };

  const handleEditItem = (row) => {
    setSelectedRow(get(row, 'original', null));
    setCurrentView('form');
  };

  const handleSorting = (sortData) => {
    const column = sortData.id;
    setSelectedSort(column);
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
  };

  const handleShowConfirmationModal = (row, e) => {
    e.stopPropagation();
    setDeleteData(row);
    setShowConfirmationDialog(true);
  };

  const handleDeleteItem = () => {
    const row = deleteData;
    if (row && row.original && row.original.id && selectedCompanyTable && selectedCompanyTable.endpoint) {
      setTableData((prevState) => ({
        ...prevState,
        isLoading: true,
        data: [],
      }));
      setCurrentView('table');
      api.delete(`${selectedCompanyTable.endpoint}${row.original.id}/`)
        .then(() => {
          getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
          return Notification('success', 'Delete successful', 'Item was successfully deleted.');
        })
        .catch(() => {
          return Notification('error', 'An error occurred');
        })
        .finally(() => setShowConfirmationDialog(false));
    }
  };

  const handleBack = () => {
    setSelectedRow(null);
    setCurrentView('table');
    setIsSelectedRowUpdated(false);
    setImportData(null);
  };

  const handleImportButton = () => {
    setSelectedRow(null);
    setCurrentView('import');
    setIsSelectedRowUpdated(false);
  };

  const handleImportData = () => {
    setIsImporting(true);
    if (importData && importData.file) {
      const formData = new FormData();
      formData.append('file', importData.file);
      formData.append('delete_before_import', importData.delete_before_import || false);
      importFile(formData)
        .then(() => {
          setIsImporting(false);
          getTable(selectedCompanyTable.endpoint);
          handleBack();
        });
    }
  };

  const updateItem = () => {
    if (selectedCompanyTable && selectedCompanyTable.endpoint) {
      setTableData((prevState) => ({
        ...prevState,
        isLoading: true,
        data: [],
      }));
      setCurrentView('table');
      api.patch(`${selectedCompanyTable.endpoint}${selectedRow.id}/`, selectedRow)
        .then(() => {
          handleBack();
          getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
          return Notification('success', 'Save successful', 'Item was successfully updated.');
        })
        .catch(() => {
          handleBack();
          return Notification('error', 'An error occurred');
        });
    }
  };

  const renderTable = () => {
    return (
      <div style={{ paddingBottom: '30px' }}>
        <Button
          type="dark-blue"
          style={{ margin: '10px' }}
          onClick={handleImportButton}
        >
          {t('settings.custom_data.import_data')}
        </Button>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('settings.custom_data.material')}</span>,
              accessor: 'material',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.article_number')}</span>,
              accessor: 'article_number',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.supplier_code')}</span>,
              accessor: 'supplier_code',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.kg_m')}</span>,
              accessor: 'kg_m',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.alloy')}</span>,
              accessor: 'alloy',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.finishing')}</span>,
              accessor: 'finishing',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.quantity_per_unit')}</span>,
              accessor: 'quantity_per_unit',
              Cell: (row) => (get(row, 'value', '-')),
              width: 80,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              Cell: (row) => <Button disabled={isReadOnly} className="remove-btn" onClick={(e) => handleEditItem(row, e)}><IconEdit width="12px" height="12px" /></Button>,
              width: 80,
            },
            {
              Header: () => '',
              Cell: (row) => <Button type="delete" disabled={isReadOnly} className="remove-btn" onClick={(e) => handleShowConfirmationModal(row, e)}><IconRemove width="12px" height="12px" /></Button>,
              width: 80,
            },
          ]}
          data={tableData.data || []}
          defaultPageSize={15}
          loading={tableData.isLoading}
          minRows={0}
          noDataText=""
          showPagination={false}
          selectedRow={null}
          defaultSorted={[{ id: 'material', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons
              previous={tableData.previous}
              next={tableData.next}
              fetchFunction={fetchPaginatedTable}
              count={tableData.count}
            />
          </span>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="default-form">
        <Button onClick={handleBack}>{t('settings.custom_data.back')}</Button>
        <div className="custom_data_modal_container">
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.material')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'material', '')} onChange={(e) => onChange(e.target.value, 'material')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.article_number')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'article_number', '')} onChange={(e) => onChange(e.target.value, 'article_number')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.supplier_code')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'supplier_code', false)} onChange={(e) => onChange(e.target.value, 'supplier_code')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.kg_m')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'kg_m', '')} onChange={(e) => onChange(e.target.value, 'kg_m')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.alloy')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'alloy', '')} onChange={(e) => onChange(e.target.value, 'alloy')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.finishing')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'finishing', '')} onChange={(e) => onChange(e.target.value, 'finishing')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.quantity_per_unit')}:</div>
            <div className="right_input">
              <input type="number" value={get(selectedRow, 'quantity_per_unit', '')} onChange={(e) => onChange(e.target.value, 'quantity_per_unit')} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderImport = () => {
    if (isImporting) {
      return <ContentLoader />;
    }
    return (
      <div className="default-form">
        <Button onClick={handleBack}>{t('settings.custom_data.back')}</Button>
        <table>
          <tbody>
            <tr>
              <td className="label">
                {t('settings.custom_data.file')}:
              </td>
              <td className="value">
                <input
                  style={{ border: 'none' }}
                  type="file"
                  onChange={onChangeAttachment}
                  multiple={false}
                />
              </td>
            </tr>

            <tr>
              <td className="label">
                {t('settings.custom_data.delete_all_data')}:
              </td>
              <td className="value">
                <input type="checkbox" checked={get(importData, 'delete_before_import', false)} onChange={(e) => onChangeImport(e.target.checked, 'delete_before_import')} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Modal
      size={modalSizes.full}
      handleClose={handleCloseModal}
      handleSave={currentView === 'form' ? updateItem : currentView === 'import' ? handleImportData : null}
      disableSave={isSelectedRowUpdated ? false : !((importData && importData.file))}
      isOpen={isOpen}
      title={get(selectedCompanyTable, 'name', '')}
    >
      {currentView === 'table' && renderTable()}
      {currentView === 'form' && renderForm()}
      {currentView === 'import' && renderImport()}
      <ConfirmationModal
        itemName={`${get(deleteData, 'original.material')} | ${get(deleteData, 'original.article_number')}`}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleDeleteItem}
        type="warning"
      />
    </Modal>
  );
};

KFKModal.propTypes = {
  selectedCompanyTable: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

export default withTranslation()(KFKModal);
