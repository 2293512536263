import React from 'react';
import Base from './Base';

export default (props) => (<Base viewBox="0 0 394 394" {...props}>
  <g>
    <path d="M10 259V131.764L107.87 184.713L282.139 80L387 131.764V259H10Z" fill="#777777" />
    <path d="M10 303V175.764L107.87 228.713L282.139 124L387 175.764V303H10Z" fill="#ADADAD" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10 378V61H7V378V381H10H387V378H10Z" fill="#777777" />
    <path d="M10 378V250.764L107.87 303.713L282.139 199L387 250.764V378H10Z" fill="#ADADAD" />
    <path d="M10 378V250.764L107.87 303.713L282.139 199L387 250.764V378H10Z" fill="#777777" fillOpacity="0.5" />
  </g>
</Base>);
