import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props}>
  <svg
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon points="50,5 95,35 77,95 23,95 5,35" strokeWidth="6" fill="none" />

    <text x="50" y="56" fontFamily="Arial, sans-serif" fontSize="48px" fontWeight="bold" textAnchor="middle" alignmentBaseline="middle">
      5s
    </text>
  </svg>

</Base>);
