"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
var React = require("react");
var stencil_service_1 = require("./services/stencil-service");
var toolbar_service_1 = require("./services/toolbar-service");
var inspector_service_1 = require("./services/inspector-service");
var halo_service_1 = require("./services/halo-service");
var keyboard_service_1 = require("./services/keyboard-service");
var rappid_service_1 = require("./services/rappid-service");
var Rappid = /** @class */ (function (_super) {
    __extends(Rappid, _super);
    function Rappid(props) {
        var _this = _super.call(this, props) || this;
        _this.startRappid = function () {
            var _a = _this.props, factoryFloorData = _a.factoryFloorData, setFactoryFloor = _a.setFactoryFloor;
            _this.rappid = new rappid_service_1["default"](document.getElementById('rappidapp'), new stencil_service_1.StencilService(), new toolbar_service_1.ToolbarService(), new inspector_service_1.InspectorService(), new halo_service_1.HaloService(), new keyboard_service_1.KeyboardService(), factoryFloorData, setFactoryFloor);
            _this.rappid.startRappid();
        };
        return _this;
    }
    Rappid.prototype.componentDidMount = function () {
        this.startRappid();
        this.props.hideTools();
    };
    Rappid.prototype.componentDidUpdate = function (prevProps) {
        var newName = this.props.factoryFloorData ? this.props.factoryFloorData['layout']['name'] : '';
        var oldName = prevProps['factoryFloorData'] ? prevProps['factoryFloorData']['layout']['name'] : '';
        if (oldName !== newName) {
            document.getElementById('rapppidapp-toolbar-container').innerHTML = '';
            document.getElementById('rapppidapp-stencil-container').innerHTML = '';
            document.getElementById('rapppidapp-paper-container').innerHTML = '';
            document.getElementById('rapppidapp-inspector-container').innerHTML = '';
            document.getElementById('rapppidapp-navigator-container').innerHTML = '';
            this.startRappid();
        }
    };
    Rappid.prototype.render = function () {
        var isStaff = this.props.isStaff;
        var toolsClass = isStaff ? '' : 'app-body--hidden';
        var paperStyle = isStaff ? {} : { 'left': 0, 'right': 0 };
        var appBodyStyle = {
            height: document.getElementsByClassName('industry-menu')[0]['offsetHeight'] + "px"
        };
        return (React.createElement("div", { id: "rappidapp", ref: "rappidapp", className: "joint-app joint-theme-modern" },
            React.createElement("div", { className: "app-header" },
                React.createElement("div", { id: "rapppidapp-app-title", className: "app-title" },
                    React.createElement("h1", null, "Rappid")),
                React.createElement("div", { id: "rapppidapp-toolbar-container", className: "toolbar-container " + toolsClass })),
            React.createElement("div", { className: "app-body", style: appBodyStyle },
                React.createElement("div", { id: "rapppidapp-stencil-container", className: "stencil-container " + toolsClass }),
                React.createElement("div", { id: "rapppidapp-paper-container", className: "paper-container", style: paperStyle }),
                React.createElement("div", { id: "rapppidapp-inspector-container", className: "inspector-container " + toolsClass }),
                React.createElement("div", { id: "rapppidapp-navigator-container", className: "navigator-container " + toolsClass }))));
    };
    return Rappid;
}(React.Component));
exports["default"] = Rappid;
