import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, TableButtons } from 'shared';
import { IconSearch, IconCloudDownload, IconPopup, IconPreviewDocument, IconEdit } from 'shared/Icons';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import Select from 'react-select';
import { selectStyles } from 'styles/modules/reactSelect';
import { withRouter } from 'react-router-dom';
import { redirectToHomePage, checkModules } from 'industry/helpers';
import 'react-table/react-table.css';
import '../styles.scss';
import { getProductTypes, getProductDepartments, editProductType, getPaginatedProductTypes } from '../actions';
import { getDmsDocumentTypes } from '../../IndustryLocationDMS/actions';
import PDFPreviewModal from './PDFPreviewModal';
import StepPreviewModal from './StepPreviewModal';
import BulkAssignModal from './BulkAssignModal';
import EditProductModal from './EditProductModal';

class Products extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    this.state = {
      filterBy: '',
      query: '',
      product_types: [],
      product_departments: [],
      previous: null,
      next: null,
      count: 0,
      currentUrl: null,
      isLoadingProductTypes: false,
      codeQuery: '',
      nameQuery: '',
      manufacturerIdQuery: '',
      categoryQuery: '',
      departmentFilter: {},
      bulkModalOpen: false,
      bulkDepartmentSelector: '',
      selectedProductIds: [],
      selectedProductsPool: [],
      dmsDocumentTypes: [],
      pdfPreviewModalState: false,
      pdfPreviewModalObject: {},
      stepPreviewModalState: false,
      stepPreviewModalObject: {},
      withoutTechnologyFilter: false,
      selectedProduct: false,
      selectedAscDesc: 'asc',
      selectedSort: 'code',
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;
    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Projects');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    this.fetchProductTypes();
    this.fetchProductDepartments();
    this.fetchDmsDocumentTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    const { bulkModalOpen, selectedProductsPool } = this.state;

    if (bulkModalOpen && prevState.bulkModalOpen !== bulkModalOpen) {
      const firstProduct = selectedProductsPool[0];

      if (firstProduct && firstProduct.department && firstProduct.department.name) {
        const firstDepartment = firstProduct.department.name;
        const departmentsCheckup = selectedProductsPool.every((product) => product.department && product.department.name === firstDepartment);
        if (departmentsCheckup) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            bulkDepartmentSelector: firstProduct.department.id,
          });
        }
      }
    }
  }

  bulkModalDepartmentSelectorFunction = (value) => {
    this.setState({
      bulkDepartmentSelector: value,
    });
  }

  fetchProductTypes = () => {
    const { companyId, projectId } = this.props;
    const { selectedAscDesc, selectedSort } = this.state;
    this.setState({
      isLoadingProductTypes: true,
    });
    let filters = '';
    const { codeQuery, nameQuery, manufacturerIdQuery, categoryQuery, departmentFilter, withoutTechnologyFilter } = this.state;
    if (codeQuery) {
      filters += `&code_contains=${codeQuery}`;
    }
    if (nameQuery) {
      filters += `&name_contains=${nameQuery}`;
    }
    if (manufacturerIdQuery) {
      filters += `&manuf_id_contains=${manufacturerIdQuery}`;
    }
    if (categoryQuery) {
      filters += `&category=${categoryQuery}`;
    }
    if (departmentFilter && Object.keys(departmentFilter).length > 0) {
      filters += `&${departmentFilter.filterKey}=${departmentFilter.value}`;
    }
    if (withoutTechnologyFilter) {
      filters += '&technology_isnull=true';
    }
    if (projectId) {
      filters += `&project=${projectId}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort}`;

    getProductTypes(companyId, filters)
      .then((re) => {
        this.setState({
          product_types: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingProductTypes: false,
        });
      })
      .catch((error) => console.log(error));
  }

  fetchPaginatedProductTypes = (url) => {
    this.setState({
      isLoadingProductTypes: true,
    });

    getPaginatedProductTypes(url)
      .then((re) => {
        this.setState({
          product_types: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingProductTypes: false,
          currentUrl: url,
        });
      });
  }

  fetchProductDepartments = () => {
    const { locationId } = this.props;
    getProductDepartments(locationId)
      .then((re) => {
        this.setState({
          product_departments: get(re, 'data.results') || [],
          isLoadingProductTypes: false,
        });
      })
      .catch((error) => console.log(error));
  }

  fetchDmsDocumentTypes = () => {
    this.setState({
      isLoadingProductTypes: true,
    });
    const { locationId } = this.props;
    getDmsDocumentTypes(locationId)
      .then((re) => {
        this.setState({
          dmsDocumentTypes: get(re, 'data.results') || [],
          isLoadingProductTypes: false,
        });
      });
  }

  handleQueryChange = (key, e) => {
    const value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleFilterChange = (key, e) => {
    const value = e;
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleQueryClear = (key) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: '',
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleStateQueryChange = () => {
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      this.fetchProductTypes();
    }, 600);
  }

  handleProductChange = (id, payload) => {
    const { companyId } = this.props;
    const { currentUrl } = this.state;

    return editProductType(companyId, id, payload)
      .then(() => {
        if (currentUrl) {
          this.fetchPaginatedProductTypes(currentUrl);
        } else {
          this.fetchProductTypes();
        }
      });
  };

  handleBulkAsignModalClose = () => {
    this.setState({
      bulkModalOpen: false,
      bulkDepartmentSelector: '',
      selectedProductIds: [],
      selectedProductsPool: [],
    }, () => this.fetchProductTypes());
  }

  handleEditItem = (row) => {
    this.setState({
      selectedProduct: get(row, 'original', null),
      isEditProductModalOpen: true,
    });
  }

  closeEditProductModal = () => {
    this.setState({
      selectedProduct: null,
      isEditProductModalOpen: false,
    });
  }

  selectProduct = (e, rowObject) => {
    const { selectedProductIds, selectedProductsPool } = this.state;
    let selectedIdsPool = selectedProductIds.slice();
    let selectedProducts = selectedProductsPool.slice();
    const productId = parseInt(rowObject.id, 10);
    if (e.target.checked === true) {
      if (selectedIdsPool.includes(productId) === false) {
        selectedIdsPool.push(productId);
        selectedProducts.push(rowObject);
      }
    } else {
      selectedIdsPool = selectedIdsPool.filter((x) => parseInt(x, 10) !== productId);
      selectedProducts = selectedProducts.filter((x) => parseInt(x.id, 10) !== productId);
    }
    this.setState({ selectedProductIds: selectedIdsPool, selectedProductsPool: selectedProducts });
  }

  multipleSelectProducts = (e, productsArray) => {
    const { selectedProductIds, selectedProductsPool } = this.state;
    let selectedIdsPool = selectedProductIds.slice();
    let selectedProducts = selectedProductsPool.slice();
    if (e.target.checked === true) {
      for (const productItem of productsArray) {
        selectedIdsPool.push(productItem.id);
        selectedProducts.push(productItem);
      }
    } else {
      for (const productItem of productsArray) {
        selectedIdsPool = selectedIdsPool.filter((x) => parseInt(x, 10) !== productItem.id);
        selectedProducts = selectedProducts.filter((x) => parseInt(x.id, 10) !== productItem.id);
      }
    }
    this.setState({ selectedProductIds: selectedIdsPool, selectedProductsPool: selectedProducts });
  }

  closePDFPreviewModal = () => {
    this.setState({
      pdfPreviewModalState: false,
      pdfPreviewModalObject: {},
    });
  }

  openStepPreviewModal = (rowInfo) => {
    this.setState({
      stepPreviewModalObject: rowInfo.original,
      stepPreviewModalState: true,
    });
  }

  closeStepPreviewModal = () => {
    this.setState({
      stepPreviewModalState: false,
    });
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchProductTypes();
    });
  }

  render() {
    const { t, history, companyId, locationId, isReadOnly } = this.props;
    const {
      product_types,
      isLoadingProductTypes,
      codeQuery,
      nameQuery,
      manufacturerIdQuery,
      categoryQuery,
      product_departments,
      departmentFilter,
      selectedProductIds,
      dmsDocumentTypes,
      bulkModalOpen,
      bulkDepartmentSelector,
      selectedProductsPool,
      pdfPreviewModalState,
      pdfPreviewModalObject,
      next,
      previous,
      stepPreviewModalState,
      stepPreviewModalObject,
      withoutTechnologyFilter,
      selectedProduct,
      isEditProductModalOpen,
      count,
    } = this.state;

    const filterOptions = [
      { key: 'codeQuery', value: codeQuery, placeholder: t('page_content.projects.products_tab.search_placeholder_code') },
      { key: 'nameQuery', value: nameQuery, placeholder: t('page_content.projects.products_tab.search_placeholder_name') },
      { key: 'manufacturerIdQuery', value: manufacturerIdQuery, placeholder: t('page_content.projects.products_tab.search_placeholder_manufacturerId') },
      { key: 'categoryQuery', value: categoryQuery, placeholder: t('page_content.projects.products_tab.search_placeholder_category') },
    ];

    const departmentFilters = [
      { filterKey: 'department_isnull', value: true, title: t('page_content.projects.products_tab.noDepartment_filter_placeholder') },
      ...product_departments.sort((a, b) => a.name.localeCompare(b.name)).map((department) => ({
        filterKey: 'department',
        value: department.id,
        title: department.name,
      })),
    ];

    const departmentColumns = [
      {
        Header: () => <span>{t('page_content.projects.products_tab.table_column_department')}</span>,
        Cell: (row) => {
          return (<select
            disabled={isReadOnly}
            style={{ width: '100%' }}
            value={row.original.department ? row.original.department.id : ''}
            onChange={(e) => {
              const payload = {
                id: row.original.id,
                department: e.target.value,
                company: row.original.company,
                code: row.original.code,
                technology: '',
              };
              this.handleProductChange(row.original.id, payload);
            }}
          >
            <option value="" disabled defaultValue>{t('page_content.projects.products_tab.department_placeholder')}</option>
            {product_departments.sort((a, b) => a.name.localeCompare(b.name)).map((department) => (
              <option
                key={department.id}
                value={department.id}
              >{department.name}</option>
            ))}
          </select>
          );
        },
        accessor: 'department',
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.table_column_technology')}</span>,
        Cell: (row) => {
          const handleTechnologyChange = (e) => {
            const payload = {
              id: row.original.id,
              technology: e.target.value,
              company: row.original.company,
              code: row.original.code,
            };
            this.handleProductChange(row.original.id, payload);
          };

          const selectedDepartment = product_departments.find((d) => d.id === (row.original.department ? row.original.department.id : -1));
          const departmentTechnologies = selectedDepartment ? selectedDepartment.technologies : [];

          return (
            <select
              style={{ width: '100%' }}
              value={row.original.technology ? row.original.technology.id : ''}
              disabled={((!row.original.department || departmentTechnologies.length === 0) && isReadOnly) || isReadOnly}
              onChange={handleTechnologyChange}
            >
              <option value="" defaultValue>
                {t('page_content.projects.products_tab.technology_placeholder')}
              </option>
              {departmentTechnologies.map((technology) => (
                <option key={technology.id} value={technology.id}>
                  {technology.name}
                </option>
              ))}
            </select>
          );
        },
        accessor: 'technology',
      },
    ];

    const infoColumnConfig = [
      {
        Header: () => (<span>
          <input
            type="checkbox"
            onChange={(e) => this.multipleSelectProducts(e, product_types)}
            checked={product_types.every((product) => selectedProductIds.includes(product.id))}
            disabled={false}
            style={{ margin: 'auto', display: 'block' }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </span>),
        sortable: false,
        width: 40,
        Cell: (row) => (
          <span>
            <input
              type="checkbox"
              value={row.original.id}
              onChange={(e) => this.selectProduct(e, row.original)}
              checked={(selectedProductIds && selectedProductIds.length > 0 && selectedProductIds.includes(row.original.id))}
              disabled={false}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </span>
        ),
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.table_column_code')}</span>,
        accessor: 'code',
        Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.description')}</span>,
        accessor: 'description',
        Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.article_number')}</span>,
        accessor: 'manuf_id',
        Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.material_type')}</span>,
        accessor: 'boms',
        sortable: false,
        Cell: (row) => (Array.isArray(row.value) && row.value.length > 0 ? row.value[0].metadata.material_type : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.finish_treatment')}</span>,
        accessor: 'boms',
        sortable: false,
        Cell: (row) => (Array.isArray(row.value) && row.value.length > 0 ? row.value[0].metadata.finish_treatment : '-'),
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.table_column_category')}</span>,
        accessor: 'category',
        Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
      },
    ];

    const dynamicColumns = dmsDocumentTypes.map((column) => ({
      Header: () => <span>{column.name}</span>,
      width: 160,
      sortable: false,
      Cell: (row) => {
        const documentList = row.original && row.original.documents_list;
        for (let i = 0; i < documentList.length; i++) {
          const documentListItem = Array.isArray(documentList) && documentList.length > 0 && documentList[i];
          const documentType = documentListItem.document_type && documentListItem.document_type.name;
          if (documentType === column.name) {
            const documentFileItem = documentListItem.files && documentListItem.files.length > 0 && documentListItem.files[0];
            const documentFileExtension = documentFileItem && documentFileItem.filename.substring(documentFileItem.filename.lastIndexOf('.') + 1);
            const documentUrl = documentFileItem && documentFileItem.file;
            const documentApproval = documentFileItem && documentFileItem.is_approved;
            const hasApproved = documentListItem.files.find((f) => f.is_approved);
            let revisionNeedsApproval = false;
            if (!documentApproval && hasApproved) {
              revisionNeedsApproval = true;
            }
            return (
              <div className="products_dynamic_column_container">
                <Tooltip
                  trigger={['hover']}
                  placement="top"
                  overlay={
                    <div>{revisionNeedsApproval ? t('page_content.projects.products_tab.dynamic_column_not_approved') : t('page_content.projects.products_tab.dynamic_column_approval')}</div>
                  }
                >
                  {documentApproval ? <div className="products_dynamic_column_item"><img src={checkMarkTrue} width="25px" height="25px" alt="" /></div> : <div className="products_dynamic_column_item"><img className={revisionNeedsApproval && 'orange_icon'} src={checkMarkFalse} width="25px" height="25px" alt="" /></div>}
                </Tooltip>
                {documentFileExtension !== 'pdf' ? <div
                  onClick={() => {
                    if (documentUrl) {
                      const link = document.createElement('a');
                      link.href = documentUrl;
                      link.target = '_blank';
                      link.style.display = 'none';
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }
                  }}
                ><IconCloudDownload height="25px" width="25px" color={documentApproval ? 'green' : revisionNeedsApproval ? 'orange' : 'red'} />
                </div> : <button
                  style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
                  disabled={!documentUrl}
                  onClick={() => {
                    this.setState({
                      pdfPreviewModalObject: {
                        name: documentListItem.name,
                        url: documentUrl,
                      },
                      pdfPreviewModalState: true,
                    });
                  }}
                ><IconPreviewDocument height="25px" width="25px" color={documentApproval ? 'green' : revisionNeedsApproval ? 'orange' : 'red'} /></button>}
                <div
                  onClick={() => { history.push(`/${companyId}/industry/location/${locationId}/dms/${documentListItem && documentListItem.id}`); }}
                ><IconPopup width="25px" height="25px" color={documentApproval ? 'green' : revisionNeedsApproval ? 'orange' : 'red'} /></div>
              </div>
            );
          }
        }
        return '-';
      },
    }));

    let maxSteps = 0;
    product_types?.forEach((p) => {
      if (p?.technology?.steps?.steps?.length > maxSteps) {
        maxSteps = p?.technology?.steps?.steps?.length;
      }
    });

    const stepColumnsConfig = Array(maxSteps).fill().map((_, index) => ({
      Header: () => <span>{`${t('page_content.projects.products_tab.table_column_step')} ${index + 1}`}</span>,
      accessor: 'technology',
      sortable: false,
      width: 160,
      Cell: (row) => {
        const tech_steps = row.original.tech_steps && Object.keys(row.original.tech_steps).length > 0 && row.original.tech_steps[index];
        return (
          <div className="products_tab_step_column_wrapper">
            <div className="info_column">
              <div>{row.value && row.value.steps && Object.keys(row.value.steps).length > 0 && row.value.steps.steps[index] && row.value.steps.steps[index] !== 0 ? row.value.steps.steps[index].name : '-'}</div>
              <div><span>{tech_steps && tech_steps.man_hours && tech_steps.man_hours !== 0 ? <span>{tech_steps.man_hours} <span className="spanDivider">per</span></span> : '-'}</span>{' / '}<span>{tech_steps && tech_steps.man_hours && tech_steps.machine_hours !== 0 ? <span>{tech_steps.machine_hours} <span className="spanDivider">min</span></span> : '-'}</span></div>
            </div>
            {index === 0 && !isReadOnly &&
              <div className="edit_column">
                <div
                  onClick={() => {
                    this.openStepPreviewModal(row);
                  }}
                >
                  <IconEdit
                    height="14px"
                    width="14px"
                  />
                </div>
              </div>}
          </div>
        );
      },
    }));

    const actionsColumns = [
      {
        Header: () => 'ERP ID',
        accessor: 'manuf_id',
        sortable: false,
        Cell: (row) => (
          <div className="products_tab_step_column_wrapper">
            <div className="info_column">
              <div><span>{row.value || '-'}</span></div>
            </div>
            {!isReadOnly &&
              <div className="edit_column">
                <div
                  onClick={() => {
                    this.handleEditItem(row);
                  }}
                >
                  <IconEdit
                    height="14px"
                    width="14px"
                  />
                </div>
              </div>}
          </div>
        ),
        width: 80,
      },
    ];

    return (
      <div className="products_container">
        <div className="products_toolbar">
          {filterOptions.map((filter) => (
            <div key={filter.key} className="products_toolbar_input_container">
              <input onChange={(e) => this.handleQueryChange(filter.key, e)} placeholder={filter.placeholder} value={filter.value} />
              {
                filter.value &&
                <button
                  onClick={() => {
                    this.handleQueryClear(filter.key);
                  }}
                >&times;</button>
              }
              <div className="products_toolbar_icon_container">
                <IconSearch
                  color="#555"
                  height="26px"
                  width="26px"
                />
              </div>
            </div>
          ))}
          <Select
            options={departmentFilters}
            getOptionLabel={(department) => `${department.title}`}
            getOptionValue={(department) => department}
            isSearchable
            placeholder={t('page_content.projects.products_tab.department_filter_placeholder')}
            onChange={(value) => this.handleFilterChange('departmentFilter', value)}
            value={(departmentFilters.find((department) => department.value === departmentFilter.value)) || ''}
            styles={selectStyles}
          />
          <div className="products_toolbar_checkbox">
            <input type="checkbox" onChange={(e) => this.handleFilterChange('withoutTechnologyFilter', e.target.checked)} checked={withoutTechnologyFilter} />
            {t('page_content.projects.products_tab.noTechnologyFilter')}
          </div>
          <Button
            onClick={() => {
              this.setState({
                codeQuery: '',
                nameQuery: '',
                manufacturerIdQuery: '',
                categoryQuery: '',
                departmentFilter: '',
                selectedProductIds: [],
                selectedProductsPool: [],
              }, () => {
                this.handleStateQueryChange();
              });
            }}
          >{t('page_content.projects.products_tab.clear_button')}</Button>
          <Button
            disabled={(selectedProductIds.length === 0 && isReadOnly) || isReadOnly}
            onClick={() => {
              this.setState({
                bulkModalOpen: true,
              });
            }}
          >{t('page_content.projects.products_tab.bulk_assign_button')}</Button>
        </div>
        <div>
          <Table
            style={{ userSelect: 'text', overflow: 'auto' }}
            columns={[...infoColumnConfig, ...dynamicColumns, ...actionsColumns, ...departmentColumns, ...stepColumnsConfig]}
            data={product_types.sort((a, b) => a.code.localeCompare(b.code))}
            minRows={0}
            defaultPageSize={30}
            noDataText=" "
            showPagination={false}
            loading={isLoadingProductTypes}
            defaultSorted={[{ id: 'code', desc: false }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
            stickyHeader={!(product_types.sort((a, b) => a.code.localeCompare(b.code)).length <= 1)}
          />
          <div style={{ float: 'right' }}>
            <TableButtons next={next} previous={previous} fetchFunction={this.fetchPaginatedProductTypes} count={count} />
          </div>
        </div>

        {
          bulkModalOpen &&
          <BulkAssignModal
            modalStatus={bulkModalOpen}
            handleBulkAsignModalClose={this.handleBulkAsignModalClose}
            productDepartments={product_departments}
            bulkDepartmentSelector={bulkDepartmentSelector}
            bulkModalDepartmentSelectorFunction={this.bulkModalDepartmentSelectorFunction}
            selectedProductIds={selectedProductIds}
            selectedProductsPool={selectedProductsPool}
            companyId={companyId}
            locationId={locationId}
          />
        }

        <PDFPreviewModal
          modalStatus={pdfPreviewModalState}
          pdfObjectInfo={pdfPreviewModalObject}
          closeModal={this.closePDFPreviewModal}
        />

        {
          stepPreviewModalState &&
          <StepPreviewModal
            modalStatus={stepPreviewModalState}
            closeModal={this.closeStepPreviewModal}
            rowData={stepPreviewModalObject}
            handleProductChange={this.handleProductChange}
          />
        }

        {
          selectedProduct &&
          <EditProductModal
            isEditProductModalOpen={isEditProductModalOpen}
            selectedProduct={selectedProduct}
            closeEditProductModal={this.closeEditProductModal}
            handleProductChange={this.handleProductChange}
          />
        }
      </div>
    );
  }
}
Products.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isReadOnly: PropTypes.bool,
  history: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
};

export default (withRouter(withTranslation()(Products)));
