import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Notification } from 'shared';
import { saveSortingAndFiltering } from 'industry/actions';
import { checkActiveFilters } from 'industry/helpers';
import { getCurrentUserSuccess } from 'user/actions';
import {
  IconLayoutFull,
  IconLayoutNoSidebar,
  IconLayoutFullscreen,
} from 'shared/Icons';
import './styles.scss';

class Fullscreen extends Component {
  constructor(props) {
    super(props);

    const sortingAndFiltering = props && props.currentUser && props.currentUser.data ? props.currentUser.data : {};

    let fullscreen_click_counter = 0;

    this.defaultFilters = {
      fullscreen_click_counter,
    };

    let filtersActive = false;

    if (sortingAndFiltering.fullscreen) {
      const sAndF = sortingAndFiltering.fullscreen;
      fullscreen_click_counter = sAndF.fullscreen_click_counter ? sAndF.fullscreen_click_counter : 0;
      filtersActive = checkActiveFilters(this.defaultFilters, sAndF);
    }
    this.state = {
      fullscreen_click_counter,
      filtersActive,
    };
  }

  componentDidMount() {
    const {
      fullscreen_click_counter,
    } = this.state;

    if (fullscreen_click_counter === 1) {
      this.handleNoSidebar();
    } else if (fullscreen_click_counter === 2) {
      this.handleFullscreen();
    } else return '';
  }

  checkActiveSortingAndFiltering = (userFilters) => {
    const filtersActive = checkActiveFilters(this.defaultFilters, userFilters);
    this.setState({ filtersActive });
  }

  saveSortingAndFiltering = () => {
    const {
      fullscreen_click_counter,
    } = this.state;

    const {
      currentUser,
      setCurrentUser,
    } = this.props;

    if (currentUser) {
      currentUser.data.fullscreen = {
        fullscreen_click_counter,
      };
      setCurrentUser(currentUser);
      saveSortingAndFiltering({ data: currentUser.data });
      this.checkActiveSortingAndFiltering(currentUser.data.fullscreen);
    }
  }

  handleNoSidebar = () => {
    const { fullscreen_click_counter } = this.state;
    const sidebar = document.getElementsByClassName('industry-menu');
    const fullscreen = document.getElementsByClassName('fullscreen');

    if (fullscreen_click_counter === 1) {
      if (fullscreen && fullscreen.length) {
        for (let i = 0; i < fullscreen.length; i++) {
          fullscreen[i].style.marginLeft = '0px';
          fullscreen[i].style.width = '100%';
        }
      }
      if (sidebar && sidebar.length) {
        sidebar[0].style.display = 'none';
      }
    }

    this.saveSortingAndFiltering();
  }

  handleFullscreen = () => {
    const { fullscreen_click_counter } = this.state;
    const sidebar = document.getElementsByClassName('industry-menu');
    const subHeader = document.getElementsByClassName('SubHeader');
    const fullscreen = document.getElementsByClassName('fullscreen');
    const page = document.getElementsByClassName('page');
    const location = window.location.href.replaceAll('/', ' ');

    if (location.includes('settings')) {
      if (subHeader && subHeader.length) {
        subHeader[0].style.display = 'none';
      }
      if (fullscreen && fullscreen.length) {
        fullscreen[0].style.height = '100%';
      }
    }

    if (fullscreen_click_counter === 0) {
      this.setState({
        fullscreen_click_counter: 1,
      }, () => this.handleNoSidebar());
    }

    if (fullscreen_click_counter === 1) {
      this.setState({
        fullscreen_click_counter: 2,
      }, () => this.handleFullscreen());
    }

    if (fullscreen_click_counter === 2) {
      if (fullscreen && fullscreen.length) {
        for (let i = 0; i < fullscreen.length; i++) {
          fullscreen[i].style.marginLeft = '0px';
          fullscreen[i].style.width = '100%';
          fullscreen[i].style.height = '100%';
        }
      }
      if (sidebar && sidebar.length) {
        sidebar[0].style.display = 'none';
      }
      if (subHeader && subHeader.length) {
        subHeader[0].style.display = 'none';
      }
      if (page && page.length) {
        page[0].style.height = '100%';
      }
      Notification('success', 'Fullscreen mode', 'Press ESC to exit');
      this.setState({
        fullscreen_click_counter: 3,
      });
    }

    if (fullscreen_click_counter === 3) {
      this.exitFullscreen();
    }

    this.saveSortingAndFiltering();
  }

  exitFullscreen = () => {
    const sidebar = document.getElementsByClassName('industry-menu');
    const fullscreen = document.getElementsByClassName('fullscreen');
    const topHeader = document.getElementsByClassName('top-header');
    const subHeader = document.getElementsByClassName('SubHeader');
    const page = document.getElementsByClassName('page');
    const location = window.location.href.replaceAll('/', ' ');

    if (location.includes('settings')) {
      topHeader[0].style.display = 'flex';
    } else {
      sidebar[0].style.display = 'block';
      topHeader[0].style.display = 'flex';
      subHeader[0].style.display = 'flex';
      page[0].style.height = 'calc(100% - 70px)';
      if (fullscreen && fullscreen.length) {
        for (let i = 0; i < fullscreen.length; i++) {
          fullscreen[i].style.height = 'calc(100vh - 60px - 70px)';
        }
      }
      this.setState({
        fullscreen_click_counter: 0,
      }, () => this.saveSortingAndFiltering());
    }
  }

  render() {
    const { fullscreen_click_counter } = this.state;

    if (fullscreen_click_counter > 0) {
      document.addEventListener('keyup', (e) => {
        if (e.key === 'Escape') {
          this.exitFullscreen();
        }
      });
    }

    return (
      <div onClick={this.handleFullscreen} className="arrowsOut">
        <div className="link">
          {fullscreen_click_counter === 0 &&
            <IconLayoutFull fill="#ffffff" height="22px" width="22px" />}
          {fullscreen_click_counter === 1 &&
            <IconLayoutNoSidebar fill="#ffffff" height="22px" width="22px" />}
          {fullscreen_click_counter === 2 &&
            <IconLayoutFullscreen fill="#ffffff" height="22px" width="22px" />}
          {fullscreen_click_counter === 3 &&
            <IconLayoutFullscreen fill="#ffffff" height="22px" width="22px" />}
        </div>
      </div>
    );
  }
}

Fullscreen.propTypes = {
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  setCurrentUser: getCurrentUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fullscreen);
