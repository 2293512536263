import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import { IconWarning } from 'shared/Icons';
import Tooltip from 'rc-tooltip';
import { colors } from 'shared/colors';
import { getLastChange } from './actions';
import './styles.scss';

class RealtimeWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state_value: null,
      last_update: null,
      mdc_status: null,
      datapointToDisplay: null,
      multipleDatapoints: false,
      isLoading: true,
      order_label: null,
    };
  }

  componentDidMount() {
    this.getDisplayState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.getDisplayState();
    }
  }

  getTimeFromNowText = (timeValue) => {
    return timeValue ? moment(timeValue).fromNow() : '';
  }

  getFormattedDate = (date) => {
    return date ? moment(date).format('DD.MM.YYYY. HH:mm') : '-';
  }

  getMDCStatus = (mdc_status) => {
    switch (mdc_status) {
      case 1:
        return 'INACTIVE';
      case 2:
        return 'ADJUSTMENT';
      case 3:
        return 'WORKING';
      case 4:
        return 'STOP';
      default:
        return null;
    }
  }

  getDisplayState = () => {
    const { data, widgetData, settings } = this.props;

    const multipleDatapoints = !!(widgetData && widgetData.length > 1);
    const datapointToDisplay = !multipleDatapoints && widgetData && widgetData[0] && widgetData[0].metric && widgetData[0].metric.code ? widgetData[0].metric.code : null;

    this.setState({
      datapointToDisplay,
      multipleDatapoints,
    });

    if (widgetData && widgetData.length) {
      for (let i = 0; i < widgetData.length; i++) {
        const id = widgetData && widgetData[i].id ? widgetData[i].id : null;
        const metricId = widgetData && widgetData[i].metric && widgetData[i].metric.id ? widgetData[i].metric.id : null;
        const metricCode = widgetData && widgetData[i].metric && widgetData[i].metric.code ? widgetData[i].metric.code : null;
        const data_arr = data && id ? data[id] : [];
        const first_value = id && data_arr ? data_arr[0] : null;
        const last_value = id && data_arr ? data_arr[data_arr.length - 1] : null;
        const inactivity_after = settings && settings.inactivity ? settings.inactivity : 20;

        if (metricCode === 'status_realtime') {
          getLastChange(metricId, -1, moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
            .then((res) => {
              this.setState({
                mdc_status: res.data,
                order_label: res.data && res.data.annotation && res.data.annotation.oznaka_radnog_naloga ? res.data.annotation.oznaka_radnog_naloga : null,
                isLoading: false,
              });
            });
        } else if (metricCode === 'cnc_runtime') {
          if (first_value && last_value) {
            if (moment().diff(last_value.time, 'minutes') > inactivity_after) {
              this.setState({
                state_value: 'IDLE',
                last_update: last_value.time,
                isLoading: false,
              });
            } else if (first_value.value < last_value.value) {
              this.setState({
                state_value: 'WORKING',
                last_update: last_value.time,
                isLoading: false,
              });
            } else if (first_value.value >= last_value.value) {
              this.setState({
                state_value: 'INACTIVE',
                last_update: last_value.time,
                isLoading: false,
              });
            }
          } else if (first_value && !last_value) {
            getLastChange(metricId, first_value.value, moment(first_value.time).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
              .then((res) => {
                this.setState({
                  state_value: 'INACTIVE',
                  last_update: res.data.ts || null,
                  isLoading: false,
                });
              });
          } else if (!first_value && !last_value) {
            getLastChange(metricId, 1, moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
              .then((res) => {
                this.setState({
                  state_value: 'IDLE',
                  last_update: res.data.ts || null,
                  isLoading: false,
                });
              });
          }
        }
      }
    }
  }

  render() {
    const { state_value, last_update, isLoading, mdc_status, datapointToDisplay, multipleDatapoints, order_label } = this.state;
    const { height, width, t, i18n, isLoadingData } = this.props;

    moment.locale(i18n.language);

    const multipleDatapointsClassname = multipleDatapoints ? 'MULTIPLE' : '';

    const status_bubble_width = `calc(${width}px - 50%)`;
    const details_label_small = width < 210 ? 'details-label-small' : '';
    const details_value_small = width < 210 ? 'details-value-small' : '';

    const MDC_status = this.getMDCStatus(mdc_status && mdc_status.value ? Number(mdc_status.value) : null);

    if (isLoading || (isLoadingData && multipleDatapoints) || (isLoadingData && datapointToDisplay === 'cnc_runtime')) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: `${height}px` }}>
          <div className="loader" style={{ border: '1px solid #aaa', borderLeftColor: 'transparent' }} />
        </div>);
    }

    return (
      <div className="realtime-widget" style={{ height: `${height}px` }}>

        <span className="warning-info">
          <Tooltip
            id="tooltip-realtime"
            trigger={['hover']}
            placement="bottom"
            overlay={<span style={{ fontSize: '11px' }}>{t('page_content.dashboards.realtime_widget.warning_tooltip')}</span>}
            overlayClassName="realtime__tooltip"
          >
            <span aria-describedby="tooltip-realtime">
              <IconWarning color={colors.red} height="16px" width="16px" />
            </span>
          </Tooltip>
        </span>

        <div className={`realtime-widget-data realtime-widget-data__${multipleDatapointsClassname}`}>
          {
            (datapointToDisplay === 'status_realtime' || multipleDatapoints) &&
            <div className={`mdc-container mdc-container__${multipleDatapointsClassname}`}>

              <p className="state-label">MDC status</p>

              {order_label && order_label !== 'None' && <p className="order-label">{order_label}</p>}

              <div className={`mdc-status mdc-status__${MDC_status} mdc-status__${multipleDatapointsClassname}`} style={{ width: status_bubble_width }}>
                <p>{mdc_status ? MDC_status : '-'}</p>
              </div>

              <div className="details_wrapper">
                <p className={`details-label ${details_label_small}`}>{t('page_content.dashboards.realtime_widget.last_update')} {`(${this.getTimeFromNowText(mdc_status && mdc_status.ts ? mdc_status.ts : null)})`}</p>
                <p className={`details-value ${details_value_small}`}>{this.getFormattedDate(mdc_status && mdc_status.ts ? mdc_status.ts : null)}</p>
              </div>
            </div>
          }

          {multipleDatapoints && <hr className="solid" />}

          {
            (datapointToDisplay === 'cnc_runtime' || multipleDatapoints) &&
            <div className={`state-container state-container__${multipleDatapointsClassname}`}>

              <p className="state-label">{t('page_content.dashboards.realtime_widget.state')}</p>

              <div className={`state-value state-value__${state_value} state-value__${multipleDatapointsClassname}`} style={{ width: status_bubble_width }}>
                <p>{state_value || '-'}</p>
              </div>

              <div className="details_wrapper">
                <p className={`details-label ${details_label_small}`}>{t('page_content.dashboards.realtime_widget.last_update')} {`(${this.getTimeFromNowText(last_update)})`}</p>
                <p className={`details-value ${details_value_small}`}>{this.getFormattedDate(last_update)}</p>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

RealtimeWidget.propTypes = {
  data: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  widgetData: PropTypes.array,
  settings: PropTypes.object,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object,
  isLoadingData: PropTypes.bool.isRequired,
};

export default withTranslation()(RealtimeWidget);
