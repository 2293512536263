import React from 'react';
import api from 'helpers/api';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Notification, ContentLoader } from 'shared';
import './style.scss';

class OperatorView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      operatorViewAssets: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchOperatorViewAssets();
  }

  fetchOperatorViewAssets = () => {
    const {
      match: {
        params: { viewId },
      },
    } = this.props;
    api
      .get(`/api/v1/dashboards/operator-view-assets/?operator_view=${viewId}`)
      .then((res) => {
        if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
          throw new Error('');
        }
        this.setState({
          isLoading: false,
          operatorViewAssets: ((res.data.results) ? res.data.results : []),
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
        Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
      });
  }

  render() {
    const {
      match: {
        params: { locationId, companyId, viewId },
      },
    } = this.props;
    const {
      operatorViewAssets,
      isLoading,
    } = this.state;

    if (isLoading) {
      return <ContentLoader />;
    }

    return (
      <div className="operatorView">
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: 'Operator view assets',
              accessor: 'asset.name',
            },
            {
              Header: 'Operator interface',
              Cell: (row) => <Link to={`/${companyId}/industry/location/${locationId}/operatorView/${viewId}/simple-operator-interface/${row.original.asset.id}`}>Open</Link>,
            },
          ]}
          data={(operatorViewAssets) || []}
          defaultPageSize={100}
          minRows={0}
          noDataText=" "
          showPagination={false}
          manual={false}
        />
      </div>
    );
  }
}

OperatorView.propTypes = {
  match: PropTypes.object.isRequired,
};
export default OperatorView;
