import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { checkModules, checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import OEEBookings from './components/OEEBookings';
import CycleTimes from './components/CycleTimes';
import OEEGoals from './components/OEEGoals';
import './styles.scss';

class IndustryLocationOEE extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { locationId, companyId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'OEE');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          this.setState({
            isReadOnly: true,
          });
        }
      });
  }

  render() {
    const { isReadOnly } = this.state;
    const { companyId, locationId, t, i18n } = this.props;

    return (
      <div className="warehouse_list fullscreen industry-tab">
        <Tabs>
          <TabList>
            <Tab>{t('page_content.oee.oee_bookings')}</Tab>
            <Tab>{t('page_content.oee.cycle_times')}</Tab>
            <Tab>{t('page_content.oee.oee_goals')}</Tab>
          </TabList>

          <TabPanel>
            <OEEBookings
              companyId={companyId}
              locationId={locationId}
              isReadOnly={isReadOnly}
              t={t}
            />
          </TabPanel>

          <TabPanel>
            <CycleTimes
              companyId={companyId}
              locationId={locationId}
              isReadOnly={isReadOnly}
              t={t}
            />
          </TabPanel>

          <TabPanel>
            <OEEGoals
              companyId={companyId}
              locationId={locationId}
              isReadOnly={isReadOnly}
              t={t}
              i18n={i18n}
            />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

IndustryLocationOEE.propTypes = {
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  i18n: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(IndustryLocationOEE)));
