import React, { useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import LimitedTextarea from 'shared/LimitedTextarea/index';
import { getLocale } from 'shared/DatePicker/constants';
import { Table, Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';

import { addAssignedEquipment, editAssignedEquipment } from '../../../actions';

const AssignEquipmentModal = ({
  t,
  isOpen,
  workers,
  companyId,
  currentUser,
  fetchWorkers,
  equipmentStatus,
  isLoadingWorkers,
  handleCloseModal,
  acceptedByWorkers,
  initialValues = {},
  equipmentsInfo = [],
  closeModalAndRefetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [equipmentsInfoCopy, setEquipmentsInfoCopy] = useState(equipmentsInfo?.map((equipment) => ({ ...equipment, quantity: 1 })));

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const onWorkerChange = (value, key) => {
    if (value?.length > 3) fetchWorkers(key, value);
  };

  const handleSave = async () => {
    setIsLoading(true);

    if (formValues?.id) {
      const payload = {
        ...formValues,
        company: companyId,
        user: currentUser?.id,
        status: formValues?.status,
        worker: formValues?.worker?.id,
        take_clerk: currentUser?.worker_id,
        return_clerk: formValues?.return_clerk?.id || null,
        name: formValues?.equipment_item?.name,
        equipment_item: formValues?.equipment_item?.id,
      };
      await editAssignedEquipment(companyId, formValues?.id, payload);
    } else {
      const payloads = formValues?.equipment_item?.map((equipment) => ({
        ...formValues,
        equipment_item: equipment.id,
        name: equipment.name,
        quantity: equipmentsInfoCopy?.find((a) => a.id === equipment.id)?.quantity,
        company: companyId,
        user: currentUser?.id,
        status: formValues?.status,
        worker: formValues?.worker?.id,
        take_clerk: currentUser?.worker_id,
        return_clerk: formValues?.return_clerk?.id || null,
      }));

      await Promise.all(payloads.map(async (payload) => {
        await addAssignedEquipment(companyId, payload);
      }));
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  const onChangeQuantity = (value, row) => {
    const updatedEquipmentInfo = equipmentsInfoCopy?.map((equipment) => {
      if (equipment.id === row.original.id) {
        return {
          ...equipment,
          quantity: value,
        };
      }
      return equipment;
    });
    setEquipmentsInfoCopy(updatedEquipmentInfo);
  };

  return (
    <Modal
      isOpen={isOpen}
      handleSave={handleSave}
      size={modalSizes.large}
      handleClose={handleCloseModal}
      title={formValues?.id ? t('page_content.human_resources.assigned_equipment.modal_title_placeholder_edit') : t('page_content.human_resources.assigned_equipment.modal_title_placeholder_add')}
      disableSave={!formValues?.equipment_item || !formValues?.date_of_take || !formValues?.status || !formValues?.worker || (formValues?.id && !formValues?.quantity)}
    >
      {
        isLoading ?
        <ContentLoader /> :
          <div className="assigned_equipment_modal_container">
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_worker')}:*</div>
              <div className="right_select">
                <Select
                  options={formValues?.worker?.id ? [...workers, formValues?.worker] : workers}
                  getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isLoading={isLoadingWorkers}
                  menuPosition="fixed"
                  placeholder={t('page_content.human_resources.assigned_equipment.modal_select_placeholder_type_3_char')}
                  onChange={(value) => onChange(value, 'worker')}
                  onInputChange={(value) => onWorkerChange(value, 'worker')}
                  value={formValues?.worker?.id ? [...workers, formValues?.worker]?.find((a) => a.id === formValues?.worker?.id) : workers?.find((a) => a.id === formValues?.worker?.id) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_name')}:*</div>
              <div className="right_select">
                <Select
                  options={equipmentsInfo}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isMulti={!formValues?.id}
                  menuPosition="fixed"
                  placeholder={t('page_content.human_resources.assigned_equipment.modal_select_placeholder_equipment_info')}
                  onChange={(value) => onChange(value, 'equipment_item')}
                  value={formValues?.equipment_item || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
            {
              !formValues?.id && formValues?.equipment_item?.length ?
                <Table
                  style={{ userSelect: 'text' }}
                  columns={[
                    {
                      Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_name')}</span>,
                      accessor: 'name',
                      sortable: false,
                      style: {
                        cursor: 'default',
                      },
                    },
                    {
                      Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_quantity')}*</span>,
                      Cell: (row) => (
                        <input
                          type="text"
                          value={equipmentsInfoCopy?.find((equipment) => equipment.id === row.original.id)?.quantity || ''}
                          onChange={(e) => {
                            const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                            if (!Number.isNaN(sanitizedValue)) {
                              onChangeQuantity(sanitizedValue, row);
                            }
                          }}
                        />
                      ),
                      sortable: false,
                      style: {
                        cursor: 'default',
                      },
                    },
                  ]}
                  minRows={0}
                  noDataText=""
                  data={formValues?.equipment_item || []}
                />
                : null
            }
            {
              formValues?.id &&
                <div className="modal_row">
                  <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_quantity')}:*</div>
                  <div className="right_input">
                    <input
                      type="text"
                      value={get(formValues, 'quantity', '')}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                        if (!Number.isNaN(sanitizedValue)) {
                          onChange(sanitizedValue, 'quantity');
                        }
                      }}
                    />
                  </div>
                </div>
            }
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_taken')}:*</div>
              <div className="right_input">
                <DatePicker
                  className="date_picker"
                  placeholderText={t('page_content.human_resources.assigned_equipment.modal_datepicker_placeholder_date_of_take')}
                  dateFormat="dd.MM.yyyy"
                  selected={formValues?.date_of_take ? moment(formValues.date_of_take, 'YYYY-MM-DD').toDate() : null}
                  onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'), 'date_of_take')}
                  locale={getLocale(t)}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_returned')}:</div>
              <div className="right_input">
                <DatePicker
                  className="date_picker"
                  placeholderText={t('page_content.human_resources.assigned_equipment.modal_datepicker_placeholder_date_of_return')}
                  dateFormat="dd.MM.yyyy"
                  selected={formValues?.date_of_return ? moment(formValues.date_of_return, 'YYYY-MM-DD').toDate() : null}
                  onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'), 'date_of_return')}
                  locale={getLocale(t)}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_note')}:</div>
              <div className="right_limited_text_area">
                <LimitedTextarea autoResize value={formValues?.note || ''} onChange={(value) => onChange(value, 'note')} />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_status')}:*</div>
              <div className="right_select">
                <Select
                  options={equipmentStatus}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  isSearchable
                  menuPosition="fixed"
                  placeholder={t('page_content.human_resources.assigned_equipment.modal_select_placeholder_status')}
                  onChange={(value) => onChange(value.value, 'status')}
                  value={(equipmentStatus?.find((a) => a.value === formValues?.status)) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment.column_return_clerk')}:</div>
              <div className="right_select">
                <Select
                  options={formValues?.return_clerk?.id ? [...acceptedByWorkers, formValues?.return_clerk] : acceptedByWorkers}
                  getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isClearable
                  isLoading={isLoadingWorkers}
                  menuPosition="fixed"
                  placeholder={t('page_content.human_resources.assigned_equipment.modal_select_placeholder_type_3_char')}
                  onChange={(value) => onChange(value, 'return_clerk')}
                  onInputChange={(value) => onWorkerChange(value, 'return_clerk')}
                  value={formValues?.return_clerk?.id ? [...acceptedByWorkers, formValues?.return_clerk]?.find((a) => a.id === formValues?.return_clerk?.id) : acceptedByWorkers?.find((a) => a.id === formValues?.return_clerk?.id) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
          </div>
      }
    </Modal>
  );
};

AssignEquipmentModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  isLoadingWorkers: PropTypes.bool,
  closeModalAndRefetch: PropTypes.func,
  companyId: PropTypes.number.isRequired,
  fetchWorkers: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  equipmentsInfo: PropTypes.array.isRequired,
  equipmentStatus: PropTypes.array.isRequired,
  workers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  acceptedByWorkers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(AssignEquipmentModal));
