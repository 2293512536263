import React, { useState } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';
import '../styles.scss';

import { addSubscriber, editSubscriber, getUsers } from '../../../../../actions';

const EventSubscriberModal = ({
  t,
  isOpen,
  locations,
  companyId,
  currentUser,
  isUserProfile,
  handleCloseModal,
  initialValues = {},
  closeModalAndRefetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const [users, setUsers] = useState({
    data: [],
    isLoading: false,
  });

  const fetchUsers = async (value) => {
    setUsers((prevState) => ({ ...prevState, isLoading: true }));

    await getUsers(companyId, `&first_or_last_name=${value}`)
      .then((res) => {
        setUsers({
          data: get(res, 'data.results', []),
          isLoading: false,
        });
      })
      .catch(() => setUsers((prevState) => ({ ...prevState, isLoading: false })));
  };

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);

    const payload = {
      ...formValues,
      location: formValues?.location?.id || null,
      company: companyId,
      user: isUserProfile ? currentUser?.id : formValues?.user?.user_id || null,
    };

    if (formValues?.id) {
      await editSubscriber(companyId, formValues?.id, payload);
    } else {
      await addSubscriber(companyId, payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  return (
    <Modal
      isOpen={isOpen}
      handleSave={handleSave}
      size={modalSizes.medium}
      handleClose={handleCloseModal}
      title={formValues?.id ? t('settings.events_and_subscribers.subscribers.modal_title_edit_subscriber') : t('settings.events_and_subscribers.subscribers.modal_title_add_subscriber')}
      disableSave={!formValues?.name}
    >
      {
        isLoading ?
        <ContentLoader /> :
          <div className="subscribers_modal_container">

            <div className="modal_row">
              <div className="left_text">{t('settings.events_and_subscribers.subscribers.name_label')}: *</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'name', '')} onChange={(e) => onChange(e.target.value, 'name')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('settings.events_and_subscribers.subscribers.email_label')}:</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'email', '')} onChange={(e) => onChange(e.target.value, 'email')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('settings.events_and_subscribers.subscribers.mobile_number_label')}:</div>
              <div className="right_input">
                <input
                  type="text"
                  placeholder="3859x0000000"
                  value={get(formValues, 'mobile_phone', '')}
                  onChange={(e) => onChange(e.target.value, 'mobile_phone')}
                  onInput={(e) => {
                    const value = e.target.value;
                    e.target.value = value.replace(/[^0-9]/g, '');
                  }}
                />
              </div>
            </div>

            <div className="modal_row">
                <div className="left_text">{t('settings.events_and_subscribers.subscribers.location_label')}:</div>
                <div className="right_select">
                    <Select
                      options={locations}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isClearable
                      menuPosition="fixed"
                      placeholder={t('settings.events_and_subscribers.event_subscribers.select_location_placeholder')}
                      onChange={(value) => onChange(value, 'location')}
                      value={get(formValues, 'location', '')}
                      styles={selectModalStyles}
                    />
                </div>
            </div>

            {
              !isUserProfile && (
                <div className="modal_row">
                  <div className="left_text">{t('settings.events_and_subscribers.event_subscribers.table_header_user')}:</div>
                  <div className="right_select">
                    <Select
                      options={users.data}
                      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                      getOptionValue={(option) => option.id}
                      isLoading={users.isLoading}
                      menuPosition="fixed"
                      placeholder={t('settings.events_and_subscribers.subscribers.search_user_hint')}
                      onInputChange={(value) => {
                        if (typeof value === 'string' && value.length >= 3) fetchUsers(value);
                      }}
                      onChange={(value) => {
                        if (value && typeof value === 'object') onChange(value, 'user');
                      }}
                      value={get(formValues, 'user', null)}
                      styles={selectModalStyles}
                    />
                  </div>
              </div>
              )
            }

          </div>
      }
    </Modal>
  );
};

EventSubscriberModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  locations: PropTypes.array.isRequired,
  companyId: PropTypes.number.isRequired,
  isUserProfile: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  currentUser: get(state, 'currentUser', null),
});

export default connect(mapStateToProps, null)(withTranslation()(EventSubscriberModal));
