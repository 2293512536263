import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';

class CycleTimesModal extends Component {
  constructor(props) {
    super();
    let average = '';
    let median = '';
    let percentile = '';

    if (props.selectedCycleTime) {
      const { selectedCycleTime } = props;
      average = selectedCycleTime.avg_per_time || '';
      median = selectedCycleTime.median_per_time || '';
      percentile = selectedCycleTime.perc99_per_time || '';
    }
    this.state = {
      average,
      median,
      percentile,
    };
  }

  onFieldChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  handleSaveOrEditCycleTime = () => {
    const { average, median, percentile } = this.state;
    const { handleSaveOrEditCycleTime, selectedCycleTime } = this.props;

    const data = {
      avg_per_time: average || 0,
      median_per_time: median || 0,
      perc99_per_time: percentile || 0,
    };

    const id = selectedCycleTime && selectedCycleTime.id ? selectedCycleTime.id : null;
    handleSaveOrEditCycleTime(id, data);
  }

  render() {
    const { average, median, percentile } = this.state;
    const {
      handleCloseModal,
      isModalOpen,
      isLoadingModal,
      t,
    } = this.props;

    return (
      <React.Fragment>
        <Modal
          size={modalSizes.medium}
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          handleSave={this.handleSaveOrEditCycleTime}
        >
          {
            isLoadingModal ?
              <ContentLoader /> :
              <div className="default-form">
                <table>
                  <tbody>
                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_average')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('average', e.target.value)}
                          value={average ? parseInt(average, 10) : ''}
                          type="number"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_median')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('median', e.target.value)}
                          value={median ? parseInt(median, 10) : ''}
                          type="number"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_percentile')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('percentile', e.target.value)}
                          value={percentile ? parseInt(percentile, 10) : ''}
                          type="number"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          }

        </Modal>
      </React.Fragment>
    );
  }
}

CycleTimesModal.propTypes = {
  t: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isLoadingModal: PropTypes.bool.isRequired,
  selectedCycleTime: PropTypes.object.isRequired,
  handleSaveOrEditCycleTime: PropTypes.func.isRequired,
};

export default CycleTimesModal;
