import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IconError, IconInfo, IconWarning } from 'shared/Icons';
import { defaultDateTimeFormat } from 'shared/constants';
import { setDateRange } from 'app/actions';
import './styles.scss';
import { getLatestEvents, updateEvent } from './actions';
import IndustryWidget from '../IndustryWidget';
import EventForm from '../../../IndustryLocationEventList/components/EventForm';
import DashboardList from '../../../../components/DashboardList/index';

const getIcon = (level) => {
  switch (level) {
    case 'warning':
      return (<IconWarning
        color="#ff9f43"
        height="18px"
        width="18px"
      />);
    case 'error':
      return (<IconError
        color="#ee5253"
        height="20px"
        width="20px"
      />);
    default:
      return (<IconInfo
        color="#2e86de"
        height="16px"
        width="16px"
      />);
  }
};

class EventListWidget extends Component {
  constructor() {
    super();
    this.state = {
      eventsLoading: true,
      isEventFormOpen: false,
      selectedEvent: null,
      isLoadingEventUpdate: false,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;
    getLatestEvents(companyId, locationId)
      .then((res) => {
        this.setState({
          events: get(res, 'data.results'),
          eventsLoading: false,
        });
      });
  }

  openEventForm = (ev) => {
    this.setState({
      isEventFormOpen: true,
      selectedEvent: ev,
    });
  }

  closeEventForm = () => {
    this.setState({
      isEventFormOpen: false,
      selectedEvent: null,
    });
  }

  handleUpdateEvent = (event) => {
    this.setState({
      isLoadingEventUpdate: true,
    });
    const { companyId, locationId } = this.props;
    updateEvent(companyId, locationId, event)
      .then(() => {
        this.setState({
          isLoadingEventUpdate: false,
          isEventFormOpen: false,
          selectedEvent: null,
        });
      })
      .catch((err) => console.log('Error updating event comment', err));
  }

  render() {
    const { events, eventsLoading, selectedEvent, isEventFormOpen, isLoadingEventUpdate } = this.state;

    const { t, locationId, companyId, locationConfig } = this.props;

    if (eventsLoading) {
      return (
        <IndustryWidget
          title={t('page_content.home.latest_events.title')}

        >
          <div className="EventListWidget">
            Loading events...
          </div>
        </IndustryWidget>
      );
    }

    if (events && events !== undefined) {
      events.sort((a, b) => ((a.ts < b.ts) ? 1 : -1));
    }

    return (
      <div className="content-wrapper">
        {locationConfig && locationConfig.homepage_links && (
          <IndustryWidget
            title={t('page_content.home.dashboards.link_board_title')}
          >
            <DashboardList locationId={locationId} companyId={companyId} location={locationConfig} />
            <div className="dashboard-widget dashboard-widget--dashboards" />
          </IndustryWidget>
        )}
        <IndustryWidget
          title={t('page_content.home.latest_events.title')}

        >
          {
            isEventFormOpen && selectedEvent ?
              <EventForm
                event={selectedEvent}
                isOpen
                closeModal={this.closeEventForm}
                saveEvent={this.handleUpdateEvent}
                isLoadingState={isLoadingEventUpdate}
              /> : ''
          }
          <div className="EventListWidget">
            {
              (events && events.length > 0) ?
                (
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <td colSpan="2">
                            {t('page_content.home.latest_events.table_column_description')}
                          </td>
                          <td>
                            {t('page_content.home.latest_events.table_column_asset')}
                          </td>
                          <td>
                            {t('page_content.home.latest_events.table_column_time')}
                          </td>
                          <td>
                            {t('page_content.home.latest_events.table_column_status')}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          ((events !== undefined) ? events : []).map((ev) => (
                            <tr
                              key={`event-${ev.id}`}
                              // onClick={() => this.goToDashboard(ev.config)}
                              onClick={() => this.openEventForm(ev)}
                            >
                              <td>
                                {
                                  getIcon(ev.log_level)
                                }
                              </td>
                              <td>
                                {(ev.description && ev.description !== null) ? ev.description : (ev.title && ev.title !== null) ? ev.title : ''}
                              </td>
                              <td>
                                {ev.asset.name}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {moment(ev.ts).format(defaultDateTimeFormat)}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {ev.status}
                              </td>
                            </tr>
                          ))
                        }

                      </tbody>
                    </table>
                  </div>
                ) : (
                  'No events'
                )
            }
          </div>
        </IndustryWidget>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locationConfig: (state.app.locationConfig !== null && Object.prototype.hasOwnProperty.call(state.app.locationConfig, 'config'))
      ? state.app.locationConfig.config
      : {},
  };
};

EventListWidget.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  locationConfig: PropTypes.object,
};

const mapDispatchToProps = {
  dispatchDateRange: setDateRange,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter((withTranslation()(EventListWidget))));
