// import * as actionTypes from './actionTypes';
import initialState from 'shared/dashboard/initialState';
import * as dashboardReducer from 'shared/dashboard/reducer';
import * as actionTypes from './actionTypes';

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.showAddWidget:
      return Object.assign(state, {
        isAddWidgetVisible: true,
      });

    case actionTypes.addWidget:
      return dashboardReducer.addWidget(state, action.payload);

    case actionTypes.updateWidget:
      return dashboardReducer.updateWidget(state, action.payload);

    case actionTypes.setLayouts:
      return dashboardReducer.setLayouts(state, action.payload);

    case actionTypes.fetchDashboardSuccess:
      return dashboardReducer.setDashboard(state, action.payload);

    case actionTypes.fetchDashboard:
      return dashboardReducer.fetchDashboard(state);

    case actionTypes.fetchDashboardError:
      return dashboardReducer.fetchDashboardError(state, action.payload);

    case actionTypes.removeWidget:
      return dashboardReducer.removeWidget(state, action.payload);

    case actionTypes.updateManualRefresh:
      return Object.assign(state, { manualRefresh: action.payload });

    case actionTypes.patchDashboard:
      return dashboardReducer.patchDashboard(state, action.payload);

    default:
      return state;
  }
}
