export const showAddWidget = 'INDUSTRY_LOCATION_DASHBOARD_CONTAINER_SHOW_ADD_WIDGET';
export const removeWidget = 'INDUSTRY_LOCATION_DASHBOARD_CONTAINER_REMOVE_WIDGET';
export const setLayouts = 'INDUSTRY_LOCATION_DASHBOARD_CONTAINER_SET_LAYOUTS';
export const addWidget = 'INDUSTRY_LOCATION_DASHBOARD_CONTAINER_ADD_WIDGET';
export const selectWidgetForEdit = 'INDUSTRY_LOCATION_DASHBOARD_CONTAINER_SELECT_WIDGET_FOR_EDIT';
export const updateWidget = 'INDUSTRY_LOCATION_DASHBOARD_CONTAINER_UPDATE_WIDGET';

export const fetchDashboard = 'INDUSTRY_LOCATION_DASHBOARD_CONTAINER_FETCH_DASHBOARD';
export const fetchDashboardSuccess = 'INDUSTRY_LOCATION_DASHBOARD_CONTAINER_FETCH_DASHBOARD_SUCCESS';
export const fetchDashboardError = 'INDUSTRY_LOCATION_DASHBOARD_CONTAINER_FETCH_DASHBOARD_ERROR';

export const updateManualRefresh = 'INDUSTRY_LOCATION_DASHBOARD_CONTAINER_UPDATE_MANUAL_REFRESH';

export const patchDashboard = 'INDUSTRY_LOCATION_DASHBOARD_CONTAINER_PATCH_DASHBOARD';
