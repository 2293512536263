// eslint-disable-next-line
export const colors = {
  red: '#ee5253',
  green: '#10ac84',
  blue: '#2e86de',
  orange: '#ff9f43',
  yellow: '#feca57',
};

export const pickerStyles = {
  control: (provided) => ({
    ...provided,
    border: '2px solid #eee',
    borderRadius: '10px',
    height: '38px',
    color: '#8d8d8d',
    fontWeight: '400',
    fontSize: '14px',
    boxShadow: null,
    '&:hover': {
      borderColor: null,
      border: '2px solid #eee',
      borderRadius: '10px',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#77C7B8' : '#8d8d8d',
    backgroundColor: 'white',
    fontWeight: '400',
    fontSize: '14px',
    borderBottom: '2px solid #eee',
    ':active': {
      backgroundColor: 'white',
      color: '#77C7B8',
    },
    '&:hover': {
      backgroundColor: 'white',
      color: '#77C7B8',
    },
  }),
  menu: (provided) => ({
    ...provided,
    border: '2px solid #eee',
    borderRadius: '10px',
    zIndex: '99',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#8d8d8d',
    fontWeight: '400',
    fontSize: '14px',
  }),
};
