import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Button } from 'shared';
import { modalSizes } from 'shared/constants';
import './styles.scss';

ReactModal.setAppElement('#root');
const Modal = ({
  children,
  error,
  handleClose,
  handleSave,
  isOpen,
  popupClass,
  contentClass,
  titleClass,
  size,
  title,
  closeText,
  saveText,
  handleDelete,
  handleDeletePosition,
  disableSave,
  disableDelete,
  contentScrollable,
  enableSaveOnEnter,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (enableSaveOnEnter) {
      const handleKeyDown = (event) => {
        if (event.key === 'Enter' && handleSave && !disableSave) {
          event.preventDefault();
          handleSave(event);
        }
      };

      if (isOpen) {
        document.addEventListener('keydown', handleKeyDown);
      } else {
        document.removeEventListener('keydown', handleKeyDown);
      }

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isOpen, handleSave, disableSave]);

  return (
    <div className={popupClass}>
      <ReactModal
        className={`Modal Modal-${size}`}
        overlayClassName="Modal-overlay"
        // contentLabel={title}
        style={{ content: { padding: '0' } }}
        isOpen={isOpen}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick={false}
      >
        <header className="Modal-header">
          <h4 className={titleClass}>
            {title}
          </h4>
          {
            handleDelete && handleDeletePosition === 'header' &&
            <Button
              onClick={handleDelete}
              type="danger"
              disabled={disableDelete}
            >
              {t('shared.modal.delete_button')}
            </Button>
          }
        </header>
        <main className={`${contentScrollable && 'modal_scrollable'} ${contentClass}`}>{children}</main>
        <footer className="Modal-footer">
          <div className="Modal-footer-grid">
            <div className="buttons">
          {handleSave && (
            <Button onClick={handleSave} title={saveText || t('shared.modal.save_button')} type="success" disabled={disableSave || false}>
              {saveText || t('shared.modal.save_button')}
            </Button>
          )}
          {
            handleDelete && handleDeletePosition === 'footer' &&
            <Button
              onClick={handleDelete}
              type="delete"
              disabled={disableDelete}
            >
              {t('shared.modal.delete_button')}
            </Button>
          }
            <Button onClick={handleClose} title={closeText || t('shared.modal.close_button')}>{closeText || t('shared.modal.close_button')}</Button>
          </div>
            {error && <span className="error">{error}</span>}
          </div>
        </footer>
      </ReactModal>
    </div>
  );
};

Modal.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func,
  handleDelete: PropTypes.func,
  isOpen: PropTypes.bool,
  size: PropTypes.oneOf([
    modalSizes.small,
    modalSizes.medium,
    modalSizes.large,
    modalSizes.full,
    modalSizes.fullPlus,
    modalSizes.max,
  ]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.any,
  popupClass: PropTypes.string,
  contentClass: PropTypes.string,
  titleClass: PropTypes.string,
  closeText: PropTypes.string,
  saveText: PropTypes.string,
  disableSave: PropTypes.bool,
  handleDeletePosition: PropTypes.string,
  disableDelete: PropTypes.bool,
  contentScrollable: PropTypes.bool,
  enableSaveOnEnter: PropTypes.bool,
};

Modal.defaultProps = {
  error: '',
  handleSave: null,
  isOpen: false,
  size: modalSizes.medium,
  title: '',
  handleDeletePosition: 'header',
  disableDelete: false,
  contentScrollable: true,
  enableSaveOnEnter: true,
};

export default Modal;
