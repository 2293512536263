import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import { IconAsset } from 'shared/Icons';
import './styles.scss';

class AssetInfoDrawer extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      interfaces: [],
    };
  }

  componentDidMount() {
    this.fetchInterfaces(this.props.asset);
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentDidUpdate(prevProps) {
    const { asset } = this.props;
    if (asset.id !== prevProps.asset.id && asset) {
      this.fetchInterfaces(asset.id);
    }
  }

  handleClickOutside = (e) => {
    if (this.node.contains(e.target) || e.target.classList.contains('rt-td')) {
      return;
    }
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    this.props.handleClose(false);
  }

  handleClose = () => {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    this.props.handleClose(false);
  }

  fetchInterfaces = (asset) => {
    const { companyId } = this.props;
    this.setState({ loading: true });
    api.get(`/api/v1/industry/interfaces/?asset=${asset && typeof asset.id !== 'undefined' ? asset.id : asset}&fat=1&company=${companyId}`)
      .then((res) => {
        this.setState({
          interfaces: res.data.results || [],
          loading: false,
        });
      });
  }

  openModal = () => {
    const { asset, openModal } = this.props;
    if (openModal) {
      openModal(asset.id);
    }

    this.handleClose();
  }

  render() {
    const { asset, isReadOnly } = this.props;
    const { loading, interfaces } = this.state;
    return (
      <div className="info-drawer" ref={(container) => { this.node = container; }}>
        <header>
          <button className="close" onClick={this.handleClose}>
            &times;
          </button>
          <h2>
            <span style={{ marginRight: 5 }}>
              <IconAsset
                color="#444"
                height="20px"
                width="20px"
              />
            </span>
            {asset.name}
          </h2>
          <p>
            {asset.external_id}
          </p>
        </header>
        <main>
          {
            loading && <div className="loading">Loading details...</div>
          }
          {
            !loading &&
            <Fragment>
              {
                interfaces.length === 0 &&
                <p className="no-details">
                  No interfaces defined
                </p>
              }
              {
                interfaces.length > 0 &&
                <div>
                  <h4>Interfaces</h4>
                  <table>
                    <thead>
                      <tr>
                        <td>
                          Label
                        </td>
                        <td>
                          Type
                        </td>
                        <td>
                          Number of metrics
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        interfaces.map((iface) => (
                          <tr key={iface.id}>
                            <td>{iface.label || '-'}</td>
                            <td>{iface.interface_type || '-'}</td>
                            <td>{(iface.metrics || []).length}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              }
              <button
                onClick={this.openModal}
                disabled={isReadOnly}
              >
                Edit asset
              </button>
            </Fragment>
          }
        </main>
      </div>
    );
  }
}

AssetInfoDrawer.propTypes = {
  openModal: PropTypes.func,
  handleClose: PropTypes.func,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  asset: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default AssetInfoDrawer;
