import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import { Table, Modal } from 'shared';
import { numberSeparatorFormat } from 'industry/helpers';
import { modalSizes } from 'shared/constants';
import { getLocale } from 'shared/DatePicker/constants';
import { getProductionLines } from './../../../actions';

class ManualEntryComponentsModal extends Component {
  constructor() {
    super();
    this.state = {
      bookings_entry: [],
      input_values: {},
      textbox_values: {},
      custom_values: {
        production_line: 0,
        booked_at: moment().toDate(),
      },
      lines: [],
    };
  }

  componentDidMount() {
    this.getProductionLines();
  }

  componentDidUpdate() {
    if (this.props.isManualEntryComponentsModalOpen === false) {
      this.state.custom_values = {
        production_line: 0,
        booked_at: moment().toDate(),
      };
    }
  }

  onSaveManualEntryComponentsModal = () => {
    const { bookings_entry, custom_values } = this.state;
    const { onSaveManualEntryComponentsModal } = this.props;

    onSaveManualEntryComponentsModal(bookings_entry, custom_values);
    this.setState({
      bookings_entry: [],
      input_values: {},
      textbox_values: {},
      custom_values: {},
    });
  }

  getProductionLines = () => {
    const { company_short_code, locationId } = this.props;

    getProductionLines(company_short_code, locationId)
      .then((res) => {
        this.setState({
          lines: res.data.results ? res.data.results : [],
        });
      });
  }

  getCellValue = (row) => {
    const { i18n } = this.props;

    const unitCode = row.original.unit_code || null;
    const cellValue = row.value || null;

    if (unitCode === 'PCE') {
      return numberSeparatorFormat(i18n.language, cellValue, 0, 0, true);
    } if (unitCode === 'KKO') {
      return numberSeparatorFormat(i18n.language, (cellValue * 1000), 0, 0, true);
    }
    return numberSeparatorFormat(i18n.language, row.value, 2, 2, true);
  }

  getRemainingValue = (row) => {
    const { i18n } = this.props;
    const { input_values } = this.state;

    const unitCode = row.original.unit_code || null;
    const quantity = row.original.quantity || 0;
    const quantity_booked = row.original.quantity_done || 0;
    const quantity_booked_new = input_values[row.original.id] || 0;

    const remaining = quantity - quantity_booked - quantity_booked_new;

    if (unitCode === 'PCE') {
      return numberSeparatorFormat(i18n.language, remaining, 0, 0, true);
    } if (unitCode === 'KKO') {
      return numberSeparatorFormat(i18n.language, (remaining * 1000), 0, 0, true);
    }
    return numberSeparatorFormat(i18n.language, remaining, 2, 2, true);
  }

  closeManualEntryComponentsModal = () => {
    const { closeManualEntryComponentsModal } = this.props;

    closeManualEntryComponentsModal();
    this.setState({
      bookings_entry: [],
      input_values: {},
      textbox_values: {},
    });
  }

  addOrUpdateBooking = (array, booking) => {
    // eslint-disable-next-line eqeqeq
    const i = array.findIndex((_booking) => _booking.order_item_id == booking.order_item_id);

    if (!booking.quantity_booked && !booking.batch) {
      array.splice(i, 1);
    } else if (i > -1) {
      array[i] = booking;
    } else {
      array.push(booking);
    }

    return array;
  }

  handleInput = (e, row) => {
    const { bookings_entry, input_values, textbox_values } = this.state;

    const value = parseFloat(e.target.value.replace(',', '.')) || null;
    const rowId = row.original.id || null;
    const unitCode = row.original.unit_code || null;

    const booking = {
      order_item_id: rowId,
      unit_code: row.original.unit_code,
      material_code: row.original.material_code,
      item_id: row.original.item_id,
      // quantity_booked: unitCode === 'KKO' ? Number(value / 1000) : Number(value),
      // batch: textbox_values && textbox_values[row.original.id] ? textbox_values[row.original.id] : null
    };

    if (value !== null) {
      booking.quantity_booked = unitCode === 'KKO' ? Number(value / 1000) : Number(value);
    }

    if (textbox_values && textbox_values[row.original.id] && textbox_values[row.original.id] !== null) {
      booking.batch = textbox_values[row.original.id];
    }

    const newBookingEntry = this.addOrUpdateBooking(bookings_entry, booking);

    this.setState({
      bookings_entry: newBookingEntry,
      input_values: { ...input_values, [row.original.id]: value },
    });
  }

  handleTextboxInput = (e, row) => {
    const { bookings_entry, input_values, textbox_values } = this.state;

    const value = e.target.value || null;
    const rowId = row.original.id || null;

    // console.log(row.original, row);
    const booking = {
      order_item_id: rowId,
      material_code: row.original.material_code,
      unit_code: row.original.unit_code,
      item_id: row.original.item_id,
      // quantity_booked: input_values && input_values[row.original.id] ? input_values[row.original.id] : null,
      // batch: value,
    };

    if (value !== null) {
      booking.batch = value;
    }

    if (input_values && input_values[row.original.id] && input_values[row.original.id] !== null) {
      booking.quantity_booked = Number(input_values[row.original.id]);
    }

    const newBookingEntry = this.addOrUpdateBooking(bookings_entry, booking);

    this.setState({
      bookings_entry: newBookingEntry,
      textbox_values: { ...textbox_values, [row.original.id]: value },
    });
  }

  updateCustomValue = (field, value) => {
    const {
      custom_values,
    } = this.state;

    this.setState({
      custom_values: { ...custom_values, [field]: value },
    });
  }

  renderInputCell = (row) => {
    // this render function is to prevent rerenders on react-table
    // because with every "onChange" we lose focus from input
    const { input_values } = this.state;

    const cellValue = input_values[row.original.id];

    return (
    <input
      type="text"
      inputMode="numeric"
      defaultValue={cellValue}
      onKeyDown={(evt) => {
        if (evt.key === '.') {
          evt.preventDefault();
        }
        if (evt.key === ',' && evt.target.value.includes(',')) {
          evt.preventDefault();
        }
      }}
      onChange={(e) => {
        // Allow only numbers and a comma
        const value = e.target.value;
        if (/^\d*[,]?\d*$/.test(value)) {
          this.handleInput(e, row);
        }
      }}
    />
    );
  };

  renderTextboxCell = (row) => {
    // this render function is to prevent rerenders on react-table
    // because with every "onChange" we lose focus from input
    const { textbox_values } = this.state;

    const cellValue = textbox_values[row.original.id];

    return (
      <input
        type="text"
        defaultValue={cellValue}
        onChange={(e) => this.handleTextboxInput(e, row)}
      />
    );
  };

  render() {
    const {
      t,
      isManualEntryComponentsModalOpen,
      groups,
      order_groups_hidden_columns,
      company_short_code,
    } = this.props;

    const {
      lines,
      custom_values,
    } = this.state;

    const sortGroupItems = ((a, b) => {
      if (a.item_num > b.item_num) {
        return 1;
      } if (a.item_num < b.item_num) {
        return -1;
      }
      return 0;
    });

    const isValidForm = (Object.prototype.hasOwnProperty.call(custom_values, 'production_line') && parseInt(custom_values.production_line, 10) > 0);

    return (
      <Modal
        isOpen={isManualEntryComponentsModalOpen}
        handleSave={isValidForm ? this.onSaveManualEntryComponentsModal : () => { }}
        error={isValidForm ? null : `* ${t('page_content.orders.order_details.bookings_tab.manual_entry_error_msg')}`}
        handleClose={this.closeManualEntryComponentsModal}
        title={t('page_content.orders.order_details.bookings_tab.manual_entry_components')}
        size={modalSizes.full}
        contentClass="manual-entry-components-modal"
      >
        <div style={{ padding: '0 20px 20px 20px' }}>
          <div style={{ margin: '10px 0' }}>
            <label>{t('page_content.orders.order_details.bookings_tab.table_column_time')}</label>
            <ReactDatePicker
              dateFormat="dd.MM.yyyy - HH:mm"
              selected={(Object.prototype.hasOwnProperty.call(custom_values, 'booked_at')) ? moment(custom_values.booked_at).toDate() : moment().toDate()}
              onChange={(e) => { this.updateCustomValue('booked_at', e); }}
              showTimeSelect
              timeFormat="HH:mm"
              disabledKeyboardNavigation
              locale={getLocale(t)}
              timeCaption={t('date_picker_locale.time_translation')}
            />
          </div>
          <div style={{ margin: '10px 0' }}>
            <label>* {t('page_content.orders.order_details.bookings_tab.table_column_line')}</label>
            <select onChange={(e) => { this.updateCustomValue('production_line', parseInt(e.target.value, 10)); }}>
              <option value="0">-</option>
              {lines.map((line) => <option key={line.id} value={line.id}>{line.name}</option>)}
            </select>
          </div>
          {
            groups.map((group, i) => (<div key={i} className="order-details-table">
              <h4 style={{ margin: '10px 0 0 0' }}>
                {group.name} {group && group.material_code ? `- ${group.material_code}` : ''}
              </h4>
              <Table
                style={{ userSelect: 'text', fontSize: '13px' }}
                columns={[
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_item_name')}</span>,
                    accessor: 'name',
                    show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_item_name', { lng: 'en' })),
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_quantity')}</span>,
                    accessor: 'quantity',
                    Cell: (row) => <span>{this.getCellValue(row)}</span>,
                    show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_quantity', { lng: 'en' })),
                    maxWidth: 100,
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_quantity_tracked')}</span>,
                    accessor: 'quantity_tracked',
                    Cell: (row) => <span>{this.getCellValue(row)}</span>,
                    show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_quantity_tracked', { lng: 'en' })) && !(group && group.material_code && group.material_code === 'Resursi'),
                    maxWidth: 100,
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_quantity_final')}</span>,
                    accessor: 'quantity_final',
                    Cell: (row) => <span>{this.getCellValue(row)}</span>,
                    show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_quantity_final', { lng: 'en' })) && !(group && group.material_code && (group.material_code === 'BOM' || group.material_code === 'Resursi')),
                    maxWidth: 100,
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_quantity_done')}</span>,
                    accessor: 'quantity_done',
                    Cell: (row) => <span>{this.getCellValue(row)}</span>,
                    show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_quantity_done', { lng: 'en' })),
                    maxWidth: 100,
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_unit')}</span>,
                    accessor: 'unit_code',
                    Cell: (row) => <span>{row.value && row.value === 'KKO' ? 'PCE' : row.value}</span>,
                    show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_unit', { lng: 'en' })),
                    maxWidth: 80,
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_status')}</span>,
                    accessor: 'status',
                    Cell: (row) => <span className={`order-details-table__status ${row.value}`}>{row.value ? t([`page_content.orders.statuses.${row.value}`]) : ''}</span>,
                    show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_status', { lng: 'en' })) && company_short_code !== 'podravka',
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_batch')}</span>,
                    Cell: this.renderTextboxCell,
                    show: group && group.material_code === 'BOM',
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_quantity_booked')}</span>,
                    Cell: this.renderInputCell,
                    maxWidth: 120,
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_remaining')}</span>,
                    Cell: (row) => <span>{this.getRemainingValue(row)}</span>,
                    maxWidth: 120,
                  },
                ]}
                data={group.items.sort(sortGroupItems)}
                minRows={group.items.length}
                maxRows={group.items.length}
                defaultPageSize={group.items.length}
                showPagination={false}
                sortable="true"
                manual={false}
                defaultSorted={[{ id: 'name', desc: false }]}
              />
            </div>))
          }
        </div>
      </Modal>
    );
  }
}

ManualEntryComponentsModal.propTypes = {
  i18n: PropTypes.object,
  t: PropTypes.func.isRequired,
  closeManualEntryComponentsModal: PropTypes.func.isRequired,
  isManualEntryComponentsModalOpen: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  order_groups_hidden_columns: PropTypes.array,
  company_short_code: PropTypes.string.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSaveManualEntryComponentsModal: PropTypes.func.isRequired,
};

export default withTranslation()(ManualEntryComponentsModal);
