import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Modal } from 'shared';
import { modalSizes } from 'shared/constants';
import '../../styles.scss';

import Absences from './components/Absences';
import Vacations from './components/Vacations';

const AbsencesAndVacations = ({
  t,
  workerId,
  companyId,
  isReadOnly,
  currentUser,
  handleClose,
  showAbsenceModal,
  workerAbsencesAndVacations,
  updateAbsenceAndVacationDays,
}) => {
  return (
    <Modal
      size={modalSizes.full}
      isOpen={showAbsenceModal}
      handleClose={handleClose}
    >
      <div className="worker_details_absence">
        <Tabs>

          <TabList>
            <Tab>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absences')}</Tab>
            <Tab>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.vacation_days')}</Tab>
          </TabList>

          <TabPanel>
            <Absences
              workerId={workerId}
              companyId={companyId}
              isReadOnly={isReadOnly}
              currentUser={currentUser}
              workerAbsencesAndVacations={workerAbsencesAndVacations}
              updateAbsenceAndVacationDays={updateAbsenceAndVacationDays}
            />
          </TabPanel>

          <TabPanel>
            <Vacations
              workerId={workerId}
              companyId={companyId}
              isReadOnly={isReadOnly}
              currentUser={currentUser}
              updateAbsenceAndVacationDays={updateAbsenceAndVacationDays}
            />
          </TabPanel>
        </Tabs>
      </div>
      </Modal>
  );
};

AbsencesAndVacations.propTypes = {
  isReadOnly: PropTypes.bool,
  handleClose: PropTypes.func,
  t: PropTypes.func.isRequired,
  showAbsenceModal: PropTypes.bool,
  workerId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  workerAbsencesAndVacations: PropTypes.object.isRequired,
  updateAbsenceAndVacationDays: PropTypes.func.isRequired,
};

export default (withTranslation()(AbsencesAndVacations));
