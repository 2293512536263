import React from 'react';
import moment from 'moment';
import { ordersCreatedAndUpdated } from 'shared/constants';

// eslint-disable-next-line react/prop-types
export default ({ time }) => {
  const date = moment(time, 'x');
  return (
    <div
      style={{
        position: 'fixed',
        left: 100,
        bottom: 50,
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: 10,

        fontSize: 20,
        borderRadius: 5,
        zIndex: 85,
      }}
    >
      {`Start time: ${date.format(ordersCreatedAndUpdated)}`}
    </div>
  );
};
