import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Table,
  Notification,
  TableButtons,
  ConfirmationModal,
} from 'shared';
import { defaultDateTimeFormat } from 'shared/constants';
import { deleteRunner } from '../../actions';
import { RunnerForm } from '../../components';
import '../../styles.scss';

class ReportRunners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      runners: [],
      runnerId: null,
      isLoading: false,
      next: null,
      previous: null,
      count: 0,
      selectedAscDesc: 'asc',
      selectedSort: 'schedule__name',
      deleteData: null,
      showConfirmationDialog: false,
    };
  }

  componentDidMount() {
    this.getRunners();
  }

  handleSorting = (sortData) => {
    const column = sortData.id;
    let sortKey = column;
    if (column === 'schedule.name' ||
    column === 'report.name' ||
    column === 'schedule.schedule_type' ||
    column === 'schedule.minutes' ||
    column === 'schedule.next_run'
    ) {
      sortKey = column.replace('.', '__');
    }

    this.setState({
      selectedSort: sortKey,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.getRunners();
    });
  }

  getRunners = (shouldReload) => {
    const { companyId } = this.props;
    const { selectedAscDesc, selectedSort } = this.state;

    if (shouldReload) {
      this.setState({ isLoading: true });
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const sortColumn = `&order_by=${asc}${selectedSort}`;

    api
      .get(`/api/v1/reports/task_queues/?company=${companyId}${sortColumn}`)
      .then(({ data }) => {
        this.setState({
          runners: ((data.results) ? data.results : []),
          next: data.next,
          previous: data.previous,
          count: data.count,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleAdd = () => {
    this.setState({
      runnerId: 0,
    });
  }

  handleEdit = (row) => {
    this.setState({
      runnerId: row.original.id,
    });
  }

  closeEdit = (shouldReload) => {
    this.setState({
      runnerId: null,
    }, () => {
      if (shouldReload) {
        this.getRunners(false);
      }
    });
  }

  handleShowConfirmationModal = (row) => {
    this.setState({ showConfirmationDialog: true, deleteData: row });
  }

  handleDelete = () => {
    const {
      companyId,
      t,
    } = this.props;
    const id = this.state.deleteData.original.id;
    deleteRunner(id, companyId)
      .then(() => {
        this.getRunners(false);
        return Notification('success', 'Delete successful', t('page_content.automatic_reports.report_run.success_delete'));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => this.setState({ showConfirmationDialog: false }));
  }

  fetchData = (url) => {
    this.setState({ isLoading: true });
    api
      .get(url)
      .then(({ data }) => {
        this.setState({
          runners: ((data.results) ? data.results : []),
          next: data.next,
          previous: data.previous,
          count: data.count,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      runners,
      runnerId,
      isLoading,
      next,
      previous,
      count,
    } = this.state;

    const {
      companyId,
      t,
    } = this.props;

    return (
      <div className="tab_container">
        <div className="create_button_wrapper">
          <Button type="add" onClick={this.handleAdd}>{t('page_content.automatic_reports.report_run.new')}</Button>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.automatic_reports.name')}</span>,
              accessor: 'schedule.name',
              Cell: (row) => (<Fragment>{row.value ? row.value : '-'}</Fragment>),
            },
            {
              Header: () => <span>{t('page_content.automatic_reports.report_run.report_name')}</span>,
              accessor: 'report.name',
              Cell: (row) => (<Fragment>{row.value ? row.value : '-'}</Fragment>),
            },
            {
              Header: () => <span>{t('page_content.automatic_reports.report_run.schedule_type')}</span>,
              accessor: 'schedule.schedule_type',
              Cell: (row) => (<Fragment>{t(`page_content.automatic_reports.report_run.schedule_type_options.option_${row.value ? row.value : '-'}`)}</Fragment>),
            },
            {
              Header: () => <span>{t('page_content.automatic_reports.report_run.minutes')}</span>,
              accessor: 'schedule.minutes',
              Cell: (row) => (<Fragment>{row.value ? row.value : '-'}</Fragment>),
            },
            {
              Header: () => <span>{t('page_content.automatic_reports.report_run.next_run')}</span>,
              accessor: 'schedule.next_run',
              Cell: (row) => (<Fragment>{moment(row.value ? row.value : '-').format(defaultDateTimeFormat)}</Fragment>),
            },
            {
              Header: () => <span>{t('page_content.automatic_reports.actions')}</span>,
              Cell: (row) => (<div className="custom_table_row">
                <Button onClick={() => this.handleEdit(row)}>{t('page_content.automatic_reports.edit')}</Button>
                <Button type="delete" onClick={() => this.handleShowConfirmationModal(row)}>{t('page_content.automatic_reports.delete')}</Button>
              </div>),
              sortable: false,
            },
          ]}
          data={(runners) || []}
          defaultPageSize={100}
          minRows={0}
          noDataText=" "
          showPagination={false}
          loading={isLoading}
          defaultSorted={[{ id: 'schedule.name', desc: false }]}
          onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
        />
        <div style={{ float: 'right' }}>
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchData} count={count} />
        </div>

        <ConfirmationModal
          itemName={this.state.deleteData?.original?.schedule?.name}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDelete}
          type="warning"
        />

        {runnerId !== null &&
          <RunnerForm
            handleClose={this.closeEdit}
            companyId={companyId}
            runnerId={runnerId}
          />}

      </div>
    );
  }
}

ReportRunners.propTypes = {
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default (withTranslation()(ReportRunners));
