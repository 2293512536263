/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Select from 'react-select';
import { selectStyles } from 'styles/modules/reactSelect';
import { IconSearch } from 'shared/Icons';
import { defaultDateTimeFormat } from 'shared/constants';
import { Table, Button } from 'shared';
import { checkAccessOnPage, redirectToHomePage, checkModules } from 'industry/helpers';
import { getProjects, getProjectsUrl, getProjectDestinations, getProjectCategories } from './actions';
import './styles.scss';
import TableButtons from '../../../shared/TableButtons/index';
import AddProjectModal from './components/AddProjectModal';

class IndustryProjects extends Component {
  constructor(props) {
    super(props);

    let isReadOnly = false;

    if (window.resourceReadOnly && window.resourceReadOnly.length) {
      window.resourceReadOnly.forEach((resource) => {
        if (window.location.href.includes(resource)) {
          isReadOnly = true;
        }
      });
    }

    this.state = {
      projects: [],
      projectDestinations: [],
      projectCategories: [],
      isLoadingProjects: true,
      isReadOnly,
      projectQuery: '',
      filteredProjects: [],
      filterProjectCategory: '',
      showAddProjectModal: false,
      selectedAscDesc: 'desc',
      selectedSort: 'updated_at',
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Projects');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    this.fetchOrderedProjects();

    getProjectDestinations(companyId)
      .then((resp) => {
        this.setState({
          projectDestinations: get(resp, 'data.results'),
        });
      });

    getProjectCategories(companyId)
      .then((resp) => {
        this.setState({
          projectCategories: get(resp, 'data.results'),
          isLoadingProjects: false,
        });
      });
  }

  handleSave = () => {
    const { companyId, locationId } = this.props;
    this.setState({ showAddProjectModal: false, isLoadingProjects: true });
    getProjects(companyId, locationId)
      .then((res) => {
        this.setState({
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          projects: get(res, 'data.results'),
          filteredProjects: get(res, 'data.results'),
          count: get(res, 'data.count'),
          isLoadingProjects: false,
        });
      });
  }

  fetchDataUrl = (url) => {
    this.setState({
      isLoadingProjects: true,
    });

    getProjectsUrl(url)
      .then((res) => {
        this.setState({
          isLoadingProjects: false,
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          reports: get(res, 'data.results'),
          count: get(res, 'data.count'),
        });
      });
  }

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    }, () => this.handleFilterChange());
  }

  handleClear = () => {
    this.setState({
      projectQuery: '',
      filterProjectCategory: '',
    }, () => this.handleFilterChange());
  }

  handleQueryClear = () => {
    this.setState({
      projectQuery: '',
    }, () => this.handleFilterChange());
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchOrderedProjects();
    });
  }

  fetchOrderedProjects = () => {
    const { companyId, locationId } = this.props;
    const { selectedAscDesc, selectedSort } = this.state;

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const orderFilter = `&order_by=${asc}${selectedSort || ''}`;
    getProjects(companyId, locationId, orderFilter)
      .then((res) => {
        this.setState({
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          projects: get(res, 'data.results'),
          filteredProjects: get(res, 'data.results'),
          count: get(res, 'data.count'),
        });
        this.handleFilterChange();
      });
  };

  redirectToDetails = (row) => {
    const { companyId, locationId, history } = this.props;

    const projectId = row.original.id || null;

    history.push(`/${companyId}/industry/location/${locationId}/projects/${projectId}`);
  }

  handleFilterChange() {
    const { projects, projectQuery, filterProjectCategory } = this.state;
    let filteredData = projects;
    if (projectQuery !== '') {
      filteredData = filteredData.filter((project) => project.name.toLowerCase().includes(projectQuery.toLowerCase()));
    }
    if (filterProjectCategory !== '') {
      filteredData = filteredData.filter((project) => project.category === filterProjectCategory);
    }
    this.setState({ filteredProjects: filteredData });
  }

  render() {
    const { t, companyId, locationId } = this.props;
    const {
      isLoadingProjects,
      next,
      previous,
      projectDestinations,
      projectQuery,
      filteredProjects,
      projectCategories,
      filterProjectCategory,
      count,
      showAddProjectModal,
      isReadOnly,
    } = this.state;

    return (
      <div className="industry-tab industry-location-projects-list fullscreen">
        <div className="projects_toolbar">
          <div className="input_container">
            <input onChange={(e) => this.handleChange('projectQuery', e.target.value)} placeholder={t('page_content.projects.input_placeholder')} value={projectQuery} />
            {projectQuery && <button
              onClick={() => {
                this.handleQueryClear();
              }}
            >&times;</button>}
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <div className="select_container">
            <Select
              options={projectCategories}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isSearchable
              placeholder={t('page_content.projects.filter_byCategory')}
              onChange={(value) => this.handleChange('filterProjectCategory', value.id)}
              value={(projectCategories.find((pC) => pC.id === filterProjectCategory)) || ''}
              styles={selectStyles}
            />
          </div>
          <div className="clear_button">
            <Button onClick={() => this.handleClear()}>{t('page_content.projects.clear_all_button')}</Button>
          </div>
          <div className="add_new_project">
            <Button type="add" disabled={isReadOnly} onClick={() => this.setState({ showAddProjectModal: true })}>{t('page_content.projects.add_new_project')}</Button>
          </div>
        </div>
        <main>
          <Table
            columns={[
              {
                Header: () => <span>{t('page_content.projects.table_column_name')}</span>,
                accessor: 'name',
                Cell: (row) => row.value || '-',
              },
              {
                Header: () => <span>{t('page_content.projects.table_column_status')}</span>,
                accessor: 'status',
                Cell: (row) => (
                  row.value ?
                    <div className="status_wrapper">
                      <span className={`order-list__status ${row.value}`}>{t([`page_content.orders.statuses.${row.value}`])}</span>
                    </div> : ''
                ),
              },
              {
                Header: () => <span>{t('page_content.projects.table_column_code')}</span>,
                accessor: 'code',
                Cell: (row) => (row.value ? row.value : '-'),
              },
              {
                Header: () => <span>{t('page_content.projects.table_column_category')}</span>,
                accessor: 'category',
                Cell: (row) => (row.value && row.value.name ? row.value.name : '-'),
              },
              {
                Header: () => <span>{t('page_content.projects.table_column_destination')}</span>,
                accessor: 'destination',
                Cell: (row) => (row.value ? (projectDestinations.find((pD) => pD.id === row.value) && projectDestinations.find((pD) => pD.id === row.value).name) : '-'),
              },
              {
                Header: () => <span>{t('page_content.projects.table_column_created')}</span>,
                accessor: 'created_at',
                Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
              },
              {
                Header: () => <span>{t('page_content.projects.table_column_updated')}</span>,
                accessor: 'updated_at',
                Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
              },
              {
                Header: () => <span>{t('page_content.projects.table_column_started')}</span>,
                accessor: 'started_at',
                Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
              },
              {
                Header: () => <span>{t('page_content.projects.table_column_finished')}</span>,
                accessor: 'finished_at',
                Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
              },
              {
                Header: () => <span>Plan {t('page_content.projects.table_column_finished')}</span>,
                accessor: 'planned_finish',
                Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
              },
              {
                Header: () => <span>{t('page_content.projects.table_column_address')}</span>,
                accessor: 'metadata',
                sortable: false,
                Cell: (row) => (Object.prototype.hasOwnProperty.call(row.value, 'address')
                  ? <span>{row.value.address}</span>
                  : '-'
                ),
              },
              {
                Header: () => <span>{t('page_content.projects.table_column_number_of_floors')}</span>,
                accessor: 'metadata',
                sortable: false,
                Cell: (row) => (Object.prototype.hasOwnProperty.call(row.value, 'floors')
                  ? <span>{row.value.floors}</span>
                  : '-'
                ),
              },
              {
                Header: () => <span>{t('page_content.projects.table_column_architect')}</span>,
                accessor: 'metadata',
                sortable: false,
                Cell: (row) => (Object.prototype.hasOwnProperty.call(row.value, 'architect')
                  ? <span>{row.value.architect}</span>
                  : '-'
                ),
              },
            ]}
            data={filteredProjects}
            minRows={0}
            loading={isLoadingProjects}
            noDataText=" "
            showPagination={false}
            handleClick={(rowInfo) => this.redirectToDetails(rowInfo)}
            defaultSorted={[{ id: 'updated_at', desc: true }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchDataUrl} count={count} />
          <AddProjectModal handleSave={this.handleSave} projectDestinations={projectDestinations} t={t} addProjectModalState={showAddProjectModal} handleClose={() => this.setState({ showAddProjectModal: false })} companyId={companyId} locationId={locationId} />
        </main>
      </div>
    );
  }
}

IndustryProjects.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(IndustryProjects));
