import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as userActions from 'user/actions';
import * as actions from '../../actions';
import './../style.scss';

class ResetMessagePage extends Component {
  handleButtonLogin = () => {
    window.location.href = '/login';
  };

  render() {
    const {
      currentUser,
    } = this.props;

    const reset_email = localStorage.getItem('reset_email');

    return (
      <div className={`Auth ${currentUser ? 'authed' : ''}`}>
        <div className="reset_message_wrapper">
          <div className="reset_message">
            <img src="/ascalia_logo.svg" alt="Ascalia logo" width="140px" />
            <h4>Email sent</h4>
            <p>
                      We emailed you at <b>{reset_email}</b>. Click on the link in the email to continue.
            </p>
            <p>
                      If you did not recieve an e-mail,make sure you correctly enter the address or the email
                      ended up in the spam mailbox
            </p>
            <p>
                      If you still don&apos;t see the email, please contact our support.
            </p>
            <button className="button-login" onClick={this.handleButtonLogin}>
                        Return to Login
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.app,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  fetchPageMeta: actions.fetchPageMeta,
  getCurrentUser: userActions.getCurrentUser,
};

ResetMessagePage.propTypes = {
  currentUser: PropTypes.any,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetMessagePage));
