import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CompanyInfo from '../CompanyInfo';
import './styles.scss';

const CompanyList = ({ companies }) => {
  const { t } = useTranslation();
  return (
    <div className="widget-container CompanyList">
      <h2>
        {t('landing_page.my_organizations')}
      </h2>
      {
        companies.map((company) => <CompanyInfo key={`company-${company.id}`} company={company} />)
      }
    </div>
  );
};

CompanyList.propTypes = {
  companies: PropTypes.array,
};

CompanyList.defaultProps = {
  companies: [],
};

export default CompanyList;
