import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { IconLocation } from 'shared/Icons';
import './styles.scss';

class CompanyInfo extends Component {
  constructor() {
    super();
    this.state = {
      expanded: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleLocationClick = this.handleLocationClick.bind(this);
  }

  handleToggle() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  handleLocationClick(company, locationId) {
    const { history } = this.props;

    history.push(`/${company.id}/industry/location/${locationId}/`, { forced: true });
  }

  render() {
    const {
      account_type,
      locations,
      name,
    } = this.props.company;
    const { expanded } = this.state;

    return (
      <div className="CompanyInfo">
        <header
          onClick={this.handleToggle}
        >
          <div>
            <span className={`sign ${expanded ? 'sign-rotated' : ''}`}>
              <i>
                +
              </i>
            </span>
            {name}
          </div>
          <span className="account-type">
            {account_type}
          </span>
        </header>
        {
          expanded &&
          <main>
            {
              !!locations.length &&
              locations.map((location) => (
                <div key={`location-${location.id}`} onClick={() => { this.handleLocationClick(this.props.company, location.id); }}>
                  <IconLocation height="16px" width="16px" />
                  <span>
                    {location.name}
                  </span>
                </div>
              ))
            }
            {
              !locations.length &&
              <div>No locations</div>
            }
          </main>
        }
      </div>
    );
  }
}

CompanyInfo.propTypes = {
  history: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
};

export default withRouter(CompanyInfo);
