import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import Select from 'react-select';
import api from 'helpers/api';

class AssetModelPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAssetModel: props.asset_model,
      options: [],
    };
  }

  componentDidMount() {
    const { companyId } = this.props;
    api.get(`/api/v1/industry/asset_models/?order_by=name&limit=200&company=${companyId}`)
      .then((json) => this.setState({
        options: get(json, 'data.results'),
      }));
  }

  componentDidUpdate(prevProps) {
    const { asset_model } = this.props;
    if (asset_model !== prevProps.asset_model) {
      // eslint-disable-next-line
      this.setState({ selectedAssetModel: asset_model });
    }
  }

  handleChange = (assetModel) => {
    const { handleChange } = this.props;
    this.setState({ selectedAssetModel: assetModel });
    handleChange(assetModel);
  }

  render() {
    const { selectedAssetModel, options } = this.state;
    const { disabled, htmlId, t } = this.props;

    return (
      <Select
        cacheOptions
        isDisabled={disabled}
        id={htmlId}
        options={options}
        getOptionLabel={(option) => `${option.manufacturer} - ${option.model}`}
        getOptionValue={(option) => option.id}
        onChange={this.handleChange}
        placeholder={t('shared.asset_model_picker.placeholder')}
        value={(options === undefined ? [] : options).find((a) => a.id === selectedAssetModel)}
        className="AscaliaSelect AssetModelPicker"
      />
    );
  }
}

AssetModelPicker.propTypes = {
  asset_model: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  companyId: PropTypes.isRequired,
  htmlId: PropTypes.string,
  t: PropTypes.func.isRequired,
};

AssetModelPicker.defaultProps = {
  disabled: false,
};

export default withTranslation()(AssetModelPicker);
