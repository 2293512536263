import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const IndustryWidget = ({ children, title }) => (
  <div className="industry-widget">
    <header>
      {title}
    </header>
    <main>
      {children}
    </main>
  </div>
);

IndustryWidget.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
};

export default IndustryWidget;
