import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Table, Button, ContentLoader, TableButtons, ConfirmationModal } from 'shared';
import Tooltip from 'rc-tooltip';
import { IconSearch, IconComment, IconRemove, IconHand } from 'shared/Icons';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import moment from 'moment';

class MaintenanceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmationDialog: false,
      deleteData: null,
    };
  }

  getDuration = (row, type) => {
    let start_time;
    let end_time;
    if (type === 'maintenance') {
      start_time = row.original.maintenance_start || '';
      end_time = row.original.maintenance_end || '';
    } else {
      start_time = row.original.event_start || '';
      end_time = row.original.event_end || '';
    }

    if (start_time && end_time) {
      const diff = moment.duration(moment(end_time).diff(moment(start_time)));
      return moment.utc(diff.asMilliseconds()).format('HH:mm');
    }
    return '';
  }

  handleShowConfirmationModal = (row, e) => {
    e.stopPropagation();
    this.setState({ showConfirmationDialog: true, deleteData: row });
  }

  handleDeleteMaintenance = () => {
    this.props.deleteMaintenance(this.state.deleteData?.id);
    this.setState({ showConfirmationDialog: false });
  }

  render() {
    const {
      next,
      previous,
      t,
      maintenances,
      exportToExcel,
      handleMaintenancesTableRowClick,
      fetchPaginatedMaintenances,
      count,
      isSearching,
      exportLoader,
      isReadOnly,
      openEditMaintenanceModal,
      handleChange,
      selectedFilterByWorkersValue,
      handleClearSearch,
      handleApproveMaintenance,
      handleDenyMaintenance,
      maintenaceEventSum,
      handleSortOrder,
    } = this.props;

    function prepareMaintenancePersonColumn(...namesOrArrays) {
      let names = [];
      for (const arg of namesOrArrays) {
        if (Array.isArray(arg)) {
          names = names.concat(arg);
        } else {
          names.push(arg);
        }
      }

      const uniqueNames = new Set();
      for (const name of names) {
        if (name.trim()) {
          uniqueNames.add(name);
        }
      }

      const result = Array.from(uniqueNames);
      if (result.length === 0) {
        return ['-'];
      }

      return result.join(', ');
    }

    return (
      <div>
        <div className="maintenance_module_table">
          <div className="toolbar_input_container">
            <input onChange={handleChange} value={selectedFilterByWorkersValue} placeholder={t('page_content.maintenance.search')} />
            {selectedFilterByWorkersValue && <button
              onClick={handleClearSearch}
            >&times;</button>}
            <div className="dms_toolbar_icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <div className="toolbar_buttons_container">
            <Button
              onClick={() => {
                openEditMaintenanceModal();
              }}
              disabled={isReadOnly}
              type="add"
            >
              {t('page_content.maintenance.add_new_maintenance_button')}
            </Button>
            <Button
              type="export"
              onClick={exportToExcel}
              disabled={exportLoader}
            > {exportLoader ? (<ContentLoader buttonVariant />) : t('page_content.maintenance.export_to_excel_button')}
            </Button>
          </div>
        </div>

        <div className="maintenaceEventSum">{`${t('page_content.maintenance.sum_duration')}: ${maintenaceEventSum.sum_duration} (HH:mm)`}</div>
        <div className="maintenaceEventSum">{`${t('page_content.maintenance.sum_maintenance')}: ${maintenaceEventSum.sum_maintenance} (HH:mm)`}</div>

        <div>
          <Table
            columns={[
              {
                Header: () => <span>{t('page_content.maintenance.tabel_column_malfunction')}</span>,
                Cell: (row) => (
                  <span style={{ display: 'flex', justifyContent: 'space-around' }}>
                    {row?.original?.reported_reason?.is_malfunction ?
                    <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />}
                    <span>{row?.original?.is_manual ?
                        <Tooltip
                          id="tooltip-zoom"
                          trigger={['hover']}
                          placement="left"
                          overlay={<p>{t('page_content.maintenance.manually_initiated_order')}</p>}
                          overlayClassName="where-filter-tooltip"
                        >
                        <span aria-describedby="tooltip-zoom">
                        <IconHand width="20px" height="20px" />
                        </span>
                      </Tooltip>
                      : ''}</span>
                  </span>
                ),
                width: 95,
                sortable: false,
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_asset')}</span>,
                accessor: 'asset.name',
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_reason')}</span>,
                accessor: 'reported_reason',
                Cell: (row) => (
                  <span>
                    {row.value ? (
                      <span>
                        {`${row.value.group.name} - ${row.value.name}`}
                        {row.original && row.original.reported_reason && row.original.reported_reason.allow_comment ? (
                          <Tooltip
                            id="tooltip-comment"
                            trigger={['hover']}
                            placement="left"
                            overlay={row.original.reason_comment || ' '}
                            overlayClassName="where-filter-tooltip"
                          >
                            <span aria-describedby="tooltip-comment">
                              <IconComment height="18px" width="20px" />
                            </span>
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </span>
                    ) : (
                      '-'
                    )}
                  </span>
                ),
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_reporter')}</span>,
                accessor: 'reporter.last_name',
                Cell: (row) => <span>{row.value ? `${row.original.reporter.name} ${row.value}` : '-'}</span>,
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_notes')}</span>,
                width: 55,
                accessor: 'note',
                Cell: (row) => (<span className="tooltip_wrapper">{row.value ? <Tooltip
                  id="tooltip-comment"
                  trigger={['hover']}
                  placement="left"
                  overlay={row.value}
                  overlayClassName="where-filter-tooltip"
                >
                  <span
                    aria-describedby="tooltip-comment"
                  >
                    <IconComment
                      height="18px"
                      width="20px"
                    />
                  </span>
                </Tooltip> : '-'}</span>),
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_event_start')}</span>,
                accessor: 'event_start',
                Cell: (row) => (row.value ? moment(row.value).format('DD.MM.YYYY. HH:mm') : '-'),
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_event_end')}</span>,
                accessor: 'event_end',
                Cell: (row) => (row.value ? moment(row.value).format('DD.MM.YYYY. HH:mm') : '-'),
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_status')}</span>,
                accessor: 'status',
                Cell: (row) => <span>{row.value ? t(`page_content.maintenance.maintenance_statuses.${row.value}`) : '-'}</span>,
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_reviewed_by')}</span>,
                accessor: 'approved_user.last_name',
                Cell: (row) => {
                  const first_name = (row.original && row.original.approved_user && row.original.approved_user.first_name ? row.original.approved_user.first_name : '');
                  const last_name = (row && row.value ? row.value : '');
                  const email = (row.original && row.original.approved_user && row.original.approved_user.email ? row.original.approved_user.email : '');
                  return first_name && last_name ? `${first_name} ${last_name}` : email || '-';
                },
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_review_time')}</span>,
                accessor: 'approved_at',
                Cell: (row) => (row.value ? moment(row.value).format('DD.MM.YYYY. HH:mm') : '-'),
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_maintenance_start')}</span>,
                accessor: 'maintenance_start',
                Cell: (row) => (row.value ? moment(row.value).format('DD.MM.YYYY. HH:mm') : '-'),
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_maintenance_end')}</span>,
                accessor: 'maintenance_end',
                Cell: (row) => (row.value ? moment(row.value).format('DD.MM.YYYY. HH:mm') : '-'),
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_duration')}</span>,
                Cell: (row) => this.getDuration(row),
                style: { background: 'rgba(50,50,50,.1)' },
                sortable: false,
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_maintenance_duration')}</span>,
                Cell: (row) => this.getDuration(row, 'maintenance'),
                sortable: false,
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_maintenance_operator')}</span>,
                Cell: (row) => {
                  const operator = (row.original.operators && row.original.operators.length > 0 ? row.original.operators.map((op) => `${op.first_name} ${op.last_name}`) : '');
                  const start_user = (row.original.maintenance_start_user ? `${row.original.maintenance_start_user.first_name} ${row.original.maintenance_start_user.last_name}` : '');
                  const end_user = (row.original.maintenance_end_user ? `${row.original.maintenance_end_user.first_name} ${row.original.maintenance_end_user.last_name}` : '');
                  return prepareMaintenancePersonColumn(operator, start_user, end_user);
                },
                sortable: false,
              },
              {
                Header: () => <span>{t('page_content.maintenance.approve')}</span>,
                accessor: 'status',
                width: 110,
                Cell: (row) => <Button type="success" disabled={(row.value && row.value === 'approved') || isReadOnly} onClick={(e) => handleApproveMaintenance(row, e)}>{t('page_content.maintenance.approve')}</Button>,
                sortable: false,
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_deny')}</span>,
                accessor: 'status',
                width: 100,
                Cell: (row) => <Button disabled={(row.value && row.value === 'denied') || isReadOnly} onClick={(e) => handleDenyMaintenance(row, e)}>{t('page_content.maintenance.table_column_deny')}</Button>,
                sortable: false,
              },
              {
                Header: () => <span>{t('page_content.maintenance.table_column_maintenance_delete')}</span>,
                width: 85,
                Cell: (row) => <div style={{ margin: 'auto' }}><Button type="delete" disabled={isReadOnly} onClick={(e) => this.handleShowConfirmationModal(row.original, e)}><IconRemove width="13px" height="13px" /></Button></div>,
                sortable: false,
              },
            ]}
            data={maintenances}
            defaultPageSize={100}
            minRows={0}
            noDataText=""
            showPagination={false}
            handleClick={(rowInfo) => isReadOnly || handleMaintenancesTableRowClick(get(rowInfo, 'original'))}
            loading={isSearching}
            stickyHeader={!(maintenances.length <= 1)}
            defaultSorted={[{ id: 'event_start', desc: true }]}
            onSortedChange={(newSorted) => { handleSortOrder(newSorted[0]); }}
          />
          <TableButtons previous={previous} next={next} fetchFunction={fetchPaginatedMaintenances} count={count} />
        </div>
        <ConfirmationModal
          isReadOnly={isReadOnly}
          itemName={this.state.deleteData?.asset?.name}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDeleteMaintenance}
          type="warning"
        />
      </div>
    );
  }
}

MaintenanceTable.propTypes = {
  next: PropTypes.any,
  previous: PropTypes.any,
  t: PropTypes.func.isRequired,
  maintenances: PropTypes.array.isRequired,
  exportToExcel: PropTypes.func.isRequired,
  handleMaintenancesTableRowClick: PropTypes.func.isRequired,
  fetchPaginatedMaintenances: PropTypes.func.isRequired,
  count: PropTypes.number,
  isSearching: PropTypes.bool.isRequired,
  exportLoader: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool,
  deleteMaintenance: PropTypes.func.isRequired,
  handleApproveMaintenance: PropTypes.func.isRequired,
  openEditMaintenanceModal: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedFilterByWorkersValue: PropTypes.string,
  handleClearSearch: PropTypes.func.isRequired,
  handleDenyMaintenance: PropTypes.func.isRequired,
  maintenaceEventSum: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  handleSortOrder: PropTypes.func,
};

export default MaintenanceTable;
