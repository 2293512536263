import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { selectStyles } from 'styles/modules/reactSelect';
import { ContentLoader } from 'shared';
import { get } from 'lodash';
import Select from 'react-select';
import api from 'helpers/api';

class StatusPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStatus: props.selectedStatus,
      statuses: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    const { companyId } = this.props;
    api.get(`/api/v1/companies/${companyId}/`)
      .then((re) => {
        const statuses = get(re, 'data.config.statuses', []);

        this.setState({
          statuses,
          isLoading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    const { selectedStatus } = this.props;
    if (prevProps.selectedStatus !== selectedStatus) {
      // eslint-disable-next-line
      this.setState({ selectedStatus });
    }
  }

  handleChange(key, value) {
    const { changeStatus } = this.props;

    this.setState({ [key]: value });
    changeStatus(key, value);
  }

  render() {
    const { selectedStatus, statuses, isLoading } = this.state;
    const { t } = this.props;

    const statusOptions = [
      { value: 'all', name: t('page_content.orders.statuses.all') },
      { value: 'created', name: t('page_content.orders.statuses.created') },
      { value: 'in_progress', name: t('page_content.orders.statuses.in_progress') },
      { value: 'in_warehouse', name: t('page_content.orders.statuses.in_warehouse') },
      { value: 'in_transit', name: t('page_content.orders.statuses.in_transit') },
      { value: 'finished', name: t('page_content.orders.statuses.finished') },
      { value: 'delivered', name: t('page_content.orders.statuses.delivered') },
      { value: 'scheduled', name: t('page_content.orders.statuses.scheduled') },
      { value: 'paused', name: t('page_content.orders.statuses.paused') },
      { value: 'partial_delivery', name: t('page_content.orders.statuses.partial_delivery') },
      { value: 'delivered_to_customer', name: t('page_content.orders.statuses.delivered_to_customer') },
    ];

    return (
      <Select
        options={
          isLoading ? [] :
            statuses && statuses.length
              ? statuses.map((status) => ({ value: status, label: status }))
              : statusOptions
        }
        placeholder={t('shared.status_placeholder')}
        getOptionLabel={(option) => (statuses && statuses.length
          ? t([`page_content.orders.statuses.${option.label}`]) :
          option.name)}
        getOptionValue={(option) => option.value}
        isSearchable
        onChange={(e) => this.handleChange('selectedStatus', e)}
        value={selectedStatus || null}
        styles={selectStyles}
        isLoading={isLoading}
        loadingMessage={() => <ContentLoader />}
      />
    );
  }
}

StatusPicker.propTypes = {
  selectedStatus: PropTypes.string,
  changeStatus: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

StatusPicker.defaultProps = {
  disabled: false,
};

export default (withTranslation()(StatusPicker));
