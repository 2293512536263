/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { modalSizes, defaultDateTimeFormat } from 'shared/constants';
import {
  Modal, ContentLoader, Table, Button, Notification, ConfirmationModal,
} from 'shared';
import { IconRemove } from 'shared/Icons';
// import { Circle } from 'rc-progress';
import leftChevron from 'shared/Icons/left-chevron.png';
import Folder from 'shared/Icons/Folder.png';
import { checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import './style.scss';
import {
  fetchAssetItems, fetchAsset, fetchItemDetails, setIsDefect, deleteEvents,
} from './actions';

class OperatorInterface extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: null,
      isModalOpen: false,
      loadingBar: 0,
      assetData: null,
      assetItems: null,
      showAssetItems: false,
      selectedTs: null,
      pageSizeModified: false,
      showConfirmationDialog: false,
      deleteData: {},
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { assetId, companyId, locationId },
      },
    } = this.props;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    const urlParams = new URLSearchParams(window.location.search);
    const ts = urlParams.get('ts');

    if (ts) {
      // eslint-disable-next-line
      this.setState({
        selectedTs: ts,
      });

      this.fetchData(assetId, ts);
    }

    fetchAsset(assetId)
      .then((response) => {
        const assetData = get(response, 'data');
        if (!assetData) {
          const urlPart = window.location.href.split('operator')[0];
          const url = new URL(urlPart);
          const base = url.pathname;
          const backLocation = `${base}assets`;
          window.location.href = backLocation;
        }
        fetchAssetItems(assetId)
          .then((res) => {
            const assetItems = get(res, 'data');
            this.setState({
              assetData,
              assetItems,
              isLoading: false,
            });
          }).catch((e) => console.log(e));
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
        const urlPart = window.location.href.split('operator')[0];
        const url = new URL(urlPart);
        const base = url.pathname;
        const backLocation = `${base}assets`;
        window.location.href = backLocation;
        return Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
      });

    this.es = new EventSource(`/orders/last-event/?asset_id=${assetId}`);
    this.es.onmessage = this.onEventMessage;
    this.es.onerror = this.onEventError;
  }

  componentDidUpdate() {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;
    const { selectedTs, pageSizeModified } = this.state;

    if (!pageSizeModified) {
      const page = document.querySelector('.page .operator');
      if (page) {
        const parent = page.parentElement.parentElement;
        this.backupPageHeight = parent.style.height;
        this.backupParent = parent;
        parent.style.height = '100%';
        // eslint-disable-next-line
        this.setState({
          pageSizeModified: true,
        });
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const ts = urlParams.get('ts');
    if (ts) {
      if (selectedTs) {
        if (selectedTs !== ts) {
          // eslint-disable-next-line
          this.setState({
            selectedTs: ts,
            showAssetItems: false,
          }, () => {
            this.fetchData(assetId, ts);
          });
        }
      } else {
        // eslint-disable-next-line
        this.setState({
          selectedTs: ts,
          showAssetItems: false,
        }, () => {
          this.fetchData(assetId, ts);
        });
      }
    } else if (selectedTs) {
      // eslint-disable-next-line
      this.setState({
        showAssetItems: false,
        selectedTs: null,
      });
    }
  }

  componentWillUnmount() {
    if (this.es) {
      this.es.close();
    }
    window.operatorTS = null;
    if (this.backupParent) {
      this.backupParent.style.height = this.backupPageHeight;
    }
  }

  onEventMessage = (e) => {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    const { selectedTs } = this.state;

    if (e.data !== window.operatorTS && !selectedTs) {
      if (e.data && e.data !== 'None') {
        window.operatorTS = e.data;
        this.fetchData(assetId, e.data);
      }
    }
  }

  onEventError = (e) => {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    switch (e.target.readyState) {
      case EventSource.CONNECTING:
        console.log('Reconnecting...');
        break;
      case EventSource.CLOSED:
        console.log('Reinitializing...');
        this.es = new EventSource(`/orders/last-event/?asset_id=${assetId}`);
        this.es.onerror = this.onEventError;
        this.es.onmessage = this.onEventMessage;
        break;
      default:
        break;
    }
  }

  setTs = (ts) => {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    if (ts.ts) {
      const urlPart = window.location.href.split('operator')[0];
      const url = new URL(urlPart);
      const base = url.pathname;
      const selectedTsUrl = `${base}operator-interface/${assetId}?ts=${ts.ts}`;

      const { history } = this.props;
      history.push(selectedTsUrl);
    } else {
      const urlPart = window.location.href.split('operator')[0];
      const url = new URL(urlPart);
      const base = url.pathname;
      const selectedTsUrl = `${base}operator-interface/${assetId}?ts=${ts}`;

      const { history } = this.props;
      history.push(selectedTsUrl);
    }
  }

  performCurrentFetch = () => {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    const ts = urlParams.get('ts');

    if (ts) {
      this.setState({
        selectedTs: ts,
      });

      this.fetchData(assetId, ts);
    }

    fetchAssetItems(assetId)
      .then((res) => {
        const assetItems = get(res, 'data');
        this.setState({
          assetItems,
        });
      }).catch((e) => console.log(e));
  }

  backToRootUrl = () => {
    const { history } = this.props;
    const { data } = this.state;

    const urlPart = window.location.href.split('operator')[0];
    const url = new URL(urlPart);
    const base = url.pathname;

    window.operatorTS = null;
    const latestTsUrl = `${base}operator-interface/${data.id}`;
    history.push(latestTsUrl);
  }

  fetchData = (assetId, ts) => {
    fetchItemDetails(assetId, ts)
      .then((res) => {
        const data = get(res, 'data');
        this.setState((prevState) => {
          return {
            isLoading: false,
            data,
            loadingBar: prevState.loadingBar === 100 ? 0 : prevState.loadingBar + 10,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  }

  handleSave = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  toggleDisplayAssetItems = (showAssetItems) => {
    this.setState({
      showAssetItems,
    });
  }

  resolveDefect = (isDefect) => {
    const { data, assetItems } = this.state;

    if (!data || !data.production_data || !data.production_data.ts || !data.production_data.item_snowflake) {
      Notification('error', 'Data missing', 'This item is missing data and defect cannot be resolved.');
      return;
    }

    const payload = {
      ts: data.production_data.ts,
      value: isDefect,
      external_id: data.external_id,
      snowflake_id: data.production_data.item_snowflake,
      comment: '',
    };

    setIsDefect(payload)
      .then(() => {
        if (isDefect) {
          Notification('success', 'Damage confirmed', 'The damage was successfully confirmed.');
        } else {
          Notification('success', 'Issue resolved', 'The issue was successfully resolved.');
          let index = assetItems.map((e) => e.ts).indexOf(data.production_data.ts);

          if (index !== 0) {
            index -= 1;
            const nextTs = assetItems[index];
            this.setTs(nextTs);
          }
        }
        this.performCurrentFetch();
      })
      .catch((error) => { console.log(error); });
  }

  handleShowConfirmationDialog = (operator_view_event, ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    this.setState({ deleteData: operator_view_event, showConfirmationDialog: true });
  }

  handleDeleteEvent = () => {
    const {
      selectedTs,
      assetItems,
      assetData,
      deleteData,
    } = this.state;
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    const operator_view_event = deleteData;

    if (operator_view_event && operator_view_event.original && operator_view_event.original.ts && operator_view_event.original.metric && operator_view_event.original.metric.id) {
      let metricId = operator_view_event.original.metric.id;
      let ts = operator_view_event.original.ts;

      deleteEvents(metricId, ts)
        .then(() => {
          metricId = '';
          ts = '';
          fetchAssetItems(assetId)
            .then((res) => {
              const newAssetItems = get(res, 'data');
              this.setState({
                assetData,
                assetItems: newAssetItems,
                isLoading: false,
              });
            }).catch((e) => console.log(e));
          return Notification('success', 'Delete successful', 'Event was successfuly deleted.');
        })
        .catch((err) => {
          console.log(err);
          return Notification('error', 'An error occurred while removing event', 'We could not perform your request, please try again.');
        });
      if (operator_view_event.original.ts === selectedTs) {
        let index = assetItems.map((e) => e.ts).indexOf(assetData.production_data.ts);
        if (index !== 0) {
          index -= 1;
          const nextTs = assetItems[index];
          this.setTs(nextTs);
        }
      }
      this.performCurrentFetch();
    } else {
      return Notification('error', 'An error occurred while removing event', 'We could not perform your request, please try again.');
    }
    this.setState({ showConfirmationDialog: false });
  }

  render() {
    const { t } = this.props;
    const {
      isLoading, data, isModalOpen, // loadingBar,
      showAssetItems, assetItems, selectedTs, assetData,
    } = this.state;

    if (isLoading) {
      return <ContentLoader />;
    }

    const urlPart = window.location.href.split('operator')[0];
    const url = new URL(urlPart);
    const base = url.pathname;
    const backLocation = `${base}operator`;

    let orderData = 'No Data';
    let positionData = 'No Data';
    let detailsData = 'No Data';
    let productCodeData = 'No Data';
    let QRData = 'No Data';
    let statusText = 'No Data';
    let nameDetail = '';

    if (data) {
      if (data.item_part) {
        statusText = data.item_part.order_item ? data.item_part.order_item.status : 'No Data';
        positionData = data.item_part.order_item.item_num === undefined ? 'No Data' : data.item_part.order_item.item_num;
        detailsData = data.item_part.order_item.detail === undefined ? 'No Data' : data.item_part.order_item.detail;
        productCodeData = data.item_part.order_item.name === undefined ? 'No Data' : data.item_part.order_item.name;
        QRData = data.integration.image_url === undefined ? 'No Data' : data.integration.image_url;
      }

      if (data.asset_group) {
        nameDetail = data.asset_group.name;
      }

      if (data.order) {
        orderData = data.order.external_id || 'No Data';
      }
    }

    if (assetData) {
      nameDetail = assetData.asset_group.name;
    }

    const orderClass = orderData === 'No Data' ? 'error' : positionData === 'No Data' ? 'error' : '';
    const detailsClass = detailsData === 'No Data' ? 'error' : '';
    const productCodeClass = productCodeData === 'No Data' ? 'error' : '';
    const QR = QRData === 'No Data' ? <p>No Image Data</p> : <img src={data.integration.image_url} alt="QR code" />;

    const itemHasError = (data && data.status) ? data.status.defect : false;
    let orderInfoError = '';
    if (itemHasError) {
      statusText = 'Detected board damage';
      orderInfoError = 'operator__orderInfo--error';
    }

    let eventImage = '';

    if (data && data.event) {
      eventImage = (<div className="error-image">
        <img src={data.event.image} alt={data.event.description} />
      </div>);
    }

    return (
      <div className="operator">
        <Modal
          style={{ border: '1px solid #f0efef', borderRadius: '10px' }}
          className="Modal"
          size={modalSizes.large}
          isOpen={isModalOpen}
          handleClose={() => this.setState({ isModalOpen: false })}
          title="QR code does not match"
          titleClass="Modal_operator_title"
          closeText="Repeate"
          saveText="Report error"
          handleSave={this.handleSave}
        >
          <p style={{ paddingLeft: '20px' }}>
            It is necessary to match the order number with the item
          </p>
        </Modal>

        <Modal
          className="Modal"
          size={modalSizes.large}
          isOpen={showAssetItems}
          handleClose={() => { this.toggleDisplayAssetItems(false); }}
        >
          <Table
            columns={[
              {
                Header: () => <span>{t('page_content.operator_view.detailed_operator_interface.modal.table_column_order')}</span>,
                accessor: 'integration.order_name',
              },
              {
                Header: () => <span>{t('page_content.operator_view.detailed_operator_interface.modal.table_column_item')}</span>,
                accessor: 'integration.item_name',
              },
              {
                Header: () => <span>{t('page_content.operator_view.detailed_operator_interface.modal.table_column_position')}</span>,
                accessor: 'integration.position',
              },
              {
                Header: () => <span>{t('page_content.operator_view.detailed_operator_interface.modal.table_column_status')}</span>,
                accessor: 'integration.status',
              },
              {
                Header: () => <span>{t('page_content.operator_view.detailed_operator_interface.modal.table_column_time')}</span>,
                accessor: 'ts',
                Cell: (row) => moment(row.value).format(defaultDateTimeFormat),
              },
              {
                Header: () => <span>{t('page_content.operator_view.detailed_operator_interface.modal.table_column_label')}</span>,
                accessor: 'metric.label',
              },
              {
                Header: () => <span>{t('page_content.operator_view.detailed_operator_interface.modal.table_column_delete')}</span>,
                Cell: (operator_view_event) => <Button type="delete" className="remove-btn" onClick={(ev) => this.handleShowConfirmationDialog(operator_view_event, ev)}><IconRemove width="12px" height="12px" /></Button>,
              },
            ]}
            data={assetItems}
            defaultPageSize={100}
            handleClick={(row) => { this.setTs(row.original.ts); }}
            minRows={0}
            noDataText=" "
            showPagination={false}
            manual={false}
          />
        </Modal>

        <div className="operator__companyInfo">
          <div className="operator__companyInfo__left">
            <div className="operator__companyInfo__Backimg">
              <Link to={backLocation}>
                <img
                  src={leftChevron}
                  alt="<"
                  style={{ height: '35px', width: '27px', marginBottom: '-2px' }}
                />
              </Link>
            </div>
            <div className="operator__companyInfo__title">
              <span>{nameDetail} {'>'} {data ? data.external_id : ''}</span>
            </div>
          </div>
          <div className="operator__companyInfo__right">
            {
              selectedTs ?
                <div className="operator__back-to-latest">
                  <Button
                    onClick={this.backToRootUrl}
                  >
                    {t('page_content.operator_view.detailed_operator_interface.back_to_latest_button')}
                  </Button>
                </div> : ''
            }
            <div className="operator__companyinfo__folder">
              <img
                src={Folder}
                alt="folder"
                onClick={() => { this.toggleDisplayAssetItems(true); }}
              />
            </div>
          </div>
        </div>
        <div className="operator__order_time_changes">
          <div className="operator__order_time ">
            <div className={`operator__orderInfo ${orderInfoError}`}>
              <div className="operator__titles">
                <strong>{t('page_content.operator_view.detailed_operator_interface.order.title')}</strong>
              </div>
              <div className={`operator__orderInfo__data ${orderClass}`}>
                <p className="operator__data-title">{t('page_content.operator_view.detailed_operator_interface.order.order_position')}</p>
                <p className="operator__data-content">
                  {orderData}
                  <span>{positionData}</span>
                </p>
              </div>
              {
                !itemHasError ?
                  <React.Fragment>
                    <div className={`operator__orderInfo__data ${productCodeClass}`}>
                      <p className="operator__data-title">{t('page_content.operator_view.detailed_operator_interface.order.product_code')}</p>
                      <p className="operator__data-content">
                        {productCodeData}
                      </p>
                    </div>
                    <div className={`operator__orderInfo__data ${detailsClass}`}>
                      <p className="operator__data-title">{t('page_content.operator_view.detailed_operator_interface.order.details')}</p>
                      <p className="operator__data-content">
                        {detailsData}
                      </p>
                    </div>
                  </React.Fragment>
                  :
                  eventImage
              }
              {itemHasError ?
                <div className="operator__resolve-defect">
                  <span
                    className="defect-button"
                  >
                    <Button
                      onClick={() => { this.resolveDefect(1); }}
                    >
                      {t('page_content.operator_view.detailed_operator_interface.order.damage_button')}
                    </Button>
                  </span>
                  <span
                    className="continue-button"
                  >
                    <Button
                      onClick={() => { this.resolveDefect(0); }}
                    >
                      {t('page_content.operator_view.detailed_operator_interface.order.continue_button')}
                    </Button>
                  </span>
                </div> : ''}
            </div>
            <div className="operator__labelStatus">
              <div className="operator__timeInfo">
                <div className="operator__titles">
                  <strong>{t('page_content.operator_view.detailed_operator_interface.title_label')}</strong>
                </div>
                <div className="operator__orderInfo__data">
                  {QR}
                </div>
              </div>
              <div className="operator__changes">
                <div className="operator__options">
                  <div className="operator__titles">
                    <strong>{t('page_content.operator_view.detailed_operator_interface.title_status')}</strong>
                  </div>
                  <div className="operator__processInfo">
                    {/* <div className="status-indicator">
                      <Circle
                        className="bar"
                        percent={loadingBar}
                        strokeWidth="3"
                        trailWidth="4"
                        strokeColor="#33B795"
                        trailColor="#EBF4F8"
                        strokeLinecap="butt"
                      />
                    </div> */}
                    {
                      !itemHasError ?
                        <div className="status-text">
                          Status: {statusText}
                        </div> :
                        <div className="status-text status-text--error">
                          {statusText}
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={`${footerClass}`}>
            <div className="operator__footer__loadingBar">
              <Line
                className="bar"
                percent={loadingBar}
                strokeWidth="3"
                trailWidth="4"
                strokeColor="#33B795"
                trailColor="#EBF4F8"
                strokeLinecap="butt"
              />
              <p>{loadingBar}%</p>
            </div>
            <div className="operator__footer__buttons">
              <Button size="large" type="success"> Transport </Button>
              <Button size="large"> Incorrect </Button>
              <Button size="large"> Other error </Button>
            </div> */}
          {/* </div> */}

        </div>
        {/*
        <Button
          onClick={() => {
            this.displayModal();
          }}
        >
          Open Modal
        </Button> */}
         <ConfirmationModal
           itemName={this.state.deleteData?.original?.integration?.item_name}
           showModal={this.state.showConfirmationDialog}
           handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
           handleConfirmModal={this.handleDeleteEvent}
           type="warning"
         />
      </div>
    );
  }
}

OperatorInterface.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(OperatorInterface);
