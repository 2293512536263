import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Modal } from 'shared';
import { modalSizes, defaultDateTimeFormat } from 'shared/constants';

const EventsModal = ({ selectedEvent = '', isOpen, handleCloseModal }) => {
  return (
    <Modal
      size={modalSizes.medium}
      handleClose={handleCloseModal}
      isOpen={isOpen}
      title={`${selectedEvent?.external_id} | ${moment(selectedEvent?.created_at).format(defaultDateTimeFormat)} | ${selectedEvent?.status}`}
    >
      <div className="default-form">
      <textarea
        style={{ height: '300px' }}
        disabled
        value={JSON.stringify(selectedEvent?.payload, null, 2)}
      />
      </div>
  </Modal>
  );
};

EventsModal.propTypes = {
  selectedEvent: PropTypes.object,
  isOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func.isRequired,
};

export default EventsModal;
