import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Modal, ContentLoader } from 'shared';

const EditProductModal = ({
  isEditProductModalOpen,
  selectedProduct,
  handleProductChange,
  closeEditProductModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    manuf_id: '',
  });

  useEffect(() => {
    if (selectedProduct) {
      setFormData((prevState) => ({
        ...prevState,
        manuf_id: get(selectedProduct, 'manuf_id', ''),
      }));
    }
  }, [selectedProduct]);

  const handleFormChange = (key, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);

    const id = get(selectedProduct, 'id', null);
    const payload = {
      code: get(selectedProduct, 'code', ''),
      company: get(selectedProduct, 'company', ''),
      manuf_id: get(formData, 'manuf_id', ''),
    };

    await handleProductChange(id, payload);
    setIsLoading(false);
    closeEditProductModal();
  };

  return (
    <Modal
      isOpen={isEditProductModalOpen}
      handleClose={closeEditProductModal}
      handleSave={handleSave}
    >
      {isLoading ?
        <ContentLoader /> :
        <div className="edit_product_modal_container">
          <div className="modal_row">
            <div className="left_text">ERP ID</div>
            <div className="right_input">
              <input
                onChange={(e) => handleFormChange('manuf_id', e.target.value)}
                value={formData.manuf_id || ''}
              />
            </div>
          </div>
        </div>}
    </Modal>
  );
};

EditProductModal.propTypes = {
  isEditProductModalOpen: PropTypes.bool,
  selectedProduct: PropTypes.object,
  handleProductChange: PropTypes.func,
  closeEditProductModal: PropTypes.func,
};

export default EditProductModal;
