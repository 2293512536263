import React from 'react';
import Base from './Base';

export default (props) => (<Base viewBox="0 0 500 500" {...props}>
  <g>
    <path d="M208 316.005C208 324.29 214.716 331.006 223.001 331.006C231.284 331.006 238 324.29 238 316.005C238 307.721 231.284 301.006 223.001 301.006C214.72 301.015 208.011 307.726 208 316.005Z" fill="#858585" />
    <path d="M239 233.005C239 241.29 245.716 248.006 254.001 248.006C262.284 248.006 269 241.29 269 233.005C269 224.722 262.284 218.006 254.001 218.006C245.72 218.015 239.011 224.726 239 233.005Z" fill="#858585" />
    <path d="M146 272.005C146 280.29 152.716 287.006 161.001 287.006C169.284 287.006 176 280.29 176 272.005C176 263.721 169.284 257.006 161.001 257.006C152.72 257.015 146.011 263.726 146 272.005Z" fill="#858585" />
    <path d="M299 282.005C299 290.29 305.716 297.006 314.001 297.006C322.284 297.006 329 290.29 329 282.005C329 273.721 322.284 267.006 314.001 267.006C305.72 267.015 299.011 273.726 299 282.005V282.005Z" fill="#858585" />
    <path d="M128.001 367.006C136.284 367.006 143 360.29 143 352.005C143 343.721 136.284 337.006 128.001 337.006C119.716 337.006 113 343.721 113 352.005C113.011 360.286 119.72 366.997 128.001 367.006V367.006Z" fill="#858585" />
    <line x1="66.9843" y1="416" x2="66.9843" y2="96" stroke="#858585" strokeWidth="3" />
    <line x1="66" y1="414.506" x2="446" y2="414.506" stroke="#858585" strokeWidth="3" />
  </g>
</Base>);
