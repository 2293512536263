import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';

import { Table, TableButtons, Button, Modal, ValuesMapper } from 'shared';
import { IconEdit, IconRemove, IconSearch } from 'shared/Icons';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import './style.scss';

import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';

import {
  addStep,
  addDepartment,
  getDepartments,
  editDepartment,
  deleteDepartment,
  editDepartmentStep,
  getDepartmentSteps,
  deleteDepartmentStep,
  getDepartmentChildren,
  getDepartmentLocations,
  getPaginatedDepartments,
} from './actions';

import DepartmentModal from './components/DepartmentModal/index';

class DepartmentTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      filterIsProduction: false,
      isLoadingDepartments: false,
      isReadOnly: false,
      previous: null,
      showModal: false,
      next: null,
      departments: [],
      locations: [],
      prevDepartmentData: [],
      departmentData: {
        id: null,
        name: '',
        location: null,
        steps: [],
        is_production_department: false,
        parent: null,
      },
      deleteModalStatus: false,
      departmentModalRow: [],
      count: 0,
      selectedAscDesc: 'desc',
      selectedSort: 'name',
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;
    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToSettingsPage(companyId, locationId);
        } else if (access === 1) {
          this.setState({
            isReadOnly: true,
          });
          this.getDepartments();
          this.getLocations();
        } else {
          this.getDepartments();
          this.getLocations();
        }
      });
  }

  getDepartments = () => {
    const { locationId } = this.props;
    const { selectedAscDesc, selectedSort, query, filterIsProduction } = this.state;
    this.setState({
      isLoadingDepartments: true,
    });

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    let apiFilters = `&order_by=${asc}${selectedSort}`;

    if (query) apiFilters += `&name=${query}`;

    if (filterIsProduction) apiFilters += '&is_production_department=true';

    getDepartments(locationId, apiFilters)
      .then((re) => {
        this.setState({
          departments: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingDepartments: false,
        });
      })
      .catch((error) => console.log(error));
  }

  getLocations = () => {
    const { companyId } = this.props;
    getDepartmentLocations(companyId)
      .then((re) => {
        this.setState({
          locations: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingDepartments: false,
        });
      });
  }

  getLocationNameById = (departmentId) => {
    const { locations } = this.state;
    const targetLocation = locations.find((d) => d.id === departmentId);
    if (targetLocation) {
      return targetLocation.name;
    }
    return '';
  }

  handleStateQueryChange = (value) => {
    this.setState({ query: value }, () => this.getDepartments());
  }

  handleFilterChange = (field, value) => {
    this.setState({ [field]: value }, () => this.getDepartments());
  }

  handleModalClose = () => {
    this.setState({
      showModal: false,
      prevDepartmentData: [],
      departmentData: {
        id: null,
        name: '',
        location: null,
        steps: [],
        parent: null,
      },
    });
  }

  handleEditDepartment = async (row) => {
    const { locationId } = this.props;
    let steps = [];
    let children = [];
    await getDepartmentSteps(get(row, 'original.id', null))
      .then((res) => { steps = get(res, 'data.results', []); })
      .catch((error) => console.error(error));
    await getDepartmentChildren(locationId, get(row, 'original.id', null))
      .then((res) => { children = get(res, 'data.results', []); })
      .catch((error) => console.error(error));
    this.setState((prevState) => ({
      ...prevState,
      prevDepartmentData: prevState.departmentData?.id ? [...prevState.prevDepartmentData, { ...prevState.departmentData }] : [],
      departmentData: {
        ...prevState.departmentData,
        id: get(row, 'original.id', null),
        name: get(row, 'original.name', null),
        department: get(row, 'original.department', null),
        location: get(row, 'original.location', null),
        steps,
        is_production_department: get(row, 'original.is_production_department', false),
        parent: get(row, 'original.parent', null),
        children,
      },
      showModal: true,
    }));
  }

  handleDepartmentData = (value, field) => {
    const { departmentData } = this.state;
    const updatedData = { ...departmentData };

    updatedData[field] = value;

    this.setState({
      departmentData: updatedData,
    });
  }

  handleCreateOrUpdateDepartment = async () => {
    const { departmentData } = this.state;
    const { locationId, currentUser } = this.props;

    if (!departmentData) return;
    this.setState({
      isLoadingDepartments: true,
      showModal: false,
    });

    if (departmentData.id) {
      const payload = {
        id: departmentData.id,
        name: departmentData.name,
        location: locationId,
        steps: { steps: departmentData.steps },
        is_production_department: departmentData.is_production_department || false,
        parent: departmentData.parent?.id || null,
      };

      let steps = [];
      let payloadSteps = payload?.steps?.steps?.filter((step) => step && typeof step === 'object');
      await getDepartmentSteps(departmentData.id)
        .then((res) => { steps = get(res, 'data.results', null); })
        .catch((error) => console.error(error));

      const changedSteps = payloadSteps.filter((newStep) => {
        const oldStep = steps.find((step) => step.id === newStep.id);
        return oldStep && (oldStep.name !== newStep.name || oldStep.code !== newStep.code || oldStep.default_duration !== newStep.default_duration);
      });
      if (changedSteps.length > 0) {
        for (const step of changedSteps) {
          // eslint-disable-next-line no-await-in-loop
          await editDepartmentStep(step.id, step.department, step);
        }
      }

      let newSteps = payloadSteps.filter((step) => typeof step === 'object' && !Object.hasOwn(step, 'department'));
      if (newSteps.length > 0) {
        // eslint-disable-next-line no-return-await
        const stepPromises = newSteps.map(async (step) => await addStep(departmentData.id, { ...step, user: currentUser?.id, department: departmentData.id }));
        newSteps = await Promise.all(stepPromises.map((promise) => promise.then((step) => get(step, 'data', null))));
        const matchedSteps = payloadSteps.map((step) => newSteps.find((newStep) => newStep.name === step.name) || step);
        payloadSteps = matchedSteps;
      }

      await editDepartment(departmentData.id, locationId, { ...payload, steps: { steps: payloadSteps } })
        .then(() => {
          this.setState({
            showModal: false,
            prevDepartmentData: [],
            departmentData: {
              id: null,
              name: '',
              location: null,
              steps: [],
              is_production_department: false,
            },
          });
          this.getDepartments();
        });
    } else {
      const payload = {
        id: '',
        name: departmentData.name,
        location: locationId,
        steps: { steps: departmentData.steps },
        is_production_department: departmentData.is_production_department || false,
        parent: departmentData.parent?.id,
      };
      let steps = departmentData.steps.filter((step) => step);
      steps = steps.filter((step) => typeof step === 'object' && step !== null);
      addDepartment(payload)
        .then(async (res) => {
          let newSteps = [];
          const departmentId = get(res, 'data.id', null);

          if (departmentId && currentUser) {
            const stepPromises = steps.map((step) => addStep(departmentId, { ...step, user: currentUser?.id, department: departmentId }));
            newSteps = await Promise.all(stepPromises.map((promise) => promise.then((step) => get(step, 'data', null))));
          }
          await editDepartment(departmentId, locationId, { ...get(res, 'data'), steps: { steps: newSteps } })
            .then(() => {
              this.setState({
                showModal: false,
                prevDepartmentData: [],
                departmentData: {
                  id: null,
                  name: '',
                  location: null,
                  steps: [],
                  is_production_department: false,
                },
              });
              this.getDepartments();
            });
        });
    }
  }

  handleDeleteDepartment = async (department) => {
    const { departments } = this.state;
    const { locationId } = this.props;

    if (!departments && !departments.id) return;
    this.setState({
      isLoadingDepartments: true,
    });

    deleteDepartment(department.id, locationId)
      .then(() => {
        this.setState({
          deleteModalStatus: false,
          departmentModalRow: [],
        }, () => this.getDepartments());
      });
  }

  fetchDataForPagination = (url) => {
    this.setState({
      isLoadingDepartments: true,
    });

    getPaginatedDepartments(url)
      .then((re) => {
        this.setState({
          departments: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingDepartments: false,
        });
      })
      .catch((e) => console.error('Error while fetching technologies', e));
  }

  handleDeleteModalOpen = (e, rowInfo) => {
    e.stopPropagation();
    this.setState({
      deleteModalStatus: true,
      departmentModalRow: rowInfo,
    });
  }

  handleDeleteModalClose = () => {
    this.setState({
      deleteModalStatus: false,
      departmentModalRow: [],
    });
  }

  handleStepJsonChange = (value, index, key) => {
    this.setState((prevState) => {
      const newSteps = [...prevState.departmentData.steps];
      newSteps[index] = { ...newSteps[index], [key]: value };
      return {
        departmentData: {
          ...prevState.departmentData,
          steps: newSteps,
        },
      };
    });
  };

  handleInsertNewEmptyStep = () => {
    this.setState((prevState) => ({
      departmentData: {
        ...prevState.departmentData,
        steps: [...prevState.departmentData.steps, ''],
      },
    }));
  };

  handleRemoveStep = async (row) => {
    const { departmentData } = this.state;
    const stepId = get(row, 'original.id', null);
    const departmentId = get(row, 'original.department', null);
    if (stepId && departmentId) await deleteDepartmentStep(stepId, departmentId).catch((error) => console.error(error));

    let steps = [];
    await getDepartmentSteps(departmentData?.id)
      .then((res) => { steps = get(res, 'data.results', []); })
      .catch((error) => console.error(error));

    this.setState((prevState) => {
      return {
        departmentData: {
          ...prevState.departmentData,
          steps,
        },
      };
    });
  };

  handleSorting = (sortData) => {
    this.setState({ isLoadingDepartments: true });
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.getDepartments();
    });
  }

  handleModalBack = async (dep) => {
    const { locationId } = this.props;
    const { prevDepartmentData } = this.state;

    const prevDepartmentDataCopy = [...prevDepartmentData];
    const removeAfterIndex = prevDepartmentDataCopy.findIndex((d) => d?.id === dep?.id);
    if (removeAfterIndex !== -1) prevDepartmentDataCopy.splice(removeAfterIndex);

    let steps = [];
    let children = [];

    await getDepartmentSteps(get(dep, 'id', null))
      .then((res) => { steps = get(res, 'data.results', []); })
      .catch((error) => console.error(error));
    await getDepartmentChildren(locationId, get(dep, 'id', null))
      .then((res) => { children = get(res, 'data.results', []); })
      .catch((error) => console.error(error));

    this.setState((prevState) => ({
      ...prevState,
      prevDepartmentData: prevDepartmentDataCopy,
      departmentData: {
        ...prevState.departmentData,
        id: get(dep, 'id', null),
        name: get(dep, 'name', null),
        department: get(dep, 'department', null),
        location: get(dep, 'location', null),
        steps,
        is_production_department: get(dep, 'is_production_department', false),
        parent: get(dep, 'parent', null),
        children,
      },
      showModal: true,
    }));
  }

  handleClearSearch = () => {
    this.setState({ query: '' }, () => this.getDepartments());
  }

  render() {
    const {
      next,
      count,
      query,
      previous,
      showModal,
      isReadOnly,
      departments,
      departmentData,
      deleteModalStatus,
      departmentModalRow,
      prevDepartmentData,
      isLoadingDepartments,
      filterIsProduction,
    } = this.state;
    const { t, locationId } = this.props;

    const deleteModalColumnInfo = [
      {
        Header: () => <span>{t('page_content.department.table_column_name')}</span>,
        accessor: 'name',
      },
      {
        Header: () => <span>{t('page_content.department.table_column_technologies')}</span>,
        accessor: 'technologies',
        Cell: (row) => {
          return <ValuesMapper data={row?.original?.technologies || []} accessor="name" sliceNumber={3} />;
        },
      },
    ];

    return (
      <div className="department-management-list">
        <div className="table-controls">
          <div className="table-controls__left">
            <div className="table-controls__search">
              <div className="input_container">
                <input
                  onChange={(e) => this.handleStateQueryChange(e.target.value)}
                  placeholder={t('page_content.department.search_by_name')}
                  value={query}
                  type="text"
                />
                {
                  query &&
                  <button onClick={this.handleClearSearch}>
                    &times;
                  </button>
                }
                <div className="icon_container">
                  <IconSearch
                    color="#555"
                    height="26px"
                    width="26px"
                  />
                </div>
              </div>
            </div>

            <div className="is_production">
            <input type="checkbox" onChange={(e) => this.handleFilterChange('filterIsProduction', e?.target?.checked)} checked={filterIsProduction} />
            <span>{t('page_content.department.is_production')}</span>
            </div>
          </div>

          <div className="table-controls__right">
            <Button
              onClick={() => this.setState({
                showModal: true,
              })}
              type="add"
              disabled={isReadOnly}
              style={{ height: 'fit-content' }}
            >
              {t('page_content.department.add_department')}
            </Button>
          </div>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.department.table_column_name')}</span>,
              accessor: 'name',
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.department.form_parent_department')}</span>,
              accessor: 'parent.name',
              width: 250,
              Cell: (row) => <span>{row?.value || '-'}</span>,
              sortable: false,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>Steps</span>,
              accessor: 'operations',
              Cell: (row) => {
                const steps = Array.isArray(row?.value) ? row?.value : [];
                return <ValuesMapper data={steps} accessor="name" sliceNumber={3} />;
              },
              sortable: false,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.department.table_column_technologies')}</span>,
              accessor: 'technologies',
              Cell: (row) => {
                return <ValuesMapper data={row?.original?.technologies || []} accessor="name" sliceNumber={3} />;
              },
              sortable: false,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.department.table_column_location')}</span>,
              accessor: 'location',
              width: 150,
              Cell: (row) => <div>{this.getLocationNameById(row.original.location)}</div>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.department.table_column_production_department')}</span>,
              accessor: 'is_production_department',
              sortable: false,
              Cell: (row) => (row.value ? <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />),
              width: 110,
              style: {
                alignItems: 'center',
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              Cell: (row) => (
                <Button disabled={isReadOnly} onClick={() => this.handleEditDepartment(row)}>
                  <IconEdit
                    height="14px"
                    width="14px"
                    fill="#4285F4"
                  />
                </Button>),
              width: 70,
              sortable: false,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              accessor: 'id',
              width: 70,
              sortable: false,
              Cell: (row) => <Button type="delete" disabled={isReadOnly} onClick={(e) => this.handleDeleteModalOpen(e, row.original)} style={{ zIndex: 0 }}>
                <IconRemove width="12px" height="12px" />
              </Button>,
              style: {
                cursor: 'default',
              },
            },
          ]}
          data={departments || []}
          defaultPageSize={30}
          loading={isLoadingDepartments}
          minRows={0}
          noDataText=""
          defaultSorted={[{ id: 'name', desc: true }]}
          onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          showPagination={false}
        />

        <div>
          <span style={{ float: 'right' }}>
            <TableButtons previous={previous} next={next} fetchFunction={this.fetchDataForPagination} count={count} />
          </span>
        </div>

        {
          showModal &&
          <DepartmentModal
            t={t}
            modalState={showModal}
            locationId={locationId}
            departmentData={departmentData}
            handleModalBack={this.handleModalBack}
            prevDepartmentData={prevDepartmentData}
            handleRemoveStep={this.handleRemoveStep}
            handleModalClose={this.handleModalClose}
            handleEditDepartment={this.handleEditDepartment}
            handleStepJsonChange={this.handleStepJsonChange}
            handleDepartmentData={this.handleDepartmentData}
            handleDeleteDepartment={this.handleDeleteDepartment}
            handleInsertNewEmptyStep={this.handleInsertNewEmptyStep}
            handleCreateOrUpdateDepartment={this.handleCreateOrUpdateDepartment}
            title={departmentData.id ? t('page_content.department.edit_technology_button') : t('page_content.department.add_technology_button')}
          />
        }

        <Modal
          isOpen={deleteModalStatus}
          title={t('page_content.department.table_column_delete')}
          handleDeletePosition="footer"
          handleDelete={() => this.handleDeleteDepartment(departmentModalRow)}
          handleClose={() => this.handleDeleteModalClose()}
        >
          <Table
            style={{ userSelect: 'text' }}
            columns={deleteModalColumnInfo}
            data={[departmentModalRow] || []}
            defaultPageSize={1}
            loading={isLoadingDepartments}
            minRows={0}
            noDataText=""
            showPagination={false}
            sortable={false}
          />
        </Modal>
      </div>
    );
  }
}
DepartmentTab.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(DepartmentTab)));
