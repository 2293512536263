export const isSetsEqual = (a, b) => a.size === b.size && [...a].every((value) => b.has(value));

export const generateAbsolutePath = (url) => `${process.env.NODE_ENV === 'development' ? '' : '/static'}${url}`;

export const isEmptyArray = (arr) => arr && arr.length === 0;

export const isProductionEnv = () => process.env.NODE_ENV === 'production';

export const getCustomDateString = (from, to) => {
  if ((from.hours() !== 0) || (to.hours() !== 0)) {
    return `${from.format('DD.MM.YYYY. HH:mm')} - ${to.format('DD.MM.YYYY. HH:mm')}`;
  }
  return `${from.format('DD.MM.YYYY.')} - ${to.format('DD.MM.YYYY.')}`;
};

export const shiftTimesValidation = (shiftTimes) => {
  const ordersToCheck = [1, 2, 3];
  return ordersToCheck.every((order) => shiftTimes.some((obj) => obj.order === order));
};

export const defaultHeaderFormats = {
  year: {
    long: 'YYYY',
    mediumLong: 'YYYY',
    medium: 'YYYY',
    short: 'YY',
  },
  month: {
    long: 'MMMM YYYY',
    mediumLong: 'MMMM',
    medium: 'MMMM',
    short: 'MM/YY',
  },
  week: {
    long: 'w',
    mediumLong: 'w',
    medium: 'w',
    short: 'w',
  },
  day: {
    long: 'dddd, LL',
    mediumLong: 'dddd, LL',
    medium: 'dd D',
    short: 'D',
  },
  hour: {
    long: 'dddd, LL, HH:00',
    mediumLong: 'L, HH:00',
    medium: 'HH:00',
    short: 'HH',
  },
  minute: {
    long: 'HH:mm',
    mediumLong: 'HH:mm',
    medium: 'HH:mm',
    short: 'mm',
  },
  second: {
    long: 'mm:ss',
    mediumLong: 'mm:ss',
    medium: 'mm:ss',
    short: 'ss',
  },
};
