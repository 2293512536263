import React, { useState, useEffect, useRef } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import api from 'helpers/api';
import { IconRemove, IconEdit, IconSearch } from 'shared/Icons';
import { modalSizes } from 'shared/constants';
import { Modal, Notification, Table, TableButtons, Button, ConfirmationModal } from 'shared';
import '../../style.scss';

const IverpanTapesModal = ({
  selectedCompanyTable,
  handleClose,
  isOpen,
  t,
  isReadOnly,
}) => {
  const debounceTimeoutRef = useRef(null);
  const [currentView, setCurrentView] = useState('table');
  const [query, setQuery] = useState('');
  const [selectedSort, setSelectedSort] = useState('traka');
  const [selectedAscDesc, setSelectedAscDesc] = useState('asc');
  const [deleteData, setDeleteData] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const [tableData, setTableData] = useState({
    isLoading: false,
    data: [],
    count: 0,
    next: null,
    previous: null,
    currentUrl: null,
  });

  const [form, setForm] = useState({
    traka: '',
    program: '',
  });

  const getTable = (endpoint) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let apiFilters = '?limit=15';
    if (query) {
      apiFilters += `&traka=${query}`;
    }

    if (!apiFilters.includes('order_by')) {
      const asc = selectedAscDesc === 'desc' ? '-' : '';
      apiFilters += `&order_by=${asc}${selectedSort}`;
    }

    api.get(`${endpoint}${apiFilters}`)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
          currentUrl: tableData.currentUrl || endpoint,
        });
      })
      .catch(() => {
        setTableData(({
          isLoading: false,
          data: [],
          count: 0,
          next: null,
          previous: null,
          currentUrl: null,
        }));
        return Notification('error', 'An error occurred');
      });
  };

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      if (selectedCompanyTable && selectedCompanyTable.endpoint) {
        getTable(selectedCompanyTable.endpoint);
      }
    }, 300);
  }, [query]);

  useEffect(() => {
    if (selectedCompanyTable && selectedCompanyTable.endpoint) {
      getTable(selectedCompanyTable.endpoint);
    }
  }, [selectedCompanyTable, selectedAscDesc, selectedSort]);

  const onFormChange = (value, key) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const fetchPaginatedTable = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    api.get(`${url}`)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
          currentUrl: url,
        });
      })
      .catch(() => {
        return Notification('error', 'An error occurred');
      });
  };

  const handleCloseModal = () => {
    setForm({ traka: null, program: null });
    setCurrentView('table');
    handleClose();
  };

  const handleEditItem = (row) => {
    setForm({
      id: get(row, 'original.id', null),
      traka: get(row, 'original.traka', ''),
      program: get(row, 'original.program', ''),
    });
    setCurrentView('form');
  };

  const handleShowConfirmationDialog = (row, e) => {
    e.stopPropagation();
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = () => {
    const row = deleteData;
    if (row && row.original && row.original.id && selectedCompanyTable && selectedCompanyTable.endpoint) {
      setTableData((prevState) => ({
        ...prevState,
        isLoading: true,
        data: [],
      }));
      setCurrentView('table');
      api.delete(`${selectedCompanyTable.endpoint}${row.original.id}/`)
        .then(() => {
          getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
          return Notification('success', 'Delete successful', 'Item was successfully deleted.');
        })
        .catch(() => {
          return Notification('error', 'An error occurred');
        })
        .finally(() => setShowConfirmationDialog(false));
    }
  };

  const handleBack = () => {
    setForm({ traka: null, program: null });
    setCurrentView('table');
  };

  const updateItem = () => {
    if (selectedCompanyTable && selectedCompanyTable.endpoint) {
      setTableData((prevState) => ({
        ...prevState,
        isLoading: true,
        data: [],
      }));
      setCurrentView('table');
      if (form && form.id) {
        api.patch(`${selectedCompanyTable.endpoint}${form.id}/`, form)
          .then((res) => {
            handleBack();
            getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
            if (res?.data?.message?.length) {
              return Notification('error', res.data.message);
            }
            return Notification('success', 'Save successful', 'Item was successfully updated.');
          })
          .catch(() => {
            handleBack();
            return Notification('error', 'An error occurred');
          });
      } else {
        api.post(`${selectedCompanyTable.endpoint}`, form)
          .then((res) => {
            handleBack();
            getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
            if (res?.data?.message?.length) {
              return Notification('error', res.data.message);
            }
            return Notification('success', 'Save successful', 'Item was successfully updated.');
          })
          .catch(() => {
            handleBack();
            return Notification('error', 'An error occurred');
          });
      }
    }
  };

  const handleSorting = (sortData) => {
    const column = sortData.id;
    setSelectedSort(column);
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
  };

  const handleAddEntryButton = () => {
    setForm({ traka: null, program: null });
    setCurrentView('form');
  };

  const handleClearSearch = () => {
    setQuery('');
  };

  const onFilterChange = (val) => {
    setQuery(val);
  };

  const renderTable = () => {
    return (
      <div style={{ paddingBottom: '30px' }}>
        <div className="custom_data_filters inline_style">
          <div className="custom_data_filters_input">
            <input
              onChange={(e) => onFilterChange(e.target.value)}
              placeholder={t('settings.custom_data.search_by_tape')}
              value={query || ''}
            />
            {query && <button
              onClick={handleClearSearch}
            >&times;</button>}
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <Button
            type="add"
            style={{ margin: '10px' }}
            onClick={handleAddEntryButton}
          >
            {t('settings.custom_data.add_entry')}
          </Button>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('settings.custom_data.tape')}</span>,
              accessor: 'traka',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.program')}</span>,
              accessor: 'program',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              Cell: (row) => <Button disabled={isReadOnly} className="remove-btn" onClick={(e) => handleEditItem(row, e)}><IconEdit width="12px" height="12px" /></Button>,
              width: 70,
            },
            {
              Header: () => '',
              Cell: (row) => <Button type="delete" disabled={isReadOnly} className="remove-btn" onClick={(e) => handleShowConfirmationDialog(row, e)}><IconRemove width="12px" height="12px" /></Button>,
              width: 70,
            },
          ]}
          data={tableData.data || []}
          defaultPageSize={15}
          loading={tableData.isLoading}
          minRows={0}
          noDataText=""
          selectedRow={null}
          defaultSorted={[{ id: 'traka', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons
              previous={tableData.previous}
              next={tableData.next}
              fetchFunction={fetchPaginatedTable}
              count={tableData.count}
            />
          </span>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="default-form">
        <Button onClick={handleBack}>{t('settings.custom_data.back')}</Button>

        <div className="custom_data_modal_container">
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.tape')}:</div>
            <div className="right_input">
              <input type="text" value={form.traka || ''} onChange={(e) => onFormChange(e.target.value, 'traka')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.program')}:</div>
            <div className="right_input">
              <input type="text" value={form.program || ''} onChange={(e) => onFormChange(e.target.value, 'program')} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      size={modalSizes.full}
      handleClose={handleCloseModal}
      handleSave={currentView === 'form' ? updateItem : null}
      isOpen={isOpen}
      title={get(selectedCompanyTable, 'name', '')}
    >
      {currentView === 'table' && renderTable()}
      {currentView === 'form' && renderForm()}
      <ConfirmationModal
        itemName={`${get(deleteData, 'original.traka')} | ${get(deleteData, 'original.program')}`}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleDeleteItem}
        type="warning"
      />
    </Modal>
  );
};

IverpanTapesModal.propTypes = {
  selectedCompanyTable: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

export default withTranslation()(IverpanTapesModal);
