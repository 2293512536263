export const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    width: '200px',
    minHeight: '34px',
    height: '36px',
    padding: 0,
    fontSize: '13px',
    color: '#555',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '34px',
    padding: '0px 0px 0px 5px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0px 3px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    paddingRight: 10,
    color: 'black',
    svg: {
      width: '15px',
      height: '15px',
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: 200,
    borderRadius: 0,
    zIndex: 2,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '13px',
    fontWeight: 500,
    padding: '6px 12px',
  }),
};

export const selectModalStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    width: '100%',
    minHeight: '34px',
    height: '36px',
    padding: 0,
    fontSize: '13px',
    color: '#555',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '34px',
    padding: '0px 0px 0px 5px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0px 3px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    paddingRight: 10,
    color: 'black',
    svg: {
      width: '15px',
      height: '15px',
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: 0,
    zIndex: 2,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '13px',
    fontWeight: 500,
    padding: '6px 12px',
  }),
};

export const createOrderCustom = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    width: '96%',
    minHeight: '34px',
    height: '34px',
    padding: 0,
    fontSize: '13px',
    color: '#555',
    border: '0.5px solid #eee',
    marginLeft: 5,
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '34px',
    padding: '0px 0px 0px 5px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0px 3px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    paddingRight: 10,
    color: 'black',
    svg: {
      width: '15px',
      height: '15px',
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: 0,
    zIndex: 2,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '13px',
    fontWeight: 500,
    padding: '6px 12px',
  }),
};

export const multiSelectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    minWidth: '200px',
    minHeight: '34px',
    height: '36px',
    padding: 0,
    fontSize: '13px',
    color: '#555',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '34px',
    overflowY: 'scroll',
    padding: '0px 0px 0px 5px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0px 3px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    paddingRight: 10,
    color: 'black',
    svg: {
      width: '15px',
      height: '15px',
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: 200,
    borderRadius: 0,
    zIndex: 2,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '13px',
    fontWeight: 500,
    padding: '6px 12px',
  }),
};

export const multiSelectStylesAutoHeight = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    minWidth: '200px',
    minHeight: '34px',
    padding: 0,
    fontSize: '13px',
    color: '#555',
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: 34,
    maxHeight: 120,
    height: 'fit-content',
    padding: '0px 0px 0px 5px',
    overflowY: 'scroll',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0px 3px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    paddingRight: 10,
    color: 'black',
    svg: {
      width: '15px',
      height: '15px',
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    zIndex: 2,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '13px',
    fontWeight: 500,
    padding: '6px 12px',
  }),
};

export const multiSelectFixedOptionsAutoHeight = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    minWidth: '200px',
    minHeight: '34px',
    padding: 0,
    fontSize: '13px',
    color: '#555',
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: 34,
    maxHeight: 120,
    height: 'fit-content',
    padding: '0px 0px 0px 5px',
    overflowY: 'scroll',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0px 3px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    paddingRight: 10,
    color: 'black',
    svg: {
      width: '15px',
      height: '15px',
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: 0,
    zIndex: 2,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '13px',
    fontWeight: 500,
    padding: '6px 12px',
  }),
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

export const multiSelectFixedOptionsAutoHeightReadonly = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    minWidth: '200px',
    minHeight: '34px',
    padding: 0,
    fontSize: '13px',
    color: '#555',
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: 34,
    maxHeight: 120,
    height: 'fit-content',
    padding: '0px 0px 0px 5px',
    overflowY: 'scroll',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0px 3px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    paddingRight: 10,
    color: 'black',
    svg: {
      width: '15px',
      height: '15px',
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: '100%',
    borderRadius: 0,
    zIndex: 2,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '13px',
    fontWeight: 500,
    padding: '6px 12px',
  }),
  multiValue: (base) => {
    return { ...base, backgroundColor: 'gray' };
  },
  multiValueLabel: (base) => {
    return { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 };
  },
  multiValueRemove: (base) => {
    return { ...base, display: 'none' };
  },
};
