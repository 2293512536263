import React, { Component } from 'react';
import { IconQuestion } from 'shared/Icons';
import { withTranslation } from 'react-i18next';
import './styles.scss';

const helpMenuDomClass = 'help-menu';
class HelpMenu extends Component {
  constructor() {
    super();
    this.state = {
      isOpened: false,
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(e) {
    if (e.target.closest(`.${helpMenuDomClass}`) === null) {
      this.setState({ isOpened: false });
      document.removeEventListener('mousedown', this.handleClickOutside, false);
    }
  }

  handleOpen() {
    this.setState({ isOpened: true });
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  render() {
    const {
      isOpened,
    } = this.state;

    // eslint-disable-next-line react/prop-types
    const { t } = this.props;

    return (
      <div className={`${helpMenuDomClass} header-link`}>
        <div
          className="link"
          onClick={this.handleOpen}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
        >
          <IconQuestion
            height="34px"
            width="34px"
          />
        </div>
        {
          isOpened &&
          <ul>
            {
              false &&
              <li>
                <a href="https://ascalia.kwiki.io" target="_blank" rel="noopener noreferrer">
                  Read documentation
                </a>
              </li>
            }
            <li>
              <a href="mailto:info@ascalia.io">
                {t('top_header.help_menu.contact_us')}
              </a>
            </li>
          </ul>
        }
      </div>
    );
  }
}

export default withTranslation()(HelpMenu);
