/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props} viewBox="0 0 35 35">
  <svg>
    <g>
      <path
        fill="#828282"
        d="M1.512,28H19.5c0.827,0,1.5-0.673,1.5-1.5v-19c0-0.023-0.01-0.043-0.013-0.065
		c-0.003-0.022-0.007-0.041-0.013-0.062c-0.023-0.086-0.06-0.166-0.122-0.227l-6.999-6.999c-0.061-0.061-0.141-0.098-0.227-0.122
		c-0.021-0.006-0.04-0.01-0.062-0.013C13.543,0.01,13.523,0,13.5,0H1.506C0.676,0,0,0.673,0,1.5v25C0,27.327,0.678,28,1.512,28z
		 M14,1.707L19.293,7H14.5C14.225,7,14,6.776,14,6.5V1.707z M1,1.5C1,1.224,1.227,1,1.506,1H13v5.5C13,7.327,13.673,8,14.5,8H20
		v18.5c0,0.276-0.225,0.5-0.5,0.5H1.512C1.229,27,1,26.776,1,26.5V1.5z"
      />
      <path fill="#828282" d="M4.5,12h12c0.276,0,0.5-0.224,0.5-0.5S16.776,11,16.5,11h-12C4.224,11,4,11.224,4,11.5S4.224,12,4.5,12z" />
      <path fill="#828282" d="M4.5,16h12c0.276,0,0.5-0.224,0.5-0.5S16.776,15,16.5,15h-12C4.224,15,4,15.224,4,15.5S4.224,16,4.5,16z" />
      <path fill="#828282" d="M4.5,8h5C9.776,8,10,7.776,10,7.5S9.776,7,9.5,7h-5C4.224,7,4,7.224,4,7.5S4.224,8,4.5,8z" />
      <path fill="#828282" d="M4.5,20h12c0.276,0,0.5-0.224,0.5-0.5S16.776,19,16.5,19h-12C4.224,19,4,19.224,4,19.5S4.224,20,4.5,20z" />
      <path fill="#828282" d="M4.5,24h12c0.276,0,0.5-0.224,0.5-0.5S16.776,23,16.5,23h-12C4.224,23,4,23.224,4,23.5S4.224,24,4.5,24z" />
      <path
        fill="#828282"
        d="M21.5,5H26v5.5c0,0.827,0.673,1.5,1.5,1.5H33v18.5c0,0.276-0.225,0.5-0.5,0.5H14.512
		C14.229,31,14,30.776,14,30.5v-1c0-0.276-0.224-0.5-0.5-0.5S13,29.224,13,29.5v1c0,0.827,0.678,1.5,1.512,1.5H32.5
		c0.827,0,1.5-0.673,1.5-1.5v-19c0-0.023-0.01-0.043-0.013-0.065c-0.003-0.022-0.007-0.041-0.013-0.062
		c-0.023-0.086-0.06-0.166-0.122-0.227l-6.999-6.999c-0.061-0.062-0.142-0.099-0.228-0.122c-0.021-0.006-0.039-0.009-0.061-0.012
		C26.543,4.01,26.523,4,26.5,4h-5C21.224,4,21,4.224,21,4.5S21.224,5,21.5,5z M27.5,11c-0.275,0-0.5-0.224-0.5-0.5V5.707L32.293,11
		H27.5z"
      />
      <path fill="#828282" d="M23.5,16h6c0.276,0,0.5-0.224,0.5-0.5S29.776,15,29.5,15h-6c-0.276,0-0.5,0.224-0.5,0.5S23.224,16,23.5,16z" />
      <path fill="#828282" d="M23.5,20h6c0.276,0,0.5-0.224,0.5-0.5S29.776,19,29.5,19h-6c-0.276,0-0.5,0.224-0.5,0.5S23.224,20,23.5,20z" />
      <path fill="#828282" d="M23.5,24h6c0.276,0,0.5-0.224,0.5-0.5S29.776,23,29.5,23h-6c-0.276,0-0.5,0.224-0.5,0.5S23.224,24,23.5,24z" />
      <path fill="#828282" d="M23.5,28h6c0.276,0,0.5-0.224,0.5-0.5S29.776,27,29.5,27h-6c-0.276,0-0.5,0.224-0.5,0.5S23.224,28,23.5,28z" />
    </g>
  </svg>
</Base>);
