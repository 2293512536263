/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
// import { IconRemove } from 'shared/Icons';
import update from 'immutability-helper';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Button, AdvancedColorPicker, AssetPicker, MetricGridPicker } from 'shared';
import { colors } from 'shared/colors';
import { IconInfo } from 'shared/Icons';
// import DatapointForm from '../DatapointForm';
import { defaultSettings } from '../constants';
import '../styles.scss';
import { usingVariablesTooltip } from '../../../constants';

const defaultColors = [colors.red, colors.yellow, colors.green];

const setFallbackColors = (settings) => {
  if (!settings) {
    return settings; // Settings not loaded
  }
  if (settings.colors) {
    return settings; // Colors exist
  }
  const fallbackColors = [colors.red];
  const thresholdsArray = settings.threshold.split(',').filter((t) => !!t);
  while (thresholdsArray.length + 1 > fallbackColors.length) {
    const index = Math.min(fallbackColors.length, 2);
    fallbackColors.push(defaultColors[index]);
  }
  return update(settings, {
    colors: {
      $set: fallbackColors,
    },
  });
};

const setFallbackAppearance = (settings) => {
  if (!settings) {
    return settings; // Settings not loaded
  }
  if (settings.appearance) {
    return settings; // Colors exist
  }

  const appearanceData = {
    title: 'left',
    backgroundColor: 'white',
  };
  return update(settings, {
    appearance: {
      $set: { ...appearanceData },
    },
  });
};

class TableRangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asset: null,
      id: props.config.id,
      widgetData: props.config.widget_data,
      settings: props.config.settings ? (setFallbackColors(props.config.settings), setFallbackAppearance(props.config.settings)) : defaultSettings.tablerange,
      title: props.config.title || '',
      type: props.config.type,
    };
    this.saveWidget = this.saveWidget.bind(this);
    this.addWidgetDataItem = this.addWidgetDataItem.bind(this);

    this.handleDecimalsChange = this.handleDecimalsChange.bind(this);
    this.handleUnitChange = this.handleUnitChange.bind(this);
  }

  componentDidMount() {
    if (this.state.widgetData.length > 0 && this.state.asset === null) {
      this.updateAsset(this.state.widgetData[0].metric.asset);
    }
  }

  updateAsset = (asset) => {
    this.setState({
      asset,
    });
  }

  addWidgetDataItem(metricObj) {
    const widgetData = this.state.widgetData.slice(0);
    widgetData.push({
      drone: null,
      asset: null,
      metric: metricObj.id,
      field: null,
      operation: 'last',
      group_by: 'auto',
      style: {
        selectedField: null,
      },
    });
    this.setState({ widgetData });
  }

  handleThresholdChange = (e) => {
    const { settings } = this.state;
    const thresholds = e.target.value;
    const clrs = settings.colors;
    const thresholdsArray = thresholds.split(',').filter((t) => !!t);

    if (thresholdsArray.length + 1 > clrs.length) {
      while (thresholdsArray.length + 1 > clrs.length) {
        clrs.push(defaultColors[Math.min(clrs.length, 2)]);
      }
    } else if (thresholdsArray.length + 1 < clrs.length) {
      clrs.splice(1 + thresholdsArray.length);
    }
    this.setState({
      settings: update(settings, {
        threshold: {
          $set: thresholds,
        },
        colors: {
          $set: clrs,
        },
      }),
    });
  }

  handleColorChange(i, color) {
    const { settings } = this.state;
    const clrs = settings.colors;
    clrs[i] = color;
    this.setState({
      settings: update(settings, {
        colors: {
          $set: clrs,
        },
      }),
    });
  }

  handleDecimalsChange(evt) {
    const { settings } = this.state;
    settings.unit_decimals = evt.target.value;
    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  handleUnitChange(evt) {
    const { settings } = this.state;
    settings.unit_text = evt.target.value;
    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  saveWidget() {
    // eslint-disable-next-line
    const data = {
      asset: this.state.asset,
      widget_data: this.state.widgetData,
      id: this.state.id,
      title: this.state.title,
      settings: this.state.settings,
    };
    // eslint-disable-next-line
    this.props.onSave(data);
  }

  render() {
    const {
      asset,
      widgetData,
      title,
      settings,
      type,
    } = this.state;

    const { locationId, t } = this.props;

    return (
      <div className="default-form">
        <table className="widget-title">
          <tr>
            <td>
              <label htmlFor="widget-title">
                {t('page_content.dashboards.edit_widget_modal.widget_title')}
              </label>
            </td>
            <td style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <input
                id="widget-title"
                onChange={(e) => this.setState({ title: e.target.value })}
                placeholder={t('page_content.dashboards.edit_widget_modal.widget_title_placeholder')}
                type="text"
                value={title}
              />
              <Tooltip
                id="tooltip-zoom"
                trigger={['hover']}
                placement="left"
                overlay={usingVariablesTooltip(t)}
                overlayClassName="where-filter-tooltip"
              >
                <span aria-describedby="tooltip-zoom">
                  <IconInfo color="#2e86de" height="15px" width="17px" />
                </span>
              </Tooltip>
            </td>
          </tr>
        </table>
        <Tabs>
          <TabList>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.datapoints')}
            </Tab>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.appearance')}
            </Tab>
          </TabList>
          <TabPanel>
            <AssetPicker
              changeAsset={(val) => this.setState({ asset: val })}
              resetMetric={() => this.setState({ asset: null })}
              // disabled={disabled}
              asset={asset}
              locationId={locationId}
            />
            <MetricGridPicker
              updateField={(field, val) => {
                settings[field] = val;
                this.setState({ settings });
              }}
              changeMetrics={(metrics) => {
                this.setState({ widgetData: [] }, () => {
                  if (metrics.length > 0) {
                    metrics.forEach((metric) => {
                      this.addWidgetDataItem(metric);
                    });
                  }
                });
              }}
              // disabled={disabled}
              settings={settings || {}}
              asset={!!asset && typeof asset === 'object' ? asset.id : asset}
              type={type}
            />
          </TabPanel>
          <TabPanel>
            <table>
              <tbody>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.threshold')}
                  </td>
                  <td>
                    <input
                      onChange={this.handleThresholdChange}
                      placeholder="Enter thresholds separated with comma"
                      type="text"
                      value={settings.threshold}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.use_custom_colors')}
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          customColors: {
                            $set: e.target.checked,
                          },
                        }),
                      })}
                      checked={settings.customColors}
                    />
                  </td>
                </tr>
                {
                  settings.customColors &&
                  <tr>
                    <td>
                      {t('page_content.dashboards.edit_widget_modal.appearance_tab.colors')}
                    </td>
                    <td>
                      <div>
                        <AdvancedColorPicker
                          color={settings.colors[0]}
                          onColorUpdate={(color) => this.handleColorChange(0, color)}
                        />
                        {
                          settings.colors.map((x, i) => {
                            if (i === 0) {
                              return null;
                            }
                            return (
                              <AdvancedColorPicker
                                key={i}
                                color={settings.colors[i]}
                                onColorUpdate={(color) => this.handleColorChange(i, color)}
                              />
                            );
                          })
                        }
                      </div>
                    </td>
                  </tr>
                }
                <tr>
                  <td>
                    Decimals
                  </td>
                  <td>
                    <input
                      onKeyUp={this.handleDecimalsChange}
                      placeholder="Enter decimals"
                      type="text"
                      defaultValue={settings.unit_decimals}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Unit
                  </td>
                  <td>
                    <input
                      onKeyUp={this.handleUnitChange}
                      placeholder="Enter unit"
                      type="text"
                      defaultValue={settings.unit_text}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

          </TabPanel>
        </Tabs>
        <footer className="modal-footer">
          <Button
            onClick={this.saveWidget}
            title="Save widget config"
            type="success"
            disabled={!widgetData.length}
          >
            {t('page_content.dashboards.edit_widget_modal.save_changes_button')}
          </Button>
          <Button
            onClick={this.props.closeModal}
          >
            {t('page_content.dashboards.edit_widget_modal.cancel_button')}
          </Button>
        </footer>
      </div>
    );
  }
}

TableRangeForm.propTypes = {
  locationId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(TableRangeForm);
