/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Button } from 'shared';
import { defaultSettings } from '../constants';
import '../styles.scss';

class LabelForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.config.id,
      settings: props.config.settings ? props.config.settings : defaultSettings.label,
    };
  }

  changeLabel = (labelText) => {
    const settings = { ...this.state.settings };

    settings.labelText = labelText || [];

    this.setState({ settings });
  }

  saveWidget = () => {
    const data = {
      widget_data: [],
      id: this.state.id,
      title: '',
      settings: this.state.settings,
    };
    this.props.onSave(data);
  }

  render() {
    const { t } = this.props;
    const {
      settings,
    } = this.state;

    return (
      <div className="default-form">
        <Tabs>
          <TabList>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.datapoints')}
            </Tab>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.appearance')}
            </Tab>
          </TabList>
          <TabPanel>
            <div className="datapoint-form">
              <table>
                <tbody>
                  <tr>
                    <td>
                      {t('page_content.dashboards.edit_widget_modal.widget_title')}:
                    </td>
                    <td>
                      <input
                        id="label-text"
                        onChange={(e) => this.changeLabel(e.target.value)}
                        placeholder={t('page_content.dashboards.edit_widget_modal.widget_title_placeholder')}
                        type="text"
                        value={settings.labelText || ''}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </TabPanel>
          <TabPanel>
            <table className="widget-extras">
              <tbody>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.show_divider')}
                  </td>
                  <td>
                    <input
                      checked={settings.appearance.show_divider}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          appearance: {
                            show_divider: {
                              $set: !settings.appearance.show_divider,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
        <footer className="modal-footer">
          <Button
            onClick={this.saveWidget}
            title="Save widget config"
            type="success"
          >
            {t('page_content.dashboards.edit_widget_modal.save_changes_button')}
          </Button>
          <Button
            onClick={this.props.closeModal}
          >
            {t('page_content.dashboards.edit_widget_modal.cancel_button')}
          </Button>
        </footer>
      </div>
    );
  }
}

LabelForm.propTypes = {
  id: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(LabelForm);
