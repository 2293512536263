// eslint-disable-next-line import/prefer-default-export
export const getRectColor = (state) => {
  switch (state) {
    case 'ok':
      return 'rgb(122, 189, 126)';
    case 'some':
      return 'rgb(255, 167, 86)';
    case 'nok':
      return 'rgb(255, 105, 97)';
    default:
      return 'rgb(220, 219, 219)';
  }
};

export const getTooltipStatusColor = (state) => {
  switch (state) {
    case 'ok':
      return 'rgb(82, 139, 86)';
    case 'some':
      return 'rgb(204, 126, 47)';
    case 'nok':
      return 'rgb(204, 74, 66)';
    default:
      return 'rgb(180, 179, 179)';
  }
};
