import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { get } from 'lodash';

import { IconRemove, IconPlus } from 'shared/Icons';
import { selectStyles } from 'styles/modules/reactSelect';

const SelectQuestion = ({
  t,
  question,
  index,
  selectInputValue,
  handleMultiSelect,
  handleAddSelectAnswer,
  handleRemoveSelectOption,
  handleNewSelectValueInputChange,
}) => {
  return (
        <div>
          <span>{t('page_content.human_resources.forms.select_answers_dropdown_placeholder')}:</span>
            <div className="question_select_inputs">
              <input
                placeholder={t('page_content.human_resources.forms.select_answers_input_placeholder')}
                value={selectInputValue?.label || ''}
                onChange={(e) => handleNewSelectValueInputChange(e, index)}
              />
              <div className="icon_box" onClick={() => handleAddSelectAnswer(index)}><IconPlus width="16px" height="16px" /></div>
            </div>
            <div className={`answer_list ${!question?.answers?.length && 'no-options-border'}`}>
              <Select
                options={question?.answers}
                getOptionLabel={(answer) => <div className="select_label_style">{answer.label}
                    <div onClick={(e) => handleRemoveSelectOption(e, index, answer)}>
                      <IconRemove color="#364252" height="14px" width="14px" />
                    </div>
                  </div>}
                getOptionValue={(answer) => answer.label}
                value=""
                menuPosition="fixed"
                placeholder={t('page_content.human_resources.forms.choice_answers_label')}
                styles={selectStyles}
              />
              <div className="is_multiple_choice_box">
                <div className={`answer_required_container ${question?.multi_select ? 'answer_required_container-checked' : 'answer_required_container-unchecked'}`}>
                  {t('page_content.human_resources.forms.select_is_multiple_choice')}
                  <div className="checkbox-style">
                    <input type="checkbox" checked={get(question, 'multi_select', false)} onChange={() => handleMultiSelect(index)} />
                  </div>
                </div>
              </div>
            </div>
        </div>
  );
};

SelectQuestion.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  index: PropTypes.number,
  handleNewSelectValueInputChange: PropTypes.func,
  handleAddSelectAnswer: PropTypes.func,
  handleRemoveSelectOption: PropTypes.func,
  handleMultiSelect: PropTypes.func,
  selectInputValue: PropTypes.object,
};

export default withTranslation()(SelectQuestion);
