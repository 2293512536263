/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Button, ContentLoader } from 'shared';
import { IconWarning } from 'shared/Icons';
import { colors } from 'shared/colors';
import Select from 'react-select';
import api from 'helpers/api';
import { get } from 'lodash';
import { defaultSettings } from '../constants';
import '../styles.scss';

class OrdersTableForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      id: props.config.id,
      settings: props.config.settings ? props.config.settings : defaultSettings.orderstable,
      title: props.config.title || '',
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;
    api.get(`/api/v1/industry/assets/?location=${locationId}&order_by=name&limit=9999&company=${companyId}`)
      .then((json) => {
        this.setState({
          isLoading: false,
          options: get(json, 'data.results'),
        });
      });
  }

  changeAsset = (asset) => {
    const settings = { ...this.state.settings };

    settings.assets_in = asset || [];

    this.setState({ settings });
  }

  changeColumn = (column) => {
    const settings = { ...this.state.settings };

    settings.columns = column || [];

    this.setState({ settings });
  }

  saveWidget = () => {
    const data = {
      widget_data: [],
      id: this.state.id,
      title: this.state.title,
      settings: this.state.settings,
    };
    this.props.onSave(data);
  }

  render() {
    const { t } = this.props;
    const {
      title,
      options,
      settings,
      isLoading,
    } = this.state;

    const customStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: '0',
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: '0',
      }),
    };

    const columns_options = [
      { id: 'external_id', name: t('page_content.dashboards.orders_table.id') },
      { id: 'customer_name', name: t('page_content.dashboards.orders_table.name') },
      { id: 'lines', name: t('page_content.dashboards.orders_table.lines') },
      { id: 'status', name: t('page_content.dashboards.orders_table.status') },
      { id: 'items_done', name: t('page_content.dashboards.orders_table.items_done') },
      { id: 'items_total', name: t('page_content.dashboards.orders_table.items_total') },
    ];

    if (isLoading) {
      return <ContentLoader />;
    }

    return (
      <div className="default-form">
        <table className="widget-title">
          <tbody>
            <tr>
              <td>
                <label htmlFor="widget-title">
                  {t('page_content.dashboards.edit_widget_modal.widget_title')}
                </label>
              </td>
              <td style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <input
                  id="widget-title"
                  onChange={(e) => this.setState({ title: e.target.value })}
                  placeholder={t('page_content.dashboards.edit_widget_modal.widget_title_placeholder')}
                  type="text"
                  value={title}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <Tabs>
          <TabList>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.datapoints')}
            </Tab>
          </TabList>
          <TabPanel>
            <div className="datapoint-form">
              <table>
                <tbody>
                  <tr>
                    <td>
                      {t('page_content.dashboards.edit_widget_modal.assets')}
                    </td>
                    <td>
                      <Select
                        cacheOptions
                        isMulti
                        options={options}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={this.changeAsset}
                        placeholder={t('shared.asset_picker.placeholder')}
                        value={settings.assets_in || null}
                        styles={customStyles}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {t('page_content.dashboards.edit_widget_modal.columns')}
                    </td>
                    <td>
                      <Select
                        cacheOptions
                        isMulti
                        options={columns_options}
                        getOptionLabel={(option) => (columns_options.find((x) => x.id === option.id) ? columns_options.find((x) => x.id === option.id).name : '')}
                        getOptionValue={(option) => option.id}
                        onChange={this.changeColumn}
                        placeholder=""
                        value={settings.columns || null}
                        styles={customStyles}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {
              !settings.assets_in || !settings.assets_in.length || !settings.columns || !settings.columns.length ?
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconWarning color={colors.red} height="16px" width="16px" />
                  <span style={{ fontSize: '13px' }}>{t('page_content.dashboards.edit_widget_modal.error_msg')}</span>
                </div>
                : ''
            }
          </TabPanel>
        </Tabs>
        <footer className="modal-footer">
          <Button
            onClick={this.saveWidget}
            title="Save widget config"
            type="success"
            disabled={!settings.assets_in.length || !settings.columns || !settings.columns.length}
          >
            {t('page_content.dashboards.edit_widget_modal.save_changes_button')}
          </Button>
          <Button
            onClick={this.props.closeModal}
          >
            {t('page_content.dashboards.edit_widget_modal.cancel_button')}
          </Button>
        </footer>
      </div>
    );
  }
}

OrdersTableForm.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(OrdersTableForm);
