import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Select from 'react-select';
import api from 'helpers/api';
import './styles.scss';

class ValueTypePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { companyId } = this.props;
    return api.get(`/api/v1/value_types/?order_by=label&company=${companyId}`)
      .then((json) => this.setState({
        options: get(json, 'data.results'),
      }));
  }

  handleChange(val) {
    this.props.changeValueType(val);
  }

  render() {
    const {
      disabled,
      hasError,
      onBlur,
      valueType,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <Select
        id="value-type-picker"
        isClearable
        cacheOptions
        className={`AscaliaSelect ValueTypePicker value-type-picker ${hasError ? 'value-type-picker__error' : ''}`}
        isDisabled={disabled}
        options={options}
        onBlur={onBlur}
        onChange={this.handleChange}
        getOptionLabel={(option) => `${option.label} - [${option.unit}]`}
        getOptionValue={(option) => option.id}
        placeholder="Select..."
        value={options.find((a) => a.id === valueType)}
      />
    );
  }
}

ValueTypePicker.propTypes = {
  changeValueType: PropTypes.func,
  disabled: PropTypes.any,
  hasError: PropTypes.bool,
  onBlur: PropTypes.func,
  valueType: PropTypes.any,
  companyId: PropTypes.number.isRequired,
};

ValueTypePicker.defaultProps = {
  changeValueType: () => { },
  isChangeable: true,
};

export default ValueTypePicker;
