const lsKey = 'ascalia_ui';

export const lsGet = (lsProperty = '') => {
  const lsData = localStorage.getItem(lsKey);
  let val;
  if (!lsData) {
    return '';
  }
  if (lsData[0] === '{') {
    const lsObj = JSON.parse(lsData);
    if (lsProperty !== '') {
      val = (Object.prototype.hasOwnProperty.call(lsObj, lsProperty))
        ? lsObj[lsProperty]
        : '';
    } else {
      val = lsObj;
    }
    return val;
  }
};

export const lsSet = (lsParams) => {
  const lsData = lsGet() || {};
  localStorage.setItem(lsKey, JSON.stringify(Object.assign(lsData, lsParams)));
};
