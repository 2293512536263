import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { get, sortBy } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, Button, Modal, TableButtons } from 'shared';
import { selectStyles } from 'styles/modules/reactSelect';
import './style.scss';

import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';

import {
  getTechnologySteps,
  getPaginatedTechnologies,
  getTechnologyDepartments,
  getDepartmentTechnologies,
  deleteDepartmentTechnology,
} from './actions';
import { getDepartmentSteps } from '../DepartmentTab/actions';

import TechnologyModal from './components/TechnologyModal/index';

class TechnologyTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: null,
      isLoadingTechnology: true,
      isReadOnly: false,
      previous: null,
      showModal: false,
      next: null,
      technologies: [],
      departments: [],
      technologyData: {
        id: null,
        name: '',
        department: null,
        steps: Array(1).fill(''),
        availableDepartmentSteps: [],
      },
      deleteModalStatus: false,
      technologyModalRow: [],
      count: 0,
      selectedAscDesc: 'asc',
      selectedSort: 'name',
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;
    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToSettingsPage(companyId, locationId);
        } else if (access === 1) {
          this.setState({
            isReadOnly: true,
          });
          this.getTechnology();
          this.getDepartments();
        } else {
          this.getDepartments();
          this.getTechnology();
        }
      });
  }

  componentDidUpdate(prevProp, prevState) {
    if (this.state.query !== prevState.query || this.props.companyId !== prevProp.companyId) {
      this.handleStateQueryChange();
    }
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.getTechnology();
    });
  }

  getDepartments = () => {
    const { locationId } = this.props;

    this.setState({ isLoadingTechnology: true });

    getTechnologyDepartments(locationId, 9999)
      .then((re) => {
        this.setState({
          departments: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingTechnology: false,
        });
      })
      .catch((error) => console.log(error));
  }

  getDepartmentNameById = (departmentId) => {
    const { departments } = this.state;
    const targetDepartment = departments.find((d) => d.id === departmentId);
    if (targetDepartment) {
      return targetDepartment.name;
    }
    return '';
  }

  getTechnology = () => {
    const { locationId } = this.props;
    const { query, selectedAscDesc, selectedSort } = this.state;

    let apiFilters = '';
    if (query) {
      apiFilters += query;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    apiFilters += `&order_by=${asc}${selectedSort}`;
    getDepartmentTechnologies(locationId, apiFilters)
      .then((re) => {
        this.setState({
          technologies: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingTechnology: false,
        });
      })
      .catch((error) => console.log(error));
  }

  handleChange = (value) => {
    this.setState({ query: value });
  }

  handleStateQueryChange = () => {
    const { query, selectedAscDesc, selectedSort } = this.state;
    const { locationId } = this.props;
    this.setState({
      isLoadingTechnology: true,
    });

    let apiFilters = '';
    if (query) {
      apiFilters += query;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    apiFilters += `&order_by=${asc}${selectedSort}`;

    getDepartmentTechnologies(locationId, apiFilters)
      .then((re) => this.setState({
        technologies: get(re, 'data.results'),
        isLoadingTechnology: false,
      }))
      .catch((error) => console.log(error));
  }

  handleClearSearch = () => {
    this.setState({ query: null });
  }

  handleModalClose = () => {
    this.setState({
      showModal: false,
      technologyData: {
        id: null,
        name: '',
        department: null,
        steps: Array(1).fill(''),
        availableDepartmentSteps: [],
      },
    });
  }

  handleModalCloseAndRefetch = () => {
    this.handleModalClose();
    this.handleStateQueryChange();
  }

  handleEditDepartmentTechnology = async (row) => {
    const departmentSteps = get(await getDepartmentSteps(row.department), 'data.results', []);
    const department = { id: row.department, departmentOperations: departmentSteps };

    let availableDepartmentSteps = departmentSteps || [];

    const technologySteps = get(await getTechnologySteps(row.id), 'data.results', []);

    if (technologySteps?.length > 0) availableDepartmentSteps = availableDepartmentSteps.filter((step) => !technologySteps.some((techStep) => techStep.operation === step.id));

    technologySteps.forEach((step) => {
      const operation = departmentSteps.find((op) => op.id === step.operation);
      if (operation) step.operation = operation;
    });

    this.setState({
      technologyData: {
        id: row.id,
        name: row.name,
        initialTechnologySteps: technologySteps,
        steps: technologySteps || Array(1).fill(''),
        availableDepartmentSteps,
        department,
      },
    }, () => {
      this.setState({
        showModal: true,
      });
    });
  }

  handleDeleteTechnology = (id) => {
    const { technologies } = this.state;
    const { locationId } = this.props;
    if (!technologies && !technologies.id) return;
    this.setState({
      isLoadingTechnology: true,
    });

    deleteDepartmentTechnology(id, locationId)
      .then(() => {
        this.setState({
          deleteModalStatus: false,
          technologyModalRow: [],
        }, () => this.getTechnology());
      });
  }

  fetchDataForPagination = (url) => {
    this.setState({
      isLoadingTechnology: true,
    });

    getPaginatedTechnologies(url)
      .then((re) => {
        this.setState({
          technologies: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingTechnology: false,
        });
      })
      .catch((e) => console.error('Error while fetching technologies', e));
  }

  handleDeleteModalOpen = (rowInfo) => {
    this.setState({
      deleteModalStatus: true,
      technologyModalRow: rowInfo,
    });
  }

  handleDeleteModalClose = () => {
    this.setState({
      deleteModalStatus: false,
      technologyModalRow: [],
    });
  }

  render() {
    const { isLoadingTechnology, isReadOnly, previous, next, technologyData, showModal, technologies, departments, query, deleteModalStatus, technologyModalRow, count } = this.state;
    const { t } = this.props;

    const defaultSelectValues = [{ id: null, name: t('page_content.technology.select_placeholder') }, ...departments];

    const deleteModalColumnInfo = [
      {
        Header: () => <span>{t('page_content.technology.table_column_name')}</span>,
        accessor: 'name',
      },
      {
        Header: () => <span>{t('page_content.technology.table_column_department')}</span>,
        accessor: 'department',
        Cell: (row) => <div>{this.getDepartmentNameById(row.original.department)}</div>,
      },
    ];

    let maxSteps = 0;
    technologies.forEach((tech) => {
      if (tech?.steps?.steps?.length > maxSteps) {
        maxSteps = tech.steps.steps.length;
      }
    });

    const dynamicColumns = Array.from({ length: maxSteps }, (_, index) => ({
      Header: () => <span>{`${t('page_content.projects.products_tab.table_column_step')} ${index + 1}`}</span>,
      accessor: 'steps',
      Cell: (row) => {
        const steps = row?.value?.steps || [];
        const operations = get(row, 'original.operations') || [];
        const stepOperationId = steps[index]?.operation;
        const operation = operations.find((op) => op.id === stepOperationId);
        return (
          <div>
            {operation ? operation.name : '-'}
          </div>
        );
      },
      sortable: false,
    }));

    return (
      <div className="department-technology-management-list">
        <div className="table-controls">
          <div className="table-controls__search">
            <div className="table-select">
              <Select
                options={sortBy(defaultSelectValues, [(value) => value.name.toLowerCase()])}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder={t('page_content.technology.select_placeholder')}
                onChange={(value) => { this.handleChange(value.id, 'department'); }}
                value={defaultSelectValues.find((d) => d.id === query)}
                styles={selectStyles}
              />
              <Button onClick={this.handleClearSearch}>
                {t('shared.clear_button')}
              </Button>
            </div>
            <Button
              type="add"
              style={{ height: 'fit-content' }}
              disabled={isReadOnly}
              onClick={() => this.setState({ showModal: true })}
            >
              {t('page_content.technology.add_technology')}
            </Button>
          </div>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.technology.table_column_name')}</span>,
              accessor: 'name',
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.technology.table_column_department')}</span>,
              accessor: 'department',
              Cell: (row) => <div>{this.getDepartmentNameById(row.original.department)}</div>,
              style: {
                cursor: 'default',
              },
            },
            ...dynamicColumns,
          ]}
          data={technologies || []}
          defaultPageSize={30}
          loading={isLoadingTechnology}
          minRows={0}
          enableEdit
          enableDelete
          onEdit={(original) => this.handleEditDepartmentTechnology(original)}
          onDelete={(original) => this.handleDeleteModalOpen(original)}
          isActionsDisabled={isReadOnly}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
        />
        <span style={{ float: 'right' }}>
          <TableButtons previous={previous} next={next} fetchFunction={this.fetchDataForPagination} count={count} />
        </span>

        {
          showModal &&
          <TechnologyModal
            title={technologyData.id ? t('page_content.technology.edit_technology_button') : t('page_content.technology.add_technology_button')}
            handleModalCloseAndRefetch={this.handleModalCloseAndRefetch}
            handleModalClose={this.handleModalClose}
            locationId={this.props.locationId}
            technology={technologyData}
            departments={departments}
            modalState={showModal}
          />
        }

        <Modal isOpen={deleteModalStatus} title={t('page_content.technology.table_column_delete')} handleDeletePosition="footer" handleDelete={() => this.handleDeleteTechnology(technologyModalRow.id)} handleClose={() => this.handleDeleteModalClose()}>
          <Table
            style={{ userSelect: 'text' }}
            columns={deleteModalColumnInfo}
            data={[technologyModalRow] || []}
            defaultPageSize={1}
            loading={isLoadingTechnology}
            minRows={0}
            noDataText=""
            showPagination={false}
            sortable={false}
          />
        </Modal>
      </div>
    );
  }
}
TechnologyTab.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default (withRouter(withTranslation()(TechnologyTab)));
