import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class CompanyRedirect extends React.Component {
  redirect(company, locationId) {
    const { id } = company;
    return <Redirect to={`/${id}/industry/location/${locationId}`} />;
  }

  render() {
    const {
      company,
      sidebarItems,
    } = this.props;

    if (!company) {
      return (
        <div />
      );
    }

    const sidebarItem = sidebarItems && sidebarItems[0];
    if (!sidebarItem) {
      return (
        <div>
          No Locations
        </div>
      );
    }

    return this.redirect(company, sidebarItem.id);
  }
}

const mapStateToProps = (state) => {
  return { ...state.app };
};

CompanyRedirect.propTypes = {
  company: PropTypes.any,
  sidebarItems: PropTypes.array,
};

export default connect(mapStateToProps, null)(CompanyRedirect);
