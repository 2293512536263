import api from 'helpers/api';
import { Notification } from 'shared';
import * as actionTypes from './actionTypes';

export const addOrUpdateDevice = (device) => {
  return {
    type: actionTypes.addOrUpdateDevice,
    payload: device,
  };
};

export const fetchDevices = async (url) => {
  try {
    const res = await api
      .get(`${url}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching devices', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const setDevices = (devices) => {
  return {
    type: actionTypes.setDevices,
    payload: devices,
  };
};
