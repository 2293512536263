import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconDevice } from 'shared/Icons';
import './styles.scss';

class DeviceDrawer extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  handleClickOutside = (e) => {
    if (this.node.contains(e.target) || e.target.classList.contains('rt-td')) {
      return;
    }
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    this.props.handleClose(false);
  }

  handleClose = () => {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    this.props.handleClose(false);
  }

  openModal = () => {
    this.props.openModal(this.props.device.id);
    this.handleClose();
  }

  render() {
    const { device, isReadOnly } = this.props;

    return (
      <div className="info-drawer" ref={(container) => { this.node = container; }}>
        <header>
          <button className="close" onClick={this.handleClose}>
            &times;
          </button>
          <h2>
            <span style={{ marginRight: 5 }}>
              <IconDevice
                color="#444"
                height="20px"
                width="20px"
              />
            </span>
            {device.external_id}
          </h2>
          <p>
            {device.device_type && `${device.device_type.model} [${device.device_type.manufacturer}]`}
          </p>
        </header>
        <main>
          <button
            onClick={this.openModal}
            disabled={isReadOnly}
          >
            Edit device
          </button>
        </main>
      </div>
    );
  }
}

DeviceDrawer.propTypes = {
  device: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default DeviceDrawer;
