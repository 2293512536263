import React from 'react';
import Base from './Base';

export default (props) => (<Base viewBox="0 0 300 300" {...props}>
  <g>
    <path d="M7.337,299.653h284.978c4.053,0,7.338-3.285,7.338-7.337c0-4.052-3.285-7.337-7.338-7.337H14.675V7.337 C14.675,3.286,11.39,0,7.337,0C3.285,0,0,3.286,0,7.337v284.979C0,296.368,3.285,299.653,7.337,299.653z" />
    <path d="M34.878,61.797h179.293c3.039,0,5.502-2.463,5.502-5.502c0-3.039-2.463-5.504-5.502-5.504H34.878 c-3.039,0-5.503,2.465-5.503,5.504C29.375,59.334,31.839,61.797,34.878,61.797z" />
    <path d="M157.44,82.627H60.647c-3.039,0-5.502,2.464-5.502,5.503c0,3.039,2.463,5.503,5.502,5.503h96.793 c3.039,0,5.502-2.464,5.502-5.503C162.942,85.091,160.479,82.627,157.44,82.627z" />
    <path d="M135.072,114.241c0,3.039,2.464,5.502,5.503,5.502h95.457c3.039,0,5.503-2.463,5.503-5.502 c0-3.039-2.464-5.504-5.503-5.504h-95.457C137.536,108.737,135.072,111.202,135.072,114.241z" />
    <path d="M44.188,169.227c0,3.039,2.464,5.503,5.503,5.503h96.797c3.039,0,5.503-2.464,5.503-5.503c0-3.039-2.464-5.503-5.503-5.503 H49.69C46.651,163.724,44.188,166.188,44.188,169.227z" />
    <path d="M87.072,204.709h59.415c3.039,0,5.503-2.464,5.503-5.503c0-3.04-2.464-5.503-5.503-5.503H87.072 c-3.039,0-5.503,2.463-5.503,5.503C81.569,202.246,84.033,204.709,87.072,204.709z" />
    <path d="M148.322,226.948h57.465c3.039,0,5.503-2.465,5.503-5.504c0-3.039-2.464-5.502-5.503-5.502h-57.465 c-3.039,0-5.503,2.463-5.503,5.502C142.819,224.483,145.283,226.948,148.322,226.948z" />
    <path d="M208.976,248.862h55.803c3.039,0,5.504-2.463,5.504-5.502s-2.465-5.504-5.504-5.504h-55.803 c-3.039,0-5.503,2.465-5.503,5.504S205.937,248.862,208.976,248.862z" />
  </g>
</Base>);
