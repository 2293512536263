/* eslint-disable no-promise-executor-return */
import api from 'helpers/api';
import { Notification } from 'shared';

// eslint-disable-next-line
export const saveSortingAndFiltering = (data) => {
  return new Promise((resolve, reject) => {
    return api.put('/api/v1/me/', data)
      .then((res) => {
        if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
          throw new Error('');
        }
        resolve(res.data);
      })
      .catch((error) => {
        Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
        console.log(error);
        reject();
      });
  });
};
