import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Table, Button, Modal, TableLoader, Notification } from 'shared';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import moment from 'moment';
import { ordersCreatedAndUpdated, modalSizes } from 'shared/constants';
import '../styles.scss';
import { getDmsDocumentFiles, importDmsDocumentFiles, approveDmsDocumentFile, deleteDmsDocumentFile } from '../actions';

class DocumentFiles extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      dmsDocumentFilesLoading: false,
      dmsDocumentFiles: [],
      fileUploadLoader: false,
      fileApprovalLoader: false,
      deleteModalStatus: false,
      deleteItemInfo: [],
      documentDeleteLoader: false,
    };
  }

  componentDidMount() {
    this.fetchDmsDocumentFiles();
  }

  getDocumentId = () => {
    const url = window.location.href;
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
  }

  fetchDmsDocumentFiles = () => {
    const documentId = this.getDocumentId();
    this.setState({
      dmsDocumentFilesLoading: true,
    });
    getDmsDocumentFiles(documentId)
      .then((re) => {
        this.setState({
          dmsDocumentFiles: get(re, 'data.results') || [],
          dmsDocumentFilesLoading: false,
        });
      });
  }

  handleUploadWindow = () => {
    this.fileInputRef.current.click();
  }

  checkIfValidFileName = (name) => {
    const { document_type } = this.props.parentDocumentInfo;

    // Check if the new uploaded version is the supported file type
    const documentExtensionArray = document_type.default_extension.includes(',') ? document_type.default_extension.split(',').map((item) => item.trim()) : [document_type.default_extension];
    const currentFileExtension = name.split('.')[1];
    if (!documentExtensionArray.includes(currentFileExtension)) {
      return { valid: false, error: 'Invalid file format' };
    }

    return { valid: true };
  }

  handleFileUpload = (files) => {
    const { refreshDocInfo } = this.props;
    if (files.length === 0) {
      return;
    }
    this.setState({
      fileUploadLoader: true,
    });
    const documentId = this.getDocumentId();
    const file = files[0];
    if (file.name) {
      const validityCheck = this.checkIfValidFileName(file.name);
      if (!validityCheck.valid) {
        this.setState({
          fileUploadLoader: false,
        }, () => {
          Notification('error', validityCheck.error);
        });
        return;
      }
    }
    if (documentId && file) {
      importDmsDocumentFiles(file, documentId)
        .then(() => {
          this.setState({
            fileUploadLoader: false,
          });
          this.fetchDmsDocumentFiles();
          refreshDocInfo();
        });
    }
  }

  render() {
    const { t, parentDocumentInfo, refreshDocInfo } = this.props;
    const { dmsDocumentFilesLoading, dmsDocumentFiles, fileUploadLoader, fileApprovalLoader, deleteModalStatus, deleteItemInfo, documentDeleteLoader } = this.state;

    const tableColumnInfo = [
      {
        Header: () => <span>{t('page_content.dms.tab_document_files.table_column_file_name')}</span>,
        Cell: (row) => (row.original.filename ? row.original.filename : '-'),
      },
      {
        Header: () => <span>{t('page_content.dms.tab_document_files.table_column_revision')}</span>,
        Cell: (row) => (row.original.revision),
      },
      {
        Header: () => <span>{t('page_content.dms.tab_document_files.table_column_is_approved')}</span>,
        Cell: (row) => (row.original.is_approved ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />),
      },
      {
        Header: () => <span>{t('page_content.dms.tab_document_files.table_column_approved_by')}</span>,
        accessor: 'approved_by',
        Cell: (row) => (row.value && (row.value.first_name || row.value.last_name) ? `${row.value.first_name || ''} ${row.value.last_name || ''}` : '-'),
      },
      {
        Header: () => <span>{t('page_content.dms.tab_document_files.table_column_approved_time')}</span>,
        Cell: (row) => (row.original.approved_time ? moment(row.original.approved_time).format(ordersCreatedAndUpdated) : '-'),
      },
    ];

    const tableColumnInteractive = [
      {
        Header: () => <span>{t('page_content.dms.tab_document_files.table_column_download')}</span>,
        Cell: (row) => (row.original.file ? <Button
          onClick={() => {
            const documentUrl = row.original.file;
            const link = document.createElement('a');
            link.href = documentUrl;
            link.target = '_blank';
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
        >{t('page_content.dms.tab_document_files.table_column_download')}</Button> : '-'),
      },
      {
        Header: () => <span>{t('page_content.dms.tab_document_files.table_column_approval')}</span>,
        Cell: (row) => (row.original.id && !row.original.is_approved ? <Button
          className="dms_document_files_approval_button"
          onClick={() => {
            const payload = {
              document_file_id: row.original.id,
            };
            this.setState({
              fileApprovalLoader: true,
            });
            approveDmsDocumentFile(payload)
              .then(() => {
                this.setState({
                  fileApprovalLoader: false,
                });
                this.fetchDmsDocumentFiles();
                refreshDocInfo();
              });
          }}
          disabled={fileApprovalLoader}
          loading={fileApprovalLoader}
        >{t('page_content.dms.tab_document_files.table_row_approve_button')}</Button> : <Button type="success">{t('page_content.dms.tab_document_files.table_row_approve_button_done')}</Button>),
      },
      {
        Header: () => <span>{t('page_content.dms.tab_document_files.table_column_delete')}</span>,
        Cell: (row) => (row.original.id ? <Button
          onClick={() => {
            this.setState({
              deleteModalStatus: true,
              deleteItemInfo: [row.original],
            });
          }}
          type="delete"
        >{t('page_content.dms.tab_document_files.delete_file_button')}</Button> : '-'),
      },
    ];

    return (
      <div className="dms_document_files_container">
        <div className="table_wrapper">
          <div className="parent_doc_table">
            <div className="header">{t('page_content.dms.tab_document_files.document_info_table_title')}</div>
            <div className="row">{`${t('page_content.dms.tab_document_files.document_info_row_name')}:`}<span>{parentDocumentInfo.name}</span></div>
            <div className="row">{`${t('page_content.dms.tab_document_files.document_info_row_type')}:`}<span>{parentDocumentInfo && parentDocumentInfo.document_type && parentDocumentInfo.document_type.name}</span></div>
            <div className="row">{`${t('page_content.dms.tab_document_files.document_info_row_created_by')}:`}<span>{parentDocumentInfo && parentDocumentInfo.user && `${parentDocumentInfo.user.first_name || ''} ${parentDocumentInfo.user.last_name || ''}`}</span></div>
            <div className="row">{`${t('page_content.dms.tab_document_files.document_info_row_updated_at')}:`}<span>{moment(parentDocumentInfo.updated_at).format(ordersCreatedAndUpdated)}</span></div>
            <div className="row">{`${t('page_content.dms.tab_document_files.document_info_row_approved')}:`}<span>{parentDocumentInfo.is_approved ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</span></div>
            <div className="row">{`${t('page_content.dms.tab_document_files.document_info_row_assets')}:`}
              <span>{parentDocumentInfo && parentDocumentInfo.document_type && parentDocumentInfo.document_type.line_based && parentDocumentInfo.assets ? parentDocumentInfo.assets.map((obj) => obj.name).join(', ') : ''}</span></div>
            <div className="row">{`${t('page_content.dms.tab_document_files.document_info_row_folder')}:`}<span>{parentDocumentInfo.folder || ''}</span></div>
          </div>

          {get(parentDocumentInfo, 'document_type.default_extension', '') === 'pdf' && (
            <div className="pdf_preview">
              <div className="header">{t('page_content.dms.tab_document_files.pdf_preview')}</div>
              <iframe
                src={get(parentDocumentInfo, 'files_list[0].file', '')}
                frameBorder={0}
                style={{ width: '100%', height: '88%' }}
                title="PDF Preview"
              />
            </div>
          )}
        </div>

        <div className="dms_document_files_toolbar_area">
          <div className="toolbar_area_upload_button">
            <Button onClick={this.handleUploadWindow} loading={fileUploadLoader} loadingText="Uploading file" disabled={fileUploadLoader}>{t('page_content.dms.tab_document_files.upload_file_button')}</Button>
          </div>
          <input
            type="file"
            ref={this.fileInputRef}
            style={{ display: 'none' }}
            accept=".pdf, .gd, .stp, .dxf"
            onChange={(e) => {
              this.handleFileUpload(e.target.files);
              e.target.value = '';
            }}
          />
        </div>
        <Table
          style={{ userSelect: 'text', overflow: 'auto' }}
          columns={[...tableColumnInfo, ...tableColumnInteractive]}
          data={dmsDocumentFiles}
          minRows={0}
          defaultPageSize={30}
          noDataText=" "
          showPagination={false}
          sortable={false}
          loading={dmsDocumentFilesLoading}
        />
        <Modal
          title={t('page_content.dms.tab_document_files.delete_modal_title')}
          isOpen={deleteModalStatus}
          size={modalSizes.large}
          handleClose={() => {
            this.setState({
              deleteModalStatus: false,
              deleteItemInfo: [],
            });
          }}
          handleDeletePosition="footer"
          disableDelete={documentDeleteLoader}
          handleDelete={() => {
            this.setState({
              documentDeleteLoader: true,
            });
            const documentId = this.getDocumentId();
            deleteDmsDocumentFile(deleteItemInfo[0].id, documentId)
              .then(() => {
                this.setState({
                  documentDeleteLoader: false,
                  deleteModalStatus: false,
                  deleteItemInfo: [],
                }, () => {
                  this.fetchDmsDocumentFiles();
                  refreshDocInfo();
                });
              });
          }}
        >
          <div className="dms_document_files_delete_modal_container">
            <Table
              style={{ userSelect: 'text' }}
              columns={tableColumnInfo}
              data={deleteItemInfo}
              minRows={0}
              defaultPageSize={30}
              noDataText=" "
              showPagination={false}
              sortable={false}
            />
            {documentDeleteLoader && <div className="dms_document_files_modal_loader_wrapper">
              <TableLoader loading={documentDeleteLoader} />
              <div>{t('page_content.dms.tab_document_files.delete_file_button_progress')}</div>
            </div>}
          </div>
        </Modal>
      </div>
    );
  }
}

DocumentFiles.propTypes = {
  t: PropTypes.func.isRequired,
  parentDocumentInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  refreshDocInfo: PropTypes.func.isRequired,
};

export default (withTranslation()(DocumentFiles));
