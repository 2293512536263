import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import api from 'helpers/api';
import { selectStyles, selectModalStyles } from 'styles/modules/reactSelect';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { IconSearch, IconPopup } from 'shared/Icons';
import { modalSizes, defaultDateTimeFormat } from 'shared/constants';
import { Modal, Notification, Table, TableButtons, Button } from 'shared';
import '../../style.scss';

const IverpanBarcodeModal = ({
  selectedCompanyTable,
  handleClose,
  isOpen,
  t,
}) => {
  const debounceTimeoutRef = useRef(null);
  const [currentView, setCurrentView] = useState('table');
  const [barcode, setBarcode] = useState(null);
  const [selectedAscDesc, setSelectedAscDesc] = useState('desc');
  const [selectedSort, setSelectedSort] = useState('updated_at');

  const [filters, setFilters] = useState({
    query: '',
    search_value: 'order_external_id',
    success: null,
    sent: null,
  });

  const [tableData, setTableData] = useState({
    isLoading: false,
    data: [],
    count: 0,
    next: null,
    previous: null,
    currentUrl: null,
  });

  const [selectedRow, setSelectedRow] = useState(null);
  const [isSelectedRowUpdated, setIsSelectedRowUpdated] = useState(false);

  const getTable = (endpoint) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    let apiFilters = `?&limit=15&order_by=${asc}${selectedSort}`;

    if (filters && filters.search_value && filters.query) {
      apiFilters += `&${filters.search_value}=${filters.query}`;
    }

    if (filters && filters.success) {
      apiFilters += `&success=${filters.success}`;
    }

    if (filters && filters.sent) {
      apiFilters += `&sent=${filters.sent}`;
    }

    api.get(`${endpoint}${apiFilters}`)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
          currentUrl: endpoint,
        });
      })
      .catch(() => {
        setTableData(({
          isLoading: false,
          data: [],
          count: 0,
          next: null,
          previous: null,
          currentUrl: null,
        }));
        return Notification('error', 'An error occurred');
      });
  };

  useEffect(() => {
    if (selectedCompanyTable && selectedCompanyTable.endpoint) {
      getTable(selectedCompanyTable.endpoint);
    }
  }, [selectedCompanyTable, selectedAscDesc, selectedSort]);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      if (selectedCompanyTable && selectedCompanyTable.endpoint) {
        getTable(selectedCompanyTable.endpoint);
      }
    }, 200);
  }, [filters]);

  const onChange = (value, key) => {
    let updatedValue = value;

    if (key === 'created_at' || key === 'updated_at') {
      updatedValue = moment(value).utc().toISOString();
    }

    setSelectedRow({
      ...selectedRow,
      [key]: updatedValue,
    });
    setIsSelectedRowUpdated(true);
  };

  const onFilterChange = (value, key) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const handleClearSearch = () => {
    setFilters({
      ...filters,
      query: '',
    });
  };

  const fetchPaginatedTable = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    api.get(`${url}`)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
          currentUrl: url,
        });
      })
      .catch(() => {
        return Notification('error', 'An error occurred');
      });
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setCurrentView('table');
    setIsSelectedRowUpdated(false);
    handleClose();
  };

  const handleBack = () => {
    setSelectedRow(null);
    setCurrentView('table');
    setIsSelectedRowUpdated(false);
  };

  const updateItem = () => {
    if (selectedCompanyTable && selectedCompanyTable.endpoint) {
      setTableData((prevState) => ({
        ...prevState,
        isLoading: true,
        data: [],
      }));
      setCurrentView('table');
      api.patch(`${selectedCompanyTable.endpoint}${selectedRow.id}/`, selectedRow)
        .then(() => {
          handleBack();
          getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
          return Notification('success', 'Save successful', 'Item was successfully updated.');
        })
        .catch(() => {
          handleBack();
          return Notification('error', 'An error occurred');
        });
    }
  };

  const handleSorting = (sortData) => {
    let sortKey = sortData.id;
    if (sortKey === 'external_id') {
      sortKey = 'order_item_part__order_item__order__external_id';
    }
    if (sortKey === 'order_item_name') {
      sortKey = 'order_item_part__order_item__name';
    }
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
    setSelectedSort(sortKey);
  };

  const openBarcode = (row) => {
    setCurrentView('barcode');
    setBarcode(get(row, 'original.barcode', ''));
  };

  const renderTable = () => {
    const searchFilterOptions = [
      { value: 'order_external_id', name: t('settings.custom_data.external_id') },
      { value: 'barcode_id', name: t('settings.custom_data.barcode') },
      { value: 'snowflake_id', name: t('settings.custom_data.snowflake') },
    ];

    const successFilterOptions = [
      { value: 'true', name: t('settings.custom_data.success') },
      { value: 'false', name: t('settings.custom_data.not_success') },
    ];

    const sentFilterOptions = [
      { value: 'true', name: t('settings.custom_data.sent') },
      { value: 'false', name: t('settings.custom_data.not_sent') },
    ];

    return (
      <div style={{ paddingBottom: '30px' }}>
        <div className="custom_data_filters">
          <div className="custom_data_filters_input">
            <input
              onChange={(e) => onFilterChange(e.target.value, 'query')}
              placeholder={t('settings.custom_data.search_by')}
              value={filters.query || ''}
            />
            {filters.query && <button
              onClick={handleClearSearch}
            >&times;</button>}
            <Select
              options={searchFilterOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              isSearchable={false}
              menuPosition="fixed"
              onChange={(e) => onFilterChange(e.value, 'search_value')}
              value={(searchFilterOptions.find((sOption) => sOption.value === filters.search_value)) || ''}
              styles={selectStyles}
            />
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>

          <div className="dropdown_wrapper">
            <Select
              options={successFilterOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              isSearchable
              isClearable
              menuPosition="fixed"
              onChange={(e) => onFilterChange(e && e.value ? e.value : '', 'success')}
              value={(successFilterOptions.find((option) => option.value === filters.success)) || ''}
              styles={selectModalStyles}
              placeholder={t('settings.custom_data.filter_by_success')}
            />
          </div>

          <div className="dropdown_wrapper">
            <Select
              options={sentFilterOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              isSearchable
              isClearable
              menuPosition="fixed"
              onChange={(e) => onFilterChange(e && e.value ? e.value : '', 'sent')}
              value={(sentFilterOptions.find((option) => option.value === filters.sent)) || ''}
              styles={selectModalStyles}
              placeholder={t('settings.custom_data.filter_by_sent')}
            />
          </div>

        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('settings.custom_data.updated_at')}</span>,
              accessor: 'updated_at',
              Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.snowflake')}</span>,
              accessor: 'snowflake_id',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.barcode_id')}</span>,
              accessor: 'barcode_id',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.barcode')}</span>,
              accessor: 'barcode',
              Cell: (row) => (
                <div onClick={() => openBarcode(row)}>
                  <IconPopup
                    height="28px"
                    width="28px"
                    fill="#4285F4"
                  />
                </div>),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.order_number')}</span>,
              accessor: 'external_id',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.order_item')}</span>,
              accessor: 'order_item_name',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.success')}</span>,
              accessor: 'success',
              Cell: (row) => (row.value ? <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />),
              width: 90,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.sent')}</span>,
              accessor: 'sent',
              Cell: (row) => (row.value ? <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />),
              width: 90,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.message')}</span>,
              accessor: 'message',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
          ]}
          data={tableData.data || []}
          defaultPageSize={15}
          loading={tableData.isLoading}
          minRows={0}
          noDataText=""
          showPagination={false}
          selectedRow={null}
          defaultSorted={[{ id: 'updated_at', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons
              previous={tableData.previous}
              next={tableData.next}
              fetchFunction={fetchPaginatedTable}
              count={tableData.count}
            />
          </span>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="default-form">
        <Button onClick={handleBack}>{t('settings.custom_data.back')}</Button>

        <div className="custom_data_modal_container">
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.order_item_part')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'order_item_part', '')} onChange={(e) => onChange(e.target.value, 'order_item_part')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.barcode')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'barcode', '')} onChange={(e) => onChange(e.target.value, 'barcode')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.success')}:</div>
            <div className="right_checkbox">
              <input type="checkbox" checked={get(selectedRow, 'success', false)} onChange={(e) => onChange(e.target.checked, 'success')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.sent')}:</div>
            <div className="right_checkbox">
              <input type="checkbox" checked={get(selectedRow, 'sent', false)} onChange={(e) => onChange(e.target.checked, 'sent')} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBarcode = () => {
    return (
      <div className="default-form">
        <Button onClick={handleBack}>{t('settings.custom_data.back')}</Button>

        <div className="custom_data_modal_container">
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.barcode')}:</div>
            <div className="right_textarea">
              <textarea type="text" value={barcode || ''} readOnly />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      size={modalSizes.full}
      handleClose={handleCloseModal}
      handleSave={currentView === 'form' ? updateItem : null}
      disableSave={!isSelectedRowUpdated}
      isOpen={isOpen}
      title={get(selectedCompanyTable, 'name', '')}
    >
      {currentView === 'table' && renderTable()}
      {currentView === 'form' && renderForm()}
      {currentView === 'barcode' && renderBarcode()}
    </Modal>
  );
};

IverpanBarcodeModal.propTypes = {
  selectedCompanyTable: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(IverpanBarcodeModal);
