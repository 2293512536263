/* eslint-disable react/no-access-state-in-setstate */
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { SketchPicker } from 'react-color';
import { Button } from 'shared';
import { formatTypes, usingVariablesTooltip } from 'shared/constants';
import { IconRemove, IconInfo } from 'shared/Icons';
import DatapointForm from '../DatapointForm';
import { defaultSettings } from '../constants';
import '../styles.scss';

const colorIndex = [
  '#1abc9c',
  '#2980b9',
  '#e67e22',
  '#e74c3c',
  '#9b59b6',
  '#2ecc71',
  '#34495e',
  '#8e44ad',
  '#2c3e50',
  '#7f8c8d',
  '#c0392b',
  '#d35400',
  '#f39c12',
];

class GanttChartForm extends Component {
  constructor(props) {
    super(props);

    const { config } = props;

    const widget_data = props.config.widget_data;
    const widgetData = widget_data.filter((wD) => !wD.style.isCalculatedValue && !wD.style.isCalculatedValueUsage);
    const calculatedWidgetData = widget_data.filter((wD) => wD.style.isCalculatedValue).map((item) => { item.randId = Math.random(); return item; }) || [];
    const calculatedUsageWidgetData = widget_data.find((wD) => wD.style.isCalculatedValueUsage);

    this.state = {
      id: config.id,
      widgetData,
      calculatedWidgetData,
      calculatedUsageWidgetData,
      settings: config.settings || defaultSettings.gantt,
      title: config.title || '',
      type: config.type,
    };
  }

  addWidgetDataItem = () => {
    const widgetData = this.state.widgetData.slice(0);
    widgetData.push({
      drone: null,
      asset: null,
      metric: null,
      field: null,
      operation: 'avg',
      group_by: 'auto',
      style: {
        selectedField: null,
      },
    });
    this.setState({ widgetData });
  }

  updateWidgetDataItem = (numberInArray, widgetDataItem) => {
    const widgetData = this.state.widgetData.slice(0);
    widgetData[numberInArray] = widgetDataItem;
    this.setState({
      widgetData,
    });
  }

  handleShiftWidgetData = (index, direction) => {
    this.setState((prevState) => {
      const newWidgetData = [...prevState.widgetData];

      if (index >= 0 && index < newWidgetData.length) {
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        if (newIndex >= 0 && newIndex < newWidgetData.length) {
          [newWidgetData[index], newWidgetData[newIndex]] = [
            newWidgetData[newIndex],
            newWidgetData[index],
          ];
        }

        newWidgetData.forEach((entry, i) => {
          if (!('sort_order' in entry)) {
            entry.sort_order = i;
          } else {
            entry.sort_order = newWidgetData.indexOf(entry);
          }
        });
      }

      return {
        ...prevState,
        widgetData: newWidgetData,
      };
    });
  };

  updateAdjustedValue = (val, metricId, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.adjusted_value)) {
      settings.adjusted_value = [];
    }
    const existingValue = settings.adjusted_value.find((aV) => aV.metricId === metricId && aV.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.adjusted_value.push({ value: val, metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateShiftFilter = (val, id, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.shift)) {
      settings.shift = [];
    }
    const existingValue = settings.shift.find((w) => w.id === id && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.shift.push({ value: val, id, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateProductTypeFilter = (val, id, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.productType)) {
      settings.productType = [];
    }
    const existingValue = settings.productType.find((w) => w.id === id && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.productType.push({ value: val, id, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateWhereFilter = (val, metricId, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.where)) {
      settings.where = [];
    }
    const existingValue = settings.where.find((w) => w.metricId === metricId && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.where.push({ value: val, metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateGapFill = (val, metricId, indexData) => {
    const widgetData = this.state.widgetData.slice(0);
    widgetData[indexData].style.gapFill = val;
    this.setState({
      widgetData,
    });
    /*
    const { settings } = this.state;
    if (!settings.gapFill || !Array.isArray(settings.gapFill)) {
      settings.gapFill = [];
    }

    const existingValue = settings.gapFill.find(w => w.metricId === metricId && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.gapFill.push({ value: val, metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
    */
  }

  removeWidgetDataItem = (numberInArray) => {
    const widgetData = this.state.widgetData.slice(0);
    widgetData.splice(numberInArray, 1);
    this.setState({
      widgetData,
    });
  }

  saveWidget = () => {
    const {
      widgetData,
      calculatedWidgetData,
      calculatedUsageWidgetData,
    } = this.state;

    let calcMetricsOK = true;
    if (calculatedWidgetData && calculatedWidgetData.length) {
      calculatedWidgetData.forEach((cWD) => {
        if (!cWD.metric) {
          calcMetricsOK = false;
        }
      });
    }

    if (!calcMetricsOK) {
      alert('All calculated values must have a selected source and metric.');
      return;
    }

    const widget_data = widgetData.map((x) => {
      if (x.metric && x.metric.id) {
        x.metric = x.metric.id;
      }
      if (Object.prototype.hasOwnProperty.call(x, 'field')) {
        delete x.field;
      }
      return x;
    });

    const cleanedCalculatedWidgetData = calculatedWidgetData.map((x) => {
      if (x.metric && x.metric.id) {
        x.metric = x.metric.id;
      }
      if (Object.prototype.hasOwnProperty.call(x, 'field')) {
        delete x.field;
      }
      return x;
    });

    if (calculatedUsageWidgetData) {
      if (Object.prototype.hasOwnProperty.call(calculatedUsageWidgetData, 'field')) {
        delete calculatedUsageWidgetData.field;
      }
      delete calculatedUsageWidgetData.metric;

      widget_data.push(calculatedUsageWidgetData);
    }

    const data = {
      widget_data: widget_data.concat(cleanedCalculatedWidgetData),
      id: this.state.id,
      title: this.state.title,
      settings: this.state.settings,
    };
    this.props.onSave(data);
  }

  updateColor = (color, widgetDataItem) => {
    const newWidgetData = this.state.widgetData.slice(0);

    if (!widgetDataItem.style.isCalculatedValue && !widgetDataItem.style.isCalculatedValueUsage) {
      const objIndex = newWidgetData.findIndex(((obj) => obj.id === widgetDataItem.id));
      newWidgetData[objIndex].style.color = color.rgb;

      this.setState({
        widgetData: newWidgetData,
      });
    } else {
      this.updateCalculatedColor(color);
    }
  }

  updateCalculatedColor = (color) => {
    const newWidgetData = this.state.calculatedUsageWidgetData;

    newWidgetData.style.color = color.rgb;

    this.setState({
      calculatedUsageWidgetData: newWidgetData,
    });
  }

  hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: 1.0,
    } : null;
  }

  updateTextMetric = (val, indexData) => {
    const newWidgetData = this.state.widgetData.slice(0);

    newWidgetData[indexData].style.textMetric = val;
    this.setState({
      widgetData: newWidgetData,
    });
  }

  render() {
    const {
      widgetData,
      calculatedWidgetData,
      calculatedUsageWidgetData,
      settings,
      title,
      type,
    } = this.state;

    const colorWd = [...widgetData, calculatedUsageWidgetData].filter((el) => {
      return el != null;
    });

    const { locationId, t } = this.props;
    const titleSides = ['left', 'center', 'right'];

    const filteredFormatTypes = { ...formatTypes };

    delete filteredFormatTypes.date;

    return (
      <div className="default-form">
        <table className="widget-title">
          <tr>
            <td>
              <label htmlFor="widget-title">
                {t('page_content.dashboards.edit_widget_modal.widget_title')}
              </label>
            </td>
            <td style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <input
                id="widget-title"
                onChange={(e) => this.setState({ title: e.target.value })}
                placeholder={t('page_content.dashboards.edit_widget_modal.widget_title_placeholder')}
                type="text"
                value={title}
              />
              <Tooltip
                id="tooltip-zoom"
                trigger={['hover']}
                placement="left"
                overlay={usingVariablesTooltip(t)}
                overlayClassName="where-filter-tooltip"
              >
                <span aria-describedby="tooltip-zoom">
                  <IconInfo color="#2e86de" height="15px" width="17px" />
                </span>
              </Tooltip>
            </td>
          </tr>
        </table>
        <Tabs>
          <TabList>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.datapoints')}
            </Tab>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.legend')}
            </Tab>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.appearance')}
            </Tab>
          </TabList>
          <TabPanel>
            {
              widgetData
                .sort((a, b) => (a.sort_order || 0) - (b.sort_order || 0))
                .map((widgetDataItem, i) => {
                  const variableMetric = widgetDataItem?.style?.textMetric || widgetDataItem?.metric?.id;
                  const adjustedValue = Array.isArray(settings.adjusted_value) ? settings.adjusted_value.find((aV) => variableMetric && aV.metricId === variableMetric && aV.indexData === i) : null;
                  const shift = Array.isArray(settings.shift) ? settings.shift.find((w) => variableMetric && w.id === variableMetric && w.indexData === i) : null;
                  const productType = Array.isArray(settings.productType) ? settings.productType.find((w) => variableMetric && w.id === variableMetric && w.indexData === i) : null;
                  const where = Array.isArray(settings.where) ? settings.where.find((w) => variableMetric && w.metricId === variableMetric && w.indexData === i) : null;
                  const gapFill = widgetDataItem.style?.gapFill || false;
                  const textMetric = widgetDataItem?.style?.textMetric ? widgetDataItem.style.textMetric : null;

                  return (
                    <div key={`datapoint-${i}`} className="datapoint-container">
                      <DatapointForm
                        type={type}
                        id={i}
                        indexData={i}
                        locationId={locationId}
                        datapoint={widgetDataItem}
                        onChange={(val) => this.updateWidgetDataItem(i, val)}
                        adjustedValue={adjustedValue}
                        updateAdjustedValue={(val, metricId, indexData) => this.updateAdjustedValue(val, metricId, indexData)}
                        shift={shift}
                        updateShiftFilter={(val, id, indexData) => this.updateShiftFilter(val, id, indexData)}
                        productType={productType}
                        updateProductTypeFilter={(val, id, indexData) => this.updateProductTypeFilter(val, id, indexData)}
                        where={where}
                        updateWhereFilter={(val, metricId, indexData) => this.updateWhereFilter(val, metricId, indexData)}
                        gapFill={gapFill}
                        updateGapFill={(val, metricId, indexData) => this.updateGapFill(val, metricId, indexData)}
                        textMetric={textMetric}
                        changeTextMetric={(val, indexData) => this.updateTextMetric(val, indexData)}
                      />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                          onClick={() => this.removeWidgetDataItem(i)}
                          size="small"
                          type="delete"
                        >
                          <IconRemove height="14px" width="14px" />
                        </Button>
                        <Button onClick={() => this.handleShiftWidgetData(i, 'up')}>
                          ↑
                        </Button>
                        <Button onClick={() => this.handleShiftWidgetData(i, 'down')}>
                          ↓
                        </Button>
                      </div>
                    </div>
                  );
                })
            }
            {
              widgetData.length < 10 &&
              <Button
                onClick={this.addWidgetDataItem}
                type="add"
              >
                {t('page_content.dashboards.edit_widget_modal.datapoints_tab.new_datapoint_button')}
              </Button>
            }
          </TabPanel>
          <TabPanel>
            <table>
              <tbody>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.legend_tab.show_legend')}
                  </td>
                  <td>
                    <input
                      checked={settings.legend.show}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          legend: {
                            show: {
                              $set: !settings.legend.show,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table className="widget-extras">
              <tbody>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.one_line_events')}
                  </td>
                  <td>
                    <input
                      checked={settings.appearance.oneline_events}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          appearance: {
                            oneline_events: {
                              $set: !settings.appearance.oneline_events,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.show_grid')}
                  </td>
                  <td>
                    <input
                      checked={settings.appearance.grid}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          appearance: {
                            grid: {
                              $set: !settings.appearance.grid,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.show_tooltip')}
                  </td>
                  <td>
                    <input
                      checked={settings.appearance.tooltip}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          appearance: {
                            tooltip: {
                              $set: !settings.appearance.tooltip,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    <label htmlFor="side">
                      {t('page_content.dashboards.edit_widget_modal.appearance_tab.align_title_text')}
                    </label>
                  </td>
                  <td className="input">
                    <Select
                      id="side"
                      className="AscaliaSelect"
                      onChange={(v) => {
                        const value = v.value;
                        this.setState({
                          settings: update(settings, {
                            appearance: {
                              title: {
                                $set: value,
                              },
                            },
                          }),
                        });
                      }}
                      options={titleSides.map((dP) => ({
                        label: dP,
                        value: dP,
                      }))}
                      value={settings.appearance.title ? titleSides.map((dP) => ({
                        label: dP,
                        value: dP,
                      })).find((dP) => dP.value === settings.appearance.title) : null}
                    />
                  </td>
                </tr>
                {
                  (colorWd && colorWd.length !== 0) ?
                    <tr>
                      <td>
                        {t('page_content.dashboards.edit_widget_modal.appearance_tab.datapoints')}
                      </td>
                      {colorWd.sort((a, b) => { return a.id - b.id; }).map((widgetDataItem, index) => (
                        <tr>
                          <td style={{ width: '50%' }}>{widgetDataItem.label}</td>
                          <td>
                            <SketchPicker
                              color={
                                (widgetDataItem && widgetDataItem.style && widgetDataItem.style.color) ? widgetDataItem.style.color :
                                  widgetDataItem.style && widgetDataItem.style.isCalculatedValueUsage ? '#2ecc71' :
                                    this.hexToRgb(colorIndex[index])
                              }
                              onChangeComplete={(color) => this.updateColor(color, widgetDataItem)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tr> : ''
                }
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
        <footer className="modal-footer">
          <Button
            onClick={this.saveWidget}
            title="Save widget config"
            type="success"
            disabled={!widgetData.length && !calculatedWidgetData.length}
          >
            {t('page_content.dashboards.edit_widget_modal.save_changes_button')}
          </Button>
          <Button
            onClick={this.props.closeModal}
          >
            {t('page_content.dashboards.edit_widget_modal.cancel_button')}
          </Button>
        </footer>
      </div>
    );
  }
}

GanttChartForm.propTypes = {
  widget_data: PropTypes.array,
  locationId: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(GanttChartForm);
