/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import update from 'immutability-helper';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { IconRemove, IconInfo } from 'shared/Icons';
import { Button } from 'shared';
import DatapointForm from '../DatapointForm';
import { defaultSettings } from '../constants';
import '../styles.scss';
import { usingVariablesTooltip } from '../../../constants';

class RealtimeWidgetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.config.id,
      widgetData: props.config.widget_data,
      settings: props.config.settings ? props.config.settings : defaultSettings.realtime_widget,
      title: props.config.title || '',
      type: props.config.type,
    };
    this.saveWidget = this.saveWidget.bind(this);
    this.addWidgetDataItem = this.addWidgetDataItem.bind(this);
  }

  addWidgetDataItem() {
    const widgetData = this.state.widgetData.slice(0);
    widgetData.push({
      drone: null,
      asset: null,
      metric: null,
      field: null,
      operation: 'avg',
      group_by: 'auto',
      style: {
        selectedField: null,
      },
    });
    this.setState({ widgetData });
  }

  updateWidgetDataItem(numberInArray, widgetDataItem) {
    const widgetData = this.state.widgetData.slice(0);
    widgetData[numberInArray] = widgetDataItem;
    this.setState({
      widgetData,
    });
  }

  updateAdjustedValue = (val, metricId) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.adjusted_value)) {
      settings.adjusted_value = [];
    }
    const existingValue = settings.adjusted_value.find((aV) => aV.metricId === metricId);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.adjusted_value.push({ value: val, metricId });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateShiftFilter = (val, id) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.shift)) {
      settings.shift = [];
    }
    const existingValue = settings.shift.find((w) => w.id === id);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.shift.push({ value: val, id });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateProductTypeFilter = (val, id) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.productType)) {
      settings.productType = [];
    }
    const existingValue = settings.productType.find((w) => w.id === id);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.productType.push({ value: val, id });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateWhereFilter = (val, metricId, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.where)) {
      settings.where = [];
    }
    const existingValue = settings.where.find((w) => w.metricId === metricId && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.where.push({ value: val, metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  removeWidgetDataItem(numberInArray) {
    const widgetData = this.state.widgetData.slice(0);
    widgetData.splice(numberInArray, 1);
    this.setState({
      widgetData,
    });
  }

  saveWidget = () => {
    const data = {
      widget_data: this.state.widgetData.map((x) => {
        if (!x.metric) {
          return x;
        }
        x.metric = x.metric.id;
        return x;
      }),
      id: this.state.id,
      title: this.state.title,
      settings: this.state.settings,
    };
    this.props.onSave(data);
  }

  render() {
    const {
      widgetData,
      settings,
      title,
      type,
    } = this.state;

    const { locationId, t } = this.props;

    return (
      <div className="default-form gauge-form">
        <table className="widget-title">
          <tbody>
            <tr>
              <td>
                <label htmlFor="widget-title">
                  {t('page_content.dashboards.edit_widget_modal.widget_title')}
                </label>
              </td>
              <td style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <input
                  id="widget-title"
                  onChange={(e) => this.setState({ title: e.target.value })}
                  placeholder={t('page_content.dashboards.edit_widget_modal.widget_title_placeholder')}
                  type="text"
                  value={title}
                />
                <Tooltip
                  id="tooltip-zoom"
                  trigger={['hover']}
                  placement="left"
                  overlay={usingVariablesTooltip(t)}
                  overlayClassName="where-filter-tooltip"
                >
                  <span aria-describedby="tooltip-zoom">
                    <IconInfo color="#2e86de" height="15px" width="17px" />
                  </span>
                </Tooltip>
              </td>
            </tr>
          </tbody>
        </table>
        <Tabs>
          <TabList>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.datapoints')}
            </Tab>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.appearance')}
            </Tab>
          </TabList>
          <TabPanel>
            {
              widgetData.map((widgetDataItem, i) => (
                <div key={`datapoint-${i}`} className="datapoint-container">
                  <DatapointForm
                    type={type}
                    key={i}
                    locationId={locationId}
                    datapoint={widgetDataItem}
                    onChange={(item) => this.updateWidgetDataItem(i, item)}
                    settings={settings}
                    adjustedValue={Array.isArray(settings.adjusted_value) ? settings.adjusted_value.find((aV) => { if (widgetDataItem.metric) { return aV.metricId === widgetDataItem.metric.id; } return false; }) : null}
                    updateAdjustedValue={(val, metricId) => this.updateAdjustedValue(val, metricId)}
                    shift={Array.isArray(settings.shift) ? settings.shift.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id; } return false; }) : null}
                    updateShiftFilter={(val, id) => this.updateShiftFilter(val, id)}
                    productType={Array.isArray(settings.productType) ? settings.productType.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id; } return false; }) : null}
                    updateProductTypeFilter={(val, id) => this.updateProductTypeFilter(val, id)}
                    where={Array.isArray(settings.where) ? settings.where.find((w) => { if (widgetDataItem.metric) { return w.metricId === widgetDataItem.metric.id && w.indexData === i; } return false; }) : null}
                    updateWhereFilter={(val, metricId, indexData) => this.updateWhereFilter(val, metricId, indexData)}
                  />
                  <Button
                    onClick={() => this.removeWidgetDataItem(i)}
                    size="small"
                    type="delete"
                  >
                    <IconRemove
                      height="14px"
                      width="14px"
                    />
                  </Button>
                </div>
              ))
            }
            {
              widgetData.length < 2 &&
              <Button
                onClick={this.addWidgetDataItem}
                type="add"
              >
                {t('page_content.dashboards.edit_widget_modal.datapoints_tab.new_datapoint_button')}
              </Button>
            }
          </TabPanel>
          <TabPanel>
            <table className="widget-extras">
              <tbody>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.display_inactivity_after')}
                  </td>
                  <td>
                    <input
                      onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          inactivity: {
                            $set: e.target.value,
                          },
                        }),
                      })}
                      placeholder="Enter value"
                      type="number"
                      value={settings.inactivity}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
        <footer className="modal-footer">
          <Button
            onClick={this.saveWidget}
            title="Save widget config"
            type="success"
            disabled={!widgetData.length}
          >
            {t('page_content.dashboards.edit_widget_modal.save_changes_button')}
          </Button>
          <Button
            onClick={this.props.closeModal}
          >
            {t('page_content.dashboards.edit_widget_modal.cancel_button')}
          </Button>
        </footer>
      </div>
    );
  }
}

RealtimeWidgetForm.propTypes = {
  locationId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(RealtimeWidgetForm);
