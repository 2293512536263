import React, { Component } from 'react';
import api from 'helpers/api';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';
import { Button, Notification } from 'shared';
import {
  getTimingSettings,
  editTimingSettings,
  saveTimingSettings,
  getAssets,
  getProductTypes,
  saveProductType,
  editProductType,
} from '../../actions';
import TimingsTable from './components/TimingsTable';
import TimingsForm from './components/TimingsForm/index';
import ProductTypeForm from '../ProductTypesTab/components/ProductTypeForm/index';
import AssetForm from '../../../../industry/containers/IndustryLocationAssetList/components/AssetForm';
import './style.scss';

class TimingSettingsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timingSettings: [],
      timingSetting: null,
      assets: [],
      assetTypes: [],
      productTypes: [],
      isTimingsFormOpen: false,
      isSavingTimings: false,
      isProductFormOpen: false,
      isAssetFormOpen: false,
      selectedProductType: null,
      isSavingProduct: false,
    };
  }

  componentDidMount() {
    const {
      companyId,
      locationId,
    } = this.props;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToSettingsPage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    getAssets(companyId)
      .then((re) => {
        const assets = get(re, 'data.results');
        this.setState({
          assets,
        }, () => {
          getProductTypes(companyId)
            .then((resp) => {
              const productTypes = get(resp, 'data.results');
              this.setState({
                productTypes,
              }, () => {
                api.get(`/api/v1/asset_types/?account_type=industry&company=${companyId}&order_by=name&limit=200`)
                  .then((json) => {
                    this.setState({
                      assetTypes: get(json, 'data.results'),
                    }, () => {
                      getTimingSettings(companyId)
                        .then((res) => {
                          this.setState({
                            timingSettings: get(res, 'data.results'),
                          });
                        }).catch((error) => {
                          return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
                        });
                    });
                  });
              });
            }).catch((error) => {
              return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
            });
        });
      }).catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  handleSaveProductType = (data) => {
    this.setState({
      isSavingProduct: true,
    });
    saveProductType(data)
      .then(() => {
        const { productTypes } = this.state;
        productTypes.push(data);
        this.setState({
          productTypes,
          isProductFormOpen: false,
          isSavingProduct: false,
        });
      });
  }

  handleEditProduct = (data) => {
    this.setState({
      isSavingProduct: true,
    });
    editProductType(data)
      .then(() => {
        const { productTypes } = this.state;
        productTypes.forEach((pType) => {
          if (pType.id === data.id) {
            pType.name = data.name;
            pType.code = data.code;
          }
        });
        this.setState({
          productTypes,
          isProductFormOpen: false,
          isSavingProduct: false,
          selectedProductType: null,
        });
      });
  }

  closeProductFormModal = () => {
    this.setState({
      isProductFormOpen: false,
      selectedProductType: null,
    });
  }

  saveTimingSetting = (timingSetting) => {
    const {
      companyId,
    } = this.props;

    this.setState({
      isSavingTimings: true,
    });

    timingSetting.company = companyId;
    const save = timingSetting.id ? editTimingSettings : saveTimingSettings;
    save(timingSetting, companyId, timingSetting.id)
      .then(() => {
        getTimingSettings(companyId)
          .then((res) => {
            this.setState({
              timingSettings: [],
            }, () => {
              this.setState({
                timingSettings: get(res, 'data.results'),
              });
            });
          }).catch((error) => {
            console.log('error fetching timing settings', error);
          });
        this.setState({
          isTimingsFormOpen: false,
          timingSetting: null,
        });
      });
  }

  openTimingsFormModal = (timing = null) => {
    const { isReadOnly } = this.state;
    if (!isReadOnly) {
      this.setState({
        timingSetting: timing,
        isTimingsFormOpen: true,
      });
    }
  }

  closeTimingsFormModal = () => {
    this.setState({
      isTimingsFormOpen: false,
    });
  }

  openAddAssetForm = () => {
    this.setState({
      isAssetFormOpen: true,
    });
  }

  closeAddAssetFormModal = () => {
    this.setState({
      isAssetFormOpen: false,
    });
  }

  updateAssetList = (asset) => {
    const { assets } = this.state;
    assets.push(asset);
    this.setState({
      assets,
    });
  }

  openProductTypeForm = () => {
    this.setState({
      isProductFormOpen: true,
    });
  }

  render() {
    const {
      companyId,
      t,
    } = this.props;
    const {
      timingSetting,
      timingSettings,
      assets,
      assetTypes,
      productTypes,
      isTimingsFormOpen,
      isSavingTimings,
      isProductFormOpen,
      selectedProductType,
      isSavingProduct,
      isAssetFormOpen,
      isReadOnly,
    } = this.state;

    return (
      <div className="default-form timings-panel">
        <Button
          onClick={this.openTimingsFormModal}
          disabled={isReadOnly}
          type="add"
        >
          {t('settings.timing_settings.add_timings_button')}
        </Button>
        &nbsp;
        <Button
          onClick={this.openAddAssetForm}
          disabled={isReadOnly}
          type="add"
        >
          {t('settings.timing_settings.add_asset_button')}
        </Button>
        &nbsp;
        <Button
          onClick={this.openProductTypeForm}
          disabled={isReadOnly}
          type="add"
        >
          {t('settings.timing_settings.add_product_button')}
        </Button>
        <div>&nbsp;</div>
        <TimingsTable
          timings={timingSettings}
          assets={assets}
          assetTypes={assetTypes}
          productTypes={productTypes}
          editTimingSetting={this.openTimingsFormModal}
        />
        {
          isTimingsFormOpen && <TimingsForm
            timingSetting={timingSetting}
            assets={assets}
            productTypes={productTypes}
            isSavingTimings={isSavingTimings}
            onSave={this.saveTimingSetting}
            closeModal={this.closeTimingsFormModal}
            isOpen={isTimingsFormOpen}
            companyId={companyId}
          />
        }
        {
          isProductFormOpen && <ProductTypeForm
            isOpen={isProductFormOpen}
            closeModal={this.closeProductFormModal}
            pType={selectedProductType || null}
            companyId={companyId}
            returnTechnologies={() => { }}
            saveProductType={selectedProductType ? this.handleEditProduct : this.handleSaveProductType}
            isLoadingState={isSavingProduct}
            productDepartments={[]}
            productTypeUnits={[]}
            productTypeOptions={[]}
            categoryOptions={[]}
          />
        }
        {
          isAssetFormOpen && <AssetForm
            onClose={this.closeAddAssetFormModal}
            companyId={companyId}
            updateAssetList={() => { }}
            returnNewAsset={this.updateAssetList}
          />
        }
      </div>
    );
  }
}

TimingSettingsTab.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(TimingSettingsTab);
