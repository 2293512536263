import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { get } from 'lodash';
import api from 'helpers/api';
// import { CSSTransition } from 'react-transition-group';
import {
  IconSearch,
} from 'shared/Icons';
import {
  Button,
  Table,
  TableButtons,
} from 'shared';
import { checkAccessOnPage, redirectToHomePage, checkModules } from 'industry/helpers';
import 'react-table/react-table.css';
import { DeviceForm } from './components';
import { fetchDevices, addOrUpdateDevice, setDevices } from './actions';
import { filterDevices, formatDeviceType } from './helpers';
import './styles.scss';

class IndustryLocationDeviceList extends Component {
  constructor(props) {
    super(props);

    let isReadOnly = false;

    if (window.resourceReadOnly && window.resourceReadOnly.length) {
      window.resourceReadOnly.forEach((resource) => {
        if (window.location.href.includes(resource)) {
          isReadOnly = true;
        }
      });
    }

    this.state = {
      activeQuery: '',
      isLoadingDevices: true,
      showDetails: false,
      showDeviceForm: false,
      selectedDevice: null,
      isReadOnly,
      count: null,
      next: null,
      previous: null,
      selectedAscDesc: 'asc',
      selectedSort: 'name',
    };
    this.handleClear = this.handleClear.bind(this);
    this.handleShowFilter = this.handleShowFilter.bind(this);
    this.toggleDetailWindow = this.toggleDetailWindow.bind(this);
    this.handleFormToggle = this.handleFormToggle.bind(this);
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Devices');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });
    this.getDevices();
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.getDevices();
    });
  }

  handleClear() {
    this.setState({
      activeQuery: '',
    });
  }

  handleShowFilter() {
    this.setState((prevState) => ({
      showFilter: !prevState.showFilter,
    }));
  }

  exportToExcel = () => {
    const { companyId, locationId } = this.props;
    const { count } = this.state;

    api.get(`/api/v1/industry/devices/?location=${locationId}&company=${companyId}&format=xlsx&limit=${count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'device_list.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  getDevices = () => {
    const { companyId, locationId } = this.props;
    const { selectedAscDesc, selectedSort } = this.state;

    let url = `/api/v1/industry/devices/?location=${locationId}&company=${companyId}`;

    this.setState({ isLoadingDevices: true });
    const asc = selectedAscDesc === 'desc' ? '-' : '';
    url += `&order_by=${asc}${selectedSort}`;

    fetchDevices(url)
      .then((res) => {
        this.setState({
          isLoadingDevices: false,
          count: res && res.data ? res.data.count : null,
          next: res && res.data ? res.data.next : null,
          previous: res && res.data ? res.data.previous : null,
        });
        this.props.setDevices(get(res, 'data.results'));
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          isLoadingDevices: false,
        });
      });
  }

  toggleDetailWindow(id) {
    this.setState({
      showDetails: id,
    });
  }

  handleAddDevice = () => {
    this.setState({
      showDeviceForm: true,
      editingDeviceId: null,
    });
  }

  handleModalClose = () => {
    this.setState({
      showDeviceForm: false,
      editingDeviceId: null,
    });
  }

  handleFormToggle = (value) => {
    this.setState({
      showDeviceForm: value,
    });
  }

  handleEditDevice = (id, device) => {
    this.setState({
      selectedDevice: device,
      showDeviceForm: true,
      editingDeviceId: id,
    });
  }

  handleClear = () => {
    this.setState({
      activeQuery: '',
    }, () => {
    });
  }

  handleSubmit = (e) => {
    this.setState({
      activeQuery: e,
    }, () => {
    });
  }

  fetchData = (url) => {
    this.setState({ isLoadingDevices: true });
    fetchDevices(url)
      .then((res) => {
        this.setState({
          isLoadingDevices: false,
          count: res && res.data ? res.data.count : null,
          next: res && res.data ? res.data.next : null,
          previous: res && res.data ? res.data.previous : null,
        });
        this.props.setDevices(get(res, 'data.results'));
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          isLoadingDevices: false,
        });
      });
  };

  render() {
    const { location, devices, companyId, t } = this.props;

    const {
      activeQuery,
      editingDeviceId,
      isLoadingDevices,
      showDetails,
      showDeviceForm,
      selectedDevice,
      isReadOnly,
      next,
      previous,
      count,
    } = this.state;

    return (
      <div className="industry-location-device-list fullscreen">
        <div className="device_list_toolbar_area">
          <div className="input_container">
            <input onChange={(e) => this.handleSubmit(e.target.value)} placeholder={t('page_content.devices.search_placeholder')} value={activeQuery} />
            {activeQuery && <button
              onClick={() => {
                this.handleClear();
              }}
            >&times;</button>}
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <div className="buttons_wrapper">
            <Button
              onClick={this.handleAddDevice}
              title="Add device"
              disabled={isReadOnly}
              type="add"
            >
              {t('page_content.devices.add_device_button')}
            </Button>
            <Button
              onClick={this.exportToExcel}
              type="export"
              style={{ marginLeft: '10px' }}
            >{t('page_content.devices.export_to_excel_button')}</Button>
          </div>
        </div>
        <main>
          <Table
            columns={[
              {
                Header: () => <span>{t('page_content.devices.table_column_serial_number')}</span>,
                accessor: 'external_id',
                Cell: (row) => row.value || '-',
              },
              {
                Header: () => <span>{t('page_content.devices.table_column_name')}</span>,
                accessor: 'name',
                Cell: (row) => row.value || '-',
              },
              {
                Header: () => <span>{t('page_content.devices.table_column_device_type')}</span>,
                accessor: 'device_type',
                Cell: formatDeviceType,
              },
              {
                Header: () => <span>{t('page_content.devices.table_column_ip')}</span>,
                accessor: 'ip_address',
                Cell: (row) => row.value || '-',
              },
              {
                Header: () => <span>{t('page_content.devices.table_column_in_use')}</span>,
                accessor: 'is_deleted',
                maxWidth: 200,
                Cell: (row) => {
                  return typeof row.value === 'undefined'
                    ? '-'
                    : !row.value
                      ? 'Yes'
                      : 'No';
                },
              },
              {
                Header: () => <span>{t('page_content.devices.table_column_comment')}</span>,
                accessor: 'note',
              },
            ]}
            data={filterDevices(devices, activeQuery)}
            // handleClick={rowInfo => this.setState({ showDetails: get(rowInfo, 'original'), selectedDevice: get(rowInfo, 'original') })}
            handleClick={(rowInfo) => !isReadOnly && this.handleEditDevice(get(rowInfo, 'original').id, get(rowInfo, 'original'))}
            minRows={0}
            loading={isLoadingDevices}
            noDataText=" "
            selectedRow={showDetails.id}
            showPagination={false}
            defaultSorted={[{ id: 'name', desc: false }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchData} count={count} />
        </main>
        {
          showDeviceForm &&
          <DeviceForm
            device={selectedDevice}
            onClose={() => this.handleModalClose()}
            id={editingDeviceId}
            addOrUpdateDevice={this.props.addOrUpdateDevice}
            location={location}
            isReadOnly={isReadOnly}
            companyId={companyId}
          />
        }
      </div>
    );
  }
}

IndustryLocationDeviceList.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  addOrUpdateDevice: PropTypes.func.isRequired,
  devices: PropTypes.array,
  location: PropTypes.any,
  setDevices: PropTypes.func,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
  devices: state.industry.container.devices,
});

const mapDispatchToProps = {
  addOrUpdateDevice,
  setDevices,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IndustryLocationDeviceList));
