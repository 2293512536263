import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const processWorkerSchedules = (data) => {
  return Object.keys(data).map((date) => {
    const item = data[date];
    const startTime = item?.begin_time
      ? moment(`${date} ${item?.begin_time}`).toDate()
      : moment(date).startOf('day').toDate();
    let endTime = item?.end_time
      ? moment(`${date} ${item?.end_time}`).toDate()
      : moment(date).endOf('day').toDate();

    if (endTime < startTime) {
      endTime = moment(endTime).add(1, 'day').toDate();
    }

    const beginBreak = item?.begin_break
      ? moment(`${date} ${item?.begin_break}`).toDate()
      : moment(date).startOf('day').toDate();
    const endBreak = item?.end_break
      ? moment(`${date} ${item?.end_break}`).toDate()
      : moment(date).endOf('day').toDate();

    return {
      id: date,
      group: 1,
      date,
      start_time: startTime,
      end_time: endTime,
      day_type: item?.day_type,
      beginBreak,
      endBreak,
    };
  });
};
