import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import api from 'helpers/api';
import { connect } from 'react-redux';
import { Button, Table } from 'shared';
import { checkAccessOnPage, redirectToHomePage, checkModules } from 'industry/helpers';

class IndustryLocationOperatorList extends Component {
  constructor(props) {
    super(props);

    let isReadOnly = false;

    if (window.resourceReadOnly && window.resourceReadOnly.length) {
      window.resourceReadOnly.forEach((resource) => {
        if (window.location.href.includes(resource)) {
          isReadOnly = true;
        }
      });
    }

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      isReadOnly,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Operator view');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            isReadOnly: true,
          });
        }
      });
    this.fetchOperatorViews(locationId);
  }

  fetchOperatorViews = (locationId) => {
    api
      .get(`/api/v1/dashboards/operator-views/?location=${locationId}`)
      .then(({ data }) => {
        this.setState({
          operatorViews: ((data.results) ? data.results : []),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleClick = (row) => {
    if (row.original.view_type === 'simple') {
      const urlPart = window.location.href.split('operator')[0];
      const url = new URL(urlPart);
      const base = url.pathname;
      const redirectLocation = `${base}operatorView/${row.original.id}/simple-operator-interface`;
      window.location.href = redirectLocation;
    }
    if (row.original.view_type === 'detailed') {
      api
        .get(`/api/v1/dashboards/operator-view-assets/?operator_view=${row.original.id}`)
        .then(({ data }) => {
          const assetId = get(data, 'results[0].asset.id');
          const urlPart = window.location.href.split('operator')[0];
          const url = new URL(urlPart);
          const base = url.pathname;
          const redirectLocation = `${base}operator-interface/${assetId}`;
          window.location.href = redirectLocation;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    const { t } = this.props;
    const {
      showDetails,
      operatorViews,
    } = this.state;

    return (
      <div className="industry-location-drone-list fullscreen">
        <main
          style={{
            width: showDetails ? 'calc(100% - 500px - 20px)' : 'calc(100% - 20px)',
            margin: '10px',
          }}
        >
          {this.props.locationId ?
            <Table
              style={{ userSelect: 'text' }}
              columns={[
                {
                  Header: () => <span>{t('page_content.operator_view.table_column_operator_view')}</span>,
                  accessor: 'name',
                },
                {
                  Header: () => <span>{t('page_content.operator_view.table_column_operator_view_asset_list')}</span>,
                  Cell: (row) => <Button onClick={() => this.handleClick(row)}>{t('page_content.operator_view.table_column_open_button')}</Button>,
                },
              ]}
              data={(operatorViews) || []}
              defaultPageSize={100}
              minRows={0}
              noDataText=" "
              showPagination={false}
              sortable="true"
              manual={false}
            /> : ''}
        </main>
      </div>
    );
  }
}

IndustryLocationOperatorList.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(withTranslation()(IndustryLocationOperatorList));
