import * as actionTypes from './actionTypes';

const initialState = {
  company: undefined,
  listType: 'location',
  list: [],
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case (actionTypes.setNavigation):
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
