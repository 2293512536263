import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { get } from 'lodash';

import { modalSizes } from 'shared/constants';
import { multiSelectStylesAutoHeight, selectModalStyles } from 'styles/modules/reactSelect';
import { Modal, TableLoader, Table, ContentLoader, Notification } from 'shared';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { checkRevision, createDmsDocument, getProductTypes, importDmsDocumentFiles } from '../actions';

class CreateDocumentModal extends Component {
  constructor() {
    super();
    this.fileInputRef = React.createRef();
    this.debounceTimeoutRef = React.createRef(null);
    this.state = {
      documentName: '',
      documentType: {},
      documentProject: {},
      documentProductType: [],
      isLoading: false,
      documentForUpload: undefined,
      productTypes: [],
      multipleFiles: [],
      isMultipleFilesDone: false,
      selectedAssets: [],
      folderName: '',
      documentId: null,
    };
  }

  componentDidMount() {
    this.fetchProductTypes();
  }

  componentDidUpdate(prevProps) {
    const { modalStatus, documentTypes } = this.props;
    if (documentTypes && prevProps.documentTypes !== documentTypes) {
      if (documentTypes.length) {
        const filteredExtensions = [...new Set(documentTypes.map((d) => `.${d.defaultExtension.toLowerCase()}`))];
        const extensionsString = filteredExtensions.join(', ');
        this.setState({ fileTypesAccept: extensionsString });
      }
    }
    if (modalStatus && prevProps.modalStatus !== modalStatus) {
      this.handleUploadWindow();
    }
  }

  handleQueryOrFilterChange = (key, event) => {
    const value = event;
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  handleUploadWindow = () => {
    this.fileInputRef.current.click();
  }

  prepareDocumentFile = async (files) => {
    const { documentTypes } = this.props;

    if (files.length === 0) {
      return;
    }

    if (files.length === 1) {
      const file = files[0];

      this.setState({
        isLoading: true,
      });

      if (file && file.name) {
        let fileName = file.name.split('.').slice(0, -1).join('.');
        const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
        const revisionNumber = fileName.split('_').length > 0 ? fileName.split('_')[1] : null;
        fileName = fileName.split('_').length > 0 ? fileName.split('_')[0] : fileName;

        if (revisionNumber) {
          const documentExists = this.props.data.find((d) => d.name === fileName);
          if (documentExists) {
            this.setState({ documentId: documentExists.id });
          }
        }

        this.setState({
          documentName: revisionNumber ? file.name.split('.').slice(0, -1).join('.') : fileName,
          fullFileName: file.name,
          documentForUpload: file,
          isLoading: false,
          documentType: extension === 'pdf' && documentTypes.find((docuType) => docuType.name.toLowerCase() === extension.toLowerCase()) ? documentTypes.find((docuType) => docuType.name.toLowerCase() === extension.toLowerCase()) : documentTypes.find((docuType) => docuType.defaultExtension === extension) || {},
        });
      }
    } else {
      const processedFiles = Array.from(files).map((file) => {
        let fileName = file.name.split('.').slice(0, -1).join('.');
        const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
        const revisionNumber = fileName.split('_').length > 0 ? fileName.split('_')[1] : null;
        fileName = fileName.split('_').length > 0 ? fileName.split('_')[0] : fileName;
        let documentId = null;
        if (revisionNumber) {
          const documentExists = this.props.data.find((d) => d.name === fileName);
          if (documentExists) {
            documentId = documentExists.id;
          }
        }
        const documentInfo = {
          isChecked: true,
          fullFileName: file.name,
          documentName: revisionNumber ? file.name.split('.').slice(0, -1).join('.') : fileName,
          documentType: extension === 'pdf' ? documentTypes.find((docuType_2) => docuType_2.name.toLowerCase() === extension.toLowerCase()) || documentTypes[0] : documentTypes.find((docuType_3) => docuType_3.defaultExtension === extension) || documentTypes[0],
          documentForUpload: file,
          documentId,
          isRevisionDocument: revisionNumber,
        };
        return documentInfo;
      });

      // Update the state differently for multiple files
      this.setState({
        multipleFiles: processedFiles,
        isLoading: false,
      });
    }
  }

  fetchProductTypes = () => {
    const { companyId } = this.props;
    this.setState({
      isLoading: true,
    });
    getProductTypes(companyId)
      .then((re) => {
        this.setState({
          productTypes: get(re, 'data.results') || [],
          isLoading: false,
        });
      })
      .catch((error) => console.log(error));
  }

  fetchProductTypesFilter = (value) => {
    const { companyId } = this.props;
    this.setState({ productTypesLoading: true });
    if (value.length > 2) {
      getProductTypes(companyId, value)
        .then((re) => {
          this.setState({
            productTypes: get(re, 'data.results') || [],
            productTypesLoading: false,
          });
        })
        .catch((error) => console.log(error));
    }
  }

  uploadDocument = async () => {
    const { documentTypes, projects, history, locationId, companyId, fetchDmsDocuments, isProjectBased, t } = this.props;
    const { fullFileName, documentId, documentName, documentType, documentProject, documentProductType, documentForUpload, multipleFiles, selectedAssets, folderName } = this.state;

    const selectedAssetsIds = selectedAssets && selectedAssets.length ? selectedAssets.map((asset) => asset.id) : [];

    try {
      if (!multipleFiles.length) {
        this.setState({ isLoading: true });

        const payload = {
          document_type: documentType.id,
          name: documentName,
          product_types: documentProductType[0] ? documentProductType.map((product) => product.id) : [],
          assets: selectedAssetsIds,
          folder: folderName,
        };

        if (isProjectBased) {
          const checkProjectsObject = Object.keys(documentType).length > 0;
          payload.project = checkProjectsObject ? projects[0].id : documentProject.id;
        }
        const extension = fullFileName.substring(fullFileName.lastIndexOf('.') + 1);
        const docType = extension === 'pdf' ? documentTypes.find((docuType) => docuType.defaultExtension.toLowerCase() === extension.toLowerCase()) : documentTypes.find((docuType) => docuType.defaultExtension === extension);
        const revision = await checkRevision(fullFileName, docType.id);
        const revisionNumber = fullFileName.split('_').length > 0 ? fullFileName.split('_')[1] : null;
        if (documentId && documentForUpload) {
          if (revision.data.exists) {
            Notification('error', t('page_content.dms.create_document_modal.error_notification_exists'));
            this.setState({ isLoading: false });
            return;
          }
          await importDmsDocumentFiles(documentForUpload, documentId);
          history.push(`/${companyId}/industry/location/${locationId}/dms/${documentId}`);
          this.handleClose();
        } else if (!revisionNumber) {
          const re = await createDmsDocument(payload);
          if (re.error || !re.data.id) {
            this.setState({ isLoading: false });
            return;
          }

          if (documentForUpload) {
            const uploadResp = await importDmsDocumentFiles(documentForUpload, re.data.id);

            if (!uploadResp || uploadResp.status >= 300) {
              Notification('error', 'Failed to create document files.');
            }
            this.handleClose();
            history.push(`/${companyId}/industry/location/${locationId}/dms/${re.data.id}`);
          } else {
            this.handleClose();
            history.push(`/${companyId}/industry/location/${locationId}/dms/${re.data.id}`);
          }
        } else {
          Notification('error', t('page_content.dms.create_document_modal.error_notification'));
          this.setState({ isLoading: false });
        }
      } else {
        try {
          const filterMultipleFiles = multipleFiles.filter((file) => file.isChecked === true);
          const updatedMultipleFiles = filterMultipleFiles.map((file) => ({ ...file, upload: 'loading' }));
          this.setState({ multipleFiles: updatedMultipleFiles });

          const uploadPromises = updatedMultipleFiles.map(async (file) => {
            const payload = {
              document_type: file.documentType.id,
              name: file.documentName,
              product_types: file.documentProductType.length ? file.documentProductType.map((product) => product.id) : [],
            };

            if (isProjectBased) {
              const checkProjectsObject = Object.keys(file.documentType).length > 0;
              payload.project = checkProjectsObject ? projects[0].id : documentProject.id;
            }

            try {
              if (file.documentId) {
                const res = await checkRevision(file.fullFileName, file.documentType.id);
                let uploadStatus = false;
                if (!res.data.exists) {
                  const uploadResp = await importDmsDocumentFiles(file.documentForUpload, file.documentId);
                  if (uploadResp && uploadResp.status >= 200 && uploadResp.status < 300) {
                    uploadStatus = true;
                  }
                  const updatedFile = { ...file, upload: uploadStatus };
                  this.setState((prevState) => ({
                    multipleFiles: prevState.multipleFiles.map((prevFile) => (prevFile.documentName === file.documentName ? updatedFile : prevFile)),
                  }));
                } else {
                  Notification('error', t('page_content.dms.create_document_modal.error_notification_exists'));
                  const updatedFile = { ...file, upload: false };
                  this.setState((prevState) => ({
                    multipleFiles: prevState.multipleFiles.map((prevFile) => (prevFile.documentName === file.documentName ? updatedFile : prevFile)),
                  }));
                }
              } else if (!file.isRevisionDocument) {
                const re = await createDmsDocument(payload);

                let uploadStatus = false;
                if (re.error || !re.data.id) {
                  uploadStatus = false;
                } else if (file.documentForUpload) {
                  const uploadResp = await importDmsDocumentFiles(file.documentForUpload, re.data.id);
                  if (uploadResp && uploadResp.status >= 200 && uploadResp.status < 300) {
                    uploadStatus = true;
                  }
                } else {
                  uploadStatus = true;
                }
                const updatedFile = { ...file, upload: uploadStatus };
                this.setState((prevState) => ({
                  multipleFiles: prevState.multipleFiles.map((prevFile) => (prevFile.documentName === file.documentName ? updatedFile : prevFile)),
                }));
              } else {
                Notification('error', t('page_content.dms.create_document_modal.error_notification'));
                const updatedFile = { ...file, upload: false };
                this.setState((prevState) => ({
                  multipleFiles: prevState.multipleFiles.map((prevFile) => (prevFile.documentName === file.documentName ? updatedFile : prevFile)),
                }));
              }
            } catch (error) {
              const updatedFile = { ...file, upload: false };
              this.setState((prevState) => ({
                multipleFiles: prevState.multipleFiles.map((prevFile) => (prevFile.documentName === file.documentName ? updatedFile : prevFile)),
              }));
            }
          });

          await Promise.all(uploadPromises);
          fetchDmsDocuments();
        } catch (error) {
          console.error('Error uploading files:', error);
        } finally {
          this.setState({ isLoading: false, isMultipleFilesDone: true });
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  handleClose = () => {
    const { closeModal } = this.props;

    this.setState({
      documentName: '',
      documentType: {},
      documentProject: {},
      documentProductType: [],
      multipleFiles: [],
      isMultipleFilesDone: false,
      folderName: '',
      documentId: null,
    });
    closeModal();
  }

  multipleSelectFiles = (isChecked) => {
    const { multipleFiles } = this.state;

    const updatedMultipleFiles = multipleFiles.map((file) => ({ ...file, isChecked: isChecked || false }));
    this.setState({ multipleFiles: updatedMultipleFiles });
  }

  selectFile = (isChecked, documentName) => {
    const { multipleFiles } = this.state;

    if (documentName) {
      const index = multipleFiles.findIndex((obj) => obj.documentName === documentName);
      const copyMultipleFiles = [...multipleFiles];
      copyMultipleFiles[index].isChecked = isChecked || false;

      this.setState({ multipleFiles: copyMultipleFiles });
    }
  }

  handleSelectType = (value, row) => {
    const { multipleFiles } = this.state;
    const currentRow = multipleFiles.findIndex((f) => f.documentName === row.original.documentName);
    multipleFiles[currentRow].documentType = value;
    this.setState({ multipleFiles });
  }

  handleSelectProductType = (value, row) => {
    const { multipleFiles } = this.state;
    const currentRow = multipleFiles.findIndex((f) => f.documentProductType === row.original.documentProductType);
    multipleFiles[currentRow].documentProductType = value;
    this.setState({ multipleFiles });
  }

  handleProductTypeSearch = (value) => {
    if (value.length > 2) {
      if (this.debounceTimeoutRef.current) {
        clearTimeout(this.debounceTimeoutRef.current);
      }

      this.debounceTimeoutRef.current = setTimeout(() => {
        this.fetchProductTypesFilter(value);
      }, 300);
    }
  }

  isChecked = (row) => {
    const { multipleFiles } = this.state;

    const foundObj = multipleFiles.find((x) => x.documentName === row.documentName);

    if (foundObj) {
      const isChecked = !!(foundObj && foundObj.isChecked && foundObj.isChecked === true);
      return isChecked;
    }
    return false;
  }

  render() {
    const { t, modalStatus, documentTypes, projects, isProjectBased, assets } = this.props;
    const { productTypesLoading, fileTypesAccept, documentName, documentType, documentProject, documentProductType, isLoading, productTypes, multipleFiles, isMultipleFilesDone, selectedAssets, folderName } = this.state;

    const selectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '500px',
        minHeight: '34px',
        height: '36px',
        padding: 0,
        fontSize: '12px',
        color: '#555',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0 10px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: 10,
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: '500px',
        borderRadius: 0,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '12px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    return (
      <div>
        <Modal
          title={t('page_content.dms.create_document_modal.modal_title')}
          isOpen={modalStatus}
          size={modalSizes.large}
          closeText={isMultipleFilesDone ? t('shared.modal.close_button_alt') : t('shared.modal.close_button')}
          handleClose={this.handleClose}
          disableSave={!multipleFiles.length ? documentName.length === 0 || !Object.keys(documentType).length > 0 : null || isMultipleFilesDone || multipleFiles.every((obj) => obj.isChecked === false)}
          handleSave={this.uploadDocument}
        >
          {isLoading ? <div className="create_document_modal_container">
            <TableLoader loading={isLoading} />
            <div className="create_document_modal_row_text">{t('page_content.dms.create_document_modal.creating_document')}</div>
          </div> : <div className="create_document_modal_container">
            {
              !multipleFiles.length &&
              <div className="create_document_modal_row">
                <div className="create_document_modal_row_text">{`${t('page_content.dms.create_document_modal.create_document')} *`}</div>
                <input className="create_document_modal_input" onChange={(e) => this.handleQueryOrFilterChange('documentName', e.target.value)} value={documentName} />
              </div>
            }

            {
              !multipleFiles.length &&
              <div className="create_document_modal_row"><div className="create_document_modal_row_text">{`${t('page_content.dms.create_document_modal.document_type')} *`}</div>
                <Select
                  options={documentTypes}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  menuPosition="fixed"
                  onChange={(value) => this.handleQueryOrFilterChange('documentType', value)}
                  value={(documentTypes.find((docuType) => docuType.id === documentType.id)) || ''}
                  styles={selectStyles}
                  placeholder={t('page_content.dms.create_document_modal.document_type')}
                /></div>
            }

            {
              documentType && documentType.line_based && documentType.line_based === true ?
                <div className="create_document_modal_row"><div className="create_document_modal_row_text">{`${t('page_content.dms.create_document_modal.assets')}`}</div>
                  <Select
                    options={assets}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isSearchable
                    isMulti
                    menuPosition="fixed"
                    placeholder={t('page_content.production_records.asset_placeholder')}
                    onChange={(val) => { this.handleQueryOrFilterChange('selectedAssets', val); }}
                    styles={selectStyles}
                    value={selectedAssets || []}
                  /></div> : ''
            }

            {
              documentType && documentType.line_based && documentType.line_based === true ?
                <div className="create_document_modal_row"><div className="create_document_modal_row_text">{`${t('page_content.dms.create_document_modal.folder')}`}</div>
                  <input
                    className="create_document_modal_input"
                    type="text"
                    onChange={(e) => this.handleQueryOrFilterChange('folderName', e.target.value)}
                    value={folderName}
                  /></div>
                : ''
            }

            {
              isProjectBased ?
                <div className="create_document_modal_row"><div className="create_document_modal_row_text">{`${t('page_content.dms.create_document_modal.project')} *`}</div>
                  <Select
                    options={projects}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isSearchable
                    menuPosition="fixed"
                    onChange={(value) => this.handleQueryOrFilterChange('documentProject', value)}
                    value={(projects.find((proj) => proj.id === documentProject.id)) || projects[0]}
                    styles={selectStyles}
                    placeholder={t('page_content.dms.create_document_modal.project')}
                  /></div> : ''
            }

            {
              !multipleFiles.length &&
              <div className="create_document_modal_row"><div className="create_document_modal_row_text">{t('page_content.dms.create_document_modal.product_type')}</div>
                <Select
                  options={productTypes}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isMulti
                  menuPosition="fixed"
                  onChange={(value) => this.handleQueryOrFilterChange('documentProductType', value)}
                  value={documentProductType}
                  onInputChange={(e) => this.handleProductTypeSearch(e)}
                  styles={selectStyles}
                  placeholder={t('page_content.dms.create_document_modal.product_type')}
                  isLoading={productTypesLoading}
                  loadingMessage={() => <ContentLoader />}
                /></div>
            }
          </div>}

          {
            multipleFiles.length && !isLoading ?
              <Table
                style={{ userSelect: 'text' }}
                columns={[
                  {
                    Header: () => (<span>
                      <input
                        type="checkbox"
                        onChange={(e) => this.multipleSelectFiles(e.target.checked)}
                        checked={multipleFiles.every((obj) => obj.isChecked === true)}
                        style={{ margin: 'auto', display: 'block' }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    </span>),
                    width: 40,
                    Cell: (row) => (
                      <span>
                        <input
                          type="checkbox"
                          onChange={(e) => this.selectFile(e.target.checked, row.original.documentName)}
                          checked={this.isChecked(row.original)}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </span>
                    ),
                    sortable: false,
                  },
                  {
                    Header: () => <span>{t('page_content.dms.create_document_modal.create_document')}</span>,
                    accessor: 'documentName',
                    Cell: (row) => (row && row.value ? row.value : '-'),
                  },
                  {
                    Header: () => <span>{t('page_content.dms.create_document_modal.document_type')}</span>,
                    accessor: 'documentType',
                    width: 150,
                    Cell: (row) => (<div className="select_row_style">
                      <Select
                        options={documentTypes}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        menuPortalTarget={document.querySelector('ReactModal__Overlay')}
                        menuShouldScrollIntoView={false}
                        menuPosition="fixed"
                        isSearchable
                        onChange={(val) => this.handleSelectType(val, row)}
                        value={row.value || ''}
                        styles={selectModalStyles}
                      />
                    </div>),
                    sortable: false,
                  },
                  {
                    Header: () => <span>{t('page_content.dms.create_document_modal.product_type')}</span>,
                    accessor: 'documentProductType',
                    Cell: (row) => (<div className="select_row_style">
                      <Select
                        options={productTypes}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        menuPortalTarget={document.querySelector('ReactModal__Overlay')}
                        menuShouldScrollIntoView={false}
                        menuPosition="fixed"
                        isSearchable
                        isMulti
                        onInputChange={(e) => this.handleProductTypeSearch(e)}
                        onChange={(val) => this.handleSelectProductType(val, row)}
                        value={row.value || ''}
                        styles={multiSelectStylesAutoHeight}
                        isLoading={productTypesLoading}
                        placeholder={t('page_content.dms.create_document_modal.product_type_placeholder')}
                        loadingMessage={() => <ContentLoader />}
                      />
                    </div>),
                    sortable: false,
                  },
                  {
                    accessor: 'upload',
                    style: {
                      textAlign: 'center',
                    },
                    width: 80,
                    Cell: (row) => (row && row.value && row.value === true ?
                      <img src={checkMarkTrue} width="25px" height="20px" alt="" /> :
                      row.value === false ? <img src={checkMarkFalse} width="25px" height="20px" alt="" /> : row.value === 'loading' ? <ContentLoader rowVariant text="" /> : '')
                    ,
                  },
                ]}
                data={multipleFiles || []}
                loading={isLoading}
                noDataText=" "
                showPagination={false}
                minRows={0}
                pageSize={multipleFiles.length}
                selectedRow={null}
                sortable="true"
                manual={false}
              /> : ''
          }
        </Modal>
        <input
          type="file"
          ref={this.fileInputRef}
          multiple="multiple"
          style={{ display: 'none' }}
          accept={fileTypesAccept}
          onChange={(e) => {
            this.prepareDocumentFile(e.target.files);
            e.target.value = '';
          }}
        />
      </div>
    );
  }
}

CreateDocumentModal.propTypes = {
  fetchDmsDocuments: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  modalStatus: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  documentTypes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  projects: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  history: PropTypes.object.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isProjectBased: PropTypes.bool,
  assets: PropTypes.array,
  data: PropTypes.array,
};

export default (withRouter(withTranslation()(CreateDocumentModal)));
