/* eslint-disable import/prefer-default-export */
import api from 'helpers/api';
import { Notification } from 'shared';

export const getDepartments = async (locationId, orderBy) => {
  const urlString = '/api/v1/departments/';
  let urlParams = '?limit=30';

  if (locationId) {
    urlParams += `&location=${locationId}`;
  }
  if (orderBy) {
    urlParams += orderBy;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching departments', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getDepartmentLocations = async (companyId) => {
  const urlString = '/api/v1/locations/';
  let urlParams = '?limit=30';

  if (companyId) {
    urlParams += `&company=${companyId}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching locations', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedDepartments = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching departments', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addDepartment = async (technologyData) => {
  return api.post('/api/v1/departments/', technologyData)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Department successful', 'Department was successfully created.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const addStep = async (departmentId, step) => {
  return api.post(`/api/v1/work_operations/?department=${departmentId}`, step)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getDepartmentSteps = async (departmentId) => {
  try {
    const res = await api.get(`/api/v1/work_operations/?department=${departmentId}&limit=9999&order_by=name`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching department steps', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteDepartmentStep = (stepId, departmentId) => {
  return api.delete(`/api/v1/work_operations/${stepId}/?department=${departmentId}`)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const editDepartmentStep = async (stepId, departmentId, stepData) => {
  return api.put(`/api/v1/work_operations/${stepId}/?department=${departmentId}`, stepData);
};

export const editDepartment = async (externalId, locationId, departmentData) => {
  return api.put(`/api/v1/departments/${externalId}/?location=${locationId}`, departmentData);
};

export const deleteDepartment = async (externalId, locationId) => {
  return api.delete(`/api/v1/departments/${externalId}/?location=${locationId}`)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Delete successful', 'Department was successfully deleted.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getAllDepartments = async (locationId) => {
  const urlString = '/api/v1/departments/';
  let urlParams = '?limit=9999';

  if (locationId) {
    urlParams += `&location=${locationId}`;
  }
  urlParams += '&order_by=-name';

  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching departments', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getDepartmentChildren = async (locationId, parentId) => {
  const urlString = '/api/v1/departments/';
  let urlParams = '?limit=9999';

  if (locationId) urlParams += `&location=${locationId}`;
  if (parentId) urlParams += `&parent=${parentId}`;

  urlParams += '&order_by=-name';

  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching departments', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
