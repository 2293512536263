import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
// import moment from 'moment';
import Tooltip from 'rc-tooltip';
import {
  ComposedChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Label,
  Tooltip as ReTooltip,
  Legend,
} from 'recharts';
import WidgetPlaceholder from '../../WidgetPlaceholder';
import { IconWarning } from '../../../Icons';
import { colors } from '../../../colors';
import './styles.scss';

import {
  formatLabel,
} from '../helpers';

const colorIndex = [
  '#1abc9c',
  '#2980b9',
  '#e67e22',
  '#e74c3c',
  '#9b59b6',
  '#2ecc71',
  '#34495e',
  '#8e44ad',
  '#2c3e50',
  '#7f8c8d',
  '#c0392b',
  '#d35400',
  '#f39c12',
];

class Histogram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refAreaLeft: '',
      selectedId: null,
    };

    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  selectedLabel = (id) => {
    if (this.state.selectedId) {
      if (this.state.selectedId === id) {
        return false;
      }
      return true;
    }
    return false;
  };

  handleClick = (id) => {
    if (this.state.selectedId && this.state.selectedId === id) {
      this.setState({
        selectedId: null,
      });
    } else {
      this.setState({
        selectedId: id,
      });
    }
  }

  legendRender = (props) => {
    // eslint-disable-next-line
    const { payload } = props;
    return (
      <ul className="recharts-default-legend" style={{ padding: '0px', margin: '0px', textAlign: 'center' }}>
        {
          payload.map((entry, index) => (
            // <li key={`item-${index}`}>{entry.value}</li>

            <li key={`item-${index}`} className="recharts-legend-item legend-item-0" style={{ display: 'inline-block', marginRight: '10px', cursor: 'pointer' }} onClick={() => this.handleClick(parseInt(entry.dataKey, 10))}>
              <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}>
                <path stroke="none" fill={this.state.selectedId ? this.state.selectedId === parseInt(entry.dataKey, 10) ? entry.color : 'gray' : entry.color} d="M0,4h32v24h-32z" className="recharts-legend-icon" />
              </svg>
              <span className="recharts-legend-item-text">{entry.value}</span>
            </li>
          ))
        }
      </ul>
    );
  };

  // eslint-disable-next-line
  prepareData(data = {}, settings) {
    if (!settings.buckets) {
      return [];
    }
    const newData = [];

    if (data !== null && data !== undefined) {
      settings.buckets.forEach((bucket) => {
        const bucketName = `${bucket.from}-${bucket.to}`;
        newData.push({
          bucket: bucketName,
        });
        const bucketIndex = newData.findIndex((x) => { return x.bucket === bucketName; });
        Object.keys(data).forEach((metricId) => {
          newData[bucketIndex][metricId] = 0;
          data[metricId].forEach((metric) => {
            if (metric.value >= bucket.from && metric.value <= bucket.to) {
              const hasMetricProp = Object.prototype.hasOwnProperty.call(newData[bucketIndex], metricId);
              if (hasMetricProp) {
                newData[bucketIndex][metricId] += 1;
              }
            }
          });
        });
      });
    }

    return newData;
  }

  handleMouseMove = throttle((e) => {
    const { refAreaLeft } = this.state;
    if (refAreaLeft) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ refAreaRight: e.activeLabel });
    }
  }, 30);

  render() {
    const {
      height,
      data,
      settings,
      width,
      widgetData,
      eventsData,
    } = this.props;

    if (!settings) {
      return <WidgetPlaceholder height={this.props.height} type="histogram" />;
    }

    let fetchEvents = null;
    if (settings.legend && settings.legend.events) {
      fetchEvents = settings.legend.events;
    }

    const graphData = this.prepareData(data, settings);

    let minValue = null;
    let maxValue = null;

    if (settings && settings.axis) {
      const axis = settings.axis;

      if (axis.y1 && axis.y1.max !== null && axis.y1.min !== null) {
        if (axis.y1.min % 1 === 0) {
          minValue = parseInt(axis.y1.min, 10);
          maxValue = parseInt(axis.y1.max, 10);
        } else {
          minValue = parseFloat(axis.y1.min);
          maxValue = parseFloat(axis.y1.max);
        }
      }

      if (axis.y && axis.y.format === 'float') {
        if (widgetData && widgetData[0]) {
          const key = widgetData[0].id;
          const decimalPlaces = settings.axis.y.decimal_places ? settings.axis.y.decimal_places : 2;
          if (decimalPlaces) {
            for (let i = 0; i < graphData.length; i += 1) {
              if (graphData[i][key]) {
                graphData[i][key] = parseFloat(graphData[i][key].toFixed(decimalPlaces));
              }
            }
          }
        }
      } else if (axis.y1 && axis.y1.format === 'float') {
        if (widgetData && widgetData[0]) {
          if (widgetData && widgetData[0]) {
            const key = widgetData[0].id;
            const decimalPlaces = settings.axis.y1.decimal_places ? settings.axis.y1.decimal_places : 2;
            if (decimalPlaces) {
              for (let i = 0; i < graphData.length; i += 1) {
                if (graphData[i][key]) {
                  graphData[i][key] = parseFloat(graphData[i][key].toFixed(decimalPlaces));
                }
              }
            }
          }
        }
      } else if (axis.y2 && axis.y2.format === 'float') {
        if (widgetData && widgetData[1]) {
          const key = widgetData[1].id;
          const decimalPlaces = settings.axis.y2.decimal_places ? settings.axis.y2.decimal_places : 2;
          if (decimalPlaces) {
            for (let i = 0; i < graphData.length; i += 1) {
              if (graphData[i][key]) {
                graphData[i][key] = parseFloat(graphData[i][key].toFixed(decimalPlaces));
              }
            }
          }
        }
      } else if (axis.y3 && axis.y3.format === 'float') {
        if (widgetData && widgetData[2]) {
          const key = widgetData[2].id;
          const decimalPlaces = settings.axis.y3.decimal_places ? settings.axis.y3.decimal_places : 2;
          if (decimalPlaces) {
            for (let i = 0; i < graphData.length; i += 1) {
              if (graphData[i][key]) {
                graphData[i][key] = parseFloat(graphData[i][key].toFixed(decimalPlaces));
              }
            }
          }
        }
      }
    }

    if (settings.annotation && settings.annotation.value) {
      for (let i = 0; i < graphData.length; i += 1) {
        graphData[i].annotationValue = parseFloat(settings.annotation.value, 10);
      }
    }

    let showBucketsIcon = true;
    if (settings.buckets && settings.buckets.length) {
      showBucketsIcon = false;
    }

    let leftYAxisId;
    let annotationYAxisId;

    const valueTypeYAxisMapping = {};

    return (
      <div className="histogram" ref={this.widgetRef} style={{ backgroundColor: settings && settings.appearance && settings.appearance.backgroundColor ? settings.appearance.backgroundColor : 'white' }}>
        {showBucketsIcon === true &&
          <span className="histogram__buckets-icon">
            <Tooltip
              id="tooltip-histogram-buckets"
              trigger={['hover']}
              placement="bottom"
              overlay={<span>There are no buckets created.</span>}
              overlayClassName="histogram__tooltip"
            >
              <span aria-describedby="tooltip-histogram-buckets">
                <IconWarning color={colors.red} height="16px" width="16px" />
              </span>
            </Tooltip>
          </span>}
        <ReBarChart
          width={width}
          height={height}
          data={graphData}
          syncId="shared"
          syncMethod="value"
          margin={{ top: 25, right: 40, left: 5, bottom: 5 }}
          style={{ userSelect: 'none' }}
        >
          {
            (settings && settings.axis && settings.axis.x && settings.axis.x.show) &&
            <XAxis
              type="category"
              dataKey="bucket"
              name=""
              allowDataOverflow
              tick={{ fontSize: 10 }}
            />
          }
          {
            widgetData.sort((a, b) => { return a.id - b.id; }).filter((wD) => !wD.style.isCalculatedValue).map(({
              scale,
              type,
              metric,
              id,
            }, index) => {
              if (!metric) return '';
              // axes settings are for left and right orientitation
              // y1 === left
              // y2 === right

              const currentAxis = settings.axis.y1;

              if (!currentAxis.show) {
                return null;
              }

              if (metric && metric.value_type) {
                if (!Object.prototype.hasOwnProperty.call(valueTypeYAxisMapping, metric.value_type.id)) {
                  valueTypeYAxisMapping[metric.value_type.id] = index;
                }
              }

              if (metric && metric.value_type && valueTypeYAxisMapping[metric.value_type.id] !== index) {
                return '';
              }

              annotationYAxisId = `yAxis-${index}`;

              return (
                <YAxis
                  key={`yAxis-bar-chart-${id}`}
                  yAxisId={`yAxis-${index}`}
                  name={currentAxis.label}
                  orientation={settings.axis.side ? settings.axis.side : 'left'}
                  scale={scale || 'linear'}
                  tick={{ fontSize: 10 }}
                  type={type || 'number'}
                  allowDataOverflow={
                    // eslint-disable-next-line no-unneeded-ternary
                    minValue !== null && maxValue !== null ? true : false
                  }
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  width={90}
                >
                  <Label
                    angle={-90}
                    fontSize="12px"
                    position={settings.axis.side ? (settings.axis.side === 'left' ? 'insideLeft' : 'insideRight') : 'insideLeft'}
                    fill="#bebebe"
                    style={{ textAnchor: 'middle' }}
                    value={currentAxis.label || (metric && metric.value_type && metric.value_type.unit)}
                  />
                </YAxis>
              );
            })
          }
          {
            (settings && settings.appearance && settings.appearance.grid) &&
            <CartesianGrid
              strokeDasharray="5 5"
            />
          }
          {(settings && settings.appearance && settings.appearance.tooltip) &&
            <ReTooltip
              contentStyle={{ fontSize: 12 }}
              labelStyle={{ fontSize: 12 }}
            />}
          {(settings && settings.legend && settings.legend.show) &&
            <Legend content={this.legendRender} />}
          {
            widgetData.sort((a, b) => { return a.id - b.id; }).filter((wD) => !wD.style.isCalculatedValue).map(({
              label: widgetLabel,
              style,
              metric,
              id,
            }, index) => {
              if (!metric) return '';

              if (metric && metric.value_type) {
                if (Object.prototype.hasOwnProperty.call(valueTypeYAxisMapping, metric.value_type.id)) {
                  leftYAxisId = `yAxis-${valueTypeYAxisMapping[metric.value_type.id]}`;
                }
              } else {
                leftYAxisId = `yAxis-${index}`;
              }

              if (settings && settings.appearance && settings.appearance.stack_bars) {
                return (
                  <Bar
                    isAnimationActive={false}
                    key={`histogram-${id}`}
                    stackId="data"
                    yAxisId={leftYAxisId}
                    dataKey={id}
                    fill={(style && style.color && style.color.r && style.color.g && style.color.b) ? `rgb(${style.color.r}, ${style.color.g}, ${style.color.b})` : style && style.isCalculatedValueUsage ? '#2ecc71' : colorIndex[index]}
                    fillOpacity={(style && style.color && style.color.a) ? style.color.a : 1}
                    maxBarSize={100}
                    name={widgetLabel || formatLabel(metric)}
                    hide={!!this.selectedLabel(id)}
                  />
                );
              }
              return (
                <Bar
                  isAnimationActive={false}
                  key={`histogram-${id}`}
                  yAxisId={leftYAxisId}
                  dataKey={id}
                  fill={(style && style.color && style.color.r && style.color.g && style.color.b) ? `rgb(${style.color.r}, ${style.color.g}, ${style.color.b})` : style && style.isCalculatedValueUsage ? '#2ecc71' : colorIndex[index]}
                  fillOpacity={(style && style.color && style.color.a) ? style.color.a : 1}
                  maxBarSize={100}
                  name={widgetLabel || formatLabel(metric)}
                  hide={!!this.selectedLabel(id)}
                />
              );
            })
          }
          {
            settings && settings.annotation && settings.annotation.value && annotationYAxisId ?
              <ReferenceLine
                key="line-widget-data-annotation"
                yAxisId={annotationYAxisId}
                y={parseFloat(settings.annotation.value)}
                stroke={settings.annotation.color || '#3182bd'}
                strokeWidth={settings.annotation.thickness || 1}
                strokeDasharray={settings.annotation.dashed ? '5 5' : '10000'}
              >
                <Label value={settings.annotation.value || 'Threshold'} position="right" style={{ fontSize: 11 }} fill="#666" />
              </ReferenceLine> : ''
          }
          {
            fetchEvents &&
            this.renderEventReferences(eventsData, leftYAxisId)
          }
        </ReBarChart>
      </div>
    );
  }
}

Histogram.defaultProps = {
  data: {},
  widgetData: [],
  setDateRange: () => { },
  settings: {},
  enableZoom: true,
};

Histogram.propTypes = {
  data: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  widgetData: PropTypes.array,
  eventsData: PropTypes.object,
  settings: PropTypes.object,
};

export default Histogram;
