import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { Table, Button, Notification, ConfirmationModal } from 'shared';
import copyImage from 'shared/Icons/FilePlus.png';
import { defaultDateTimeFormat } from 'shared/constants';
import { numberSeparatorFormat } from 'industry/helpers';
import { IconEdit, IconWarning, IconRemove } from 'shared/Icons';
import Tooltip from 'rc-tooltip';
import { colors } from 'shared/colors';
import { getLocale } from 'shared/DatePicker/constants';
import { editBooking } from '../../../actions';

class OrderBookingItemsTable extends Component {
  constructor() {
    super();
    this.state = {
      booking_date: moment(),
      ts_start: null,
      ts_end: null,
      showConfirmationDialog: false,
      confirmationTitle: '',
      confirmationType: null,
      deleteData: null,
    };
  }

  onBookingDateChange = (t) => {
    const formatedDate = moment(t);
    this.setState({
      booking_date: formatedDate._d,
    });
  }

  onDateChange = (key, value) => {
    const formatedDate = moment(value);
    this.setState({
      [key]: formatedDate._d,
    }, () => {
      this.editBookingItem();
    });
  }

  editBookingItem = () => {
    const { ts_start, ts_end } = this.state;
    const { selectedBooking } = this.props;

    const selectedBookingId = selectedBooking?.id || null;
    const data = {
      ts_start,
      ts_end,
    };

    editBooking(selectedBookingId, data)
      .then(() => {
        return Notification('success', 'Save successful', 'Date was successfully updated.');
      })
      .catch(() => {
        return Notification('error', 'An error occurred.');
      });
  }

  getCellValue = (row) => {
    const { i18n } = this.props;

    const unitCode = row.original && row.original.order_item && row.original.order_item.unit_code ? row.original.order_item.unit_code : null;
    const cellValue = row.value || null;

    if (unitCode === 'PCE') {
      return numberSeparatorFormat(i18n.language, cellValue, 0, 0, true);
    }
    return numberSeparatorFormat(i18n.language, cellValue, 3, 3, true);
  }

  handleDuplicate = (row) => {
    const { handleDuplicate } = this.props;

    const orderItemBookingId = row.original.id || null;
    const data = {};

    handleDuplicate(orderItemBookingId, data);
  }

  handleShowConfirmDelete = (row) => {
    const { t } = this.props;

    const title = t('page_content.orders.order_details.bookings_tab.delete_tooltip_confirm');

    const orderItemBookingId = row.original.id || null;
    this.setState({ showConfirmationDialog: true, confirmationTitle: title, confirmationType: 'delete', deleteData: orderItemBookingId });
  }

  markDuplicateData = (bookingItems) => {
    const data = [];

    for (let i = 0; i < bookingItems.length; i++) {
      const item = bookingItems[i];
      const index = data.findIndex((el) => el.order_item.name === item.order_item.name);

      if (index !== -1) {
        data.push({ ...item, duplicate: true });
      } else {
        data.push({ ...item, duplicate: false });
      }
    }

    return data;
  }

  bookGoodsConfirm = () => {
    const { booking_date } = this.state;
    const { t } = this.props;

    const title = t('page_content.orders.order_details.bookings_tab.book_tooltip_confirm');
    this.setState({ showConfirmationDialog: true, confirmationTitle: title, confirmationType: 'bookGoods', deleteData: booking_date });
  }

  bookReceipt = () => {
    const { booking_date } = this.state;
    const { t } = this.props;

    const title = t('page_content.orders.order_details.bookings_tab.book_tooltip_confirm');
    this.setState({ showConfirmationDialog: true, confirmationTitle: title, confirmationType: 'bookReceipt', deleteData: booking_date });
  }

  handleConfirmModal = () => {
    const { confirmationType, deleteData } = this.state;
    const { handleDelete, bookGoods, bookReceipt } = this.props;
    const data = deleteData;
    if (confirmationType === 'delete') {
      handleDelete(data, {});
    }
    if (confirmationType === 'bookGoods') {
      bookGoods(data);
    }
    if (confirmationType === 'bookReceipt') {
      bookReceipt(data);
    }
    this.setState({ showConfirmationDialog: false, confirmationType: null, confirmationTitle: null });
  }

  render() {
    const { booking_date, ts_start, ts_end } = this.state;
    const {
      t,
      bookingItems,
      isLoadingBookingItems,
      isReadOnly,
      handleEdit,
      handleEditToBook,
      selectedBooking,
      handleEditBatchAndWarehouse,
      getUserByID,
      handleReturnButton,
      company_short_code,
      exportSAPBookings,
      generateForERP,
      openFinalBookingModal,
      isLoadingRequest,
      enable_book_tt_and_goods,
      isAscaliaAdmin,
      always_allow_booking_confirmation,
      loadingSapStatus,
      bookingSapStatus,
      handleSapStatusMessage,
      handleSapStatusColor,
      isOrderBookingAdmin,
      enable_book_receipt,
      isOrderBookingOnlyEdit,
    } = this.props;

    const disableBookTTLogic = always_allow_booking_confirmation === true ?
      (selectedBooking && selectedBooking.booked_confirmation === true) :
      (selectedBooking && (selectedBooking.booked_confirmation || selectedBooking.booked_goods === false));

    return (
      <div className="order-booking-items">
        <div className="order-booking-items__buttons">

          <Button
            type="plain"
            onClick={handleReturnButton}
          >
            {t('page_content.orders.order_details.bookings_tab.return_button')}
          </Button>
          {
            company_short_code === 'podravka' &&
            <Button
              type="export"
              onClick={exportSAPBookings}
              disabled={isReadOnly || isOrderBookingOnlyEdit}
            >
              {t('page_content.orders.order_details.bookings_tab.export_SAP')}
            </Button>
          }
          {
            company_short_code === 'podravka' &&
            <Button
              type="dark-blue"
              onClick={generateForERP}
              disabled={
                (selectedBooking && (selectedBooking.booked_confirmation || selectedBooking.booked_goods)) ||
                isLoadingRequest ||
                isReadOnly ||
                isOrderBookingOnlyEdit ||
                selectedBooking?.is_intermediary_booking
              }
            >
              {t('page_content.orders.order_details.bookings_tab.generate_for_ERP')}
            </Button>
          }
          {
            company_short_code === 'podravka' &&
            <Button
              type="dark-blue"
              onClick={() => openFinalBookingModal(booking_date)}
              disabled={
                (selectedBooking?.booking_type !== 'confirmation' && disableBookTTLogic) ||
                (selectedBooking?.booking_type === 'confirmation' && selectedBooking?.booked_confirmation) ||
                isLoadingRequest ||
                isReadOnly ||
                (!enable_book_tt_and_goods && !isAscaliaAdmin) ||
                (selectedBooking?.booking_type === 'raw' || selectedBooking?.booking_type === 'packaging') ||
                isOrderBookingOnlyEdit ||
                selectedBooking?.is_intermediary_booking
              }
            >
              {t('page_content.orders.order_details.bookings_tab.book_tt')}
            </Button>
          }
          {
            company_short_code === 'podravka' &&
            <Button
              type="dark-blue"
              onClick={this.bookGoodsConfirm}
              disabled={
                (selectedBooking && selectedBooking.booked_goods === true) ||
                isLoadingRequest ||
                isReadOnly ||
                (!enable_book_tt_and_goods && !isAscaliaAdmin) ||
                selectedBooking?.booking_type === 'confirmation' ||
                isOrderBookingOnlyEdit ||
                selectedBooking?.is_intermediary_booking
              }
            >
              {t('page_content.orders.order_details.bookings_tab.book_goods')}
            </Button>
          }
          {
            enable_book_receipt &&
            <Button
              type="dark-blue"
              onClick={this.bookReceipt}
              disabled={
                (selectedBooking && selectedBooking.booked_receipt === true) ||
                isLoadingRequest ||
                isReadOnly ||
                selectedBooking?.booking_type === 'confirmation' ||
                (selectedBooking?.booking_type === 'raw' || selectedBooking?.booking_type === 'packaging') ||
                isOrderBookingOnlyEdit ||
                selectedBooking?.is_intermediary_booking
              }
            >
              {t('page_content.orders.order_details.bookings_tab.book_receipt')}
            </Button>
          }

          <Button
            className="sap_status_button"
            type={handleSapStatusColor(bookingSapStatus.status)}
            loading={loadingSapStatus}
            loadingText={`SAP Status: ${t('page_content.orders.order_details.bookings_tab.sap_check')}`}
            disabled={loadingSapStatus}
            tooltip={bookingSapStatus.msg || ''}
          >
            {`SAP Status: ${handleSapStatusMessage(bookingSapStatus.status)}`}
          </Button>
        </div>

        <div className="booking_container">
          <div className="left_container">
            <div className="booking_item_info">
              <div className="booking_item_info__card">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p className="booking_item_info__card__label">
                    <span className="key">{t('page_content.orders.order_details.bookings_tab.booking_date')}</span>
                  {!selectedBooking.booked_confirmation && !selectedBooking.booked_goods &&
                      <ReactDatePicker
                        placeholderText={t('settings.ascalia_audit_log.select_start_date_placeholder')}
                        dateFormat="dd.MM.yyyy"
                        selected={booking_date ? moment(booking_date).toDate() : null}
                        maxDate={moment().toDate()}
                        selectsStart
                        onChange={(e) => this.onBookingDateChange(e)}
                        showTimeSelect={false}
                        disabledKeyboardNavigation
                        locale={getLocale(t)}
                      />}
                    {selectedBooking.booking_date && moment(selectedBooking.booking_date).format(defaultDateTimeFormat)}</p>
                  <p className="booking_item_info__card__label">
                    <span className="key">{t('page_content.orders.order_details.bookings_tab.booking')}: </span>{selectedBooking && selectedBooking.booked_at ? moment(selectedBooking.booked_at).format(defaultDateTimeFormat) : ''}</p>

                  <p className="booking_item_info__card__label">
                    <span className="key">{t('page_content.orders.order_details.bookings_tab.table_column_shift')}: </span> {selectedBooking.shift || ''}</p>
                </div>
              </div>

              {
                (selectedBooking?.booking_type === 'all' || selectedBooking?.booking_type === 'confirmation') &&
                <div className={`booking_item_info__card__label${selectedBooking?.booked_confirmation ? '-true' : '-false'}`}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p className="booking_item_info__card__label">
                      <span className="key">{t('page_content.orders.order_details.bookings_tab.booked_tt')} </span>
                      {selectedBooking?.booked_confirmation ? t('page_content.orders.order_details.bookings_tab.yes') : t('page_content.orders.order_details.bookings_tab.no')}
                      {selectedBooking?.booked_confirmation_at ? `, ${moment(selectedBooking.booked_confirmation_at).format(defaultDateTimeFormat)}` : ''}
                      {selectedBooking?.booked_confirmation_user ? `, ${getUserByID(selectedBooking.booked_confirmation_user, ['first_name', 'last_name'])}` : ''}</p>
                  </div>
                </div>
              }

              {
                (selectedBooking?.booking_type === 'raw' || selectedBooking?.booking_type === 'all') &&
                <div className={`booking_item_info__card__label${selectedBooking?.booked_goods ? '-true' : '-false'}`}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p className="booking_item_info__card__label">
                      <span className="key">{t('page_content.orders.order_details.bookings_tab.booked_goods')} </span>
                      {selectedBooking?.booked_goods ? t('page_content.orders.order_details.bookings_tab.yes') : t('page_content.orders.order_details.bookings_tab.no')}
                      {selectedBooking?.booked_goods_at ? `, ${moment(selectedBooking.booked_goods_at).format(defaultDateTimeFormat)}` : ''}
                      {selectedBooking?.booked_goods_user ? `, ${getUserByID(selectedBooking.booked_goods_user, ['first_name', 'last_name'])}` : ''}</p>
                  </div>
                </div>
              }

              {
                enable_book_receipt &&
                <div className={`booking_item_info__card__label${selectedBooking?.booked_receipt ? '-true' : '-false'}`}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p className="booking_item_info__card__label">
                      <span className="key">{t('page_content.orders.order_details.bookings_tab.booked_receipt')} </span>
                      {selectedBooking?.booked_receipt ? t('page_content.orders.order_details.bookings_tab.yes') : t('page_content.orders.order_details.bookings_tab.no')}
                      {selectedBooking?.booked_receipt_at ? `, ${moment(selectedBooking.booked_receipt_at).format(defaultDateTimeFormat)}` : ''}
                      {selectedBooking?.booked_receipt_user ? `, ${getUserByID(selectedBooking.booked_receipt_user, ['first_name', 'last_name'])}` : ''}</p>
                  </div>
                </div>
              }

              <div className="booking_item_info__card">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {!selectedBooking.booked_confirmation ?
                    <p className="booking_item_info__card__label">
                      <span className="key">{t('page_content.orders.order_details.bookings_tab.start_of_booking')}: </span>
                      <ReactDatePicker
                        disabled={!isOrderBookingAdmin}
                        placeholderText=""
                        dateFormat="dd.MM.yyyy HH:mm"
                        timeFormat="HH:mm"
                        showTimeSelect
                        selected={ts_start || (selectedBooking && selectedBooking.ts_start ? moment(selectedBooking.ts_start).toDate() : null)}
                        maxDate={moment().toDate()}
                        selectsStart
                        onChange={(e) => this.onDateChange('ts_start', e)}
                        disabledKeyboardNavigation
                        locale={getLocale(t)}
                      /></p> :
                    <span className="value" style={{ marginLeft: '5px' }}>{selectedBooking.ts_start ? moment(selectedBooking.ts_start).format(defaultDateTimeFormat) : ''}</span>}

                  {!selectedBooking.booked_confirmation ?
                    <p className="booking_item_info__card__label">
                      <span className="key">{t('page_content.orders.order_details.bookings_tab.end_of_booking')}: </span>
                      <ReactDatePicker
                        disabled={!isOrderBookingAdmin}
                        placeholderText=""
                        dateFormat="dd.MM.yyyy HH:mm"
                        timeFormat="HH:mm"
                        showTimeSelect
                        selected={ts_end || (selectedBooking && selectedBooking.ts_end ? moment(selectedBooking.ts_end).toDate() : null)}
                        maxDate={moment().toDate()}
                        selectsStart
                        onChange={(e) => this.onDateChange('ts_end', e)}
                        disabledKeyboardNavigation
                        locale={getLocale(t)}
                      /></p> :
                    <span className="value" style={{ marginLeft: '5px' }}>{selectedBooking.ts_end ? moment(selectedBooking.ts_end).format(defaultDateTimeFormat) : ''}</span>}

                </div>
              </div>
            </div>
          </div>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_item')}</span>,
              accessor: 'order_item',
              Cell: (row) => (<span>
                {(!row.original.warehouse || !row.original.batch) &&
                  row.original.order_item.group_name !== 'Stavke' &&
                  row.original.order_item.unit_code !== 'HUR' &&
                  !row.original.duplicate ?
                  <Tooltip
                    id="tooltip-is_admin"
                    trigger={['hover']}
                    placement="top"
                    overlay={t('page_content.orders.order_details.bookings_tab.warning_tooltip')}
                    overlayClassName="where-filter-tooltip"
                  >
                    <span
                      aria-describedby="tooltip-is_admin"
                    >
                      <IconWarning
                        color={colors.red}
                        height="16px"
                        width="16px"
                      />
                    </span>
                  </Tooltip>
                  : ''}
                {row.original.duplicate ? '' : row?.value?.name ?
                  <span>
                    {row?.value?.name}
                    <span style={{ color: 'red' }}>
                      {row?.original?.is_scrap ? ` - ${t('settings.product_types.is_scrap')}` : ''}
                    </span>
                  </span>
                  : '-'}
              </span>),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_batch')}</span>,
              // accessor: 'batch',
              minWidth: 90,
              maxWidth: 350,
              Cell: (row) => (
                !isReadOnly &&
                  row.original.order_item.unit_code !== 'HUR' &&
                  !selectedBooking.booked_goods &&
                  !selectedBooking.booked_confirmation &&
                  (enable_book_receipt && selectedBooking.booked_receipt ? row.index !== 0 : true) ?
                  <div
                    style={{ display: 'flex', justifyContent: 'center' }}
                    onClick={() => handleEditBatchAndWarehouse(row)}
                  >
                    <span>
                      {row.original.batch ? row.original.batch : '-'}<br />
                      {row.original.batch_source ? <small><strong>{row.original.batch_source.toUpperCase()}</strong></small> : ''}
                    </span>
                    <span style={{ marginLeft: '10px' }}>
                      <IconEdit
                        height="14px"
                        width="14px"
                        fill="#4285F4"
                      />
                    </span>
                  </div> :
                  <span>
                    {row.original.batch ? row.original.batch : '-'}<br />
                    {row.original.batch_source ? <small><strong>{row.original.batch_source.toUpperCase()}</strong></small> : ''}
                  </span>
              ),
              style: {
                cursor: (row) => (isReadOnly && row.original.order_item.unit_code === 'HUR' && !selectedBooking.booked_goods ? 'default' : 'pointer'),
              },
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_warehouse')}</span>,
              accessor: 'warehouse',
              minWidth: 70,
              maxWidth: 150,
              Cell: (row) => (
                !isReadOnly &&
                  row.original.order_item.unit_code !== 'HUR' &&
                  !selectedBooking.booked_goods &&
                  !selectedBooking.booked_confirmation &&
                  row.original.order_item.group_name !== 'Stavke' &&
                  (enable_book_receipt && selectedBooking.booked_receipt ? row.index !== 0 : true) ?
                  <div
                    style={{ display: 'flex', justifyContent: 'center' }}
                    onClick={() => handleEditBatchAndWarehouse(row)}
                  >
                    <span>{row.value ? row.value : '-'}</span>
                    <span style={{ marginLeft: '10px' }}>
                      <IconEdit
                        height="14px"
                        width="14px"
                        fill="#4285F4"
                      />
                    </span>
                  </div> :
                  <span>{row.value ? row.value : '-'}</span>

              ),
              style: {
                cursor: (row) => (isReadOnly && row.original.order_item.unit_code === 'HUR' && !selectedBooking.booked_goods ? 'default' : 'pointer'),
              },
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_quantity_total')}</span>,
              accessor: 'order_item.quantity',
              minWidth: 70,
              maxWidth: 300,
              Cell: (row) => <span>{row.original.duplicate ? '' : this.getCellValue(row)}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_quantity_counted')}</span>,
              accessor: 'quantity_plc',
              minWidth: 70,
              maxWidth: 300,
              Cell: (row) => <span>{row.original.duplicate ? '' : this.getCellValue(row)}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_quantity_to_book')}</span>,
              accessor: 'quantity_total_booked',
              minWidth: 70,
              maxWidth: 300,
              Cell: (row) => (
                (
                  !isReadOnly &&
                    !selectedBooking.booked_goods &&
                    !row.original.duplicate &&
                    !selectedBooking.booked_confirmation &&
                    (enable_book_receipt && selectedBooking.booked_receipt ? row.index !== 0 : true) ?
                    <div
                      style={{ display: 'flex', justifyContent: 'center' }}
                      onClick={() => handleEditToBook(row)}
                    >
                      <span>{this.getCellValue(row)}</span>
                      <span style={{ marginLeft: '10px' }}>
                        <IconEdit
                          height="14px"
                          width="14px"
                          fill="#4285F4"
                        />
                      </span>
                    </div> :
                    <span>{row.original.duplicate ? '' : this.getCellValue(row)}</span>
                )

              ),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_quantity_booked')}</span>,
              accessor: 'quantity_booked',
              minWidth: 70,
              maxWidth: 300,
              style: { background: 'rgba(50,50,50,.1)' },
              Cell: (row) => (
                (
                  !isReadOnly &&
                    !selectedBooking.booked_goods &&
                    !selectedBooking.booked_confirmation &&
                    (enable_book_receipt && selectedBooking.booked_receipt ? row.index !== 0 : true) ?
                    <div
                      style={{ display: 'flex', justifyContent: 'center' }}
                      onClick={() => handleEdit(row)}
                    >
                      <span>
                        {numberSeparatorFormat('en', row.value, 3, 3, false) !== '0,000' ?
                          this.getCellValue(row) :
                          <span>
                            {this.getCellValue(row)}
                            <IconWarning
                              color={colors.red}
                              height="16px"
                              width="16px"
                            />
                          </span>}
                      </span>
                      <span style={{ marginLeft: '10px' }}>
                        <IconEdit
                          height="14px"
                          width="14px"
                          fill="#4285F4"
                        />
                      </span>
                    </div> :
                    <span>{this.getCellValue(row)}</span>
                )

              ),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_batch_remaining')}</span>,
              accessor: 'batch_remaining',
              minWidth: 70,
              maxWidth: 300,
              Cell: (row) => <span>{this.getCellValue(row)}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_quantity_remaining')}</span>,
              accessor: 'quantity_remaining',
              minWidth: 70,
              maxWidth: 300,
              Cell: (row) => <span>{this.getCellValue(row)}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_unit')}</span>,
              accessor: 'order_item.unit_code',
              minWidth: 50,
              maxWidth: 200,
              Cell: (row) => <span>{row.original.duplicate ? '' : row && row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_duplicate')}</span>,
              minWidth: 50,
              maxWidth: 200,
              Cell: (row) => {
                if (!isReadOnly &&
                  !selectedBooking.booked_goods &&
                  !selectedBooking.booked_confirmation &&
                  (enable_book_receipt && selectedBooking.booked_receipt ? row.index !== 0 : true)) {
                  return (
                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                      <Tooltip
                        id="tooltip-is_admin"
                        trigger={['hover']}
                        placement="top"
                        overlay={t('page_content.orders.order_details.bookings_tab.duplicate_tooltip')}
                        overlayClassName="where-filter-tooltip"
                      >
                        <span onClick={() => this.handleDuplicate(row)}><img src={copyImage} alt="+" style={{ height: '24px', width: '23px' }} /></span>
                      </Tooltip>
                      <Tooltip
                        id="tooltip-is_admin"
                        trigger={['hover']}
                        placement="top"
                        overlay={t('page_content.orders.order_details.bookings_tab.delete_tooltip')}
                        overlayClassName="where-filter-tooltip"
                      >
                        <span onClick={() => this.handleShowConfirmDelete(row)}><IconRemove color="#364252" width="20px" height="20px" /></span>
                      </Tooltip>
                    </div>
                  );
                }
                return null;
              },
            },

          ]}
          data={this.markDuplicateData(bookingItems) || []}
          minRows={0}
          defaultPageSize={100}
          noDataText=" "
          showPagination={false}
          sortable={false}
          loading={isLoadingBookingItems}
        />
        <ConfirmationModal
          customTitle={this.state.confirmationTitle}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleConfirmModal}
          type="warning"
        />
      </div>
    );
  }
}

OrderBookingItemsTable.propTypes = {
  i18n: PropTypes.object,
  t: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleEditToBook: PropTypes.func.isRequired,
  bookingItems: PropTypes.array.isRequired,
  isLoadingBookingItems: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  selectedBooking: PropTypes.object.isRequired,
  handleEditBatchAndWarehouse: PropTypes.func.isRequired,
  getUserByID: PropTypes.func.isRequired,
  handleReturnButton: PropTypes.func.isRequired,
  company_short_code: PropTypes.string.isRequired,
  exportSAPBookings: PropTypes.func.isRequired,
  generateForERP: PropTypes.func.isRequired,
  openFinalBookingModal: PropTypes.func.isRequired,
  bookGoods: PropTypes.func.isRequired,
  isLoadingRequest: PropTypes.bool.isRequired,
  handleDuplicate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  enable_book_tt_and_goods: PropTypes.bool,
  isAscaliaAdmin: PropTypes.bool,
  always_allow_booking_confirmation: PropTypes.bool,
  bookingSapStatus: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  loadingSapStatus: PropTypes.bool.isRequired,
  handleSapStatusMessage: PropTypes.func.isRequired,
  handleSapStatusColor: PropTypes.func.isRequired,
  isOrderBookingAdmin: PropTypes.bool,
  enable_book_receipt: PropTypes.bool,
  bookReceipt: PropTypes.func,
  isOrderBookingOnlyEdit: PropTypes.bool,
};

export default withTranslation()(OrderBookingItemsTable);
