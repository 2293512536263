import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Button, Table, TableButtons } from 'shared';
import { defaultDateTimeFormat } from 'shared/constants';
import { downloadXlsx } from '../actions';
import '../styles.scss';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAscDesc: 'desc',
      selectedSort: 'updated_at',
    };
  }

  openPDF = (row) => {
    const pdf = row.original.pdf || null;
    if (pdf) {
      const a = document.createElement('a');
      a.href = pdf;
      a.download = true;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  downloadExcel = (row) => {
    const companyId = row.original.company || null;
    const reportId = row.original.report_id || null;
    downloadXlsx(companyId, reportId)
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'export.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  redirectToDetails = (row, e) => {
    const selection = window.getSelection();
    if (selection.toString().length === 0) {
      const { companyId, locationId, history } = this.props;
      const reportId = row.original.id;
      if (e.metaKey || e.ctrlKey) {
        window.open(`/${companyId}/industry/location/${locationId}/5S/${reportId}`, '_blank');
      } else {
        history.push(`/${companyId}/industry/location/${locationId}/5S/${reportId}`);
      }
    }
  }

  render() {
    const {
      t,
      displayUserName,
      reports,
      isLoadingReports,
      next,
      previous,
      fetchReportsByUrl,
      count,
      handleSorting,
    } = this.props;

    return (
      <div>
        <Table
          columns={[
            {
              Header: () => <span>{t('page_content.5S.table_column_created_by')}</span>,
              accessor: 'report_lead',
              Cell: (row) => (row && row.value ? displayUserName(row.value) : ' '),
            },
            {
              Header: () => <span>{t('page_content.5S.table_column_created_at')}</span>,
              accessor: 'created_at',
              Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
            },
            {
              Header: () => <span>{t('page_content.5S.table_column_last_updated')}</span>,
              accessor: 'updated_at',
              Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
            },
            {
              Header: () => <span>{t('page_content.5S.table_column_status')}</span>,
              accessor: 'status',
              Cell: (row) => (
                <div className="status_wrapper">
                  <span className={`order-list__status ${row.value}`}>{row.value ? t([`page_content.5S.statuses.${row.value}`]) : ''}</span>
                </div>
              ),
            },
            {
              Header: () => <span>{t('page_content.5S.table_column_export')}</span>,
              accessor: 'pdf',
              sortable: false,
              Cell: (row) => (row.value ?
                <span className="column_buttons_wrapper">
                  <Button type="export" onClick={() => this.openPDF(row)}>{t('page_content.5S.button_PDF')}</Button>
                  <Button type="export" onClick={() => this.downloadExcel(row)}>{t('page_content.5S.button_excel')}</Button>
                </span>
                : ''),
            },
          ]}
          data={reports}
          minRows={0}
          loading={isLoadingReports}
          defaulfPageSize={30}
          noDataText=" "
          showPagination={false}
          handleClick={(rowInfo, e) => this.redirectToDetails(rowInfo, e)}
          defaultSorted={[{ id: 'updated_at', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons next={next} previous={previous} fetchFunction={fetchReportsByUrl} count={count} />
      </div>
    );
  }
}

Reports.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  displayUserName: PropTypes.func.isRequired,
  reports: PropTypes.array.isRequired,
  isLoadingReports: PropTypes.bool.isRequired,
  next: PropTypes.string,
  previous: PropTypes.string,
  fetchReportsByUrl: PropTypes.func.isRequired,
  count: PropTypes.number,
  handleSorting: PropTypes.func,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(Reports));
