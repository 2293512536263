import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal, Table, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';
import { createOrderFromItems } from '../actions';

const CreateOrderFromItemsModal = ({
  t,
  isCreateOrderFromItemsModalOpen,
  closeCreateOrderFromItemsModal,
  selectedOrderItemsIds,
  selectedOrderItems,
  selectedProject,
  companyId,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);

    const outputArray = [];

    for (const inputObject of selectedOrderItems) {
      const selectedPartsProduct = inputObject.product_type.id || null;
      const selectedPartsQuantity = inputObject.quantity || null;
      const newObject = { product: selectedPartsProduct, quantity: selectedPartsQuantity };
      outputArray.push(newObject);
    }

    const payload = {
      project: selectedProject || null,
      company: companyId,
      items: selectedOrderItemsIds || [],
      products: outputArray,
    };

    await createOrderFromItems(payload);
    setIsLoading(false);
    closeCreateOrderFromItemsModal();
  };

  const tableColumnConfig = [
    {
      Header: () => <span>{t('page_content.orders.order_items_table.name')}</span>,
      accessor: 'product_type.name',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.orders.order_items_table.description')}</span>,
      accessor: 'product_type.description',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.orders.order_items_table.project')}</span>,
      accessor: 'project.name',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.orders.order_items_table.product_type')}</span>,
      accessor: 'product_type.product_type',
      width: 120,
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.orders.order_items_table.status')}</span>,
      width: 120,
      accessor: 'status',
      Cell: (row) => <span className={`order-list__status ${row.value}`}>{row.value ? t([`page_content.orders.statuses.${row.value}`]) : ''}</span>,
    },
    {
      Header: () => <span>{t('page_content.orders.order_items_table.quantity')}</span>,
      accessor: 'quantity',
      width: 100,
      Cell: (row) => (row.value ? row.value : '-'),
    }, {
      Header: () => <span>{t('page_content.orders.order_items_table.quantity_done')}</span>,
      accessor: 'quantity_done',
      width: 100,
      Cell: (row) => (row.value ? row.value : '-'),
    }];
  return (
    <Modal
      size={modalSizes.full}
      isOpen={isCreateOrderFromItemsModalOpen}
      handleSave={handleSave}
      handleClose={closeCreateOrderFromItemsModal}
      title={t('page_content.orders.confirm_create_order')}
    >
        {
            isLoading ?
            <ContentLoader /> :
            <Table
              style={{ userSelect: 'text' }}
              columns={tableColumnConfig}
              data={selectedOrderItems}
              minRows={0}
              defaultPageSize={selectedOrderItems.length}
              noDataText=" "
              showPagination={false}
            />
        }
    </Modal>
  );
};

CreateOrderFromItemsModal.propTypes = {
  t: PropTypes.func,
  isCreateOrderFromItemsModalOpen: PropTypes.bool.isRequired,
  closeCreateOrderFromItemsModal: PropTypes.func.isRequired,
  selectedOrderItems: PropTypes.array,
  selectedOrderItemsIds: PropTypes.array,
  selectedProject: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default CreateOrderFromItemsModal;
