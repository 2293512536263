/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Select from 'react-select';
import { IconRemove } from 'shared/Icons';
import {
  Button,
} from 'shared';
import DatapointForm from '../DatapointForm';
import { defaultSettings } from '../constants';
import '../styles.scss';

class MapForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.config.id,
      wigdetData: props.config.widget_data,
      settings: props.config.settings || defaultSettings.map,
      title: props.config.title || '',
      type: props.config.type,
    };
    this.saveWidget = this.saveWidget.bind(this);
    this.addDatapoint = this.addDatapoint.bind(this);
  }

  addDatapoint() {
    const wigdetData = this.state.wigdetData.slice(0);
    wigdetData.push({
      metric: null,
      field: null,
      operation: 'last',
      group_by: 'auto',
      style: {
        selectedField: null,
      },
    });
    this.setState({ wigdetData });
  }

  updateDatapoint(numberInArray, widgetDataItem) {
    const wigdetData = this.state.wigdetData.slice(0);
    wigdetData[numberInArray] = widgetDataItem;
    this.setState({
      wigdetData,
    });
  }

  updateAdjustedValue = (val, metricId) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.adjusted_value)) {
      settings.adjusted_value = [];
    }
    const existingValue = settings.adjusted_value.find((aV) => aV.metricId === metricId);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.adjusted_value.push({ value: val, metricId });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateShiftFilter = (val, id) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.shift)) {
      settings.shift = [];
    }
    const existingValue = settings.shift.find((w) => w.id === id);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.shift.push({ value: val, id });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateProductTypeFilter = (val, id) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.productType)) {
      settings.productType = [];
    }
    const existingValue = settings.productType.find((w) => w.id === id);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.productType.push({ value: val, id });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateWhereFilter = (val, metricId) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.where)) {
      settings.where = [];
    }
    const existingValue = settings.where.find((w) => w.metricId === metricId);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.where.push({ value: val, metricId });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  removeDatapoint(numberInArray) {
    const datapoints = this.state.datapoints.slice(0);
    datapoints.splice(numberInArray, 1);
    this.setState({
      datapoints,
    });
  }

  saveWidget() {
    const data = {
      widget_data: this.state.wigdetData.map((x) => {
        x.metric = x.metric.id;
        return x;
      }),
      id: this.state.id,
      title: this.state.title,
      settings: this.state.settings,
    };
    this.props.onSave(data);
  }

  render() {
    const {
      wigdetData,
      settings,
      title,
      type,
    } = this.state;

    const { locationId } = this.props;

    const titleSides = ['left', 'center', 'right'];

    return (
      <div className="default-form">
        <table className="widget-title">
          <tr>
            <td>
              <label htmlFor="widget-title">
                Widget title
              </label>
            </td>
            <td>
              <input
                id="widget-title"
                onChange={(e) => this.setState({ title: e.target.value })}
                placeholder="Enter widget title"
                type="text"
                value={title}
              />
            </td>
          </tr>
        </table>
        <Tabs>
          <TabList>
            <Tab>
              Datapoints
            </Tab>
            <Tab>
              Appearance
            </Tab>
          </TabList>
          <TabPanel>
            {
              wigdetData.map((widgetDataItem, i) => (
                <div key={`widget-data-item-${i}`} className="datapoint-container">
                  <DatapointForm
                    type={type}
                    isFieldNeeded={false}
                    key={i}
                    datapoint={widgetDataItem}
                    onChange={(item) => this.updateDatapoint(i, item)}
                    locationId={locationId}
                    adjustedValue={Array.isArray(settings.adjusted_value) ? settings.adjusted_value.find((aV) => { if (widgetDataItem.metric) { return aV.metricId === widgetDataItem.metric.id; } return false; }) : null}
                    updateAdjustedValue={(val, metricId) => this.updateAdjustedValue(val, metricId)}
                    shift={Array.isArray(settings.shift) ? settings.shift.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id; } return false; }) : null}
                    updateShiftFilter={(val, id) => this.updateShiftFilter(val, id)}
                    productType={Array.isArray(settings.productType) ? settings.productType.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id; } return false; }) : null}
                    updateProductTypeFilter={(val, id) => this.updateProductTypeFilter(val, id)}
                    where={Array.isArray(settings.where) ? settings.where.find((w) => { if (widgetDataItem.metric) { return w.metricId === widgetDataItem.metric.id; } return false; }) : null}
                    updateWhereFilter={(val, metricId) => this.updateWhereFilter(val, metricId)}
                  />
                  <Button
                    onClick={() => this.removeDatapoint(i)}
                    size="small"
                    type="delete"
                  >
                    <IconRemove
                      height="14px"
                      width="14px"
                    />
                  </Button>
                </div>
              ))
            }
            {
              wigdetData.length === 0 &&
              <Button
                onClick={this.addDatapoint}
                type="add"
              >
                New datapoint
              </Button>
            }
          </TabPanel>
          <TabPanel>
            <table className="widget-extras">
              <tbody>
                <tr>
                  <td>
                    Center
                  </td>
                  <td>
                    <input
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          center: {
                            lat: {
                              $set: e.target.value.split(',')[0],
                            },
                            lng: {
                              $set: e.target.value.split(',')[1],
                            },
                          },
                        }),
                      })}
                      placeholder="Enter center point"
                      type="text"
                      value={`${settings.center.lat},${settings.center.lng}`}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Zoom
                  </td>
                  <td>
                    <input
                      onChange={(e) => this.setState({
                        settings: { ...settings, zoom: e.target.value },
                      })}
                      placeholder="Enter zoom"
                      type="text"
                      value={settings.zoom}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Show history
                  </td>
                  <td>
                    <input
                      checked={settings.showHistory}
                      onChange={() => this.setState({
                        settings: { ...settings, showHistory: !settings.showHistory },
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Tracking vehicle
                  </td>
                  <td>
                    <input
                      checked={settings.tracking}
                      onChange={() => this.setState({
                        settings: { ...settings, tracking: !settings.tracking },
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Speed limit
                  </td>
                  <td>
                    <input
                      onChange={(e) => this.setState({
                        settings: { ...settings, speedLimit: e.target.value },
                      })}
                      placeholder="Enter speed limit"
                      type="text"
                      value={settings.speedLimit}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    <label htmlFor="side">
                      Align title text
                    </label>
                  </td>
                  <td className="input">
                    <Select
                      id="side"
                      className="AscaliaSelect"
                      onChange={(v) => {
                        const value = v.value;
                        this.setState({
                          settings: update(settings, {
                            appearance: {
                              title: {
                                $set: value,
                              },
                            },
                          }),
                        });
                      }}
                      options={titleSides.map((dP) => ({
                        label: dP,
                        value: dP,
                      }))}
                      value={settings.appearance.title ? titleSides.map((dP) => ({
                        label: dP,
                        value: dP,
                      })).find((dP) => dP.value === settings.appearance.title) : null}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
        <footer className="modal-footer">
          <Button
            onClick={this.saveWidget}
            title="Save widget config"
            type="success"
          >
            Save changes
          </Button>
          <Button
            onClick={this.props.closeModal}
          >
            Cancel
          </Button>
        </footer>
      </div>
    );
  }
}

MapForm.propTypes = {
  locationId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
};

export default MapForm;
