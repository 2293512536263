import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import './styles.scss';

const Drawer = ({
  children,
  icon,
  isShown,
  onClose,
  size,
  title,
}) => (
  <CSSTransition
    in={isShown}
    timeout={200}
    classNames="fly"
    unmountOnExit
  >
    <div className={`drawer size-${size}`}>
      <header>
        <div className="title">
          {icon}
          <h3>
            {title}
          </h3>
        </div>
        <button
          className="close"
          onClick={onClose}
        >
          &times;
        </button>
      </header>
      <main>
        {children}
      </main>
    </div>
  </CSSTransition>
);

Drawer.propTypes = {
  icon: PropTypes.element,
  isShown: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any,
  size: PropTypes.string.isRequired,
};

Drawer.defaultProps = {
  size: 'md',
  title: 'Details',
};

export default Drawer;
