export const timeRanges = [
  'last_week',
  'last_month',
  'custom_range',
];

export const scheduleTypes = [
  'O',
  'I',
  'H',
  'D',
  'W',
  'M',
];
