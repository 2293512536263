import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { TableButtons } from 'shared/index';
import api from 'helpers/api';
import OrderBatchTable from './components/OrderBatchTable';
import OrderBatchStepsTable from './components/OrderBatchStepsTable';
import { getBatchItems, getBatchList, getUsers } from '../../actions';

const OrderBatches = ({ companyId, t, isProjectBased }) => {
  const [page, setPage] = useState(1);
  const [batches, setBatches] = useState([]);
  const [batchItems, setBatchItems] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedAscDescSteps, setSelectedAscDescSteps] = useState('asc');
  const [selectedSortSteps, setSelectedSortSteps] = useState('step');
  const [selectedAscDesc, setSelectedAscDesc] = useState('desc');
  const [selectedSort, setSelectedSort] = useState('created_at');
  const [tableData, setTableData] = useState({
    count: 0,
    next: null,
    previous: null,
    isLoading: true,
  });
  const [tableDataSteps, setTableDataSteps] = useState({
    count: 0,
    next: null,
    previous: null,
    isLoading: true,
  });

  const getCompanyUsers = async () => {
    const resp = await getUsers(companyId);
    setUsers(get(resp, 'data.results'));
  };

  const getBatches = async () => {
    const resp = await getBatchList(companyId);
    setBatches(get(resp, 'data.results'));
    setTableData({
      count: get(resp, 'data.count'),
      next: get(resp, 'data.next'),
      previous: get(resp, 'data.previous'),
      isLoading: false,
    });
  };

  const fetchBatch = async () => {
    let filters = '';
    const asc = selectedAscDesc === 'desc' ? '-' : '';

    if (selectedSort === 'worker') {
      return;
    }

    filters += `&order_by=${asc}${selectedSort}`;
    const resp = await getBatchList(companyId, filters);
    setBatches(get(resp, 'data.results') || []);
    setTableData({
      count: get(resp, 'data.count'),
      next: get(resp, 'data.next'),
      previous: get(resp, 'data.previous'),
      isLoading: false,
    });
  };

  useEffect(() => {
    getCompanyUsers();
    fetchBatch();
  }, []);

  const getBatchSteps = async (batchId) => {
    const ascSteps = selectedAscDescSteps === 'desc' ? '-' : '';
    const filters = `&order_by=${ascSteps}${selectedSortSteps}`;
    const resp = await getBatchItems(batchId, filters);
    return resp;
  };

  const getPaginatedBatches = (url) => {
    setTableData({ isLoading: true });
    if (page === 1) {
      api.get(url)
        .then((resp) => {
          setBatches(get(resp, 'data.results') || []);
          setTableData({
            count: get(resp, 'data.count'),
            next: get(resp, 'data.next'),
            previous: get(resp, 'data.previous'),
            isLoading: false,
          });
        })
        .catch((e) => console.log('Error while fetching batches data', e));
    } else {
      api.get(url)
        .then((resp) => {
          setBatchItems(get(resp, 'data.results') || []);
          setTableDataSteps({
            count: get(resp, 'data.count'),
            next: get(resp, 'data.next'),
            previous: get(resp, 'data.previous'),
            isLoading: false,
          });
        })
        .catch((e) => console.log('Error while fetching batches steps', e));
    }
  };

  const handleSelectBatch = async (row) => {
    setTableDataSteps({ isLoading: true });
    setPage(2);
    const resp = await getBatchSteps(row?.original?.id);
    setSelectedBatch(row);
    setBatchItems(get(resp, 'data.results') || []);
    setTableDataSteps({
      count: get(resp, 'data.count'),
      next: get(resp, 'data.next'),
      previous: get(resp, 'data.previous'),
      isLoading: false,
    });
  };

  const handleReturnButton = () => {
    setTableData({ isLoading: true });
    setPage(1);
    getBatches();
  };

  const handleSortingSteps = async (sortData) => {
    setSelectedSortSteps(sortData.id);
    setSelectedAscDescSteps(sortData.desc ? 'desc' : 'asc');
  };

  const fetchBatchSteps = async (orderId = '') => {
    const ascSteps = selectedAscDescSteps === 'desc' ? '-' : '';
    const filters = `&order_by=${ascSteps}${selectedSortSteps}`;
    let resp = '';

    if (!selectedBatch) {
      resp = await getBatchItems(orderId, filters);
    } else {
      resp = await getBatchItems(selectedBatch?.original?.id, filters);
    }

    setBatchItems(get(resp, 'data.results') || []);
    setTableDataSteps({
      count: get(resp, 'data.count'),
      next: get(resp, 'data.next'),
      previous: get(resp, 'data.previous'),
      isLoading: false,
    });
    return resp;
  };

  useEffect(() => {
    fetchBatchSteps();
  }, [selectedAscDescSteps, selectedSortSteps]);

  const handleSortingBatches = async (sortData) => {
    setSelectedSort(sortData.id);
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
  };

  useEffect(() => {
    fetchBatch();
  }, [selectedAscDesc, selectedSort]);

  return (
    <React.Fragment>
      {
        page === 1 &&
        <OrderBatchTable
          t={t}
          batches={batches}
          handleSelectBatch={handleSelectBatch}
          users={users}
          isLoadingBatches={tableData.isLoading}
          isProjectBased={isProjectBased}
          handleSortingBatches={handleSortingBatches}
        />
      }

      {
        page === 2 &&
        <OrderBatchStepsTable
          t={t}
          batchSteps={batchItems}
          selectedBatch={selectedBatch}
          handleReturnButton={handleReturnButton}
          isLoadingBatches={tableDataSteps.isLoading}
          isProjectBased={isProjectBased}
          handleSortingSteps={handleSortingSteps}
        />
      }
      {
        <div style={{ float: 'right' }}>
          <TableButtons previous={page === 1 ? tableData.previous : tableDataSteps.previous} next={page === 1 ? tableData.next : tableDataSteps.next} fetchFunction={getPaginatedBatches} count={page === 1 ? tableData.count : tableDataSteps.count} />
        </div>
      }
    </React.Fragment>
  );
};

OrderBatches.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number,
  isProjectBased: PropTypes.bool,
};

export default (withTranslation()(OrderBatches));
