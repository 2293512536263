import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { numberSeparatorFormat } from 'industry/helpers';
import { get } from 'lodash';
import { IconWarning } from 'shared/Icons';
import { colors } from 'shared/colors';
import WidgetPlaceholder from '../WidgetPlaceholder';
import './styles.scss';

class OrdersTable extends Component {
  redirectToOrder = (order) => {
    const url = window.location.href;

    const lastDashboardsIndex = url.lastIndexOf('dashboards');

    let newUrl = url.substring(0, lastDashboardsIndex);

    newUrl += `orders/${order.id}`;

    window.open(newUrl, '_blank');
  }

  render() {
    const { data, height, widgetData, t, settings, i18n } = this.props;

    if (!widgetData) {
      return <WidgetPlaceholder height={height} type="table" />;
    }

    return (
      <div className="table-widget" style={{ height: `${height}px` }}>
        {
          (!settings || !settings.assets_in || !settings.assets_in.length || !settings.columns || !settings.columns.length) ?
            <IconWarning color={colors.red} height="16px" width="16px" /> : ''
        }
        <table>
          <thead>
            {
              data && data.length && (settings && settings.assets_in && settings.assets_in.length && settings.columns && settings.columns.length) ?
                <tr>
                  {settings.columns.some((obj) => obj.id === 'external_id') && <th>{t('page_content.dashboards.orders_table.id')}</th>}
                  {settings.columns.some((obj) => obj.id === 'customer_name') && <th>{t('page_content.dashboards.orders_table.name')}</th>}
                  {settings.columns.some((obj) => obj.id === 'lines') && <th>{t('page_content.dashboards.orders_table.lines')}</th>}
                  {settings.columns.some((obj) => obj.id === 'status') && <th>{t('page_content.dashboards.orders_table.status')}</th>}
                  {settings.columns.some((obj) => obj.id === 'items_done') && <th>{t('page_content.dashboards.orders_table.items_done')}</th>}
                  {settings.columns.some((obj) => obj.id === 'items_total') && <th>{t('page_content.dashboards.orders_table.items_total')}</th>}
                </tr> : ''
            }
          </thead>
          <tbody>
            {!get(data, 'length') && <p>{t('page_content.dashboards.orders_table.no_data')}</p>}
            {
              data && data.length > 0 &&
                (settings && settings.assets_in && settings.assets_in.length && settings.columns && settings.columns.length) ?
                data.map((x) => {
                  const lines = x.lines && Object.keys(x.lines).length !== 0 ? Object.values(x.lines).join(', ') : '-';

                  return (
                    <tr onClick={() => { this.redirectToOrder(x); }}>
                      {settings.columns.some((obj) => obj.id === 'external_id') ? <td>{x.external_id}</td> : ''}
                      {settings.columns.some((obj) => obj.id === 'customer_name') ? <td>{x.customer_name}</td> : ''}
                      {settings.columns.some((obj) => obj.id === 'lines') ? <td>{lines}</td> : ''}
                      {settings.columns.some((obj) => obj.id === 'status') ? <td>{t([`shared.statuses.${x.status}`])}</td> : ''}
                      {settings.columns.some((obj) => obj.id === 'items_done') ? <td>{`${numberSeparatorFormat(i18n.language, x.items_done, 0, 0, true)}`}</td> : ''}
                      {settings.columns.some((obj) => obj.id === 'items_total') ? <td>{`${numberSeparatorFormat(i18n.language, x.items_total, 0, 0, true)}`}</td> : ''}
                    </tr>
                  );
                }) : ''
            }
          </tbody>
        </table>
      </div>
    );
  }
}

OrdersTable.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
  widgetData: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  settings: PropTypes.object,
  i18n: PropTypes.object,
};

OrdersTable.defaultProps = {
  data: [],
  widgetData: [],
};

export default withTranslation()(OrdersTable);
