import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const TableLoader = ({ theme, loading }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <Fragment>
    {loading &&
      <div className={`table-loader theme-${theme} -loading -active`}>
        <div className="-loading-inner">
          <div className="loader" />
        </div>
      </div>}
  </Fragment>
);

TableLoader.propTypes = {
  loading: PropTypes.bool,
  theme: PropTypes.string,
};

TableLoader.defaultProps = {
  loading: false,
  theme: 'white',
};

export default TableLoader;
