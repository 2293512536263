import React from 'react';

// eslint-disable-next-line
export const sizeMeHack = (WrappedComponent)  => {
  return class extends React.Component {
    render() {
      // eslint-disable-next-line
      const { size } = this.props;
      // eslint-disable-next-line react/prop-types
      return <WrappedComponent width={size && size.width} {...this.props} />;
    }
  };
};
