import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Select from 'react-select';
import api from 'helpers/api';

class DevicePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDevice: props.device,
      options: [],
    };
  }

  componentDidMount() {
    const { locationId, companyId } = this.props;
    if (locationId) {
      api.get(`/api/v1/industry/devices/?company=${companyId}&location=${locationId}&order_by=name&limit=200`)
        .then((json) => this.setState({
          options: get(json, 'data.results'),
        }));
    } else {
      api.get(`/api/v1/industry/devices/?company=${companyId}&order_by=name&limit=200`)
        .then((json) => this.setState({
          options: get(json, 'data.results'),
        }));
    }
  }

  componentDidUpdate(prevProps) {
    const { device } = this.props;
    if (prevProps.device !== device) {
      // eslint-disable-next-line
      this.setState({ selectedDevice: device });
    }
  }

  handleChange = (device) => {
    const { changeDevice } = this.props;
    this.setState({ selectedDevice: device });
    changeDevice(device);
  }

  compare = (a, b) => {
    const dataA = a.external_id.toUpperCase();
    const dataB = b.external_id.toUpperCase();

    let comparison = 0;
    if (dataA > dataB) {
      comparison = 1;
    } else if (dataA < dataB) {
      comparison = -1;
    }
    return comparison;
  }

  render() {
    const { selectedDevice, options } = this.state;
    const { disabled, onBlur, customClassName } = this.props;

    return (
      <Select
        cacheOptions
        isDisabled={disabled}
        id={`device-picker-${get(selectedDevice, 'id') || 0}`}
        options={options.sort(this.compare)}
        getOptionLabel={(option) => option.external_id}
        getOptionValue={(option) => option.external_id}
        onBlur={onBlur}
        onChange={this.handleChange}
        placeholder="Select device..."
        value={(options === undefined ? [] : options).find((a) => a.id === selectedDevice)}
        className={`AscaliaSelect DevicePicker ${customClassName}`}
      />
    );
  }
}

DevicePicker.propTypes = {
  disabled: PropTypes.bool,
  device: PropTypes.object,
  changeDevice: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  onBlur: PropTypes.func.isRequired,
  customClassName: PropTypes.func,
};

DevicePicker.defaultProps = {
  disabled: false,
  customClassName: '',
};

const mapStateToProps = (state) => ({
  locationId: get(state, 'app.location.id'),
  companyId: get(state, 'app.company.id'),
});

export default connect(mapStateToProps)(DevicePicker);
