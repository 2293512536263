import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import { selectModalStyles } from 'styles/modules/reactSelect';
import api from 'helpers/api';
import { Notification, Modal } from 'shared';
import copyImage from 'shared/Icons/FilePlus.png';
import { fetchDashboards } from 'industry/IndustryLocationContainer/actions';
import './styles.scss';

const getColorForIndex = (index) => {
  const colors = ['red', 'orange', 'yellow', 'blue', 'green'];
  const colorIndex = index % colors.length;

  return colors[colorIndex];
};

class DashboardList extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isUpdating: false,
      permissionList: [],
      dashboardModalStatus: false,
      selectedDashboard: null,
      selectedLocationForDuplicate: (props.companyLocations && props.companyLocations.length > 0 && props.locationId)
        ? (props.companyLocations.find((cL) => cL.id === props.locationId)
          && props.companyLocations.find((cL) => cL.id === props.locationId).id)
        : ((props.companyLocations && props.companyLocations.length > 0)
          ? (props.companyLocations[0] && props.companyLocations[0].id)
          : null)
        || null,
    };
  }

  componentDidMount() {
    this.getPermissionList();
  }

  getPermissionList = () => {
    const { companyId } = this.props;

    api.get(`/api/v1/permission_list/?company=${companyId}`)
      .then((res) => {
        this.setState({
          permissionList: res.data || {},
        });
      })
      .catch((error) => Notification('error', 'An error occurred while fetching permission list', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.'));
  }

  getPermissionForModule = (module) => {
    const { locationId, companyId } = this.props;
    const { permissionList } = this.state;

    let path = `/${companyId}/industry/location/${locationId}`;

    if (module) {
      path += `/${module}`;
    }

    return permissionList[path] ? permissionList[path] : 0;
  }

  copyDashboard = (dashboard_id, location) => {
    const { t } = this.props;
    if (!location) {
      const confirm = window.confirm(t('page_content.dashboards.copy_message_dashboard'));
      if (!confirm) {
        return;
      }
    }

    this.setState({
      isUpdating: true,
    });

    const { locationId, refetchDashboards } = this.props;

    const data = {
      dashboard_id,
    };

    if (location) {
      data.location_id = location;
    }

    api.post('/api/v1/dashboards/duplicate-dashboard/', data)
      .then(() => {
        refetchDashboards({ locationId });
        this.setState({
          isUpdating: false,
        }, () => this.handleDashboardModalClose());
      })
      .catch((err) => console.log(err));
  }

  editDashboardFavorite = (dashboard) => {
    this.setState({
      isUpdating: true,
    });
    const { locationId, refetchDashboards } = this.props;

    dashboard.is_favorite = !dashboard.is_favorite;
    api.patch(`/api/v1/dashboards/dashboard/${dashboard.id}/`, dashboard)
      .then(() => {
        refetchDashboards({ locationId });
        this.setState({
          isUpdating: false,
        });
      })
      .catch((error) => {
        this.setState({
          isUpdating: false,
        });
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  handleDashboardModalOpen = (dashboardObject) => {
    this.setState({
      dashboardModalStatus: true,
      selectedDashboard: dashboardObject,
    });
  }

  handleDashboardModalClose = () => {
    const { companyLocations } = this.props;
    this.setState({
      dashboardModalStatus: false,
      selectedDashboard: null,
      selectedLocationForDuplicate: companyLocations && companyLocations.length > 0 ? companyLocations[0].id : null,
    });
  }

  handleDashboardModalLocationChange = (value) => {
    this.setState({
      selectedLocationForDuplicate: value,
    });
  }

  render() {
    const { isUpdating, permissionList, dashboardModalStatus, selectedDashboard, selectedLocationForDuplicate } = this.state;
    const {
      dashboards: input,
      companyId,
      locationId,
      isFavorite,
      totalDashboardsCount,
      userIsCompanyAdmin,
      currentUser,
      location,
      companyLocations,
      t,
    } = this.props;
    let data;

    if (input) {
      const dashboardData = input.map((dashboard, index) => {
        dashboard.label = getColorForIndex(index);
        return dashboard;
      });
      data = dashboardData;
    }

    const hasAccessToDashboards = this.getPermissionForModule('dashboards');

    return (
      <ul className="dashboard-list">
        {
          data && data.length === 0 && !isFavorite && !totalDashboardsCount ?
            <li className="no-content">
              There are no dashboards created yet
            </li> : ''
        }
        {
          data && currentUser && currentUser.is_admin ?
            data.map((dashboard, index) => {
              let isHidden = true;

              if (currentUser && currentUser.is_admin) {
                isHidden = false;
              } else if (!userIsCompanyAdmin) {
                if (permissionList && permissionList[`/${companyId}/industry/location/${locationId}/dashboards/${dashboard.id}`]
                  && permissionList[`/${companyId}/industry/location/${locationId}/dashboards/${dashboard.id}`] !== 0) {
                  isHidden = false;
                } else {
                  isHidden = true;
                }
              } else {
                isHidden = false;
              }
              return (
                !isHidden ?
                  <li key={`dashboard-list-item-${index}`}>
                    <NavLink
                      to={`/${companyId}/industry/location/${locationId}/dashboards/${dashboard.id}`}
                    >
                      <div className="meta">
                        <div className="label" style={{ backgroundColor: dashboard.label }} />
                        <div className="name">
                          {dashboard.title}
                        </div>
                      </div>
                      <div className="controls" style={{ display: 'flex' }}>
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (!isUpdating) {
                              if (userIsCompanyAdmin || (currentUser && currentUser.is_admin)) {
                                this.handleDashboardModalOpen(dashboard);
                              } else {
                                this.copyDashboard(dashboard.id);
                              }
                            }
                          }}
                          style={{ marginRight: '8px' }}
                          className="controls__copy"
                        >
                          <img src={copyImage} alt="+" style={{ height: '17px', width: '16px', marginBottom: '-4px' }} />
                        </div>
                        <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); if (!isUpdating) { this.editDashboardFavorite(dashboard); } }} className={`favorite ${dashboard.is_favorite ? 'active' : ''}`}>
                          &#x2605;
                        </div>
                      </div>
                    </NavLink>
                  </li>
                  : ''
              );
            }) : hasAccessToDashboards ?
              data && data.map((dashboard, index) => {
                let isHidden = true;

                if (currentUser && currentUser.is_admin) {
                  isHidden = false;
                } else if (!userIsCompanyAdmin) {
                  if (permissionList && permissionList[`/${companyId}/industry/location/${locationId}/dashboards/${dashboard.id}`]
                    && permissionList[`/${companyId}/industry/location/${locationId}/dashboards/${dashboard.id}`] !== 0) {
                    isHidden = false;
                  } else {
                    isHidden = true;
                  }
                } else {
                  isHidden = false;
                }
                return (
                  !isHidden ?
                    <li key={`dashboard-list-item-${index}`}>
                      <NavLink
                        to={`/${companyId}/industry/location/${locationId}/dashboards/${dashboard.id}`}
                      >
                        <div className="meta">
                          <div className="label" style={{ backgroundColor: dashboard.label }} />
                          <div className="name">
                            {dashboard.title}
                          </div>
                        </div>
                        <div className="controls" style={{ display: 'flex' }}>
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (!isUpdating) {
                                if (userIsCompanyAdmin || (currentUser && currentUser.is_admin)) {
                                  this.handleDashboardModalOpen(dashboard);
                                } else {
                                  this.copyDashboard(dashboard.id);
                                }
                              }
                            }}
                            style={{ marginRight: '8px' }}
                            className="controls__copy"
                          >
                            <img src={copyImage} alt="+" style={{ height: '17px', width: '16px', marginBottom: '-4px' }} />
                          </div>
                          <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); if (!isUpdating) { this.editDashboardFavorite(dashboard); } }} className={`favorite ${dashboard.is_favorite ? 'active' : ''}`}>
                            &#x2605;
                          </div>
                        </div>
                      </NavLink>
                    </li> : ''
                );
              }) : ''
        }
        {
          location && location.homepage_links.length > 0 ? location.homepage_links.map((item, index) => {
            return (
              <li key={`dashboard-list-item-${index}`}>
                <a
                  href={item.url}
                >
                  <div className="meta">
                    <div className="label" style={{ backgroundColor: getColorForIndex(index) }} />
                    <div className="name">
                      {item.title}
                    </div>
                  </div>
                </a>
              </li>
            );
          }) : ''
        }
        {dashboardModalStatus && <Modal isOpen={dashboardModalStatus} handleClose={() => this.handleDashboardModalClose()} title={t('page_content.dashboards.dashboard_duplicate_modal.title')} handleSave={() => this.copyDashboard(selectedDashboard.id, selectedLocationForDuplicate)}>
          <div className="dashboard_modal_container">
            <div className="modal_row">
              <div className="left_text">{`${t('page_content.dashboards.dashboard_duplicate_modal.selected_dashboard')}`}</div>
              <div className="right_input">
                <input value={selectedDashboard.title} readOnly disabled />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{`${t('page_content.dashboards.dashboard_duplicate_modal.location_selector')}`}</div>
              <div className="right_select">
                <Select
                  options={companyLocations}
                  getOptionLabel={(companyLocation) => companyLocation.name}
                  getOptionValue={(companyLocation) => companyLocation.id}
                  isSearchable
                  placeholder=""
                  menuPosition="fixed"
                  onChange={(value) => this.handleDashboardModalLocationChange(value.id)}
                  value={(companyLocations.find((cL) => cL.id === selectedLocationForDuplicate)) || null}
                  styles={selectModalStyles}
                />
              </div>
            </div>
          </div>
        </Modal>}
      </ul>
    );
  }
}

DashboardList.defaultProps = {
  dashboards: [],
};

DashboardList.propTypes = {
  dashboards: PropTypes.array,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refetchDashboards: PropTypes.func.isRequired,
  isFavorite: PropTypes.bool,
  totalDashboardsCount: PropTypes.number,
  t: PropTypes.func.isRequired,
  userIsCompanyAdmin: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  location: PropTypes.object,
  companyLocations: PropTypes.array,
};

DashboardList.defaultProps = {
  totalDashboardsCount: 0,
};

const mapStateToProps = (state) => {
  return {
    userIsCompanyAdmin: state.app.userIsCompanyAdmin,
    currentUser: state.currentUser,
    companyLocations: state.app && state.app.companyConfig && state.app.companyConfig.locations,
  };
};

const mapDispatchToProps = {
  refetchDashboards: fetchDashboards,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardList));
