import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { checkModules, checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import './style.scss';

import OrderList from './components/OrderList';
import OrderItemSearch from './components/OrderItemSearch';
import Bookings from './components/Bookings';
import LineMaterials from './components/LineMaterials';
// import Viewer3D from './components/Viewer3D';

class IndustryLocationOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderBookings: false,
    };
  }

  componentDidMount() {
    const { locationId, companyId } = this.props;
    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Orders');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }

        const moduleOrderBookings = modules.find((m) => m.name === 'Order bookings');
        this.setState({ orderBookings: moduleOrderBookings?.is_active ?? false });
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            isReadOnly: true,
          });
        }
      });
  }

  render() {
    const { t, locationId, companyId, isProjectBased, orderLineMaterials } = this.props;
    const { orderBookings } = this.state;

    return (
      <div className="industry-tab orders_container fullscreen">
        {(isProjectBased || orderBookings) ? <Tabs>
          <TabList>
            <Tab>{t('page_content.orders.order_list_tab')}</Tab>
            {isProjectBased && <Tab>{t('page_content.orders.order_item_search_tab')}</Tab>}
            {orderBookings && <Tab>{t('page_content.orders.bookings_tab')}</Tab>}
            {orderLineMaterials && <Tab>{t('page_content.orders.line_materials')}</Tab>}
          </TabList>

          <TabPanel>
            <OrderList locationId={locationId} companyId={companyId} />
          </TabPanel>

          {isProjectBased && <TabPanel>
            <OrderItemSearch locationId={locationId} companyId={companyId} />
          </TabPanel>}

          {orderBookings && <TabPanel>
            <Bookings locationId={locationId} companyId={companyId} />
          </TabPanel>}

          {orderBookings && <TabPanel>
            <LineMaterials t={t} locationId={locationId} companyId={companyId} />
          </TabPanel>}

        </Tabs> : <OrderList locationId={locationId} companyId={companyId} />}
      </div>
    );
  }
}

IndustryLocationOrderList.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  isProjectBased: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  orderLineMaterials: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isProjectBased: get(state, 'app.companyConfig.config.project_based', false),
    orderLineMaterials: get(state, 'app.locationConfig.config.order_line_materials', false),
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(IndustryLocationOrderList)));
