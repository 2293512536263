import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, TableButtons, ConfirmationModal } from 'shared';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';
import { get } from 'lodash';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import NotificationsForm from './components/NotificationsForm/index';
import { getNotifications, createNewNotification, editNotification, deleteNotification } from '../../actions';
import './style.scss';

class NotificationsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      next: null,
      previous: null,
      isReadOnly: false,
      isLoading: true,
      isNotificationsFormOpen: false,
      selectedNotification: null,
      notifications: [],
      count: 0,
      selectedAscDesc: 'asc',
      selectedSort: 'api_key',
      deleteData: null,
      showConfirmationDialog: false,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;
    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToSettingsPage(companyId, locationId);
        } else if (access === 1) {
          this.setState({
            isReadOnly: true,
          });
        }
        this.fetchNotifications();
      });
  }

  handleSorting = (sortData) => {
    this.setState({
      selectedSort: sortData.id,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchNotifications();
    });
  };

  fetchNotifications = () => {
    const { companyId } = this.props;
    const { selectedSort, selectedAscDesc } = this.state;

    let url = `/api/v1/notifications/setups/?company=${companyId}`;

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const tableFilter = `&order_by=${asc}${selectedSort}`;
    url += tableFilter;

    getNotifications(url)
      .then((resp) => {
        const notifications = get(resp, 'data.results');
        const count = get(resp, 'data.count');
        const next = get(resp, 'data.next');
        const previous = get(resp, 'data.previous');
        this.setState({
          notifications,
          count,
          next,
          previous,
          isLoading: false,
        });
      }).catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  openNotificationsForm = () => {
    this.setState({
      isNotificationsFormOpen: true,
    });
  }

  closeNotificationsForm = () => {
    this.setState({
      isNotificationsFormOpen: false,
      selectedNotification: null,
    });
  }

  handleNotificationsTableRowClick = (notifObject) => {
    const { isReadOnly } = this.state;
    if (!isReadOnly) {
      this.setState({
        selectedNotification: notifObject,
      }, () => {
        this.openNotificationsForm();
      });
    }
  }

  handleCreateNotification = (data) => {
    this.setState({
      isLoading: true,
    });
    createNewNotification(data)
      .then(() => {
        this.setState({
          isNotificationsFormOpen: false,
          isLoading: false,
          selectedNotification: null,
        }, () => {
          this.fetchNotifications();
        });
      });
  }

  handleEditNotification = (data) => {
    this.setState({
      isLoading: true,
    });
    editNotification(data)
      .then(() => {
        this.setState({
          isNotificationsFormOpen: false,
          isLoading: false,
          selectedNotification: null,
        }, () => {
          this.fetchNotifications();
        });
      });
  }

  handleShowConfirmationModal = (e, rowObject) => {
    e.stopPropagation();
    this.setState({ showConfirmationDialog: true, deleteData: rowObject });
  }

  handleDeleteNotification = () => {
    this.setState({
      isLoading: true,
    });
    const rowObject = this.state.deleteData;
    deleteNotification(rowObject.id, rowObject.company)
      .then(() => {
        this.setState({
          isLoading: false,
        }, () => {
          this.fetchNotifications();
        });
      }).finally(() => this.setState({ showConfirmationDialog: false }));
  }

  fetchData = (url) => {
    this.setState({ isLoading: true });
    getNotifications(url)
      .then((resp) => {
        const notifications = get(resp, 'data.results');
        const count = get(resp, 'data.count');
        const next = get(resp, 'data.next');
        const previous = get(resp, 'data.previous');
        this.setState({
          notifications,
          count,
          next,
          previous,
          isLoading: false,
        });
      }).catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  };

  render() {
    const { t, companyId } = this.props;
    const { notifications, selectedNotification, isNotificationsFormOpen, isLoading, next, previous, count, isReadOnly } = this.state;

    const notificationTypeOptions = [
      { id: 'sms_infobip', name: 'Infobip SMS' },
    ];

    const triggerSourceOptions = [
      { id: 'order_finished', name: t('settings.notifications.triggOption_order_finished') },
    ];

    const tableColumnConfiguration = [
      {
        Header: () => <span>{t('settings.notifications.table_column_apikey')}</span>,
        accessor: 'api_key',
        Cell: (row) => <div>{row?.value ? row.value : '-'}</div>,
      },
      {
        Header: () => <span>{t('settings.notifications.table_column_notificationType')}</span>,
        accessor: 'notification_type',
        Cell: (row) => <div>{row?.value ? notificationTypeOptions?.find((nOption) => nOption.id === row.value)?.name : '-'}</div>,
      },
      {
        Header: () => <span>{t('settings.notifications.table_column_enabled')}</span>,
        accessor: 'is_enabled',
        Cell: (row) => <div>{row?.value ? <img src={checkMarkTrue} width="25px" height="25px" alt="" /> : <img src={checkMarkFalse} width="25px" height="25px" alt="" />}</div>,
      },
      {
        Header: () => <span>{t('settings.notifications.table_column_triggerSource')}</span>,
        accessor: 'trigger_source',
        Cell: (row) => <div>{row?.value ? triggerSourceOptions.find((sOption) => sOption.id === row.value).name : '-'}</div>,
      },
      {
        Header: () => <span>{t('settings.notifications.table_column_template')}</span>,
        accessor: 'template',
        Cell: (row) => <div>{row?.value ? row.value : '-'}</div>,
      },
      {
        Header: () => <span>{t('settings.notifications.table_column_delete')}</span>,
        Cell: (row) => <Button type="delete" disabled={isReadOnly} onClick={(e) => this.handleShowConfirmationModal(e, row.original)}>{t('settings.notifications.delete_button')}</Button>,
        sortable: false,
      },
    ];

    return (
      <div className="technology_tab_container">
        <div className="button_row">
          <Button type="add" disabled={isReadOnly} onClick={() => this.openNotificationsForm()}>{t('settings.notifications.add_notification_button')}</Button>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={tableColumnConfiguration}
          data={notifications}
          defaultPageSize={30}
          minRows={0}
          noDataText=""
          showPagination={false}
          handleClick={(rowInfo) => !isReadOnly && this.handleNotificationsTableRowClick(rowInfo.original)}
          selectedRow={null}
          loading={isLoading}
          defaultSorted={[{ id: 'api_key', desc: false }]}
          onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
        />
        <div style={{ float: 'right' }}>
        <TableButtons next={next} previous={previous} fetchFunction={this.fetchData} count={count} />
        </div>
        {isNotificationsFormOpen && (<NotificationsForm notification={selectedNotification} isOpen={isNotificationsFormOpen} onClose={this.closeNotificationsForm} notificationTypeOptions={notificationTypeOptions} triggerSourceOptions={triggerSourceOptions} saveNotification={selectedNotification ? this.handleEditNotification : this.handleCreateNotification} companyId={companyId} />)}
        <ConfirmationModal
          itemName={this.state.deleteData?.api_key || ''}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDeleteNotification}
          type="warning"
        />
      </div>
    );
  }
}

NotificationsTab.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  t: PropTypes.func.isRequired,
};

export default (withRouter(withTranslation()(NotificationsTab)));
