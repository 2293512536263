import api from 'helpers/api';
import { Notification } from 'shared';

export const getCompanyData = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/companies/${companyId}/`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching companies', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getCompanyTables = async (company_short_code) => {
  const url = `/api/v1/company-tables/?limit=100&company_code=${company_short_code}`;
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching company tables', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedCompanyTables = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching company tables', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const importFile = async (data) => {
  try {
    const res = await api
      .post('/api/v1/upload_kfk_profile_definitions/', data);
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred', error.response.data.message || 'We could not perform your request, please try again.');
  }
};

export const getProductTypes = async (companyId, nameContains) => {
  try {
    const res = await api
      .get(`/api/v1/product_types/?company=${companyId}&limit=100&order_by=name${nameContains ? `&name_contains=${nameContains}` : ''}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching product types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addNewRastep = async (endpoint, data) => {
  return api.post(endpoint, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Save successful', 'New Rastep was successfully created.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};
