import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { IconRefresh } from 'shared/Icons';
import { buttonDisabledDuration } from './constants';
import './styles.scss';

class RefreshButton extends Component {
  constructor() {
    super();
    this.state = {
      animating: false,
      disabled: false,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.t);
  }

  enableButton = () => {
    this.t = setTimeout(() => {
      this.setState(() => ({ disabled: false }));
    }, buttonDisabledDuration);
  }

  handleAnimationEnd = () => {
    this.setState({ animating: false });
  }

  handleClick = () => {
    const { handleRefresh } = this.props;
    this.setState(() => ({
      animating: true,
      disabled: true,
    }), () => {
      this.enableButton();
      setTimeout(() => {
        handleRefresh();
      }, 0);
    });
  }

  render() {
    const { animating, disabled } = this.state;
    const { isDisabled, t } = this.props;

    return (
      <button
        className={`RefreshButton ${animating ? 'loading' : ''}`}
        disabled={isDisabled || disabled}
        onClick={this.handleClick}
        onAnimationEnd={this.handleAnimationEnd}
      >
        <IconRefresh height="16px" width="16px" />
        <span>
          {t('sub_header.refresh_button')}
        </span>
      </button>
    );
  }
}

RefreshButton.propTypes = {
  handleRefresh: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

RefreshButton.defaultProps = {
  isDisabled: false,
};

export default withTranslation()(RefreshButton);
