import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import WidgetPlaceholder from '../WidgetPlaceholder';
import './styles.scss';

const columnSorter = (a, b) => {
  if (a === 'time') {
    return -1;
  }
  if (b === 'time') {
    return 1;
  }

  return 0;
};

class Table extends Component {
  constructor() {
    super();
    this.state = {
      sort: -1,
    };

    this.handleSortChange = this.handleSortChange.bind(this);
  }

  getCellBackgroundColor = (value) => {
    const { settings } = this.props;

    let backgroundColor = '#ffff';

    if (settings && settings.threshold) {
      const threshold = settings.threshold.split(',');
      if (threshold && threshold.length) {
        if (threshold[0]) {
          const t1 = Number(threshold[0]);
          if (t1 && typeof t1 === 'number' && Number(value) <= t1) {
            backgroundColor = settings.colors[0];
          }
        }
        if (threshold[0] && threshold[1]) {
          const t1 = Number(threshold[0]);
          const t2 = Number(threshold[1]);
          if (t2 && typeof t2 === 'number' && Number(value) <= t2 && Number(value) > t1) {
            backgroundColor = settings.colors[1];
          }
        }
        if (threshold[1] && threshold[2]) {
          const t2 = Number(threshold[1]);
          const t3 = Number(threshold[2]);
          if (t3 && typeof t3 === 'number' && Number(value) <= t3 && Number(value) > t2) {
            backgroundColor = settings.colors[2];
          }
        }
        if (threshold[2]) {
          const t3 = Number(threshold[2]);
          if (t3 && typeof t3 === 'number' && Number(value) > t3) {
            backgroundColor = settings.colors[3];
          }
        }
        return backgroundColor;
      }
    }
    return backgroundColor;
  }

  handleSortChange() {
    const { sort } = this.state;
    this.setState({ sort: sort === -1 ? -1 : 1 });
  }

  render() {
    const { data, height, widgetData } = this.props;
    const widgetConfig = widgetData[0];
    if (!widgetConfig) {
      return <WidgetPlaceholder height={height} type="table" />;
    }

    const dataToShow = data;

    return (
      <div className="table-widget" style={{ height: `${height}px` }}>
        <table>
          <thead>
            <tr>
              {
                dataToShow && dataToShow.length
                  ? Object
                    .keys(dataToShow[0])
                    .sort(columnSorter)
                    .map((x) => {
                      let columnName = x;
                      if (columnName === 'value') {
                        columnName = widgetConfig.label;
                      }

                      return (
                        <th key={`column-${columnName}`}>{columnName}</th>
                      );
                    })
                  : <th>time</th>
              }
            </tr>
          </thead>
          <tbody>
            {
              !get(dataToShow, 'length') &&
              <tr>
                <td>
                  No data
                </td>
              </tr>
            }
            {
              dataToShow && dataToShow.length > 0 && dataToShow.map((x, i) => {
                return (
                  <tr key={`row-${i}`}>
                    {
                      Object
                        .keys(x)
                        .sort(columnSorter)
                        .map((y, j) => {
                          if (y === 'time') {
                            return <td key={`row-${moment(x[y])}`}>{moment(x[y]).format('DD.MM. HH:mm:ss.SSSS')}</td>;
                          }
                          return <td style={{ background: this.getCellBackgroundColor(x[y]) }} key={`cell-${i}-${j}`}>{x[y]}</td>;
                        })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

Table.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
  widgetData: PropTypes.array.isRequired,
  settings: PropTypes.any,
};

Table.defaultProps = {
  data: [],
  widgetData: [],
};

export default Table;
