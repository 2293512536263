import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';

import { selectStyles } from 'styles/modules/reactSelect';
import { Heatmap, ContentLoader, Button } from 'shared';
import { getProductionRecords, getStatusOverview } from '../actions';

const ProductionRecordsHeatMap = ({ t, locationId, recordTypeOptions }) => {
  const [listOfRecords, setListOfRecords] = useState({
    data: [],
    isLoading: false,
  });
  const [statusOverview, setStatusOverview] = useState({
    data: [],
    isLoading: false,
  });
  const [filters, setFilters] = useState({
    recordType: 'maintenance',
    record: '',
  });
  const [currentYear, setCurrentYear] = useState(new Date());

  const getListOfRecords = () => {
    setListOfRecords((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getProductionRecords(locationId, `&record_type=${filters.recordType}`)
      .then((res) => {
        setListOfRecords({
          isLoading: false,
          data: get(res, 'data.results', []),
        });
      })
      .catch(() => {
        setListOfRecords({
          isLoading: false,
          data: [],
        });
      });
  };

  const getHeatmapData = () => {
    setStatusOverview((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    getStatusOverview(filters.record, moment(currentYear).startOf('year').format('YYYY-MM-DD'), moment(currentYear).endOf('year').format('YYYY-MM-DD'))
      .then((res) => {
        setStatusOverview({
          isLoading: false,
          data: get(res, 'data.data', []),
        });
      })
      .catch(() => {
        setStatusOverview({
          isLoading: false,
          data: [],
        });
      });
  };

  useEffect(() => {
    getListOfRecords();
    setStatusOverview((prevState) => ({
      ...prevState,
      data: [],
    }));
  }, [locationId, filters.recordType, filters.record]);

  useEffect(() => {
    if (filters?.record) {
      getHeatmapData();
    }
  }, [locationId, filters.record, currentYear]);

  const onFilterChange = async (key, value) => {
    if (key === 'recordType') {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [key]: value,
        record: '',
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [key]: value,
      }));
    }
  };

  const handleChangeNextYear = () => {
    const currentDate = new Date(currentYear);
    setCurrentYear(currentDate.setFullYear(currentDate.getFullYear() + 1));
  };

  const handleChangePreviousYear = () => {
    const currentDate = new Date(currentYear);
    setCurrentYear(currentDate.setFullYear(currentDate.getFullYear() - 1));
  };

  return (
    <div className="heatmap_tab">
      <div className="heatmap_filters">
        <div className="filters">
          <Select
            options={recordTypeOptions}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            placeholder=""
            onChange={(e) => { onFilterChange('recordType', e.value); }}
            value={recordTypeOptions.find((o) => o.value === filters.recordType)}
            styles={selectStyles}
          />

          <Select
            options={listOfRecords.data}
            getOptionLabel={(record) => record.title}
            getOptionValue={(record) => record.id}
            isSearchable
            placeholder={t('page_content.production_records.record_label')}
            onChange={(value) => onFilterChange('record', value.id)}
            value={(listOfRecords.data.find((rec) => rec.id === filters.record)) || ''}
            styles={selectStyles}
            isLoading={listOfRecords.isLoading}
            loadingMessage={() => <ContentLoader />}
          />
        </div>

        <div className="heatmap_buttons">
          <div>
            {filters?.record &&
              <Button onClick={handleChangePreviousYear}>{t('page_content.production_records.status_overview.previous_year')}</Button>}
          </div>
          <div>
            {filters?.record &&
              <Button disabled={new Date(currentYear).getFullYear() >= new Date().getFullYear()} onClick={handleChangeNextYear}>{t('page_content.production_records.status_overview.next_year')}</Button>}
          </div>
        </div>
      </div>
      {
        statusOverview?.isLoading ?
        <ContentLoader /> :
          filters?.record && statusOverview?.data?.length ?
          <Heatmap
            data={statusOverview.data}
            startDate={moment(currentYear).startOf('year')}
            endDate={moment(currentYear).endOf('year')}
          /> : null
      }

    </div>
  );
};

ProductionRecordsHeatMap.propTypes = {
  locationId: PropTypes.number,
  recordTypeOptions: PropTypes.array,
  t: PropTypes.func,
};

export default ProductionRecordsHeatMap;
