import api from 'helpers/api';
import * as actionTypes from './actionTypes';
import { Notification } from '../shared/index';

const fetchPageMetaError = () => ({
  type: actionTypes.fetchPageMetaError,
});

const fetchPageMetaSuccess = (data) => ({
  type: actionTypes.fetchPageMetaSuccess,
  payload: data,
});

export const setUserIsCompanyAdmin = ({ isCompanyAdmin }) => {
  return {
    type: actionTypes.setUserIsCompanyAdmin,
    payload: isCompanyAdmin,
  };
};

export const setInterval = ({ interval }) => {
  return {
    type: actionTypes.setInterval,
    payload: {
      interval,
    },
  };
};

export const setDateRange = ({ start, end, label, selectedShiftDays }) => {
  return {
    type: actionTypes.setDateRange,
    payload: {
      start,
      end,
      label,
      selectedShiftDays,
    },
  };
};

export const setSmartCityDateRange = ({ start, end, label }) => {
  return {
    type: actionTypes.setSmartCityDateRange,
    payload: {
      start,
      end,
      label,
    },
  };
};

export const toggleSidebar = () => {
  return {
    type: actionTypes.toggleSidebar,
  };
};

export const zoomOut = () => {
  return {
    type: actionTypes.zoomOut,
  };
};

export const postCompany = (company) => {
  return {
    type: actionTypes.postCompany,
    payload: company,
  };
};

export const patchCompany = (company) => {
  return {
    type: actionTypes.patchCompany,
    payload: company,
  };
};

export const patchLocation = (location) => {
  return {
    type: actionTypes.patchLocation,
    payload: location,
  };
};

export const postLocation = (location) => {
  return {
    type: actionTypes.postLocation,
    payload: location,
  };
};

export const fetchPageMeta = ({ droneId, locationId, companyId } = {}) => (dispatch) => {
  dispatch({ type: actionTypes.fetchPageMetaInit });
  let path = `/api/v1/sidebar/?_=${Date.now()}`;
  if (droneId) {
    path += `&drone=${droneId}`;
  }
  if (locationId) {
    path += `&location=${locationId}`;
  }

  if (companyId) {
    path += `&company=${companyId}`;
  }

  return api.get(path)
    .then(
      (res) => dispatch(fetchPageMetaSuccess(res.data)),
      fetchPageMetaError,
    );
};

export const getCompanyData = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/companies/${companyId}/`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching companies', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const fetchCompanyConfigRequest = () => {
  return {
    type: actionTypes.fetchCompanyConfigRequest,
  };
};

export const fetchCompanyConfigSuccess = (companyConfig) => {
  return {
    type: actionTypes.fetchCompanyConfigSuccess,
    payload: companyConfig,
  };
};

export const fetchCompanyConfigFailure = (error) => {
  return {
    type: actionTypes.fetchCompanyConfigFailure,
    payload: error,
  };
};

export const fetchCompanyConfig = (companyId) => {
  return (dispatch) => {
    dispatch(fetchCompanyConfigRequest());
    api
      .get(`/api/v1/companies/${companyId}/`)
      .then((response) => {
        const companyConfig = response.data;
        dispatch(fetchCompanyConfigSuccess(companyConfig));
      })
      .catch((error) => {
        dispatch(fetchCompanyConfigFailure(error.message));
      });
  };
};

export const fetchLocationConfigRequest = () => {
  return {
    type: actionTypes.fetchLocationConfigRequest,
  };
};

export const fetchLocationConfigSuccess = (locationConfig) => {
  return {
    type: actionTypes.fetchLocationConfigSuccess,
    payload: locationConfig,
  };
};

export const fetchLocationConfigFailure = (error) => {
  return {
    type: actionTypes.fetchLocationConfigFailure,
    payload: error,
  };
};

export const fetchLocationConfig = (locationId) => {
  return (dispatch) => {
    dispatch(fetchLocationConfigRequest());
    api
      .get(`/api/v1/locations/${locationId}/`)
      .then((response) => {
        const locationConfig = response.data;
        dispatch(fetchLocationConfigSuccess(locationConfig));
      })
      .catch((error) => {
        dispatch(fetchLocationConfigFailure(error.message));
      });
  };
};
