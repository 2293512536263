import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import { disable2FA } from '../../actions';

class Disable2FA extends Component {
  render() {
    const { resetPage, t } = this.props;

    return (
      <div className="form2FA">
        <p>{t('user_profile_page.enter_token_2FA_disable')}</p>
        <Formik
          initialValues={{ token: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.token.length) {
              errors.token = 'Required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            disable2FA({ ...values })
              .then(() => {
                setSubmitting(false);
                resetPage();
              })
              .catch(() => {
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            isSubmitting,
          }) => (
            <Form ref={this.formRef} className="default-form">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <Field
                        type="text"
                        name="token"
                        placeholder="Token"
                        value={values.token}
                      />
                      <ErrorMessage className="error-message" name="token" component="div" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        type="submit"
                        className="submit-button"
                        disabled={isSubmitting}
                        style={{ backgroundColor: 'rgba(255, 0, 0, 0.7)' }}
                      >
                        {t('user_profile_page.disable_2FA_button')}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

Disable2FA.propTypes = {
  resetPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default Disable2FA;
