import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props} viewBox="0 0 50 50">
  <g>
    <path
      d="M47.4,26.3c0.1,0.5-0.3,1.1-0.9,1.1c-0.1,0-0.2,0-0.3,0c-1.3,0-2.7,0-4.1,0c-0.6,0-0.8,0.1-0.8,0.8
    c0,4.6,0,9.2,0,13.7c0.1,1.6-1.1,2.9-2.6,3c-0.1,0-0.3,0-0.4,0h-7.6c-1,0-1.3-0.3-1.3-1.3c0-2.3,0-4.7,0-7c0-2.5-2-4.5-4.5-4.5
    c-2,0-3.8,1.3-4.3,3.3c-0.1,0.5-0.2,1-0.2,1.5c0,2.2,0,4.5,0,6.8c0,1-0.3,1.3-1.3,1.3h-7.5c-1.9,0-3.1-1.2-3.1-3.1
    c0-2.2,0-4.4,0-6.7c0-2.3,0-4.7,0-7c0-0.6-0.1-0.7-0.7-0.7c-1.3,0-2.6,0-3.8,0c-1.1,0-1.4-0.3-1.4-1.3c0-0.9,0-1.8,0-2.7
    c0-0.7,0.3-1.5,0.9-1.9c5.7-4.7,11.4-9.5,17.1-14.2L22.1,6c1.7-1.4,4.1-1.4,5.8,0l18.6,15.5c0.6,0.4,0.9,1.1,0.9,1.8
    C47.4,24.3,47.4,25.3,47.4,26.3z"
    />
    <path
      d="M40.6,15.7l-3.5-2.9c-1.3-1.1-2.7-2.2-4-3.3c-0.2-0.2-0.4-0.4-0.3-0.7c0-0.3,0-0.6,0-0.9
    c-0.1-0.8,0.5-1.5,1.3-1.5c0,0,0.1,0,0.1,0c1.7,0,3.3,0,5,0c0.8,0,1.4,0.6,1.4,1.3C40.6,10.4,40.6,13,40.6,15.7z"
    />
  </g>
</Base>);
