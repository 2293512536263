import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import Timeline from 'react-calendar-timeline';
import { IconWarning } from 'shared/Icons';
import { colors } from 'shared/colors';
import { formatLabel } from '../helpers';
import 'react-calendar-timeline/lib/Timeline.css';
import WidgetPlaceholder from '../../WidgetPlaceholder';
import { timeRangeEnum, timeRangeConfig } from '../../../DatePicker/constants';
import { defaultDateTimeFormat, timeFormatWithUnits } from './../../../constants';
import './styles.scss';

const colorIndex = [
  '#1abc9c',
  '#2980b9',
  '#e67e22',
  '#e74c3c',
  '#9b59b6',
  '#2ecc71',
  '#34495e',
];

class GanttChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: null,
    };

    this.prepareData = this.prepareData.bind(this);
  }

  prepareData(data = {}, widgetData = []) {
    const {
      t,
      settings,
      globalFilters,
    } = this.props;

    let events = [];
    let groups = [];
    let legend = [];

    if (data === null || widgetData === null) {
      return { events, groups };
    }

    let assetIndex = 0;
    let entryIdx = 0;
    let groupColor;

    // normal endpoints
    Object.entries(data)
      .filter((key) => { return key[0] !== 'events'; })
      .forEach(([key, entries]) => {
        assetIndex++; // incrementing assetIndex here to start from 1, not 0

        const endpointItem = widgetData[key];

        const widgetTextMetric = endpointItem?.style?.textMetric || null;
        let globalAsset = null;
        let metricFromGlobalAsset = null;

        if (widgetTextMetric) {
          globalAsset = globalFilters?.asset || null;
          metricFromGlobalAsset = globalAsset?.metrics?.find((m) => m.code === widgetTextMetric);
        }

        const currentMetric = metricFromGlobalAsset || endpointItem?.metric;

        if (!currentMetric) return '';

        if (settings && settings.appearance && settings.appearance.oneline_events && endpointItem && Object.prototype.hasOwnProperty.call(endpointItem, 'label')) {
          groupColor = (endpointItem.style && endpointItem.style.color && endpointItem.style.color.r && endpointItem.style.color.g && endpointItem.style.color.b && endpointItem.style.color.a) ? `rgba(${endpointItem.style.color.r}, ${endpointItem.style.color.g}, ${endpointItem.style.color.b}, ${endpointItem.style.color.a})` : colorIndex[assetIndex];
          legend[key] = {
            id: 'test',
            title: endpointItem?.label || formatLabel(currentMetric),
            bgColor: groupColor,
            height: 30,
            sort_order: endpointItem.sort_order,
          };
        }

        if (settings && settings.appearance && !settings.appearance.oneline_events && endpointItem && Object.prototype.hasOwnProperty.call(endpointItem, 'label')) {
          groupColor = (endpointItem.style && endpointItem.style.color && endpointItem.style.color.r && endpointItem.style.color.g && endpointItem.style.color.b && endpointItem.style.color.a) ? `rgba(${endpointItem.style.color.r}, ${endpointItem.style.color.g}, ${endpointItem.style.color.b}, ${endpointItem.style.color.a})` : colorIndex[assetIndex];
          groups[key] = {
            id: assetIndex,
            title: endpointItem?.label || formatLabel(currentMetric),
            bgColor: groupColor,
            height: 30,
            sort_order: endpointItem.sort_order,
          };
        }

        // transformer data - Active product type
        if (currentMetric?.code === 'active_product') {
          entries.forEach((entry, idx) => {
            // const entryProductIndex = `item_${currentMetric?.code}_${assetIndex}_${idx}`;
            const entryProductIndex = `item_transformer_${Math.random()}_${idx}`;
            const newEntry = {
              id: entryProductIndex,
              group: assetIndex,
              title: '',
              start: moment(entry.start).valueOf(),
              end: moment(entry.annotation.active_to).valueOf(),
              itemProps: {
                className: 'gantt-item',
                hoverTitle: `<label>${t('page_content.dashboards.widget_tooltips.source_label')}:</label> 
                  <span>${endpointItem?.label || formatLabel(currentMetric)}</span><br />
                  <label>${t('page_content.dashboards.widget_tooltips.start_label')}:</label> 
                  <span>${moment(entry.start).format(defaultDateTimeFormat)}</span><br />
                  <label>${t('page_content.dashboards.widget_tooltips.end_label')}:</label> 
                  <span>${moment(entry.annotation.active_to).format(defaultDateTimeFormat)}</span><br />
                  <label>${t('page_content.dashboards.widget_tooltips.group_label')}:</label> 
                  <span>${entry.annotation.data.group}</span><br /><label>${t('page_content.dashboards.widget_tooltips.mass_label')}:</label> 
                  <span>${entry.annotation.data.data.mass}</span>`,
              },
            };
            events.push(newEntry);
          });
          // transformer data - Maintenance event
        } else if (currentMetric?.code === 'maintenance-duration') {
          entries.forEach((entry, idx) => {
            // const entryProductIndex = `item_${currentMetric?.code}_${assetIndex}_${idx}`;
            const entryProductIndex = `item_maintenance_${Math.random()}_${idx}`;
            const newEntry = {
              id: entryProductIndex,
              group: assetIndex,
              title: '',
              start: moment(entry.start).valueOf(),
              end: moment(entry.annotation.active_to).valueOf(),
              itemProps: {
                className: 'gantt-item',
                hoverTitle: `<label>${t('page_content.dashboards.widget_tooltips.source_label')}:</label> 
                  <span>${endpointItem?.label || formatLabel(currentMetric)}</span><br /><label>${t('page_content.dashboards.widget_tooltips.start_label')}:</label> 
                  <span>${moment(entry.start).format(defaultDateTimeFormat)}</span><br /><label>${t('page_content.dashboards.widget_tooltips.end_label')}:</label> 
                  <span>${moment(entry.annotation.active_to).format(defaultDateTimeFormat)}</span><br /><label>${t('page_content.dashboards.widget_tooltips.reason_label')}:</label> 
                  <span>${entry.annotation.reason}</span><br /><label>${t('page_content.dashboards.widget_tooltips.waiting_label')}:</label> <span>${entry.annotation.waiting_duration}</span>`,
              },
            };
            events.push(newEntry);
          });
          // transformer data - Other event
        } else {
          entries.forEach((entry) => {
            // new starting entry
            const newEntry = {
              id: `item_other_${Math.random()}_${entryIdx}`,
              group: settings && settings.appearance && !settings.appearance.oneline_events ? assetIndex : 'test',
              title: '',
              start: moment(entry.start).valueOf(),
              end: moment(entry.end).valueOf(),
              itemProps: {
                bgColor: groupColor,
                startMoment: moment(entry.start),
                className: 'gantt-item',
                hoverTitle: <div className="popup_tooltip">
                  <label>{t('page_content.dashboards.widget_tooltips.source_label')}:</label><span>{endpointItem?.label || formatLabel(currentMetric)}</span><br />
                  <label>{t('page_content.dashboards.widget_tooltips.start_label')}:</label>
                  <span>{moment(entry.start).format(defaultDateTimeFormat)}</span>,
                  <br /><label>{t('page_content.dashboards.widget_tooltips.end_label')}:</label>
                  <span>{moment(entry.end).format(defaultDateTimeFormat)}</span>,
                  <br /><label>{t('page_content.dashboards.widget_tooltips.duration_label')}:</label>
                  <span>{moment.duration(entry.duration, 'seconds').format(timeFormatWithUnits)}</span>
                  <br />
                  <span>{entry.finished === false ?
                    <div style={{ display: 'flex', textAlign: 'center' }}>
                      <IconWarning color={colors.red} height="16px" width="16px" />
                      {t('page_content.dashboards.widget_tooltips.running_event')}
                    </div>
                    : ''}</span>
                  <span>{entry && entry.annotation && entry.annotation.description ?
                    <span>{entry.annotation.description}</span>
                    : ''}</span>
                </div>,
              },
            };
            events[entryIdx] = newEntry;
            entryIdx++;
          });
        }
      });

    events = events.filter((itm) => itm);
    legend = legend.filter((itm) => itm);
    groups = settings && settings.appearance && !settings.appearance.oneline_events ?
      groups.filter((itm) => itm) : [{
        id: 'test',
        title: 'title',
        bgColor: groupColor,
        height: 30,
      }];

    return { events, groups, legend };
  }

  itemRenderer = ({ item, getItemProps }) => {
    return (
      <Tooltip
        id={item.id}
        trigger={['hover']}
        placement="top"
        overlay={item.itemProps.hoverTitle || ''}
      >
        <div
          {...getItemProps({
            style: {
              background: item.itemProps.bgColor,
              border: 'none',
            },
          })}
        />
      </Tooltip>);
  }

  filterGroups = (groups) => {
    return groups
      .filter((group) => { return (this.state.selectedId !== null) ? group.id === this.state.selectedId : true; })
      .map((group) => {
        return { ...group, title: (<div style={{ fontSize: '12px' }}>{group.title}</div>) };
      });
  }

  toggleGroup = (id) => {
    if (this.state.selectedId !== null && this.state.selectedId === id) {
      this.setState({
        selectedId: null,
      });
    } else {
      this.setState({
        selectedId: id,
      });
    }
  }

  legendRender = (groups) => {
    return (
      <ul className="recharts-default-legend" style={{ margin: '20px 0 0 0', textAlign: 'center' }}>
        {
          groups.sort((a, b) => (a.sort_order || 0) - (b.sort_order || 0)).map((group, index) => (
            <li key={`item-${index}`} className="recharts-legend-item legend-item-0" style={{ display: 'inline-block', marginRight: '10px', cursor: 'pointer' }} onClick={() => this.toggleGroup(group.id)}>
              <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}>
                <path stroke="none" fill={this.state.selectedId ? this.state.selectedId === group.id ? group.bgColor : 'gray' : group.bgColor} d="M0,4h32v24h-32z" className="recharts-legend-icon" />
              </svg>
              <span className="recharts-legend-item-text">{group.title}</span>
            </li>
          ))
        }
      </ul>
    );
  };

  timeChanged = (visibleStart, visibleEnd, updateScrollCanvas) => {
    updateScrollCanvas(
      moment(visibleStart).valueOf(),
      moment(visibleEnd).valueOf(),
    );
  }

  boundsChanged = (boundsStart, boundsEnd) => {
    this.props.setDateRange({
      label: 'custom',
      start: moment(boundsStart),
      end: moment(boundsEnd),
    });
  }

  render() {
    const {
      // height,
      // width,
      data,
      widgetData,
      settings,
      meta: {
        daterange: {
          start,
          end,
          label,
        },
        range,
      },
      // enableZoom,
    } = this.props;

    const sortedData = widgetData.sort((a, b) => a.sort_order - b.sort_order).map((w) => data[w.id] || []);
    const mappedWidgetData = widgetData.map((w) => w);

    if (!settings) {
      return <WidgetPlaceholder height={this.props.height} type="gantt" />;
    }

    const { events, groups, legend } = this.prepareData(sortedData, mappedWidgetData);
    const filteredGroups = this.filterGroups(groups);

    let from = start;
    let to = end;
    const rangeLabel = label;

    if (label !== timeRangeEnum.custom && label !== timeRangeEnum.nth_week && label !== timeRangeEnum.customYesterday && label !== timeRangeEnum.customToday) {
      from = timeRangeConfig()[rangeLabel].startDate;
      to = timeRangeConfig()[rangeLabel].endDate;
    }

    const timeRange = range
      ? [range[0], range[1]]
      : [from, to];

    const keys = {
      groupIdKey: 'id',
      groupTitleKey: 'title',
      groupRightTitleKey: 'rightTitle',
      itemIdKey: 'id',
      itemTitleKey: 'title',
      itemDivTitleKey: 'title',
      itemGroupKey: 'group',
      itemTimeStartKey: 'start',
      itemTimeEndKey: 'end',
    };

    const rerenderComponentKey = `gantt_${events.length}_${timeRange[0].valueOf()}_${timeRange[1].valueOf()}`;

    return (
      <div key={rerenderComponentKey}>
        <Timeline
          style={{ padding: '5px 5px 0 5px' }}
          keys={keys}
          groups={filteredGroups}
          items={events}
          itemRenderer={this.itemRenderer}
          sidebarWidth={0}
          itemsSorted
          stackItems={false}
          showCursorLine
          itemTouchSendsClick={false}
          canMove={false}
          canResize={false}
          itemHeightRatio={0.75}
          dragSnap={moment.duration(10, 'minutes').asMilliseconds()}
          defaultTimeStart={new Date(timeRange[0].valueOf())}
          defaultTimeEnd={new Date(timeRange[1].valueOf())}
          // minZoom={timeRange[0].valueOf()}
          // maxZoom={timeRange[1].valueOf()}
          onTimeChange={this.timeChanged}
          onBoundsChange={this.boundsChanged}
        // onZoom={this.zoomChanged}
        />
        {
          (settings && settings.legend && settings.legend.show) &&
          <div className="recharts-legend-wrapper">
            {this.legendRender(settings && settings.appearance && !settings.appearance.oneline_events ? groups : legend)}
          </div>
        }
      </div>
    );
  }
}

GanttChart.defaultProps = {
  data: {},
  widgetData: [],
  setDateRange: () => { },
  enableZoom: true,
  settings: {},
};

GanttChart.propTypes = {
  data: PropTypes.object,
  height: PropTypes.number,
  // width: PropTypes.number,
  setDateRange: PropTypes.func.isRequired,
  widgetData: PropTypes.array,
  settings: PropTypes.object,
  meta: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  globalFilters: PropTypes.object,
};

export default withTranslation()(GanttChart);
