import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Select from 'react-select';
import api from 'helpers/api';

class AssetGroupLocationPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    const { companyId } = this.props;
    api.get(`/api/v1/industry/asset_locations/?company=${companyId}`)
      .then((json) => this.setState({
        options: get(json, 'data.results'),
        selectedAssetGroupLocation: get(json, 'data.results[0]'),
      }));
  }

  componentDidUpdate(prevProps) {
    const { asset_group_location } = this.props;
    if (asset_group_location !== prevProps.asset_group_location) {
      // eslint-disable-next-line
      this.setState({ selectedAssetGroupType: asset_group_location });
    }
  }

  handleChange = (assetGroupLocation) => {
    const { handleChange } = this.props;
    this.setState({ selectedAssetGroupLocation: assetGroupLocation });
    handleChange(assetGroupLocation);
  }

  renderOption(option) {
    return `${option.street} ${option.street_number}, ${option.city}`;
  }

  render() {
    const { selectedAssetGroupLocation, options } = this.state;
    const { htmlId, customClassName } = this.props;

    return (
      <Select
        cacheOptions
        isClearable
        id={htmlId}
        options={options}
        getOptionLabel={(option) => this.renderOption(option)}
        getOptionValue={(option) => option.id}
        onChange={this.handleChange}
        placeholder="Select group location..."
        value={(options === undefined ? [] : options).find((a) => a.id === selectedAssetGroupLocation.id)}
        className={`AscaliaSelect AssetGroupLocationPicker ${customClassName}`}
      />
    );
  }
}

AssetGroupLocationPicker.propTypes = {
  asset_group_location: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  htmlId: PropTypes.string,
  customClassName: PropTypes.string,
  companyId: PropTypes.number.isRequired,
};

AssetGroupLocationPicker.defaultProps = {
  customClassName: '',
};

export default AssetGroupLocationPicker;
