import React from 'react';
import PropTypes from 'prop-types';
import IndustryWidget from '../IndustryWidget';
import './styles.scss';

const DeviceWidget = ({ devices = [] }) => {
  return (
    <IndustryWidget
      title="Active devices"
    >
      <div className="drone-widget">
        {devices.length}
      </div>
    </IndustryWidget>
  );
};

DeviceWidget.propTypes = {
  devices: PropTypes.array,
};

export default DeviceWidget;
