import React, { Component } from 'react';
import { Sankey, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import WidgetPlaceholder from '../../WidgetPlaceholder';

const targetId = [];
let filteredTargetId = [];
let calculatedNum = [];
let sankeyValues = [];
let linksList = [];
let nodes = [];
let dataSankey = {};

class SankeyChart extends Component {
  getNodesAndLinks = (widgetData = []) => {
    const sortedById = widgetData.sort((a, b) => {
      return (a.id - b.id);
    });

    const nodesData = sortedById.map((node) => {
      const data = {
        name: node.label,
      };
      return data;
    });

    const removeDuplicates = (data, key) => {
      return [...new Map(data.map((x) => [key(x), x])).values()];
    };

    nodes = removeDuplicates(nodesData, (e) => e.name);

    const targetIndex = [];

    linksList = [];

    for (let i = 0; i < nodesData.length; i += 2) {
      const selectedSource = nodesData[i];
      const selectedTarget = nodesData[i + 1];

      const selectedData = {
        source: nodes.indexOf(nodes.find(({ name }) => name === selectedSource.name)),
        target: nodes.indexOf(nodes.find(({ name }) => name === selectedTarget.name)),
      };

      linksList.push(selectedData);
      targetIndex.push(selectedTarget);
    }

    for (let i = 0; i < targetIndex.length; i++) {
      const result = widgetData.find((o) => o.label === targetIndex[i].name);
      targetId.push(result.id);
    }

    filteredTargetId = [...new Set(targetId)];
    this.calculateValues(this.props.data);
  }

  calculateValues = (data = {}) => {
    if (Object.keys(data).length > 1 && filteredTargetId.length > 0) {
      const newKeys = [];

      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i++) {
        newKeys.push(parseInt(keys[i], 10));
      }
      const filteredNewKeys = [...new Set(newKeys)];

      const duplicates = filteredTargetId.filter((val) => filteredNewKeys.includes(val));

      const result = duplicates.map(
        (id) => data[id].map((res) => res),
      );

      const values = duplicates.map(
        (id) => data[id].map(({ value }) => value),
      );

      sankeyValues = [];
      calculatedNum = [];

      for (let i = 0; i < result.length; i++) {
        const selectedArr = result[i];
        const selectedValue = values[i];
        const numberOfSamples = selectedArr.length;
        const sumOfSamples = selectedValue.reduce((a, b) => a + b, 0);
        const firstDate = new Date(selectedArr[0].time);
        const lastDate = new Date(selectedArr[selectedArr.length - 1].time);
        const diffTime = Math.floor(Math.abs((lastDate - firstDate)) / 1000);

        let num = Math.abs(diffTime / (numberOfSamples * sumOfSamples));
        num = parseFloat(num.toFixed(2));
        calculatedNum.push(num);
        sankeyValues = [...new Set(calculatedNum)];
      }
    }

    const linksWithValue = linksList.map((a, b) => ({ ...a, value: sankeyValues[b] }));

    const links = linksWithValue.filter((final, index, self) => index === self.findIndex((t) => (
      t.source === final.source && t.target === final.target
    )));

    dataSankey = {
      nodes,
      links,
    };
  }

  render() {
    const {
      height,
      width,
      data,
      widgetData,
      settings,
    } = this.props;

    let isEmpty = true;

    const keys = Object.keys(data);

    if (keys.length > 1) {
      isEmpty = Object.values(data).some((it) => !it.length);
    }

    if (isEmpty) {
      return (
        <WidgetPlaceholder height={height} type="sankey" />
      );
    }

    this.getNodesAndLinks(widgetData);

    return (
      <Sankey
        width={width}
        height={height}
        data={dataSankey}
        nodePading={50}
        node={{ fill: '#2980B9' }}
        link={{ stroke: '#36C4A8' }}
        style={{ userSelect: 'none' }}
      >
        {
          (settings && settings.appearance && settings.appearance.tooltip) &&
          <Tooltip />
        }
      </Sankey>
    );
  }
}

SankeyChart.defaultProps = {
  data: {},
  widgetData: [],
  enableZoom: true,
  settings: {},
};

SankeyChart.propTypes = {
  data: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  widgetData: PropTypes.array,
  settings: PropTypes.object,
};

export default SankeyChart;
