import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import * as actions from '../../app/actions';

import './styles.scss';

class SubHeader extends Component {
  render() {
    const { children } = this.props;
    return (
      <div className="SubHeader">
        {children}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sidebarCollapsed: get(state, 'app.sidebarCollapsed'),
  };
};

const mapDispatchToProps = {
  toggleSidebar: actions.toggleSidebar,
};

SubHeader.propTypes = {
  children: PropTypes.any,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubHeader));
