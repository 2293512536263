import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Table } from 'shared';
import { defaultDateTimeFormat } from 'shared/constants';
import './style.scss';

const OrderPartDetails = (props) => {
  const { data } = props;
  const {
    part,
    history,
  } = data;

  const { t } = useTranslation();

  if (!part) {
    return '';
  }

  return (
    <Tabs>
      <TabList>
        <Tab>{t('page_content.orders.order_details.order_parts_tab.order_parts_modal.tabs.details')}</Tab>
        <Tab>{t('page_content.orders.order_details.order_parts_tab.order_parts_modal.tabs.history')}</Tab>
      </TabList>

      <TabPanel>
        <div className="order-part-details">
          <img src={part.snowflake ? part.snowflake.image_url : ''} alt="" />
          <div className="order-part-details__items">
            <div>
              {t('page_content.orders.order_details.order_parts_tab.order_parts_modal.details_tab.item_name')}
            </div>
            <h3>
              {part.order_item ? part.order_item.name : '-'}
            </h3>
            <div>
              {t('page_content.orders.order_details.order_parts_tab.order_parts_modal.details_tab.item_number')}
            </div>
            <h3>
              {part.order_item ? part.order_item.item_num : '-'}
            </h3>
            <div>
              {t('page_content.orders.order_details.order_parts_tab.order_parts_modal.details_tab.quantity')}

            </div>
            <h3>
              {part.order_item ? part.order_item.quantity : '-'}
            </h3>
            <div>
              {t('page_content.orders.order_details.order_parts_tab.order_parts_modal.details_tab.quantity_done')}
            </div>
            <h3>
              {part.order_item ? part.order_item.quantity_done : '-'}
            </h3>
          </div>
        </div>
      </TabPanel>
      <TabPanel>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.orders.order_details.order_parts_tab.order_parts_modal.history_tab.table_column_asset')}</span>,
              accessor: 'asset_group.name',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.order_parts_tab.order_parts_modal.history_tab.table_column_location')}</span>,
              accessor: 'asset_group.location',
              Cell: (row) => <span>{row.value && row.value.name ? row.value.name : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.order_parts_tab.order_parts_modal.history_tab.table_column_operators')}</span>,
              accessor: 'operator',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.order_parts_tab.order_parts_modal.history_tab.table_column_time')}</span>,
              accessor: 'created_at',
              Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
              style: {
                cursor: 'default',
              },
            },
          ]}
          data={history}
          minRows={history.length}
          defaultPageSize={history.length}
          noDataText=" "
          showPagination={false}
          sortable
          manual={false}
          defaultSorted={[{ id: 'created_at', desc: true }]}
        />
      </TabPanel>
    </Tabs>
  );
};

OrderPartDetails.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OrderPartDetails;
