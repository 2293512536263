import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Modal, Button, Table, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';
import { IconRemove } from 'shared/Icons';
import { wmsOrderVerify } from '../../../actions';

const CreateOrderModal = ({
  t,
  isCreateOrderModalOpen,
  closeCreateOrderModal,
  createOrder,
  selectedPartsObj,
  selectedParts,
  deselectPart,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showWMSList, setShowWMSList] = useState(false);
  const [input_values, setInputValues] = useState({});
  const [wmsData, setWmsData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const hasPartWithWMS = selectedPartsObj.find((part) => part.product_type.department.name.includes('WMS'));

    if (hasPartWithWMS) {
      const payload = {
        items: selectedParts,
      };
      wmsOrderVerify(payload)
        .then((res) => {
          setWmsData(get(res, 'data.data', []));
          setShowWMSList(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setShowWMSList(false);
      setIsLoading(false);
    }
  }, [selectedPartsObj]);

  const handleInput = (e, row) => {
    const value = e.target.value || null;
    const key = `${row.original.order_item.id}_${row.original.warehouse_id}`;

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [key]: {
        quantity: value,
        warehouse_id: row.original.warehouse_id,
      },
    }));
  };

  const renderInputCell = (row) => {
    // this render function is to prevent rerenders on react-table
    // because with every "onChange" we lose focus from input

    const inputElementIdPrefix = 'input_';
    const cellValue = input_values[row.original.id];

    return (
      <input
        id={`${inputElementIdPrefix}${row.original.id}`}
        type="number"
        defaultValue={cellValue || (row.original.this_quantity ? row.original.this_quantity : 0)}
        onChange={(e) => handleInput(e, row)}
      />
    );
  };

  const generateItemsArray = (data, inputValues) => {
    const items = [];

    data.forEach((item) => {
      const key = `${item.order_item.id}_${item.warehouse_id || ''}`;
      const inputItem = inputValues[key] || {};
      const quantity = inputItem.quantity !== undefined ? inputItem.quantity : item.this_quantity;

      items.push({ id: item.order_item.id, quantity, warehouse_id: item.warehouse_id || null });
    });

    return items;
  };

  const handleSave = async () => {
    const wmsItems = generateItemsArray(wmsData, input_values);

    setIsLoading(true);
    const payload = {
      wms_order: true,
      items: wmsItems,
    };

    await createOrder(showWMSList ? payload : selectedPartsObj, showWMSList);
    setIsLoading(false);
    closeCreateOrderModal();
  };

  return (
    <Modal
      isOpen={isCreateOrderModalOpen}
      handleClose={closeCreateOrderModal}
      handleSave={handleSave}
      size={modalSizes.large}
    >
      <div className="create-order-modal__content">
        {
          isLoading ?
            <ContentLoader /> :
            showWMSList ?
              <Table
                style={{ userSelect: 'text' }}
                columns={[
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.item_name')}</span>,
                    accessor: 'order_item',
                    Cell: (row) => (get(row, 'value.name', '-')),
                    style: {
                      cursor: 'default',
                    },
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.code')}</span>,
                    accessor: 'order_item',
                    Cell: (row) => (get(row, 'value.code', '-')),
                    style: {
                      cursor: 'default',
                    },
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.erp_code')}</span>,
                    accessor: 'order_item',
                    Cell: (row) => (get(row, 'value.manuf_id', '-')),
                    style: {
                      cursor: 'default',
                    },
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.warehouse_name')}</span>,
                    accessor: 'warehouse',
                    Cell: (row) => (get(row, 'value', '-')),
                    style: {
                      cursor: 'default',
                    },
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.warehouse_quantity')}</span>,
                    accessor: 'warehouse_quantity',
                    Cell: (row) => (get(row, 'value', '-')),
                    style: {
                      cursor: 'default',
                    },
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.needed_quantity')}</span>,
                    accessor: 'order_item',
                    Cell: (row) => (get(row, 'value.quantity', '-')),
                    style: {
                      cursor: 'default',
                    },
                  },
                  {
                    Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.order_quantity')}</span>,
                    Cell: renderInputCell,
                    style: {
                      cursor: 'default',
                    },
                  },
                ]}
                data={wmsData || []}
                trClassByProp="warehouse"
                loading={isLoading}
                noDataText=" "
                showPagination={false}
                minRows={0}
                selectedRow={null}
                sortable={false}
              /> :
              <React.Fragment>
                <h4>{t('page_content.orders.order_details.order_groups_tab.confirm_create_order')}</h4><div className="project-parts-modal__list">
                  {selectedPartsObj.map((part, partIdx) => {
                    return (<div key={`part_${partIdx}`} className="project-parts-modal__list-item">
                      {part.name}
                      {selectedParts.length > 1 &&
                        <Button
                          className="remove-btn"
                          type="delete"
                          onClick={() => { deselectPart(part.id); }}
                        >
                          <IconRemove width="12px" height="12px" />
                        </Button>}
                    </div>);
                  })}
                </div>
              </React.Fragment>
        }
      </div>
    </Modal>
  );
};

CreateOrderModal.propTypes = {
  t: PropTypes.func.isRequired,
  isCreateOrderModalOpen: PropTypes.bool.isRequired,
  closeCreateOrderModal: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
  selectedPartsObj: PropTypes.array.isRequired,
  selectedParts: PropTypes.array.isRequired,
  deselectPart: PropTypes.func.isRequired,
};

export default CreateOrderModal;
