/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import api from 'helpers/api';
import {
  AssetPicker,
  MetricPicker,
  Notification,
} from 'shared';
import Select from 'react-select';
import Tooltip from 'rc-tooltip';
import { IconInfo } from 'shared/Icons';
import { groupBy, operations, singleMetricOperations } from '../constants';
import './styles.scss';
import ValueToDisplayPicker from '../../../ValueToDisplayPicker/index';

const groupByOptionsWithoutValue = groupBy.map((group) => group.value).filter((value) => value === 'auto' || value === 'none');
const typesWithAssetVariable = ['line', 'step', 'percent', 'scatter', 'composed', 'bar', 'area', 'pie', 'scalar', 'gantt'];

class DatapointForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asset: props?.datapoint?.style?.textMetric ? { id: 'asset_variable', name: props.t('page_content.dashboards.edit_widget_modal.datapoints_tab.variable') } : props.datapoint && props.datapoint.metric && props.datapoint.metric.asset,
      shifts: [],
      productTypes: [],
      isOEE: props.isOEE ? props.isOEE : false,
      departments: [],
      workOperations: [],
    };

    this.generateFieldOptions = this.generateFieldOptions.bind(this);
    this.updateField = this.updateField.bind(this);
  }

  componentDidMount() {
    const { department } = this.props;
    this.getShifts();
    this.getProductTypes();
    this.getDepartments();
    if (department?.value) this.getWorkOperations(department.value);
  }

  componentDidUpdate(prevProps) {
    if (this.props && this.props.datapoint && this.props.datapoint.metric && this.props.datapoint.metric.asset && prevProps && prevProps.datapoint && prevProps.datapoint.metric && prevProps.datapoint.metric.asset) {
      if (prevProps.datapoint.metric.asset !== this.props.datapoint.metric.asset) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          asset: this.props.datapoint.metric.asset,
        });
      }
    }
  }

  getWorkOperations = async (departmentId) => {
    const url = `/api/v1/work_operations/?department=${departmentId}&limit=9999&order_by=name`;
    try {
      const res = await api.get(url);
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
        throw new Error('');
      }
      this.setState({ workOperations: get(res, 'data.results', []) });
    } catch (error) {
      return Notification('error', 'An error occurred while fetching work operations', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    }
  };

  getDepartments = async () => {
    const { locationId } = this.props;
    const url = `/api/v1/departments/?location=${locationId}&limit=9999&order_by=name`;
    try {
      const res = await api.get(url);
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
        throw new Error('');
      }
      this.setState({ departments: get(res, 'data.results', []) });
    } catch (error) {
      return Notification('error', 'An error occurred while fetching departments', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    }
  };

  getShifts = () => {
    const { locationId } = this.props;
    api.get(`/api/v1/shifts/shifts/?location=${locationId}`)
      .then((re) => {
        if (re.status === undefined || re.status === 400 || re.status === 403 || re.status === 404 || re.status === 500 || !re || !re.data || !re.data.results) {
          return Notification('error', 'An error occurred while fetching shifts', 'We could not perform your request, please try again.');
        }
        this.setState({
          shifts: re.data.results,
        });
      });
  }

  getProductTypes = () => {
    const urlSplit = window.location.href.split('/');
    const companyIdIndex = urlSplit.indexOf('industry') - 1;
    const companyId = urlSplit[companyIdIndex];

    api.get(`/api/v1/product_types/?company=${companyId}`)
      .then((re) => {
        if (re.status === undefined || re.status === 400 || re.status === 403 || re.status === 404 || re.status === 500 || !re || !re.data || !re.data.results) {
          return Notification('error', 'An error occurred while fetching product types', 'We could not perform your request, please try again.');
        }
        this.setState({
          productTypes: re.data.results,
        });
      });
  }

  generateFieldOptions(metric) {
    return metric.field.split(',').map((f) => ({
      value: f,
      label: f,
    }));
  }

  updateField(field, value, indexData = 0) {
    const { type } = this.props;
    if (type && (type === 'scalar' || type === 'gauge')) {
      if (field && field === 'metric' && value && value.label && value.label === 'OEE') {
        this.setState({
          isOEE: true,
        });
        // eslint-disable-next-line react/prop-types
        this.props.changeIsOEE(true);
      } else if (field && field === 'metric') {
        this.setState({
          isOEE: false,
        });
        // eslint-disable-next-line react/prop-types
        this.props.changeIsOEE(false);
        if (typesWithAssetVariable.includes(type)) {
          this.props.changeTextMetric(null, indexData);
        }
      }
    }
    if (field === 'textMetric' && typesWithAssetVariable.includes(type)) {
      this.props.changeTextMetric(value, indexData);
    }
    this.props.onChange({ ...this.props.datapoint, [field]: value });
  }

  resetOEEFields = () => {
    const { type } = this.props;
    if (type && (type === 'scalar' || type === 'gauge')) {
      const {
        updateAllItemsMetric,
        updateBadItemsMetric,
        updateUtilizationMetric,
        updateMaxItemsPerMin,
        // updateValueToDisplay,
      } = this.props;

      updateAllItemsMetric(null);
      updateBadItemsMetric(null);
      updateUtilizationMetric(null);
      updateMaxItemsPerMin(null);
      // updateValueToDisplay(null);
    }
  }

  handleUpdateDepartmentFilter = (value, metricId, indexData) => {
    const { updateDepartmentFilter } = this.props;
    updateDepartmentFilter(value, metricId, indexData);
    if (value) this.getWorkOperations(value);
  }

  render() {
    const {
      datapoint,
      disabled,
      id,
      type,
      indexData,
      t,
      updateAllItemsMetric,
      updateBadItemsMetric,
      updateUtilizationMetric,
      updateMaxItemsPerMin,
      updateValueToDisplay,
      settings,
    } = this.props;

    const { shifts, productTypes, isOEE, asset, departments, workOperations } = this.state;

    const {
      group_by,
      metric,
      operation,
      style,
      label,
    } = datapoint;
    const {
      locationId, updateAdjustedValue, adjustedValue, where, updateWhereFilter, updateWorkOperationsFilter,
      gapFill, updateGapFill, shift, updateShiftFilter, productType, updateProductTypeFilter, department, workOperation,
    } = this.props;

    const metricId = style?.textMetric || metric?.id;

    const adjValue = (metricId && adjustedValue && metricId === adjustedValue.metricId) ? adjustedValue.value : '';
    const whereFilter = (metricId && where && metricId === where.metricId) ? where.value : '';
    // const gapFillChecked = (metric && gapFill && metric.id === gapFill.metricId) ? gapFill.value : false;

    let availableOperations = operations;
    if (['pie', 'scalar', 'gauge'].indexOf(type) >= 0) {
      availableOperations = singleMetricOperations;
    }

    const tooltipForWhereUsage = (
      <div>
        <h4>
          {t('page_content.dashboards.edit_widget_modal.datapoints_tab.where_tooltip.title')}
        </h4>
        <ul>
          <li>{t('page_content.dashboards.edit_widget_modal.datapoints_tab.where_tooltip.description_one')}</li>
          <li>{t('page_content.dashboards.edit_widget_modal.datapoints_tab.where_tooltip.description_two')}</li>
          <li>{t('page_content.dashboards.edit_widget_modal.datapoints_tab.where_tooltip.description_three')}</li>
          <li>{t('page_content.dashboards.edit_widget_modal.datapoints_tab.where_tooltip.description_four')}</li>
        </ul>
        <p>
          {t('page_content.dashboards.edit_widget_modal.datapoints_tab.where_tooltip.description_five')}
        </p>
      </div>
    );

    const tooltipForOperation = (
      <div>
        <p>
          <Trans components={{ br: <br /> }}>page_content.dashboards.edit_widget_modal.datapoints_tab.operation_tooltip</Trans>
        </p>
      </div>
    );

    const fixedMarginStyles = {
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          marginLeft: '6px',
        };
      },
      singleValue: (provided) => ({
        ...provided,
        marginLeft: '6px',
      }),
    };

    return (
      <div className={`datapoint-form ${disabled ? 'disabled' : ''}`}>
        <table>
          <tbody>
            <tr>
              <td className="label">
                {t('page_content.dashboards.edit_widget_modal.datapoints_tab.source')}
              </td>
              <td className="content-td">
                <AssetPicker
                  changeAsset={(val) => { this.setState({ asset: val }); this.updateField('textMetric', null, indexData); }}
                  resetMetric={() => this.updateField('metric', null)}
                  disabled={disabled}
                  asset={asset}
                  locationId={locationId}
                  extendOptions={typesWithAssetVariable.includes(type) ? [{ id: 'asset_variable', name: t('page_content.dashboards.edit_widget_modal.datapoints_tab.variable') }] : []}
                />
              </td>
              {
                asset !== null && (
                  (typeof asset === 'object' && asset?.id !== 'asset_variable') ||
                  (typeof asset !== 'object' && asset !== 'asset_variable')
                ) ?
                  <td className="content-td">
                    <MetricPicker
                      changeMetric={(val) => {
                        this.updateField('metric', val);
                        setTimeout(() => {
                          if (!val) {
                            return;
                          }
                          this.updateField('label', `${asset.name || ''}: ${val.label} [${val.value_type.unit}]`);
                          this.resetOEEFields();
                        }, 200);
                      }}
                      disabled={disabled}
                      metric={!isOEE ? metric : { label: 'OEE', value_type: { unit: '' } }}
                      asset={!!asset && typeof asset === 'object' ? asset.id : asset}
                      id={id}
                      type={type}
                    />
                  </td> :
                  asset?.id === 'asset_variable' || asset === 'asset_variable' ?
                    <td className="content-td">
                      <input
                        placeholder={t('page_content.dashboards.edit_widget_modal.datapoints_tab.variable_metric_placeholder')}
                        onChange={(e) => {
                          this.updateField('label', e.target.value);
                          this.updateField('textMetric', e.target.value, indexData);
                        }}
                        value={style?.textMetric || ''}
                        type="text"
                      />
                    </td> : null
              }
            </tr>
            <tr>
              <td>
                <label>
                  {t('page_content.dashboards.edit_widget_modal.datapoints_tab.label')}
                </label>
              </td>
              <td colSpan="2" className="content-td">
                <input
                  onChange={(e) => this.updateField('label', e.target.value)}
                  value={label}
                  type="text"
                />
              </td>
            </tr>
            {
              !isOEE && type && (type === 'scalar' || type === 'gauge') ?
                <React.Fragment><tr>
                  <td>
                    <label>
                      {t('page_content.dashboards.edit_widget_modal.datapoints_tab.shift')}
                    </label>
                  </td>
                  <td className="content-td">
                    <Select
                      isClearable
                      isDisabled={disabled}
                      onChange={(val) => updateShiftFilter(val ? val.value : null, (style?.textMetric) || metric?.id, indexData)}
                      options={shifts.filter((s) => s.is_shift).map((o) => ({ label: o.name, value: o.id }))}
                      searchable={false}
                      value={shift ? shifts.map((o) => ({ label: o.name, value: o.id })).find((aO) => aO.value === shift.value) : null}
                      className="AscaliaSelect"
                      styles={fixedMarginStyles}
                    />
                  </td>
                </tr><tr>
                    <td>
                      <label>
                        {t('page_content.dashboards.edit_widget_modal.datapoints_tab.product_type')}
                      </label>
                    </td>
                    <td className="content-td">
                      <Select
                        isClearable
                        isDisabled={disabled}
                        onChange={(val) => updateProductTypeFilter(val ? val.value : null, (style?.textMetric) || metric?.id, indexData)}
                        options={productTypes.map((o) => ({ label: `${o.name} (${o.code})`, value: o.id }))}
                        searchable={false}
                        value={productType ? productTypes.map((o) => ({ label: o.name, value: o.id })).find((aO) => aO.value === productType.value) : null}
                        className="AscaliaSelect"
                        styles={fixedMarginStyles}
                      />
                    </td>
                  </tr><tr>
                    <td>
                      <label>
                        {t('page_content.dashboards.edit_widget_modal.datapoints_tab.operation')}
                      </label>
                    </td>
                    <td className="content-td">
                      <Select
                        clearable={false}
                        isDisabled={disabled}
                        onChange={(val) => this.updateField('operation', val.value)}
                        options={availableOperations.map((o) => ({ label: o, value: o }))}
                        searchable={false}
                        value={operation ? availableOperations.map((o) => ({ label: o, value: o })).find((aO) => aO.value === operation) : null}
                        className="AscaliaSelect"
                        styles={fixedMarginStyles}
                      />
                    </td>
                    {(style?.textMetric) || metric?.id ?
                      <td className="content-td"><div
                        style={{
                          display: 'inline-block',
                          width: '100%',
                          verticalAlign: 'middle',
                          boxSizing: 'border-box',
                          height: '33px',
                          border: '1px solid #ddd',
                        }}
                      >
                        <input
                          onChange={(e) => updateAdjustedValue(e.target.value, (style?.textMetric) || metric?.id, indexData)}
                          value={adjValue}
                          type="text"
                          placeholder={t('page_content.dashboards.edit_widget_modal.datapoints_tab.adjusted_value_placeholder')}
                          style={{
                            boxSizing: 'border-box',
                            height: '100%',
                            border: 'none',
                          }}
                        />
                      </div></td> : null}
                    <td>
                      <Tooltip
                        id="tooltip-calcval"
                        trigger={['hover']}
                        placement="right"
                        overlay={tooltipForOperation}
                        overlayClassName="where-filter-tooltip"
                      >
                        <span
                          aria-describedby="tooltip-calcval"
                        >
                          <IconInfo
                            color="#2e86de"
                            height="14px"
                            width="16px"
                          />
                        </span>
                      </Tooltip>
                    </td>
                  </tr><tr>
                    <td>
                      <label>
                        {t('page_content.dashboards.edit_widget_modal.datapoints_tab.work_operations')}
                      </label>
                    </td>
                    <td className="content-td">
                      <Select
                        className="AscaliaSelect"
                        placeholder={t('page_content.dashboards.edit_widget_modal.datapoints_tab.select_placeholder_department')}
                        options={departments}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isDisabled={!metric?.id}
                        isClearable
                        isSearchable
                        onChange={(val) => this.handleUpdateDepartmentFilter(val?.id || null, (style?.textMetric) || metric?.id)}
                        value={departments.find((d) => d.id === department?.value || '')}
                        styles={fixedMarginStyles}
                      />
                    </td>
                    {department?.value && <td className="content-td">
                      <Select
                        className="AscaliaSelect"
                        placeholder={t('page_content.dashboards.edit_widget_modal.datapoints_tab.select_placeholder_work_operations')}
                        options={workOperations}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isDisabled={disabled}
                        isClearable
                        isSearchable
                        onChange={(val) => updateWorkOperationsFilter(val?.id || null, (style?.textMetric) || metric?.id)}
                        value={workOperations.find((d) => d.id === workOperation?.value || '')}
                        styles={fixedMarginStyles}
                      />
                    </td>}
                    </tr><tr>
                    <td>
                      <label>
                        {t('page_content.dashboards.edit_widget_modal.datapoints_tab.group_by')}
                      </label>
                    </td>
                    <td colSpan="2" className="content-td">
                      <Select
                        className="AscaliaSelect"
                        styles={fixedMarginStyles}
                        clearable={false}
                        isDisabled={disabled}
                        onChange={(option) => {
                          const isCurrentOptionWithoutValue = group_by === 'auto' || group_by === 'none';
                          const isNewOptionWithValue = option.value !== 'auto' && option.value !== 'none';
                          // auto, none, [5 minutes] => val
                          let newValue = option.value;
                          // [auto, none] => 5 minutes
                          if (isCurrentOptionWithoutValue && isNewOptionWithValue) {
                            newValue = `1 ${option.value}`;
                          }
                          // 5 minutes => 5minutes
                          if (!isCurrentOptionWithoutValue && isNewOptionWithValue) {
                            newValue = `${group_by.split(' ')[0]} ${option.value}`;
                          }

                          this.updateField('group_by', newValue);
                        }}
                        options={groupBy}
                        searchable={false}
                        value={group_by ? groupBy.find((gB) => gB.value === (group_by.includes(' ') ? group_by.split(' ')[1] : group_by)) : null}
                      />
                      {groupByOptionsWithoutValue.indexOf(group_by) < 0 &&
                        <input
                          onChange={(e) => {
                            this.updateField('group_by', groupByOptionsWithoutValue.indexOf(e.target.value) >= 0
                              ? e.target.value
                              : `${e.target.value} ${group_by.split(' ')[1]}`);
                          }}
                          value={groupByOptionsWithoutValue.indexOf(group_by) >= 0 ? group_by : (group_by.split(' ')[0] || 1)}
                          type="number"
                        />}
                    </td>
                  </tr><tr>
                    <td>
                      <label>
                        {t('page_content.dashboards.edit_widget_modal.datapoints_tab.where')}
                      </label>
                    </td>
                    <td colSpan="2" className="content-td">
                      <div
                        style={{
                          display: 'inline-block',
                          width: '100%',
                          verticalAlign: 'middle',
                          boxSizing: 'border-box',
                          height: '33px',
                          border: '1px solid #ddd',
                        }}
                      >
                        <input
                          onChange={(e) => updateWhereFilter(e.target.value, (style?.textMetric) || metric?.id, indexData)}
                          value={whereFilter}
                          type="text"
                          placeholder={t('page_content.dashboards.edit_widget_modal.datapoints_tab.where_placeholder')}
                          style={{
                            boxSizing: 'border-box',
                            height: '100%',
                            border: 'none',
                          }}
                        />
                      </div>
                    </td>
                    {(style?.textMetric) || metric?.id ?
                      <td>
                        <Tooltip
                          id={`tooltip-${(style?.textMetric) || metric?.id}`}
                          trigger={['hover']}
                          placement="right"
                          overlay={tooltipForWhereUsage}
                          overlayClassName="where-filter-tooltip"
                        >
                          <span
                            aria-describedby={`tooltip-${(style?.textMetric) || metric?.id}`}
                          >
                            <IconInfo
                              color="#2e86de"
                              height="14px"
                              width="16px"
                            />
                          </span>
                        </Tooltip>
                      </td> : null}
                  </tr></React.Fragment> :
                isOEE && type && (type === 'scalar' || type === 'gauge') ? null :
                  <React.Fragment>
                    <tr>
                      <td>
                        <label>
                          {t('page_content.dashboards.edit_widget_modal.datapoints_tab.shift')}
                        </label>
                      </td>
                      <td className="content-td">
                        <Select
                          isClearable
                          isDisabled={disabled}
                          onChange={(val) => updateShiftFilter(val ? val.value : null, (style?.textMetric) || metric?.id, indexData)}
                          options={shifts.filter((s) => s.is_shift).map((o) => ({ label: o.name, value: o.id }))}
                          searchable={false}
                          value={shift ? shifts.map((o) => ({ label: o.name, value: o.id })).find((aO) => aO.value === shift.value) : null}
                          className="AscaliaSelect"
                          styles={fixedMarginStyles}
                        />
                      </td>
                    </tr><tr>
                      <td>
                        <label>
                          {t('page_content.dashboards.edit_widget_modal.datapoints_tab.product_type')}
                        </label>
                      </td>
                      <td className="content-td">
                        <Select
                          isClearable
                          isDisabled={disabled}
                          onChange={(val) => updateProductTypeFilter(val ? val.value : null, (style?.textMetric) || metric?.id, indexData)}
                          options={productTypes.map((o) => ({ label: `${o.name} (${o.code})`, value: o.id }))}
                          searchable={false}
                          value={productType ? productTypes.map((o) => ({ label: o.name, value: o.id })).find((aO) => aO.value === productType.value) : null}
                          className="AscaliaSelect"
                          styles={fixedMarginStyles}
                        />
                      </td>
                    </tr><tr>
                      <td>
                        <label>
                          {t('page_content.dashboards.edit_widget_modal.datapoints_tab.operation')}
                        </label>
                      </td>
                      <td className="content-td">
                        <Select
                          clearable={false}
                          isDisabled={disabled}
                          onChange={(val) => this.updateField('operation', val.value)}
                          options={availableOperations.map((o) => ({ label: o, value: o }))}
                          searchable={false}
                          value={operation ? availableOperations.map((o) => ({ label: o, value: o })).find((aO) => aO.value === operation) : null}
                          className="AscaliaSelect"
                          styles={fixedMarginStyles}
                        />
                      </td>
                      {(style?.textMetric) || metric?.id ?
                        <td className="content-td"><div
                          style={{
                            display: 'inline-block',
                            width: '100%',
                            verticalAlign: 'middle',
                            boxSizing: 'border-box',
                            height: '33px',
                            border: '1px solid #ddd',
                          }}
                        >
                          <input
                            onChange={(e) => updateAdjustedValue(e.target.value, (style?.textMetric) || metric?.id, indexData)}
                            value={adjValue}
                            type="text"
                            placeholder={t('page_content.dashboards.edit_widget_modal.datapoints_tab.adjusted_value_placeholder')}
                            style={{
                              boxSizing: 'border-box',
                              height: '100%',
                              border: 'none',
                            }}
                          />
                        </div></td> : ''}
                      <td>
                        <Tooltip
                          id="tooltip-calcval"
                          trigger={['hover']}
                          placement="right"
                          overlay={tooltipForOperation}
                          overlayClassName="where-filter-tooltip"
                        >
                          <span
                            aria-describedby="tooltip-calcval"
                          >
                            <IconInfo
                              color="#2e86de"
                              height="14px"
                              width="16px"
                            />
                          </span>
                        </Tooltip>
                      </td>
                    </tr><tr>
                    <td>
                        <label>
                          {t('page_content.dashboards.edit_widget_modal.datapoints_tab.work_operations')}
                        </label>
                      </td>
                      <td className="content-td">
                        <Select
                          className="AscaliaSelect"
                          placeholder={t('page_content.dashboards.edit_widget_modal.datapoints_tab.select_placeholder_department')}
                          options={departments}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isDisabled={!metric?.id}
                          isClearable
                          isSearchable
                          onChange={(val) => this.handleUpdateDepartmentFilter(val?.id || null, metric?.id, indexData)}
                          value={departments.find((d) => d.id === department?.value || '')}
                          styles={fixedMarginStyles}
                        />
                      </td>
                      {department?.value && <td className="content-td">
                        <Select
                          className="AscaliaSelect"
                          placeholder={t('page_content.dashboards.edit_widget_modal.datapoints_tab.select_placeholder_work_operations')}
                          options={workOperations}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isDisabled={disabled}
                          isClearable
                          isSearchable
                          onChange={(val) => updateWorkOperationsFilter(val?.id || null, metric?.id, indexData)}
                          value={workOperations.find((d) => d.id === workOperation?.value || '')}
                          styles={fixedMarginStyles}
                        />
                      </td>}
                    </tr><tr>
                      <td>
                        <label>
                          {t('page_content.dashboards.edit_widget_modal.datapoints_tab.group_by')}
                        </label>
                      </td>
                      <td colSpan="2" className="content-td">
                        <Select
                          className="AscaliaSelect"
                          styles={fixedMarginStyles}
                          clearable={false}
                          isDisabled={disabled}
                          onChange={(option) => {
                            const isCurrentOptionWithoutValue = group_by === 'auto' || group_by === 'none';
                            const isNewOptionWithValue = option.value !== 'auto' && option.value !== 'none';
                            // auto, none, [5 minutes] => val
                            let newValue = option.value;
                            // [auto, none] => 5 minutes
                            if (isCurrentOptionWithoutValue && isNewOptionWithValue) {
                              newValue = `1 ${option.value}`;
                            }
                            // 5 minutes => 5minutes
                            if (!isCurrentOptionWithoutValue && isNewOptionWithValue) {
                              newValue = `${group_by.split(' ')[0]} ${option.value}`;
                            }

                            this.updateField('group_by', newValue);
                          }}
                          options={groupBy}
                          searchable={false}
                          value={group_by ? groupBy.find((gB) => gB.value === (group_by.includes(' ') ? group_by.split(' ')[1] : group_by)) : null}
                        />
                        {groupByOptionsWithoutValue.indexOf(group_by) < 0 &&
                          <input
                            onChange={(e) => {
                              this.updateField('group_by', groupByOptionsWithoutValue.indexOf(e.target.value) >= 0
                                ? e.target.value
                                : `${e.target.value} ${group_by.split(' ')[1]}`);
                            }}
                            value={groupByOptionsWithoutValue.indexOf(group_by) >= 0 ? group_by : (group_by.split(' ')[0] || 1)}
                            type="number"
                          />}
                      </td>
                    </tr><tr>
                      <td>
                        <label>
                          {t('page_content.dashboards.edit_widget_modal.datapoints_tab.where')}
                        </label>
                      </td>
                      <td colSpan="2" className="content-td">
                        <div
                          style={{
                            display: 'inline-block',
                            width: '100%',
                            verticalAlign: 'middle',
                            boxSizing: 'border-box',
                            height: '33px',
                            border: '1px solid #ddd',
                          }}
                        >
                          <input
                            onChange={(e) => updateWhereFilter(e.target.value, (style?.textMetric) || metric?.id, indexData)}
                            value={whereFilter}
                            type="text"
                            placeholder={t('page_content.dashboards.edit_widget_modal.datapoints_tab.where_placeholder')}
                            style={{
                              boxSizing: 'border-box',
                              height: '100%',
                              border: 'none',
                            }}
                          />
                        </div>
                      </td>
                      {(style?.textMetric) || metric?.id ?
                        <td>
                          <Tooltip
                            id={`tooltip-${(style?.textMetric) || metric?.id}`}
                            trigger={['hover']}
                            placement="right"
                            overlay={tooltipForWhereUsage}
                            overlayClassName="where-filter-tooltip"
                          >
                            <span
                              aria-describedby={`tooltip-${(style?.textMetric) || metric?.id}`}
                            >
                              <IconInfo
                                color="#2e86de"
                                height="14px"
                                width="16px"
                              />
                            </span>
                          </Tooltip>
                        </td> : ''}
                    </tr>
                  </React.Fragment>
            }
            {
              isOEE && (type === 'scalar' || type === 'gauge') ?
                <React.Fragment>
                  <tr>
                    <td>
                      {t('page_content.dashboards.edit_widget_modal.datapoints_tab.all_items')}
                    </td>
                    <td className="content-td">
                      <MetricPicker
                        clearable
                        changeMetric={(val) => { updateAllItemsMetric(val); }}
                        disabled={disabled}
                        metric={settings.all_items_metric}
                        asset={!!asset && typeof asset === 'object' ? asset.id : asset}
                        id={id}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t('page_content.dashboards.edit_widget_modal.datapoints_tab.bad_items')}
                    </td>
                    <td className="content-td">
                      <MetricPicker
                        clearable
                        changeMetric={(val) => { updateBadItemsMetric(val); }}
                        disabled={disabled}
                        metric={settings.bad_items_metric}
                        asset={!!asset && typeof asset === 'object' ? asset.id : asset}
                        id={id}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t('page_content.dashboards.edit_widget_modal.datapoints_tab.utilization')}
                    </td>
                    <td className="content-td">
                      <MetricPicker
                        clearable
                        changeMetric={(val) => { updateUtilizationMetric(val); }}
                        disabled={disabled}
                        metric={settings.utilization_metric}
                        asset={!!asset && typeof asset === 'object' ? asset.id : asset}
                        id={id}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t('page_content.dashboards.edit_widget_modal.datapoints_tab.max_items_per_min')}
                    </td>
                    <td className="content-td">
                      <input
                        onChange={(e) => { updateMaxItemsPerMin(e.target.value); }}
                        value={settings.max_items_per_min}
                        type="number"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t('page_content.dashboards.edit_widget_modal.datapoints_tab.value_to_display')}
                    </td>
                    <td className="content-td">
                      <ValueToDisplayPicker
                        changeValue={(val) => { updateValueToDisplay(val); }}
                        value={settings.value_to_display}
                      />
                    </td>
                  </tr>
                </React.Fragment> : ''
            }
            {
              updateGapFill && typeof updateGapFill === 'function' ?
                <tr>
                  <td>
                    <label>
                      {t('page_content.dashboards.edit_widget_modal.datapoints_tab.fill_gaps')}
                    </label>
                  </td>
                  <td colSpan="2">
                    <div>
                      <input
                        onChange={(e) => updateGapFill(e.target.checked, (style?.textMetric) || metric?.id, indexData)}
                        defaultChecked={gapFill}
                        type="checkbox"
                        disabled={((style?.textMetric || metric) === null)}
                        style={{
                          width: '20px',
                          height: '20px',
                          marginLeft: '0',
                        }}
                      />
                    </div>
                  </td>
                </tr> : ''
            }
          </tbody>
        </table>
      </div>
    );
  }
}

DatapointForm.propTypes = {
  indexData: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.any,
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  datapoint: PropTypes.object,
  metric: PropTypes.any,
  asset: PropTypes.any,
  updateAdjustedValue: PropTypes.func.isRequired,
  adjustedValue: PropTypes.object,
  where: PropTypes.object,
  updateWhereFilter: PropTypes.func.isRequired,
  gapFill: PropTypes.object,
  updateGapFill: PropTypes.func,
  shift: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateShiftFilter: PropTypes.func,
  productType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateProductTypeFilter: PropTypes.func,
  t: PropTypes.func.isRequired,
  isOEE: PropTypes.bool,
  updateAllItemsMetric: PropTypes.func,
  updateBadItemsMetric: PropTypes.func,
  updateUtilizationMetric: PropTypes.func,
  updateMaxItemsPerMin: PropTypes.func,
  updateValueToDisplay: PropTypes.func,
  settings: PropTypes.any,
  updateDepartmentFilter: PropTypes.func,
  department: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateWorkOperationsFilter: PropTypes.func,
  workOperation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  changeTextMetric: PropTypes.func,
  textMetric: PropTypes.string,
};

export default memo(withTranslation()(DatapointForm));
