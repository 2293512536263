import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  AssetPicker,
  MetricPicker,
} from 'shared';
import './styles.scss';

class SankeyTargetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asset: props.datapoint && props.datapoint.metric && props.datapoint.metric.asset,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (this.props.datapoint && this.props.datapoint.metric && this.props.datapoint.metric.asset !== null) {
      if (nextProps.datapoint.metric.asset !== this.props.datapoint.metric.asset) {
        this.setState({
          asset: nextProps.datapoint.metric.asset,
        });
      }
    }
  }

  updateField = (field, value) => {
    this.props.onChange({ ...this.props.datapoint, [field]: value });
  }

  render() {
    const {
      datapoint,
      disabled,
      id,
    } = this.props;
    const { asset } = this.state;

    const {
      metric,
      label,
    } = datapoint;
    const {
      locationId,
    } = this.props;

    return (
      <div className={`datapoint-form ${disabled ? 'disabled' : ''}`}>
        <table>
          <tbody>
            <tr>
              <td className="label">
                Target
              </td>
              <td className="content-td">
                <AssetPicker
                  changeAsset={(val) => this.setState({ asset: val })}
                  resetMetric={() => this.updateField('metric', null)}
                  disabled={disabled}
                  asset={asset}
                  locationId={locationId}
                />
              </td>
              {
                asset !== null &&
                <td className="content-td">
                  <MetricPicker
                    changeMetric={(val) => {
                      this.updateField('metric', val);
                      setTimeout(() => {
                        if (!val) {
                          return;
                        }
                        this.updateField('label', `${asset.name || ''}: ${val.label} [${val.value_type.unit}]`);
                      }, 200);
                    }}
                    disabled={disabled}
                    metric={metric}
                    asset={!!asset && typeof asset === 'object' ? asset.id : asset}
                    id={id}
                  />
                </td>
              }
            </tr>
            <tr>
              <td>
                <label>
                  Target label
                </label>
              </td>
              <td colSpan="2" className="content-td">
                <input
                  onChange={(e) => this.updateField('label', e.target.value)}
                  value={label}
                  type="text"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

SankeyTargetForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  disabled: PropTypes.any,
  id: PropTypes.number.isRequired,
  datapoint: PropTypes.object,
  metric: PropTypes.any,
  asset: PropTypes.any,
};

export default SankeyTargetForm;
