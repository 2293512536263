import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { checkAccessOnPage, redirectToHomePage, checkModules } from 'industry/helpers';
import UploadStepOne from './steps/StepOne';
import {
  getWorkProcesses,
  uploadAllData,
  getCalculation,
  getUploads,
} from './actions';
import './style.scss';
import UploadStepTwo from './steps/StepTwo';
import UploadStepThree from './steps/StepThree';
import UploadList from './steps/StepFour';

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      base64Images: [],
      calculatedData: {},
      calculatedColors: {},
      photos: {},
      program: null,
      programDetails: {},
      isReadOnly: false,
      uploadIds: {},
      selectedClasses: [],
    };
  }

  componentDidMount() {
    const {
      companyId, locationId,
    } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Upload');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          getWorkProcesses(companyId)
            .then((re) => {
              const r = re.data.results;
              this.setState({
                workProcess: r ? r[0] : {},
                isReadOnly: true,
                base64Images: [],
                step: 4,
              });
            });
        }
      });

    getWorkProcesses(companyId)
      .then((re) => {
        const {
          workProcess,
        } = this.state;
        if (!workProcess) {
          const r = re.data.results;
          this.setState({
            workProcess: r ? r[0] : {},
          });
        }
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.resetComponent();
    }
  }

  onFileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      this.handleFile(files);
    }
  }

  onChangeInput = (e) => {
    const files = e.target.files;
    if (files && files.length) {
      this.handleFile(files);
    }
  }

  // onSelectButtonClick = () => {
  //   const input = document.createElement('input');
  //   input.type = 'file';
  //   input.multiple = true;
  //   input.onchange = (e) => {
  //     const files = e.target.files;
  //     if (files && files.length) {
  //       this.handleFile(files);
  //     }
  //   };

  //   input.click();
  // }

  getCalculatedData = (uploadId, fileName = '', isOld = false) => {
    const {
      workProcess, uploadIds, calculatedColors, calculatedData, photos,
    } = this.state;

    if (uploadIds[uploadId]) {
      return;
    }

    getCalculation(workProcess.id, uploadId)
      .then((re) => {
        const count = re.data.count;
        if (count && re.data.results[0].results) {
          if (!isOld) {
            uploadIds[uploadId] = true;
            calculatedData[uploadId] = re.data.results[0].results.result.result;
            calculatedColors[uploadId] = re.data.results[0].results.result.colors;
            photos[uploadId] = re.data.results[0].upload.file;
            const selectedClasses = re.data.results[0].upload && re.data.results[0].upload.parameters && re.data.results[0].upload.parameters.selected_classes ? re.data.results[0].upload.parameters.selected_classes : [];
            const selectedColors = re.data.results[0].upload && re.data.results[0].upload.parameters && re.data.results[0].upload.parameters.selected_colors ? re.data.results[0].upload.parameters.selected_colors : [];
            this.setState({
              calculatedData,
              calculatedColors,
              uploadIds,
              photos,
              selectedClasses,
              selectedColors,
            });
          } else {
            const program = re && re.data && re.data.results && re.data.results[0] && re.data.results[0].upload && re.data.results[0].upload.parameters && re.data.results[0].upload.parameters.program ? re.data.results[0].upload.parameters.program : '';
            const newCalculatedData = {};
            const newCalculatedColors = {};
            const newPhotos = {};
            newCalculatedData[uploadId] = re && re.data && re.data.results && re.data.results[0] && re.data.results[0].results.result && re.data.results[0].results.result.result ? re.data.results[0].results.result.result : {};
            newCalculatedColors[uploadId] = re && re.data && re.data.results && re.data.results[0] && re.data.results[0].results.result && re.data.results[0].results.result.colors ? re.data.results[0].results.result.colors : {};
            newPhotos[uploadId] = re && re.data && re.data.results && re.data.results[0] && re.data.results[0].upload && re.data.results[0].upload.file ? re.data.results[0].upload.file : '';
            const selectedClasses = re.data.results[0].upload && re.data.results[0].upload.parameters && re.data.results[0].upload.parameters.selected_classes ? re.data.results[0].upload.parameters.selected_classes : [];
            const selectedColors = re.data.results[0].upload && re.data.results[0].upload.parameters && re.data.results[0].upload.parameters.selected_colors ? re.data.results[0].upload.parameters.selected_colors : [];
            this.setState({
              calculatedData: newCalculatedData,
              calculatedColors: newCalculatedColors,
              program,
              programDetails: workProcess.choices.programs[program],
              files: [{
                uploadId,
                name: fileName,
              }],
              step: 3,
              base64Images: [],
              photos: newPhotos,
              selectedClasses,
              selectedColors,
            });
          }
        } else {
          // eslint-disable-next-line
          if (!isOld) {
            setTimeout(() => {
              this.getCalculatedData(uploadId);
            }, 1000);
          }
        }
      })
      .catch((err) => console.log(err));
  }

  getPreviousUploads = (query, url) => {
    return new Promise((resolve, reject) => {
      const { companyId } = this.props;
      const { workProcess } = this.state;
      getUploads(companyId, workProcess.id, query, url)
        .then((re) => {
          resolve(re.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  setImage = (base64Image) => {
    const { base64Images } = this.state;
    base64Images.push(base64Image);
    this.setState({
      base64Images,
    });
  }

  resetComponent = () => {
    this.setState({
      step: 1,
      base64Images: [],
      calculatedData: {},
      calculatedColors: {},
      photos: {},
      program: null,
      programDetails: {},
      isReadOnly: false,
      uploadIds: {},
    });
  }

  preventDefaultEvent = (e) => {
    e.preventDefault();
  }

  validateFile = (file) => {
    const maxSize = 5242880;
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon', 'image/bmp'];
    if (validTypes.indexOf(file.type) === -1) {
      alert('File type is not valid. Please upload a valid file type.');
      return false;
    }

    if (file.size > maxSize) {
      alert('File size is too large. Please upload a smaller file.');
      return false;
    }

    return true;
  }

  handleFile = (files) => {
    let filesOK = true;
    for (let i = 0; i < files.length; i += 1) {
      if (!this.validateFile(files[i])) {
        filesOK = false;
      }
    }

    if (!filesOK) {
      return;
    }

    this.setState({
      files,
      step: 2,
      base64Images: [],
    });
  }

  resetUpload = () => {
    this.setState({
      step: 1,
      base64Images: [],
      calculatedData: {},
      calculatedColors: {},
      photos: {},
    });
  }

  showList = () => {
    this.setState({
      step: 4,
      base64Images: [],
    });
  }

  uploadData = (colors, program, selectedClasses) => {
    const { companyId } = this.props;
    const { files, workProcess } = this.state;

    this.setState({
      step: 3,
      base64Images: [],
      programDetails: workProcess.choices.programs[program],
      program,
      selectedColors: colors,
    });

    const formDatas = [];

    for (let i = 0; i < files.length; i += 1) {
      const formData = new FormData();
      formData.append('work_process', workProcess.id);
      formData.append('parameters', `{ "program": "${program}", "selected_colors": "${colors}", "selected_classes": "${selectedClasses}" }`);
      formData.append('file', files[i]);
      formDatas.push(formData);
    }

    formDatas.forEach((formData, index) => {
      uploadAllData(formData, companyId)
        .then((re) => {
          const { uploadIds } = this.state;
          uploadIds[re.data.id] = false;
          files[index].uploadId = re.data.id;
          this.setState({
            uploadIds,
            files,
          }, () => {
            this.getCalculatedData(re.data.id);
          });
        })
        .catch((err) => console.log(err));
    });
  }

  toUpload = () => {
    this.setState({
      step: 1,
      base64Images: [],
      calculatedData: {},
      calculatedColors: {},
      photos: {},
    });
  }

  resetUploads = () => {
    return new Promise((resolve) => {
      this.setState({
        uploadIds: {},
      }, () => {
        resolve();
      });
    });
  }

  render() {
    const { companyId } = this.props;
    const {
      step, files, workProcess, calculatedData, calculatedColors,
      program, programDetails, isReadOnly, photos, selectedClasses,
      selectedColors,
    } = this.state;

    return (
      <div className="industry-tab fullscreen">
        <div>
          {
            step === 1 ?
              <UploadStepOne
                onFileDrop={this.onFileDrop}
                dragLeave={this.preventDefaultEvent}
                dragEnter={this.preventDefaultEvent}
                dragOver={this.preventDefaultEvent}
                onChangeInput={this.onChangeInput}
                isReadOnly={isReadOnly}
                onSelectButtonClick={this.onSelectButtonClick}
                showList={this.showList}
              /> : ''
          }
          {
            step === 2 ?
              <UploadStepTwo
                files={files}
                workProcess={workProcess}
                resetUpload={this.resetUpload}
                uploadData={this.uploadData}
                setImage={this.setImage}
              /> : ''
          }
          {
            step === 3 ?
              <UploadStepThree
                data={calculatedData}
                colorsData={calculatedColors}
                photos={photos}
                showList={this.showList}
                files={files}
                program={program}
                programDetails={programDetails}
                toUpload={this.toUpload}
                selectedClasses={selectedClasses}
                selectedColors={selectedColors}
              /> : ''
          }
          {
            step === 4 ?
              <UploadList
                workProcess={workProcess}
                toUpload={this.toUpload}
                getUploads={this.getPreviousUploads}
                showCalculation={this.getCalculatedData}
                isReadOnly={isReadOnly}
                resetUploads={this.resetUploads}
                companyId={companyId}
              /> : ''
          }
        </div>
      </div>
    );
  }
}

Upload.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  location: PropTypes.object,
  key: PropTypes.string,
};

export default withRouter(Upload);
