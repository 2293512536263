import api from 'helpers/api';
import { Notification } from 'shared';

// eslint-disable-next-line import/prefer-default-export
export const getAssets = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/assets/?company=${companyId}&fat=1`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching assets', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getShifts = async (locationId) => {
  try {
    const res = await api
      .get(`/api/v1/shifts/shifts/?location=${locationId}&limit=30`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching shifts', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getShiftsPaginated = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching shifts', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const saveShift = (data) => {
  return api.post(`/api/v1/shifts/shifts/?location=${data.location}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Save successful', 'Shift was successfully created.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};
export const editShift = (data) => {
  return api.put(`/api/v1/shifts/shifts/${data.id}/?location=${data.location}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Save successful', 'Shift was successfully updated.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};
export const deleteShift = (data) => {
  return api.delete(`/api/v1/shifts/shifts/${data.id}/?location=${data.location}`)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Delete successful', 'Shift was successfuly deleted.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};
