import React from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { Modal } from 'shared';
import './style.scss';

const defaultColors = ['rgb(231,66,69)', 'rgb(252,189,78)', 'rgb(24,155,114)'];

class ThresholdColors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isColorPickerOpen: false,
      colorPickerIndex: 0,
    };
  }

  setColors = (index, color) => {
    const { setThresholdColors } = this.props;
    setThresholdColors(index, color);
  }

  openColorPicker = (index) => {
    this.setState({
      isColorPickerOpen: true,
      colorPickerIndex: index,
    });
  }

  render() {
    const {
      colorsCount, thresholdColors,
    } = this.props;

    const {
      isColorPickerOpen, colorPickerIndex,
    } = this.state;

    if (!colorsCount) {
      return '';
    }

    // populate default colors
    if (colorsCount !== thresholdColors.length) {
      for (let i = 0; i < colorsCount; i += 1) {
        if (!thresholdColors[i]) {
          thresholdColors[i] = defaultColors[i];
        }
      }
    }

    return (
      <React.Fragment>
        <Modal
          isOpen={isColorPickerOpen}
          closeText="Done"
          handleClose={() => { this.setState({ isColorPickerOpen: false }); }}
        >
          <SketchPicker
            color={thresholdColors[colorPickerIndex] || '#ffffff'}
            onChangeComplete={(color) => this.setColors(colorPickerIndex, color.hex)}
          />
        </Modal>
        <div>
          {thresholdColors.map((tC, index) => <span className="threshold-color" style={{ backgroundColor: tC }} onClick={() => { this.openColorPicker(index); }} />)}
        </div>
      </React.Fragment>
    );
  }
}

ThresholdColors.propTypes = {
  colorsCount: PropTypes.number.isRequired,
  thresholdColors: PropTypes.array.isRequired,
  setThresholdColors: PropTypes.func.isRequired,
};

export default ThresholdColors;
