import React, { Component } from 'react';
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Table, ContentLoader, Button, Modal, ConfirmationModal } from 'shared';
import { defaultDateTimeFormat, modalSizes } from 'shared/constants';
import { selectStyles } from 'styles/modules/reactSelect';
import { styledStatusOptions } from 'industry/helpers';

import OrderPhasesTimeline from '../OrderPhasesTimeline/index';
import { updateOrderStatus } from '../../actions';
import './style.scss';

class OrderDetails extends Component {
  constructor() {
    super();
    this.state = {
      isParametersModalOpen: false,
      showConfirmationDialog: false,
      old_status: null,
      new_status: null,
    };
  }

  onStatusChange = () => {
    const { new_status } = this.state;
    const { onStatusChange, order, setDetailsLoader } = this.props;

    if (order && order.id) {
      const data = {
        order: order.id,
        status: new_status,
        update_groups_and_items: true,
      };

      setDetailsLoader(true);

      updateOrderStatus(data)
        .then(() => {
          onStatusChange();
          this.setState({
            showConfirmationDialog: false,
          });
        });
    }
  }

  handleStatusDropdownChange = (old_status, new_status) => {
    this.setState({
      old_status,
      new_status,
      showConfirmationDialog: true,
    });
  }

  handleParametersModalOpen = () => {
    this.setState({
      isParametersModalOpen: true,
    });
  }

  handleParametersModalClose = () => {
    this.setState({
      isParametersModalOpen: false,
    });
  }

  firstLetterUpperCase = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  createHtmlList = (obj) => {
    if (obj === null) {
      return null;
    }

    return Object.keys(obj).map((item, i) => (
      <li key={i}>
        {this.firstLetterUpperCase(item)} :
        {typeof obj[item] !== 'object' ? (
          ` ${obj[item]}`
        ) : Array.isArray(obj[item]) ? (
          ` ${obj[item].join(', ')}`
        ) : (
          <ul>{this.createHtmlList(obj[item])}</ul>
        )}
      </li>
    ));
  };

  render() {
    const { isParametersModalOpen, showConfirmationDialog, old_status, new_status } = this.state;
    const {
      t,
      order,
      modules,
      statuses,
      histories,
      companyId,
      isReadOnly,
      archiveLabel,
      orderTimeline,
      status_change,
      statusOptions,
      statusHistory,
      getLocationName,
      isLoadingHistory,
      isLoadingDetails,
      ordersArchivePermission,
    } = this.props;

    const selectedStatus = order && order.status ? order.status : null;

    const parametersModalData = order && order.data ? order.data : null;

    const options = ordersArchivePermission
      ? status_change && !statuses.includes('archived')
        ? [...statuses, 'archived'].map((status) => ({ value: status, label: status === 'archived' && archiveLabel ? archiveLabel : status }))
        : statuses.map((status) => ({ value: status, label: status === 'archived' && archiveLabel ? archiveLabel : status }))
      : statuses.filter((status) => status !== 'archived').map((status) => ({ value: status, label: status }));

    const detailsTableConfig = [
      {
        name: `${t('page_content.orders.order_details.order_details_tab.details_table.location')}:`,
        data: order && order.location && order.location.name ? order.location.name : '-',
      },
      {
        name: `${t('page_content.orders.order_details.order_details_tab.details_table.order_number')}:`,
        data: order.external_id || '-',
      },
      {
        name: `${t('page_content.orders.order_details.order_details_tab.details_table.internal_order_number')}:`,
        data: order.external_order_id || '-',
      },
      {
        name: `${t('page_content.orders.order_details.order_details_tab.details_table.status')}:`,
        data: status_change
          ?
          statuses && statuses.length ?
            <div>
              <Select
                options={options}
                getOptionLabel={(option) => (option.label === archiveLabel ? archiveLabel : t([`page_content.orders.statuses.${option.label}`]))}
                getOptionValue={(option) => option.value}
                isSearchable
                isDisabled={isReadOnly}
                onChange={(e) => this.handleStatusDropdownChange(selectedStatus, e.value)}
                value={(statuses.map((status) => ({ value: status, label: status })).find((status) => status.value === selectedStatus)) || ''}
                styles={selectStyles}
                menuPortalTarget={document.body}
                menuPosition="fixed"
              />
            </div> :
            <div>
              <Select
                options={statusOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                isSearchable
                isDisabled={isReadOnly}
                onChange={(e) => this.handleStatusDropdownChange(selectedStatus, e.value)}
                value={(statusOptions && statusOptions.find((status) => status.value === selectedStatus)) || ''}
                styles={selectStyles}
                menuPortalTarget={document.body}
                menuPosition="fixed"
              />
            </div>
          : <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span style={styledStatusOptions(order.status)}>{t([`page_content.orders.statuses.${order.status}`])}</span>
          </div>,
      },
      {
        name: `${t('page_content.orders.order_details.order_details_tab.details_table.operator')}:`,
        data: order.operator || '-',
      },
      {
        name: `${t('page_content.orders.order_details.order_details_tab.details_table.comment')}:`,
        data: order.comment || '-',
      },
      ...(statusHistory ? statusHistory.map((item) => ({
        name: t([`page_content.orders.statuses.${item.status}`]),
        data: moment(item.time).format(defaultDateTimeFormat),
      })) : []),
    ];

    const old_status_text = old_status === 'archived' && archiveLabel ? archiveLabel : old_status;
    const new_status_text = new_status === 'archived' && archiveLabel ? archiveLabel : new_status;

    return (
      <div className="details_container">
        <div style={{ width: '220px' }}>
          <Button onClick={this.handleParametersModalOpen}>{t('page_content.orders.order_details.order_details_tab.show_order_parameters')}</Button>
        </div>
        <div className="table_row">
          <div className="info_table">
            <div className="header">{t('page_content.orders.order_details.order_details_tab.details_table.details_title')}</div>
            {isLoadingDetails ? <div className="loading"><ContentLoader /></div> :
              detailsTableConfig.map((row, i) => (
                <div key={i} className="row">{row.name}<span>{row.data}</span></div>
              ))}
          </div>
          <div className="react_table_wrapper">
            <div className="header">{t('page_content.orders.order_details.order_details_tab.production_process_table.production_process_title')}
            </div>
            <Table
              style={{ userSelect: 'text' }}
              columns={[
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_details_tab.production_process_table.table_column_position')}</span>,
                  accessor: 'order_item.item_num',
                  Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_details_tab.production_process_table.table_column_item')}</span>,
                  accessor: 'order_item.name',
                  Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_details_tab.production_process_table.table_column_asset')}</span>,
                  accessor: 'asset_group.name',
                  Cell: (row) => <span>{row.value ? (row.original.asset_group && row.original.asset_group.location ? `${row.value} ${getLocationName(row.original.asset_group.location)}` : row.value) : '-'}</span>,
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_details_tab.production_process_table.table_column_operators')}</span>,
                  accessor: 'operator',
                  Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_details_tab.production_process_table.table_column_time')}</span>,
                  accessor: 'created_at',
                  Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
                  style: {
                    cursor: 'default',
                  },
                },
              ]}
              data={histories}
              minRows={0}
              defaultPageSize={5}
              noDataText=" "
              showPagination={false}
              sortable={false}
              loading={isLoadingHistory}
            />
          </div>
        </div>

        {
          orderTimeline && orderTimeline.length && modules['Order timeline'] ?
            <div className="timeline_container">
              <div
                className="order-details details-table"
                style={{ borderBottom: '2px solid #eee', background: 'white' }}
              >
                <div className="details-table__title">
                  {t('page_content.orders.order_details.order_details_tab.timeline.timeline_title')}
                </div>
                <div className="details-table__row--full">
                  <OrderPhasesTimeline timeline={orderTimeline} order={order} companyId={companyId} />
                </div>
              </div>
            </div> : ''
        }

        <Modal
          isOpen={isParametersModalOpen}
          handleClose={this.handleParametersModalClose}
          size={modalSizes.full}
        >
          <div style={{ padding: '10px' }}>
            {parametersModalData && Object.keys(parametersModalData).length !== 0 ?
              <div className="details_list">{this.createHtmlList(parametersModalData)}</div>
              : <div style={{ textAlign: 'center' }}>No data</div>}
          </div>

        </Modal>

        <ConfirmationModal
          customTitle={t('page_content.orders.order_details.order_details_tab.status_change_confirmation_title', { old_status: old_status_text, new_status: new_status_text })}
          showModal={showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.onStatusChange}
          type="warning"
        />
      </div>
    );
  }
}

OrderDetails.propTypes = {
  statuses: PropTypes.array,
  t: PropTypes.func.isRequired,
  archiveLabel: PropTypes.string,
  statusHistory: PropTypes.array,
  orderTimeline: PropTypes.array,
  modules: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  histories: PropTypes.array.isRequired,
  companyId: PropTypes.number.isRequired,
  ordersArchivePermission: PropTypes.bool,
  statusOptions: PropTypes.array.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  getLocationName: PropTypes.func.isRequired,
  isLoadingHistory: PropTypes.bool.isRequired,
  isLoadingDetails: PropTypes.bool.isRequired,
  setDetailsLoader: PropTypes.func.isRequired,
  status_change: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  order: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default withTranslation()(OrderDetails);
