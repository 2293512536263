import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GithubPicker } from 'react-color';
import './styles.scss';

class ColorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: props.color,
      isPickerShown: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(color) {
    this.setState({
      color: color.hex,
      isPickerShown: false,
    });
    this.props.onColorUpdate(color.hex);
  }

  render() {
    return (
      <div className="color-picker-container">
        <div
          className="color"
          onClick={() => this.setState((prevState) => ({ isPickerShown: !prevState.isPickerShown }))}
          style={{ background: this.state.color }}
        />
        {
          this.state.isPickerShown &&
          <div className="color-picker-popup">
            <GithubPicker
              color={this.state.color}
              colors={['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB']}
              onChangeComplete={this.handleChange}
              triangle="hide"
            />
          </div>
        }
      </div>
    );
  }
}

ColorPicker.propTypes = {
  color: PropTypes.string,
  onColorUpdate: PropTypes.func.isRequired,
};

ColorPicker.defaultProps = {
  color: '#333',
};

export default ColorPicker;
