import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Notification } from 'shared';
import Select from 'react-select';
import api from 'helpers/api';

class MetricPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metric: props.metric,
      options: [],
    };
  }

  componentDidMount() {
    this.getOptions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.metric !== this.props.metric) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        metric: this.props.metric,
      });
    }
    if (prevProps.asset !== this.props.asset) {
      // eslint-disable-next-line
      // this.setState({ metric: null });
      // this.handleChange(null);
      this.getOptions();
    }
  }

  getOptions = () => {
    const { asset, type } = this.props;
    const assetString = typeof asset !== 'undefined'
      ? `&asset=${asset}`
      : '';

    return api.get(`/api/v1/industry/metrics/?order_by=label${assetString}&limit=200`)
      .then((json) => {
        if (json.status === undefined || json.status === 400 || json.status === 403 || json.status === 404 || json.status === 500 || !json || !json.data || !json.data.results) {
          return Notification('error', 'An error occurred while fetching metrics', 'We could not perform your request, please try again.');
        }
        this.setState({
          options: (type === 'scalar' || type === 'gauge') ? [...get(json, 'data.results'), { label: 'OEE', value_type: { unit: '' } }] : get(json, 'data.results'),
        });
      });
  }

  handleChange = (val) => {
    const { changeMetric } = this.props;
    this.setState({ metric: val });
    changeMetric(val);
  }

  render() {
    const {
      disabled,
      id,
      t,
      clearable,
    } = this.props;
    const {
      metric,
      options,
    } = this.state;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderTop: state.data && state.data.label && state.data.label === 'OEE' ? '2px solid #3184ff' : '',
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          marginLeft: '5px',
        };
      },
      singleValue: (provided) => ({
        ...provided,
        marginLeft: '5px',
      }),
    };

    return (
      <Select
        isClearable={clearable || false}
        styles={customStyles}
        id={`metric-picker-${id}`}
        isDisabled={disabled}
        getOptionLabel={(option) => `${option.label} ${option.value_type.unit ? `[${option.value_type.unit}]` : ''}`}
        getOptionValue={(option) => option.id || 'OEE'}
        options={options}
        onChange={this.handleChange}
        placeholder={t('shared.metric_picker.placeholder')}
        value={metric}
        cacheOptions
        className="AscaliaSelect MetricPicker"
      />
    );
  }
}

MetricPicker.propTypes = {
  disabled: PropTypes.bool,
  asset: PropTypes.number.isRequired,
  changeMetric: PropTypes.func,
  metric: PropTypes.any.isRequired,
  id: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
  clearable: PropTypes.bool,
};

MetricPicker.defaultProps = {
  changeMetric: null,
  disabled: false,
};

export default withTranslation()(MetricPicker);
