import api from 'helpers/api';
import { Notification } from 'shared';
import moment from 'moment';

export const getRoles = (companyId, locationId) => api.get(`/api/v1/roles/?company=${companyId}&location=${locationId}`);

export const getProductionRecordsByLocation = async (locationId, filters = '', limit = 30) => {
  try {
    const res = await api
      .get(`/api/v1/production_records/logs/?location=${locationId}${filters}&limit=${limit}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching production records', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProductionRecords = async (locationId, filters = '', limit = 30) => {
  try {
    const res = await api
      .get(`/api/v1/production_records/records/?limit=${limit}&location=${locationId}${filters}&order_by=title`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching production records', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedProductionRecords = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching production records', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getLogs = async (production_record, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/production_records/logs/?production_record=${production_record}${filters}&limit=20`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching production logs', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const exportToPdf = async (selectedRecord, dateFilterStart, dateFilterEnd, line) => {
  const urlString = '/api/v1/production_records/logs/';
  let urlParams = `?production_record=${selectedRecord}&format=pdf`;

  if (dateFilterStart) {
    urlParams += `&time_created__gte=${moment(dateFilterStart).startOf('day').utc().toISOString()}`;
  }
  if (dateFilterEnd) {
    urlParams += `&time_created__lte=${moment(dateFilterEnd).endOf('day').utc().toISOString()}`;
  }
  if (line && line !== 'all') {
    urlParams += `&line=${line}`;
  }

  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl, { responseType: 'blob' });
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while exporting to pdf', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const exportToXlsx = async (selectedRecord, dateFilterStart, dateFilterEnd, line, companyId) => {
  const urlString = '/api/v1/production_records/logs/';
  let urlParams = `?production_record=${selectedRecord}&format=xlsx`;

  if (dateFilterStart) {
    urlParams += `&time_created__gte=${moment(dateFilterStart).startOf('day').utc().toISOString()}`;
  }
  if (dateFilterEnd) {
    urlParams += `&time_created__lte=${moment(dateFilterEnd).endOf('day').utc().toISOString()}`;
  }
  if (line && line !== 'all') {
    urlParams += `&line=${line}`;
  }
  if (companyId && companyId !== '') {
    urlParams += `&company=${companyId}`;
  }

  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl, { responseType: 'blob' });
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while exporting to xlsx', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedLogs = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching production logs', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getLines = async (company_short_code, location = '', filter = '') => {
  try {
    const res = await api
      .get(`/api/v1/planning/lines/?company=${company_short_code}&location=${location}${filter}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching groups', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getCompanyData = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/companies/${companyId}/`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching companies', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getResources = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/resources/?company=${companyId}&limit=999`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching roles', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addProductionRecord = (locationId, data) => {
  return api.post(`/api/v1/production_records/records/?location=${locationId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Record was successfully created.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const editProductionRecord = (locationId, data) => {
  return api.put(`/api/v1/production_records/records/${data.id}/?location=${locationId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Record was successfully updated.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const deleteProductionRecord = async (id, locationId) => {
  try {
    const res = await api
      .delete(`/api/v1/production_records/records/${id}/?location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while deleting', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addProductionRecordEntry = (recordId, data) => {
  return api.post(`/api/v1/production_records/entries/?production_record=${recordId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return res;
    });
};

export const editProductionRecordEntry = (entryId, recordId, data) => {
  return api.put(`/api/v1/production_records/entries/${entryId}/?production_record=${recordId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return res;
    });
};

export const deleteProductionRecordEntry = async (entryId, recordId) => {
  try {
    const res = await api
      .delete(`/api/v1/production_records/entries/${entryId}/?production_record=${recordId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while deleting', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const signEntry = (id, recordId, data) => {
  return api.patch(`/api/v1/production_records/logs/${id}/?production_record=${recordId}`, data);
};

export const getAssets = async (companyId, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/assets/?company=${companyId}&location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching assets', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addProductionRecordLog = (data) => {
  return api.post('/api/v1/production_records/logs/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Log was successfully created.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const editProductionRecordLog = (data, selectedRowId) => {
  return api.put(`/api/v1/production_records/logs/${selectedRowId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Edit successful', 'Log was successfully edited.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getOrders = async (companyId, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/orders/orders/?company=${companyId}&location=${locationId}&status=in_progress`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching orders', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getFolders = async (locationId, filters = '', limit = 30) => {
  try {
    const res = await api
      .get(`/api/v1/production_records/folders/?location=${locationId}&limit=${limit}${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching folders', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedFolders = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching folders', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteFolder = async (id, locationId) => {
  try {
    const res = await api
      .delete(`/api/v1/production_records/folders/${id}/?location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while deleting', error?.response?.data?.message ? error.response.data.message : 'We could not perform your request, please try again.');
  }
};

export const addFolder = (locationId, data) => {
  return api.post(`/api/v1/production_records/folders/?location=${locationId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Folder was successfully created.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const editFolder = (locationId, id, data) => {
  return api.put(`/api/v1/production_records/folders/${id}/?location=${locationId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Folder was successfully created.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getStatusOverview = async (production_record, from, to) => {
  try {
    const res = await api
      .get(`/api/v1/production_records/status_overview/?production_record=${production_record}&from_date=${from}&to_date=${to}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching status overview', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
