import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import Select from 'react-select';
import api from 'helpers/api';
import { pickerStyles } from '../colors';

class LocationPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocation: props.location,
      options: [],
    };
  }

  componentDidMount() {
    const { companyId } = this.props;
    api.get('/api/v1/companies/')
      .then((json) => this.setState({
        options: get(json, 'data.results'),
      }))
      .then(() => {
        this.setState((prevState) => {
          // eslint-disable-next-line eqeqeq
          const locations = prevState.options.filter((el) => el.id == companyId);
          const locationsArray = (locations && locations[0] && locations[0].locations) ? locations[0].locations : [];
          return { locations: locationsArray };
        });
      });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location !== location) {
      // eslint-disable-next-line
      this.setState({ selectedLocation: location });
    }
  }

  handleChange = (location) => {
    const { handleChange } = this.props;
    this.setState({ selectedLocation: location });
    handleChange(location);
  }

  render() {
    const { selectedLocation, locations } = this.state;
    // eslint-disable-next-line no-unused-vars
    const { disabled, htmlId, onBlur, customClassName, t } = this.props;

    return (
      <Select
        cacheOptions
        isDisabled={disabled}
        id={htmlId}
        options={locations}
        getOptionLabel={(option) => `${option.name}`}
        getOptionValue={(option) => option.id}
        onBlur={onBlur}
        onChange={this.handleChange}
        placeholder={t('shared.location_picker.placeholder')}
        value={selectedLocation ? (locations === undefined ? [] : locations).find((option) => option.id === parseInt(selectedLocation, 10)) : null}
        className="LocationPicker"
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        styles={pickerStyles}
      />
    );
  }
}

LocationPicker.propTypes = {
  location: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  htmlId: PropTypes.string,
  onBlur: PropTypes.func,
  customClassName: PropTypes.string,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

LocationPicker.defaultProps = {
  disabled: false,
  customClassName: '',
};

export default withTranslation()(LocationPicker);
