import { combineReducers } from 'redux';
import navigation from './navigation/reducer';
import user from './user/reducer';
import app from './app/reducer';

import industryContainer from './industry/IndustryLocationContainer/reducer';
import industryDashboardContainer from './industry/containers/IndustryLocationDashboard/reducer';

export default combineReducers({
  navigation,
  entities: () => [],
  currentUser: user,
  app,
  industry: combineReducers({
    container: industryContainer,
    dashboard: industryDashboardContainer,
  }),

});
