import { applyMiddleware, compose, createStore } from 'redux';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import logger from 'redux-logger';
import ReactDOM from 'react-dom';
import React, { Suspense } from 'react';
import { ContentLoader } from 'shared/index';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/browser';
import { getDsn } from './sentry';
import appStore from './rootReducer';
import './css/openSans.css';
import './index.scss';
import './css/rappid.css';
import './css/style.css';
import './css/style.dark.css';
import './css/style.material.css';
import './css/style.modern.css';
import translationEN from './languages/locales/en/translation.json';
import translationDE from './languages/locales/de/translation.json';
import translationHR from './languages/locales/hr/translation.json';
import translationSL from './languages/locales/sl/translation.json';
import App from './App';

let middleware = [thunk];

if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware];
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  appStore,
  composeEnhancers(applyMiddleware(...middleware)),
);

Sentry.init({
  dsn: getDsn(),
  blacklistUrls: [
    /localhost/i,
    /127.0.0.1/,
  ],
});

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  hr: {
    translation: translationHR,
  },
  sl: {
    translation: translationSL,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['en', 'hr', 'de', 'sl'],
    resources,
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'sessionStorage'],
    },
  });

ReactDOM.render(
  <Suspense fallback={<ContentLoader />}>
    <App
      store={store}
    />
  </Suspense>,
  document.getElementById('root'),
);
