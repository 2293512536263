export default {
  layouts: {
    lg: [],
    md: [],
    s: [],
  },
  widgets: [],
  isLoading: false,
  isAddWidgetVisible: false,
};
