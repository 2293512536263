import React from 'react';
import Base from '../Base';

export default (props) => (<Base viewBox="0 0 90 30" {...props}>
    <path fill="#555" d="M37.5 7.5h15v15h-15z" />
    <path fill="#555" d="M27.5 2.5h5v25h-5z" />
    <path fill="#555" d="M7.5 7.5h15v15h-15z" />
    <path fill="#555" d="M57.5 2.5h5v25h-5z" />
    <path fill="#555" d="M67.5 7.5h15v15h-15z" />
</Base>);
