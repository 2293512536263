import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import api from 'helpers/api';
import { withTranslation } from 'react-i18next';
import {
  Table,
  Button,
  Notification,
  TableButtons,
  ConfirmationModal,
} from 'shared';
import { defaultDateTimeFormat } from 'shared/constants';
import { deleteReport } from '../../actions';
import { ReportForm, ReportMetricsList } from '../../components';
import '../../styles.scss';

class ReportsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportId: null,
      reportIdMetric: null,
      reportIdName: null,
      reports: [],
      factors: [],
      isLoading: false,
      next: null,
      previous: null,
      count: 0,
      selectedAscDesc: 'asc',
      selectedSort: 'name',
      deleteData: null,
      showConfirmationDialog: false,
    };
  }

  componentDidMount() {
    this.getReports(true);
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.getReports();
    });
  }

  getReports = (shouldReload) => {
    const { companyId, locationId } = this.props;
    const { selectedAscDesc, selectedSort } = this.state;

    if (shouldReload) {
      this.setState({ isLoading: true });
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const sortColumn = `&order_by=${asc}${selectedSort}`;

    api
      .get(`/api/v1/reports/reports/?company=${companyId}&location=${locationId}${sortColumn}`)
      .then(({ data }) => {
        this.setState({
          reports: ((data.results) ? data.results : []),
          next: data.next,
          previous: data.previous,
          count: data.count,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    api
      .get(`/api/v1/reports/factors/?company=${companyId}`)
      .then(({ data }) => {
        this.setState({
          factors: ((data.results) ? data.results : []),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleEdit = (row) => {
    this.setState({
      reportId: row.original.id,
    });
  }

  closeEdit = (shouldReload) => {
    this.setState({
      reportId: null,
    }, () => {
      if (shouldReload) {
        this.getReports(false);
      }
    });
  }

  handleShowConfirmationModal = (row, e) => {
    e.stopPropagation();
    this.setState({ deleteData: row, showConfirmationDialog: true });
  }

  handleDelete = () => {
    const {
      companyId,
      t,
    } = this.props;
    const id = this.state.deleteData.original.id;
    deleteReport(id, companyId)
      .then(() => {
        this.getReports(false);
        return Notification('success', 'Delete successful', t('page_content.automatic_reports.reports.success_delete'));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => this.setState({ showConfirmationDialog: false }));
  }

  handleMetric = (row) => {
    this.setState({
      reportIdMetric: row.original.id,
      reportIdName: row.original.name,
    });
  }

  closeMetricEdit = () => {
    this.setState({
      reportIdMetric: null,
      reportIdName: null,
    });
  }

  fetchData = (url) => {
    this.setState({ isLoading: true });
    api
      .get(url)
      .then(({ data }) => {
        this.setState({
          reports: ((data.results) ? data.results : []),
          next: data.next,
          previous: data.previous,
          count: data.count,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      isLoading,
      reports,
      reportId,
      reportIdMetric,
      reportIdName,
      factors,
      next,
      previous,
      count,
    } = this.state;

    const {
      companyId,
      t,
      locationId,
    } = this.props;

    return (
      <div className="tab_container">
        {/* <div className="create_button_wrapper">
          <Button onClick={this.handleAdd}>{t('page_content.automatic_reports.reports.new_report')}</Button>
        </div> */}

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.automatic_reports.name')}</span>,
              accessor: 'name',
            },
            {
              Header: () => <span>{t('page_content.automatic_reports.time_range')}</span>,
              accessor: 'time_range',
              Cell: (row) => (<Fragment>
                {row.original.time_range === 'custom_range'
                  ? `${moment(row.original.time_from).format(defaultDateTimeFormat)} - ${moment(row.original.time_to).format(defaultDateTimeFormat)}`
                  : t(`page_content.automatic_reports.time_ranges.${row.value}`)}
              </Fragment>),
            },
            {
              Header: () => <span>{t('page_content.automatic_reports.active')}</span>,
              accessor: 'is_active',
              Cell: (row) => (<Fragment>
                {row.value
                  ? t('page_content.automatic_reports.report_active')
                  : t('page_content.automatic_reports.report_inactive')}
              </Fragment>),
            },
            {
              Header: () => <span>{t('page_content.automatic_reports.report_metrics.title')}</span>,
              accessor: 'report_type',
              Cell: (row) => (
                row.value && row.value !== 'custom' ?
                  <div className="custom_table_row">
                    <Button onClick={() => this.handleMetric(row)}>{t('page_content.automatic_reports.report_metrics.button_title')}</Button>
                  </div> : '-'),
            },
            {
              Header: () => <span>{t('page_content.automatic_reports.actions')}</span>,
              Cell: (row) => (
                row && row.original && row.original.report_type && row.original.report_type !== 'custom' ?
                  <div className="custom_table_row">
                    <Button onClick={() => this.handleEdit(row)}>{t('page_content.automatic_reports.edit')}</Button>
                    <Button type="delete" onClick={(e) => this.handleShowConfirmationModal(row, e)}>{t('page_content.automatic_reports.delete')}</Button>
                  </div> : '-'),
              sortable: false,
            },
          ]}
          data={(reports) || []}
          defaultPageSize={100}
          minRows={0}
          noDataText=" "
          showPagination={false}
          loading={isLoading}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
        />
        <div style={{ float: 'right' }}>
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchData} count={count} />
        </div>

        <ConfirmationModal
          itemName={this.state.deleteData?.original?.name}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDelete}
          type="warning"
        />

        {reportId !== null &&
          <ReportForm
            handleClose={this.closeEdit}
            companyId={companyId}
            reportId={reportId}
            factors={factors}
            locationId={locationId}
          />}

        {reportIdMetric !== null &&
          <ReportMetricsList
            handleClose={this.closeMetricEdit}
            companyId={companyId}
            reportId={reportIdMetric}
            reportName={reportIdName}
          />}
      </div>
    );
  }
}

ReportsList.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default (withTranslation()(ReportsList));
