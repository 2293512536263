import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Select from 'react-select';
import { fetchInterfaceTypes } from './actions';

class InterfaceTypePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interfaceType: props.interfaceType,
      options: [],
    };
  }

  componentDidMount() {
    const { accountType, companyId } = this.props;
    return fetchInterfaceTypes(accountType, companyId)
      .then((res) => this.setState({
        options: get(res, 'data.results'),
      }))
      .catch((err) => {
        console.error(err);
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.interfaceType !== this.props.interfaceType) {
      // eslint-disable-next-line
      this.setState({ interfaceType: this.props.interfaceType });
    }
  }

  handleChange = (interfaceType) => {
    const { handleChange } = this.props;
    this.setState(
      () => ({ interfaceType: interfaceType.id }),
      () => {
        handleChange(interfaceType.id);
      },
    );
  }

  render() {
    const {
      disabled,
      htmlId,
      customClassName,
    } = this.props;
    const { interfaceType, options } = this.state;

    return (
      <Select
        cacheOptions
        className={`AscaliaSelect InterfaceTypePicker ${customClassName}`}
        isDisabled={disabled}
        id={htmlId}
        onChange={this.handleChange}
        getOptionLabel={(option) => option.description}
        getOptionValue={(option) => option.id}
        options={options}
        placeholder="Select type..."
        value={(options === undefined ? [] : options).find((a) => a.id === interfaceType)}
      />
    );
  }
}

InterfaceTypePicker.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  htmlId: PropTypes.string,
  interfaceType: PropTypes.string,
  accountType: PropTypes.string,
  customClassName: PropTypes.string,
  companyId: PropTypes.number.isRequired,
};

InterfaceTypePicker.defaultProps = {
  disabled: false,
  customClassName: '',
};

const mapStateToProps = (state) => ({
  accountType: get(state, 'app.company.account_type'),
});

export default connect(mapStateToProps)(InterfaceTypePicker);
