import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props}>
  <g>
    <path
      d="M38.7,37.5c1.7,1.8,4.8,5.6,6.4,9.6c0.1,0.1,0.1,0.3,0.1,0.4l0.1-0.4c1.6-4,4.7-7.7,6.4-9.6l1.1-1.2c1.8-2,2.9-4.6,2.9-7.4
    c0-5.9-4.6-10.7-10.4-11.1c0,0-0.1,0-0.1,0l-0.1,0c-5.8,0.4-10.4,5.2-10.4,11.1c0,2.9,1.1,5.5,2.9,7.4L38.7,37.5z M45.1,24.4
    c2.8,0,5,2.3,5,5s-2.3,5-5,5c-2.8,0-5-2.3-5-5S42.4,24.4,45.1,24.4z M64.9,39.2L64.9,39.2v19.4l12.7-1.4c0,0,0.1,0,0.2,0V39.2H64.9
    L64.9,39.2z M33.4,39.2L33.4,39.2H21.1v24.3l12.3-1.4V39.2z M46.7,47.6l-1.6,4.4l-1.2-4.2c0-0.1,0-0.1-0.1-0.2l0-0.1
    c-1.3-3.3-3.8-6.5-5.5-8.4h-2v22.5L62,58.9V39.2h-9.7C50.6,41.1,48.1,44.4,46.7,47.6z M21.1,66.4v14.8h21.8V64l-21.6,2.4
    C21.2,66.4,21.2,66.4,21.1,66.4z M45.8,81.2h31.9V60.1l-31.9,3.6V81.2z"
    />
  </g>
</Base>);
