import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';
import CompanyConfig from './components/CompanyConfig';
import LocationConfig from './components/LocationConfig';
import './styles.scss';

const ConfigTab = ({ companyId, locationId }) => {
  const [isReadOnly, setIsReadOnly] = useState(false);

  const getAccess = async () => {
    try {
      const access = await checkAccessOnPage(companyId);

      if (access === 0) {
        redirectToSettingsPage(companyId, locationId);
      } else if (access === 1) {
        // read only
        setIsReadOnly(true);
      }
    } catch (error) {
      console.error('Error checking access:', error);
    }
  };

  useEffect(() => {
    getAccess();
  }, []);

  return (
    <div className="config-tab-container tabs">
      <Tabs>
        <TabList>
          <Tab>Company config</Tab>
          <Tab>Location config</Tab>
        </TabList>
        <TabPanel>
          <CompanyConfig companyId={companyId} isReadOnly={isReadOnly} />
        </TabPanel>
        <TabPanel>
          <LocationConfig locationId={locationId} isReadOnly={isReadOnly} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

ConfigTab.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  //   t: PropTypes.func.isRequired,
};

export default withTranslation()(ConfigTab);
