import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/min/locales';
import Tooltip from 'rc-tooltip';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import Timeline, { TimelineMarkers, CursorMarker, TimelineHeaders, DateHeader, CustomMarker } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';

import { ContentLoader } from 'shared';
import { defaultHeaderFormats } from 'shared/helpers';
import { ordersCreatedAndUpdated } from 'shared/constants';
import { ordersTransformer, groupsTransformer } from '../helpers';
import { getGanttOrders } from '../../../actions';
import '../styles.scss';

const visibleStart = moment().subtract(0.5, 'weeks');
const visibleEnd = moment().add(0.5, 'weeks').endOf('week').isoWeekday(7);

const OrdersGantt = ({ i18n, companyId, projectId }) => {
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [openGroups, setOpenGroups] = useState({});
  const [orders, setOrders] = useState();

  const getOrders = () => {
    setLoading(true);

    let filters = '';

    if (projectId) {
      filters += `&project_id=${projectId}`;
    }

    getGanttOrders(companyId, filters)
      .then((res) => {
        setGroups(get(res, 'data', []) ? groupsTransformer(get(res, 'data', [])) : []);
        setOrders(get(res, 'data', []) ? ordersTransformer(get(res, 'data', [])) : []);
        setLoading(false);
      });
  };

  const getChildren = (parent_id, treeLevel) => {
    let filters = '';

    if (parent_id) {
      filters += `&parent_id=${parent_id}`;
    }

    if (projectId) {
      filters += `&project_id=${projectId}`;
    }

    getGanttOrders(companyId, filters)
      .then((res) => {
        const parentIndex = groups.findIndex((group) => group.id === parent_id);

        const newGroups = get(res, 'data', []) ? groupsTransformer(get(res, 'data', []), treeLevel, parent_id) : [];
        const newOrders = get(res, 'data', []) ? ordersTransformer(get(res, 'data', []), treeLevel, parent_id) : [];

        setGroups((prevGroups) => [
          ...prevGroups.slice(0, parentIndex + 1),
          ...newGroups,
          ...prevGroups.slice(parentIndex + 1),
        ]);

        setOrders((prevOrders) => [
          ...prevOrders.slice(0, parentIndex + 1),
          ...newOrders,
          ...prevOrders.slice(parentIndex + 1),
        ]);
      });
  };

  useEffect(() => {
    getOrders();
  }, []);

  // eslint-disable-next-line react/prop-types
  const itemRenderer = ({ item, itemContext, getItemProps }) => {
    return (
      <Tooltip
        id={(item.id).toString()}
        trigger={['hover']}
        placement="top"
        overlay={
          <div className="reacttooltip">
            <span><label>Order: </label>{get(item, 'id', '')}</span><br />
            <span><label>Order number: </label>{get(item, 'external_id', '')}</span><br />
            <span><label>Status: </label>{get(item, 'status', '')}</span><br />
            <span><label>Items total: </label>{get(item, 'items_total', '')}</span><br />
            <span><label>Items done: </label>{get(item, 'items_done', '')}</span><br />
            <span><label>Start: </label>{item.start_time ? moment(item.start_time).format(ordersCreatedAndUpdated) : ''}</span><br />
            <span><label>Stop: </label>{item.end_time ? moment(item.end_time).format(ordersCreatedAndUpdated) : ''}</span><br />
          </div>
        }
      >
        <div {...getItemProps({ style: { zIndex: '999 !important' } })}>
          <div
            style={{
              height: itemContext.dimensions.height,
              overflow: 'hidden',
              paddingLeft: 3,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              position: 'relative',
            }}
          >
            <span>{get(item, 'status', '')} || {get(item, 'id', '')} || {get(item, 'external_id', '')}</span>
          </div>
        </div>
      </Tooltip>
    );
  };

  const labelFormater = (
    // eslint-disable-next-line no-unused-vars
    [timeStart, timeEnd],
    unit,
    labelWidth,
    formatOptions = defaultHeaderFormats,
  ) => {
    moment.locale(i18n.language);
    let format;
    if (labelWidth >= 150) {
      format = formatOptions[unit].long;
    } else if (labelWidth >= 100) {
      format = formatOptions[unit].mediumLong;
    } else if (labelWidth >= 50) {
      format = formatOptions[unit].medium;
    } else {
      format = formatOptions[unit].short;
    }

    return timeStart.format(format);
  };

  const toggleGroup = (id, treeLevel) => {
    if (openGroups[parseInt(id, 10)]) {
      // get index of selected group
      const selectedIndex = groups.findIndex((group) => parseInt(group.id, 10) === parseInt(id, 10));
      let groupsToDelete = [];

      // get all groups from selected group to first group with treeLevel === 1
      for (let i = selectedIndex + 1; i < groups.length; i++) {
        if (groups[i].treeLevel === 1) { break; }
        groupsToDelete.push(groups[i]);
      }
      groupsToDelete = groupsToDelete.map((group) => group.id);

      // remove groups from array that have id from groupsToDelete
      const updatedGroups = groups.filter((group) => !groupsToDelete.includes(group.id));
      setGroups(updatedGroups);

      // remove orders from array that have id from groupsToDelete
      const updatedOrders = orders.filter((order) => !groupsToDelete.includes(order.id));
      setOrders(updatedOrders);

      // Remove keys from selected id to the end of the list
      const updatedOpenGroups = { ...openGroups };
      for (const key in updatedOpenGroups) {
        if (parseInt(key, 10) >= parseInt(id, 10)) {
          delete updatedOpenGroups[key];
        }
      }

      setOpenGroups(updatedOpenGroups);
    } else {
      setOpenGroups((prevOpenGroups) => ({ ...prevOpenGroups, [id]: true }));
      getChildren(id, treeLevel);
    }
  };

  const getTreeStyledGroups = (data) => {
    return data
      .map((group) => {
        return {
          ...group,
          title: group.has_children ? (
            <div onClick={() => toggleGroup(parseInt(group.id, 10), group.treeLevel + 1)} style={{ cursor: 'pointer', paddingLeft: `${group.treeLevel * 10}px` }}>
              {openGroups[parseInt(group.id, 10)] ? '[-]' : '[+]'} {group.title}
            </div>
          ) : (
            <div style={{ paddingLeft: `${group.treeLevel * 10}px` }}>{group.title}</div>
          ),
        };
      });
  };

  if (loading) {
    return <ContentLoader />;
  }

  return (
    <div className="orders__gantt">
      <Timeline
        groups={getTreeStyledGroups(groups) || []}
        items={orders || []}
        horizontalLineClassNamesForGroup={(group) => (group && group.treeLevel ? [`treeLevel__${group.treeLevel}`] : '')}
        defaultTimeStart={visibleStart}
        defaultTimeEnd={visibleEnd}
        itemRenderer={itemRenderer}
        stackItems
        fullUpdate={false}
        lineHeight={45}
        sidebarWidth={220}
        itemHeightRatio={0.90}
        canResize={false}
      >
        <TimelineMarkers>
          <CustomMarker date={moment().valueOf()}>
            {({ styles }) => <div style={{ ...styles, backgroundColor: 'red', width: '2px' }} />}
          </CustomMarker>
          <CursorMarker>
            {({ styles }) => <div style={{ ...styles, backgroundColor: 'gray' }} />}
          </CursorMarker>
        </TimelineMarkers>
        <TimelineHeaders className="sticky">
          <DateHeader unit="primaryHeader" />
          <DateHeader labelFormat={labelFormater} />
        </TimelineHeaders>
      </Timeline>
    </div>

  );
};

OrdersGantt.propTypes = {
  projectId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  //   t: PropTypes.func.isRequired,
  i18n: PropTypes.object,
};

export default withTranslation()(OrdersGantt);
