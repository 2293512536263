import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import moment from 'moment';
import { get } from 'lodash';
import { AssetTypePicker, Notification, Button } from 'shared';
import Select from 'react-select';
import { selectStyles } from 'styles/modules/reactSelect';
import leftChevron from 'shared/Icons/left-chevron.png';
import rightChevron from 'shared/Icons/right-chevron.png';
import Calendar from '@toast-ui/react-calendar';
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import { getAssets, getTimetable, saveShiftTime, editShiftTime, deleteShiftTime, deleteDefaultShiftTime } from '../actions';
import './style.scss';
import TimetableShiftForm from './components/TimetableShiftForm';

class TimetableTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableAssets: [],
      asset: null,
      asset_type: null,
      showTimetableShiftForm: false,
      shiftTime: null,
      loadingShiftTimeForm: false,
      schedules: [],
    };
    this.calendarRef = React.createRef();
    this.timerRef = React.createRef();
  }

  componentDidMount() {
    const { locationId, companyId } = this.props;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    getAssets(companyId, locationId)
      .then((re) => {
        const assets = get(re, 'data.results');
        const selectedAsset = (assets === undefined) ? null : (assets.length !== 0) ? assets[0] : null;
        this.setState({
          availableAssets: get(re, 'data.results'),
          asset: selectedAsset,
        });
      }).catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });

    const getTimetableWithTimeout = () => {
      setTimeout(() => {
        if (locationId) {
          this.getCurrentWeekTimetable();
        } else {
          getTimetableWithTimeout();
        }
      }, 300);
    };
    getTimetableWithTimeout();
  }

  onCalendarClick = (event) => {
    const { isReadOnly } = this.state;
    if (!isReadOnly) {
      const dateAndTime = {
        start: event.start,
        end: event.end,
      };
      this.showTimetableShiftForm(true, null, dateAndTime);
    }
  }

  onClickSchedule = (shiftTime) => {
    const { isReadOnly, schedules } = this.state;
    if (!isReadOnly) {
      const event = schedules.find((obj) => obj.idx === shiftTime.schedule.id);
      const dateAndTime = {
        start: shiftTime.schedule.start,
        end: shiftTime.schedule.end,
        shiftName: shiftTime.schedule.title,
        shiftTimeId: window.shiftTimeIds[shiftTime.schedule.id],
        isDefault: event && event.is_default ? event.is_default : false,
      };
      this.showTimetableShiftForm(true, shiftTime, dateAndTime);
    }
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    }, () => {
      this.handleClickTodayButton();
    });
  }

  getCurrentWeekTimetable = () => {
    const { locationId } = this.props;

    const {
      asset,
      asset_type,
    } = this.state;

    const firstDayOfWeek = moment().day(1);
    const lastDayOfWeek = moment().day(7);
    let timetableFilter = `&date__gte=${firstDayOfWeek.format('YYYY-MM-DD')}&date__lte=${lastDayOfWeek.format('YYYY-MM-DD')}`;
    if (asset && asset.id) {
      timetableFilter += `&asset=${asset.id}`;
    }
    if (asset_type) {
      timetableFilter += `&asset_type=${asset_type}`;
    }
    if (asset !== null) {
      getTimetable(locationId, timetableFilter)
        .then((re) => {
          const formattedSchedules = (re && re.data) ? re.data : [];
          window.shiftTimeIds = {};
          formattedSchedules.forEach((s) => {
            s.id = s.idx;
            window.shiftTimeIds[s.id] = s.shifttime_id;
            s.caledarId = '1';
            s.category = 'time';
            s.isVisible = true;
            s.dueDateClass = '';
            s.bgColor = '#364252';
            s.color = '#fff';
            s.end = moment(s.end).format('YYYY-MM-DD[T]HH:mm:ss');
            s.start = moment(s.start).format('YYYY-MM-DD[T]HH:mm:ss');
            // ---------------------------
            if (s.is_additional_event) {
              s.bgColor = '#1074AC';
            } else if (!s.is_shift) {
              s.bgColor = '#10AC84';
            }
          });
          this.setState({
            schedules: formattedSchedules,
            lastFirstDay: firstDayOfWeek,
          });
        }).catch((e) => console.log(e));
    }
  }

  getSelectedWeek = () => {
    const {
      lastFirstDay,
      asset,
      asset_type,
    } = this.state;

    const {
      locationId,
    } = this.props;

    let firstDayOfWeek;
    let lastDayOfWeek;

    if (lastFirstDay) {
      firstDayOfWeek = moment(lastFirstDay);
      lastDayOfWeek = moment(firstDayOfWeek).add(6, 'days');
    }

    if (!lastFirstDay) {
      firstDayOfWeek = moment().day(1);
      lastDayOfWeek = moment().day(7);
    }

    let timetableFilter = `&date__gte=${firstDayOfWeek.format('YYYY-MM-DD')}&date__lte=${lastDayOfWeek.format('YYYY-MM-DD')}`;
    if (asset && asset.id) {
      timetableFilter += `&asset=${asset.id}`;
    }
    if (asset_type) {
      timetableFilter += `&asset_type=${asset_type}`;
    }
    getTimetable(locationId, timetableFilter)
      .then((re) => {
        const formattedSchedules = re.data;
        formattedSchedules.forEach((s) => {
          s.id = s.idx;
          window.shiftTimeIds[s.id] = s.shifttime_id;
          s.caledarId = '1';
          s.category = 'time';
          s.isVisible = true;
          s.dueDateClass = '';
          s.bgColor = '#364252';
          s.color = '#fff';
          s.end = moment(s.end).format('YYYY-MM-DD[T]HH:mm:ss');
          s.start = moment(s.start).format('YYYY-MM-DD[T]HH:mm:ss');
          // ---------------------------
          if (s.is_additional_event) {
            s.bgColor = '#1074AC';
          } else if (!s.is_shift) {
            s.bgColor = '#10AC84';
          }
        });
        this.setState({
          schedules: formattedSchedules,
          lastFirstDay: firstDayOfWeek,
        });
      }).catch((e) => console.log(e));
  }

  showTimetableShiftForm = (showTimetableShiftForm, shiftTime = null, dateAndTime = null) => {
    this.setState({
      shiftTime,
      dateAndTime,
    }, () => {
      this.setState({
        showTimetableShiftForm,
      });
    });
  }

  handleClickPreviousButton = () => {
    const {
      lastFirstDay,
      asset,
      asset_type,
    } = this.state;

    const {
      locationId,
    } = this.props;

    const firstDayOfWeek = moment(lastFirstDay).subtract(7, 'days');
    const lastDayOfWeek = moment(firstDayOfWeek).add(6, 'days');
    let timetableFilter = `&date__gte=${firstDayOfWeek.format('YYYY-MM-DD')}&date__lte=${lastDayOfWeek.format('YYYY-MM-DD')}`;
    if (asset && asset.id) {
      timetableFilter += `&asset=${asset.id}`;
    }
    if (asset_type) {
      timetableFilter += `&asset_type=${asset_type}`;
    }
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      getTimetable(locationId, timetableFilter)
        .then((re) => {
          const formattedSchedules = re.data;
          formattedSchedules.forEach((s) => {
            s.id = s.idx;
            window.shiftTimeIds[s.id] = s.shifttime_id;
            s.caledarId = '1';
            s.category = 'time';
            s.isVisible = true;
            s.dueDateClass = '';
            s.bgColor = '#364252';
            s.color = '#fff';
            s.end = moment(s.end).format('YYYY-MM-DD[T]HH:mm:ss');
            s.start = moment(s.start).format('YYYY-MM-DD[T]HH:mm:ss');
            // ---------------------------
            if (s.is_additional_event) {
              s.bgColor = '#1074AC';
            } else if (!s.is_shift) {
              s.bgColor = '#10AC84';
            }
          });
          this.setState({
            schedules: formattedSchedules,
          });
        }).catch((e) => console.log(e));
    }, 300);
    this.setState({
      lastFirstDay: firstDayOfWeek,
    });
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.prev();
  }

  handleClickNextButton = () => {
    const {
      lastFirstDay,
      asset,
      asset_type,
    } = this.state;

    const {
      locationId,
    } = this.props;
    const firstDayOfWeek = moment(lastFirstDay).add(7, 'days');
    const lastDayOfWeek = moment(firstDayOfWeek).add(6, 'days');
    let timetableFilter = `&date__gte=${firstDayOfWeek.format('YYYY-MM-DD')}&date__lte=${lastDayOfWeek.format('YYYY-MM-DD')}`;
    if (asset && asset.id) {
      timetableFilter += `&asset=${asset.id}`;
    }
    if (asset_type) {
      timetableFilter += `&asset_type=${asset_type}`;
    }
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      getTimetable(locationId, timetableFilter)
        .then((re) => {
          const formattedSchedules = re.data;
          formattedSchedules.forEach((s) => {
            s.id = s.idx;
            window.shiftTimeIds[s.id] = s.shifttime_id;
            s.caledarId = '1';
            s.category = 'time';
            s.isVisible = true;
            s.dueDateClass = '';
            s.bgColor = '#364252';
            s.color = '#fff';
            s.end = moment(s.end).format('YYYY-MM-DD[T]HH:mm:ss');
            s.start = moment(s.start).format('YYYY-MM-DD[T]HH:mm:ss');
            // ---------------------------
            if (s.is_additional_event) {
              s.bgColor = '#1074AC';
            } else if (!s.is_shift) {
              s.bgColor = '#10AC84';
            }
          });
          this.setState({
            schedules: formattedSchedules,
          });
        }).catch((e) => console.log(e));
    }, 300);
    this.setState({
      lastFirstDay: firstDayOfWeek,
    });
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.next();
  }

  resetTimetableForm = (e) => {
    const { availableAssets } = this.state;
    e.preventDefault();

    const selectedAsset = (availableAssets === undefined) ? null : (availableAssets.length !== 0) ? availableAssets[0] : null;
    this.setState({
      asset: selectedAsset,
      asset_type: null,
    });

    setTimeout(this.handleClickTodayButton, 100);
  }

  handleClickTodayButton = () => {
    this.getCurrentWeekTimetable();
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.today();
  }

  saveShiftTime = (id, shiftTime, locationId, isEdit, isDefault) => {
    this.setState({
      loadingShiftTimeForm: true,
    });

    if (isEdit && !isDefault) {
      editShiftTime(id, shiftTime, locationId)
        .then(() => {
          this.setState({
            loadingShiftTimeForm: false,
            showTimetableShiftForm: false,
          });
          this.getSelectedWeek();
        })
        .catch((err) => console.log(err));
    } else if (isEdit && isDefault) {
      deleteDefaultShiftTime({
        shifttime: id,
        date: shiftTime.date,
        asset: shiftTime.asset,
        change_reason: shiftTime.change_reason,
      }, false)
        .then(() => {
          saveShiftTime(shiftTime, locationId)
            .then(() => {
              this.setState({
                loadingShiftTimeForm: false,
                showTimetableShiftForm: false,
              });
              this.getSelectedWeek();
            });
        });
    } else {
      saveShiftTime(shiftTime, locationId)
        .then(() => {
          this.setState({
            loadingShiftTimeForm: false,
            showTimetableShiftForm: false,
          });
          this.getSelectedWeek();
        })
        .catch((err) => console.log(err));
    }
  }

  deleteShiftTime = (id, locationId, data, isDefault) => {
    this.setState({
      loadingShiftTimeForm: true,
    });

    if (isDefault === true) {
      deleteDefaultShiftTime(data)
        .then(() => {
          this.setState({
            loadingShiftTimeForm: false,
            showTimetableShiftForm: false,
          });
          this.getSelectedWeek();
        });
    } else if (isDefault === false) {
      deleteShiftTime(id, locationId)
        .then(() => {
          this.setState({
            loadingShiftTimeForm: false,
            showTimetableShiftForm: false,
          });
          this.getSelectedWeek();
        });
    } else {
      this.setState({
        loadingShiftTimeForm: false,
      });
    }
  }

  render() {
    const { companyId, locationId, t, shifts } = this.props;
    const {
      availableAssets,
      asset,
      asset_type,
      showTimetableShiftForm,
      shiftTime,
      loadingShiftTimeForm,
      dateAndTime,
      schedules,
      isReadOnly,
    } = this.state;

    const template = {
      timegridDisplayPrimaryTime(time) {
        const hour = time.hour;
        return `${hour} : 00`;
      },
    };

    return (
      <div className="timetable_tab">
        <div className="company-settings__calendar--controls">
          {locationId &&
            <div className="timetable_filters">
              <div className="assetPicker">
                {/* <AssetPicker
                  asset={asset}
                  changeAsset={val => this.onFilterChange('asset', val)}
                  resetMetric={() => { }}
                  locationId={locationId}
                  customStyles={selectStyles}
                  isClearable={false}
                /> */}
                <Select
                  options={availableAssets}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  onChange={(value) => this.onFilterChange('asset', value)}
                  value={(availableAssets.find((a) => a.id === asset.id)) || ''}
                  styles={selectStyles}
                  isDisabled={availableAssets.length === 0}
                  placeholder={t('settings.shifts.no_asset')}
                />
              </div>
              <div className="assetTypePicker">
                <AssetTypePicker
                  asset_type={asset_type || null}
                  handleChange={(val) => this.onFilterChange('asset_type', val && val.id ? val.id : null)}
                  htmlId="type"
                  companyId={companyId}
                  locationId={locationId}
                  customStyles={selectStyles}
                />
              </div>
              <Button onClick={this.handleClickTodayButton}>
                {t('settings.timetable_tab.today_button')}
              </Button>
              <Button onClick={this.handleClickPreviousButton} disabled={(availableAssets && availableAssets.length === 0) || asset === null}>
                <img src={leftChevron} alt="<" style={{ height: '12px', width: '12px', marginBottom: '-2px' }} />
              </Button>
              <Button onClick={this.handleClickNextButton} disabled={(availableAssets && availableAssets.length === 0) || asset === null}>
                <img src={rightChevron} alt=">" style={{ height: '12px', width: '12px', marginBottom: '-2px' }} />
              </Button>
              <Button onClick={this.resetTimetableForm}>
                {t('settings.timetable_tab.reset_button')}
              </Button>
            </div>}
        </div>
        {
          showTimetableShiftForm ?
            <TimetableShiftForm
              isOpen={showTimetableShiftForm}
              closeModal={() => { this.showTimetableShiftForm(false, null); }}
              locationId={locationId || null}
              saveShiftTime={this.saveShiftTime}
              deleteShiftTime={this.deleteShiftTime}
              shiftTime={shiftTime}
              isLoadingState={loadingShiftTimeForm}
              dateAndTime={dateAndTime}
              asset={asset}
              shifts={shifts}
            /> : ''
        }

        <div className="timetable-calendar">
          <Calendar
            ref={this.calendarRef}
            height="1300px"
            isReadOnly={isReadOnly}
            calendars={[
              {
                id: '1',
                name: 'Company',
                bgColor: '#f3f3f3',
                borderColor: '#cccccc',
              },
            ]}
            disableDblClick
            disableClick={false}
            month={{
              startDayOfWeek: 1,
            }}
            week={{
              daynames: t('date_picker_locale.days', { returnObjects: true }),
              startDayOfWeek: 1,
            }}
            schedules={schedules}
            scheduleView={['time']}
            taskView={false}
            // timezones={[
            //   {
            //     timezoneOffset: 60,
            //   },
            // ]}
            useCreationPopup={false}
            useDetailPopup={false}
            defaultView="week"
            onClickSchedule={this.onClickSchedule}
            onBeforeCreateSchedule={this.onCalendarClick}
            template={template}
          />
        </div>
      </div>
    );
  }
}

TimetableTab.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  shifts: PropTypes.array.isRequired,
};

export default withTranslation()(TimetableTab);
