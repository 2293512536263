import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { defaultDateTimeFormat } from 'shared/constants';
import { Table, TableButtons, ContentLoader } from 'shared';
import { selectStyles } from 'styles/modules/reactSelect';

import { getAssets, getLineMaterials, getPaginatedLineMaterials } from '../actions';
import '../style.scss';

const LineMaterials = ({ t, companyId, locationId }) => {
  const [lineMaterials, setLineMaterials] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });

  const [tableData, setTableData] = useState({
    data: [],
    selectedMaterial: null,
  });

  const [lineMaterialsFilters, setLineMaterialsFilters] = useState({
    selectedAsset: null,
  });

  const [assets, setAssets] = useState({
    data: [],
    isLoading: false,
  });

  const handleMaterialSelect = (material) => {
    setTableData((prevState) => ({
      ...prevState,
      data: material?.items || [],
      selectedMaterial: material || null,
    }));
  };

  const fetchLineMaterials = () => {
    setLineMaterials((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const apiFilters = '&limit=30';

    getLineMaterials(lineMaterialsFilters?.selectedAsset?.id, apiFilters)
      .then((res) => {
        setLineMaterials({
          data: get(res, 'data', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
        handleMaterialSelect(res?.data?.materials?.length ? res?.data?.materials[0] : null);
      })
      .catch(() => {
        setLineMaterials((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedLineMaterials = (url) => {
    setLineMaterials((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedLineMaterials(url)
      .then((res) => {
        setLineMaterials({
          data: get(res, 'data', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
        handleMaterialSelect(res?.data?.materials?.length ? res?.data?.materials[0] : null);
      })
      .catch(() => {
        setLineMaterials((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchAssets = () => {
    setAssets((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getAssets(companyId, locationId)
      .then((res) => {
        setAssets({
          data: get(res, 'data.results', []),
          isLoading: false,
        });
        setLineMaterialsFilters((prevState) => ({
          ...prevState,
          selectedAsset: get(res, 'data.results[0]', null),
        }));
      })
      .catch(() => {
        setAssets((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        setLineMaterialsFilters((prevState) => ({
          ...prevState,
          selectedAsset: null,
        }));
      });
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  useEffect(() => {
    if (lineMaterialsFilters?.selectedAsset) {
      fetchLineMaterials();
    }
  }, [lineMaterialsFilters]);

  const onFilterChange = (key, value) => {
    setLineMaterialsFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  if (lineMaterials?.isLoading) {
    return <ContentLoader />;
  }

  console.log(tableData, lineMaterials);

  return (
    <div className="line-materials">
      <div className="line-materials__info-and-filters">
        <div className="line-materials__filters">
          <Select
            options={assets.data}
            getOptionLabel={(line) => line.name}
            getOptionValue={(line) => line.id}
            placeholder="All assets"
            isSearchable
            onChange={(asset) => onFilterChange('selectedAsset', asset)}
            value={lineMaterialsFilters?.selectedAsset || ''}
            styles={selectStyles}
            isLoading={assets?.isLoading}
            loadingMessage={() => <ContentLoader />}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
          {lineMaterials?.data?.order?.external_id && <span>{lineMaterials?.data?.order?.external_id}</span>}
          {lineMaterials?.data?.order?.name && <span style={{ fontWeight: '600' }}>{lineMaterials?.data?.order?.name}</span>}
          </div>
        </div>

        <div className="line-materials__info">
          {
            lineMaterials?.data?.materials?.length && lineMaterials?.data?.materials?.map((material) => (
              <div
                className={tableData?.selectedMaterial?.code === material?.code ? 'line-materials__info__card selected' : 'line-materials__info__card'}
                onClick={() => handleMaterialSelect(material)}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p className="line-materials__info__card__label"><span className="key">{t('page_content.orders.name')}: </span> {material?.name}</p>
                  <p className="line-materials__info__card__label"><span className="key">{t('page_content.orders.total_quantity')}: </span> {material?.total_quantity}</p>
                  <p className="line-materials__info__card__label"><span className="key">{t('page_content.orders.booked')}: </span> {material?.quantity_booked_all}</p>
                  <p className="line-materials__info__card__label"><span className="key">{t('page_content.orders.assigned')}: </span> {material?.quantity_loaded}</p>
                  <hr style={{ width: '100%' }} />
                  <p className="line-materials__info__card__label"><span style={{ fontWeight: '600', fontSize: '15px' }}>{t('page_content.orders.on_machine')}: </span> {material?.quantity_in_machine} {material?.unit}</p>
                  <p className="line-materials__info__card__label"><span style={{ fontWeight: '600', fontSize: '15px' }}>{t('page_content.orders.remaining_for_filling')}: </span> {material?.quantity_remaining} {material?.unit}</p>
                </div>
              </div>
            ))
            }
        </div>
      </div>
      <div className="line-materials__table">
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              accessor: 'ts',
              Cell: (row) => moment(row.value).format(defaultDateTimeFormat),
              sortable: false,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.orders.batch')}</span>,
              accessor: 'batch',
              sortable: false,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.orders.quantity')}</span>,
              accessor: 'quantity',
              sortable: false,
              style: {
                cursor: 'default',
              },
            },
          ]}
          minRows={0}
          noDataText=""
          selectedRow={null}
          defaultPageSize={100}
          showPagination={false}
          data={tableData.data || []}
          loading={lineMaterials.isLoading}
          getTrProps={(state, rowInfo) => {
            if (rowInfo) {
              return {
                style: { backgroundColor: rowInfo?.original?.quantity >= 0 ? 'rgba(40, 167, 69, 0.3)' : 'rgb(239, 195, 195)' },
              };
            }
          }}
        />
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons
              next={lineMaterials.next}
              count={lineMaterials.count}
              previous={lineMaterials.previous}
              fetchFunction={fetchPaginatedLineMaterials}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

LineMaterials.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default LineMaterials;
