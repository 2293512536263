/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import FactoryFloor from 'shared/FactoryFloor';
import { Button } from 'shared';
import {
  checkAccessOnPage,
  redirectToHomePage,
  checkModules,
} from 'industry/helpers';
import {
  getFactoryFloor,
  saveFactoryFloor,
  saveRefreshInterval,
  getAssetGroups,
  getDevices,
  getLastEvents,
  getLastSeenCounts,
} from './actions';
import './styles.scss';

class IndustryFactoryFloor extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: 'devices',
      layouts: [],
      assetGroups: [],
      devices: [],
      selectedLayout: null,
      newFloorName: null,
      showSelection: {
        text: 'Show floor selection',
        hide: true,
      },
      isStaff: false,
      showTools: true,
      pfRefreshInterval: window.pfRefreshInterval,
    };
  }

  componentDidMount() {
    const { companyId, locationId, currentUser } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Plant floor');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    getDevices(companyId, locationId)
      .then((r) => {
        const devices = ((get(r, 'data.results') === undefined) ? [] : get(r, 'data.results'));
        this.setState({
          loading: 'asset groups',
        });
        getAssetGroups(locationId)
          .then((response) => {
            const assetGroups = ((get(response, 'data.results') === undefined) ? [] : get(response, 'data.results'));
            this.setState({
              loading: 'workspaces',
            });
            getFactoryFloor(locationId)
              .then((res) => {
                const layouts = ((get(res, 'data.results') === undefined) ? [] : get(res, 'data.results'));
                this.setState({
                  layouts,
                  assetGroups,
                  devices,
                  loading: false,
                  isStaff: currentUser.is_staff,
                });
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  }

  onRefreshIntervalChange = (e) => {
    window.pfRefreshInterval = e.target.value;
    this.setState({
      pfRefreshInterval: e.target.value,
    });

    const { showTools } = this.state;
    const { currentUser } = this.props;
    if (showTools && currentUser.is_staff) {
      saveRefreshInterval({
        data: {
          pfRefreshInterval: e.target.value,
        },
      });
    }
  }

  setSelectedLayout = (name) => {
    const { layouts } = this.state;
    this.setState({
      selectedLayout: layouts.find((l) => l.name === name),
      showSelection: {
        hide: true,
        text: 'Show floor selection',
      },
    });
  }

  handleSave = (data) => {
    const { selectedLayout, layouts } = this.state;
    const saveData = {};

    saveData.source = data;
    saveData.location_id = selectedLayout.location_id;
    saveData.name = selectedLayout.name;

    if (selectedLayout.id) {
      saveData.id = selectedLayout.id;
    }

    saveFactoryFloor(saveData, selectedLayout.id || null);
    const newLayouts = layouts.filter((l) => !l.notSaved);
    delete selectedLayout.notSaved;
    newLayouts.push(selectedLayout);
    this.setState({
      selectedLayout,
      layouts: newLayouts,
    });
  }

  hideTools = () => {
    this.setState({
      showTools: false,
    });
  }

  changeNewName = (e) => {
    this.setState({
      newFloorName: e.target.value,
    });
  }

  toggleToolsView = () => {
    const { showTools } = this.state;
    this.setState({
      showTools: !showTools,
    });
  };

  createNewFloor = () => {
    const { newFloorName, layouts } = this.state;
    const { locationId } = this.props;
    const newFloor = {
      name: newFloorName,
      location_id: locationId,
      source: null,
      notSaved: true,
    };

    const newLayouts = layouts;
    newLayouts.push(newFloor);
    this.setState({
      layouts: newLayouts,
      selectedLayout: newFloor,
    });
  }

  toggleShowSelection = () => {
    const { showSelection } = this.state;
    const newShowSelection = {};
    newShowSelection.hide = !showSelection.hide;
    newShowSelection.text = newShowSelection.hide ? 'Show floor selection' : 'Hide floor selection';
    this.setState({
      showSelection: newShowSelection,
    });
  }

  render() {
    const { companyId, locationId, currentUser } = this.props;
    const {
      layouts,
      selectedLayout,
      newFloorName,
      showSelection,
      loading,
      devices,
      assetGroups,
      lastEvents,
      // isStaff,
      showTools,
      pfRefreshInterval,
      isReadOnly,
    } = this.state;

    const locationData = {
      companyId,
      locationId,
    };

    if (loading) {
      return (
        <div className="factory-floor">
          <div className="factory-floor--loading">Loading {loading}...</div>
        </div>
      );
    }

    if (!selectedLayout) {
      return (
        <div className="factory-floor">
          <div className="factory-floor__selection-wrapper">
            <table className="factory-floor__selection table">
              <tbody>
                {((layouts === undefined) ? [] : layouts).map((l) => <tr><td onClick={() => this.setSelectedLayout(l.name)}>{l.name} {l.notSaved ? '(unsaved)' : ''}</td></tr>)}
              </tbody>
            </table>
            <input
              placeholder="New floor name"
              onChange={this.changeNewName}
              disabled={isReadOnly}
            /><br />
            <Button
              onClick={this.createNewFloor}
              type="add"
              disabled={!newFloorName || isReadOnly}
            >
              Create new floor
            </Button>
          </div>
        </div>
      );
    }

    const layout = JSON.parse(JSON.stringify(layouts.find((l) => l.name === selectedLayout.name)));
    const selectionWrapperClass = showSelection.hide ? 'factory-floor__selection-wrapper--smaller' : '';

    return (
      <div className="factory-floor">
        <div className={`factory-floor__selection-wrapper ${selectionWrapperClass}`}>
          <Button className="showHideToggle" onClick={this.toggleShowSelection}>{showSelection.text}</Button>
          {
            !showSelection.hide ?
              <div>
                <table className="factory-floor__selection table">
                  <tbody>
                    {layouts.map((l) => <tr><td className={selectedLayout.name === l.name ? 'activeFloor' : ''} onClick={() => this.setSelectedLayout(l.name)}>{l.name} {l.notSaved ? '(unsaved)' : ''}</td></tr>)}
                  </tbody>
                </table>
                <input
                  placeholder="New floor name"
                  onChange={this.changeNewName}
                  disabled={isReadOnly}
                /><br />
                <Button
                  onClick={this.createNewFloor}
                  disabled={!newFloorName || isReadOnly}
                  type="add"
                >
                  Create new floor
                </Button>
              </div> : ''
          }
        </div>
        {currentUser.is_staff ?
          <div className="factory-floor__tools-display-wrapper">
            <Button
              className="showHideToggle"
              onClick={this.toggleToolsView}
              disabled={isReadOnly}
            >
              {showTools ? 'Hide tools' : 'Show tools'}
            </Button>
          </div> : ''}
        {
          !isReadOnly ?
            <div className="factory-floor__refresh-interval-wrapper">
              Refresh interval:
              <select onChange={this.onRefreshIntervalChange} value={pfRefreshInterval}>
                <option value="5">5 s</option>
                <option value="10">10 s</option>
                <option value="15">15 s</option>
                <option value="30">30 s</option>
                <option value="60">60 s</option>
              </select>
            </div> : ''
        }

        <FactoryFloor
          factoryFloorData={{
            layout, devices, assetGroups, lastEvents, locationData, getLastEvents, getLastSeenCounts,
          }}
          setFactoryFloor={this.handleSave}
          isStaff={showTools}
          hideTools={this.hideTools || isReadOnly}
        />
      </div>
    );
  }
}

IndustryFactoryFloor.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps)(IndustryFactoryFloor);
