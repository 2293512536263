import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { get } from 'lodash';
import moment from 'moment';

import { Table, TableButtons } from 'shared';
import { checkModules, styledBookingStatusOptions } from 'industry/helpers';
import { defaultDateTimeFormat } from 'shared/constants';
import GroupsDropdown from 'shared/GroupsDropdown/index';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { selectModalStyles, selectStyles } from 'styles/modules/reactSelect';
import { getAllBookings, getPaginatedAllBookings, getLines } from '../actions';

function Bookings({ companyId, locationId, t, company_short_code, checkForBookingsJoin, enable_book_receipt }) {
  const [modules, setModules] = useState({
    'Order details': false,
    'Order groups': false,
    'Order parts': false,
    'Order production history': false,
    'Order warehouse relocation': false,
    'Order saw data': false,
    'Order operation history': false,
    'Order audit log': false,
    'Order bookings': false,
  });
  const [selectedAscDesc, setSelectedAscDesc] = useState('desc');
  const [selectedSort, setSelectedSort] = useState('booked_at');
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({
    selectedBookingType: '',
    selectedLine: '',
  });
  const [lines, setLines] = useState([]);
  const [tableData, setTableData] = useState({
    isLoading: true,
    data: [],
    count: 0,
    next: null,
    previous: null,
  });

  const fetchBookings = () => {
    let filters = '&expand_order=true';
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    if (selectedFilters?.selectedLine) {
      filters += `&production_line=${selectedFilters.selectedLine}`;
    }
    if (selectedOption?.value?.includes('booking_final')) {
      filters += `&booking_final=${!!selectedOption?.value.includes('true')}`;
    }
    if (selectedOption?.value?.includes('confirmation')) {
      filters += `&booked_confirmation=${!!selectedOption?.value.includes('true')}`;
    }
    if (selectedOption?.value?.includes('goods')) {
      filters += `&booked_goods=${!!selectedOption?.value.includes('true')}`;
    }
    if (selectedFilters?.selectedBookingType) {
      filters += `&booking_type=${selectedFilters.selectedBookingType}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort}`;

    getAllBookings(locationId, filters)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      })
      .catch((e) => {
        console.error('Error while fetching bookings', e);
        setTableData({
          isLoading: false,
          data: [],
          count: 0,
          next: null,
          previous: null,
          currentUrl: null,
        });
      });
  };

  const fetchPaginatedAllBookings = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedAllBookings(url)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      })
      .catch((e) => {
        console.error('Error while fetching paginated bookings', e);
        setTableData({
          isLoading: false,
          data: [],
          count: 0,
          next: null,
          previous: null,
          currentUrl: null,
        });
      });
  };

  const fetchLines = () => {
    getLines(company_short_code, locationId)
      .then((res) => {
        setLines(get(res, 'data.results') || []);
      });
  };

  const checkActiveModules = () => {
    checkModules(companyId).then((re) => {
      const moduleAccess = re.data;
      const updatedModules = { ...modules };
      let countActiveBeforeOrderBookings = 0;

      Object.keys(modules).forEach((key) => {
        const moduleExists = moduleAccess ? moduleAccess.find((m) => m.name === key) : null;
        if (moduleExists) {
          updatedModules[key] = moduleExists.is_active;
          if (key === 'Order bookings') {
            return;
          }
          countActiveBeforeOrderBookings++;
        }
      });

      setModules({ ...updatedModules, countActiveBeforeOrderBookings });
    });
  };

  useEffect(() => {
    checkActiveModules();
    fetchLines();
    fetchBookings();
  }, []);

  useEffect(() => {
    fetchBookings();
  }, [selectedSort, selectedAscDesc, selectedFilters, selectedOption]);

  const handleSorting = (sortData) => {
    let sortKey = sortData.id;
    if (sortKey === 'order.external_id') {
      sortKey = 'order__external_id';
    }
    setSelectedSort(sortKey);
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
  };

  const getUserByID = (user, userFields = ['email']) => {
    if (user !== null && typeof user === 'object') {
      const userFieldsMerged = userFields.map((userField) => {
        if (Object.prototype.hasOwnProperty.call(user, userField)) {
          return user[userField];
        }
        return '';
      });
      return userFieldsMerged.join(' ');
    }
    return '';
  };

  const onFilterChange = (key, value) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleOptionChange = (selected) => {
    setSelectedOption(selected);
  };

  const handleRedirect = (order) => {
    if (order?.order?.id) {
      const url = new URL(window.location);
      const base = url.pathname;
      const redirectLocation = `${base}/${order.order.id}${modules?.countActiveBeforeOrderBookings ? `#${modules.countActiveBeforeOrderBookings}` : ''}`;
      window.location.href = redirectLocation;
    }
  };

  const options = [
    {
      label: t('page_content.orders.order_details.bookings_tab.table_column_booking_final'),
      options: [
        { label: t('page_content.orders.order_details.bookings_tab.dropdown_status_booked_final'), value: 'booking_final_true' },
        { label: t('page_content.orders.order_details.bookings_tab.dropdown_status_unbooked_final'), value: 'booking_final_false' },
      ],
    },
    {
      label: t('page_content.orders.order_details.bookings_tab.table_column_confirmation'),
      options: [
        { label: t('page_content.orders.order_details.bookings_tab.dropdown_status_confirmed'), value: 'confirmation_true' },
        { label: t('page_content.orders.order_details.bookings_tab.dropdown_status_unconfirmed'), value: 'confirmation_false' },
      ],
    },
    {
      label: t('page_content.orders.order_details.bookings_tab.table_column_goods'),
      options: [
        { label: t('page_content.orders.order_details.bookings_tab.dropdown_status_goods'), value: 'goods_true' },
        { label: t('page_content.orders.order_details.bookings_tab.dropdown_status_nogoods'), value: 'goods_false' },
      ],
    },
  ];

  const bookingTypeFilterOptions = [
    { id: 'confirmation', label: t('page_content.orders.order_details.bookings_tab.select_booking_type_confirmation') },
    { id: 'packaging', label: t('page_content.orders.order_details.bookings_tab.select_booking_type_packaging') },
    { id: 'raw', label: t('page_content.orders.order_details.bookings_tab.select_booking_type_raw_material') },
  ];

  return (
    <div className="all_bookings_tab">
        <div className="filters_row">
            <Select
              options={lines}
              getOptionLabel={(line) => line.name}
              getOptionValue={(line) => line.id}
              placeholder={t('page_content.orders.all_lines')}
              isSearchable
              isClearable
              onChange={(e) => onFilterChange('selectedLine', e?.id ?? null)}
              value={(lines.find((line) => line.id === selectedFilters?.selectedLine)) || ''}
              styles={selectStyles}
            />
            <Select
              options={bookingTypeFilterOptions}
              getOptionLabel={(line) => line.label}
              getOptionValue={(line) => line.id}
              placeholder={t('page_content.orders.order_details.bookings_tab.select_booking_type_placeholder')}
              isSearchable
              isClearable
              onChange={(e) => onFilterChange('selectedBookingType', e?.id ?? null)}
              value={(bookingTypeFilterOptions.find((line) => line.id === selectedFilters?.selectedBookingType)) || ''}
              styles={selectStyles}
            />
            <div style={{ width: '225px' }}>
              <GroupsDropdown
                t={t}
                options={options}
                onOptionChange={handleOptionChange}
                styles={selectModalStyles}
              />
            </div>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.merged_bookings')}</span>,
              width: 95,
              accessor: 'merged_booking',
              sortable: false,
              Cell: (row) => (
                <span>
                    {
                        get(row, 'value', false) &&
                            <p>{t('page_content.orders.order_details.bookings_tab.merged_booking')}</p>
                    }
                </span>
              ),
              show: checkForBookingsJoin,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_time')}</span>,
              accessor: 'booked_at',
              Cell: (row) => (get(row, 'value', false) ? moment(row.value).format(defaultDateTimeFormat) : '-'),
            },
            {
              Header: () => <span>{t('page_content.orders.table_column_name')}</span>,
              accessor: 'order.external_id',
              Cell: (row) => (
                <span
                  className="order-list__id"
                  onClick={() => handleRedirect(row?.original)}
                >
                  {get(row, 'value', '-')}
                </span>
              ),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_line')}</span>,
              accessor: 'production_line',
              sortable: false,
              Cell: (row) => (<div>
                <span style={{ display: 'block' }}>{get(row, 'value.name', false) ? row.value.name : '-'}</span>
                <b style={{ display: 'block' }}>{get(row, 'original.is_manual_booking', false) ?
                  t('page_content.orders.order_details.bookings_tab.manual_booking') : null}</b>
                </div>),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.book_receipt')}</span>,
              accessor: 'book_receipt',
              Cell: (row) => (get(row, 'value', false) ?
              <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />),
              show: enable_book_receipt,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_booking_final')}</span>,
              accessor: 'booking_final',
              Cell: (row) => (get(row, 'value', false) ?
                <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_confirmation')}</span>,
              accessor: 'booked_confirmation',
              Cell: (row) => (get(row, 'value', false) ?
                <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_booking_type')}</span>,
              accessor: 'booking_type',
              Cell: (row) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span style={styledBookingStatusOptions(row?.value)}>{row?.value ?
                    t([`page_content.orders.booking_statuses.${row?.value}`]) : t('page_content.orders.booking_statuses.no_status')}</span>
                </div>
              ),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_confirmation_time')}</span>,
              accessor: 'booked_confirmation_at',
              Cell: (row) => (get(row, 'value', false) ? moment(row.value).format(defaultDateTimeFormat) : '-'),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_confirmation_user')}</span>,
              accessor: 'booked_confirmation_user',
              Cell: (row) => <span>{get(row, 'value', false) ? getUserByID(row.value, ['first_name', 'last_name']) : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_goods')}</span>,
              accessor: 'booked_goods',
              Cell: (row) => (get(row, 'value', false) ?
                <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_goods_time')}</span>,
              accessor: 'booked_goods_at',
              Cell: (row) => (get(row, 'value', false) ? moment(row.value).format(defaultDateTimeFormat) : '-'),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.bookings_tab.table_column_goods_user')}</span>,
              accessor: 'booked_goods_user',
              Cell: (row) => <span>{get(row, 'value', false) ? getUserByID(row.value, ['first_name', 'last_name']) : '-'}</span>,
            },
          ]}
          data={tableData.data || []}
          defaultPageSize={30}
          loading={tableData.isLoading}
          minRows={0}
          noDataText=""
          showPagination={false}
          sortable
          isCompact
          stickyHeader
          defaultSorted={[{ id: 'booked_at', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <span style={{ float: 'right' }}>
            <TableButtons previous={tableData.previous} next={tableData.next} fetchFunction={fetchPaginatedAllBookings} count={tableData.count} />
        </span>
    </div>
  );
}

Bookings.propTypes = {
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  checkForBookingsJoin: PropTypes.bool.isRequired,
  company_short_code: PropTypes.string,
  companyId: PropTypes.number.isRequired,
  enable_book_receipt: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    company_short_code: get(state, 'app.companyConfig.short_code', null),
    checkForBookingsJoin: get(state, 'app.locationConfig.config.join_bookings', false),
    enable_book_receipt: get(state, 'app.locationConfig.config.enable_book_receipt', false),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(Bookings));
