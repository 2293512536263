import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IndustryWidget from '../IndustryWidget';
import DashboardList from '../../../../components/DashboardList';
import './styles.scss';

// const sortByFav = (dashboards) => {
//   return dashboards.sort((dashboardA, dashboardB) => {
//     if (dashboardA.is_favorite && !dashboardB.is_favorite) {
//       return -1;
//     } else if (!dashboardA.is_favorite && dashboardB.is_favorite) {
//       return 1;
//     }

//     return 0;
//   });
// };

const DashboardWidget = ({ locationId, companyId, dashboards }) => {
  const { t } = useTranslation();
  const hasFavs = dashboards.filter((d) => d.is_favorite);

  return (
    <Fragment>
      {
        hasFavs && hasFavs.length ?
          <IndustryWidget
            title={t('page_content.home.favorite_dashboards.title')}
          >
            <div className="dashboard-widget dashboard-widget--dashboards">
              <DashboardList locationId={locationId} companyId={companyId} dashboards={dashboards.filter((d) => d.is_favorite)} isFavorite />
            </div>
          </IndustryWidget> : ''
      }
      <IndustryWidget
        title={t('page_content.home.dashboards.title')}
      >
        <div className="dashboard-widget dashboard-widget--dashboards">
          <DashboardList locationId={locationId} companyId={companyId} totalDashboardsCount={dashboards.length} isFavorite={false} dashboards={dashboards.filter((d) => !d.is_favorite)} />
        </div>
      </IndustryWidget>
    </Fragment>
  );
};

DashboardWidget.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dashboards: PropTypes.array.isRequired,
};

export default DashboardWidget;
