import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import Select from 'react-select';
import { connect } from 'react-redux';
import api from 'helpers/api';

class DeviceTypePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceType: props.deviceType,
      options: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const {
      accountType,
      companyId,
    } = this.props;

    api.get(`/api/v1/device_types/?applies_to=${accountType}&order_by=model&company=${companyId}`)
      .then((json) => this.setState({
        options: get(json, 'data.results'),
      }));
  }

  handleChange(val) {
    this.setState({ deviceType: val });
    this.props.onChange(val);
  }

  render() {
    const {
      disabled,
      id,
      customClassName,
      t,
    } = this.props;
    const {
      deviceType,
      options,
    } = this.state;

    return (
      <Select
        id={`DeviceTypePicker-${id}`}
        className={`AscaliaSelect DeviceTypePicker ${customClassName}`}
        isDisabled={disabled}
        getOptionLabel={(option) => `${option.model} [${option.manufacturer || ''}]`}
        getOptionValue={(option) => option.id}
        options={options}
        onChange={this.handleChange}
        placeholder={t('shared.device_type_picker.placeholder')}
        value={(options === undefined ? [] : options).find((a) => a.id === deviceType)}
        cacheOptions
      />
    );
  }
}

DeviceTypePicker.propTypes = {
  disabled: PropTypes.bool,
  companyId: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  deviceType: PropTypes.any,
  accountType: PropTypes.any.isRequired,
  id: PropTypes.number,
  customClassName: PropTypes.string,
  t: PropTypes.func.isRequired,
};

DeviceTypePicker.defaultProps = {
  disabled: false,
  onChange: () => { },
  customClassName: '',
};

const mapStateToProps = (state) => ({
  accountType: get(state, 'app.company.account_type'),
});

export default connect(mapStateToProps)(withTranslation()(DeviceTypePicker));
