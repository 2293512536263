import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { Button, Table, ContentLoader, Notification } from 'shared';
import { defaultDateFormat } from 'shared/constants';
import { getShiftTimes, saveShiftTime, editShiftTime, deleteShiftTime } from '../../actions';
import ShiftTimeForm from './components/ShiftTimeForm';
import '../style.scss';

class ShiftTimes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shiftTimes: [],
      shiftTime: null,
      shiftFilter: '',
      showShiftTimeModal: false,
      loadingShiftTimeForm: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchShiftTimes();
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    }, () => {
      this.fetchShiftTimes();
    });
  }

  fetchShiftTimes = () => {
    const { shiftFilter } = this.state;
    const { locationId } = this.props;

    this.setState({
      isLoading: true,
    });

    let filters = '&is_default_shift=true';

    if (shiftFilter) {
      filters += `&shift=${shiftFilter.id}`;
    }

    getShiftTimes(locationId, filters)
      .then((re) => {
        this.setState({
          shiftTimes: re.data.results,
          isLoading: false,
        });
      })
      .catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  showShiftTimeModal = (showShiftTimeModal, shiftTime) => {
    this.setState({
      shiftTime,
    }, () => {
      this.setState({
        showShiftTimeModal,
      });
    });
  }

  deleteShiftTime = (shiftTime, locationId) => {
    this.setState({
      loadingShiftTimeForm: true,
    });
    deleteShiftTime(shiftTime, locationId)
      .then(() => {
        this.setState({
          loadingShiftTimeForm: false,
          showShiftTimeModal: false,
        });
        this.fetchShiftTimes();
      })
      .catch((err) => console.log(err));
  }

  saveShiftTime = (id, shiftTime, locationId, isEdit) => {
    this.setState({
      loadingShiftTimeForm: true,
    });
    if (isEdit) {
      editShiftTime(id, shiftTime, locationId)
        .then(() => {
          this.setState({
            loadingShiftTimeForm: false,
            showShiftTimeModal: false,
          });
          this.fetchShiftTimes();
        })
        .catch((err) => console.log(err));
    } else {
      saveShiftTime(shiftTime, locationId)
        .then(() => {
          this.setState({
            loadingShiftTimeForm: false,
            showShiftTimeModal: false,
          });
          this.fetchShiftTimes();
        })
        .catch((err) => console.log(err));
    }
  }

  handleClearFilter = () => {
    this.setState({
      shiftFilter: '',
    }, () => {
      this.fetchShiftTimes();
    });
  }

  render() {
    const {
      shiftTimes,
      showShiftTimeModal,
      shiftTime,
      loadingShiftTimeForm,
      shiftFilter,
      isLoading,
    } = this.state;

    const {
      shifts,
      locationId,
      isReadOnly,
      t,
    } = this.props;

    const daysOfWeek = [
      { value: 0, name: t('shared.daysOfWeek.mon') },
      { value: 1, name: t('shared.daysOfWeek.tue') },
      { value: 2, name: t('shared.daysOfWeek.wed') },
      { value: 3, name: t('shared.daysOfWeek.thu') },
      { value: 4, name: t('shared.daysOfWeek.fri') },
      { value: 5, name: t('shared.daysOfWeek.sat') },
      { value: 6, name: t('shared.daysOfWeek.sun') },
      { value: 7, name: t('shared.daysOfWeek.all') }];

    const selectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '200px',
        minHeight: '34px',
        height: '36px',
        padding: 0,
        fontSize: '13px',
        color: '#555',
        marginRight: '5px',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0 10px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: 10,
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: 200,
        borderRadius: 0,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    return (
      <div className="shifts_tab">
        {
          showShiftTimeModal ?
            <ShiftTimeForm
              isOpen={showShiftTimeModal}
              closeModal={() => { this.showShiftTimeModal(false, null); }}
              locationId={locationId}
              saveShiftTime={this.saveShiftTime}
              deleteShiftTime={this.deleteShiftTime}
              shiftTime={shiftTime}
              shifts={shifts}
              isLoadingState={loadingShiftTimeForm}
              daysOfWeek={daysOfWeek}
            /> : ''
        }
        <div className="filters">
        <div className="shifts__filter">
          <Select
            options={shifts}
            getOptionLabel={(shift) => shift.name}
            getOptionValue={(shift) => shift.id}
            isSearchable
            placeholder={t('settings.shifts.select_shift_placeholder')}
            onChange={(e) => this.onFilterChange('shiftFilter', e)}
            value={shiftFilter}
            styles={selectStyles}
          />
           {shiftFilter && <button
             onClick={this.handleClearFilter}
           >&times;</button>}
        </div>
          {
            shifts && shifts.length &&
            <div style={{ marginLeft: 'auto' }}>
            <Button
              type="add"
              onClick={() => this.showShiftTimeModal(true, null)}
              disabled={isReadOnly}
            >
              {t('settings.shifts.add_shift_time_button')}
            </Button>
              </div>
          }
          </div>
        {
          isLoading && <ContentLoader />
        }
        {!isLoading &&
          <Table
            columns={[
              {
                Header: () => <span>{t('settings.shifts.table_column_date')}</span>,
                accessor: 'date',
                Cell: (row) => moment(row.value).format(defaultDateFormat) || 'Default',
              },
              {
                Header: () => <span>{t('settings.shifts.table_column_day_of_week')}</span>,
                accessor: 'day_of_week',
                Cell: (row) => {
                  const selectedDay = row && row.value !== undefined ? daysOfWeek.find((day) => day.value === row.value) : null;
                  return (selectedDay && selectedDay.name ? selectedDay.name : '');
                },
              },
              {
                Header: () => <span>{t('settings.shifts.table_column_start')}</span>,
                accessor: 'shift_begin',
                Cell: (row) => row.value || '-',
              },
              {
                Header: () => <span>{t('settings.shifts.table_column_end')}</span>,
                accessor: 'shift_end',
                Cell: (row) => row.value || '-',
              },
              {
                Header: () => <span>{t('settings.shifts.table_column_shift')}</span>,
                accessor: 'shift.name',
                Cell: (row) => row.value || '-',
              },
            ]}
            data={shiftTimes}
            defaultPageSize={100}
            handleClick={(rowInfo) => { if (rowInfo.original.shift.is_shift) { this.showShiftTimeModal(!isReadOnly, rowInfo.original); } else { this.showShiftTimeBreakModal(!isReadOnly, rowInfo.original); } }}
            minRows={0}
            noDataText=" "
            sortable
            manual={false}
            showPagination={false}
          />}
      </div>
    );
  }
}

ShiftTimes.propTypes = {
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  shifts: PropTypes.array.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ShiftTimes);
