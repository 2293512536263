import React, { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';

import { addIndividualScore, editIndividualScore } from '../../../actions';

const IndividualScoresModal = ({ t, companyId, initialValues = {}, isOpen, handleCloseModal, closeModalAndRefetch, currentUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    if (formValues?.name && formValues?.score) {
      setIsLoading(true);

      const payload = {
        ...formValues,
        name: formValues?.name || null,
        score: formValues?.score || null,
        user: currentUser?.id,
        company: companyId,
      };

      if (formValues?.id) {
        await editIndividualScore(companyId, formValues?.id, payload);
      } else {
        await addIndividualScore(companyId, payload);
      }

      setIsLoading(false);
      closeModalAndRefetch();
    }
  };

  return (
    <Modal
      title={formValues?.id ?
        t('page_content.human_resources.control_panel.individual_goals.modal_edit_title_individual_scores') :
        t('page_content.human_resources.control_panel.individual_goals.modal_add_title_individual_scores')}
      size={modalSizes.large}
      handleClose={handleCloseModal}
      handleSave={handleSave}
      isOpen={isOpen}
    >
      {
        isLoading ? <ContentLoader /> :
          <div className="asset_form_modal_container">
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.control_panel.individual_goals.column_name_individual_scores')}:</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'name', '')} onChange={(e) => onChange(e.target.value, 'name')} />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.control_panel.individual_goals.column_score_individual_scores')}:</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'score', '')} onChange={(e) => onChange(e.target.value, 'score')} />
              </div>
            </div>
          </div>
      }
    </Modal>
  );
};

IndividualScoresModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id', null),
  currentUser: get(state, 'currentUser', null),
});

export default connect(mapStateToProps, null)(withTranslation()(IndividualScoresModal));
