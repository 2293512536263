import api from 'helpers/api';
import moment from 'moment';
import { Notification } from 'shared';

export const getAssets = async (companyId, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/assets/?company=${companyId}&location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching assets', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProductivity = (companyId, locationId, filters = null) => {
  const url = `/api/v1/workforce/productivity/?company=${companyId}&location_id=${locationId}${filters}`;

  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not fetch productivity.');
    });
};

export const getProductivityPaginated = (url) => {
  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not fetch productivity.');
    });
};

export const getProductivityUrl = (url) => {
  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not fetch productivity.');
    });
};

export const getDaySummary = (companyId, locationId, date = null, filters = null) => {
  let url = `/api/v1/workforce/day-summary/?company=${companyId}&location=${locationId}`;
  if (!date) {
    date = moment();
  }

  const dateFilter = date.format('YYYY-MM-DD');
  url += `&date=${dateFilter}`;

  if (filters) {
    url += filters;
  }

  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not fetch day summary.');
    });
};

export const getWorkdays = (companyId, locationId, filters = '', limit = 40) => {
  const url = `/api/v1/workforce/workdays/?company=${companyId}&location=${locationId}&limit=${limit}${filters}`;

  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not fetch workdays.');
    });
};

export const getWorkdaysUrl = (url) => {
  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not fetch workdays.');
    });
};

export const getWorkerCategories = (companyId) => {
  const url = `/api/v1/workforce/worker_categories/?company=${companyId}`;

  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not fetch worker categories.');
    });
};
