import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { IconRemove } from 'shared/Icons';
import { Button } from 'shared';

const ChoiceQuestion = ({ t, question, index, handleChangeAnswerConfig, handleRemoveChoice, handleAddAnswerOptions  }) => {
  return (
    <div>
        <div className="choice_inline_answer">
            <span>{t('page_content.human_resources.forms.choice_answers_label')}:</span>
            <span>{t('page_content.human_resources.forms.choice_points_label')}:</span>
        </div>
    <div className="choice_inline_answer">
        <div>
            {question?.answers?.map((answer, answerIndex) => {
              return <div key={answerIndex} className="question_choice_content">
                    <div className="right_radio">
                        <input checked={false} readOnly type="radio" />
                    </div>
                    <div className={`${!answer.label && 'input-not-valid'} question_choice_inputs`}>
                        <input
                          type="text"
                          onChange={(e) => handleChangeAnswerConfig(e.target.value, index, answerIndex, 'label')}
                          value={answer?.label || ''}
                          placeholder={t('page_content.human_resources.forms.choice_answer_input_placeholder')}
                        />
                    </div>
                </div>;
            })}
        </div>
        <div>
        {question?.answers?.map((answer, answerIndex) => {
          return <div key={answerIndex} className="question_choice_content">
                    <div className={`${!answer.value && 'input-not-valid'} question_choice_inputs-value`}>
                        <input
                          type="text"
                          onChange={(e) => handleChangeAnswerConfig(e.target.value, index, answerIndex, 'value')}
                          value={answer?.value || ''}
                        />
                    </div>
                    <div className="icon_box" onClick={() => handleRemoveChoice(index, answerIndex)}><IconRemove color="#364252" height="14px" width="14px" /></div>
                </div>;
        })}
        </div>
        </div>
        <div style={{ marginTop: 15 }}>
            <Button type="add" onClick={() => handleAddAnswerOptions(question, index)}>{t('page_content.human_resources.forms.button_add_answer')}</Button>
        </div>
    </div>
  );
};

ChoiceQuestion.propTypes = {
    t: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
    index: PropTypes.number,
    handleChangeAnswerConfig: PropTypes.func,
    handleRemoveChoice: PropTypes.func,
    handleAddAnswerOptions: PropTypes.func,
}

export default withTranslation()(ChoiceQuestion);
