import React, { Component } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ReactTableDefaults } from 'react-table';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Modal, Table, Button, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';

import { bulkAsignProductDepartments, getDmsDocuments } from '../actions';
import { getAllTechnologies } from '../../IndustryLocationOrderList/actions';

class BulkAssignModal extends Component {
  constructor() {
    super();
    this.state = {
      dmsDocuments: [],
      bulkGDSelector: null,
      isLoadingDocuments: true,
      bulkImportLoader: false,
      displayStepsTable: false,
      bulkImportMessage: '',
      bulkTechnologySelector: '',
      tech_steps: [
        {
          step: '-',
          man_hours: 0,
          machine_hours: 0,
        },
        {
          step: '-',
          man_hours: 0,
          machine_hours: 0,
        },
        {
          step: '-',
          man_hours: 0,
          machine_hours: 0,
        },
        {
          step: '-',
          man_hours: 0,
          machine_hours: 0,
        },
        {
          step: '-',
          man_hours: 0,
          machine_hours: 0,
        },
      ],
    };
  }

  componentDidMount() {
    const { locationId } = this.props;

    getDmsDocuments(locationId)
      .then((re) => {
        this.setState({
          dmsDocuments: get(re, 'data.results').filter((obj) => obj.document_type.name === 'GD') || [],
          isLoadingDocuments: false,
        });
      });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { bulkTechnologySelector } = this.state;
    const { bulkDepartmentSelector, locationId } = this.props;

    if (prevState.bulkTechnologySelector !== bulkTechnologySelector && bulkTechnologySelector !== '') {
      const resp = get(await getAllTechnologies(locationId, `&department=${bulkDepartmentSelector}`), 'data.results', []);
      const technology = resp.find((tech) => tech.id === bulkTechnologySelector.id);
      const updatedTechSteps = technology?.operations?.map((stepItem) => ({
        step: stepItem,
        man_hours: 0,
        machine_hours: 0,
      }));
      this.setState({
        tech_steps: updatedTechSteps,
      });
    }

    if (prevProps.bulkDepartmentSelector !== bulkDepartmentSelector) {
      this.setState({
        bulkTechnologySelector: '',
        displayStepsTable: false,
        tech_steps: [
          {
            step: '-',
            man_hours: 0,
            machine_hours: 0,
          },
          {
            step: '-',
            man_hours: 0,
            machine_hours: 0,
          },
          {
            step: '-',
            man_hours: 0,
            machine_hours: 0,
          },
          {
            step: '-',
            man_hours: 0,
            machine_hours: 0,
          },
          {
            step: '-',
            man_hours: 0,
            machine_hours: 0,
          },
        ],
      });
    }
  }

  handleHoursChange = (index, property, value) => {
    this.setState((prevState) => {
      const updatedTechSteps = [...prevState.tech_steps];
      updatedTechSteps[index] = {
        ...updatedTechSteps[index],
        [property]: value,
      };

      return {
        ...prevState,
        tech_steps: updatedTechSteps,
      };
    });
  };

  render() {
    const {
      bulkImportLoader,
      bulkImportMessage,
      bulkTechnologySelector,
      tech_steps,
      displayStepsTable,
      dmsDocuments,
      bulkGDSelector,
      isLoadingDocuments,
    } = this.state;
    const {
      t,
      modalStatus,
      handleBulkAsignModalClose,
      productDepartments,
      bulkDepartmentSelector,
      bulkModalDepartmentSelectorFunction,
      selectedProductIds,
      selectedProductsPool,
      companyId,
    } = this.props;

    const columnDefaults = { ...ReactTableDefaults.column, headerClassName: 'bulkAssign_table_row_override_header', className: 'bulkAssign_table_row_override' };

    const getDepartmentFromId = (idToFind, objectList) => {
      const foundObject = objectList.find((obj) => obj.id === parseInt(idToFind, 10));
      return foundObject ? foundObject.name : null;
    };

    const returnTechnologiesFromDepartment = (idToFind, objectList) => ((obj) => obj && obj.technologies)(objectList.find((obj) => obj.id === idToFind));
    const availableTechnologiesModal = returnTechnologiesFromDepartment(bulkDepartmentSelector, productDepartments);

    const modalColumnConfig = [
      {
        Header: () => <span>{t('page_content.projects.products_tab.table_column_code')}</span>,
        accessor: 'code',
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.table_column_name')}</span>,
        accessor: 'name',
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.table_column_manufId')}</span>,
        accessor: 'manuf_id',
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.table_column_baseUnit')}</span>,
        accessor: 'base_unit',
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.table_column_category')}</span>,
        accessor: 'category',
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.table_column_department')}</span>,
        accessor: 'department',
        Cell: (row) => <span>{(row.value !== null && row.value.id) ? getDepartmentFromId(row.value.id, productDepartments) : '-'}</span>,
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.table_column_technology')}</span>,
        accessor: 'technology',
        Cell: (row) => {
          const technologies = returnTechnologiesFromDepartment(row.original.department && row.original.department.id, productDepartments);
          if (technologies && technologies.length > 0) {
            const technology = row.value !== null && row.value.id ? technologies.find((tech) => tech.id === row.value.id) : undefined;
            if (technology) return <span>{technology.name}</span>;
          }
          return '-';
        },
      },
    ];

    const tableColumnConfig = [
      {
        Header: () => <span>{t('page_content.projects.products_tab.steps_modal.table_column_stepName')}</span>,
        width: 300,
        Cell: (row) => <span className="step_preview_modal_input"><input value={bulkTechnologySelector && bulkTechnologySelector.steps && bulkTechnologySelector.steps.steps[row.index]} readOnly disabled /></span>,
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.steps_modal.table_column_manHours')}</span>,
        width: 150,
        Cell: (row) => <span className="step_preview_modal_input"><input value={tech_steps[row.index].man_hours} onChange={(e) => this.handleHoursChange(row.index, 'man_hours', e.target.value)} type="number" /></span>,
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.steps_modal.table_column_machineHours')}</span>,
        width: 150,
        Cell: (row) => <span className="step_preview_modal_input"><input value={tech_steps[row.index].machine_hours} onChange={(e) => this.handleHoursChange(row.index, 'machine_hours', e.target.value)} type="number" /></span>,
      },
    ];

    return (
      <Modal
        isOpen={modalStatus}
        handleClose={handleBulkAsignModalClose}
        size={modalSizes.full}
        title={t('page_content.projects.products_tab.bulk_assign_button')}
        closeText={t('shared.modal.close_button_alt')}
      >
        <div className="products_bulk_assign_modal_wrapper">
          <div>{`${t('page_content.projects.products_tab.bulk_assign_modal.assign_items_count')}: ${selectedProductIds.length}`}</div>
          <Table
            style={{ userSelect: 'text' }}
            columns={modalColumnConfig}
            data={selectedProductsPool.sort((a, b) => a.code.localeCompare(b.code))}
            minRows={0}
            defaultPageSize={10}
            noDataText=" "
            showPagination
            sortable={false}
            loading={bulkImportLoader}
            nextText={t('pagination_table.next')}
            previousText={t('pagination_table.previous')}
          />
          <div className="bulk_selector_area">
            <div className="dropdownArea">
              <div className="selector_wrapper">
                {displayStepsTable && t('page_content.projects.products_tab.bulk_assign_modal.gd_label')}
                <Select
                  options={dmsDocuments.sort((a, b) => a.name.localeCompare(b.name))}
                  getOptionLabel={(document) => document.name}
                  getOptionValue={(document) => document.id}
                  isSearchable
                  menuPosition="fixed"
                  placeholder={t('page_content.projects.products_tab.document_placeholder')}
                  onChange={(value) => this.setState({ bulkGDSelector: value.id })}
                  value={(dmsDocuments.find((doc) => doc.id === bulkGDSelector)) || ''}
                  styles={selectModalStyles}
                  isLoading={isLoadingDocuments}
                  loadingMessage={() => <ContentLoader />}
                />
              </div>
              <div className="selector_wrapper">
                {displayStepsTable && t('page_content.projects.products_tab.bulk_assign_modal.department_label')}
                <Select
                  options={productDepartments.sort((a, b) => a.name.localeCompare(b.name))}
                  getOptionLabel={(department) => department.name}
                  getOptionValue={(department) => department.id}
                  isSearchable
                  menuPosition="fixed"
                  placeholder={t('page_content.projects.products_tab.department_placeholder')}
                  onChange={(value) => bulkModalDepartmentSelectorFunction(value.id)}
                  value={(productDepartments.find((pDep) => pDep.id === bulkDepartmentSelector)) || ''}
                  styles={selectModalStyles}
                />
              </div>
              <div className="selector_wrapper">
                {displayStepsTable && t('page_content.projects.products_tab.bulk_assign_modal.technology_label')}
                <Select
                  isDisabled={(bulkDepartmentSelector && availableTechnologiesModal.length === 0) || (!bulkDepartmentSelector)}
                  options={(bulkDepartmentSelector && availableTechnologiesModal) || []}
                  getOptionLabel={(technology) => technology.name}
                  getOptionValue={(technology) => technology.id}
                  isSearchable
                  menuPosition="fixed"
                  placeholder={(!bulkDepartmentSelector) ? t('settings.timing_settings.product_type_form.technology_requirement') : (bulkDepartmentSelector && availableTechnologiesModal.length === 0) ? t('settings.timing_settings.product_type_form.no_technologies') : t('page_content.projects.products_tab.technology_placeholder')}
                  onChange={(value) => this.setState({
                    bulkTechnologySelector: value,
                  })}
                  value={productDepartments && bulkDepartmentSelector && bulkTechnologySelector
                    ? availableTechnologiesModal.find((tech) => tech.id === bulkTechnologySelector.id) || ''
                    : ''}
                  styles={selectModalStyles}
                />
              </div>
              <div className="checkbox_wrapper">
                <input
                  disabled={!bulkTechnologySelector}
                  type="checkbox"
                  checked={displayStepsTable}
                  onChange={() => this.setState({ displayStepsTable: !displayStepsTable })}
                />
                {t('page_content.projects.products_tab.bulk_assign_modal.man_machine_hours_label')}
              </div>
            </div>

            <div className="table_area">
              {bulkDepartmentSelector && bulkTechnologySelector && displayStepsTable &&
                <Table
                  style={{ userSelect: 'text', overflowX: 'none' }}
                  columns={tableColumnConfig}
                  data={Array(5).fill('')}
                  minRows={0}
                  defaultPageSize={5}
                  noDataText=" "
                  showPagination={false}
                  sortable={false}
                  loading={false}
                  column={columnDefaults}
                />}
            </div>
          </div>

          <Button
            disabled={selectedProductIds.length === 0 || bulkImportLoader || (!bulkDepartmentSelector && !bulkTechnologySelector && !displayStepsTable && !bulkGDSelector)}
            onClick={() => {
              this.setState({
                bulkImportLoader: true,
              });
              const payload = {
                ids: selectedProductIds,
              };
              if (bulkDepartmentSelector) {
                payload.to_id = bulkDepartmentSelector;
              }

              if (bulkTechnologySelector && bulkTechnologySelector.id) {
                payload.to_technology = bulkTechnologySelector.id;
              }

              if (displayStepsTable) {
                payload.tech_steps = tech_steps;
              }

              if (bulkGDSelector) {
                payload.assign_gd_document = bulkGDSelector;
              }

              bulkAsignProductDepartments(companyId, payload)
                .then((re) => {
                  this.setState({
                    bulkImportLoader: false,
                    bulkImportMessage: parseInt((re.data.message.match(/\d+/) || [])[0] || 0, 10),
                  });
                });
            }}
          >
            {t('page_content.projects.products_tab.bulk_assign_modal.assign_button')}
            <span>{bulkGDSelector ? ` ${t('page_content.projects.products_tab.bulk_assign_modal.gd_label')}` : ''}</span>
            <span>{bulkDepartmentSelector ? ` ,${t('page_content.projects.products_tab.bulk_assign_modal.department_label')}` : ''}</span>
            <span>{bulkTechnologySelector ? ` ,${t('page_content.projects.products_tab.bulk_assign_modal.technology_label')}` : ''}</span>
            <span>{displayStepsTable ? ` ,${t('page_content.projects.products_tab.bulk_assign_modal.man_machine_hours_label')}` : ''}</span>
          </Button>

          {bulkImportMessage && <div>{`${t('page_content.projects.products_tab.bulk_assign_modal.assign_post_message')}: ${bulkImportMessage}`}</div>}
        </div>
      </Modal>
    );
  }
}

BulkAssignModal.propTypes = {
  t: PropTypes.func.isRequired,
  modalStatus: PropTypes.bool.isRequired,
  productDepartments: PropTypes.array.isRequired,
  selectedProductIds: PropTypes.array.isRequired,
  selectedProductsPool: PropTypes.array.isRequired,
  handleBulkAsignModalClose: PropTypes.func.isRequired,
  bulkModalDepartmentSelectorFunction: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  bulkDepartmentSelector: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
export default (withTranslation()(BulkAssignModal));
