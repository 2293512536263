import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { checkAccess, redirectToHomePage } from 'industry/helpers';
import { ContentLoader } from 'shared';
import './styles.scss';

import api from 'helpers/api';

import Workers from './components/Workers';
import Forms from './components/Forms/Forms';
import Shifts from './components/Shifts/Shifts';
import Ratings from './components/Ratings/Ratings';
import ControlPanel from './components/ControlPanel/index';
import WorkingInjuries from './components/WorkingInjuries';
import Employments from './components/Employments/Employments';
import DepartmentsTab from './components/Departments/DepartmentsTab';
import AssignedEquipment from './components/AssignedEquipment/index';

const IndustryLocationHumanResources = ({ currentUser, companyId, locationId, t, customDepartment, history, showWorkingInjuries, hrTabs }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUserRole, setCurrentUserRole] = useState('');
  const [managerDepartments, setManagerDepartments] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [department, setDepartment] = useState(null);

  const checkRoles = async () => {
    setIsLoading(true);

    if (currentUser && !currentUser?.is_admin) {
      const isHrAdmin = get(await checkAccess('x-hr-admin', companyId), 'data.access', 0) === 10;

      if (isHrAdmin) {
        setCurrentUserRole('HR Admin');
        setIsLoading(false);
        return;
      }

      const hrRole = currentUser?.roles.find((obj) => obj.role?.name?.toLowerCase() === 'hr');
      if (hrRole) {
        setCurrentUserRole('HR');
        setIsLoading(false);
      } else {
        try {
          const response = await api.get(`/api/v1/workforce/workers/?company=${companyId}&location=${locationId}&user=${currentUser?.id}`);
          const workerId = get(response, 'data.results[0].id', null);
          if (workerId) {
            // Make an API call to check if the user is a manager or deputy manager
            const data = await api.get(`/api/v1/workforce/department_managers/?location=${locationId}&worker=${workerId}&is_active=true`);
            const results = get(data, 'data.results', null);
            const isManager = results.some((manager) => manager.is_manager || manager.is_deputy_manager);
            const departments = results.filter((manager) => manager.is_manager || manager.is_deputy_manager)?.map((obj) => obj.department);
            if (isManager) {
              setCurrentUserRole('Voditelj');
              setManagerDepartments(departments || []);
            } else {
              const currentUrl = window.location.href;
              const newUrl = currentUrl.endsWith('/') ? `${currentUrl}worker/${workerId}` : `${currentUrl}/worker/${workerId}`;
              window.location.href = newUrl;
            }
          } else {
            redirectToHomePage(companyId, locationId);
          }
        } catch (error) {
          redirectToHomePage(companyId, locationId);
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // TODO: staviti neki company/locaiton config s kojim cemo toglati dal da se provjere role ili klasicni check
    checkRoles();
  }, [companyId, locationId]);

  useEffect(() => {
    setSelectedTab(history?.location?.state?.tabIndex || 0);
  }, []);

  const filterByDepartment = (value) => {
    setSelectedTab(0);
    setDepartment(value);
  };

  const handleSelectTab = (e) => {
    setSelectedTab(e);
    setDepartment(null);
  };

  const shouldDisplayTab = (tabName) => {
    const defaultHrTabs = hrTabs || [{ name: 'Workers' }];
    return defaultHrTabs.some((o) => o.name === tabName);
  };

  const userHaveFullAccess = currentUserRole === 'HR Admin' || currentUser.is_admin;
  const userIsHR = currentUserRole === 'HR' || currentUserRole === 'HR Admin' || currentUser.is_admin;

  return (
    <div className="industry-tab human_resources_container fullscreen">
      {
        isLoading
          ? <ContentLoader /> :
          <Tabs selectedIndex={selectedTab} onSelect={handleSelectTab}>
          <TabList>
            {shouldDisplayTab('Workers') && <Tab>{t('page_content.human_resources.workers_tab')}</Tab>}
            {shouldDisplayTab('Employments') && <Tab>{t('page_content.human_resources.layoffs_tab')}</Tab>}
            {shouldDisplayTab('Departments') && <Tab>{t('page_content.human_resources.departments_tab')}</Tab>}
            {shouldDisplayTab('Shifts') && <Tab>{t('page_content.human_resources.shifts_tab')}</Tab>}
            {shouldDisplayTab('Forms') && <Tab>{t('page_content.human_resources.forms_tab')}</Tab>}
            {shouldDisplayTab('Assigned equipment') && <Tab>{t('page_content.human_resources.assigned_equipment_tab')}</Tab>}
            {showWorkingInjuries && <Tab>{t('page_content.human_resources.injuries.working_injuries')}</Tab>}
            {shouldDisplayTab('Ratings') && <Tab>{t('page_content.human_resources.ratings_tab')}</Tab>}
            {shouldDisplayTab('Control panel') && userHaveFullAccess && <Tab>{t('page_content.human_resources.control_panel.parent_tab_control_panel')}</Tab>}
          </TabList>

          {shouldDisplayTab('Workers') &&
            <TabPanel>
              <Workers
                userIsHR={userIsHR}
                companyId={companyId}
                department={department}
                locationId={locationId}
                currentUserRole={currentUserRole}
                customDepartment={customDepartment}
                userHaveFullAccess={userHaveFullAccess}
                managerDepartments={managerDepartments}
              />
            </TabPanel>}

          {shouldDisplayTab('Employments') &&
            <TabPanel>
              <Employments
                userIsHR={userIsHR}
                companyId={companyId}
                locationId={locationId}
              />
            </TabPanel>}

          {shouldDisplayTab('Departments') &&
            <TabPanel>
              <DepartmentsTab
                userIsHR={userIsHR}
                companyId={companyId}
                locationId={locationId}
                handleShowWorkers={filterByDepartment}
              />
            </TabPanel>}

          {shouldDisplayTab('Shifts') &&
            <TabPanel>
              <Shifts
                companyId={companyId}
                locationId={locationId}
              />
            </TabPanel>}

          {shouldDisplayTab('Forms') &&
            <TabPanel>
              <Forms
                companyId={companyId}
                locationId={locationId}
              />
            </TabPanel>}

          {shouldDisplayTab('Assigned equipment') &&
            <TabPanel>
              <AssignedEquipment
                companyId={companyId}
                locationId={locationId}
                currentUser={currentUser}
              />
            </TabPanel>}

          {showWorkingInjuries &&
            <TabPanel>
              <WorkingInjuries
                locationId={locationId}
              />
            </TabPanel>}

          {shouldDisplayTab('Ratings') &&
            <TabPanel>
              <Ratings
                companyId={companyId}
                locationId={locationId}
                currentUserRole={currentUserRole}
                userHaveFullAccess={userHaveFullAccess}
                managerDepartments={managerDepartments}
              />
            </TabPanel>}

          {shouldDisplayTab('Control panel') && userHaveFullAccess &&
            <TabPanel>
              <ControlPanel
                companyId={companyId}
                locationId={locationId}
                userHaveFullAccess={userHaveFullAccess}
                managerDepartments={managerDepartments}
                currentUserRole={currentUserRole}
              />
            </TabPanel>}
        </Tabs>
      }
    </div>
  );
};

IndustryLocationHumanResources.propTypes = {
  hrTabs: PropTypes.array,
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  customDepartment: PropTypes.string,
  showWorkingInjuries: PropTypes.bool,
  history: PropTypes.object.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state) {
  return {
    currentUser: get(state, 'currentUser'),
    companyId: get(state, 'app.company.id'),
    locationId: get(state, 'app.location.id'),
    customDepartment: get(state, 'app.companyConfig.config.custom_department'),
    checkHRAccessByRole: get(state, 'app.companyConfig.config.check_hr_by_role', false),
    showWorkingInjuries: get(state, 'app.companyConfig.config.working_injuries', false),
    hrTabs: get(state, 'app.companyConfig.config.hr_tabs', null),
  };
}

export default connect(mapStateToProps)(withRouter(withTranslation()(IndustryLocationHumanResources)));
