import React from 'react';
import Base from '../Base';

export default (props) => (<Base viewBox="0 0 150 30" {...props}>
    <path fill="#555" d="M37.5 7.5h15v15h-15z" />
    <path fill="#555" d="M27.5 2.5h5v25h-5z" />
    <path fill="#555" d="M7.5 7.5h15v15h-15z" />
    <path fill="#555" d="M57.5 2.5h5v25h-5z" />
    <path fill="#555" d="M67.5 7.5h15v15h-15z" />
    <path fill="#555" d="M87.5 2.5h5v25h-5z" />
    <path fill="#555" d="M97.5 7.5h15v15h-15z" />
    <path fill="#555" d="M117.5 2.5h5v25h-5z" />
    <path fill="#555" d="M127.5 7.5h15v15h-15z" />
</Base>);
