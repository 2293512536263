import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import { withTranslation } from 'react-i18next';
import {
  ContentLoader,
  Modal,
} from 'shared';
import { ReportMetricForm } from '../../components';

class ReportMetricsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      report_metrics: [],
      metrics: [],
      isLoading: false,
    };

    this.getReportMetrics = this.getReportMetrics.bind(this);
    this.getMetrics = this.getMetrics.bind(this);
  }

  componentDidMount() {
    const {
      reportId,
    } = this.props;

    if (reportId > 0) {
      this.getReportMetrics(true);
    }

    this.getMetrics(true);
  }

  getReportMetrics(shouldShowReload) {
    const {
      reportId,
    } = this.props;

    if (shouldShowReload) {
      this.setState({ isLoading: true });
    }

    api
      .get(`/api/v1/reports/report_metrics/?report=${reportId}`)
      .then(({ data }) => {
        this.setState({
          isLoading: false,
          report_metrics: data.results,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getMetrics() {
    const {
      companyId,
    } = this.props;

    api
      .get(`/api/v1/companies/${companyId}/`)
      .then(({ data }) => {
        api
          .get(`/api/v1/industry/metrics/?company_code=${data.short_code}`)
          // eslint-disable-next-line
          .then(({ data }) => {
            if (data.results.length > 0) {
              this.setState({
                metrics: data.results,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleClickOutside = (e) => {
    if (this.node.contains(e.target) || e.target.classList.contains('rt-td')) {
      return;
    }
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    this.props.handleClose(false);
  }

  render() {
    const {
      isLoading,
      report_metrics,
      metrics,
    } = this.state;

    const {
      reportId,
      reportName,
      t,
    } = this.props;

    const modalTitlePrefix = t('page_content.automatic_reports.report_metrics.modal_title_prefix');

    return (
      // eslint-disable-next-line no-return-assign
      <div ref={(node) => (this.node = node)}>
        <Modal
          handleClose={this.props.handleClose}
          isOpen
          size="large"
          contentClass="automatic-reports__report-metrics-modal"
          style={{ content: { padding: '0', top: '20px', left: '0', right: '0', bottom: 'initial' } }}
          title={`${modalTitlePrefix} ${reportName}`}
        >
          {isLoading === true &&
            <ContentLoader />}
          {isLoading === false &&
            <div className="default-form">

              {report_metrics.length > 0 &&
                <Fragment>
                  <h5>{t('page_content.automatic_reports.report_metrics.title')}</h5>
                  <table>
                    {report_metrics.map((reportMetric, index) => {
                      return (<ReportMetricForm
                        key={index}
                        metricId={reportMetric.metric.id}
                        reportId={reportMetric.report}
                        reportMetricId={reportMetric.id}
                        metrics={metrics}
                        reloadEntries={this.getReportMetrics}
                      />);
                    })}
                  </table>
                </Fragment>}

              <h5>{t('page_content.automatic_reports.report_metrics.new')}</h5>
              <table>
                <ReportMetricForm
                  metricName={null}
                  metricId={null}
                  reportId={reportId}
                  metrics={metrics}
                  reloadEntries={this.getReportMetrics}
                />
              </table>
            </div>}
        </Modal>
      </div>
    );
  }
}

ReportMetricsList.propTypes = {
  reportId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  reportName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ReportMetricsList);
