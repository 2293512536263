import React from 'react';
import Base from './Base';

export default (props) => (<Base viewBox="0 0 50 50" {...props}>
  <defs>
    <clipPath id="clip-path">
      <circle
        cx="24.72"
        cy="17"
        r="22"
        style={{
          fill: 'none',
        }}
      />
    </clipPath>
  </defs>
  <circle cx="24.72" cy="17.52" r="5.74" />
  <g clipPath="url(#clip-path)">
    <circle cx="24.72" cy="36.85" r="12.15" />
  </g>
</Base>);
