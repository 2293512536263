import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function ExportIndicator(props) {
  const { current, total, finishing } = props;
  const message = finishing ? 'Exporting...' : `Exporting metric ${current} of ${total}`;
  return (
    <div className="export-loader">
      {message}
      <span className="export-loader__spinner" />
    </div>
  );
}

ExportIndicator.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  finishing: PropTypes.bool.isRequired,
};

export default ExportIndicator;
