import React from 'react';
import api from 'helpers/api';
import './style.scss';

const endpointBase = '/api/v1/industry/datapoints/?group_by=auto&limit=1';

class FerroPreisPlantFloor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      finishingPowerCabinetSum: null,
      finishingPowerCabinetLast: null,
      meltingFurnacePumpSum: null,
      meltingFurnacePumpLast: null,
      ts130Sum: null,
      ts130Last: null,
      mbcSum: null,
      mbcLast: null,
      coreshopTermogenGasSum: null,
      coreshopTermogenGasLast: null,
      topsPowerSum: null,
      topsPowerLast: null,
      pipeProductionLFR20Sum: null,
      pipeProductionLFR20Last: null,
      pipeProductionPowerSum: null,
      pipeProductionPowerLast: null,
      sandPrepWaterSum: null,
      sandPrepWaterLast: null,
      mainOfficePowerSum: null,
      mainOfficePowerLast: null,
      water2PipeSum: null,
      water2PipeLast: null,
      water2SandSum: null,
      water2SandLast: null,
      ts7601000Sum: null,
      ts7601000Last: null,
      mainTrafostationPowerSum: null,
      mainTrafostationPowerLast: null,
      pipeProductionPipes: null,
      meltingFurnace1: null,
      meltingFurnace2: null,
    };
  }

  componentDidMount() {
    this.getValues();
  }

  componentWillUnmount() {
    clearTimeout(this.plantFloorTimeout);
  }

  getValues = () => {
    console.log('fetching values...');
    const to = new Date();
    const from = new Date();
    from.setHours(0, 0, 0, 0);

    api.get(`${endpointBase}&metric__in=${9}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 9', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            finishingPowerCabinetSum: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${50}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 50', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            finishingPowerCabinetLast: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${29}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 29', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            meltingFurnacePumpSum: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${70}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 70', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            meltingFurnacePumpLast: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${37}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 37', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            ts130Sum: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${78}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 78', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            ts130Last: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${6}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 6', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            mbcSum: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${47}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 47', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            mbcLast: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${106}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 106', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            coreshopTermogenGasSum: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${107}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 107', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            coreshopTermogenGasLast: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${31}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 31', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            topsPowerSum: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${72}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 72', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            topsPowerLast: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${22}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 22', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            pipeProductionLFR20Sum: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${63}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 63', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            pipeProductionLFR20Last: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${21}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 21', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            sandPrepWaterSum: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${62}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 62', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            sandPrepWaterLast: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${39}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 39', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            mainOfficePowerSum: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${80}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 80', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            mainOfficePowerLast: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${20}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 20', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            water2PipeSum: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${61}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 61', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            water2PipeLast: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${21}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 21', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            water2SandSum: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${62}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 62', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            water2SandLast: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${23}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 23', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            pipeProductionPowerSum: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${64}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 64', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            pipeProductionPowerLast: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${40}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 40', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            ts7601000Sum: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${81}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 81', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            ts7601000Last: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${25}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 25', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            pipeProductionPipes: v.value,
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${86}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 86', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            meltingFurnace1: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${87}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 87', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            meltingFurnace2: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${35}&operation=sum&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 35', res.data);
        if (res && res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            mainTrafostationPowerSum: v.value.toFixed(2),
          });
        }
      });

    api.get(`${endpointBase}&metric__in=${76}&operation=last&from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => {
        console.log('result metric 76', res.data);
        if (res.data && Object.keys(res.data).length) {
          const v = res.data[Object.keys(res.data)][0];
          this.setState({
            mainTrafostationPowerLast: v.value.toFixed(2),
          });
        }
      });

    this.plantFloorTimeout = setTimeout(this.getValues, 7000);
  }

  render() {
    const {
      finishingPowerCabinetLast,
      finishingPowerCabinetSum,
      meltingFurnacePumpSum,
      meltingFurnacePumpLast,
      ts130Last,
      ts130Sum,
      mbcSum,
      mbcLast,
      coreshopTermogenGasSum,
      coreshopTermogenGasLast,
      topsPowerSum,
      topsPowerLast,
      pipeProductionLFR20Last,
      pipeProductionLFR20Sum,
      pipeProductionPowerSum,
      pipeProductionPowerLast,
      sandPrepWaterSum,
      sandPrepWaterLast,
      mainOfficePowerLast,
      mainOfficePowerSum,
      water2PipeLast,
      water2PipeSum,
      water2SandSum,
      water2SandLast,
      ts7601000Last,
      ts7601000Sum,
      mainTrafostationPowerLast,
      mainTrafostationPowerSum,
      pipeProductionPipes,
      meltingFurnace1,
      meltingFurnace2,
    } = this.state;

    return (
      <div className="industry-tab fullscreen">
        <main style={{ position: 'relative' }}>
          <div style={{ display: 'flex' }}>
            <div className="ferro-preis-resources col">
              <div className="resource resource--electricity">
                Electricity <span />
              </div>
              <div className="resource resource--gas">
                Gas <span />
              </div>
              <div className="resource resource--water">
                Water <span />
              </div>
              <div className="resource resource--air">
                Air <span />
              </div>
              <div className="resource resource--process">
                Process parameters <span />
              </div>
              <p style={{ display: 'inline-block', width: '160px', border: '1px solid #bbb', fontSize: '.9em', padding: '10px 5px' }}>
                Energy is totalised for current day and current power is displayed in parentheses.
              </p>
            </div>
            <table className="ferro-preis-pf col">
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td className="station" colSpan="2" rowSpan="3">
                    <div className="station__title">
                      Finishing
                    </div>
                    <div className="resource resource--electricity resource--first">
                      <span />
                    </div>
                    <div className="station--inner">
                      Annealing
                      <div className="resource resource--electricity">
                        <span />
                      </div>
                    </div>
                    <div className="station--inner">
                      Koyma
                      <div className="resource resource--electricity">
                        <span />
                      </div>
                    </div>
                    <div className="station--inner">
                      Shotblasting
                      <div className="resource resource--electricity">
                        <span />
                      </div>
                    </div>
                    <div className="station--metric">
                      <u>Power cabinet</u><br />{finishingPowerCabinetSum || '-'} kWh ({finishingPowerCabinetLast || '-'} kW)
                    </div>
                  </td>
                  <td>&nbsp;</td>
                  <td className="future-station" rowSpan="3">
                    <div className="station__title">
                      Future: Disa and HWS
                    </div>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td className="future-station">
                    <div className="station__title">
                      Future: sand separation
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="future-station" rowSpan="3">
                    <div className="station__title">
                      Future: warehouse & logistic
                    </div>
                  </td>
                  <td>&nbsp;</td>
                  <td className="station" rowSpan="2">
                    <div className="station__title">
                      Sand preparation
                    </div>
                    <div className="resource resource--electricity resource--first">
                      <span />
                    </div>
                    <div className="resource resource--water resource--second">
                      <span />
                    </div>
                    <div className="station--metric">
                      <u>Water</u><br />{sandPrepWaterSum || '-'} L ({sandPrepWaterLast || '-'} L/h)
                    </div>
                  </td>
                  <td className="station" rowSpan="2" colSpan="2">
                    <div className="station__title">
                      Melting & furnaces
                    </div>
                    <div className="resource resource--electricity resource--first">
                      <span />
                    </div>
                    <div className="resource resource--air resource--second">
                      <span />
                    </div>
                    <div className="station--metric" style={{ fontSize: '.7em' }}>
                      <u>Furnace pump</u><br />{meltingFurnacePumpSum || '-'} kWh ({meltingFurnacePumpLast || '-'} kW)
                    </div>
                    <div className="station--metric" style={{ fontSize: '.7em' }}>
                      Furnace 1: {meltingFurnace1 || '-'} kW
                    </div>
                    <div className="station--metric" style={{ fontSize: '.7em', marginBottom: '25px' }}>
                      Furnace 2: {meltingFurnace2 || '-'} kW
                    </div>
                  </td>
                  <td className="future-station" rowSpan="2">
                    <div className="station__title">
                      Future: pattern shop
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td className="station">
                    <div className="station__title">
                      TS130
                    </div>
                    <div className="resource resource--electricity resource--first">
                      <span />
                    </div>
                    <div className="station--metric" style={{ fontSize: '.7em', marginBottom: '25px' }}>
                      <u>1000 kVA</u><br />{ts130Sum || '-'} kWh ({ts130Last || '-'} kW)
                    </div>
                  </td>
                  <td className="station" colSpan="4" rowSpan="2">
                    <div className="station__title">
                      Main building casting
                    </div>
                    <div className="resource resource--electricity resource--first">
                      <span />
                    </div>
                    <div className="resource resource--process resource--second">
                      <span />
                    </div>
                    <div className="station--inner">
                      Disa
                      <div className="resource resource--electricity">
                        <span />
                      </div>
                      <div className="resource resource--process" style={{ right: '10px' }}>
                        <span style={{ borderColor: 'rgb(109, 109, 109)' }} />
                      </div>
                    </div>
                    <div className="station--inner">
                      Hand
                      <div className="resource resource--electricity">
                        <span />
                      </div>
                      <div className="resource resource--process" style={{ right: '10px' }}>
                        <span style={{ borderColor: 'rgb(109, 109, 109)' }} />
                      </div>
                    </div>
                    <div className="station--metric">
                      <u>Power Cabinet 1</u><br />{mbcSum || '-'} kWh ({mbcLast || '-'} kW)
                    </div>
                  </td>
                  <td className="station">
                    <div className="station__title">
                      Main gas station
                    </div>
                    <div className="resource resource--gas resource--first">
                      <span />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td className="station" rowSpan="2" colSpan="2">
                    <div className="station__title">
                      Coreshop
                    </div>
                    <div className="resource resource--electricity resource--first">
                      <span />
                    </div>
                    <div className="resource resource--gas resource--second">
                      <span />
                    </div>
                    <div className="resource resource--water resource--third">
                      <span />
                    </div>
                    <div className="resource resource--process resource--fourth">
                      <span />
                    </div>
                    <div className="station--metric">
                      <u>Termogen gas</u><br />{coreshopTermogenGasSum || '-'} m3 ({coreshopTermogenGasLast || '-'} m3/h)
                    </div>
                  </td>
                  <td className="future-station" rowSpan="2">
                    <div className="station__title">
                      Future: maintenance dpt
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="office">
                    <div className="station__title">
                      Main office
                    </div>
                    <div className="station--metric">
                      <u>Power</u><br />{mainOfficePowerSum || '-'} kWh ({mainOfficePowerLast || '-'} kW)
                    </div>
                  </td>
                  <td>&nbsp;</td>
                  <td className="office" colSpan="3">
                    <div className="station__title">
                      Tech offices & pattern shop
                    </div>
                    <div className="station--metric">
                      <u>Power</u><br />{topsPowerSum || '-'} kWh ({topsPowerLast || '-'} kW)
                    </div>
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td className="station">
                    <div className="station__title">
                      Water
                    </div>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td className="station" colSpan="2" rowSpan="2">
                    <div className="station__title">
                      Pipe production
                    </div>
                    <div className="resource resource--electricity resource--first">
                      <span />
                    </div>
                    <div className="resource resource--water resource--second">
                      <span />
                    </div>
                    <div className="resource resource--process resource--third">
                      <span />
                    </div>
                    <div className="station--metric">
                      <u>LFR20</u><br />{pipeProductionLFR20Sum || '-'} kWh ({pipeProductionLFR20Last || '-'} kW)
                    </div>
                    <div className="station--metric">
                      <u>Power</u><br />{pipeProductionPowerSum || '-'} kWh ({pipeProductionPowerLast || '-'} kW)
                    </div>
                    <div className="station--metric">
                      <u>Pipes</u><br />{pipeProductionPipes || '-'} unit(s)
                    </div>
                  </td>
                  <td className="station">
                    <div className="station__title">
                      TS760
                    </div>
                    <div className="resource resource--electricity resource--first">
                      <span />
                    </div>
                    <div className="station--metric" style={{ fontSize: '.7em', marginBottom: '25px' }}>
                      <u>1000 kVa</u><br />{ts7601000Sum || '-'} kWh ({ts7601000Last || '-'} kW)
                    </div>
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td className="station">
                    <div className="station__title">
                      Water
                    </div>
                    <div className="resource resource--water resource--first">
                      <span />
                    </div>
                    <div className="station--metric" style={{ fontSize: '.7em' }}>
                      <u>Pipe water</u><br />{water2PipeSum || '-'} L ({water2PipeLast || '-'} L/h)
                    </div>
                    <div className="station--metric" style={{ fontSize: '.7em', marginBottom: '25px' }}>
                      <u>Sand water</u><br />{water2SandSum || '-'} L ({water2SandLast || '-'} L/h)
                    </div>
                  </td>
                  <td className="station">
                    <div className="station__title">
                      Compressors
                    </div>
                    <div className="resource resource--electricity resource--first">
                      <span />
                    </div>
                  </td>
                  <td className="station">
                    <div className="station__title">
                      Main trafostation
                    </div>
                    <div className="resource resource--electricity resource--first">
                      <span />
                    </div>
                    <div className="station--metric">
                      <u>Power</u><br />{mainTrafostationPowerSum || '-'} kWh ({mainTrafostationPowerLast || '-'} kW)
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      </div>
    );
  }
}

export default FerroPreisPlantFloor;
