import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
// import { get } from 'lodash';
// import moment from 'moment';
import WidgetPlaceholder from '../WidgetPlaceholder';
import './styles.scss';

class TableRange extends Component {
  constructor() {
    super();
    this.state = {
      sort: -1,
    };

    this.handleSortChange = this.handleSortChange.bind(this);
  }

  getCellBackgroundColor = (value) => {
    const { settings } = this.props;

    let backgroundColor = '#ffff';

    if (settings && settings.threshold) {
      const threshold = settings.threshold.split(',');
      if (threshold && threshold.length) {
        if (threshold[0]) {
          const t1 = parseInt(threshold[0], 10);
          if (t1 && typeof t1 === 'number' && parseInt(value, 10) > t1) {
            backgroundColor = settings.colors[0];
          }
        }
        if (threshold[1]) {
          const t2 = parseInt(threshold[1], 10);
          if (t2 && typeof t2 === 'number' && parseInt(value, 10) > t2) {
            backgroundColor = settings.colors[1];
          }
        }
        if (threshold[2]) {
          const t3 = parseInt(threshold[2], 10);
          if (t3 && typeof t3 === 'number' && parseInt(value, 10) > t3) {
            backgroundColor = settings.colors[2];
          }
        }
        return backgroundColor;
      }
    }
    return backgroundColor;
  }

  handleSortChange() {
    const { sort } = this.state;
    this.setState({ sort: sort === -1 ? -1 : 1 });
  }

  render() {
    const { height, data, widgetData, settings } = this.props;
    const widgetConfig = widgetData[0];
    if (!widgetConfig) {
      return <WidgetPlaceholder height={height} type="tablerange" />;
    }

    const displayData = [];
    widgetData.forEach((metric) => {
      const metricValuesObj = (data !== null && data !== undefined) ? Object.entries(data).find((id, obj) => { return (parseInt(id, 10) === parseInt(metric.id, 10)) ? obj : null; }) : null;
      const metricValues = (metricValuesObj !== null && metricValuesObj !== undefined && metricValuesObj.length > 0) ? metricValuesObj[1] : [];
      const metricValue = metricValues[0] || { value: 0 };
      displayData.push({
        label: metric.metric.label,
        metric: metricValue,
      });
    });

    // eslint-disable-next-line
    const range = (min, max) => [...Array(max - min + 1).keys()].map(i => i + min);

    const dataToShow = displayData;

    const colsArr = settings.metricCols.split('-');
    const cols = range(
      parseInt(colsArr[0], 10),
      parseInt(colsArr[1], 10),
    );

    const rowsArr = settings.metricRows.split('-');
    const rows = range(
      parseInt(rowsArr[0], 10),
      parseInt(rowsArr[1], 10),
    );

    return (
      <div className="tablerange-widget" style={{ height: `${height}px` }}>

        <table>
          <tbody>
            {rows.map((row) => (
              <tr key={`row_${row}`}>
                {cols.map((col) => {
                  const val = dataToShow.find((x) => x.label === settings.metricTemplate.replace('{rows}', row).replace('{cols}', col));
                  return (val !== null && val !== undefined)
                    ? <td
                        key={`row_${row}_col_${col}`}
                        style={{
                          background: this.getCellBackgroundColor(val.metric.value),
                        }}
                    >
                      <label>{row}-{col}</label>
                      {(settings.unit_decimals !== '')
                        ? <Fragment>
                          {val.metric.value.toFixed(parseInt(settings.unit_decimals, 10) || 0)} {settings.unit_text}
                        </Fragment>
                        : <Fragment>
                          {val.metric.value} {settings.unit_text}
                        </Fragment>}
                    </td>
                    : <td
                        key={`row_${row}_col_${col}`}
                        style={{
                          background: this.getCellBackgroundColor(val.metric.value),
                        }}
                    >
                      -
                    </td>;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

TableRange.propTypes = {
  data: PropTypes.array,
  // eventsData: PropTypes.array,
  height: PropTypes.number,
  widgetData: PropTypes.array.isRequired,
  settings: PropTypes.any,
};

TableRange.defaultProps = {
  data: [],
  eventsData: [],
  widgetData: [],
};

export default TableRange;
