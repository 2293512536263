import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props} viewBox="0 0 100 100">
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(1,0,0,1,7.65078,21.7401)">
      <path d="M16.619,38.821L24.866,17.347L27.928,17.347L36.717,38.821L33.48,38.821L30.975,32.318L21.995,32.318L19.637,38.821L16.619,38.821ZM22.816,30.003L30.096,30.003L27.855,24.056C27.171,22.249 26.663,20.765 26.331,19.603C26.058,20.98 25.672,22.347 25.174,23.704L22.816,30.003Z" />
    </g>
    <g transform="matrix(1,0,0,1,7.65078,21.7401)">
      <path d="M41.082,38.821L38.636,38.821L38.636,17.347L41.273,17.347L41.273,25.008C42.386,23.611 43.807,22.913 45.535,22.913C46.492,22.913 47.398,23.106 48.253,23.492C49.107,23.878 49.81,24.42 50.362,25.118C50.914,25.816 51.346,26.658 51.658,27.645C51.971,28.631 52.127,29.686 52.127,30.809C52.127,33.475 51.468,35.535 50.15,36.99C48.831,38.445 47.249,39.173 45.403,39.173C43.567,39.173 42.127,38.406 41.082,36.873L41.082,38.821ZM41.053,30.926C41.053,32.791 41.307,34.139 41.815,34.969C42.645,36.326 43.768,37.005 45.184,37.005C46.336,37.005 47.332,36.505 48.172,35.504C49.012,34.503 49.432,33.011 49.432,31.028C49.432,28.997 49.029,27.498 48.223,26.531C47.418,25.565 46.443,25.081 45.301,25.081C44.149,25.081 43.152,25.582 42.313,26.583C41.473,27.584 41.053,29.031 41.053,30.926Z" />
    </g>
    <g transform="matrix(1,0,0,1,7.65078,21.7401)">
      <path d="M65.486,33.123L68.079,33.46C67.796,35.247 67.071,36.646 65.904,37.657C64.737,38.668 63.304,39.173 61.605,39.173C59.476,39.173 57.764,38.477 56.47,37.086C55.176,35.694 54.529,33.699 54.529,31.102C54.529,29.422 54.808,27.952 55.364,26.693C55.921,25.433 56.768,24.488 57.906,23.858C59.044,23.228 60.281,22.913 61.619,22.913C63.309,22.913 64.691,23.34 65.765,24.195C66.839,25.049 67.527,26.263 67.83,27.835L65.267,28.231C65.023,27.186 64.59,26.4 63.97,25.872C63.35,25.345 62.601,25.081 61.722,25.081C60.394,25.081 59.315,25.557 58.484,26.509C57.654,27.462 57.239,28.968 57.239,31.028C57.239,33.118 57.64,34.637 58.441,35.584C59.241,36.531 60.286,37.005 61.575,37.005C62.61,37.005 63.475,36.688 64.168,36.053C64.861,35.418 65.301,34.442 65.486,33.123Z" />
    </g>
    <g transform="matrix(0.244725,0,0,0.750825,-2.58561,-11.7141)">
      <rect x="10.271" y="80.197" width="81.666" height="3.996" />
    </g>
    <g transform="matrix(0.244725,0,0,0.750825,77.4864,-11.7141)">
      <rect x="10.271" y="80.197" width="81.666" height="3.996" />
    </g>
  </svg>

</Base>);
