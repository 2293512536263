import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Circle,
  Map,
  TileLayer,
} from 'react-leaflet';
import CustomMarker from './CustomMarker';
import WidgetPlaceholder from '../WidgetPlaceholder';
import { colors } from '../../colors';
import './styles.scss';
// import { markerPositions2 } from './mock.js';

class LocationMap extends Component {
  constructor() {
    super();
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      bounds: [[45.7973127, 15.8968329]],
    };
    this.renderMarkers = this.renderMarkers.bind(this);
  }

  componentDidMount() {
    // Implement fit bounds
    // setInterval(() => {
    //   const newMarkers = this.state.markers.map(m => ({
    //     lat: 45 + Math.random(),
    //     lng: 15 + Math.random(),
    //     title: m.title,
    //   }));
    //   this.setState({ markers: newMarkers });
    // }, 2000);
  }

  getMarkerColor(settings, dot) {
    if (!settings.speedLimit) {
      return '#eee';
    }
    if (dot.speed > settings.speedLimit) {
      return colors.red;
    }
    return colors.green;
  }

  renderMarkers(markers, widgetData) {
    // let bound = this.state.bounds.slice(0);
    if (!widgetData) return;
    const markerz = widgetData.map((data) => {
      if (!markers[data.id] || !markers[data.id].length) {
        return null;
      }
      // bound.push([markers[data.id][0].latitude, markers[data.id][0].longitude]);
      return (
        <CustomMarker
          key={data.id}
          color={data.style.color ? data.style.color : '#e67e22'}
          data={data}
          position={[markers[data.id][0].latitude, markers[data.id][0].longitude]}
        />
      );
    });
    // this.setState({ bounds: bound });
    return markerz;
  }

  renderDots(dots, widgetData, settings) {
    if (!widgetData) return;
    const dotz = widgetData.map((data) => {
      if (!dots[data.id]) {
        return null;
      }
      const len = dots[data.id].length;
      return dots[data.id].map((dot, i) => (
        <Circle
          center={[dot.latitude, dot.longitude]}
          radius={5 + Math.round(dot.speed / 5)}
          color={this.getMarkerColor(settings, dot)}
          stroke={false}
          fillOpacity={Math.max(1 - (i / len), 0.5)}
        />
      ));
    });
    return dotz;
  }

  render() {
    const {
      data,
      height,
      settings,
      widgetData,
      width,
    } = this.props;

    // const data = markerPositions2;

    if (!settings) {
      return <WidgetPlaceholder height={height} type="map" />;
    }

    let center = [get(settings, 'center.lat') || 0, get(settings, 'center.lng') || 0];

    if (settings.tracking && data[widgetData[0].id]) {
      if (data[widgetData[0].id].length) {
        center = [data[widgetData[0].id][0].latitude, data[widgetData[0].id][0].longitude];
      }
    }

    return (
      <Map
        scrollWheelZoom={false}
        center={center}
        zoom={settings.zoom ? parseInt(settings.zoom, 10) : 5}
        style={{
          width,
          height,
        }}
      >
        <TileLayer
          attribution=""
          // url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_nolabels/{z}/{x}/{y}.png"
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_nolabels/{z}/{x}/{y}{r}.png"
        />
        {
          get(settings, 'showHistory') &&
          this.renderDots(data, widgetData, settings)
        }
        {this.renderMarkers(data, widgetData)}
      </Map>
    );
  }
}

LocationMap.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.any,
  settings: PropTypes.object,
  widgetData: PropTypes.any,
  width: PropTypes.any,
};

export default LocationMap;
