import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Modal } from 'shared';
import { modalSizes } from 'shared/constants';

class SAPResponseModal extends Component {
  render() {
    const {
      t,
      isSAPResponseModalOpen,
      closeSAPResponseModal,
      postInSAPResponse,
    } = this.props;

    return (
      <Modal
        isOpen={isSAPResponseModalOpen}
        handleClose={() => closeSAPResponseModal()}
        size={modalSizes.large}
      >
        {
          postInSAPResponse &&
          <div className="SAP_response">
            <Tabs>
              <TabList>
                <Tab>{t('page_content.orders.order_details.bookings_tab.result_tt')}</Tab>
                <Tab>{t('page_content.orders.order_details.bookings_tab.result_goods')}</Tab>
                <Tab>{t('page_content.orders.order_details.bookings_tab.all')}</Tab>
              </TabList>

              <TabPanel>
                <p className="key">{t('page_content.orders.order_details.bookings_tab.status')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_tt && postInSAPResponse.result_tt.status ? postInSAPResponse.result_tt.status : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.content')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_tt && postInSAPResponse.result_tt.content ? postInSAPResponse.result_tt.content : '-'}</p>
              </TabPanel>

              <TabPanel>
                <p className="key">{t('page_content.orders.order_details.bookings_tab.status')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_goods && postInSAPResponse.result_goods.status ? postInSAPResponse.result_goods.status : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.content')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_goods && postInSAPResponse.result_goods.content ? postInSAPResponse.result_goods.content : '-'}</p>
              </TabPanel>

              <TabPanel>
                <h3>{t('page_content.orders.order_details.bookings_tab.result_tt')}</h3>
                <p className="key">{t('page_content.orders.order_details.bookings_tab.success')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_tt && postInSAPResponse.result_tt.success ? 'True' : 'False'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.status')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_tt && postInSAPResponse.result_tt.status ? postInSAPResponse.result_tt.status : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.content')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_tt && postInSAPResponse.result_tt.content ? postInSAPResponse.result_tt.content : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.type')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_tt && postInSAPResponse.result_tt.return_type ? postInSAPResponse.result_tt.return_type : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.id')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_tt && postInSAPResponse.result_tt.return_id ? postInSAPResponse.result_tt.return_id : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.number')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_tt && postInSAPResponse.result_tt.return_number ? postInSAPResponse.result_tt.return_number : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.message')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_tt && postInSAPResponse.result_tt.return_message ? postInSAPResponse.result_tt.return_message : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.detail_type')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_tt && postInSAPResponse.result_tt.detail_type ? postInSAPResponse.result_tt.detail_type : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.detail_message')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_tt && postInSAPResponse.result_tt.detail_message ? postInSAPResponse.result_tt.detail_message : '-'}</p>

                <hr />

                <h3>{t('page_content.orders.order_details.bookings_tab.result_goods')}</h3>
                <p className="key">{t('page_content.orders.order_details.bookings_tab.success')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_goods && postInSAPResponse.result_goods.success ? 'True' : 'False'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.status')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_goods && postInSAPResponse.result_goods.status ? postInSAPResponse.result_goods.status : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.content')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_goods && postInSAPResponse.result_goods.content ? postInSAPResponse.result_goods.content : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.type')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_goods && postInSAPResponse.result_goods.return_type ? postInSAPResponse.result_goods.return_type : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.id')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_goods && postInSAPResponse.result_goods.return_id ? postInSAPResponse.result_goods.return_id : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.number')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_goods && postInSAPResponse.result_goods.return_number ? postInSAPResponse.result_goods.return_number : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.message')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_goods && postInSAPResponse.result_goods.return_message ? postInSAPResponse.result_goods.return_message : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.detail_type')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_goods && postInSAPResponse.result_goods.detail_type ? postInSAPResponse.result_goods.detail_type : '-'}</p>

                <p className="key">{t('page_content.orders.order_details.bookings_tab.detail_message')}</p>
                <p className="value">{postInSAPResponse && postInSAPResponse.result_goods && postInSAPResponse.result_goods.detail_message ? postInSAPResponse.result_goods.detail_message : '-'}</p>
              </TabPanel>
            </Tabs>
          </div>
        }
      </Modal>
    );
  }
}

SAPResponseModal.propTypes = {
  t: PropTypes.func.isRequired,
  isSAPResponseModalOpen: PropTypes.bool.isRequired,
  closeSAPResponseModal: PropTypes.func.isRequired,
  postInSAPResponse: PropTypes.object.isRequired,
};

export default withTranslation()(SAPResponseModal);
