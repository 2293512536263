import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { IconRemove } from 'shared/Icons';
import { Button, Table, TableButtons } from 'shared';

import { addTerminationReasons, getTerminationReasons, getPaginatedTerminationReasons, deleteTerminationReason } from '../actions';

const AddReasonModalPage = ({ t, companyId, showAddReasons, setShowAddReason, fetchReasons, currentUser }) => {
  const [newReasonInput, setNewReasonInput] = useState('');
  const [tableData, setTableData] = useState({
    reasons: [],
    isLoading: false,
    previous: null,
    next: null,
    count: 0,
  });

  const fetchTerminationReasons = async () => {
    setTableData((prevState) => ({ ...prevState, isLoading: true }));
    await getTerminationReasons(companyId, '&limit=15&order_by=-updated_at')
      .then((res) => setTableData({
        reasons: get(res, 'data.results', []),
        isLoading: false,
        previous: get(res, 'data.previous', null),
        next: get(res, 'data.next', null),
        count: get(res, 'data.count', 0),
      }))
      .catch(() => setTableData((prevState) => ({ ...prevState, isLoading: false })));
  };

  const fetchPaginatedTerminationReasons = async (url) => {
    setTableData((prevState) => ({ ...prevState, isLoading: true }));
    await getPaginatedTerminationReasons(url)
      .then((res) => setTableData({
        reasons: get(res, 'data.results', []),
        isLoading: false,
        previous: get(res, 'data.previous', null),
        next: get(res, 'data.next', null),
        count: get(res, 'data.count', 0),
      }))
      .catch(() => setTableData((prevState) => ({ ...prevState, isLoading: false })));
  };

  useEffect(() => {
    fetchTerminationReasons();
  }, []);

  const handleNewReasonInput = (e) => {
    const value = e.target.value;
    setNewReasonInput(value);
  };

  const handleAddReason = async () => {
    const data = {};
    data.company = companyId;
    data.name = newReasonInput;
    data.user = currentUser?.id || null;
    await addTerminationReasons(data)
      .then(() => {
        setNewReasonInput('');
        fetchTerminationReasons();
        fetchReasons();
      })
      .catch(() => setTableData((prevState) => ({ ...prevState, isLoading: false })));
  };

  const handleDeleteTerminationReason = async (row) => {
    await deleteTerminationReason(companyId, row?.original?.id)
      .then(() => {
        fetchTerminationReasons();
        fetchReasons();
      })
      .catch(() => setTableData((prevState) => ({ ...prevState, isLoading: false })));
  };

  return (
    <>
        <div style={{ display: 'flex' }}>
            <Button
              type={showAddReasons ? 'dark-blue' : 'add'}
              style={showAddReasons ? { marginRight: 'auto' } : { marginLeft: 'auto' }}
              onClick={() => setShowAddReason((prevState) => !prevState)}
            >
                {showAddReasons ? t('page_content.human_resources.employments.button_back') : t('page_content.human_resources.employments.button_add_reason')}
            </Button>
        </div>
        {
            showAddReasons && (
                <div>
                    <div className="modal_row">
                        <div className="left_text">{t('page_content.human_resources.employments.label_reason')}</div>
                        <div className="right_input">
                            <input value={newReasonInput} onChange={handleNewReasonInput} placeholder={t('page_content.human_resources.employments.placeholder_type_reason')} />
                        </div>
                    </div>
                    <div className="save_reason_button">
                        <Button type="success" onClick={handleAddReason}>{t('page_content.human_resources.employments.button_add_reason')}</Button>
                    </div>
                </div>
            )
        }
        {
          showAddReasons && (
            <div style={{ paddingTop: 10, marginTop: 10, borderTop: '1px solid #d0d0d0' }}>
              <Table
                style={{ userSelect: 'text' }}
                columns={[
                  {
                    Header: () => <span>{t('Name')}</span>,
                    accessor: 'name',
                    sortable: false,
                    style: {
                      cursor: 'default',
                    },
                  },
                  {
                    Header: () => '',
                    Cell: (row) => (
                      <Button
                        type="delete"
                        onClick={() => handleDeleteTerminationReason(row)}
                      >
                        <IconRemove width="12px" height="12px" />
                      </Button>
                    ),
                    width: 75,
                    sortable: false,
                  },
                ]}
                data={tableData.reasons || []}
                defaultPageSize={15}
                loading={tableData.isLoading}
                minRows={0}
                noDataText=""
                showPagination={false}
                selectedRow={null}
                sortable={false}
                defaultSorted={[{ id: 'updated_at', desc: true }]}
              />
              <div>
                <span style={{ float: 'right', paddingBottom: 10 }}>
                    <TableButtons previous={tableData.previous} next={tableData.next} fetchFunction={fetchPaginatedTerminationReasons} count={tableData.count} />
                </span>
              </div>
            </div>
          )
        }
    </>
  );
};

AddReasonModalPage.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  fetchReasons: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  showAddReasons: PropTypes.bool.isRequired,
  setShowAddReason: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(AddReasonModalPage));
