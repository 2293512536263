import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import { enable2FA, getQRCode, validateToken } from '../../actions';
import { ContentLoader } from '../../../shared/index';

class Enable2FA extends Component {
  constructor() {
    super();
    this.state = {
      otp_secret: null,
      otp_uri: null,
    };
  }

  componentDidMount() {
    this.handleEnable2FA();
  }

  handleEnable2FA = () => {
    enable2FA()
      .then((res) => this.setState({
        otp_secret: res && res.data && res.data.otp_secret ? res.data.otp_secret : null,
        otp_uri: res && res.data && res.data.otp_uri ? res.data.otp_uri : null,
      }))
      .then(() => {
        getQRCode(this.state.otp_uri)
          .then((res) => {
            const base64ImageString = Buffer.from(res.data, 'binary').toString('base64');
            const srcValue = `data:image/png;base64,${base64ImageString}`;
            this.setState({
              qr_code: srcValue,
            });
          });
      });
  }

  render() {
    const { resetPage, t } = this.props;
    const { qr_code, otp_secret } = this.state;

    return (
      <div className="form2FA">
        {!qr_code ? <ContentLoader /> : <img src={qr_code} alt="QR" />}
        <p>{t('user_profile_page.setup_info_2FA_message')}</p>
        <Formik
          initialValues={{ token: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.token.length) {
              errors.token = 'Required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            validateToken({ ...values, otp_secret })
              .then(() => {
                setSubmitting(false);
                resetPage();
              })
              .catch(() => {
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            isSubmitting,
          }) => (
            <Form ref={this.formRef} className="default-form">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <Field
                        type="text"
                        name="token"
                        placeholder="Token"
                        value={values.token}
                      />
                      <ErrorMessage className="error-message" name="token" component="div" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        type="submit"
                        className="submit-button"
                        disabled={isSubmitting}
                      >
                        {t('user_profile_page.validate_button')}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

Enable2FA.propTypes = {
  resetPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default Enable2FA;
