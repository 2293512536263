import api from 'helpers/api';
import { Notification } from 'shared';

export const getWorkProcesses = (companyId) => api.get(`/api/v1/uploads/work_processes/?company=${companyId}`);

export const uploadAllData = (data, companyId) => {
  return api.post(`/api/v1/uploads/uploads/?company=${companyId}`, data)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
    });
};

export const getUploads = (companyId, wpId, query, url) => {
  if (url) {
    return api.get(url);
  }

  if (query) {
    return api.get(`/api/v1/uploads/uploads/?company=${companyId}&work_process=${wpId}&file_name=${query}&order_by=-created_at&limit=50`);
  }

  return api.get(`/api/v1/uploads/uploads/?company=${companyId}&work_process=${wpId}&limit=50&order_by=-created_at`);
};
export const getCalculation = (wpId, uploadId) => api.get(`/api/v1/uploads/histories/?work_process=${wpId}&upload=${uploadId}`);

export const getUploadsHistory = (wpId, filters) => api.get(`/api/v1/uploads/histories/?work_process=${wpId}&order_by=-created_at${filters}`);

export const getUploadsHistoryURL = (url) => api.get(url);

export const addOrEditProgram = (id, companyId, data) => {
  return api.patch(`/api/v1/uploads/work_processes/${id}/?company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};
