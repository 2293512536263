import React from 'react';
import PropTypes from 'prop-types';

const BaseIcon = ({ children, color, height, viewBox, width }) => (
  <svg
    fill={color}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
  // {...props}
  >
    {children}
  </svg>
);

BaseIcon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.array.isRequired,
  ]),
  color: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
};

BaseIcon.defaultProps = {
  color: '#364252',
  height: '100px',
  viewBox: '0 0 100 100',
  width: '100px',
};

export default BaseIcon;
