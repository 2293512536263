import React, { Component } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import api from 'helpers/api';
import PropTypes from 'prop-types';
import moment from 'moment';

import { checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import { Table, Button, Notification, TableButtons, ValuesMapper, ConfirmationModal } from 'shared';
import { IconRemove } from 'shared/Icons';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { selectStyles } from 'styles/modules/reactSelect';
import { getReasons, saveReason, getReasonGroups, editReason, getPaginatedReasons } from '../actions';
import ReasonModal from './ReasonModal';

class Reasons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason_groups: [],
      isLoadingModal: false,
      isLoading: true,
      reasons: [],
      isReasonModalOpen: false,
      selectedReason: null,
      assetOptions: [],
      assetTypeOptions: [],
      selectedReasonGroup: null,
      selectedAsset: null,
      previous: null,
      next: null,
      count: null,
      selectedAscDesc: 'asc',
      selectedSort: 'name',
      showConfirmationDialog: false,
      deleteData: null,
    };
  }

  componentDidMount() {
    const { locationId, companyId } = this.props;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            isReadOnly: true,
          });
        }
      });

    api.get(`/api/v1/industry/assets/?location=${locationId}&order_by=name&limit=9999&company=${companyId}`)
      .then((json) => {
        this.setState({
          assetOptions: get(json, 'data.results', []),
        });
      });

    api.get(`/api/v1/asset_types/?account_type=industry&company=${companyId}&location=${locationId}&order_by=name&limit=200`)
      .then((json) => {
        this.setState({
          assetTypeOptions: get(json, 'data.results', []),
        });
      });

    getReasonGroups(companyId, '', locationId)
      .then((re) => {
        this.setState({
          reason_groups: re && re.data && re.data.results ? re.data.results : [],
        });
      });

    this.fetchReasons();
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchReasons();
    });
  }

  fetchReasons = () => {
    const { selectedReasonGroup, selectedAsset, selectedAscDesc, selectedSort } = this.state;
    const { companyId, locationId } = this.props;
    this.setState({
      isLoading: true,
    });

    let filters = '&limit=30';

    if (selectedReasonGroup) {
      filters += `&group=${selectedReasonGroup}`;
    }

    if (selectedAsset) {
      filters += `&asset__in=${selectedAsset}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    let sortKey = selectedSort;
    if (selectedSort === 'asset_type.code' || selectedSort === 'group.name') {
      sortKey = sortKey.replace('.', '__');
    }
    filters += `&order_by=${asc}${sortKey}`;

    getReasons(companyId, locationId, filters)
      .then((re) => {
        this.setState({
          reasons: get(re, 'data.results', []),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoading: false,
          count: get(re, 'data.count'),
        });
      });
  }

  fetchPaginatedReasons = (url) => {
    this.setState({
      isLoading: true,
    });
    getPaginatedReasons(url)
      .then((re) => {
        this.setState({
          reasons: get(re, 'data.results', []),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoading: false,
          count: get(re, 'data.count'),
        });
      });
  }

  handleOpenModal = (reason) => {
    this.setState({
      isReasonModalOpen: true,
      selectedReason: reason || null,
    });
  }

  handleCloseModal = () => {
    this.setState({
      isReasonModalOpen: false,
      selectedReason: null,
      isLoadingModal: false,
    });
  }

  handleShowConfirmationModal = (reason, e) => {
    e.stopPropagation();
    this.setState({ deleteData: reason, showConfirmationDialog: true });
  }

  handleArchivingReason = () => {
    const { currentUser } = this.props;
    if (!currentUser) {
      return;
    }
    this.setState({
      isLoading: true,
    });

    const id = this.state.deleteData?.original?.id;
    const data = {
      deleted_user: currentUser?.id,
      is_deleted: true,
      deleted_at: moment().toISOString(),
    };

    return api
      .patch(`/api/v1/maintenance/reasons/${id}/`, data)
      .then(() => {
        this.fetchReasons();
        Notification('success', 'Archive successful');
      })
      .catch(() => {
        Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
      })
      .finally(() => {
        this.setState({ showConfirmationDialog: false });
      });
  }

  handleSaveOrEditReason = (data, id = null) => {
    const { companyId } = this.props;

    this.setState({
      isLoadingModal: true,
    });

    if (id) {
      editReason(id, companyId, data)
        .then(() => {
          this.handleCloseModal();
          this.fetchReasons();
        });
    } else {
      saveReason(data, companyId)
        .then(() => {
          this.handleCloseModal();
          this.fetchReasons();
        });
    }
  }

  mapIdsToNames = (ids) => {
    const { assetOptions } = this.state;

    return ids.map((id) => {
      const matchingObject = assetOptions.find((obj) => obj.id === id);
      return matchingObject ? matchingObject.name : '';
    });
  }

  handleFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    }, () => {
      this.fetchReasons();
    });
  }

  handleClearFilters = () => {
    this.setState({
      selectedReasonGroup: null,
      selectedAsset: null,
    }, () => {
      this.fetchReasons();
    });
  }

  render() {
    const {
      reasons,
      isLoading,
      next,
      previous,
      isReasonModalOpen,
      selectedReason,
      isLoadingModal,
      reason_groups,
      assetOptions,
      assetTypeOptions,
      selectedReasonGroup,
      selectedAsset,
      count,
      isReadOnly,
    } = this.state;
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="reason_group_container">
          <div className="reason_filter_row">
            <Select
              options={reason_groups}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isSearchable
              isClearable
              placeholder={t('page_content.maintenance.filter_by_reason_group_placeholder')}
              onChange={(reasonGroup) => this.handleFilterChange('selectedReasonGroup', reasonGroup?.id ?? null)}
              value={reason_groups.find((reason_group) => reason_group.id === selectedReasonGroup) || ''}
              styles={selectStyles}
            />

            <Select
              options={assetOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isSearchable
              isClearable
              placeholder={t('page_content.maintenance.filter_by_asset_placeholder')}
              onChange={(asset) => this.handleFilterChange('selectedAsset', asset?.id ?? null)}
              value={assetOptions.find((asset) => asset.id === selectedAsset) || ''}
              styles={selectStyles}
            />
            <Button onClick={this.handleClearFilters}>{t('shared.clear_button')}</Button>
            <div className="reason_add_new_button">
              <Button type="add" disabled={isReadOnly} onClick={this.handleOpenModal}>{t('page_content.maintenance.reasons.add_new_reason')}</Button>
            </div>
          </div>
          <Table
            columns={[
              {
                Header: () => <span>{t('page_content.maintenance.reasons.name')}</span>,
                accessor: 'name',
                Cell: (row) => (row.value || '-'),
              },
              {
                Header: () => <span>{t('page_content.maintenance.reasons.group')}</span>,
                accessor: 'group.name',
                Cell: (row) => (row.value ? row.value : '-'),
              },
              {
                Header: () => <span>{t('page_content.maintenance.reasons.description')}</span>,
                accessor: 'description',
                Cell: (row) => (row.value || '-'),
              },
              {
                Header: () => <span>{t('page_content.maintenance.reasons.code')}</span>,
                accessor: 'code',
                Cell: (row) => (row.value || '-'),
              },
              {
                Header: () => <span>{t('page_content.maintenance.reasons.is_malfunction')}</span>,
                accessor: 'is_malfunction',
                width: 110,
                Cell: (row) => (<div style={{ margin: 'auto' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" />
                  : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>),
              },
              {
                Header: () => <span>{t('page_content.maintenance.reasons.assets')}</span>,
                accessor: 'assets',
                Cell: (row) => (<ValuesMapper data={this.mapIdsToNames(row.value)} sliceNumber={3} />),
                sortable: false,
              },
              {
                Header: () => <span>{t('page_content.maintenance.reasons.asset_type')}</span>,
                accessor: 'asset_type.code',
                Cell: (row) => (row.value ? row.value : '-'),
              },
              {
                Header: () => <span>{t('page_content.maintenance.reasons.allow_comment')}</span>,
                accessor: 'allow_comment',
                width: 110,
                Cell: (row) => (<div style={{ margin: 'auto' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" />
                  : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>),
              },
              {
                Header: () => <span>{t('page_content.maintenance.reasons.delete')}</span>,
                Cell: (reason) => <div style={{ margin: 'auto' }}><Button type="delete" disabled={isReadOnly} onClick={(e) => this.handleShowConfirmationModal(reason, e)}><IconRemove width="12px" height="12px" /></Button></div>,
                sortable: false,
                width: 110,
              },
            ]}
            data={reasons}
            defaultPageSize={30}
            minRows={0}
            noDataText=""
            showPagination={false}
            loading={isLoading}
            handleClick={(rowInfo) => isReadOnly || this.handleOpenModal(get(rowInfo, 'original'))}
            defaultSorted={[{ id: 'name', desc: false }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <div style={{ float: 'right' }}>
            <TableButtons next={next} previous={previous} fetchFunction={this.fetchPaginatedReasons} count={count} />
          </div>
        </div>

        <ConfirmationModal
          itemName={this.state.deleteData?.original?.name}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleArchivingReason}
          type="warning"
        />

        {
          isReasonModalOpen &&
          <ReasonModal
            t={t}
            isReasonModalOpen={isReasonModalOpen}
            handleCloseModal={this.handleCloseModal}
            selectedReason={selectedReason}
            handleSaveOrEditReason={this.handleSaveOrEditReason}
            isLoadingModal={isLoadingModal}
            reason_groups={reason_groups}
            assetOptions={assetOptions}
            assetTypeOptions={assetTypeOptions}
          />
        }
      </React.Fragment>
    );
  }
}

Reasons.propTypes = {
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default Reasons;
