import api from 'helpers/api';
import { Notification } from 'shared';

export const getReports = async (companyId, locationId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/fives/reports/?company=${companyId}&location=${locationId}&limit=30${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching reports', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getReportsUrl = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching reports', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const downloadXlsx = async (companyId, reportId) => {
  try {
    const res = await api
      .get(`/api/v1/fives/report_entries/?company=${companyId}&report_id=${reportId}&last=true&format=xlsx`, { responseType: 'blob' });
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while downloading xlsx report', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
