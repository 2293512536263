import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { modalSizes } from 'shared/constants';
import { IconRemove, IconEdit } from 'shared/Icons';
import { Table, TableButtons, Button, ConfirmationModal } from 'shared';

import '../styles.scss';
import IndividualScoresModal from './IndividualScoresModal';

import { getIndividualScores, getPaginatedIndividualScores, deleteIndividualScore } from '../../../actions';

const IndividualScoresTable = ({ t, companyId }) => {
  const [individualScores, setIndividualScores] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });

  const [individualScoresFilters, setIndividualScoresFilters] = useState({
    selectedAscDesc: 'desc',
    selectedSort: 'score',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchIndividualScores = () => {
    setIndividualScores((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = individualScoresFilters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&order_by=${asc}${individualScoresFilters?.selectedSort}`;

    getIndividualScores(companyId, apiFilters)
      .then((res) => {
        setIndividualScores({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setIndividualScores((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedIndividualScores = (url) => {
    setIndividualScores((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedIndividualScores(url)
      .then((res) => {
        setIndividualScores({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setIndividualScores((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchIndividualScores();
  }, [companyId, individualScoresFilters]);

  const handleShowConfirmationDialog = (row, e) => {
    e.stopPropagation();
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteIndividualScore(deleteData?.original?.id, companyId);
    setShowConfirmationDialog(false);
    fetchIndividualScores();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setIndividualScoresFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewValueOption = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchIndividualScores();
  };

  return (
    <React.Fragment>
      <div className="individual_goals_table">
        <h3>{t('page_content.human_resources.control_panel.individual_goals.table_name_individual_scores')}</h3>

        <div className="table_action_buttons">
        <Button type="add" onClick={handleAddNewValueOption}>
          {t('page_content.human_resources.control_panel.individual_goals.button_add_new_individual_scores')}
        </Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.control_panel.individual_goals.column_name_individual_scores')}</span>,
              accessor: 'name',
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.control_panel.individual_goals.column_score_individual_scores')}</span>,
              accessor: 'score',
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              Cell: (row) => (
                <Button
                  type="delete"
                  onClick={() => handleTableRowClick(get(row, 'original'))}
                >
                  <IconEdit width="12px" height="12px" />
                </Button>
              ),
              width: 70,
              sortable: false,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              Cell: (row) => (
                <Button
                  type="delete"
                  onClick={(e) => handleShowConfirmationDialog(row, e)}
                >
                  <IconRemove width="12px" height="12px" />
                </Button>
              ),
              width: 70,
              sortable: false,
              style: {
                cursor: 'default',
              },
            },
          ]}
          data={individualScores.data || []}
          defaultPageSize={100}
          loading={individualScores.isLoading}
          minRows={0}
          noDataText=""
          showPagination={false}
          selectedRow={null}
          defaultSorted={[{ id: 'score', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons
              previous={individualScores.previous}
              next={individualScores.next}
              fetchFunction={fetchPaginatedIndividualScores}
              count={individualScores.count}
            />
          </span>
        </div>
      </div>

      {modalData?.isOpen &&
      <IndividualScoresModal
        isOpen={modalData?.isOpen}
        initialValues={modalData?.selectedItem}
        handleCloseModal={handleCloseModal}
        closeModalAndRefetch={closeModalAndRefetch}
      />}

      <ConfirmationModal
        size={modalSizes.medium}
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        customTitle={t('page_content.human_resources.control_panel.individual_goals.table_delete_title', { itemName: get(deleteData, 'original.name') })}
      />
    </React.Fragment>
  );
};

IndividualScoresTable.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default (withTranslation()(IndividualScoresTable));
