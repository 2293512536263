import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Table,
} from 'shared';

class TimingsTable extends React.Component {
  handleRowClick = (timingSetting) => {
    const {
      editTimingSetting,
    } = this.props;

    editTimingSetting(timingSetting);
  }

  render() {
    const {
      timings,
      assets,
      assetTypes,
      productTypes,
      t,
    } = this.props;

    if (!timings || !timings.length) {
      return '';
    }

    return (
      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span>{t('settings.timing_settings.table_column_asset')}</span>,
            accessor: 'asset',
            Cell: (row) => { const asset = ((assets !== undefined) ? assets : []).find((a) => a.id === row.value); return asset ? asset.name : '-'; },
          },
          {
            Header: () => <span>{t('settings.timing_settings.table_column_asset_type')}</span>,
            accessor: 'asset_type',
            Cell: (row) => { const assetType = ((assetTypes !== undefined) ? assetTypes : []).find((aT) => aT.id === row.value); return assetType ? assetType.description : '-'; },
          },
          {
            Header: () => <span>{t('settings.timing_settings.table_column_product_type')}</span>,
            accessor: 'product_type',
            Cell: (row) => { const asset = ((productTypes !== undefined) ? productTypes : []).find((a) => a.id === row.value); return asset ? asset.name : '-'; },
          },
          {
            Header: () => <span>{t('settings.timing_settings.table_column_line')}</span>,
            accessor: 'line_setup_time',
            Cell: (row) => `${row.value} ${row.original.line_setup_unit}`,
          },
          {
            Header: () => <span>{t('settings.timing_settings.table_column_item')}</span>,
            accessor: 'item_setup_time',
            Cell: (row) => `${row.value} ${row.original.item_setup_unit}`,
          },
          {
            Header: () => <span>{t('settings.timing_settings.table_column_cycle')}</span>,
            accessor: 'cycle_time',
            Cell: (row) => `${row.value} ${row.original.cycle_setup_unit}`,
          },
          {
            Header: () => <span>{t('settings.timing_settings.table_column_handling')}</span>,
            accessor: 'handling_time',
            Cell: (row) => `${row.value} ${row.original.handling_setup_unit}`,
          },
        ]}
        data={timings}
        defaultPageSize={timings ? timings.length : 0}
        handleClick={(row) => { this.handleRowClick(row.original); }}
        minRows={0}
        noDataText=" "
        showPagination={false}
        sortable="true"
        manual={false}
      />
    );
  }
}

TimingsTable.propTypes = {
  timings: PropTypes.array.isRequired,
  assets: PropTypes.array.isRequired,
  assetTypes: PropTypes.array.isRequired,
  productTypes: PropTypes.array.isRequired,
  editTimingSetting: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(TimingsTable);
