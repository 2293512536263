import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Tooltip from 'rc-tooltip';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { getLocale } from 'shared/DatePicker/constants';
import { getRectColor, getTooltipStatusColor } from './helpers';
import './styles.scss';

const HeatMapWidget = ({
  t,
  data,
  startDate,
  endDate,
  monthsShown,
}) => {
  const getTooltipContent = (tooltipData) => {
    return (
      <React.Fragment>
        <b>{get(tooltipData, 'date', '') ? moment(get(tooltipData, 'date', '')).format('DD.MM.YYYY') : ''}</b>
        <br />
        <span><b>Status: </b>
          <span style={{ color: getTooltipStatusColor(get(tooltipData, 'state', '')) }}>{get(tooltipData, 'state', '')}</span></span>
        <br />
        {Object.keys(tooltipData.assets).map((assetKey) => {
          const asset = tooltipData.assets[assetKey];
          return (
            <React.Fragment key={assetKey}>
              <span>
                <b>{asset.asset}: </b>
                <span style={{ color: getTooltipStatusColor(asset.state) }}>
                  {asset.state} {asset.state !== 'ok' && `(${asset.counter || ''})`}
                </span>
                <br />
              </span>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  const renderDayContents = (day, date) => {
    const dayData = data.find((entry) => moment(entry.date).isSame(date));

    if (dayData) {
      return (
        <Tooltip
          overlay={getTooltipContent(dayData || {})}
          placement="top"
          trigger={['hover']}
          mouseEnterDelay={0.5}
          overlayClassName="where-filter-tooltip"
        >
          <div
            style={{
              backgroundColor: getRectColor(get(dayData, 'state', '')),
              color: 'black',
              borderRadius: '3px',
            }}
          >
            {day}
          </div>
        </Tooltip>
      );
    }
    return <div
      style={{
        backgroundColor: getRectColor(''),
        color: 'black',
        borderRadius: '3px',
      }}
    >
      {day}
    </div>;
  };

  return (
    <React.Fragment>
      <ReactDatePicker
        selected={startDate.toDate()}
        calendarClassName="heatmap-datepicker"
        renderDayContents={renderDayContents}
        locale={getLocale(t)}
        inline
        calendarStartDay={1}
        monthsShown={monthsShown}
        includeDateIntervals={[
          {
            start: moment(startDate).toDate(),
            end: moment(endDate).toDate(),
          },
        ]}
      />
    </React.Fragment>
  );
};

HeatMapWidget.propTypes = {
  t: PropTypes.func,
  data: PropTypes.array,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  monthsShown: PropTypes.number,
};

HeatMapWidget.defaultProps = {
  data: [],
  startDate: moment().startOf('year'),
  endDate: moment().endOf('year'),
  monthsShown: 12,
};

export default withTranslation()(HeatMapWidget);
