import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Button } from 'shared';
import '../../../styles.scss';

import IndividualGoalsRating from './IndividualGoalsRating';
import CompanyValuesRating from './CompanyValuesRating';
import WorkProcessesRating from './WorkProcessesRating';
import PersonnelDataRating from './PersonnelDataRating';

const RatingScreen = ({ t, selectedWorker, handleBackButton, userHaveFullAccess }) => {
  return (
    <div className="ratings__screen">
      <p className="ratings__screen__worker">{`${selectedWorker?.name} ${selectedWorker?.last_name}`}</p>
      <Tabs>
        <TabList>
          {userHaveFullAccess && <Tab>{t('page_content.human_resources.rating.individual_goals')}</Tab>}
          {userHaveFullAccess && <Tab>{t('page_content.human_resources.rating.company_values')}</Tab>}
          <Tab>{t('page_content.human_resources.rating.work_processes')}</Tab>
          {userHaveFullAccess && <Tab>{t('page_content.human_resources.rating.personnel_data')}</Tab>}
        </TabList>

        <Button onClick={handleBackButton}>{t('page_content.human_resources.rating.back')}</Button>

        {userHaveFullAccess && <TabPanel>
            <IndividualGoalsRating selectedWorker={selectedWorker} userHaveFullAccess={userHaveFullAccess} />
        </TabPanel>}

        {userHaveFullAccess && <TabPanel>
            <CompanyValuesRating selectedWorker={selectedWorker} userHaveFullAccess={userHaveFullAccess} />
        </TabPanel>}

        <TabPanel>
            <WorkProcessesRating selectedWorker={selectedWorker} userHaveFullAccess={userHaveFullAccess} />
        </TabPanel>

        {userHaveFullAccess && <TabPanel>
            <PersonnelDataRating selectedWorker={selectedWorker} userHaveFullAccess={userHaveFullAccess} />
        </TabPanel>}
      </Tabs>
    </div>
  );
};

RatingScreen.propTypes = {
  t: PropTypes.func,
  selectedWorker: PropTypes.object.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  userHaveFullAccess: PropTypes.bool.isRequired,
};

export default RatingScreen;
