import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Modal, ContentLoader } from 'shared';
import { defaultDateTimeFormat } from 'shared/constants';
import { getOCRDetails } from '../../../actions';
import '../../../style.scss';

const ImageModal = ({ t, isOpen, handleClose, id, getAssetNameByExternalId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [OCRDetails, setOCRDetails] = useState({
    image: null,
    created_at: null,
    external_id: null,
    status: null,
  });

  const fetchOCRImage = () => {
    setIsLoading(true);

    getOCRDetails(id)
      .then((res) => {
        setOCRDetails({
          image: get(res, 'data.image', null),
          created_at: get(res, 'data.created_at', null),
          external_id: get(res, 'data.external_id', null),
          status: get(res, 'data.status', null),
        });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchOCRImage();
  }, []);

  const title = `${OCRDetails.created_at ? moment(OCRDetails.created_at).format(defaultDateTimeFormat) : ''} | ${getAssetNameByExternalId(OCRDetails.external_id) || ''} | ${OCRDetails.status || ''}`;
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
    >
      {isLoading ?
        <ContentLoader /> :
        OCRDetails.image ? (
          <div className="OCR__modal">
            <img src={OCRDetails.image} alt="OCR" />
          </div>
        ) :
          <p>{t('page_content.product_library.no_image')}</p>}
    </Modal>
  );
};

ImageModal.propTypes = {
  t: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  getAssetNameByExternalId: PropTypes.func.isRequired,
};

export default ImageModal;
