import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class Label extends Component {
  calculateFontSize = (width, height, characterWidth, lineHeight) => {
    const fontSizeWidth = width / characterWidth;
    const fontSizeHeight = height / lineHeight;
    return Math.min(fontSizeWidth, fontSizeHeight);
  }

  render() {
    const { height, width, settings } = this.props;
    const characterWidth = 9;
    const lineHeight = 2.5;

    const fontSize = this.calculateFontSize(width, height, characterWidth, lineHeight);

    return (
      <div style={{ height: `${height}px`, display: 'flex', alignItems: 'center', color: '#555' }}>
        <p
          style={{
            fontSize: `${fontSize}px`,
            width: '100%',
            textAlign: 'center',
            borderBottom: settings && settings.appearance && settings.appearance.show_divider ? settings.appearance.show_divider ? '2px solid #000' : 'none' : 'none',
            lineHeight: '0.1em',
            margin: '6px',
          }}
        >
          <span style={{ padding: '0 5px', background: 'white' }}>{settings && settings.labelText ? settings.labelText : ''}</span>
        </p>
      </div>
    );
  }
}

Label.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  settings: PropTypes.object,
};

export default withTranslation()(Label);
