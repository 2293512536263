import { isString } from 'lodash';

export const filterDevices = (devices, query) => {
  if (!query) {
    return devices;
  }
  return devices.filter((device) => {
    return (isString(device.external_id) && device.external_id.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
      (isString(device.device_type) && device.device_type.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  });
};

export const formatDeviceType = (row) => {
  const deviceType = typeof row.value === 'object'
    ? `${row.value.model} [${row.value.manufacturer}]`
    : row.value;
  return deviceType || '-';
};
