import React, { useState, useEffect, useRef } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import api from 'helpers/api';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { IconRemove, IconEdit, IconSearch } from 'shared/Icons';
import { modalSizes } from 'shared/constants';
import { Modal, Notification, Table, TableButtons, Button, ConfirmationModal } from 'shared';
import '../../style.scss';

const IverpanFoilsModal = ({
  selectedCompanyTable,
  handleClose,
  isOpen,
  t,
  isReadOnly,
}) => {
  const debounceTimeoutRef = useRef(null);
  const [currentView, setCurrentView] = useState('table');
  const [query, setQuery] = useState('');
  const [selectedSort, setSelectedSort] = useState('materijal');
  const [selectedAscDesc, setSelectedAscDesc] = useState('asc');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const [tableData, setTableData] = useState({
    isLoading: false,
    data: [],
    count: 0,
    next: null,
    previous: null,
    currentUrl: null,
  });

  const [form, setForm] = useState({
    materijal: '',
    folija: '',
  });

  const getTable = (endpoint) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let apiFilters = '?limit=15';

    if (query) {
      apiFilters += `&materijal=${query}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    apiFilters += `&order_by=${asc}${selectedSort}`;

    api.get(`${endpoint}${apiFilters}`)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
          currentUrl: endpoint,
        });
      })
      .catch(() => {
        setTableData(({
          isLoading: false,
          data: [],
          count: 0,
          next: null,
          previous: null,
          currentUrl: null,
        }));
        return Notification('error', 'An error occurred');
      });
  };

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      if (selectedCompanyTable && selectedCompanyTable.endpoint) {
        getTable(selectedCompanyTable.endpoint);
      }
    }, 300);
  }, [query]);

  useEffect(() => {
    if (selectedCompanyTable && selectedCompanyTable.endpoint) {
      getTable(selectedCompanyTable.endpoint);
    }
  }, [selectedCompanyTable, selectedAscDesc, selectedSort]);

  const onFormChange = (value, key) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const fetchPaginatedTable = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    api.get(`${url}`)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
          currentUrl: url,
        });
      })
      .catch(() => {
        return Notification('error', 'An error occurred');
      });
  };

  const handleCloseModal = () => {
    setForm({ materijal: null, folija: null });
    setCurrentView('table');
    handleClose();
  };

  const handleEditItem = (row) => {
    setForm({
      id: get(row, 'original.id', null),
      materijal: get(row, 'original.materijal', null),
      folija: get(row, 'original.folija', null),
    });
    setCurrentView('form');
  };

  const handleShowConfirmationDialog = (row, e) => {
    e.stopPropagation();
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = () => {
    const row = deleteData;
    if (row && row.original && row.original.id && selectedCompanyTable && selectedCompanyTable.endpoint) {
      setTableData((prevState) => ({
        ...prevState,
        isLoading: true,
        data: [],
      }));
      setCurrentView('table');
      api.delete(`${selectedCompanyTable.endpoint}${row.original.id}/`)
        .then(() => {
          getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
          return Notification('success', 'Delete successful', 'Item was successfully deleted.');
        })
        .catch(() => {
          return Notification('error', 'An error occurred');
        })
        .finally(() => setShowConfirmationDialog(false));
    }
  };

  const handleBack = () => {
    setForm({ materijal: null, folija: null });
    setCurrentView('table');
  };

  const handleSorting = (sortData) => {
    const column = sortData.id;
    setSelectedSort(column);
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
  };

  const updateItem = () => {
    if (selectedCompanyTable && selectedCompanyTable.endpoint) {
      setTableData((prevState) => ({
        ...prevState,
        isLoading: true,
        data: [],
      }));
      setCurrentView('table');
      if (form && form.id) {
        api.patch(`${selectedCompanyTable.endpoint}${form.id}/`, form)
          .then(() => {
            handleBack();
            getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
            return Notification('success', 'Save successful', 'Item was successfully updated.');
          })
          .catch(() => {
            handleBack();
            return Notification('error', 'An error occurred');
          });
      } else {
        api.post(`${selectedCompanyTable.endpoint}`, form)
          .then(() => {
            handleBack();
            getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
            return Notification('success', 'Save successful', 'Item was successfully added.');
          })
          .catch(() => {
            handleBack();
            return Notification('error', 'An error occurred');
          });
      }
    }
  };

  const handleAddEntryButton = () => {
    setForm({ materijal: null, folija: null });
    setCurrentView('form');
  };

  const handleClearSearch = () => {
    setQuery('');
  };

  const onFilterChange = (val) => {
    setQuery(val);
  };

  const renderTable = () => {
    return (
      <div style={{ paddingBottom: '30px' }}>
        <div className="custom_data_filters inline_style">
          <div className="custom_data_filters_input">
            <input
              onChange={(e) => onFilterChange(e.target.value)}
              placeholder={t('settings.custom_data.search_by_foil')}
              value={query || ''}
            />
            {query && <button
              onClick={handleClearSearch}
            >&times;</button>}
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <Button
            type="add"
            style={{ margin: '10px' }}
            onClick={handleAddEntryButton}
          >
            {t('settings.custom_data.add_entry')}
          </Button>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('settings.custom_data.material')}</span>,
              accessor: 'materijal',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.foil')}</span>,
              accessor: 'folija',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              Cell: (row) => <Button disabled={isReadOnly} className="remove-btn" onClick={(e) => handleEditItem(row, e)}><IconEdit width="12px" height="12px" /></Button>,
              width: 70,
            },
            {
              Header: () => '',
              Cell: (row) => <Button type="delete" disabled={isReadOnly} className="remove-btn" onClick={(e) => handleShowConfirmationDialog(row, e)}><IconRemove width="12px" height="12px" /></Button>,
              width: 70,
            },
          ]}
          data={tableData.data || []}
          defaultPageSize={15}
          loading={tableData.isLoading}
          minRows={0}
          noDataText=""
          showPagination={false}
          selectedRow={null}
          defaultSorted={[{ id: 'materijal', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons
              previous={tableData.previous}
              next={tableData.next}
              fetchFunction={fetchPaginatedTable}
              count={tableData.count}
            />
          </span>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    const foilOptions = [
      { value: 'FO', name: 'FO: folija obostrano' },
      { value: 'FG', name: 'FG: folija gore' },
      { value: 'FD', name: 'FD: folija dolje' },
    ];

    return (
      <div className="default-form">
        <Button onClick={handleBack}>{t('settings.custom_data.back')}</Button>

        <div className="custom_data_modal_container">
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.material')}:</div>
            <div className="right_input">
              <input type="text" value={get(form, 'materijal', '')} onChange={(e) => onFormChange(e.target.value, 'materijal')} />
            </div>
          </div>

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.foil')}:</div>
            <div className="right_select">
              <Select
                options={foilOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                isClearable
                placeholder=""
                menuPosition="fixed"
                onChange={(e) => onFormChange(e && e.value ? e.value : '', 'folija')}
                value={(foilOptions.find((sOption) => (sOption.value === form.folija))) || ''}
                styles={selectModalStyles}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      size={modalSizes.full}
      handleClose={handleCloseModal}
      handleSave={currentView === 'form' ? updateItem : null}
      isOpen={isOpen}
      title={get(selectedCompanyTable, 'name', '')}
    >
      {currentView === 'table' && renderTable()}
      {currentView === 'form' && renderForm()}
      <ConfirmationModal
        itemName={`${get(deleteData, 'original.materijal')} | ${get(deleteData, 'original.folija')}`}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleDeleteItem}
        type="warning"
      />
    </Modal>
  );
};

IverpanFoilsModal.propTypes = {
  selectedCompanyTable: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

export default withTranslation()(IverpanFoilsModal);
