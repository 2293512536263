/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class MultiClassPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/prop-types
      selectedClasses: props.classValue,
      options: [],
    };
  }

  componentDidMount() {
    const { showAllOptions } = this.props;

    if (showAllOptions) {
      // eslint-disable-next-line
      this.setState({
        options: ['NATUR', 'NATUR DISCOLORATION', 'NATUR BALTIC', 'COUNTRY', 'COUNTRY DISCOLORATION', 'COUNTRY BALTIC', 'CANYON', 'ESPRESSIVO', 'ALAMO', 'OS', 12, 13, 14, 15, 34, 35, 45, 46],
        selectedClasses: [],
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { classValue, selectedProgram, showAllOptions } = this.props;
    if (prevProps.classValue !== classValue && !showAllOptions) {
      // eslint-disable-next-line
      this.setState({ selectedClasses: classValue });
    }
    if (prevProps.selectedProgram !== selectedProgram && !showAllOptions) {
      if (selectedProgram.includes('CK')) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          options: ['NATUR', 'NATUR DISCOLORATION', 'NATUR BALTIC', 'COUNTRY', 'COUNTRY DISCOLORATION', 'COUNTRY BALTIC', 'CANYON', 'ESPRESSIVO', 'ALAMO', 'OS'],
          selectedClasses: [],
        });
      } else {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          options: [12, 13, 14, 15, 34, 35, 45, 46, 'OS'],
          selectedClasses: [],
        });
      }
    }
  }

  handleChange = (classValue) => {
    const { handleChange } = this.props;
    this.setState({ selectedClasses: classValue });
    handleChange(classValue);
  }

  render() {
    const { selectedClasses, options } = this.state;
    const { disabled, htmlId, onBlur, t } = this.props;

    const pickerStyles = {
      placeholder: (provided) => ({
        ...provided,
        color: '#555',
        fontSize: '13px',
        fontWeight: 'normal',
      }),
      control: (provided) => ({
        ...provided,
        border: '1px solid #eee',
        borderRadius: '0',
        height: '38px',
        maxHeight: '100px',
        overflowY: 'auto',
        color: 'black',
        fontWeight: 'normal',
        fontSize: '13px',
        boxShadow: null,
        '&:hover': {
          borderColor: null,
          border: '1px solid #eee',
          borderRadius: '0',
        },
      }),
      dropdownIndicator: (base) => ({
        ...base,
        color: 'black',
        padding: '0',
        width: '15px',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'black',
        fontWeight: 'normal',
        fontSize: '13px',
      }),
    };

    return (
      <Select
        cacheOptions
        isDisabled={disabled}
        id={htmlId}
        isMulti
        options={options}
        getOptionLabel={(option) => option}
        getOptionValue={(option) => option}
        onBlur={onBlur}
        onChange={this.handleChange}
        placeholder={t('shared.multi_class_picker.placeholder')}
        value={selectedClasses}
        className="MultiClassPicker"
        components={{ IndicatorSeparator: () => null }}
        styles={pickerStyles}
      />
    );
  }
}

MultiClassPicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showAllOptions: PropTypes.bool,
  htmlId: PropTypes.string,
  onBlur: PropTypes.func,
  t: PropTypes.func.isRequired,
  selectedProgram: PropTypes.string,
};

MultiClassPicker.defaultProps = {
  disabled: false,
  customClassName: '',
};

export default withTranslation()(MultiClassPicker);
