import React from 'react';
import {
  IconAreaChart,
  IconBarChart,
  IconGauge,
  IconHistogram,
  IconLineChart,
  IconScatterChart,
  IconTable,
  IconMap,
  IconPieChart,
  IconSingleMetric,
  IconSankey,
  IconStepChart,
  IconComposedChart,
  IconGanttChart,
  IconPercentArea,
  IconLabel,
} from 'shared/Icons';

// eslint-disable-next-line
export const widgetTypes = [
  {
    name: 'Line chart',
    type: 'line',
    icon: <IconLineChart color="#777" />,
  },
  {
    name: 'Scatter chart',
    type: 'scatter',
    icon: <IconScatterChart color="#777" />,
  },
  {
    name: 'Area chart',
    type: 'area',
    icon: <IconAreaChart color="#777" />,
  },
  {
    name: 'Step chart',
    type: 'step',
    icon: <IconStepChart color="#777" />,
  },
  {
    name: 'Bar chart',
    type: 'bar',
    icon: <IconBarChart color="#777" />,
  },
  {
    name: 'Sankey chart',
    type: 'sankey',
    icon: <IconSankey color="#777" />,
  },
  {
    name: 'Composed chart',
    type: 'composed',
    icon: <IconComposedChart color="#777" />,
  },
  {
    name: 'Pie chart',
    type: 'pie',
    icon: <IconPieChart color="#777" />,
  },
  {
    name: 'Gauge',
    type: 'gauge',
    icon: <IconGauge color="#777" />,
  },
  {
    name: 'Scalar',
    type: 'scalar',
    icon: <IconSingleMetric color="#777" />,
  },
  {
    name: 'Table',
    type: 'table',
    icon: <IconTable color="#777" />,
  },
  {
    name: 'Table Range',
    type: 'tablerange',
    icon: <IconTable color="#777" />,
  },
  {
    name: 'Orders table',
    type: 'orderstable',
    icon: <IconTable color="#777" />,
  },
  {
    name: 'Label',
    type: 'label',
    icon: <IconLabel color="#777" />,
  },
  {
    name: 'Map',
    type: 'map',
    icon: <IconMap color="#777" />,
  },
  {
    name: 'Histogram',
    type: 'histogram',
    icon: <IconHistogram color="#777" />,
  },
  {
    name: 'Gantt Chart',
    type: 'gantt',
    icon: <IconGanttChart color="#777" />,
  },
  {
    name: 'Percent Area Chart',
    type: 'percent',
    icon: <IconPercentArea color="#777" />,
  },
  {
    name: 'Realtime widget',
    type: 'realtime',
  },
];
