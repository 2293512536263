import * as actionTypes from './actionTypes';

export default function (user = null, action) {
  switch (action.type) {
    case actionTypes.getCurrentUserSuccess:
      return action.payload;

    case actionTypes.getCurrentUserError:
      return null;

    case actionTypes.getPermissionListSuccess:
      return {
        ...action.user,
        permissionList: action.payload,
      };

    case actionTypes.getPermissionListError:
      return action.user;

    default:
      return user;
  }
}
