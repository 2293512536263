import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';
import { multiSelectStylesAutoHeight } from 'styles/modules/reactSelect';

class ReasonModal extends Component {
  constructor(props) {
    super();
    let name = '';
    let description = '';
    let code = '';
    let isMalfunction = false;
    let reason_group = '';
    let assets = [];
    let asset_type = null;
    let allow_comment = false;

    if (props.selectedReason) {
      const { selectedReason } = props;
      name = selectedReason.name || '';
      description = selectedReason.description || '';
      code = selectedReason.code || '';
      isMalfunction = selectedReason.is_malfunction || false;
      reason_group = selectedReason && selectedReason.group && selectedReason.group.id ? selectedReason.group.id : '';
      assets = selectedReason && selectedReason.assets ? selectedReason.assets : [];
      asset_type = selectedReason && selectedReason.asset_type && selectedReason.asset_type.id ? selectedReason.asset_type.id : null;
      allow_comment = selectedReason.allow_comment || false;
    }
    this.state = {
      name,
      description,
      code,
      isMalfunction,
      reason_group,
      assets,
      asset_type,
      allow_comment,
    };
  }

  onFieldChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  getObjectsByIds = (arrayOfIds) => {
    const { assetOptions } = this.props;

    return arrayOfIds.map((id) => assetOptions.find((obj) => obj.id === id));
  }

  handleSaveOrEditReason = () => {
    const { name, description, isMalfunction, reason_group, assets, asset_type, code, allow_comment } = this.state;
    const { handleSaveOrEditReason, selectedReason } = this.props;

    const assetsIds = assets.length && typeof assets[0] === 'object' ? assets.map((obj) => obj.id) : assets;
    const assetTypeId = asset_type && typeof asset_type === 'object' ? asset_type.id : asset_type;

    const data = {
      name,
      description,
      code,
      is_malfunction: isMalfunction,
      group: reason_group,
      logic_group: null,
      assets: assetsIds,
      asset_type: assetTypeId,
      allow_comment,
    };

    const id = selectedReason && selectedReason.id ? selectedReason.id : null;
    handleSaveOrEditReason(data, id);
  }

  render() {
    const { name, description, isMalfunction, reason_group, assets, asset_type, code, allow_comment } = this.state;
    const {
      handleCloseModal,
      isReasonModalOpen,
      isLoadingModal,
      reason_groups,
      t,
      assetOptions,
      assetTypeOptions,
    } = this.props;

    const selectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '100%',
        minHeight: '34px',
        height: '36px',
        padding: 0,
        fontSize: '13px',
        color: '#555',
        marginRight: '5px',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0 10px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: 10,
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: '100%',
        borderRadius: 0,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    return (
      <React.Fragment>
        <Modal
          size={modalSizes.medium}
          isOpen={isReasonModalOpen}
          handleClose={handleCloseModal}
          handleSave={this.handleSaveOrEditReason}
          disableSave={!name || !reason_group || !assets}
        >
          {
            isLoadingModal ?
              <ContentLoader /> :
              <div className="default-form">
                <table>
                  <tbody>
                    <tr>
                      <td className="label">
                        {t('page_content.maintenance.reasons.name')}*
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('name', e.target.value)}
                          value={name}
                          type="text"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.maintenance.reasons.assets')}*
                      </td>
                      <td className="input">
                        <Select
                          cacheOptions
                          isMulti
                          options={assetOptions || []}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          placeholder=""
                          menuPosition="fixed"
                          onChange={(val) => this.onFieldChange('assets', val)}
                          // eslint-disable-next-line eqeqeq
                          value={assets && assets.length && typeof assets[0] == 'number' ? this.getObjectsByIds(assets) : assets}
                          styles={multiSelectStylesAutoHeight}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.maintenance.reasons.asset_type')}
                      </td>
                      <td className="input">
                        <Select
                          isClearable
                          options={assetTypeOptions}
                          getOptionLabel={(option) => option.code}
                          getOptionValue={(option) => option.id}
                          menuPosition="fixed"
                          onChange={(value) => this.onFieldChange('asset_type', value ? value.id : null)}
                          value={assetTypeOptions.find((option) => option.id === asset_type) || null}
                          styles={selectStyles}
                          placeholder=""
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.maintenance.reasons.reason_group')}*
                      </td>
                      <td className="input">
                        <Select
                          options={reason_groups}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          menuPosition="fixed"
                          onChange={(value) => this.onFieldChange('reason_group', value ? value.id : null)}
                          value={reason_groups.find((option) => option.id === reason_group) || null}
                          styles={selectStyles}
                          placeholder=""
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.maintenance.reasons.description')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('description', e.target.value)}
                          value={description}
                          type="text"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.maintenance.reasons.code')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('code', e.target.value)}
                          value={code}
                          type="text"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.maintenance.reasons.is_malfunction')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('isMalfunction', e.target.checked)}
                          checked={isMalfunction}
                          type="checkbox"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.maintenance.reasons.allow_comment')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('allow_comment', e.target.checked)}
                          checked={allow_comment}
                          type="checkbox"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          }

        </Modal>
      </React.Fragment>
    );
  }
}

ReasonModal.propTypes = {
  assetTypeOptions: PropTypes.array.isRequired,
  assetOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isReasonModalOpen: PropTypes.bool.isRequired,
  isLoadingModal: PropTypes.bool.isRequired,
  selectedReason: PropTypes.object.isRequired,
  handleSaveOrEditReason: PropTypes.func.isRequired,
  reason_groups: PropTypes.array.isRequired,
};

export default ReasonModal;
