import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { TopHeader } from 'shared';
import api from 'helpers/api';
import * as industryDashboardActions from 'industry/containers/IndustryLocationDashboard/actions';
import * as userActions from 'user/actions';
import * as actions from '../actions';

const getPathMeta = (pathname = '') => {
  const pathArray = pathname.split('/');

  const companyId = parseInt(pathArray[1], 10) || null;
  const locationId = parseInt(pathArray[4], 10) || null;

  return {
    companyId,
    locationId,
  };
};

class AppContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchedCompanyMemberObject: false,
      user: {},
      selectedLanguage: localStorage.getItem('i18next') || 'en',
      supportedLanguages: ['en', 'hr', 'sl'],

    };

    if (props.history) {
      this.props.history.listen((location) => {
        const pathMeta = getPathMeta(location.location.pathname);
        this.setState(pathMeta);
      });
    }
  }

  componentDidMount() {
    const { supportedLanguages } = this.state;
    const {
      history: {
        location: {
          pathname,
        },
      },
      currentUser,
      fetchPageMeta,
      setUserIsCompanyAdmin,
      fetchCompanyConfig,
      fetchLocationConfig,
      getCurrentUser,
    } = this.props;

    const pageMeta = getPathMeta(pathname);
    if (pageMeta.companyId) {
      getCurrentUser(pageMeta.companyId);
      api.get(`/api/v1/company_members/?company=${pageMeta.companyId}&email=${currentUser.email}`)
        .then((re) => {
          const companyMembers = re.data;
          if (companyMembers && companyMembers.results && companyMembers.results.length) {
            const companyMember = companyMembers.results[0];
            setUserIsCompanyAdmin({ isCompanyAdmin: companyMember.is_admin });
            window.userIsCompanyAdmin = companyMember.is_admin;
            this.setState({
              fetchedCompanyMemberObject: true,
            });
          } else if (currentUser.is_admin) {
            window.userIsCompanyAdmin = true;
          }
        })
        .catch(() => { });

      if (localStorage.getItem('i18next') !== null) {
        this.changeLanguage(localStorage.getItem('i18next'));
      } else {
        actions.getCompanyData(pageMeta.companyId)
          .then((re) => {
            const default_language = re.data.config.default_language || 'en';

            if (supportedLanguages.includes(default_language)) {
              this.changeLanguage(default_language);
            } else {
              this.changeLanguage('en');
            }
          });
      }

      fetchCompanyConfig(pageMeta.companyId);
    }

    if (pageMeta.locationId) {
      fetchLocationConfig(pageMeta.locationId);
    }
    fetchPageMeta(pageMeta);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      setUserIsCompanyAdmin,
      currentUser,
      fetchCompanyConfig,
      fetchLocationConfig,
      getCurrentUser,
    } = this.props;

    const {
      companyId,
      droneId,
      locationId,
      fetchedCompanyMemberObject,
      user,
      supportedLanguages,
    } = this.state;
    // if accountcontroller and not forced and has 1 company redirect to company
    if (companyId && currentUser.email && !fetchedCompanyMemberObject) {
      api.get(`/api/v1/company_members/?company=${companyId}&email=${currentUser.email}`)
        .then((re) => {
          const companyMembers = re.data;
          if (companyMembers && companyMembers.results && companyMembers.results.length) {
            const companyMember = companyMembers.results[0];
            if (window.userIsCompanyAdmin !== companyMember.is_admin) {
              window.userIsCompanyAdmin = companyMember.is_admin;
              user.isCompanyAdmin = window.userIsCompanyAdmin;
              setUserIsCompanyAdmin({ isCompanyAdmin: user.isCompanyAdmin });
            }
          } else if (user.is_admin && !window.userIsCompanyAdmin) {
            window.userIsCompanyAdmin = true;
            user.isCompanyAdmin = window.userIsCompanyAdmin;
            setUserIsCompanyAdmin({ isCompanyAdmin: user.isCompanyAdmin });
          }
          this.setState({
            fetchedCompanyMemberObject: true,
            user,
          });
        })
        .catch(() => { });
    }

    if (companyId && prevState.companyId !== companyId) {
      getCurrentUser(companyId);
      fetchCompanyConfig(companyId);
    }

    if (locationId && prevState.locationId !== locationId) {
      if (localStorage.getItem('i18next') !== null) {
        this.changeLanguage(localStorage.getItem('i18next'));
      } else {
        actions.getCompanyData(companyId)
          .then((re) => {
            const default_language = re.data.config.default_language || 'en';

            if (supportedLanguages.includes(default_language)) {
              this.changeLanguage(default_language);
            } else {
              this.changeLanguage('en');
            }
          });
      }
      fetchLocationConfig(locationId);
    }

    const {
      sidebarItemType,
      sidebarItems,
      history: {
        location: {
          state,
        },
      },
    } = this.props;

    if (sidebarItemType === 'company' && (!state || !state.forced) && sidebarItems && sidebarItems.length === 1) {
      this.props.history.push(`/${sidebarItems[0].id}`, { forced: true });
    }

    if (prevState.companyId !== companyId ||
      prevState.locationId !== locationId ||
      prevState.droneId !== droneId) {
      this.props.fetchPageMeta({ companyId, locationId, droneId });
    }
  }

  handleLogout = () => {
    this.props.logout()
      .then(() => {
        window.location.href = '/';
      });
  }

  changeLanguage = (e) => {
    const value = e && e.target && e.target.value ? e.target.value : e;
    i18next.changeLanguage(value);
    this.setState({
      selectedLanguage: value,
    }, () => {
      localStorage.setItem('i18next', value);
      sessionStorage.setItem('i18next', value);
    });
  }

  render() {
    const {
      sidebarCollapsed,
      currentUser,
      customLogo,
    } = this.props;

    const {
      locationId,
      selectedLanguage,
    } = this.state;

    return (
      <div className={`App ${sidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
        <TopHeader
          logout={this.handleLogout}
          currentUser={currentUser}
          locationId={locationId}
          selectedLanguage={selectedLanguage}
          changeLanguage={this.changeLanguage}
          settings={window.location.href.includes('/settings/')}
          customLogo={customLogo}
          showNotifications={false}
        />
        {
          // false && !isAccountDashboard &&
          // <SubHeader
          //   company={company}
          //   currentChildEntity={sidebarItemType}
          //   drone={drone}
          //   interval={interval}
          //   location={location}
          //   onSetInterval={setInterval}
          //   selectedRange={selectedRange}
          //   sidebarItems={sidebarItems}
          //   isSmartCity={isSmartCity}
          //   daterange={daterange}
          //   setDateRange={this.props.setDateRange}
          //   updateManualRefresh={this.props.updateManualRefresh}
          //   zoomOut={this.props.zoomOut}
          //   isDatePickerVisible={isDatePickerVisible}
          // />
        }
        {this.props.children}
        <ToastContainer
          autoClose={5000}
          closeButton={false}
          toastClassName="Notification-container"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.app,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  setInterval: actions.setInterval,
  setDateRange: actions.setDateRange,
  fetchPageMeta: actions.fetchPageMeta,
  logout: userActions.logout,
  updateManualRefresh: industryDashboardActions.updateManualRefresh,
  zoomOut: actions.zoomOut,
  getCurrentUser: userActions.getCurrentUser,
  setUserIsCompanyAdmin: actions.setUserIsCompanyAdmin,
  fetchCompanyConfig: actions.fetchCompanyConfig,
  fetchLocationConfig: actions.fetchLocationConfig,
};

AppContainer.propTypes = {
  children: PropTypes.any,
  sidebarCollapsed: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  sidebarItemType: PropTypes.string.isRequired,
  sidebarItems: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  fetchPageMeta: PropTypes.func.isRequired,
  setUserIsCompanyAdmin: PropTypes.func.isRequired,
  fetchCompanyConfig: PropTypes.func.isRequired,
  fetchLocationConfig: PropTypes.func.isRequired,
  customLogo: PropTypes.string,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
