import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import api from 'helpers/api';
import {
  Table, Modal, ContentLoader, Button,
} from 'shared';
import { modalSizes } from 'shared/constants';
import {
  checkAccessOnPage, redirectToHomePage, checkModules, numberSeparatorFormat, checkAccess,
} from 'industry/helpers';
import {
  getOrderDetails,
  getOrderParts,
  getOrderHistory,
  getOrderTimeline,
  getOrderStatusHistory,
  getItemStatusHistory,
  getOrderPartHistory,
  getLocations,
  getAssetGroups,
  getSawData,
  getCompanyData,
  getOrderAttachments,
  addOrderAttachment,
  editOrderAttachment,
  deleteOrderAttachment,
  getOrderLinePlans,
} from './actions';
import Pallets from '../IndustryLocationWarehouse/components/Pallets';
import './style.scss';
import OrderBookings from './components/OrderBookings/index';
import OrderAuditLog from './components/OrderAuditLog/index';
import OrderWarehouseRelocation from './components/OrderWarehouseRelocation/index';
import GoodsMovement from '../IndustryLocationWarehouse/components/GoodsMovement';
import OrderParts from './components/OrderParts/index';
import OrderDetails from './components/OrderDetails/index';
import OrderSawData from './components/OrderSawData/index';
import OrderProductionHistory from './components/OrderProductionHistory/index';
import OrderAttachements from './components/OrderAttachements/index';
import LinePlans from './components/OrderLinePlans/index';
import OrderCancellationModal from './components/OrderCancellationModal/index';
import OrderBatches from './components/OrderBatches/index';
import OrderGroups from './components/OrderGroups/index';
import APIEvents from './components/OrderAPIEvents/index';

class IndustryLocationOrderDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      modules: {
        'Order details': false,
        'Order groups': false,
        'Order parts': false,
        'Order production history': false,
        'Order warehouse relocation': false,
        'Order saw data': false,
        'Order operation history': false,
        'Order audit log': false,
        'Order timeline': false,
        'Order circular bar positions': false,
        'Order circular bar components': false,
        'Order bookings': false,
        'Order attachments': false,
        'Order pallets': false,
        'Order line plans': false,
        'Order goods movement': false,
        'Order batches': false,
        'Order api events': false,
      },
      order: null,
      orderLinePlans: null,
      parts: [],
      showSnowflakeModal: false,
      snowflakeUrl: null,
      isLoadingParts: true,
      isLoadingHistory: true,
      isLoadingStatusHistoryModal: true,
      isLoadingLinePlans: true,
      histories: [],
      selectedPartsOperator: '',
      selectedPartsOrderName: '',
      selectedPartsStatus: 'all',
      isStatusHistoryModalOpen: false,
      partsModalData: {},
      sawData: [],
      isLoadingSawData: true,
      isLoadingModules: true,
      orderAttachments: [],
      isLoadingOrderAttachments: true,
      isAttachmentModalOpen: false,
      attachments: [],
      formError: false,
      isUploading: false,
      order_groups_hidden_columns: [],
      isLoadingDetails: false,
      groups: [],
      isAttachmentEdit: false,
      isOrderCancellationModalOpen: false,
      count: 0,
      selectedTab: 0,
      selectedAscDescAttachements: 'desc',
      selectedSortAttachements: 'created_at',
      selectedAscDescOrderParts: 'desc',
      selectedSortOrderParts: 'updated_at',
      selectedAscDescOrderHistory: 'asc',
      selectedSortOrderHistory: 'created_at',
      selectedAscDescSawData: 'desc',
      selectedSortSawData: 'datetime',
      ordersArchivePermission: false,
    };
  }

  fetchOrderParts = () => {
    const orderId = this.getOrderId();
    const filters = this.getPartsFilters();
    this.setState({
      isLoadingParts: true,
    });

    getOrderParts(orderId, filters)
      .then((res) => {
        this.setState({
          parts: get(res, 'data.results') || [],
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          isLoadingParts: false,
        });
      })
      .catch((er) => console.log('Error while fetching order parts', er));
  };

  fetchOrderHistory = (filters = '') => {
    const { selectedAscDescOrderHistory, selectedSortOrderHistory } = this.state;
    const orderId = this.getOrderId();
    const asc = selectedAscDescOrderHistory === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSortOrderHistory}`;
    getOrderHistory(orderId, filters)
      .then((res) => {
        this.setState({
          histories: get(res, 'data.results') || [],
          nextHistory: get(res, 'data.next'),
          previousHistory: get(res, 'data.previous'),
          isLoadingHistory: false,
        });
      })
      .catch((e) => console.log('Error while fetching order history', e));
  };

  fetchSawData = (filters = '') => {
    const { selectedAscDescSawData, selectedSortSawData } = this.state;
    const orderId = this.getOrderId();
    const asc = selectedAscDescSawData === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSortSawData}`;
    getSawData(orderId, filters)
      .then((res) => {
        this.setState({
          sawData: get(res, 'data.results') || [],
          count: get(res, 'data.count'),
          nextSawData: get(res, 'data.next'),
          previousSawData: get(res, 'data.previous'),
          isLoadingSawData: false,
        });
      })
      .catch((e) => console.error('Error while fetching saw data', e));
  };

  async componentDidMount() {
    const {
      companyId, locationId, showPallets, showBatches,
    } = this.props;
    const orderId = this.getOrderId();

    const companyData = await getCompanyData(companyId);
    const location = get(companyData, 'data.locations', null)?.find((loc) => loc.id === locationId)?.code;
    this.setState({
      locationCode: location,
      company_short_code: get(companyData, 'data.short_code', null),
      order_groups_hidden_columns: companyData.data && companyData.data.config && companyData.data.config.order_groups_hidden_columns ? companyData.data.config.order_groups_hidden_columns : [],
      statuses: companyData.data.config.statuses || [],
    });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only --- check if we need it on order details
          this.setState({
            isReadOnly: true,
          });
        }
        if (showBatches) {
          this.setState((prevState) => ({
            modules: {
              ...prevState.modules,
              'Order batches': true,
            },
          }));
        }
        if (showPallets === true) {
          this.setState((prevState) => ({
            modules: {
              ...prevState.modules,
              'Order pallets': true,
              'Order goods movement': true,
            },
          }));
        }
      });

    checkModules(companyId)
      .then((re) => {
        const moduleAccess = re.data;
        const { modules } = this.state;
        Object.keys(modules).forEach((key) => {
          const moduleExists = moduleAccess ? moduleAccess.find((m) => m.name === key) : [];
          if (moduleExists) {
            modules[key] = moduleExists.is_active;
          }
        });
        this.setState({
          modules,
          isLoadingModules: false,
        }, () => {
          if (modules['Order timeline']) {
            getOrderTimeline(orderId)
              .then((res) => {
                this.setState({
                  orderTimeline: res.data,
                });
              })
              .catch((e) => console.error('Error while fetching order details', e));
          }
        });
      });

    if (window?.location?.hash) {
      const tabName = window?.location?.hash.substring(1);
      this.setState({ selectedTab: tabName });
    }

    checkAccess('orders-archive', companyId)
      .then((re) => {
        const data = re.data;
        const orderArchiveAccess = data.access;
        if (orderArchiveAccess === 10) {
          this.setState({
            ordersArchivePermission: true,
          });
        }

        getOrderDetails(orderId, orderArchiveAccess === 10 ? '&show_all=true' : '')
          .then((res) => {
            this.setState({
              order: res.data,
              groups: (res.data && res.data.groups) || [],
            });
          })
          .catch((e) => console.error('Error while fetching order details', e));
      });

    this.fetchOrderParts();

    this.fetchOrderHistory();

    getOrderStatusHistory(orderId)
      .then((res) => {
        this.setState({
          statusHistory: get(res, 'data') || [],
        });
      })
      .catch((e) => console.log('Error while fetching order status history', e));

    getLocations(companyId)
      .then((res) => {
        this.setState({
          locations: get(res, 'data') || [],
        });
      })
      .catch((e) => console.log('Error while fetching locations', e));

    getAssetGroups(companyId)
      .then((res) => {
        this.setState({
          assetGroups: get(res, 'data') || [],
        });
      })
      .catch((e) => console.log('Error while fetching asset groups', e));

    this.fetchSawData();

    this.fetchOrderAttachements();

    this.getOrderLinePlans();
  }

  attachmentsOrderFilter = () => {
    const { selectedAscDescAttachements, selectedSortAttachements } = this.state;
    const asc = selectedAscDescAttachements === 'desc' ? '-' : '';
    let sortKey = selectedSortAttachements;
    if (sortKey === 'user.email') {
      sortKey = 'user__email';
    }

    return `&order_by=${asc}${sortKey}`;
  };

  fetchOrderAttachements = () => {
    const filter = this.attachmentsOrderFilter();

    const orderId = this.getOrderId();
    getOrderAttachments(orderId, filter)
      .then((res) => {
        this.setState({
          orderAttachments: get(res, 'data.results') || [],
          count: get(res, 'data.count'),
          nextAttachemnts: get(res, 'data.next'),
          previousAttachments: get(res, 'data.previous'),
          isLoadingOrderAttachments: false,
        });
      });
  }

  getOrderLinePlans = () => {
    this.setState({
      isLoadingLinePlans: true,
    });

    const orderId = this.getOrderId();
    return getOrderLinePlans(orderId)
      .then((res) => {
        this.setState({
          orderLinePlans: get(res, 'data.results') || [],
          isLoadingLinePlans: false,
        });
      })
      .catch(() => {
        this.setState({
          orderLinePlans: [],
          isLoadingLinePlans: false,
        });
      });
  }

  refreshOrderTabs = () => {
    const { ordersArchivePermission } = this.state;

    const orderId = this.getOrderId();
    return getOrderDetails(orderId, ordersArchivePermission ? '&show_all=true' : '')
      .then((res) => {
        this.setState({
          order: res.data,
          groups: (res.data && res.data.groups) || [],
          isLoadingDetails: false,
        });
      })
      .catch((e) => console.error('Error while fetching order details', e));
  }

  onStatusChange = () => {
    this.refreshOrderTabs();
  }

  setDetailsLoader = (value) => {
    this.setState({
      isLoadingDetails: value,
    });
  }

  onPartsStatusChange = (e) => {
    const selectedPartsStatus = e.target.value;
    this.setState({
      selectedPartsStatus,
    }, () => {
      this.applyPartsFilters();
    });
  }

  onPartsOrderNameChange = (e) => {
    const selectedPartsOrderName = e.target.value;
    this.setState({
      selectedPartsOrderName,
    }, () => {
      if (selectedPartsOrderName.length >= 2 || !selectedPartsOrderName) {
        this.applyPartsFilters();
      }
    });
  }

  onPartsOperatorChange = (e) => {
    const selectedPartsOperator = e.target.value;
    this.setState({
      selectedPartsOperator,
    }, () => {
      if (selectedPartsOperator.length >= 2 || !selectedPartsOperator) {
        this.applyPartsFilters();
      }
    });
  }

  onPartsSnowflakeChange = (e) => {
    const { filteringBySnowflake } = this.state;
    const selectedPartsSnowflake = e.target.value;
    this.setState({
      selectedPartsSnowflake,
    }, () => {
      if ((selectedPartsSnowflake.length >= 6 || !selectedPartsSnowflake) && !filteringBySnowflake) {
        this.setState({
          filteringBySnowflake: true,
        }, () => {
          this.applyPartsFilters();
          setTimeout(() => {
            this.setState({
              filteringBySnowflake: false,
            });
          }, 2000);
        });
      }
    });
  }

  getUserByID = (user, userFields = ['email']) => {
    if (user !== null && typeof user === 'object') {
      const userFieldsMerged = userFields.map((userField) => {
        if (Object.prototype.hasOwnProperty.call(user, userField)) {
          return user[userField];
        }
        return '';
      });
      return userFieldsMerged.join(' ');
    }
    return '';
  }

  getOrderId = () => {
    const url = window.location.href;
    const urlParts = url.split('/');
    const ordersIndex = urlParts.indexOf('orders');

    if (ordersIndex !== -1 && ordersIndex < urlParts.length - 1) {
      const orderId = urlParts[ordersIndex + 1];
      const cleanOrderId = orderId.split('#')[0];
      return cleanOrderId;
    }
    return null;
  }

  getLocationName = (locationId) => {
    const { locations } = this.state;
    if (locations && locations.items) {
      const locationName = locations.items.find((x) => x.id === locationId);
      if (!locationName) {
        return '-';
      }
      return locationName.name;
    }
    return '-';
  }

  getPartsFilters = () => {
    const {
      selectedPartsOperator,
      selectedPartsStatus,
      selectedPartsOrderName,
      selectedPartsSnowflake,
      selectedAscDescOrderParts,
      selectedSortOrderParts,
    } = this.state;

    let filters = '';
    if (selectedPartsOrderName) {
      filters += `&order_name=${selectedPartsOrderName}`;
    }
    if (selectedPartsOperator) {
      filters += `&operator=${selectedPartsOperator}`;
    }
    if (selectedPartsStatus !== 'all') {
      filters += `&status=${selectedPartsStatus}`;
    }
    if (selectedPartsSnowflake) {
      filters += `&snowflake_id=${selectedPartsSnowflake}`;
    }

    const asc = selectedAscDescOrderParts === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSortOrderParts}`;

    return filters;
  }

  getPaginatedParts = (url) => {
    this.setState({
      isLoadingParts: true,
    });
    api.get(url)
      .then((res) => {
        this.setState({
          parts: get(res, 'data.results') || [],
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          isLoadingParts: false,
        });
      })
      .catch((e) => console.log('Error while fetching order parts', e));
  }

  getPaginatedHistory = (url) => {
    this.setState({
      isLoadingHistory: true,
    });
    api.get(url)
      .then((res) => {
        this.setState({
          histories: get(res, 'data.results') || [],
          nextHistory: get(res, 'data.next'),
          previousHistory: get(res, 'data.previous'),
          isLoadingHistory: false,
        });
      })
      .catch((e) => console.log('Error while fetching order history', e));
  }

  getPaginatedSawData = (url) => {
    this.setState({
      isLoadingSawData: true,
    });
    api.get(url)
      .then((res) => {
        this.setState({
          sawData: get(res, 'data.results') || [],
          count: get(res, 'data.count'),
          nextSawData: get(res, 'data.next'),
          previousSawData: get(res, 'data.previous'),
          isLoadingSawData: false,
        });
      })
      .catch((e) => console.log('Error while fetching saw data', e));
  }

  onChangeAttachment = (e) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const newAttachments = Array.from(files).map((file) => ({
        file,
        name: file.name.slice(0, file.name.lastIndexOf('.')),
      }));

      this.setState({
        formError: false,
        attachments: newAttachments,
      });
    }
  }

  onChangeAttachmentName = (index, value) => {
    this.setState((prevState) => {
      const updatedAttachments = [...prevState.attachments];
      updatedAttachments[index] = { ...updatedAttachments[index], name: value };
      return { attachments: updatedAttachments };
    });
  };

  onChangeAttachmentNote = (index, value) => {
    this.setState((prevState) => {
      const updatedAttachments = [...prevState.attachments];

      if (!prevState.isAttachmentEdit) {
        updatedAttachments.forEach((attachment, i) => {
          updatedAttachments[i] = { ...attachment, note: value };
        });
      } else {
        updatedAttachments[index] = { ...updatedAttachments[index], note: value };
      }

      return { attachments: updatedAttachments };
    });
  };

  openAttachmentModal = (rowInfo) => {
    this.setState((prevState) => {
      const newAttachment = rowInfo && rowInfo.original
        ? rowInfo.original
        : {
          file: '',
          name: '',
          note: '',
        };
      const updatedAttachments = [newAttachment, ...prevState.attachments];

      return {
        attachments: updatedAttachments,
        isAttachmentModalOpen: true,
        isAttachmentEdit: !!rowInfo && !!rowInfo.original,
      };
    });
  };

  closeAttachmentModal = () => {
    this.setState({
      formError: false,
      isAttachmentModalOpen: false,
      attachments: [],
      isAttachmentEdit: false,
    });
  }

  saveAttachmentModal = () => {
    const { attachments } = this.state;

    if (attachments.length === 0) {
      this.setState({
        isAttachmentModalOpen: true,
        formError: true,
      });
      return;
    }

    const orderId = this.getOrderId();
    const formDataArray = attachments.map((attachment) => {
      const formData = new FormData();
      formData.append('order', orderId);
      formData.append('name', attachment.name);
      formData.append('note', attachment.note ? attachment.note : '');

      if (!attachment.id) {
        formData.append('file', attachment.file);
      }

      return formData;
    });

    this.setState({
      isLoadingOrderAttachments: true,
      isUploading: true,
    });

    const uploadPromises = formDataArray.map((formData, index) => {
      const attachment = attachments[index];

      if (attachment.id) {
        return editOrderAttachment(attachment.id, orderId, formData);
      }

      return addOrderAttachment(formData);
    });

    Promise.all(uploadPromises)
      .then(() => {
        const filter = this.attachmentsOrderFilter();
        getOrderAttachments(orderId, filter)
          .then((resp) => {
            this.setState({
              isUploading: false,
              isAttachmentEdit: false,
              orderAttachments: get(resp, 'data.results') || [],
              nextAttachemnts: get(resp, 'data.next'),
              count: get(resp, 'data.count'),
              previousAttachments: get(resp, 'data.previous'),
              isLoadingOrderAttachments: false,
              formError: false,
              isAttachmentModalOpen: false,
              attachments: [],
            });
          });
      })
      .catch((error) => {
        console.error('Error uploading files:', error);
        this.setState({
          isLoadingOrderAttachments: false,
          isUploading: false,
        });
      });
  }

  handleDeleteAttachment = (row) => {
    this.setState({
      isLoadingOrderAttachments: true,
    });

    const id = row.original.id;
    const orderId = this.getOrderId();

    deleteOrderAttachment(id, orderId)
      .then(() => {
        const filter = this.attachmentsOrderFilter();
        getOrderAttachments(orderId, filter)
          .then((res) => {
            this.setState({
              orderAttachments: get(res, 'data.results') || [],
              count: get(res, 'data.count'),
              nextAttachemnts: get(res, 'data.next'),
              previousAttachments: get(res, 'data.previous'),
              isLoadingOrderAttachments: false,
            });
          });
      });
  }

  applyPartsFilters = () => {
    this.setState({
      isLoadingParts: true,
    });
    const filters = this.getPartsFilters();
    const orderId = this.getOrderId();
    getOrderParts(orderId, filters)
      .then((res) => {
        this.setState({
          parts: get(res, 'data.results') || [],
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          isLoadingParts: false,
        });
      })
      .catch((er) => console.log('Error while fetching order parts', er));
  }

  clearAllPartsFilters = () => {
    this.setState({
      selectedPartsOperator: '',
      selectedPartsOrderName: '',
      selectedPartsStatus: 'all',
      selectedPartsSnowflake: '',
    }, this.applyPartsFilters);
  }

  displaySnowflakeModal = (imageUrl) => {
    this.setState({
      showSnowflakeModal: true,
      snowflakeUrl: imageUrl,
    });
  }

  handleSortingParts = (sortData) => {
    let sortKey = sortData.id;
    if (sortKey === 'order_item.name' || sortKey === 'order_item.item_num') {
      sortKey = sortKey.replace('.', '__');
    }
    this.setState({
      selectedSortOrderParts: sortKey,
      selectedAscDescOrderParts: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchOrderParts();
    });
  }

  openStatusHistoryModal = (rowInfo) => {
    const orderId = rowInfo.original.order;
    const itemId = rowInfo.original.order_item.id;
    const statusHistoryModalData = rowInfo.original;

    this.setState({
      isStatusHistoryModalOpen: true,
      statusHistoryModalData,
    });

    getItemStatusHistory(orderId, itemId)
      .then((res) => {
        this.setState({
          selectedOrderHistory: get(res, 'data') || [],
          isLoadingStatusHistoryModal: false,
        });
      })
      .catch((e) => console.log('Error while fetching order status history', e));
  }

  closeStatusHistoryModal = () => {
    this.setState({
      isStatusHistoryModalOpen: false,
      statusHistoryModalData: null,
      selectedOrderHistory: null,
      isLoadingStatusHistoryModal: true,
    });
  }

  firstLetterUpperCase = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  openPartsModal = (part) => {
    const { locations } = this.state;
    this.setState({
      isLoadingPartsModal: true,
      isPartsModalOpen: true,
    });

    const orderId = this.getOrderId();
    const data = { part, history: null };
    getOrderPartHistory(orderId, part.id)
      .then((re) => {
        data.history = re.data ? re.data.results : [];
        if (data.history && data.history.length) {
          data.history.forEach((h) => {
            if (h.asset_group && h.asset_group.location) {
              h.asset_group.location = locations.items.find((l) => l.id === h.asset_group.location);
            }
          });
        }
        this.setState({
          partsModalData: data,
          isLoadingPartsModal: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoadingPartsModal: false,
          isPartsModalOpen: false,
        });
      });
  }

  closePartsModal = () => {
    this.setState({
      isLoadingPartsModal: false,
      isPartsModalOpen: false,
      partsModalData: {},
    });
  }

  selectHistoryAssetGroup = (e) => {
    const selectedHistoryAssetGroup = e;
    this.setState({
      selectedHistoryAssetGroup,
    });

    const { selectedHistoryItem, selectedHistoryOperator, selectedHistorySnowflake } = this.state;

    let filters = '';
    if (selectedHistoryAssetGroup) {
      filters += `&asset_group=${selectedHistoryAssetGroup}`;
    }
    if (selectedHistoryItem) {
      filters += `&order_item=${selectedHistoryItem}`;
    }
    if (selectedHistoryOperator) {
      filters += `&operator=${selectedHistoryOperator}`;
    }
    if (selectedHistorySnowflake && selectedHistorySnowflake.length === 19) {
      filters += `&snowflake=${selectedHistorySnowflake}`;
    }

    this.fetchOrderHistory(filters);
  }

  selectHistoryItem = (e) => {
    const selectedHistoryItem = e;
    this.setState({
      selectedHistoryItem,
    });

    const { selectedHistoryAssetGroup, selectedHistoryOperator, selectedHistorySnowflake } = this.state;

    let filters = '';
    if (selectedHistoryAssetGroup) {
      filters += `&asset_group=${selectedHistoryAssetGroup}`;
    }
    if (selectedHistoryItem) {
      filters += `&order_item=${selectedHistoryItem}`;
    }
    if (selectedHistoryOperator) {
      filters += `&operator=${selectedHistoryOperator}`;
    }
    if (selectedHistorySnowflake && selectedHistorySnowflake.length === 19) {
      filters += `&snowflake=${selectedHistorySnowflake}`;
    }

    this.fetchOrderHistory(filters);
  }

  selectHistoryOperator = (e) => {
    const selectedHistoryOperator = e.target.value;
    this.setState({
      selectedHistoryOperator,
    }, () => {
      if (selectedHistoryOperator.length >= 2 || !selectedHistoryOperator) {
        const { selectedHistoryAssetGroup, selectedHistoryItem, selectedHistorySnowflake } = this.state;

        let filters = '';
        if (selectedHistoryAssetGroup) {
          filters += `&asset_group=${selectedHistoryAssetGroup}`;
        }
        if (selectedHistoryItem) {
          filters += `&order_item=${selectedHistoryItem}`;
        }
        if (selectedHistoryOperator) {
          filters += `&operator=${selectedHistoryOperator}`;
        }
        if (selectedHistorySnowflake && selectedHistorySnowflake.length === 19) {
          filters += `&snowflake=${selectedHistorySnowflake}`;
        }

        this.fetchOrderHistory(filters);
      }
    });
  }

  selectHistorySnowflake = (e) => {
    const { filteringBySnowflake } = this.state;
    const selectedHistorySnowflake = e.target.value;
    this.setState({
      selectedHistorySnowflake,
    }, () => {
      if ((selectedHistorySnowflake.length >= 6 || !selectedHistorySnowflake) && !filteringBySnowflake) {
        this.setState({
          filteringBySnowflake: true,
          isLoadingHistory: true,
        }, () => {
          const { selectedHistoryAssetGroup, selectedHistoryItem, selectedHistoryOperator } = this.state;

          let filters = '';
          if (selectedHistoryAssetGroup) {
            filters += `&asset_group=${selectedHistoryAssetGroup}`;
          }
          if (selectedHistoryItem) {
            filters += `&order_item=${selectedHistoryItem}`;
          }
          if (selectedHistoryOperator) {
            filters += `&operator=${selectedHistoryOperator}`;
          }
          if (selectedHistorySnowflake && selectedHistorySnowflake.length === 19) {
            filters += `&snowflake=${selectedHistorySnowflake}`;
          }

          this.fetchOrderHistory(filters);
        });
      }
    });
  }

  clearHistoriesFilters = () => {
    this.setState({
      selectedHistoryOperator: '',
      selectedHistoryAssetGroup: '',
      selectedHistoryItem: '',
      selectedHistorySnowflake: '',
    }, () => {
      this.fetchOrderHistory();
    });
  }

  handleSortingSawData = (sortData) => {
    this.setState({ isLoadingSawData: true });
    const column = sortData.id;

    let sortKey = column;
    if (column === 'asset.name') {
      sortKey = 'asset__name';
    }

    this.setState({
      selectedSortSawData: sortKey,
      selectedAscDescSawData: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchSawData();
    });
  }

  handleHistorySorting = (sortData) => {
    this.setState({ isLoadingHistory: true });
    const column = sortData.id;

    const {
      selectedHistoryAssetGroup,
      selectedHistoryItem,
      selectedHistoryOperator,
      selectedHistorySnowflake,
    } = this.state;

    let filters = '';
    if (selectedHistoryAssetGroup) {
      filters += `&asset_group=${selectedHistoryAssetGroup}`;
    }
    if (selectedHistoryItem) {
      filters += `&order_item=${selectedHistoryItem}`;
    }
    if (selectedHistoryOperator) {
      filters += `&operator=${selectedHistoryOperator}`;
    }
    if (selectedHistorySnowflake && selectedHistorySnowflake.length === 19) {
      filters += `&snowflake=${selectedHistorySnowflake}`;
    }

    let sortKey = column;
    if (sortKey === 'order_item.status' ||
    sortKey === 'asset_group.location' ||
    sortKey === 'asset_group.name' ||
    sortKey === 'order_item.name' ||
    sortKey === 'order_item.item_num') {
      sortKey = sortKey.replace('.', '__');
    }

    this.setState({
      selectedSortOrderHistory: sortKey,
      selectedAscDescOrderHistory: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchOrderHistory(filters);
    });
  }

  handleTabSelect = (index) => {
    this.setState({ selectedTab: index });
    window.location.hash = index;
  };

  redirectToOrder = (orderId) => {
    const url = window.location.href;

    const urlParts = url.split('/');
    urlParts.pop();
    const newUrl = `${urlParts.join('/')}/${orderId}`;

    window.open(newUrl, '_blank');
  }

  openOrderCancellationModal = () => {
    this.setState({
      isOrderCancellationModalOpen: true,
    });
  }

  closeOrderCancellationModal = () => {
    this.setState({
      isOrderCancellationModalOpen: false,
    });
  }

  handleSortingAttachments = (sortData) => {
    this.setState({
      selectedSortAttachements: sortData.id,
      selectedAscDescAttachements: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchOrderAttachements();
    });
  }

  render() {
    const {
      companyId,
      locationId,
      t,
      i18n,
      isProjectBased,
      status_change,
      can_cancel_order,
      locationConfig,
      archiveLabel,
    } = this.props;
    const {
      order,
      parts,
      showSnowflakeModal,
      snowflakeUrl,
      previous,
      next,
      countParts,
      isLoadingParts,
      histories,
      isLoadingHistory,
      nextHistory,
      previousHistory,
      selectedPartsOperator,
      selectedPartsOrderName,
      selectedPartsStatus,
      selectedPartsSnowflake,
      isStatusHistoryModalOpen,
      statusHistoryModalData,
      selectedOrderHistory,
      isLoadingStatusHistoryModal,
      statusHistory,
      orderTimeline,
      isPartsModalOpen,
      isLoadingPartsModal,
      partsModalData,
      selectedHistoryAssetGroup,
      selectedHistoryItem,
      selectedHistoryOperator,
      selectedHistorySnowflake,
      assetGroups,
      sawData,
      isLoadingSawData,
      previousSawData,
      nextSawData,
      modules,
      isLoadingModules,
      company_short_code,
      orderAttachments,
      isLoadingOrderAttachments,
      nextAttachemnts,
      previousAttachments,
      isAttachmentModalOpen,
      attachments,
      isAttachmentEdit,
      isUploading,
      order_groups_hidden_columns,
      isReadOnly,
      orderLinePlans,
      isLoadingLinePlans,
      statuses,
      isLoadingDetails,
      groups,
      isOrderCancellationModalOpen,
      count,
      selectedTab,
      locationCode,
      ordersArchivePermission,
    } = this.state;

    let order_progress_bars;
    if (locationConfig && Object.prototype.hasOwnProperty.call(locationConfig, 'order_progress_bars')) {
      order_progress_bars = locationConfig.order_progress_bars;
    } else {
      order_progress_bars = [{ name: 'items_done/items_total' }, { name: 'parts_done/parts_total' }];
    }

    if (!order) {
      return '';
    }

    const itemsWithGroups = order.items.filter((item) => item.order_group);
    groups.sort((a, b) => { return a.id - b.id; });
    groups.forEach((group) => {
      group.items = itemsWithGroups.filter((item) => item.order_group === group.id);
    });

    order.items_info = `${order.items_done}/${order.items_total}`;
    order.parts_info = `${order.parts_done}/${order.parts_total}`;

    const statusOptions = [
      { value: 'created', name: t('page_content.orders.statuses.created') },
      { value: 'in_progress', name: t('page_content.orders.statuses.in_progress') },
      { value: 'in_warehouse', name: t('page_content.orders.statuses.in_warehouse') },
      { value: 'in_transit', name: t('page_content.orders.statuses.in_transit') },
      { value: 'finished', name: t('page_content.orders.statuses.finished') },
      { value: 'delivered', name: t('page_content.orders.statuses.delivered') },
      { value: 'scheduled', name: t('page_content.orders.statuses.scheduled') },
      { value: 'paused', name: t('page_content.orders.statuses.paused') },
      { value: 'partial_delivery', name: t('page_content.orders.statuses.partial_delivery') },
      { value: 'delivered_to_customer', name: t('page_content.orders.statuses.delivered_to_customer') },
      ordersArchivePermission ? { value: 'archived', name: archiveLabel || t('page_content.orders.statuses.archived') } : null,
    ].filter((option) => option !== null);

    const orderStatus = get(order, 'status', '');
    const disabledStatuses = ['cancelled', 'finished', 'in_progress'];

    if (isLoadingModules) {
      return (
        <div className="loaderWrapper">
          <ContentLoader />
        </div>
      );
    }

    return (
      <div className="industry-tab order-details fullscreen" style={{ boxSizing: 'border-box' }}>
        <main>
          <div className="order-details__breadcrumbs">
            <div>
              <Link
                to={`/${companyId}/industry/location/${locationId}/orders`}
                className="order-details__back-link"
              >
                {t('page_content.orders.order_details.order_list')}
              </Link>
              {'>'}
              <span>{order.external_id}</span>
              {order.customer_name ? '>' : ''}
              <span>{order.customer_name || ''}</span>
              {order.parent_orders.length
                ? (
                  <div style={{ fontSize: 13.6, fontWeight: 600 }}>
                    {t('page_content.orders.order_details.parent_orders')}
                    {' >'}
                    {
                      order.parent_orders.map((e) => (
                        <span
                          key={e.id}
                          style={{
                            marginRight: 0, textDecoration: 'underline', color: 'blue', cursor: 'pointer', fontSize: 13.6,
                          }}
                          onClick={() => { this.redirectToOrder(e.id); }}
                        >
                          {e.external_id}
                        </span>
                      ))
                    }
                  </div>
                )
                : null}
            </div>

            {
              can_cancel_order
              && (
                <Button
                  type="danger"
                  disabled={disabledStatuses.includes(orderStatus)}
                  onClick={this.openOrderCancellationModal}
                >
                  {t('page_content.orders.order_details.order_cancellation')}
                </Button>
              )
            }
          </div>
          <div className="order_technology_breadcrumbs">
            {
              order && order.department && order.department !== null && order.department.name && <div>{`${t('page_content.orders.order_details.department')}:  ${order.department.name}`}</div>
            }
            {
              order && order.technology && order.technology !== null && order.technology.name && <div>{`${t('page_content.orders.order_details.technology')}:  ${order.technology.name}`}</div>
            }
          </div>
          <div className="progress_bar_wrapper">
            {
              modules['Order circular bar positions'] && (order_progress_bars && order_progress_bars.some((o) => o.name === 'items_done/items_total'))
              && (
                <div className="progress_bar">
                  <CircularProgressbarWithChildren value={order.items_done && order.items_total ? (order.items_done / order.items_total) * 100 : 0}>
                    <div className="progress_bar_data">
                      <strong>{numberSeparatorFormat(i18n.language, order.items_done, 0, 0)}</strong>
                      <strong>/</strong>
                      <strong>{numberSeparatorFormat(i18n.language, order.items_total, 0, 0)}</strong>
                      <br />
                      <span className="progress_bar_data_label">{t('page_content.orders.order_details.position_bar')}</span>
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              )
            }
            {
              modules['Order circular bar components'] && (order_progress_bars && order_progress_bars.some((o) => o.name === 'parts_done/parts_total'))
              && (
                <div className="progress_bar">
                  <CircularProgressbarWithChildren value={order.parts_done && order.parts_total ? (order.parts_done / order.parts_total) * 100 : 0}>
                    <div className="progress_bar_data">
                      <strong>{numberSeparatorFormat(i18n.language, order.parts_done, 0, 0)}</strong>
                      <strong>/</strong>
                      <strong>{numberSeparatorFormat(i18n.language, order.parts_total, 0, 0)}</strong>
                      <br />
                      <span className="progress_bar_data_label">{t('page_content.orders.order_details.components_bar')}</span>
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              )
            }
            {
              (order_progress_bars && order_progress_bars.some((o) => o.name === 'erp_parts_done/parts_total'))
              && (
                <div className="progress_bar">
                  <CircularProgressbarWithChildren value={order.erp_parts_done && order.parts_total ? (order.erp_parts_done / order.parts_total) * 100 : 0}>
                    <div className="progress_bar_data">
                      <strong>{numberSeparatorFormat(i18n.language, order.erp_parts_done, 0, 0)}</strong>
                      <strong>/</strong>
                      <strong>{numberSeparatorFormat(i18n.language, order.parts_total, 0, 0)}</strong>
                      <br />
                      <span className="progress_bar_data_label">{t('page_content.orders.order_details.erp_bar')}</span>
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              )
            }
          </div>
          <Tabs selectedIndex={Number(selectedTab)} onSelect={this.handleTabSelect}>
            <TabList>
              {modules['Order details'] && <Tab>{t('page_content.orders.order_details.tabs.order_details')}</Tab>}
              {modules['Order groups'] && <Tab>{t('page_content.orders.order_details.tabs.order_groups')}</Tab>}
              {modules['Order parts'] && <Tab>{t('page_content.orders.order_details.tabs.order_parts')}</Tab>}
              {modules['Order production history'] && <Tab>{t('page_content.orders.order_details.tabs.production_history')}</Tab>}
              {modules['Order warehouse relocation'] && <Tab>{t('page_content.orders.order_details.tabs.warehouse_relocation')}</Tab>}
              {modules['Order saw data'] && <Tab>{t('page_content.orders.order_details.tabs.saw_data')}</Tab>}
              {modules['Order operation history'] && <Tab>{t('page_content.orders.order_details.tabs.operation_history')}</Tab>}
              {modules['Order audit log'] && <Tab>{t('page_content.orders.order_details.tabs.audit_log')}</Tab>}
              {modules['Order bookings'] && <Tab>{t('page_content.orders.order_details.tabs.bookings')}</Tab>}
              {modules['Order batches'] && <Tab>{t('page_content.orders.order_details.tabs.batches')}</Tab>}
              {modules['Order attachments'] && <Tab>{t('page_content.orders.order_details.tabs.attachments')}</Tab>}
              {modules['Order pallets'] && <Tab>{t('page_content.orders.order_details.tabs.order_pallets')}</Tab>}
              {modules['Order line plans'] && <Tab>{t('page_content.orders.order_details.tabs.line_plans')}</Tab>}
              {modules['Order goods movement'] && <Tab>{t('page_content.orders.order_details.tabs.order_goods_movement')}</Tab>}
              {modules['Order api events'] && <Tab>{t('page_content.orders.order_details.api_events.api_events')}</Tab>}
            </TabList>

            {
              modules['Order details'] &&
              <TabPanel>
                <OrderDetails
                  order={order}
                  modules={modules}
                  statuses={statuses}
                  companyId={companyId}
                  histories={histories}
                  isReadOnly={isReadOnly}
                  archiveLabel={archiveLabel}
                  orderTimeline={orderTimeline}
                  statusHistory={statusHistory}
                  status_change={status_change}
                  statusOptions={statusOptions}
                  isLoadingDetails={isLoadingDetails}
                  isLoadingHistory={isLoadingHistory}
                  onStatusChange={this.onStatusChange}
                  getLocationName={this.getLocationName}
                  setDetailsLoader={this.setDetailsLoader}
                  ordersArchivePermission={ordersArchivePermission}
                />
              </TabPanel>
            }
            {
              modules['Order groups']
              && (
                <TabPanel>
                  <OrderGroups
                    t={t}
                    i18n={i18n}
                    order={order}
                    groups={groups}
                    statuses={statuses}
                    companyId={companyId}
                    isReadOnly={isReadOnly}
                    locationId={locationId}
                    getOrderId={this.getOrderId}
                    statusOptions={statusOptions}
                    projectsEnabled={isProjectBased}
                    isLoadingDetails={isLoadingDetails}
                    getLocationName={this.getLocationName}
                    company_short_code={company_short_code}
                    refreshOrderTabs={this.refreshOrderTabs}
                    setDetailsLoader={this.setDetailsLoader}
                    firstLetterUpperCase={this.firstLetterUpperCase}
                    setInstancedGroupsForFilter={this.setInstancedGroupsForFilter}
                    order_groups_hidden_columns={order_groups_hidden_columns}
                  />
                </TabPanel>
              )
            }
            {
              modules['Order parts'] &&
              <TabPanel>
                {parts && <OrderParts
                  previous={previous}
                  next={next}
                  parts={parts}
                  countParts={countParts}
                  isLoadingParts={isLoadingParts}
                  selectedPartsOperator={selectedPartsOperator}
                  selectedPartsOrderName={selectedPartsOrderName}
                  selectedPartsStatus={selectedPartsStatus}
                  selectedPartsSnowflake={selectedPartsSnowflake}
                  isPartsModalOpen={isPartsModalOpen}
                  isLoadingPartsModal={isLoadingPartsModal}
                  partsModalData={partsModalData}
                  onPartsStatusChange={this.onPartsStatusChange}
                  closePartsModal={this.closePartsModal}
                  getPaginatedParts={this.getPaginatedParts}
                  openPartsModal={this.openPartsModal}
                  handleSortingParts={this.handleSortingParts}
                  onPartsSnowflakeChange={this.onPartsSnowflakeChange}
                  onPartsOperatorChange={this.onPartsOperatorChange}
                  onPartsOrderNameChange={this.onPartsOrderNameChange}
                  clearAllPartsFilters={this.clearAllPartsFilters}
                  displaySnowflakeModal={this.displaySnowflakeModal}
                />}
              </TabPanel>
            }
            {
              modules['Order production history'] &&
              <TabPanel>
                {
                  histories ?
                    <OrderProductionHistory
                      order={order}
                      histories={histories}
                      assetGroups={assetGroups}
                      nextHistory={nextHistory}
                      previousHistory={previousHistory}
                      isLoadingHistory={isLoadingHistory}
                      getLocationName={this.getLocationName}
                      selectedHistoryItem={selectedHistoryItem}
                      selectHistoryItem={this.selectHistoryItem}
                      selectedOrderHistory={selectedOrderHistory}
                      getPaginatedHistory={this.getPaginatedHistory}
                      statusHistoryModalData={statusHistoryModalData}
                      firstLetterUpperCase={this.firstLetterUpperCase}
                      handleHistorySorting={this.handleHistorySorting}
                      selectHistoryOperator={this.selectHistoryOperator}
                      clearHistoriesFilters={this.clearHistoriesFilters}
                      selectedHistoryOperator={selectedHistoryOperator}
                      selectedHistorySnowflake={selectedHistorySnowflake}
                      isStatusHistoryModalOpen={isStatusHistoryModalOpen}
                      openStatusHistoryModal={this.openStatusHistoryModal}
                      selectHistorySnowflake={this.selectHistorySnowflake}
                      selectedHistoryAssetGroup={selectedHistoryAssetGroup}
                      selectHistoryAssetGroup={this.selectHistoryAssetGroup}
                      closeStatusHistoryModal={this.closeStatusHistoryModal}
                      isLoadingStatusHistoryModal={isLoadingStatusHistoryModal}
                    /> : null
                }
              </TabPanel>
            }
            {
              modules['Order warehouse relocation'] &&
              <TabPanel>
                <OrderWarehouseRelocation
                  t={t}
                  getOrderId={this.getOrderId}
                  getLocationName={this.getLocationName}
                  order={order}
                  locationId={locationId}
                />
              </TabPanel>
            }
            {
              modules['Order saw data'] &&
              <TabPanel>
                <OrderSawData
                  count={count}
                  sawData={sawData}
                  nextSawData={nextSawData}
                  previousSawData={previousSawData}
                  isLoadingSawData={isLoadingSawData}
                  getPaginatedSawData={this.getPaginatedSawData}
                  handleSortingSawData={this.handleSortingSawData}
                />
              </TabPanel>
            }
            {
              modules['Order operation history'] &&
              <TabPanel>
                <Table
                  style={{ userSelect: 'text' }}
                  columns={[
                    {
                      Header: () => <span>{t('page_content.orders.order_details.operation_history_tab.table_column_operation_type')}</span>,
                      accessor: 'operation',
                      Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
                      style: {
                        cursor: 'default',
                      },
                    },
                    {
                      Header: () => <span>{t('page_content.orders.order_details.operation_history_tab.table_column_duration')}</span>,
                      accessor: 'time',
                      Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
                      style: {
                        cursor: 'default',
                      },
                    },
                    {
                      Header: () => <span>{t('page_content.orders.order_details.operation_history_tab.table_column_location')}</span>,
                      accessor: 'location',
                      Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
                      style: {
                        cursor: 'default',
                      },
                    },
                  ]}
                  data={order && order.data && order.data.operations ? order.data.operations : []}
                  minRows={0}
                  defaultPageSize={100}
                  noDataText=" "
                  showPagination={false}
                  sortable
                  manual={false}
                />
              </TabPanel>
            }
            {
              modules['Order audit log'] &&
              <TabPanel>
                <OrderAuditLog
                  companyId={companyId}
                  locationId={locationId}
                  isReadOnly={isReadOnly}
                  getOrderId={this.getOrderId}
                />
              </TabPanel>
            }
            {
              modules['Order bookings']
              && (
                <TabPanel>
                  <OrderBookings
                    order={order}
                    companyId={companyId}
                    isReadOnly={isReadOnly}
                    locationId={locationId}
                    locationCode={locationCode}
                    getOrderId={this.getOrderId}
                    getUserByID={this.getUserByID}
                    company_short_code={company_short_code}
                    refreshOrderTabs={this.refreshOrderTabs}
                    order_groups_hidden_columns={order_groups_hidden_columns}
                  />
                </TabPanel>
              )
            }
            {
              modules['Order batches'] &&
              <TabPanel>
                <OrderBatches
                  order={order}
                  companyId={companyId}
                  locationId={locationId}
                  getUserByID={this.getUserByID}
                />
              </TabPanel>
            }
            {
              modules['Order attachments'] &&
              <TabPanel>
                <OrderAttachements
                  count={count}
                  modalSizes={modalSizes}
                  isReadOnly={isReadOnly}
                  attachments={attachments}
                  isUploading={isUploading}
                  formError={this.state.formError}
                  nextAttachemnts={nextAttachemnts}
                  orderAttachments={orderAttachments}
                  isAttachmentEdit={isAttachmentEdit}
                  previousAttachments={previousAttachments}
                  onChangeAttachment={this.onChangeAttachment}
                  isAttachmentModalOpen={isAttachmentModalOpen}
                  openAttachmentModal={this.openAttachmentModal}
                  saveAttachmentModal={this.saveAttachmentModal}
                  closeAttachmentModal={this.closeAttachmentModal}
                  onChangeAttachmentNote={this.onChangeAttachmentNote}
                  onChangeAttachmentName={this.onChangeAttachmentName}
                  handleDeleteAttachment={this.handleDeleteAttachment}
                  isLoadingOrderAttachments={isLoadingOrderAttachments}
                  getPaginatedAttachments={this.getPaginatedAttachments}
                  handleSortingAttachments={this.handleSortingAttachments}
                />
              </TabPanel>
            }
            {
              modules['Order pallets'] && <TabPanel>
                <div>
                  <Pallets companyId={companyId} locationId={locationId} isReadOnly={isReadOnly} order={order} />
                </div>
              </TabPanel>
            }
            {
              modules['Order line plans'] &&
              <TabPanel>
                <LinePlans
                  locationId={locationId}
                  isReadOnly={isReadOnly}
                  getOrderId={this.getOrderId}
                  orderLinePlans={orderLinePlans}
                  companyShortCode={company_short_code}
                  getLocationName={this.getLocationName}
                  isLoadingLinePlans={isLoadingLinePlans}
                  getOrderLinePlans={this.getOrderLinePlans}
                />
              </TabPanel>
            }
            {
              modules['Order goods movement'] && <TabPanel>
                <div>
                  <GoodsMovement companyId={companyId} locationId={locationId} order={order} />
                </div>
              </TabPanel>
            }
                        {
              modules['Order api events'] &&
              <TabPanel>
                  <APIEvents
                    t={t}
                    order={order}
                    companyId={companyId}
                    isReadOnly={isReadOnly}
                    locationId={locationId}
                  />
              </TabPanel>
            }
          </Tabs>

          <Modal
            isOpen={showSnowflakeModal}
            handleClose={() => { this.setState({ showSnowflakeModal: false, snowflakeUrl: null }); }}
            size={modalSizes.large}
          >
            <img src={snowflakeUrl} alt="" />
          </Modal>

          {
            isOrderCancellationModalOpen
            && (
              <OrderCancellationModal
                t={t}
                locationId={locationId}
                getOrderId={this.getOrderId}
                isOrderCancellationModalOpen={isOrderCancellationModalOpen}
                closeOrderCancellationModal={this.closeOrderCancellationModal}
              />
            )
          }

        </main>
      </div>
    );
  }
}

IndustryLocationOrderDetails.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object,
  showPallets: PropTypes.bool,
  showBatches: PropTypes.bool,
  status_change: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isProjectBased: PropTypes.bool,
  can_cancel_order: PropTypes.bool,
  locationConfig: PropTypes.object,
  archiveLabel: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    locationConfig: get(state, 'app.location.config', null),
    showBatches: get(state, 'app.location.config.show_batches', false),
    showPallets: get(state, 'app.location.config.pallets_enabled', false),
    status_change: get(state, 'app.location.config.status_change', false),
    isProjectBased: get(state, 'app.companyConfig.config.project_based', false),
    can_cancel_order: get(state, 'app.location.config.can_cancel_order', false),
    showOrdersArchive: get(state, 'app.location.config.orders_archive', false),
    archiveLabel: get(state, 'app.companyConfig.config.archive_label', ''),
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(IndustryLocationOrderDetails)));
