/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Table } from 'shared';
import { withTranslation } from 'react-i18next';
import { modalSizes } from 'shared/constants';
import { ReactTableDefaults } from 'react-table';

import { getDepartmentSteps } from '../actions';

class StepPreviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productTypeObject: {
        id: null,
        company: '',
        code: '',
        tech_steps: [
          { step: '-', man_hours: 0, machine_hours: 0 },
          { step: '-', man_hours: 0, machine_hours: 0 },
          { step: '-', man_hours: 0, machine_hours: 0 },
          { step: '-', man_hours: 0, machine_hours: 0 },
          { step: '-', man_hours: 0, machine_hours: 0 },
        ],
      },
      allDepartmentTechnologies: [],
    };
  }

  async componentDidMount() {
    const { rowData } = this.props;
    const { technology } = rowData;

    if (technology) {
      const allDepartmentTechnologies = await this.fetchAllDepartmentTechnology(technology);
      this.setState({ allDepartmentTechnologies });
    }

    this.updateProductTypeObject();
  }

  async fetchAllDepartmentTechnology(technology) {
    try {
      const response = await getDepartmentSteps(technology?.department);
      return response?.data?.results || [];
    } catch (error) {
      console.error('Error fetching department technologies:', error);
      return [];
    }
  }

  updateProductTypeObject() {
    const { rowData } = this.props;
    const { technology } = rowData;
    const { allDepartmentTechnologies } = this.state;

    if (technology) {
      const steps = technology?.steps?.steps;
      const updatedTechSteps = steps?.map((step, index) => ({
        step: this.getTechStepValue(index, step, allDepartmentTechnologies),
        man_hours: this.getTechStepValue(index, 'man_hours'),
        machine_hours: this.getTechStepValue(index, 'machine_hours'),
      }));

      this.setState((prevState) => ({
        productTypeObject: {
          ...prevState.productTypeObject,
          id: rowData.id || prevState.productTypeObject.id,
          company: rowData.company || prevState.productTypeObject.company,
          code: rowData.code || prevState.productTypeObject.code,
          tech_steps: updatedTechSteps,
        },
      }));
    }
  }

  getTechStepValue(index, property, allDepartmentTechnologies) {
    const { rowData } = this.props;
    const { tech_steps } = rowData;
    const { productTypeObject } = this.state;

    if (tech_steps && tech_steps[index] && tech_steps[index][property] !== undefined) {
      return tech_steps[index][property];
    }

    if (allDepartmentTechnologies?.length > 0) {
      return allDepartmentTechnologies?.find((op) => op?.id === property?.operation)?.name || this.state.productTypeObject.tech_steps[index].step;
    }

    return productTypeObject.tech_steps[index][property];
  }

  handleHoursChange = (index, property, value) => {
    this.setState((prevState) => {
      const updatedTechSteps = [...prevState.productTypeObject.tech_steps];
      updatedTechSteps[index] = {
        ...updatedTechSteps[index],
        [property]: value,
      };

      return {
        productTypeObject: {
          ...prevState.productTypeObject,
          tech_steps: updatedTechSteps,
        },
      };
    });
  };

  render() {
    const { t, modalStatus, closeModal, rowData, handleProductChange } = this.props;
    const { productTypeObject } = this.state;
    const tableColumnConfig = [
      {
        Header: () => <span>{t('page_content.projects.products_tab.steps_modal.table_column_stepName')}</span>,
        Cell: (row) => <span className="step_preview_modal_input"><input value={productTypeObject.tech_steps[row.index].step} readOnly disabled /></span>,
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.steps_modal.table_column_manHours')}</span>,
        width: 150,
        Cell: (row) => <span className="step_preview_modal_input"><input pattern="\d+" value={productTypeObject.tech_steps[row.index].man_hours} onChange={(e) => this.handleHoursChange(row.index, 'man_hours', e.target.value.replace(/[^\d]/g, ''))} type="number" /></span>,
      },
      {
        Header: () => <span>{t('page_content.projects.products_tab.steps_modal.table_column_machineHours')}</span>,
        width: 150,
        Cell: (row) => <span className="step_preview_modal_input"><input value={productTypeObject.tech_steps[row.index].machine_hours} onChange={(e) => this.handleHoursChange(row.index, 'machine_hours', e.target.value)} type="number" /></span>,
      },
    ];

    const columnDefaults = { ...ReactTableDefaults.column, headerClassName: 'step_table_row_override_header', className: 'step_table_row_override' };

    const title = `${t('page_content.projects.products_tab.steps_modal.modal_title')} ${rowData.code}`;

    return (
      <Modal
        closeText={t('shared.modal.close_button_alt')}
        isOpen={modalStatus}
        size={modalSizes.medium}
        title={title}
        handleClose={() => {
          closeModal();
        }}
        handleSave={() => {
          handleProductChange(rowData.id, productTypeObject);
          closeModal();
        }}
      >
        <div className="step_preview_modal_container">
          <Table
            style={{ userSelect: 'text', overflowX: 'none' }}
            columns={tableColumnConfig}
            data={rowData?.technology?.steps?.steps || []}
            minRows={0}
            defaultPageSize={10}
            noDataText=" "
            showPagination={rowData?.technology?.steps?.steps?.length > 10}
            sortable={false}
            loading={false}
            column={columnDefaults}
          />
        </div>
      </Modal>
    );
  }
}

StepPreviewModal.propTypes = {
  t: PropTypes.func.isRequired,
  modalStatus: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  handleProductChange: PropTypes.func.isRequired,
};

export default withTranslation()(StepPreviewModal);
