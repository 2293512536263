import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { IconEdit, IconRemove } from 'shared/Icons';
import { Table, Button, Modal, TableButtons, ConfirmationModal } from 'shared';
import { getFolders, getPaginatedFolders, deleteFolder, addFolder, editFolder } from '../actions';

const Folders = ({ t, locationId, fetchFoldersOnSave, isReadOnly }) => {
  const [tableData, setTableData] = useState({
    isLoading: false,
    data: [],
    count: 0,
    next: null,
    previous: null,
    currentUrl: null,
  });
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  const [selectedAscDesc, setSelectedAscDesc] = useState('asc');
  const [selectedSort, setSelectedSort] = useState('folder');

  const [modal, setModal] = useState({
    id: null,
    isOpen: false,
    isLoading: false,
    folder: '',
  });

  const fetchFolders = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const filters = `&order_by=${asc}${selectedSort}`;

    getFolders(locationId, filters)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      })
      .catch(() => {
        setTableData(({
          isLoading: false,
          data: [],
          count: 0,
          next: null,
          previous: null,
        }));
      });
  };

  const fetchFoldersByUrl = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedFolders(url)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      })
      .catch(() => {
        setTableData(({
          isLoading: false,
          data: [],
          count: 0,
          next: null,
          previous: null,
        }));
      });
  };

  useEffect(() => {
    fetchFolders();
  }, []);

  const openFoldersModal = () => {
    setModal((prevState) => ({
      ...prevState,
      isOpen: true,
      id: null,
    }));
  };

  const closeFoldersModal = () => {
    setModal({
      id: null,
      isOpen: false,
      isLoading: false,
      name: '',
    });
  };

  const handleEditFolder = (folder) => {
    setModal({
      id: get(folder, 'original.id', ''),
      isOpen: true,
      isLoading: false,
      folder: get(folder, 'original.folder', ''),
    });
  };

  const handleShowConfirmationModal = (folder) => {
    setDeleteData(folder);
    setShowConfirmationDialog(true);
  };

  const handleDeleteFolder = () => {
    const folder = deleteData;

    deleteFolder(folder?.original?.id, locationId)
      .then(() => {
        closeFoldersModal();
        fetchFolders();
      });
    setModal((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    setShowConfirmationDialog(false);
  };

  useEffect(() => {
    fetchFolders();
  }, [selectedAscDesc, selectedSort]);

  const handleSorting = (sortData) => {
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
    setSelectedSort(sortData.id);
  };

  const handleModalChange = (key, value) => {
    setModal((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const saveFoldersModal = () => {
    const payload = {
      folder: modal.folder,
      location: locationId,
    };

    if (modal.id) {
      editFolder(locationId, modal.id, payload)
        .then(() => {
          fetchFolders();
          fetchFoldersOnSave();
          closeFoldersModal();
        });
    } else {
      addFolder(locationId, payload)
        .then(() => {
          fetchFolders();
          fetchFoldersOnSave();
          closeFoldersModal();
        });
    }
  };

  return (
    <div className="folders">
      <div className="filters">
        <Button type="add" disabled={isReadOnly} onClick={openFoldersModal}>{t('page_content.production_records.add_folder')}</Button>
      </div>
      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span>{t('page_content.production_records.folder')}</span>,
            accessor: 'folder',
            Cell: (row) => (row.value || '-'),
          },
          {
            Header: () => <span> </span>,
            sortable: false,
            width: 100,
            Cell: (folder) => <div style={{ margin: 'auto' }}><Button disabled={isReadOnly} onClick={() => handleEditFolder(folder)}><IconEdit width="12px" height="12px" /></Button></div>,
          },
          {
            Header: () => <span> </span>,
            sortable: false,
            width: 100,
            Cell: (folder) => <div style={{ margin: 'auto' }}><Button disabled={isReadOnly} type="delete" onClick={() => handleShowConfirmationModal(folder)}><IconRemove width="12px" height="12px" /></Button></div>,
          },
        ]}
        data={tableData.data || []}
        loading={tableData.isLoading}
        noDataText=" "
        showPagination={false}
        defaultPageSize={30}
        minRows={0}
        defaultSorted={[{ id: 'folder', desc: false }]}
        onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
      />
      <div style={{ float: 'right' }}>
        <TableButtons next={tableData.next} previous={tableData.previous} fetchFunction={fetchFoldersByUrl} count={tableData.count} />
      </div>

      <Modal
        isOpen={modal.isOpen}
        handleClose={closeFoldersModal}
        handleSave={saveFoldersModal}
      >
        <div className="modal_container">
          <div className="modal_row">
            <div className="modal_row">
              <div className="left_text">{t('page_content.production_records.folder')}:</div>
              <div className="right_input">
                <input onChange={(e) => handleModalChange('folder', e.target.value)} value={modal.folder} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmationModal
        itemName={deleteData?.original?.folder}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleDeleteFolder}
        type="warning"
      />
    </div>
  );
};

Folders.propTypes = {
  t: PropTypes.func.isRequired,
  fetchFoldersOnSave: PropTypes.func,
  isReadOnly: PropTypes.bool.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default Folders;
