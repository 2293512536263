import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import enhanceWithClickOutside from 'react-click-outside';
import { IconUser } from 'shared/Icons';
import { Notification } from 'shared';
import './styles.scss';

const profileMenuDomClass = 'profile-menu';
class ProfileMenu extends Component {
  constructor() {
    super();
    this.state = {
      isOpened: false,
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleUserNavigation = (e) => {
    e.preventDefault();
    const { companyId, history } = this.props;
    const source = e.target || e.srcElement;

    const getHref = (el) => {
      if (el.href) {
        return el.href;
      }
      return getHref(el.parentElement);
    };
    const url = new URL(getHref(source));
    const resource = url.pathname;

    if (companyId) {
      history.push(resource, { forced: true });
    } else {
      Notification('error', 'Access denied', 'Please choose a company location before continuing.');
    }
  }

  handleOpen() {
    this.setState({ isOpened: true });
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  handleClickOutside(e) {
    if (e.target.closest(`.${profileMenuDomClass}`) === null) {
      this.setState({ isOpened: false });
      document.removeEventListener('mousedown', this.handleClickOutside, false);
    }
  }

  handleLogout = () => {
    this.props.logout();
  }

  render() {
    const { companyId, t } = this.props;
    const { isOpened } = this.state;
    let locationId = '';

    locationId = window.location.href.split('location/')[1];
    if (locationId && locationId.includes('/')) {
      locationId = locationId.split('/')[0];
    }

    return (
      <div className={`${profileMenuDomClass} header-link`}>
        <div
          className="link"
          onClick={this.handleOpen}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
        >
          <IconUser
            height="30px"
            width="30px"
          />
        </div>
        {
          isOpened &&
          <ul>
            {
              false &&
              <li>
                <a>
                  Profile settings
                </a>
              </li>
            }
            <li>
              <a onClick={this.handleLogout}>
                {t('top_header.user_menu.logout')}
              </a>
            </li>
            <li onClick={() => this.setState({ isOpened: false })}>
              <NavLink
                to={`/${companyId}/industry/location/${locationId}/user-profile`}
                onClick={this.handleUserNavigation}
              >
                {t('top_header.user_menu.user_profile')}
              </NavLink>
            </li>
          </ul>
        }
      </div>
    );
  }
}

ProfileMenu.defaultProps = {
  logout: () => { },
};

ProfileMenu.propTypes = {
  history: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  t: PropTypes.func.isRequired,
};

export default withRouter((withTranslation()(ProfileMenu)));
