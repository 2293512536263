import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Modal, AssetTypePicker } from 'shared';
import { withTranslation } from 'react-i18next';
import { selectModalStyles } from '../../../../../../styles/modules/reactSelect';

class TimingsForm extends React.Component {
  constructor(props) {
    super();
    const { timingSetting } = props;
    if (timingSetting && timingSetting.id) {
      this.state = {
        timingSetting: {
          ...timingSetting,
        },
      };
    } else {
      this.state = {
        timingSetting: {
          cycle_setup_unit: 's',
          cycle_time: null,
          handling_setup_unit: 's',
          handling_time: null,
          item_setup_time: null,
          item_setup_unit: 's',
          line_setup_time: null,
          line_setup_unit: 's',
          product_type: null,
          asset: null,
          asset_type: null,
        },
      };
    }
  }

  onChange = (value, field) => {
    const {
      timingSetting,
    } = this.state;

    this.setState({
      timingSetting: {
        ...timingSetting,
        [field]: value,
      },
    });
  }

  onSaveTimingSettings = () => {
    const { timingSetting } = this.state;
    if (!timingSetting.asset) {
      alert('Please select an asset before saving the timings.');
      return;
    }
    if (!timingSetting.line_setup_time) {
      alert('Please enter line setup time.');
      return;
    }
    if (!timingSetting.line_setup_unit) {
      alert('Please enter line setup unit.');
      return;
    }
    if (!timingSetting.item_setup_time) {
      alert('Please enter item setup time.');
      return;
    }
    if (!timingSetting.item_setup_unit) {
      alert('Please enter item setup unit.');
      return;
    }
    if (!timingSetting.cycle_time) {
      alert('Please enter cycle setup time.');
      return;
    }
    if (!timingSetting.cycle_setup_unit) {
      alert('Please enter cycle setup unit.');
      return;
    }
    if (!timingSetting.handling_time) {
      alert('Please enter handling time.');
      return;
    }
    if (!timingSetting.handling_setup_unit) {
      alert('Please enter handling setup unit.');
      return;
    }

    const { onSave } = this.props;
    onSave(timingSetting);
  }

  changeTimingsAsset = (e) => {
    const selectedAssetId = parseInt(e.target.value.id, 10);
    const {
      timingSetting,
    } = this.state;
    this.setState({
      timingSetting: {
        ...timingSetting,
        asset: selectedAssetId,
      },
    });
  }

  changeTimingsProduct = (e) => {
    const selectedProductId = parseInt(e.target.value.id, 10);
    const {
      timingSetting,
    } = this.state;
    this.setState({
      timingSetting: {
        ...timingSetting,
        product_type: selectedProductId,
      },
    });
  }

  render() {
    const {
      // isSavingTimings,
      assets,
      productTypes,
      closeModal,
      isOpen,
      companyId,
      t,
    } = this.props;

    const {
      timingSetting,
    } = this.state;

    const sortAssets = (a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      } if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }

      return 0;
    };

    const defaultSortAssets = [{ id: '', name: '------' }, ...assets.sort(sortAssets)];
    const defaultProductTypes = [{ id: '', name: '------' }, ...productTypes];
    const defaultTimings = [{ id: 'ms', value: 'Miliseconds' }, { id: 'min', value: 'Minutes' }, { id: 's', value: 'Seconds' }];

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        handleSave={this.onSaveTimingSettings}
        handleClose={closeModal}
        title={timingSetting.id ? t('settings.timing_settings.timings_form.edit_title') : t('settings.timing_settings.timings_form.add_title')}
      >
        <div className="default-form timings-form">
          <table>
            <tbody>
              <tr>
                <td>
                  {t('settings.timing_settings.timings_form.asset')}
                </td>
                <td colSpan="2">
                  <Select
                    options={defaultSortAssets}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isSearchable={false}
                    menuPosition="fixed"
                    onChange={(value) => this.changeTimingsAsset({ target: { value } })}
                    value={(defaultSortAssets.find((defaultSort) => defaultSort.id === timingSetting.asset)) || ''}
                    styles={selectModalStyles}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t('settings.timing_settings.timings_form.asset_type')}
                </td>
                <td colSpan="2">
                  <AssetTypePicker
                    asset_type={timingSetting.asset_type}
                    handleChange={(assetType) => this.onChange(assetType ? assetType.id : null, 'asset_type')}
                    htmlId="type"
                    companyId={companyId}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {t('settings.timing_settings.timings_form.product')}
                </td>
                <td colSpan="2">
                  <Select
                    options={defaultProductTypes}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isSearchable={false}
                    menuPosition="fixed"
                    onChange={(value) => this.changeTimingsProduct({ target: { value } })}
                    value={(defaultProductTypes.find((defaultProductType) => defaultProductType.id === timingSetting.product_type)) || ''}
                    styles={selectModalStyles}
                  />
                </td>
              </tr>

              <tr>
                <td className="label">
                  {t('settings.timing_settings.timings_form.line_setup_time')}
                </td>
                <td className="value">
                  <input
                    type="number"
                    value={timingSetting.line_setup_time}
                    onChange={(e) => { this.onChange(e.target.value, 'line_setup_time'); }}
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('settings.timing_settings.timings_form.line_setup_unit')}
                </td>
                <td colSpan="2" className="value">
                  <Select
                    options={defaultTimings}
                    getOptionLabel={(option) => option.value}
                    getOptionValue={(option) => option.id}
                    isSearchable={false}
                    menuPosition="fixed"
                    onChange={(value) => { this.onChange(value.id, 'line_setup_unit'); }}
                    value={(defaultTimings.find((defaultTiming) => defaultTiming.id === timingSetting.line_setup_unit)) || ''}
                    styles={selectModalStyles}
                  />
                </td>
              </tr>

              <tr>
                <td className="label">
                  {t('settings.timing_settings.timings_form.item_setup_time')}
                </td>
                <td className="value">
                  <input
                    type="number"
                    value={timingSetting.item_setup_time}
                    onChange={(e) => { this.onChange(e.target.value, 'item_setup_time'); }}
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('settings.timing_settings.timings_form.item_setup_unit')}
                </td>
                <td colSpan="2" className="value">
                  <Select
                    options={defaultTimings}
                    getOptionLabel={(option) => option.value}
                    getOptionValue={(option) => option.id}
                    isSearchable={false}
                    menuPosition="fixed"
                    onChange={(value) => { this.onChange(value.id, 'item_setup_unit'); }}
                    value={(defaultTimings.find((defaultTiming) => defaultTiming.id === timingSetting.item_setup_unit)) || ''}
                    styles={selectModalStyles}
                  />
                </td>
              </tr>

              <tr>
                <td className="label">
                  {t('settings.timing_settings.timings_form.cycle_setup_time')}
                </td>
                <td className="value">
                  <input
                    type="number"
                    value={timingSetting.cycle_time}
                    onChange={(e) => { this.onChange(e.target.value, 'cycle_time'); }}
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('settings.timing_settings.timings_form.cycle_setup_unit')}
                </td>
                <td colSpan="2" className="value">
                  <Select
                    options={defaultTimings}
                    getOptionLabel={(option) => option.value}
                    getOptionValue={(option) => option.id}
                    isSearchable={false}
                    menuPosition="fixed"
                    onChange={(value) => { this.onChange(value.id, 'cycle_setup_unit'); }}
                    value={(defaultTimings.find((defaultTiming) => defaultTiming.id === timingSetting.cycle_setup_unit)) || ''}
                    styles={selectModalStyles}
                  />
                </td>
              </tr>

              <tr>
                <td className="label">
                  {t('settings.timing_settings.timings_form.handling_time')}
                </td>
                <td className="value">
                  <input
                    type="number"
                    value={timingSetting.handling_time}
                    onChange={(e) => { this.onChange(e.target.value, 'handling_time'); }}
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('settings.timing_settings.timings_form.handling_unit')}
                </td>
                <td colSpan="2" className="value">
                  <Select
                    options={defaultTimings}
                    getOptionLabel={(option) => option.value}
                    getOptionValue={(option) => option.id}
                    isSearchable={false}
                    menuPosition="fixed"
                    onChange={(value) => { this.onChange(value.id, 'handling_setup_unit'); }}
                    value={(defaultTimings.find((defaultTiming) => defaultTiming.id === timingSetting.handling_setup_unit)) || ''}
                    styles={selectModalStyles}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }
}

TimingsForm.propTypes = {
  timingSetting: PropTypes.object,
  // isSavingTimings: PropTypes.bool.isRequired,
  assets: PropTypes.array.isRequired,
  productTypes: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(TimingsForm);
