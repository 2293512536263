/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import Select from 'react-select';
import api from 'helpers/api';

class AdvancedAssetPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/prop-types
      selectedAssets: props.asset,
      options: [],
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;
    api.get(`/api/v1/industry/assets/?location=${locationId}&order_by=name&limit=200&company=${companyId}`)
      .then((json) => {
        this.setState({
          options: get(json, 'data.results'),
        });
      });
  }

  componentDidUpdate(prevProps) {
    const { asset, companyId, locationId } = this.props;
    if (prevProps.asset !== asset) {
      // eslint-disable-next-line
      this.setState({ selectedAssets: asset });
    }
    if (prevProps.locationId !== locationId) {
      api.get(`/api/v1/industry/assets/?location=${locationId}&order_by=name&limit=200&company=${companyId}`)
        .then((json) => {
          this.setState({
            options: get(json, 'data.results'),
          });
        });
    }
  }

  handleChange = (asset) => {
    const { handleChange } = this.props;
    this.setState({ selectedAssets: asset });
    handleChange(asset);
  }

  render() {
    const { selectedAssets, options } = this.state;
    // eslint-disable-next-line no-unused-vars
    const { disabled, htmlId, onBlur, customClassName, isMultiSelect, t } = this.props;

    const pickerStyles = {
      control: (provided) => ({
        ...provided,
        border: '2px solid #eee',
        borderRadius: '10px',
        height: '38px',
        maxHeight: '100px',
        overflowY: 'auto',
        color: '#8d8d8d',
        fontWeight: '400',
        fontSize: '14px',
        boxShadow: null,
        '&:hover': {
          borderColor: null,
          border: '2px solid #eee',
          borderRadius: '10px',
        },
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#77C7B8' : '#8d8d8d',
        backgroundColor: 'white',
        fontWeight: '400',
        fontSize: '14px',
        borderBottom: '2px solid #eee',
        ':active': {
          backgroundColor: 'white',
          color: '#77C7B8',
        },
        '&:hover': {
          backgroundColor: 'white',
          color: '#77C7B8',
        },
      }),
      menu: (provided) => ({
        ...provided,
        border: '2px solid #eee',
        borderRadius: '10px',
        zIndex: '99',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: '#8d8d8d',
        fontWeight: '400',
        fontSize: '14px',
      }),
    };

    return (
      <Select
        cacheOptions
        isDisabled={disabled}
        id={htmlId}
        isMulti={isMultiSelect}
        options={options}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        onBlur={onBlur}
        onChange={this.handleChange}
        placeholder={t('shared.asset_picker.placeholder')}
        value={isMultiSelect ? selectedAssets : selectedAssets ? (options === undefined ? [] : options).find((option) => option.id === selectedAssets) : null}
        className="MultiAssetPicker"
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        styles={pickerStyles}
      />
    );
  }
}

AdvancedAssetPicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  locationId: PropTypes.number.isRequired,
  htmlId: PropTypes.string,
  onBlur: PropTypes.func,
  customClassName: PropTypes.string,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

AdvancedAssetPicker.defaultProps = {
  disabled: false,
  customClassName: '',
};

export default withTranslation()(AdvancedAssetPicker);
