import React from 'react';
import './styles.scss';

const PageLoader = () => (
  <div className="page-loader">
    <div className="loader" />
  </div>
);

export default PageLoader;
