import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import {
  Button,
  ContentLoader,
  Notification,
} from 'shared';
import api from 'helpers/api';

import './styles.scss';

class AddDashboardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      isLoading: false,
      isPublic: false,
      isFavorite: false,
      description: '',
    };
  }

  componentDidMount() {
    const { id } = this.props;
    if (id) {
      this.fetchDashboardDetails(id);
    }
  }

  fetchDashboardDetails = (id) => {
    this.setState({ isLoading: true });
    api
      .get(`/api/v1/dashbaords/dashboard/${id}/`)
      .then(({ data }) => {
        this.setState({
          description: data.description,
          isPublic: data.public,
          isFavorite: data.is_favorite,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        return Notification('error', 'An error occurred while fetching dashboard details', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  handleSaveClick = () => {
    const {
      id,
      locationId,
    } = this.props;
    const {
      title,
      description,
      isFavorite,
      isPublic,
    } = this.state;
    const data = {
      title,
      description,
      is_favorite: isFavorite,
      public: isPublic,
    };

    if (locationId) {
      data.location = locationId;
    }
    this.setState({ isLoading: true });
    if (id) {
      api.patch(`/api/v1/dashboards/dashboard/${id}/`, data)
        .then((returnData) => {
          this.props.onClose();
          this.props.addOrUpdateDashboardSuccess(returnData.data);
        })
        .catch(() => {
          this.setState({
            isLoading: false,
          });
          Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
        });
    } else {
      data.widget_config = [];
      api.post('/api/v1/dashboards/dashboard/', data)
        .then((returnData) => {
          this.props.onClose();
          this.props.addOrUpdateDashboardSuccess(returnData.data);
        })
        .catch(() => {
          this.setState({
            isLoading: false,
          });
          Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
        });
    }
  }

  handleFavoriteChange = (e) => {
    this.setState({ isFavorite: e.target.checked });
  }

  render() {
    const {
      title,
      description,
      isFavorite,
      isLoading,
    } = this.state;
    const {
      id,
      onClose,
      t,
    } = this.props;

    return (
      <Modal
        isOpen
        onRequestClose={onClose}
        style={{ content: { padding: '0', top: '20px', left: '0', right: '0', bottom: 'initial' } }}
        shouldCloseOnOverlayClick={false}
      >
        <header>
          {t('page_content.dashboards.dashboard_modal.add_title')}
        </header>
        {
          isLoading &&
          <ContentLoader />
        }
        {
          !isLoading &&
          <div className="default-form dashboard-form">
            <div className="padded-20">
              <table>
                <tbody>
                  <tr>
                    <td className="label">
                      <label htmlFor="name">
                        {t('page_content.dashboards.dashboard_modal.name')}
                      </label>
                    </td>
                    <td className="input">
                      <input
                        id="name"
                        onChange={(e) => this.setState({ title: e.target.value })}
                        type="text"
                        value={title}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label htmlFor="favorite">
                        {t('page_content.dashboards.dashboard_modal.favorite')}
                      </label>
                    </td>
                    <td className="input">
                      <input
                        id="favorite"
                        type="checkbox"
                        value={isFavorite}
                        onChange={this.handleFavoriteChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="label">
                      <label htmlFor="description">
                        {t('page_content.dashboards.dashboard_modal.description')}
                      </label>
                    </td>
                    <td className="input">
                      <input
                        id="description"
                        type="text"
                        value={description}
                        onChange={(e) => this.setState({ description: e.target.value })}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <footer className="modal-footer">
              <Button
                disabled={!this.state.title}
                onClick={this.handleSaveClick}
                type="add"
              >
                {
                  id
                    ? 'Update dashboard'
                    : 'Add dashboard'
                }
              </Button>
              <Button
                onClick={onClose}
              >
                Cancel
              </Button>
            </footer>
          </div>
        }
      </Modal>
    );
  }
}

AddDashboardModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  locationId: PropTypes.number.isRequired,
  addOrUpdateDashboardSuccess: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

AddDashboardModal.defaultProps = {
  id: null,
  onClose: () => { },
  patchDashboard: () => { },
  postDashboard: () => { },
  addOrUpdateDashboardSuccess: () => { },
};

export default withTranslation()(AddDashboardModal);
