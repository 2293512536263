import api from 'helpers/api';
import { Notification } from 'shared';

// eslint-disable-next-line import/prefer-default-export
export const getShifts = async (locationId) => {
  try {
    const res = await api
      .get(`/api/v1/shifts/shifts/?location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching shifts', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
