import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Table, TableButtons } from 'shared';
import { defaultDateTimeFormat } from 'shared/constants';
import { getAuditLogContentTypes, getShiftAuditLogs, getPaginatedShiftAuditLogs } from './actions';

const action_string = [
  { id: 0, label: 'create' },
  { id: 1, label: 'update' },
  { id: 2, label: 'delete' },
  { id: 3, label: 'access' },
];

const ShiftAuditLogs = ({ t, companyId, locationId }) => {
  const [auditLogs, setAuditLogs] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [filters, setFilters] = useState({
    selectedAscDesc: 'desc',
    selectedSort: 'timestamp',
  });
  const [contentType, setContentType] = useState({
    shiftTimeContentTypeId: null,
    isLoading: true,
  });

  const fetchAuditLogs = () => {
    setAuditLogs((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    let apiFilters = `company_id=${companyId}&location_id=${locationId}&limit=20&order_by=${asc}${filters?.selectedSort}`;

    if (contentType?.shiftTimeContentTypeId) apiFilters += `&content_type=${contentType?.shiftTimeContentTypeId}`;

    getShiftAuditLogs(apiFilters)
      .then((res) => {
        setAuditLogs({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setAuditLogs((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchAuditLogContentTypes = () => {
    setContentType((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const apiFilters = 'model=shifttime';

    getAuditLogContentTypes(apiFilters)
      .then((res) => {
        const shiftTimeContentTypeId = get(res, 'data.results[0].id', null);
        setContentType({
          shiftTimeContentTypeId,
          isLoading: false,
        });
      })
      .catch(() => {
        setContentType((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedAuditLogs = (url) => {
    setAuditLogs((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedShiftAuditLogs(url)
      .then((res) => {
        setAuditLogs({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setAuditLogs((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchAuditLogContentTypes();
  }, []);

  useEffect(() => {
    if (contentType?.shiftTimeContentTypeId) {
      fetchAuditLogs();
    }
  }, [companyId, locationId, filters, contentType?.shiftTimeContentTypeId]);

  const handleSorting = (sortData) => {
    let sortKey = sortData.id;
    sortKey = sortKey.replace('.', '__');

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const getActionString = (actionId) => {
    return action_string.find((action) => action.id === actionId).label || '';
  };

  return (
    <div className="company-values">
      <div className="company-values__table">
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('settings.shifts.actor')}</span>,
              accessor: 'actor',
              Cell: (row) => `${row?.value?.first_name || ''} ${row?.value?.last_name || ''}`,
            },
            {
              Header: () => <span>{t('settings.shifts.action')}</span>,
              accessor: 'action',
              Cell: (row) => getActionString(row?.value) || '-',
            },
            {
              Header: () => <span>{t('settings.shifts.change_reason')}</span>,
              accessor: 'changes.change_reason',
              Cell: (row) => (row?.value && row?.value[1] ? row?.value[1] : '-'),
            },
            {
              Header: () => <span>{t('settings.shifts.timestamp')}</span>,
              accessor: 'timestamp',
              Cell: (row) => (row && row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
            },
          ]}
          minRows={0}
          noDataText=""
          selectedRow={null}
          defaultPageSize={100}
          showPagination={false}
          data={auditLogs.data || []}
          loading={auditLogs.isLoading}
          defaultSorted={[{ id: 'timestamp', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons
              next={auditLogs.next}
              count={auditLogs.count}
              previous={auditLogs.previous}
              fetchFunction={fetchPaginatedAuditLogs}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

ShiftAuditLogs.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ShiftAuditLogs;
