import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { IconRemove, IconEdit } from 'shared/Icons';
import { Table, TableButtons, Button, ConfirmationModal } from 'shared';

import { deleteProfession, getProfessions, getPaginatedProfessions } from '../../../actions';
import '../../../styles.scss';

import ProfessionsModal from './ProfessionsModal';

const ProfessionsTable = ({ t, companyId, userIsHR }) => {
  const [professions, setProfessions] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [professionsFilters, setProfessionsFilter] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'name',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchProfessions = () => {
    setProfessions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = professionsFilters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&order_by=${asc}${professionsFilters?.selectedSort}`;

    getProfessions(companyId, apiFilters)
      .then((res) => {
        setProfessions({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setProfessions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedProfessions = (url) => {
    setProfessions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedProfessions(url)
      .then((res) => {
        setProfessions({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setProfessions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchProfessions();
  }, [companyId, professionsFilters]);

  const handleShowConfirmationDialog = (row, e) => {
    e.stopPropagation();
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteProfession(deleteData?.original?.id, companyId);
    setShowConfirmationDialog(false);
    fetchProfessions();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setProfessionsFilter((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewProfession = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleEditProfession = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchProfessions();
  };

  return (
    <div className="professions-container">
      <div className="professions-container__table">
        <h3>{t('page_content.human_resources.workers.professions')}</h3>

        <div className="professions-container__action-buttons">
        <Button
          type="add"
          disabled={!userIsHR}
          onClick={handleAddNewProfession}
        >
          {t('page_content.human_resources.workers.add_new_profession')}
        </Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.workers.name')}</span>,
              accessor: 'name',
            },
            {
              Header: () => '',
              Cell: (row) => (
                <Button
                  disabled={!userIsHR}
                  onClick={() => handleEditProfession(row)}
                >
                  <IconEdit
                    height="14px"
                    width="14px"
                    fill="#4285F4"
                  />
                </Button>),
              width: 70,
              sortable: false,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              Cell: (row) => (
                <Button
                  type="delete"
                  disabled={!userIsHR}
                  onClick={(e) => handleShowConfirmationDialog(row, e)}
                >
                  <IconRemove width="12px" height="12px" />
                </Button>
              ),
              width: 70,
              sortable: false,
            },
          ]}
          minRows={0}
          noDataText=""
          selectedRow={null}
          defaultPageSize={100}
          showPagination={false}
          data={professions.data || []}
          loading={professions.isLoading}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
          handleClick={(rowInfo) => handleTableRowClick(get(rowInfo, 'original'))}
        />
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons
              next={professions.next}
              count={professions.count}
              previous={professions.previous}
              fetchFunction={fetchPaginatedProfessions}
            />
          </span>
        </div>
      </div>

      {modalData?.isOpen &&
      <ProfessionsModal
        isOpen={modalData?.isOpen}
        handleCloseModal={handleCloseModal}
        initialValues={modalData?.selectedItem}
        closeModalAndRefetch={closeModalAndRefetch}
      />}

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'original.name')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </div>
  );
};

ProfessionsTable.propTypes = {
  t: PropTypes.func.isRequired,
  userIsHR: PropTypes.bool.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default withTranslation()(ProfessionsTable);
