import React, { useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { modalSizes } from 'shared/constants';
import { Modal, ContentLoader } from 'shared';
import { getLocale } from 'shared/DatePicker/constants';
import { addWorkerSalary, editWorkerSalary } from '../../../actions';

const SalaryModal = ({ t, companyId, workerDetails, initialValues = {}, isOpen, handleCloseModal, closeModalAndRefetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      ...formValues,
      worker: workerDetails?.id,
    };

    if (formValues?.id) {
      await editWorkerSalary(companyId, formValues?.id, payload);
    } else {
      await addWorkerSalary(companyId, payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  return (
    <Modal
      size={modalSizes.medium}
      handleClose={handleCloseModal}
      handleSave={handleSave}
      isOpen={isOpen}
    >
      {
        isLoading ? <ContentLoader /> :
          <div className="asset_form_modal_container">
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.workers.salary_increase_table.table_column_date')}:</div>
              <div className="right_datePicker">
                <ReactDatePicker
                  placeholderText=""
                  dateFormat="dd.MM.yyyy"
                  selected={get(formValues, 'date', '') ? moment(get(formValues, 'date', '')).toDate() : null}
                  onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'), 'date')}
                  showTimeSelect={false}
                  disabledKeyboardNavigation
                  locale={getLocale(t)}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.workers.salary_increase_table.table_column_net_salary')}:</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'net_salary', '')} onChange={(e) => onChange(e.target.value, 'net_salary')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.workers.salary_increase_table.table_column_gross_salary')}:</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'gross_salary', '')} onChange={(e) => onChange(e.target.value, 'gross_salary')} />
              </div>
            </div>
          </div>
      }
    </Modal>
  );
};

SalaryModal.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  initialValues: PropTypes.object,
  isOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  workerDetails: PropTypes.object,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(SalaryModal));
