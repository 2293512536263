import { useEffect } from 'react';

// eslint-disable-next-line react/prop-types
const CustomCSS = ({ css, customLogo, customLoginLogo, customLoginBackground, customTitle, customFaviconLogo }) => {
  useEffect(() => {
    if (customTitle) {
      // Reset the title when the component unmounts
      document.title = customTitle;
    }

    // Create a <style> element and append the custom CSS
    const styleElement = document.createElement('style');
    styleElement.innerHTML = css;
    document.head.appendChild(styleElement);

    if (customFaviconLogo) {
      const faviconLink = document.querySelector('link[rel="icon"]');
      if (faviconLink) {
        faviconLink.href = customFaviconLogo;
      }
    }

    // Find all <img> elements with the alt attribute value "Ascalia logo" and update src if customLogo is provided
    if (customLogo) {
      const ascaliaLogoImages = document.querySelectorAll('img[alt="Ascalia logo"]');
      ascaliaLogoImages.forEach((img) => {
        // Check if the img is wrapped inside a div with the class "form-class"
        const parentDiv = img.parentElement;
        if (parentDiv.classList.contains('form-class')) {
          img.setAttribute('src', customLoginLogo || customLogo);
        }
      });
    }

    // Check if customLoginBackground is provided and apply the background image
    if (customLoginBackground) {
      const loginFormContainer = document.querySelector('.login-form-container');

      // Add a conditional check to ensure loginFormContainer exists before modifying the style
      if (loginFormContainer) {
        loginFormContainer.style.backgroundImage = `url(${customLoginBackground})`;
      }
    }

    // Clean up the <style> element, reset logo image src attributes, and reset background image when the component unmounts
    return () => {
      if (customTitle) {
      // Reset the title when the component unmounts
        document.title = 'Ascalia';
      }

      document.head.removeChild(styleElement);

      if (customLogo) {
        const ascaliaLogoImages = document.querySelectorAll('img[alt="Ascalia logo"]');
        ascaliaLogoImages.forEach((img) => {
          // Check if the img is wrapped inside a div with the class "form-class"
          const parentDiv = img.parentElement;
          if (parentDiv.classList.contains('form-class')) {
            img.setAttribute('src', '/ascalia_logo.svg'); // Replace with the default image src
          }
        });
      }

      if (customLoginBackground) {
        const loginFormContainer = document.querySelector('.login-form-container');

        // Add a conditional check before resetting the background image
        if (loginFormContainer) {
          loginFormContainer.style.backgroundImage = '';
        }
      }
    };
  }, [css, customLogo, customLoginBackground]);

  return null;
};

export default CustomCSS;
