import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from 'shared';
import { shiftTimesValidation } from 'shared/helpers';
import i18n from 'i18next';
import './style.scss';

const ShiftPicker = ({ shifts, handleShiftPickerChange }) => {
  const [currentShiftOrder, setCurrentShiftOrder] = useState(null);
  const [shiftOffset, setShiftOffset] = useState(null);
  const isTimeBetween = (startTime, endTime, time) => {
    if (startTime < endTime) {
      return time >= startTime && time <= endTime;
    }
    return time >= startTime || time <= endTime;
  };

  useEffect(() => {
    if (shifts && shifts.length && shiftOffset !== null) {
      const shiftTimesArray = [];
      shifts.forEach((shift) => {
        const shiftStartTime = moment(shift.default_begin, 'HH:mm');
        const shiftEndTime = moment(shift.default_end, 'HH:mm');
        if (isTimeBetween(shiftStartTime, shiftEndTime, moment())) {
          setCurrentShiftOrder(shift.order);
        }
        let shiftDuration = moment.duration(shiftEndTime.diff(shiftStartTime)).asHours();
        if (shiftStartTime > shiftEndTime) {
          shiftDuration = 24 + shiftDuration;
        }
        shiftTimesArray.push(shiftDuration);
      });
    }
  }, [shiftOffset]);

  useEffect(() => {
    if (!currentShiftOrder) {
      return;
    }
    let daysOffset = 0;
    const currentShiftIndex = currentShiftOrder - 1;
    const newShiftIndex = (((currentShiftIndex + shiftOffset) % shifts.length) + shifts.length) % shifts.length;
    const shiftStartDate = moment(shifts[newShiftIndex].default_begin, 'HH:mm');
    const shiftEndDate = moment(shifts[newShiftIndex].default_end, 'HH:mm');
    if (shiftStartDate.isAfter(shiftEndDate)) {
      shiftEndDate.add(1, 'day');
    }
    if (shiftOffset < 0) {
      for (let i = shiftOffset; i < 0; i++) {
        const prevShiftIndex = (((currentShiftIndex + i) % shifts.length) + shifts.length) % shifts.length;
        const prevShiftStart = moment(shifts[prevShiftIndex].default_begin, 'HH:mm');
        const prevShiftEnd = moment(shifts[prevShiftIndex].default_end, 'HH:mm');
        if (prevShiftStart.isAfter(prevShiftEnd)) {
          daysOffset--;
        }
      }
    } else if (shiftOffset > 0) {
      for (let i = 0; i < shiftOffset; i++) {
        const nextShiftIndex = (((currentShiftIndex + i) % shifts.length) + shifts.length) % shifts.length;
        const nextShiftStart = moment(shifts[nextShiftIndex].default_begin, 'HH:mm');
        const nextShiftEnd = moment(shifts[nextShiftIndex].default_end, 'HH:mm');
        if (nextShiftStart.isAfter(nextShiftEnd)) {
          daysOffset++;
        }
      }
    }
    shiftStartDate.add(daysOffset, 'days');
    shiftEndDate.add(daysOffset, 'days');
    handleShiftPickerChange(shiftStartDate.format('YYYY-MM-DDTHH:mm'), shiftEndDate.format('YYYY-MM-DDTHH:mm'));
  }, [shiftOffset, currentShiftOrder]);

  const handleShiftsDateFilter = (shiftType) => {
    if (shiftType === 'current') {
      setShiftOffset(0);
    }
    if (shiftType === 'next') {
      setShiftOffset((currentOffset) => currentOffset + 1);
    }
    if (shiftType === 'previous') {
      setShiftOffset((currentOffset) => currentOffset - 1);
    }
  };

  if (shifts && shifts.length > 0 && shiftTimesValidation(shifts)) {
    return (
      <div className="shifts_picker_container">
        <Button onClick={() => handleShiftsDateFilter('previous')}>{i18n.t('page_content.dashboards.time_range_dropdown.previous_shift')}</Button>
        <Button onClick={() => handleShiftsDateFilter('current')}>{i18n.t('page_content.dashboards.time_range_dropdown.current_shift')}</Button>
        <Button onClick={() => handleShiftsDateFilter('next')}>{i18n.t('page_content.dashboards.time_range_dropdown.next_shift')}</Button>
      </div>
    );
  } return <div />;
};

ShiftPicker.propTypes = {
  shifts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  handleShiftPickerChange: PropTypes.func.isRequired,
};

ShiftPicker.defaultProps = {
  shifts: [],
};

export default ShiftPicker;
