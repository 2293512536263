import React from 'react';
import { IconShifts, IconBarChart, IconWorkforce } from 'shared/Icons';

// eslint-disable-next-line import/prefer-default-export
export const getModuleIcon = (module) => {
  switch (module) {
    case 'shifts':
      return (<IconShifts height="20px" width="20px" />);

    case 'form':
      return (<IconWorkforce height="20px" width="20px" />);

    case 'dashboards':
      return (<IconBarChart height="20px" width="20px" />);

    case 'surveys.workersurvey':
      return (<IconWorkforce height="20px" width="20px" />);
    default:
      return '';
  }
};
