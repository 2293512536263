export const setInterval = 'SET_INTERVAL';
export const toggleSidebar = 'TOGGLE_SIDEBAR';
export const fetchPageMetaInit = 'FETCH_PAGE_META_INIT';
export const fetchPageMetaSuccess = 'FETCH_PAGE_META_SUCCESS';
export const fetchPageMetaError = 'FETCH_PAGE_META_ERROR';

export const patchCompany = 'APP_PATCH_COMPANY';
export const postCompany = 'APP_POST_COMPANY';

export const patchLocation = 'APP_PATCH_LOCATION';
export const postLocation = 'APP_POST_LOCATION';

export const setDateRange = 'APP_INDUSTRY_SET_DATE_RANGE';
export const setSmartCityDateRange = 'APP_SMART_CITY_SET_DATE_RANGE';

export const zoomOut = 'APP_INDUSTRY_ZOOM_OUT';

export const setUserIsCompanyAdmin = 'SET_USER_IS_COMPANY_ADMIN';

export const fetchCompanyConfigRequest = 'FETCH_COMPANY_CONFIG_REQUEST';
export const fetchCompanyConfigSuccess = 'FETCH_COMPANY_CONFIG_SUCCESS';
export const fetchCompanyConfigFailure = 'FETCH_COMPANY_CONFIG_FAILURE';

export const fetchLocationConfigRequest = 'FETCH_LOCATION_CONFIG_REQUEST';
export const fetchLocationConfigSuccess = 'FETCH_LOCATION_CONFIG_SUCCESS';
export const fetchLocationConfigFailure = 'FETCH_LOCATION_CONFIG_FAILURE';
