import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';
import Select from 'react-select';
import { getProductTypes, editOrderGroupItem } from '../../../actions';

class OrderGroupItemModal extends Component {
  constructor(props) {
    super();
    let product_type = null;
    let planned = '';
    let unit = '';

    if (props.selectedGroupItem) {
      const { selectedGroupItem } = props;
      product_type = selectedGroupItem.product_type && selectedGroupItem.product_type.id ? selectedGroupItem.product_type.id : null;
      planned = selectedGroupItem.quantity || '';
      unit = selectedGroupItem.unit_code || '';
    }
    this.state = {
      isLoading: true,
      product_type,
      planned,
      unit,
    };
  }

  componentDidMount() {
    const { companyId } = this.props;

    getProductTypes(companyId)
      .then((res) => {
        this.setState({
          product_types: get(res, 'data.results') || [],
          isLoading: false,
        });
      });
  }

  onFieldChange = (key, value) => {
    if (key === 'product_type') {
      this.setState({
        [key]: value.id,
        unit: value.base_unit,
      });
    } else if (key === 'planned') {
      value = (value === 'NaN' || !value) ? 0 : value;
      this.setState({
        [key]: this.formatInput(value),
      });
    } else {
      this.setState({
        [key]: value,
      });
    }
  }

  formatInput = (value) => {
    const { unit } = this.state;
    let formattedValue = value;

    if (['PCE', 'KKO', 'KOM'].includes(unit)) {
      formattedValue = parseInt(formattedValue, 10).toString();
    } else {
      formattedValue = parseFloat(value).toString();
    }

    return formattedValue;
  };

  handleEditItem = () => {
    const { selectedGroupItem, handleCloseGroupItemModal } = this.props;
    const {
      product_type,
      planned,
      unit,
      product_types,
    } = this.state;

    const selectedProductType = (product_types && product_types.find((pT) => pT.id === product_type)) || '';

    const data = {
      ...selectedGroupItem,
      name: selectedProductType && selectedProductType.name && selectedProductType.code ? `${selectedProductType.code} (${selectedProductType.name})` : '',
      product_type,
      quantity: planned,
      unit_code: unit,
      material_code: selectedProductType && selectedProductType.code ? selectedProductType.code : '',
    };

    editOrderGroupItem(data)
      .then(() => {
        handleCloseGroupItemModal();
      });
  }

  render() {
    const {
      product_type,
      planned,
      unit,
      product_types,
      isLoading,
    } = this.state;
    const {
      t,
      handleCloseGroupItemModal,
      isEditGroupItemModalOpen,
    } = this.props;

    if (isLoading) {
      return <ContentLoader />;
    }

    const sorted_product_types = product_types.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    const selectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '100%',
        minHeight: '34px',
        height: '36px',
        padding: 0,
        fontSize: '13px',
        color: '#555',
        marginRight: '5px',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0 10px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: 10,
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: 300,
        borderRadius: 0,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    return (
      <React.Fragment>
        <Modal
          size={modalSizes.medium}
          isOpen={isEditGroupItemModalOpen}
          handleClose={handleCloseGroupItemModal}
          handleSave={this.handleEditItem}
        >
          <div className="default-form">
            <table>
              <tbody>
                <tr>
                  <td className="label">
                    {t('page_content.orders.order_details.order_groups_tab.table_column_item_name')}
                  </td>
                  <td className="input">
                    <Select
                      options={sorted_product_types}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isSearchable
                      menuPosition="fixed"
                      onChange={(e) => this.onFieldChange('product_type', e)}
                      value={(sorted_product_types && sorted_product_types.find((pT) => pT.id === product_type)) || ''}
                      styles={selectStyles}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="label">
                    {t('page_content.orders.order_details.order_groups_tab.table_column_quantity')}
                  </td>
                  <td className="input">
                    <input
                      onChange={(e) => this.onFieldChange('planned', e.target.value)}
                      value={this.formatInput(planned)}
                      type="number"
                    />
                  </td>
                </tr>

                <tr>
                  <td className="label">
                    {t('page_content.orders.order_details.order_groups_tab.table_column_unit')}
                  </td>
                  <td className="input">
                    <input
                      value={unit}
                      type="text"
                      disabled
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </Modal>
      </React.Fragment>
    );
  }
}

OrderGroupItemModal.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleCloseGroupItemModal: PropTypes.func.isRequired,
  isEditGroupItemModalOpen: PropTypes.bool.isRequired,
  selectedGroupItem: PropTypes.object.isRequired,
};

export default OrderGroupItemModal;
