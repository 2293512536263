import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const PageHeader = ({ children }) => {
  return (
    <div className="page-header">
      {children}
    </div>
  );
};

PageHeader.propTypes = {
  children: PropTypes.any,
};

export default PageHeader;
