import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import { Modal } from 'shared';
import '../styles.scss';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { addNewProject } from '../actions';

const AddProjectModal = ({ t, addProjectModalState, handleSave, handleClose, projectDestinations, companyId, locationId }) => {
  const [destinations, setDestinations] = useState();
  const [stateValues, setStateValues] = useState({});

  useEffect(() => {
    if (projectDestinations.length) {
      setDestinations(projectDestinations);
    }
  }, [projectDestinations]);

  const handleInputChange = (value, type) => {
    setStateValues((prevState) => ({
      ...prevState,
      [type]: value,
    }
    ));
  };

  const handleInputChangeMetadata = (value, type) => {
    setStateValues((prevState) => ({
      ...prevState,
      metadata: {
        ...prevState.metadata,
        [type]: value,
      },
    }
    ));
  };

  const handleAddProject = async () => {
    const data = stateValues;
    data.company = companyId;
    data.location = locationId;
    await addNewProject(data);
    setStateValues({});
    handleSave();
  };

  return (
    <Modal disableSave={!stateValues.name} title={t('page_content.projects.add_new_project')} isOpen={addProjectModalState} handleClose={handleClose} handleSave={handleAddProject}>
        <div className="custom_data_modal_container">
            <div className="modal_row">
                <div className="left_text">{t('page_content.projects.table_column_name')} *</div>
                <div className="right_input">
                <input type="text" value={stateValues.name || ''} onChange={(e) => handleInputChange(e.target.value, 'name')} />
                </div>
            </div>
            <div className="modal_row">
                <div className="left_text">{t('page_content.projects.table_column_code')}</div>
                <div className="right_input">
                <input type="text" value={stateValues.code || ''} onChange={(e) => handleInputChange(e.target.value, 'code')} />
                </div>
            </div>
            <div className="modal_row">
                <div className="left_text">{t('page_content.projects.table_column_destination')}</div>
                <div className="right_select">
                <Select
                  options={destinations}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  menuPosition="fixed"
                  onChange={(d) => handleInputChange(d.id, 'destination')}
                  value={destinations && destinations.length ? (destinations.find((d) => d.id === stateValues.destination)) || '' : ''}
                  styles={selectModalStyles}
                />
                </div>
            </div>
            <div className="modal_row">
                <div className="left_text">{t('page_content.projects.table_column_address')}</div>
                <div className="right_input">
                <input type="text" value={stateValues.metadata ? stateValues.metadata.address || '' : ''} onChange={(e) => handleInputChangeMetadata(e.target.value, 'address')} />
                </div>
            </div>
            <div className="modal_row">
                <div className="left_text">{t('page_content.projects.table_column_number_of_floors')}</div>
                <div className="right_input">
                <input type="number" value={stateValues.metadata ? stateValues.metadata.floors || '' : ''} onChange={(e) => handleInputChangeMetadata(e.target.value, 'floors')} />
                </div>
            </div>
            <div className="modal_row">
                <div className="left_text">{t('page_content.projects.table_column_architect')}</div>
                <div className="right_input">
                <input type="text" value={stateValues.metadata ? stateValues.metadata.architect || '' : ''} onChange={(e) => handleInputChangeMetadata(e.target.value, 'architect')} />
                </div>
            </div>
        </div>
    </Modal>
  );
};

AddProjectModal.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  addProjectModalState: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  projectDestinations: PropTypes.array,
};

export default (withTranslation()(AddProjectModal));
