import api from 'helpers/api';
import { timeRangeEnum } from 'shared/DatePicker/constants';
import { Notification } from 'shared';

export const showAddWidget = (actionTypes) => () => {
  return {
    type: actionTypes.showAddWidget,
  };
};

// layouts
export const updateLayouts = (actionTypes) => ((layouts) => {
  return {
    type: actionTypes.setLayouts,
    payload: layouts,
  };
});

export const setLayouts = (actionTypes) => ({ dashboardId, layouts }) => ((dispatch) => {
  dispatch(updateLayouts(actionTypes)(layouts));
  if (!layouts) {
    return;
  }

  const layoutMapping = {};
  Object.keys(layouts).forEach((breakPoint) => {
    layouts[breakPoint].forEach((widgetLayout) => {
      const widgetId = widgetLayout.i;
      layoutMapping[widgetId] = layoutMapping[widgetId] || {};
      layoutMapping[widgetId][breakPoint] = {
        x: widgetLayout.x,
        y: widgetLayout.y,
        w: widgetLayout.w,
        h: widgetLayout.h,
      };
    });
  });

  const widgetIds = Object.keys(layoutMapping);
  widgetIds.sort((a, b) => a - b);
  const lastAddedWidgetId = widgetIds[widgetIds.length - 1];

  if (layoutMapping[lastAddedWidgetId].lg.w === 1 && layoutMapping[lastAddedWidgetId].lg.h === 1) {
    layoutMapping[lastAddedWidgetId].lg.w = 4;
    layoutMapping[lastAddedWidgetId].lg.h = 4;
  }

  return api
    .post(`/api/v1/dashboards/dashboard/${dashboardId}/layout`, {
      ...layoutMapping,
    })
    .then(
      () => { },
    );
});

export const addWidget = (actionTypes) => ((widgetData) => {
  return {
    type: actionTypes.addWidget,
    payload: widgetData,
  };
});

// remove widget
export const removeWidget = (actionTypes) => ((widgetId) => {
  return {
    type: actionTypes.removeWidget,
    payload: widgetId,
  };
});

export const deleteWidget = (actionTypes) => ({ dashboardId, widgetId }) => ((dispatch) => {
  return api
    .delete(`/api/v1/dashboards/dashboard/${dashboardId}/widget_configs/${widgetId}/`)
    .then(
      () => dispatch(removeWidget(actionTypes)(widgetId)),
      () => { },
    );
});

export const updateWidget = (actionTypes) => ((config) => {
  return {
    type: actionTypes.updateWidget,
    payload: config,
  };
});

export const editWidget = (actionTypes) => (config) => ((dispatch) => {
  return api
    .post('/api/v1/dashboards/widget_data_bulk/', config)
    .then((res) => dispatch(updateWidget(actionTypes)(res.data)));
});

const getMaxY = (items) => {
  let max = 0;
  items.forEach((item) => {
    if (item.y > max) {
      max = item.y;
    }
  });

  return max;
};

const generateLayoutForWidget = (layouts) => {
  layouts = { s: [], lg: [], ...layouts };
  const layout = {};
  Object.keys(layouts).forEach((key) => {
    const currentLayout = layouts[key];
    layout[key] = {
      h: key === 's' ? 1 : 4,
      w: key === 's' ? 1 : 4,
      x: 0,
      y: getMaxY(currentLayout),
    };
  });
  return layout;
};

export const createWidget = (actionTypes) => ({ dashboardId, type, layouts }) => ((dispatch) => {
  return api
    .post(`/api/v1/dashboards/dashboard/${dashboardId}/widget_configs/`, {
      type,
      dashboard: dashboardId,
      layouts: generateLayoutForWidget(layouts, type),
      widget_data: [],
    })
    .then(
      (res) => {
        dispatch(addWidget(actionTypes)(res.data));
        return res.data;
      },
      () => { },
    );
});

// fetch dashboard
const fetchDashboardSuccess = (actionTypes) => ((res) => {
  return {
    type: actionTypes.fetchDashboardSuccess,
    payload: res,
  };
});

const fetchDashboardError = (actionTypes) => () => {
  return {
    type: actionTypes.fetchDashboardError,
  };
};

const initFetchDashboard = (actionTypes) => () => {
  return {
    type: actionTypes.fetchDashboard,
  };
};

export const fetchDashboard = (actionTypes) => ({ dashboardId }) => ((dispatch) => {
  dispatch(initFetchDashboard(actionTypes)());
  return api.get(`/api/v1/dashboards/dashboard/${dashboardId}/`)
    .then(
      (res) => {
        if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data || !res.data.widget_config) {
          return (
            Notification('error', 'An error occurred while fetching dashboard', 'We could not perform your request, please try again.'),
            dispatch(fetchDashboardError(actionTypes)())
          );
        }
        dispatch(fetchDashboardSuccess(actionTypes)(res.data));
      },
    );
});

export const patchDashboardRangeChange = ({ dashboardId, label }) => () => {
  if (label === timeRangeEnum.custom || label === timeRangeEnum.nth_week || label === timeRangeEnum.customYesterday || label === timeRangeEnum.customToday) {
    return;
  }
  return api.patch(`/api/v1/dashboards/dashboard/${dashboardId}/`, {
    last_selected_range: label,
  });
};

export const updateManualRefresh = (actionTypes) => ((value) => {
  return {
    type: actionTypes.updateManualRefresh,
    payload: value,
  };
});

export const patchDashboard = (actionTypes) => ((data) => {
  return {
    type: actionTypes.patchDashboard,
    payload: data,
  };
});

export const copyWidget = (actionTypes) => (data) => ((dispatch) => {
  return api
    .post('/api/v1/dashboards/duplicate-widget/', { widget_id: data.widgetId, type: data.type, settings: data && data.settings ? data.settings : {} })
    .then(
      (res) => {
        dispatch(fetchDashboard(actionTypes)({ dashboardId: data.dashboardId }));
        return res.data;
      },
      () => { },
    );
});
