import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import api from 'helpers/api';
import Select from 'react-select';
import Tooltip from 'rc-tooltip';
import { Notification } from 'shared';
import { IconInfo } from 'shared/Icons';
import { groupBy, operations, singleMetricOperations } from '../constants';
import './styles.scss';

const groupByOptionsWithoutValue = groupBy.map((group) => group.value).filter((value) => value === 'auto' || value === 'none');

class CalculatedValueUsageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shifts: [],
      productTypes: [],
    };

    this.generateFieldOptions = this.generateFieldOptions.bind(this);
    this.updateField = this.updateField.bind(this);
  }

  componentDidMount() {
    this.getShifts();
    this.getProductTypes();
  }

  getShifts = () => {
    const { locationId } = this.props;
    api.get(`/api/v1/shifts/shifts/?location=${locationId}`)
      .then((re) => {
        if (re.status === undefined || re.status === 400 || re.status === 403 || re.status === 404 || re.status === 500 || !re || !re.data || !re.data.results) {
          return Notification('error', 'An error occurred while fetching shifts', 'We could not perform your request, please try again.');
        }
        this.setState({
          shifts: re.data.results,
        });
      });
  }

  getProductTypes = () => {
    const urlSplit = window.location.href.split('/');
    const companyIdIndex = urlSplit.indexOf('industry') - 1;
    const companyId = urlSplit[companyIdIndex];

    api.get(`/api/v1/product_types/?company=${companyId}`)
      .then((re) => {
        if (re.status === undefined || re.status === 400 || re.status === 403 || re.status === 404 || re.status === 500 || !re || !re.data || !re.data.results) {
          return Notification('error', 'An error occurred while fetching product types', 'We could not perform your request, please try again.');
        }
        this.setState({
          productTypes: re.data.results,
        });
      });
  }

  generateFieldOptions(metric) {
    return metric.field.split(',').map((f) => ({
      value: f,
      label: f,
    }));
  }

  updateField(field, value) {
    const {
      onChange,
      datapoint,
    } = this.props;

    if (field === 'formula') {
      datapoint.style.formula = value;
      onChange(datapoint);
      return;
    }

    onChange({ ...datapoint, [field]: value });
  }

  render() {
    const {
      datapoint,
      disabled,
      type,
      t,
    } = this.props;
    const { shifts, productTypes } = this.state;

    const {
      group_by,
      metric,
      operation,
      label,
      style,
    } = datapoint;
    const {
      updateAdjustedValue, updateWhereFilter, adjustedValue, where,
      gapFill, updateGapFill, shift, updateShiftFilter, productType, updateProductTypeFilter,
    } = this.props;

    const gapFillChecked = (gapFill);

    const formula = style.formula;

    let availableOperations = operations;
    if (['pie', 'scalar', 'gauge'].indexOf(type) >= 0) {
      availableOperations = singleMetricOperations;
    }

    const tooltipForWhereUsage = (
      <div>
        <h4>
          {t('page_content.dashboards.edit_widget_modal.calculated_values_tab.where_tooltip.title')}
        </h4>
        <ul>
          <li>{t('page_content.dashboards.edit_widget_modal.calculated_values_tab.where_tooltip.description_one')}</li>
          <li>{t('page_content.dashboards.edit_widget_modal.calculated_values_tab.where_tooltip.description_two')}</li>
          <li>{t('page_content.dashboards.edit_widget_modal.calculated_values_tab.where_tooltip.description_three')}</li>
          <li>{t('page_content.dashboards.edit_widget_modal.calculated_values_tab.where_tooltip.description_four')}</li>
        </ul>
        <p>
          {t('page_content.dashboards.edit_widget_modal.calculated_values_tab.where_tooltip.description_five')}
        </p>
      </div>
    );

    const tooltipForOperation = (
      <div>
        <p>
          <Trans components={{ br: <br /> }}>page_content.dashboards.edit_widget_modal.calculated_values_tab.operation_tooltip</Trans>
        </p>
      </div>
    );

    return (
      <div className={`calculated-value-form ${disabled ? 'disabled' : ''}`} style={{ marginTop: '20px' }}>
        <table>
          <tbody>
            <tr>
              <td>
                <label>
                  {t('page_content.dashboards.edit_widget_modal.calculated_values_tab.label')}
                </label>
              </td>
              <td colSpan="2" className="content-td">
                <input
                  onChange={(e) => this.updateField('label', e.target.value)}
                  value={label}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>
                  {t('page_content.dashboards.edit_widget_modal.calculated_values_tab.formula')}
                </label>
              </td>
              <td colSpan="2" className="content-td">
                <input
                  onChange={(e) => this.updateField('formula', e.target.value)}
                  value={formula}
                  type="text"
                  placeholder="Eg. Alias1 * Alias2"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>
                  {t('page_content.dashboards.edit_widget_modal.calculated_values_tab.shift')}
                </label>
              </td>
              <td className="content-td">
                <Select
                  clearable={false}
                  disabled={disabled}
                  onChange={(val) => updateShiftFilter(val.value)}
                  options={shifts.filter((s) => s.is_shift).map((o) => ({ label: o.name, value: o.id }))}
                  searchable={false}
                  value={shift ? shifts.map((o) => ({ label: o.name, value: o.id })).find((aO) => aO.value === shift.value) : null}
                  className="AscaliaSelect"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>
                  {t('page_content.dashboards.edit_widget_modal.calculated_values_tab.product_type')}
                </label>
              </td>
              <td className="content-td">
                <Select
                  clearable={false}
                  disabled={disabled}
                  onChange={(val) => updateProductTypeFilter(val.value)}
                  options={productTypes.map((o) => ({ label: o.name, value: o.id }))}
                  searchable={false}
                  value={productType ? productTypes.map((o) => ({ label: o.name, value: o.id })).find((aO) => aO.value === productType.value) : null}
                  className="AscaliaSelect"
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>
                  {t('page_content.dashboards.edit_widget_modal.calculated_values_tab.operation')}
                </label>
              </td>
              <td className="content-td">
                <Select
                  clearable={false}
                  disabled={disabled}
                  onChange={(val) => this.updateField('operation', val.value)}
                  options={availableOperations.map((o) => ({ label: o, value: o }))}
                  searchable={false}
                  value={operation ? availableOperations.map((o) => ({ label: o, value: o })).find((aO) => aO.value === operation) : null}
                  className="AscaliaSelect"
                />
              </td>
              {
                metric && metric.id ?
                  <td className="content-td"><div
                    style={{
                      display: 'inline-block',
                      width: '100%',
                      verticalAlign: 'middle',
                      boxSizing: 'border-box',
                      height: '33px',
                      border: '1px solid #ddd',
                    }}
                  >
                    <input
                      onChange={(e) => updateAdjustedValue(e.target.value)}
                      value={adjustedValue}
                      type="text"
                      placeholder={t('page_content.dashboards.edit_widget_modal.calculated_values_tab.adjusted_value_placeholder')}
                      style={{
                        boxSizing: 'border-box',
                        height: '100%',
                        border: 'none',
                      }}
                    />
                  </div></td> : ''
              }
              <td>
                <Tooltip
                  id="tooltip-calcval"
                  trigger={['hover']}
                  placement="right"
                  overlay={tooltipForOperation}
                  overlayClassName="where-filter-tooltip"
                >
                  <span
                    aria-describedby="tooltip-calcval"
                  >
                    <IconInfo
                      color="#2e86de"
                      height="14px"
                      width="16px"
                    />
                  </span>
                </Tooltip>
              </td>
            </tr>
            <tr>
              <td>
                <label>
                  {t('page_content.dashboards.edit_widget_modal.calculated_values_tab.group_by')}
                </label>
              </td>
              <td colSpan="2" className="content-td">
                <Select
                  className="AscaliaSelect"
                  clearable={false}
                  disabled={disabled}
                  onChange={(option) => {
                    const isCurrentOptionWithoutValue = group_by === 'auto' || group_by === 'none';
                    const isNewOptionWithValue = option.value !== 'auto' && option.value !== 'none';
                    // auto, none, [5 minutes] => val
                    let newValue = option.value;
                    // [auto, none] => 5 minutes
                    if (isCurrentOptionWithoutValue && isNewOptionWithValue) {
                      newValue = `0 ${option.value}`;
                    }
                    // 5 minutes => 5minutes
                    if (!isCurrentOptionWithoutValue && isNewOptionWithValue) {
                      newValue = `${group_by.split(' ')[0]} ${option.value}`;
                    }

                    this.updateField('group_by', newValue);
                  }}
                  options={groupBy}
                  searchable={false}
                  value={group_by ? groupBy.find((gB) => gB.value === (group_by.includes(' ') ? group_by.split(' ')[1] : group_by)) : null}
                />
                {
                  groupByOptionsWithoutValue.indexOf(group_by) < 0 &&
                  <input
                    onChange={(e) => {
                      this.updateField('group_by', groupByOptionsWithoutValue.indexOf(e.target.value) >= 0
                        ? e.target.value
                        : `${e.target.value} ${group_by.split(' ')[1]}`);
                    }}
                    value={groupByOptionsWithoutValue.indexOf(group_by) >= 0 ? group_by : (group_by.split(' ')[0] || 0)}
                    type="number"
                  />
                }
              </td>
            </tr>
            <tr>
              <td>
                <label>
                  {t('page_content.dashboards.edit_widget_modal.calculated_values_tab.where')}
                </label>
              </td>
              <td colSpan="2" className="content-td">
                <div
                  style={{
                    display: 'inline-block',
                    width: '100%',
                    verticalAlign: 'middle',
                    boxSizing: 'border-box',
                    height: '33px',
                    border: '1px solid #ddd',
                  }}
                >
                  <input
                    onChange={(e) => updateWhereFilter(e.target.value)}
                    value={where}
                    type="text"
                    placeholder={t('page_content.dashboards.edit_widget_modal.calculated_values_tab.where_placeholder')}
                    style={{
                      boxSizing: 'border-box',
                      height: '100%',
                      border: 'none',
                    }}
                  />
                </div>
              </td>
              <td>
                <Tooltip
                  id="tooltip-calcval"
                  trigger={['hover']}
                  placement="right"
                  overlay={tooltipForWhereUsage}
                  overlayClassName="where-filter-tooltip"
                >
                  <span
                    aria-describedby="tooltip-calcval"
                  >
                    <IconInfo
                      color="#2e86de"
                      height="14px"
                      width="16px"
                    />
                  </span>
                </Tooltip>
              </td>
            </tr>
            {
              updateGapFill && typeof updateGapFill === 'function' ?
                <tr>
                  <td>
                    <label>
                      {t('page_content.dashboards.edit_widget_modal.calculated_values_tab.fill_gaps')}
                    </label>
                  </td>
                  <td colSpan="2">
                    <div>
                      <input
                        onChange={(e) => updateGapFill(e.target.checked)}
                        defaultChecked={gapFillChecked}
                        type="checkbox"
                        style={{
                          width: '20px',
                          height: '20px',
                          marginLeft: '0',
                        }}
                      />
                    </div>
                  </td>
                </tr> : ''
            }
          </tbody>
        </table>
      </div>
    );
  }
}

CalculatedValueUsageForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  disabled: PropTypes.any,
  type: PropTypes.string.isRequired,
  datapoint: PropTypes.object,
  metric: PropTypes.any,
  updateAdjustedValue: PropTypes.func.isRequired,
  adjustedValue: PropTypes.object,
  where: PropTypes.object,
  updateWhereFilter: PropTypes.func.isRequired,
  gapFill: PropTypes.object,
  updateGapFill: PropTypes.func,
  shift: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateShiftFilter: PropTypes.func,
  productType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateProductTypeFilter: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(CalculatedValueUsageForm);
