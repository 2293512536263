import api from 'helpers/api';
import { Notification } from 'shared';

// eslint-disable-next-line import/prefer-default-export
export const getAssets = async (locationId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/assets/?location=${locationId}&order_by=name&limit=200`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching asset items', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPhotos = async (assetId, filters = '', numOfPhotos = 12) => {
  try {
    const res = await api
      .get(`/api/v1/product_photos/photos/?asset=${assetId}${filters}&limit=${numOfPhotos}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching photos', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPhotosUrl = (url) => {
  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred while fetching photos', 'We could not perform your request, please try again.');
    });
};

export const addToFavorite = (data, id = null) => {
  return api.patch(`/api/v1/product_photos/photos/${id}/`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const markDefect = (data, id = null) => {
  return api.patch(`/api/v1/product_photos/photos/${id}/`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getCompanyShortCode = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/companies/${companyId}/`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching companies', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
