/* eslint-disable  */
import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Button } from 'shared';
import { IconRemove } from 'shared/Icons';
import SankeySourceForm from './SankeySourceForm';
import { defaultSettings } from '../constants';
import '../styles.scss';
import SankeyTargetForm from './SankeyTargetForm';

class SankeyChartForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.config.id,
      sourceData: this.props.config.widget_data ? this.props.config.widget_data.filter((el) => el.style.source) : [],
      targetData: this.props.config.widget_data ? this.props.config.widget_data.filter((el) => el.style.source === false) : [],
      settings: props.config.settings || defaultSettings.sankey,
      title: props.config.title || '',
    };
  }

  addWidgetDataItem = () => {
    const sourceData = this.state.sourceData.slice(0);
    sourceData.push({
      drone: null,
      asset: null,
      metric: null,
      field: null,
      operation: 'avg',
      group_by: 'auto',
      style: {
        selectedField: null,
        source: true,
      },
    });

    const targetData = this.state.targetData.slice(0);
    targetData.push({
      drone: null,
      asset: null,
      metric: null,
      field: null,
      operation: 'avg',
      group_by: 'auto',
      style: {
        selectedField: null,
        source: false,
      },
    });
    this.setState({ targetData, sourceData });
  }

  updateWidgetDataItemSource = (numberInArray, widgetDataItem) => {
    const sourceData = this.state.sourceData.slice(0);
    sourceData[numberInArray] = widgetDataItem;
    this.setState({
      sourceData,
    });
  }

  updateWidgetDataItemTarget = (numberInArray, widgetDataItem) => {
    const targetData = this.state.targetData.slice(0);
    targetData[numberInArray] = widgetDataItem;
    this.setState({
      targetData,
    });
  }

  removeWidgetDataItem = (numberInArray) => {
    const sourceData = this.state.sourceData.slice(0);
    sourceData.splice(numberInArray, 1);

    const targetData = this.state.targetData.slice(0);
    targetData.splice(numberInArray, 1);
    this.setState({
      sourceData,
      targetData,
    });
  }

  saveWidget = () => {
    const sourceData = {
      source_data: this.state.sourceData.map((x) => {
        x.metric = (x.metric && x.metric.id) || x.metric;
        return x;
      }),
      id: this.state.id,
      title: this.state.title,
      settings: this.state.settings,
    };

    const targetData = {
      target_data: this.state.targetData.map((x) => {
        x.metric = (x.metric && x.metric.id) || x.metric;
        return x;
      }),
    };

    const widget_data = sourceData.source_data.concat(targetData.target_data);
    delete sourceData.source_data;
    delete targetData.target_data;

    const data = { ...sourceData, ...targetData, widget_data };
    this.props.onSave(data);
  }

  render() {
    const {
      title,
      settings,
      sourceData,
      targetData,
    } = this.state;
    const {
      locationId,
    } = this.props;

    const sortedByIdSource = sourceData.sort((a, b) => {
      return (a.id - b.id);
    });

    const sortedByIdTarget = targetData.sort((a, b) => {
      return (a.id - b.id);
    });

    return (
      <div className="default-form">
        <table className="widget-title">
          <tr>
            <td>
              <label htmlFor="widget-title">
                Widget title
              </label>
            </td>
            <td>
              <input
                id="widget-title"
                onChange={(e) => this.setState({ title: e.target.value })}
                placeholder="Enter widget title"
                type="text"
                value={title}
              />
            </td>
          </tr>
        </table>
        <Tabs>
          <TabList>
            <Tab>
              Add new pair
            </Tab>
            <Tab>
              Appearance
            </Tab>
          </TabList>
          <TabPanel>
            {
              sortedByIdSource.map((widgetDataItem, i) => (
                <div key={`datapoint-${i}`} className="datapoint-container">
                  <div style={{ display: 'block', width: '100%' }}>
                    <SankeySourceForm
                      id={i}
                      locationId={locationId}
                      datapoint={widgetDataItem}
                      onChange={(val) => this.updateWidgetDataItemSource(i, val)}
                    />
                    <SankeyTargetForm
                      id={i}
                      locationId={locationId}
                      datapoint={sortedByIdTarget[i]}
                      onChange={(val) => this.updateWidgetDataItemTarget(i, val)}
                    />
                  </div>
                  <Button
                    onClick={() => this.removeWidgetDataItem(i)}
                    size="small"
                    type="delete"
                  >
                    <IconRemove
                      height="14px"
                      width="14px"
                    />
                  </Button>
                </div>
              ))
            }
            {
              <Button
                onClick={this.addWidgetDataItem}
                type="add"
              >
                Add new pair
              </Button>
            }
          </TabPanel>
          <TabPanel>
            <table>
              <tbody>
                <tr>
                  <td>
                    Show tooltip
                  </td>
                  <td>
                    <input
                      checked={settings.appearance.tooltip}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          appearance: {
                            tooltip: {
                              $set: !settings.appearance.tooltip,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
        </Tabs>

        <footer className="modal-footer">
          <Button
            onClick={this.saveWidget}
            title="Save widget config"
            type="success"
            disabled={sourceData.length < 1 && targetData.length < 1}
          >
            Save changes
          </Button>
          <Button
            onClick={this.props.closeModal}
          >
            Cancel
          </Button>
        </footer>
      </div>
    );
  }
}

SankeyChartForm.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  locationId: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default SankeyChartForm;
