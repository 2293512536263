import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { defaultDateTimeFormat, modalSizes } from 'shared/constants';
import { Table, Button, Modal, ContentLoader } from 'shared';
import Select from 'react-select';
import { selectStyles } from 'styles/modules/reactSelect';
import moment from 'moment';
import { styledStatusOptions } from 'industry/helpers';
import './style.scss';
import { TableButtons } from '../../../../../shared/index';

class OrderProductionHistory extends Component {
  render() {
    const { t, order, histories, selectedHistoryItem, selectHistoryItem, selectHistoryAssetGroup, selectedHistoryAssetGroup, assetGroups, selectHistoryOperator, selectedHistoryOperator, selectHistorySnowflake, selectedHistorySnowflake, isStatusHistoryModalOpen, isLoadingStatusHistoryModal, statusHistoryModalData, selectedOrderHistory, isLoadingHistory, previousHistory, nextHistory, closeStatusHistoryModal, clearHistoriesFilters, firstLetterUpperCase, getLocationName, openStatusHistoryModal, handleHistorySorting, getPaginatedHistory, count } = this.props;
    return (
      <div className="orders_production_history_container">
        <div className="filters_row">
          <div className="element_text_wrapper">
            {`${t('page_content.orders.order_details.production_history_tab.items')}:`}
            <Select
              options={order && order.items}
              getOptionLabel={(item) => item.name}
              getOptionValue={(item) => item.id}
              isSearchable
              placeholder="All"
              onChange={(value) => selectHistoryItem(value.id)}
              value={(order && order.items.find((item) => item.id === selectedHistoryItem)) || ''}
              styles={selectStyles}
            />
          </div>
          <div className="element_text_wrapper">
            {`${t('page_content.orders.order_details.production_history_tab.asset')}:`}
            <Select
              options={assetGroups && assetGroups.results}
              getOptionLabel={(aGroup) => aGroup.name}
              getOptionValue={(aGroup) => aGroup.id}
              isSearchable
              placeholder="All"
              onChange={(value) => selectHistoryAssetGroup(value.id)}
              value={(assetGroups && assetGroups.results.find((aG) => aG.id === selectedHistoryAssetGroup)) || ''}
              styles={selectStyles}
            />
          </div>
          <div className="element_text_wrapper">
            {`${t('page_content.orders.order_details.production_history_tab.operator')}:`}
            <div className="input_container">
              <input type="text" value={selectedHistoryOperator} onChange={selectHistoryOperator} />
            </div>
          </div>
          <div className="element_text_wrapper">
            {`${t('page_content.orders.order_details.production_history_tab.snowflake')}:`}
            <div className="input_container">
              <input type="text" value={selectedHistorySnowflake} onChange={selectHistorySnowflake} />
            </div>
          </div>
          <div className="clear_all_button">
            <Button
              onClick={clearHistoriesFilters}
            >
              {t('page_content.orders.order_details.production_history_tab.clear_all_button')}
            </Button>
          </div>
        </div>
        <Modal
          isOpen={isStatusHistoryModalOpen}
          handleClose={() => {
            closeStatusHistoryModal();
          }}
          size={modalSizes.large}
          title={t('page_content.orders.order_details.production_history_tab.production_history_modal.production_history_title')}
        >
          {
            isLoadingStatusHistoryModal &&
            <ContentLoader />
          }
          {
            !isLoadingStatusHistoryModal && statusHistoryModalData &&
            <div className="table_container_items">
              <div className="left_container_items">
                <div>
                  <p className="title">{t('page_content.orders.order_details.production_history_tab.production_history_modal.id')}</p>
                  <p className="data"><strong>{statusHistoryModalData.id || '-'}</strong></p>
                </div>
                <div>
                  <p className="title">{t('page_content.orders.order_details.production_history_tab.production_history_modal.assets')}</p>
                  <p className="data"><strong>{statusHistoryModalData.asset_group.name || '-'}</strong></p>
                </div>
                <div>
                  <p className="title">{t('page_content.orders.order_details.production_history_tab.production_history_modal.operator')}</p>
                  <p className="data"><strong>{statusHistoryModalData.operater || '-'}</strong></p>
                </div>
              </div>
              <div className="right_container_items">
                <table>
                  <thead>
                    <tr>
                      <td className="title">{t('page_content.orders.order_details.production_history_tab.production_history_modal.status')}</td>
                      <td className="title">{t('page_content.orders.order_details.production_history_tab.production_history_modal.history')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !isLoadingStatusHistoryModal && selectedOrderHistory &&
                      selectedOrderHistory.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="data"><strong>{firstLetterUpperCase(item.status)}</strong></td>
                            <td className="data"><strong>{item.time ? moment(item.time).format(defaultDateTimeFormat) : '-'}</strong></td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }
        </Modal>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.orders.order_details.production_history_tab.table_column_position')}</span>,
              accessor: 'order_item.item_num',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.production_history_tab.table_column_item')}</span>,
              accessor: 'order_item.name',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.production_history_tab.table_column_asset')}</span>,
              accessor: 'asset_group.name',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.production_history_tab.table_column_location')}</span>,
              accessor: 'asset_group.location',
              Cell: (row) => <span>{row.value ? getLocationName(row.value) : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.production_history_tab.table_column_operators')}</span>,
              accessor: 'operator',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.production_history_tab.table_column_time')}</span>,
              accessor: 'created_at',
              Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
            },
            {
              Header: () => <span>{t('page_content.orders.order_details.production_history_tab.table_column_status')}</span>,
              accessor: 'order_item.status',
              Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>
                <span style={styledStatusOptions(row.value)}>{firstLetterUpperCase(t([`page_content.orders.statuses.${row.value}`]))}</span>
              </div>,
            },
          ]}
          data={histories}
          minRows={0}
          defaultPageSize={50}
          noDataText=" "
          showPagination={false}
          sortable
          loading={isLoadingHistory}
          handleClick={(rowInfo) => openStatusHistoryModal(rowInfo)}
          defaultSorted={[{ id: 'created_at', desc: false }]}
          onSortedChange={(newSorted) => { handleHistorySorting(newSorted[0]); }}
        />
        <div style={{ float: 'right' }}>
          <TableButtons previous={previousHistory} next={nextHistory} fetchFunction={getPaginatedHistory} count={count} />
        </div>
      </div>
    );
  }
}

OrderProductionHistory.propTypes = {
  t: PropTypes.func.isRequired,
  order: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  histories: PropTypes.array.isRequired,
  selectHistoryItem: PropTypes.func.isRequired,
  selectedHistoryItem: PropTypes.string,
  selectHistoryAssetGroup: PropTypes.func,
  selectedHistoryAssetGroup: PropTypes.string,
  assetGroups: PropTypes.object.isRequired,
  selectHistoryOperator: PropTypes.func.isRequired,
  selectedHistoryOperator: PropTypes.string,
  selectHistorySnowflake: PropTypes.func.isRequired,
  selectedHistorySnowflake: PropTypes.string,
  isStatusHistoryModalOpen: PropTypes.bool.isRequired,
  isLoadingStatusHistoryModal: PropTypes.bool.isRequired,
  statusHistoryModalData: PropTypes.object,
  selectedOrderHistory: PropTypes.array,
  isLoadingHistory: PropTypes.bool.isRequired,
  previousHistory: PropTypes.array,
  nextHistory: PropTypes.array,
  clearHistoriesFilters: PropTypes.func.isRequired,
  closeStatusHistoryModal: PropTypes.func.isRequired,
  firstLetterUpperCase: PropTypes.func.isRequired,
  getLocationName: PropTypes.func.isRequired,
  openStatusHistoryModal: PropTypes.func.isRequired,
  handleHistorySorting: PropTypes.func.isRequired,
  getPaginatedHistory: PropTypes.func.isRequired,
  count: PropTypes.number,
};

export default withTranslation()(OrderProductionHistory);
