import React from 'react';
import Base from './Base';

export default (props) => (<Base viewBox="0 0 50 50" {...props}>
  <g>
    <rect x="16.78" y="4.51" width="7.67" height="2.81" />
    <rect x="1.61" y="18.73" width="3" height="7.19" />
    <rect x="36.63" y="18.73" width="3" height="7.19" />
    <polygon points="36.63 11.54 39.63 11.54 39.63 4.51 32.13 4.51 32.13 7.32 36.63 7.32 36.63 11.54" />
    <polygon points="4.61 33.11 1.61 33.11 1.61 40.14 9.11 40.14 9.11 37.33 4.61 37.33 4.61 33.11" />
    <polygon points="37.69 36.38 39.63 36.66 49.11 38.02 23.35 18.82 28.89 46.84 32.13 40.13 33.02 38.27 34.35 40.14 40.76 49.21 44.93 46.62 39.63 39.12 37.69 36.38" />
    <rect x="16.78" y="37.33" width="7.67" height="2.81" />
    <polygon points="1.61 11.54 4.61 11.54 4.61 7.32 9.11 7.32 9.11 4.51 1.61 4.51 1.61 11.54" />
  </g>
</Base>);
