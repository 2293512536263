import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props} viewBox="0 0 400 400">
  <path fillRule="evenodd" clipRule="evenodd" d="M10 378V61H7V378V381H10H387V378H10Z" fill="#777777" />
  <path d="M25 281H55V381H25V281Z" fill="#777777" />
  <path d="M70 241H100V381H70V241Z" fill="#777777" />
  <path d="M115 195H145V381H115V195Z" fill="#777777" />
  <path d="M160 164H190V381H160V164Z" fill="#777777" />
  <path d="M205 212H235V381H205V212Z" fill="#777777" />
  <path d="M340 164H370V381H340V164Z" fill="#777777" />
  <path d="M250 233H280V381H250V233Z" fill="#777777" />
  <path d="M295 259H325V381H295V259Z" fill="#777777" />
  <path fillRule="evenodd" clipRule="evenodd" d="M368 84.5C368 91.4036 362.404 97 355.5 97C352.824 97 350.345 96.1593 348.312 94.7277L275.846 178.248C276.587 179.845 277 181.624 277 183.5C277 190.404 271.404 196 264.5 196C257.596 196 252 190.404 252 183.5C252 182.077 252.238 180.71 252.676 179.436L183.442 123.234C181.172 125.558 178.005 127 174.5 127C170.791 127 167.458 125.384 165.169 122.818L94.5923 186.123C96.1062 188.191 97 190.741 97 193.5C97 200.404 91.4036 206 84.5 206C81.2537 206 78.2964 204.762 76.0742 202.733L11.861 260.331C10.8332 261.253 9.25263 261.167 8.33071 260.139C7.40878 259.111 7.49463 257.531 8.52245 256.609L73.116 198.67C72.3992 197.095 72 195.344 72 193.5C72 186.596 77.5964 181 84.5 181C86.8273 181 89.006 181.636 90.8719 182.744L162.617 118.39C162.217 117.166 162 115.858 162 114.5C162 107.596 167.596 102 174.5 102C181.404 102 187 107.596 187 114.5C187 116.088 186.704 117.606 186.164 119.004L255.254 175.088C257.54 172.577 260.836 171 264.5 171C267.68 171 270.582 172.187 272.788 174.143L344.865 91.0713C343.682 89.162 343 86.9107 343 84.5C343 77.5964 348.596 72 355.5 72C362.404 72 368 77.5964 368 84.5Z" fill="#777777" />
  <path d="M10 290.926V381H387V210.333C387 210.333 347.067 157.5 299.033 189C251 220.5 193.473 276.704 193.473 276.704C161.638 270.383 80.3733 264.378 10 290.926Z" fill="#777777" fillOpacity="0.5" />  </Base>);
