import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import './styles.scss';
import { IconX, IconSearch } from 'shared/Icons';
import { Button } from 'shared';

const SelectedWorkersBox = ({ t, workers, handleRemoveWorker, handleRemoveDepartmentWorkers }) => {
  const [query, setQuery] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [workersData, setWorkersData] = useState(null);
  const [rawWorkersData, setRawWorkersData] = useState(null);

  const sortWorkerByDepartment = (data) => {
    const newData = {};
    if (data?.length) {
      data.forEach((worker) => {
        const { location_department } = worker;
        const keyValue = location_department?.name;
        if (!newData[keyValue]) {
          newData[keyValue] = [];
        }
        newData[keyValue].push(worker);
      });
    }
    setWorkersData(newData);
    setRawWorkersData(newData);
  };

  useEffect(() => {
    sortWorkerByDepartment(workers);
  }, [workers]);

  useEffect(() => {
    const div = document.querySelector('.selected_workers_content');
    const hasVerticalScrollbar = div?.scrollHeight > 150;
    if (!hasVerticalScrollbar) {
      setShowMore(false);
    }
    setShowMoreButton(hasVerticalScrollbar);
  }, [workersData]);

  const handleFindWorker = (e) => {
    const searchValue = e?.target?.value?.toLowerCase() || '';
    setQuery(searchValue);
    const filteredData = Object.values(rawWorkersData).flat().filter((worker) => worker.name.toLowerCase().includes(searchValue) ||
                            worker.last_name.toLowerCase().includes(searchValue));

    const newData = filteredData.reduce((acc, worker) => {
      const key = worker.location_department?.name;

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(worker);
      return acc;
    }, {});

    setWorkersData(newData);
  };

  const handleClearInput = () => {
    setQuery('');
    handleFindWorker('');
  };

  return (
    <div className="selected_workers_container">
        {workers?.length > 0 ? <div className="search_selected_worker">
            <div>{t('page_content.human_resources.forms.selected_workers')}</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input onChange={handleFindWorker} value={query} placeholder={t('page_content.human_resources.forms.find_selected_workers_placeholder')} />
              {query && <button
                onClick={handleClearInput}
              >&times;</button>}
              <div className="icon_container">
                <IconSearch
                  color="#555"
                  height="26px"
                  width="26px"
                />
            </div>
              </div>
        </div> : null}
        {workers?.length > 0 && (workersData && Object.keys(workersData).length > 0) ?
        <div style={{ maxHeight: showMore ? 500 : 250 }} className="selected_workers_content">
            {
            Object.entries(workersData).map(([key, worker]) => (
                <div className="selected_workers_section" key={key}>
                  <div className="selected_workers_section-department_title">
                    <div className="selected_workers_section-title_text">
                      {key}
                     {worker?.length && worker?.filter((w) => !w.isFixed)?.length ? <Button type="delete" onClick={() => handleRemoveDepartmentWorkers(key)} size="tiny">
                        <div className="icon_remove_all">
                          <IconX className="icon-style" width="10px" height="10px" />
                          </div>
                          </Button> : null}
                          </div>
                          <div className="selected_workers_section-line"> </div>
                  </div>
                  <div className="selected_workers_section-workers">
                  {worker?.length && worker.map((w, index) => (
                    <div className="selected_workers_content-card-style" key={index}>
                      <div className={w.isFixed ? 'worker_name worker_name_fixed' : 'worker_name'}>
                        {`${w.name} ${w.last_name}`}
                      </div>
                      {!w.isFixed && (
                        <div onClick={() => handleRemoveWorker(w)} className="icon">
                          <IconX className="icon-style" width="13px" height="13px" />
                        </div>
                      )}
                    </div>
                  ))}
                  </div>
                </div>))
}
    {showMoreButton &&
        <div onClick={() => setShowMore((prevState) => !prevState)} className="show_more_button">
            {showMore ? t('page_content.human_resources.forms.show_less') : t('page_content.human_resources.forms.show_more')}
        </div>}
    </div> : null}
    </div>
  );
};

SelectedWorkersBox.propTypes = {
  t: PropTypes.func.isRequired,
  workers: PropTypes.array,
  handleRemoveWorker: PropTypes.func,
  handleRemoveDepartmentWorkers: PropTypes.func,
};

export default (withRouter(withTranslation()(SelectedWorkersBox)));
