import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class AdminComponent extends Component {
  render() {
    const { currentUser } = this.props;

    if (!currentUser || (!currentUser.is_admin && !currentUser.is_staff)) {
      return null;
    }

    return (
      this.props.children
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

AdminComponent.propTypes = {
  children: PropTypes.any,
  currentUser: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(AdminComponent);
