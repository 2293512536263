import React, { Component } from 'react';
import api from 'helpers/api';
import { get } from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import { selectStyles } from 'styles/modules/reactSelect';
import ReactDatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';
import { getLocale } from 'shared/DatePicker/constants';
import { Table, Notification, Button, TableButtons } from 'shared';
import { defaultDateTimeFormat } from 'shared/constants';
import { IconSearch } from 'shared/Icons';
import { getAscaliaAuditLogs, getPaginatedAscaliaAuditLogs } from '../../actions';
import './style.scss';

const content_type_model = [
  { id: 1, name: 'dashboard' },
  { id: 2, name: 'lineplan' },
  { id: 3, name: 'maintenanceevent' },
];

class AscaliaAuditLogTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auditLogs: [],
      isLoadingAuditLogs: true,
      count: null,
      query: '',
      content_type: null,
      dateFilterStart: null,
      dateFilterEnd: null,
      selectedSort: 'timestamp',
      selectedAscDesc: 'desc',
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToSettingsPage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    this.getAuditLogs();
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    }, () => {
      this.getAuditLogs();
    });
  }

  handleSorting = (sortData) => {
    this.setState({ isLoadingAuditLogs: true });
    let column = sortData.id;

    if (column === 'action_string') {
      column = 'action';
    }

    if (column === 'content_type') {
      column = 'content_type_model';
    }

    if (column === 'actor') {
      column = 'actor__email';
    }

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.getAuditLogs();
    });
  }

  getAuditLogs = () => {
    const { locationId } = this.props;
    const {
      query,
      content_type,
      dateFilterStart,
      dateFilterEnd,
      selectedSort,
      selectedAscDesc,
    } = this.state;

    this.setState({
      isLoadingAuditLogs: true,
    });

    let filters = '';
    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort}`;
    if (query) {
      filters += `&object_repr=${query}`;
    }

    if (content_type) {
      filters += `&content_type_model=${content_type}`;
    }

    if (dateFilterStart) {
      filters += `&timestamp_after=${moment(dateFilterStart).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`;
    }

    if (dateFilterEnd) {
      filters += `&timestamp_before=${moment(dateFilterEnd).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`;
    }
    getAscaliaAuditLogs(locationId, filters)
      .then((re) => {
        this.setState({
          count: re && re.data ? re.data.count : null,
          auditLogs: get(re, 'data.results'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingAuditLogs: false,
        });
      }).catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  getUserByID = (user, userFields = ['email']) => {
    if (user !== null && typeof user === 'object') {
      const userFieldsMerged = userFields.map((userField) => {
        if (Object.prototype.hasOwnProperty.call(user, userField)) {
          return user[userField];
        }
        return '';
      });
      return userFieldsMerged.join(' ');
    }
    return '';
  }

  fetchByUrl = (url) => {
    this.setState({
      isLoadingAuditLogs: true,
    });

    getPaginatedAscaliaAuditLogs(url)
      .then((re) => {
        const auditLogs = get(re, 'data.results');
        this.setState({
          count: re && re.data ? re.data.count : null,
          auditLogs,
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingAuditLogs: false,
        });
      }).catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  exportToExcel = () => {
    const { count, dateFilterStart, dateFilterEnd } = this.state;

    let filters = '';

    if (dateFilterStart) {
      filters += `&timestamp_after=${moment(dateFilterStart).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`;
    }

    if (dateFilterEnd) {
      filters += `&timestamp_before=${moment(dateFilterEnd).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`;
    }

    api.get(`/api/v1/reports/audit_logs/?order_by=-timestamp&limit=50${filters}&format=xlsx&limit=${count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'audit_logs.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  clearFilters = () => {
    this.setState({
      query: '',
      content_type: '',
      dateFilterStart: null,
      dateFilterEnd: null,
    }, () => { this.getAuditLogs(); });
  }

  handleClear(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      query: '',
    }, () => { this.getAuditLogs(); });
  }

  render() {
    const { t } = this.props;

    const {
      auditLogs,
      isLoadingAuditLogs,
      next,
      previous,
      query,
      content_type,
      dateFilterStart,
      dateFilterEnd,
      count,
    } = this.state;

    return (
      <div className="ascalia-audit-log-container">
        <div className="filter_row">
          <div className="input_container">
            <input
              onChange={(e) => { this.onFilterChange('query', e.target.value); }}
              value={query}
              type="text"
            />
            {query && <button
              onClick={(e) => {
                this.handleClear(e);
              }}
            >&times;</button>}
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>

          <Select
            options={content_type_model}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            placeholder={t('settings.ascalia_audit_log.select_placeholder')}
            onChange={(opt) => this.onFilterChange('content_type', opt.name)}
            value={(content_type_model.find((cT) => cT.name === content_type)) || ''}
            styles={selectStyles}
          />

          <div className="datepicker_container">
            <div className="datepicker_wrapper">
              <ReactDatePicker
                placeholderText={t('settings.ascalia_audit_log.select_start_date_placeholder')}
                dateFormat="dd.MM.yyyy"
                selected={dateFilterStart ? moment(dateFilterStart).toDate() : null}
                maxDate={dateFilterEnd ? moment(dateFilterEnd).toDate() : null}
                selectsStart
                onChange={(e) => this.onFilterChange('dateFilterStart', e)}
                showTimeSelect={false}
                disabledKeyboardNavigation
                locale={getLocale(t)}
              />
            </div>
            <div className="datepicker_wrapper">
              <ReactDatePicker
                placeholderText={t('settings.ascalia_audit_log.select_end_date_placeholder')}
                dateFormat="dd.MM.yyyy"
                selected={dateFilterEnd ? moment(dateFilterEnd).toDate() : null}
                minDate={dateFilterStart ? moment(dateFilterStart).toDate() : null}
                selectsEnd
                onChange={(e) => this.onFilterChange('dateFilterEnd', e)}
                showTimeSelect={false}
                disabledKeyboardNavigation
                locale={getLocale(t)}
              />
            </div>
          </div>
          <Button onClick={this.clearFilters}>
            {t('shared.clear_button')}
          </Button>

          <span className="export_button"><Button type="export" onClick={this.exportToExcel}>{t('settings.ascalia_audit_log.export_to_excel_button')}</Button></span>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('settings.ascalia_audit_log.table_column_actor')}</span>,
              accessor: 'actor',
              Cell: (row) => (row && row.value ? this.getUserByID(row.value) : '-'),
            },
            {
              Header: () => <span>{t('settings.ascalia_audit_log.table_column_action')}</span>,
              accessor: 'action_string',
              Cell: (row) => (row && row.value ? row.value : '-'),
            },
            {
              Header: () => <span>{t('settings.ascalia_audit_log.table_column_app_label')}</span>,
              accessor: 'content_type',
              Cell: (row) => (row && row.value && row.value.app_label ? row.value.app_label : '-'),
            },
            {
              Header: () => <span>{t('settings.ascalia_audit_log.table_column_location')}</span>,
              accessor: 'object_repr',
              Cell: (row) => (row && row.value ? row.value : '-'),
            },
            {
              Header: () => <span>{t('settings.ascalia_audit_log.table_column_timestamp')}</span>,
              accessor: 'timestamp',
              Cell: (row) => (row && row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
            },
          ]}
          data={auditLogs || []}
          defaultSorted={[{ id: 'timestamp', desc: true }]}
          onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          loading={isLoadingAuditLogs}
          noDataText=" "
          defaultPageSize={50}
          showPagination={false}
          minRows={0}
        />
        <div style={{ float: 'right' }}>
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchByUrl} count={count} />
        </div>
      </div>);
  }
}

AscaliaAuditLogTab.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AscaliaAuditLogTab);
