import React, { useState } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';

import { addIndividualGoalTemplate, editIndividualGoalTemplate } from '../../../actions';

const IndividualGoalsModal = ({ t, initialValues = {}, isOpen, handleCloseModal, closeModalAndRefetch, departments, currentUser, companyId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    if (formValues?.name) {
      setIsLoading(true);
      const payload = {
        ...formValues,
        name: formValues?.name || null,
        user: currentUser?.id,
        department: formValues?.department || null,
      };

      if (formValues?.id) {
        await editIndividualGoalTemplate(companyId, formValues?.id, payload);
      } else {
        await addIndividualGoalTemplate(companyId, payload);
      }

      setIsLoading(false);
      closeModalAndRefetch();
    }
  };

  return (
    <Modal
      title={formValues?.id ?
        t('page_content.human_resources.control_panel.individual_goals.modal_edit_title_individual_goals') :
        t('page_content.human_resources.control_panel.individual_goals.modal_add_title_individual_goals')}
      disableSave={!formValues?.department || !formValues?.name}
      handleClose={handleCloseModal}
      size={modalSizes.large}
      handleSave={handleSave}
      isOpen={isOpen}
    >
      {
        isLoading ? <ContentLoader /> :
          <div className="asset_form_modal_container">
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.control_panel.individual_goals.column_name_individual_goals')}: *</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'name', '')} onChange={(e) => onChange(e.target.value, 'name')} />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.control_panel.individual_goals.column_name_individual_department')}: *</div>
              <div className="right_select">
                <Select
                  options={departments}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  menuPosition="fixed"
                  placeholder={t('page_content.human_resources.control_panel.individual_goals.modal_select_placeholder_department_individual_goals')}
                  onChange={(value) => onChange(value.id, 'department')}
                  value={departments?.find((a) => a.id === formValues?.department) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
          </div>
      }
    </Modal>
  );
};

IndividualGoalsModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  departments: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default (withTranslation()(IndividualGoalsModal));
