import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Modal, Table } from 'shared';
import { modalSizes } from 'shared/constants';
import { withTranslation } from 'react-i18next';
import { getBomChildren } from '../actions';

class ProjectPartsModal extends Component {
  constructor() {
    super();
    this.state = {
      activeBomLayer: 0,
      bomChildrenData: [],
      isLoadingTable: false,
      breadcrumbs: [],
    };
  }

  fetchItemChildren = (parentBom) => {
    const { bomChildrenData, activeBomLayer } = this.state;
    const { companyId, locationId } = this.props;

    this.setState({ isLoadingTable: true });

    getBomChildren(companyId, locationId, null, parentBom)
      .then((res) => {
        const newBomChildrenData = [...bomChildrenData];
        newBomChildrenData[activeBomLayer] = res.data.results;

        this.setState({
          bomChildrenData: newBomChildrenData,
          isLoadingTable: false,
        });
      });
  }

  handleBreadcrumbClickParent = () => {
    this.setState({
      bomChildrenData: [],
      breadcrumbs: [],
      activeBomLayer: 0,
    });
  }

  handleBreadcrumbClick = (bom) => {
    const { breadcrumbs, bomChildrenData } = this.state;

    const id = bom?.id || null;
    const index = breadcrumbs.findIndex((obj) => obj.id === id);

    if (index !== -1) {
      const updatedBreadcrumbs = breadcrumbs.slice(0, index + 1);
      const updatedBomChildrenData = bomChildrenData.slice(0, index + 1);

      this.setState({
        bomChildrenData: updatedBomChildrenData,
        breadcrumbs: updatedBreadcrumbs,
        activeBomLayer: index + 1,
      });
    }
  };

  render() {
    const { modalStatus, closeModal, isLoading, data, t, fatherBreadcrumb } = this.props;
    const { activeBomLayer, bomChildrenData, isLoadingTable, breadcrumbs } = this.state;

    return (
      <Modal
        closeText={t('shared.modal.close_button_alt')}
        isOpen={modalStatus}
        handleClose={() => {
          this.setState({
            activeBomLayer: 0,
            bomChildrenData: [],
            isLoadingTable: false,
            breadcrumbs: [],
          }, () => closeModal());
        }}
        size={modalSizes.full}
        title={t('page_content.projects.project_parts_modal.modal_title')}
      >
        <div style={{ padding: '1rem' }}>
        <div className="parts_modal_breadcrumbs">
          <span
            onClick={this.handleBreadcrumbClickParent}
            style={activeBomLayer === 0 ? { color: 'gray' } : { color: 'blue' }}
          >
            {`${fatherBreadcrumb}`}</span>

          {breadcrumbs.map((bom, index) => <React.Fragment>
          <span>{'> '}</span>
          <p
            key={bom.id}
            style={activeBomLayer === index + 1 ? { color: 'gray' } : { color: 'blue' }}
            onClick={() => this.handleBreadcrumbClick(bom)}
          >
            {bom.external_id}</p>
          </React.Fragment>)}
          </div>
          <Table
            style={{ userSelect: 'text', overflowX: 'scroll' }}
            columns={
              [
                {
                  Header: () => <span>{t('page_content.projects.project_parts_modal.sub_parts')}</span>,
                  Cell: (row) => {
                    const hasChildItems = activeBomLayer === 0 ?
                      get(data, `[${row.index}].bom_childs.length`, 0) > 0 :
                      get(bomChildrenData, `[${activeBomLayer - 1}][${row.index}].bom_childs.length`, 0) > 0;

                    return (
                      hasChildItems ?
                      <div
                        className="parts_modal_showChildren_button_yes"
                        onClick={() => {
                          this.setState((prevState) => ({
                            activeBomLayer: prevState.activeBomLayer + 1,
                            breadcrumbs: [
                              ...prevState.breadcrumbs,
                              activeBomLayer === 0 ? data[row.index] : bomChildrenData[activeBomLayer - 1][row.index],
                            ],
                          }));
                          this.fetchItemChildren(
                            activeBomLayer === 0 ? data[row.index].id : bomChildrenData[activeBomLayer - 1][row.index].id,
                            1,
                          );
                        }}
                      >
                        {t('page_content.projects.project_parts_modal.show_parts_yes')}
                      </div> :
                      <div className="parts_modal_showChildren_no">
                        {t('page_content.projects.project_parts_modal.show_parts_no')}
                      </div>
                    );
                  },
                },
                {
                  Header: () => <span>{t('page_content.projects.project_parts_modal.external_id')}</span>,
                  accessor: 'external_id',
                  Cell: (row) => (row.value ? row.value : '-'),
                },
                {
                  Header: () => <span>{t('page_content.projects.project_parts_modal.quantity')}</span>,
                  accessor: 'quantity',
                  width: 90,
                  Cell: (row) => (row.value ? Math.floor(row.value) : '-'),
                },
                {
                  Header: () => <span>{t('page_content.projects.project_parts_modal.description')}</span>,
                  accessor: 'product_type',
                  Cell: (row) => (row.value.description ? row.value.description : '-'),
                },
                {
                  Header: () => <span>{t('page_content.projects.project_parts_modal.manufacturer_id')}</span>,
                  accessor: 'product_type',
                  Cell: (row) => (row.value.manuf_id ? row.value.manuf_id : '-'),
                },
                {
                  Header: () => <span>{t('page_content.projects.project_parts_modal.material')}</span>,
                  accessor: 'metadata',
                  Cell: (row) => (row.value.material_type ? row.value.material_type : '-'),
                },
                {
                  Header: () => <span>{t('page_content.projects.project_parts_modal.finishing')}</span>,
                  accessor: 'metadata',
                  Cell: (row) => (row.value.finish_treatment ? row.value.finish_treatment : '-'),
                },
                {
                  Header: () => <span>{t('page_content.projects.project_parts_modal.component_type')}</span>,
                  accessor: 'metadata',
                  Cell: (row) => (row.value.component_type ? row.value.component_type : '-'),
                },
                {
                  Header: () => <span>{t('page_content.projects.project_parts_modal.place_of_installation')}</span>,
                  accessor: 'metadata',
                  Cell: (row) => (row.value.place_of_installation ? row.value.place_of_installation : '-'),
                },
              ]
            }
            data={bomChildrenData[activeBomLayer - 1] || data}
            minRows={0}
            noDataText=""
            loading={activeBomLayer === 0 ? isLoading : isLoadingTable}
            showPagination={false}
            manual={false}
            nextText={t('pagination_table.next')}
            previousText={t('pagination_table.previous')}
          />

        </div>
      </Modal>
    );
  }
}

ProjectPartsModal.propTypes = {
  modalStatus: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  fatherBreadcrumb: PropTypes.string.isRequired,
};

export default (withTranslation()(ProjectPartsModal));
