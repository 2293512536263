import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import {
  ContentLoader,
  Modal,
} from 'shared';
import { FactorValueForm } from '../../components';
// import { defaultDateTimeFormat } from 'shared/constants';

class FactorValuesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      factor_values: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    const {
      factorId,
    } = this.props;

    if (factorId > 0) {
      this.getFactorValues(true);
    }
  }

  getFactorValues = (shouldShowReload) => {
    const {
      factorId,
      companyId,
    } = this.props;

    if (shouldShowReload) {
      this.setState({ isLoading: true });
    }

    api
      .get(`/api/v1/reports/factor_values/?factor=${factorId}&company=${companyId}`)
      .then(({ data }) => {
        this.setState({
          isLoading: false,
          factor_values: data.results,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleClickOutside = (e) => {
    if (this.node.contains(e.target) || e.target.classList.contains('rt-td')) {
      return;
    }
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    this.props.handleClose(false);
  }

  render() {
    const {
      isLoading,
      factor_values,
    } = this.state;

    const {
      companyId,
      factorId,
      factorName,
      t,
    } = this.props;

    const modalTitlePrefix = t('page_content.automatic_reports.factor_values.modal_title_prefix');

    return (
      // eslint-disable-next-line no-return-assign
      <div ref={(node) => (this.node = node)}>
        <Modal
          handleClose={this.props.handleClose}
          isOpen
          size="large"
          contentClass="automatic-reports__factor-values-modal"
          style={{ content: { padding: '0', top: '20px', left: '0', right: '0', bottom: 'initial' } }}
          title={`${modalTitlePrefix} ${factorName}`}
        >
          {isLoading === true &&
            <ContentLoader />}
          {isLoading === false &&
            <div className="default-form">

              {factor_values.length > 0 &&
                <Fragment>
                  <h5>{t('page_content.automatic_reports.factor_values.title')}</h5>
                  <table>
                    {factor_values.map((factorValue, index) => {
                      return (<FactorValueForm
                        key={index}
                        factorValueDate={moment(factorValue.datetime)}
                        factorValueValue={factorValue.value}
                        factorValueId={factorValue.id}
                        companyId={companyId}
                        factorId={factorId}
                        reloadEntries={this.getFactorValues}
                      />);
                    })}
                  </table>
                </Fragment>}

              <h5>{t('page_content.automatic_reports.factor_values.new')}</h5>
              <table>
                <FactorValueForm
                  factorValueDate={null}
                  factorValueValue={null}
                  factorValueId={null}
                  companyId={companyId}
                  factorId={factorId}
                  reloadEntries={this.getFactorValues}
                />
              </table>
            </div>}
        </Modal>
      </div>
    );
  }
}

FactorValuesList.propTypes = {
  companyId: PropTypes.number.isRequired,
  factorId: PropTypes.number,
  factorName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(FactorValuesList);
