import React from 'react';
import Base from './Base';

export default function Icon(props) {
  // eslint-disable-next-line react/prop-types
  const { color, ...otherProps } = props;

  return (
    <Base {...otherProps} viewBox="0 0 1024 1024" fill={color || 'black'}>
      <path d="M614.4 1024H128c-70.656 0-128-57.344-128-128V128C0 57.344 57.344 0 128 0h614.4c70.656 0 128 57.344 128 128v230.4c0 14.336-11.264 25.6-25.6 25.6s-25.6-11.264-25.6-25.6V128c0-42.496-34.304-76.8-76.8-76.8H128c-42.496 0-76.8 34.304-76.8 76.8v768c0 42.496 34.304 76.8 76.8 76.8h486.4c14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6z" fill={color} /><path d="M691.2 256H179.2c-14.336 0-25.6-11.264-25.6-25.6s11.264-25.6 25.6-25.6h512c14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6zM384 443.904H179.2c-14.336 0-25.6-11.264-25.6-25.6s11.264-25.6 25.6-25.6h204.8c14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6zM281.6 631.296H179.2c-14.336 0-25.6-11.264-25.6-25.6s11.264-25.6 25.6-25.6h102.4c14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6zM332.8 819.2H179.2c-14.336 0-25.6-11.264-25.6-25.6s11.264-25.6 25.6-25.6h153.6c14.336 0 25.6 11.264 25.6 25.6s-11.264 25.6-25.6 25.6zM640 896c-141.312 0-256-114.688-256-256s114.688-256 256-256 256 114.688 256 256-114.688 256-256 256z m0-460.8c-113.152 0-204.8 91.648-204.8 204.8s91.648 204.8 204.8 204.8 204.8-91.648 204.8-204.8-91.648-204.8-204.8-204.8z" fill={color} /><path d="M998.4 1024c-6.656 0-13.312-2.56-17.92-7.68l-189.44-189.44c-10.24-10.24-10.24-26.112 0-36.352s26.112-10.24 36.352 0l189.44 189.44c10.24 10.24 10.24 26.112 0 36.352-5.12 5.12-11.776 7.68-18.432 7.68z" fill={color} />
    </Base>
  );
}
