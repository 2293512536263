import React from 'react';
import PropTypes from 'prop-types';
import uploadIcon from 'shared/Icons/big_upload.png';
import { withTranslation } from 'react-i18next';
import {
  Button,
} from 'shared';

class UploadStepOne extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef(null);
  }

  handleClick = () => {
    this.inputRef.current.click();
  };

  render() {
    const {
      onFileDrop, dragOver, dragEnter, dragLeave, onChangeInput, showList, t, isReadOnly,
    } = this.props;

    return (
      <div className="upload-content">
        <div className="upload-container">
          <div
            className="drop-container"
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={onFileDrop}
          >
            <div className="drop-message">
              <img src={uploadIcon} alt="UPLOAD" className="upload-icon" />
              <p><strong>{t('page_content.upload.step_one.drag_and_drop_text')}</strong></p>
              <p>{t('page_content.upload.step_one.description')}</p>
              <Button
                style={{ background: '#364252', color: 'white', height: '50px', width: '170px', marginTop: '10px', marginBottom: '20px' }}
                onClick={this.handleClick}
                disabled={isReadOnly}
              >
                {t('page_content.upload.step_one.select_image_button')}
              </Button>
              {/* Hidden input element controlled by ^ button */}
              <input
                type="file"
                ref={this.inputRef}
                onChange={onChangeInput}
                style={{ display: 'none' }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button style={{ height: '50px', width: '170px', marginTop: '10px', marginBottom: '20px' }} onClick={showList}>{t('page_content.upload.step_one.list_button')}</Button>
            </div>
          </div>
        </div>
        <p style={{ fontSize: '.85em' }}>
          {t('page_content.upload.step_one.supported_files_text')}
        </p>
      </div>
    );
  }
}

UploadStepOne.propTypes = {
  onFileDrop: PropTypes.func.isRequired,
  dragEnter: PropTypes.func.isRequired,
  dragOver: PropTypes.func.isRequired,
  dragLeave: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  showList: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(UploadStepOne);
