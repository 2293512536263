import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ValuesMapper } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import { defaultDateFormat } from 'shared/constants';
import { IconEdit, IconSearch } from 'shared/Icons';

import { saveSortingAndFiltering } from 'industry/actions';
import { getCurrentUserSuccess } from 'user/actions';
import api from 'helpers/api';

import { getWorkersList, getPaginatedWorkersList, getAllDepartments, getPartners, getRelatedWorkerInfo, getRelatedInfo } from '../../../actions';

import WorkerModalForm from './WorkerModalForm';

const WorkersList = ({
  t,
  history,
  userIsHR,
  companyId,
  department,
  locationId,
  currentUser,
  setCurrentUser,
  currentUserRole,
  customDepartment,
  managerDepartments,
}) => {
  const debounceTimeoutRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [tableData, setTableData] = useState({
    data: [],
    count: null,
    next: null,
    previous: null,
    isLoading: true,
  });

  const latestStateRef = useRef({ extraFilters: {}, filters: {}, query: '', pageNumber: 1 });
  const sortingAndFiltering = get(currentUser, 'data.HR', null);

  const [pageNumber, setPageNumber] = useState(get(sortingAndFiltering, 'pageNumber', 1));
  const [query, setQuery] = useState(!get(sortingAndFiltering, 'query', null) ? null : get(sortingAndFiltering, 'query'));
  const [filters, setFilters] = useState({
    activityFilter: get(sortingAndFiltering, 'filters.activityFilter', { id: 'active', name: t('page_content.human_resources.workers.activity_option_active') }),
    departmentFilter: get(sortingAndFiltering, 'filters.departmentFilter', currentUserRole === 'Voditelj' ? managerDepartments[0] : department),
    companiesFilter: get(sortingAndFiltering, 'filters.companiesFilter', ''),
    selectedSearchBy: get(sortingAndFiltering, 'filters.selectedSearchBy', { id: 'name', name: t('page_content.human_resources.workers.search_by_option_name') }),
    selectedAscDesc: get(sortingAndFiltering, 'filters.selectedAscDesc', 'desc'),
    selectedSort: get(sortingAndFiltering, 'filters.selectedSort', 'active_from'),
    workPermit: get(sortingAndFiltering, 'filters.workPermit', null),
  });
  const [extraFilters, setExtraFilters] = useState({
    relatedInfo: get(sortingAndFiltering, 'extraFilters.relatedInfo', null),
    relatedInfoValue: get(sortingAndFiltering, 'extraFilters.relatedInfoValue', null),
  });
  const [relatedInfo, setRelatedInfo] = useState([]);
  const [relatedInfoValues, setRelatedInfoValues] = useState([]);
  const [isLoadingRelatedInfoValues, setIsLoadingRelatedInfoValues] = useState(false);

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);

  const fetchWorkers = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let urlFilters = '';
    if (filters?.selectedSearchBy && query) {
      if (filters?.selectedSearchBy?.id === 'name') {
        urlFilters += `&first_or_last_name=${query}`;
      } else {
        urlFilters += `&${filters?.selectedSearchBy?.id}=${query}`;
      }
    }

    if (filters?.activityFilter?.id === 'active' || filters?.activityFilter?.id === 'inactive') {
      urlFilters += `&is_active=${filters?.activityFilter.id === 'active' ? 'true' : 'false'}`;
    }

    if (filters?.departmentFilter) {
      const departmentFilterValue = filters?.departmentFilter;
      urlFilters += `&${customDepartment ? `${customDepartment}` : 'department'}=${customDepartment ? departmentFilterValue.id : departmentFilterValue.name}`;
    }

    if (filters?.companiesFilter) {
      const companiesFilterValue = filters?.companiesFilter;
      urlFilters += `&partner=${companiesFilterValue.id}`;
    }

    if (filters?.workPermit?.id) urlFilters += '&work_permit=true';
    else if (filters?.workPermit?.id === false) urlFilters += '&work_permit=false';

    if (pageNumber > 1) urlFilters += `&offset=${(pageNumber - 1) * 30}`;

    if (extraFilters?.relatedInfo && extraFilters?.relatedInfoValue) {
      urlFilters += `&info=${extraFilters?.relatedInfo?.code}&info_value=${extraFilters?.relatedInfoValue?.id === true || extraFilters?.relatedInfoValue?.id === false ?
        extraFilters?.relatedInfoValue?.id : extraFilters?.relatedInfoValue?.data?.value}`;
    }

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    urlFilters += `&order_by=${asc}${filters?.selectedSort}&extend_data=true`;

    getWorkersList(locationId, companyId, urlFilters)
      .then((res) => {
        const response = get(res, 'data.results', []);

        const newResponseData = response.map((worker) => {
          const workingplaces = worker?.workingplaces?.map((wp) => {
            wp.working_place_name = wp.working_place.name;
            return wp;
          });
          return { ...worker, workingplaces };
        });

        setTableData({
          data: newResponseData,
          count: get(res, 'data.count', null),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          isLoading: false,
        });
      })
      .catch((e) => { setTableData((prevState) => ({ ...prevState, isLoading: false })); console.error('Error while fetching workers', e); });
  };

  const fetchDepartments = async () => {
    if (currentUserRole === 'Voditelj') {
      setDepartmentOptions(managerDepartments || []);
    } else {
      const res = await getAllDepartments(locationId);
      setDepartmentOptions(get(res, 'data.results', []));
    }
  };

  const fetchCompanies = async () => {
    const res = await getPartners(companyId);
    setCompaniesOptions(get(res, 'data.results', []));
  };

  const fetchRelatedInfo = async () => {
    await getRelatedInfo(companyId, '&limit=9999&is_active=true')
      .then((resp) => setRelatedInfo(get(resp, 'data.results', [])));
  };

  const fetchRelatedWorkerInfo = async (code) => {
    setIsLoadingRelatedInfoValues(true);
    const resp = await getRelatedWorkerInfo(companyId, `&code=${code}&limit=9999`);
    const response = get(resp, 'data.results', []);

    const uniqueValues = new Set();
    const newRelatedInfoValues = response.filter((info) => {
      const value = info?.data?.value;
      if (!value || uniqueValues.has(value)) return false;
      uniqueValues.add(value);
      return true;
    });

    setIsLoadingRelatedInfoValues(false);
    setRelatedInfoValues(newRelatedInfoValues);
  };

  useEffect(() => {
    latestStateRef.current = { extraFilters, filters, query, pageNumber };
  }, [extraFilters, filters, query, pageNumber]);

  useEffect(() => {
    if (extraFilters?.relatedInfo?.value_type === 'bool') setRelatedInfoValues([{ id: true, data: { value: t('page_content.human_resources.workers.worker_related_info_bool_true') } }, { id: false, data: { value: t('page_content.human_resources.workers.worker_related_info_bool_false') } }]);
    if (extraFilters?.relatedInfo?.value_type === 'text') fetchRelatedWorkerInfo(extraFilters?.relatedInfo?.code);
  }, [extraFilters?.relatedInfo]);

  useEffect(() => {
    fetchDepartments();
    fetchCompanies();
    fetchRelatedInfo();

    return () => {
      const dataToSaveToMe = { ...latestStateRef.current };
      if (currentUser) {
        currentUser.data.HR = dataToSaveToMe;
        setCurrentUser(currentUser);
        saveSortingAndFiltering({ data: currentUser.data });
      }
    };
  }, []);

  useEffect(() => {
    fetchWorkers();
  }, [filters]);

  useEffect(() => {
    if (extraFilters?.relatedInfo && extraFilters?.relatedInfoValue) fetchWorkers();
  }, [extraFilters]);

  useEffect(() => {
    if (query !== null) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        fetchWorkers();
      }, 300);
    }
  }, [query]);

  const fetchPaginatedWorkersList = (url) => {
    getPaginatedWorkersList(url)
      .then((res) => {
        const count = get(res, 'data.count', null);
        const next = get(res, 'data.next', null);
        const previous = get(res, 'data.previous', null);

        const params = count && (next !== null || previous !== null) ? new URLSearchParams(next) : undefined;
        const offset = count && params && params.get('offset') ? parseInt(params.get('offset'), 10) : count;
        const pageNumCalc = Math.ceil(offset / 30);

        setTableData({
          data: get(res, 'data.results', []),
          count,
          next,
          previous,
          isLoading: false,
        });
        setPageNumber(pageNumCalc);
      })
      .catch((e) => { setTableData((prevState) => ({ ...prevState, isLoading: false })); console.error('Error while fetching workers', e); });
  };

  const redirectToWorkerDetails = (row) => {
    const workerId = row.original.id || null;
    history.push(`/${companyId}/industry/location/${locationId}/human-resources/worker/${workerId}`);
  };

  const transformNameOrSurnameString = (string) => {
    if (!string) {
      return '';
    }
    const deconstructedString = string.split(/\s+|-/);
    const formatedString = deconstructedString.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(' ');

    return formatedString;
  };

  const clearAllFilters = () => {
    setQuery('');
    setPageNumber(1);
    setFilters((prevState) => ({
      ...prevState,
      activityFilter: '',
      departmentFilter: currentUserRole === 'Voditelj' ? managerDepartments[0] : department,
      companiesFilter: '',
      selectedSearchBy: { id: 'name', name: t('page_content.human_resources.workers.search_by_option_name') },
      workPermit: null,
    }));
    setExtraFilters((prevState) => ({
      ...prevState,
      relatedInfo: null,
      relatedInfoValue: null,
    }));
  };

  const handleEditButton = (e, row) => {
    e.stopPropagation();
    setShowModal(true);
    setData(row.original);
  };

  const handleClose = () => {
    setShowModal(false);
    setData(null);
    setExtraFilters((prevState) => ({
      ...prevState,
      relatedInfo: null,
      relatedInfoValue: null,
    }));
  };

  const handleCloseAndRefetch = () => {
    setShowModal(false);
    setData(null);
    setExtraFilters((prevState) => ({
      ...prevState,
      relatedInfo: null,
      relatedInfoValue: null,
    }));
    fetchWorkers();
  };

  const handleAddNewWorker = () => {
    setShowModal(true);
    setData(null);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setPageNumber(1);
  };

  const handleExtraFilterChange = (key, value) => {
    if (key === 'relatedInfo') {
      setExtraFilters((prevState) => ({
        ...prevState,
        [key]: value,
        relatedInfoValue: null,
      }));
    } else {
      setExtraFilters((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
    setPageNumber(1);
  };

  const handleSorting = (sortData) => {
    let column = sortData.id;

    if (column === 'location_department.name') column = 'location_department__name';
    if (column === 'department_shift') column = 'department_shift__name';
    if (column === 'workingplaces') column = 'workingplaces__id';
    if (column === 'partner') column = 'partner__name';

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: column,
    }));
  };

  const exportToExcel = () => {
    let urlFilters = '';
    if (filters?.selectedSearchBy && query) {
      if (filters?.selectedSearchBy?.id === 'name') {
        urlFilters += `&first_or_last_name=${query}`;
      } else {
        urlFilters += `&${filters?.selectedSearchBy?.id}=${query}`;
      }
    }

    if (filters?.activityFilter?.id === 'active' || filters?.activityFilter?.id === 'inactive') {
      urlFilters += `&is_active=${filters?.activityFilter.id === 'active' ? 'true' : 'false'}`;
    }

    if (filters?.departmentFilter) {
      const departmentFilterValue = filters?.departmentFilter;
      urlFilters += `&${customDepartment ? `${customDepartment}` : 'department'}=${customDepartment ? departmentFilterValue.id : departmentFilterValue.name}`;
    }

    if (filters?.companiesFilter) {
      const companiesFilterValue = filters?.companiesFilter;
      urlFilters += `&partner=${companiesFilterValue.id}`;
    }

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    urlFilters += `&order_by=${asc}${filters?.selectedSort}`;

    api.get(`/api/v1/workforce/workers/?company=${companyId}&location=${locationId}${urlFilters}&format=xlsx&limit=${tableData?.count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Workers.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const tableColumnConfig = [
    {
      Header: () => <span>PIN</span>,
      accessor: 'external_id',
      width: 50,
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_first_name')}</span>,
      accessor: 'name',
      width: 100,
      Cell: (row) => (row.value ? transformNameOrSurnameString(row.value) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_last_name')}</span>,
      accessor: 'last_name',
      width: 120,
      Cell: (row) => (row.value ? transformNameOrSurnameString(row.value) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_email')}</span>,
      accessor: 'email',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_private_email')}</span>,
      accessor: 'private_email',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_department')}</span>,
      accessor: `${customDepartment}.name` || 'department',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.default_shift')}</span>,
      accessor: 'department_shift',
      Cell: (row) => (row.value ? `${row?.value?.name} (${moment(row?.value?.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(row?.value?.end_time, 'HH:mm:ss').format('HH:mm')})` : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_working_place')}</span>,
      accessor: 'workingplaces',
      width: 125,
      Cell: (row) => (row?.value?.length ? <ValuesMapper data={row?.value} accessor="working_place_name" sliceNumber={1} /> : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_company')}</span>,
      accessor: 'partner',
      width: 100,
      Cell: (row) => (row.value ? row.value.name : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_active_from')}</span>,
      accessor: 'active_from',
      width: 100,
      Cell: (row) => (row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_active_to')}</span>,
      accessor: 'active_to',
      width: 100,
      Cell: (row) => (row.original?.is_active ? '-' : row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.input_label_work_permit')}</span>,
      accessor: 'work_permit_valid_until',
      width: 120,
      Cell: (row) => (!row.original?.work_permit ? '-' : row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_active')}</span>,
      accessor: 'is_active',
      width: 85,
      Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>,
    },
    {
      Header: () => <span>{t('page_content.human_resources.workers.table_column_edit')}</span>,
      width: 75,
      style: {
        cursor: 'default',
      },
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button style={{ width: 55, padding: '5px 8px' }} onClick={(e) => handleEditButton(e, row)} disabled={!userIsHR}>
          <IconEdit
            height="14px"
            width="14px"
            fill="#4285F4"
          />
        </Button>
      </div>),
    },
  ];

  const activeFilterOptions = [
    { id: 'active', name: t('page_content.human_resources.workers.activity_option_active') },
    { id: 'inactive', name: t('page_content.human_resources.workers.activity_option_inactive') }];
  const searchByOptions = [
    { id: 'name', name: t('page_content.human_resources.workers.search_by_option_name') },
    { id: 'email', name: t('page_content.human_resources.workers.search_by_option_email') },
    { id: 'private_email', name: t('page_content.human_resources.workers.search_by_option_private_email') },
  ];
  const workPermitOptions = [
    { id: true, name: t('page_content.human_resources.workers.work_permit_option_yes') },
    { id: false, name: t('page_content.human_resources.workers.work_permit_option_no') },
  ];

  return (
    <div className="workersList_container">
      <div className="workersList_addNew">
        <div className="filters">
          <div className="input_container_search">
            <div className="input_field_style">
            <input onChange={(e) => setQuery(e.target.value)} placeholder={t('page_content.orders.search_for_placeholder')} value={query || ''} />
            {query && <button
              onClick={() => setQuery('')}
            >&times;</button>}
              </div>
              <Select
                className="select-style"
                options={searchByOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(e) => handleFilterChange('selectedSearchBy', e)}
                value={filters?.selectedSearchBy}
                styles={selectModalStyles}
              />
          <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          </div>
          <div className="filters">
          <Select
            className="select-style"
            options={activeFilterOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.filter_by_activity_placeholder')}
            isClearable
            onChange={(e) => handleFilterChange('activityFilter', e)}
            value={activeFilterOptions.find((a) => a.id === filters?.activityFilter?.id) || ''}
            styles={selectModalStyles}
          />
          <Select
            className="select-style"
            options={departmentOptions.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.filter_by_department_placeholder')}
            isClearable={currentUserRole !== 'Voditelj'}
            onChange={(e) => handleFilterChange('departmentFilter', e)}
            value={departmentOptions.find((a) => a.id === filters?.departmentFilter?.id) || ''}
            styles={selectModalStyles}
          />
          <Select
            className="select-style"
            options={companiesOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.filter_by_company_placeholder')}
            isClearable
            isSearchable
            onChange={(e) => handleFilterChange('companiesFilter', e)}
            value={companiesOptions.find((a) => a.id === filters?.companiesFilter?.id) || ''}
            styles={selectModalStyles}
          />
          <Select
            className="select-style"
            options={workPermitOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.human_resources.workers.filter_by_work_permit_placeholder')}
            isClearable
            onChange={(e) => handleFilterChange('workPermit', e)}
            value={filters?.workPermit || ''}
            styles={selectModalStyles}
          />

          <div className="filters__related_info">
            <Select
              className="select-style"
              options={relatedInfo}
              getOptionLabel={(option) => t(`page_content.human_resources.workers.worker_related_info_${option.code}`)}
              getOptionValue={(option) => option.id}
              placeholder={t('page_content.human_resources.workers.filter_by_related_info_placeholder')}
              isClearable
              onChange={(e) => handleExtraFilterChange('relatedInfo', e)}
              value={extraFilters?.relatedInfo || ''}
              styles={selectModalStyles}
            />
            <Select
              className="select-style"
              options={relatedInfoValues}
              getOptionLabel={(option) => option.data?.value}
              getOptionValue={(option) => option.id}
              placeholder={t('page_content.human_resources.workers.filter_by_related_value_info_placeholder')}
              isClearable
              isLoading={isLoadingRelatedInfoValues}
              isDisabled={!extraFilters?.relatedInfo}
              onChange={(e) => handleExtraFilterChange('relatedInfoValue', e)}
              value={extraFilters?.relatedInfoValue || ''}
              styles={selectModalStyles}
            />
          </div>

          <div className="clear_button"><Button type="plain" onClick={clearAllFilters}>{t('page_content.orders.clear_all_button')}</Button></div>
          <div className="add_worker_button">
            <Button type="add" onClick={handleAddNewWorker} disabled={!userIsHR}>{t('page_content.human_resources.workers.button_add_new_worker')}</Button>
            <Button disabled={!userIsHR} type="export" onClick={exportToExcel}>{t('page_content.human_resources.workers.button_export_to_excel')}</Button>
          </div>
        </div>
      </div>
      <div className="workersList_tableArea">
        <Table
          style={{ userSelect: 'text' }}
          columns={tableColumnConfig}
          data={tableData.data}
          minRows={0}
          defaultPageSize={30}
          noDataText=" "
          showPagination={false}
          loading={tableData.isLoading}
          defaultSorted={[{ id: 'active_from', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo) {
              const workPermitValidUntil = moment(rowInfo.original.work_permit_valid_until);
              const fortyDaysFromToday = moment().add(40, 'days');
              const isWithinFortyDays = (workPermitValidUntil.isSameOrBefore(fortyDaysFromToday) && workPermitValidUntil.isAfter(moment())) || workPermitValidUntil.isSameOrBefore(moment());

              return {
                style: { backgroundColor: isWithinFortyDays ? 'rgb(239, 195, 195)' : null },
                onClick: () => { redirectToWorkerDetails(rowInfo); },
              };
            }
          }}
        />
        <TableButtons
          previous={tableData.previous}
          next={tableData.next}
          fetchFunction={fetchPaginatedWorkersList}
          count={tableData.count}
        />
      </div>

      {
        showModal && (
          <WorkerModalForm
            t={t}
            initialData={data}
            companyId={companyId}
            showModal={showModal}
            locationId={locationId}
            handleClose={handleClose}
            departments={departmentOptions}
            customDepartment={customDepartment}
            companiesOptions={companiesOptions}
            handleCloseAndRefetch={handleCloseAndRefetch}
          />
        )
      }

    </div>
  );
};

WorkersList.propTypes = {
  userIsHR: PropTypes.bool,
  t: PropTypes.func.isRequired,
  department: PropTypes.number,
  currentUserRole: PropTypes.string,
  customDepartment: PropTypes.string,
  managerDepartments: PropTypes.array,
  history: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser'),
  };
};

const mapDispatchToProps = {
  setCurrentUser: getCurrentUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(WorkersList)));
