/* eslint-disable no-debugger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import ReactDatePicker from 'react-datepicker';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import sliderIcon from 'shared/Icons/slider_03.png';
import refreshIcon from 'shared/Icons/refresh.png';
import {
  Table,
  Button,
  TableLoader,
} from 'shared';
import { saveSortingAndFiltering } from 'industry/actions';
import { getCurrentUserSuccess } from 'user/actions';
import { checkAccessOnPage, redirectToHomePage, checkModules, checkActiveFilters } from 'industry/helpers';
import { setDateRange } from 'app/actions';
import { defaultDateFormat } from 'shared/constants';
import { getLocale } from 'shared/DatePicker/constants';
import 'react-table/react-table.css';
import './style.scss';
import {
  getAllEfficiencies,
  getAssetUtilizations,
  getAssets,
  getAssetTypes,
  getShifts,
  runUtilizationByAsset,
  runUtilizationByAssetUtilization,
} from './actions';
import TableButtons from '../../../shared/TableButtons/index';
import { selectStyles } from '../../../styles/modules/reactSelect';

class IndustryLocationEfficiencyList extends Component {
  constructor(props) {
    super(props);

    const sortingAndFiltering = props && props.currentUser && props.currentUser.data ? props.currentUser.data : {};
    let startDate = null;
    let endDate = null;
    let assetId = null;
    let assetTypeId = null;
    let shiftId = null;

    this.defaultFilters = {
      startDate,
      endDate,
      assetId,
      assetTypeId,
      shiftId,

    };
    let filtersActive = false;
    if (sortingAndFiltering.efficiencyList) {
      const sAndF = sortingAndFiltering.efficiencyList;
      startDate = sAndF.startDate ? moment(sAndF.startDate) : null;
      endDate = sAndF.endDate ? moment(sAndF.endDate) : null;
      assetId = sAndF.assetId ? parseInt(sAndF.assetId, 10) : null;
      assetTypeId = sAndF.assetTypeId ? parseInt(sAndF.assetTypeId, 10) : null;
      shiftId = sAndF.shiftId ? parseInt(sAndF.shiftId, 10) : null;
      filtersActive = checkActiveFilters(this.defaultFilters, sAndF);
    }

    this.state = {
      isLoading: false,
      efficiencies: [],
      startDate,
      endDate,
      assetId,
      assetTypeId,
      shiftId,
      showFilters: true,
      assetUtilizations: [],
      assets: [],
      assetTypes: [],
      shifts: [],
      daysToRecalculate: 1,
      filtersActive,
      count: null,
      previous: null,
      next: null,
      selectedAscDesc: 'desc',
      selectedSort: 'date',
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Efficiency');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            isReadOnly: true,
          });
        }
      });

    getAssetUtilizations(companyId, locationId)
      .then((re) => {
        this.setState({
          assetUtilizations: re.data.results,
        });
      }).catch((err) => console.log(err));

    getAssets(companyId)
      .then((re) => {
        this.setState({
          assets: re.data.results,
        });
      }).catch((err) => console.log(err));

    getAssetTypes(companyId)
      .then((re) => {
        this.setState({
          assetTypes: re.data.results,
        });
      }).catch((err) => console.log(err));

    getShifts(locationId)
      .then((re) => {
        this.setState({
          shifts: re.data.results,
        });
      }).catch((err) => console.log(err));

    this.applyFilters();

    /*
    const {
      startDate, endDate, assetId, assetTypeId, shiftId,
    } = this.state;

    let url = `/api/v1/industry/efficiencies/?location=${locationId}&order_by=-date`;

    if (startDate) {
      const date = `${startDate.year()}-${startDate.month() + 1}-${startDate.date()}`;
      url += `&date__gte=${date}`;
    }

    if (endDate) {
      const date = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;
      url += `&date__lte=${date}`;
    }

    if (assetId) {
      url += `&asset=${assetId}`;
    }

    if (assetTypeId) {
      url += `&asset_type=${assetTypeId}`;
    }

    if (shiftId) {
      url += `&shift=${shiftId}`;
    }

    this.fetchData(url);
    */
  }

  componentWillUnmount() {
    this.saveSortingAndFiltering();
  }

  handleSorting = (sortData) => {
    const { locationId } = this.props;
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      let url = `/api/v1/industry/efficiencies/?location=${locationId}`;
      const asc = sortData.desc ? '-' : '';
      let sortKey = column;
      if (column === 'shift.name' || column === 'asset.name') {
        sortKey = sortKey.replace('.', '__');
      }
      url += `&order_by=${asc}${sortKey || 'date'}`;
      this.fetchData(url);
    });
  }

  onChangeStart = (startDate) => {
    const formatedDate = moment(startDate);
    this.setState({
      startDate: formatedDate,
    });
  }

  onChangeEnd = (endDate) => {
    const formatedDate = moment(endDate);
    this.setState({
      endDate: formatedDate,
    });
  }

  getAllEfficiencies = () => {
    const {
      locationId,
    } = this.props;
    const url = `/api/v1/industry/efficiencies/?location=${locationId}&order_by=-date`;
    this.fetchData(url);
  }

  fetchData = (url) => {
    this.setState({
      isLoading: true,
    });
    getAllEfficiencies(url)
      .then((res) => {
        this.setState({
          efficiencies: get(res, 'data.results') || [],
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          count: get(res, 'data.count'),
          isLoading: false,
        });
      })
      .catch((e) => console.error('Error while fetching efficiencies', e));
  }

  saveSortingAndFiltering = () => {
    const {
      startDate,
      endDate,
      assetId,
      assetTypeId,
      shiftId,
    } = this.state;

    const {
      currentUser,
      setCurrentUser,
    } = this.props;

    if (currentUser) {
      currentUser.data.efficiencyList = {
        startDate,
        endDate,
        assetId,
        assetTypeId,
        shiftId,
      };
      setCurrentUser(currentUser);
      saveSortingAndFiltering({ data: currentUser.data });
      this.checkActiveSortingAndFiltering(currentUser.data.efficiencyList);
    }
  }

  checkActiveSortingAndFiltering = (userFilters) => {
    const filtersActive = checkActiveFilters(this.defaultFilters, userFilters);
    this.setState({ filtersActive });
  }

  toggleFilters = () => {
    const {
      showFilters,
    } = this.state;

    this.setState({
      showFilters: !showFilters,
    });
  }

  applyFilters = () => {
    const {
      locationId,
    } = this.props;
    const {
      startDate, endDate, assetId, assetTypeId, shiftId, selectedAscDesc, selectedSort,
    } = this.state;

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    let url = `/api/v1/industry/efficiencies/?location=${locationId}&order_by=${asc}${selectedSort}`;

    if (startDate) {
      const date = `${startDate.year()}-${startDate.month() + 1}-${startDate.date()}`;
      url += `&date__gte=${date}`;
    }

    if (endDate) {
      const date = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;
      url += `&date__lte=${date}`;
    }

    if (assetId) {
      url += `&asset=${assetId}`;
    }

    if (assetTypeId) {
      url += `&asset_type=${assetTypeId}`;
    }

    if (shiftId) {
      url += `&shift=${shiftId}`;
    }
    this.fetchData(url);
    this.saveSortingAndFiltering();
  }

  clearAllFilters = () => {
    this.setState(this.defaultFilters, () => {
      this.saveSortingAndFiltering();
    });

    const {
      locationId,
    } = this.props;

    const url = `/api/v1/industry/efficiencies/?location=${locationId}&order_by=-date`;
    this.fetchData(url);
  }

  recalculateByAsset = (row) => {
    this.setState({
      isLoading: true,
    });
    const id = row.asset.id;
    const date = moment(row.date).format('YYYY-MM-DD');
    runUtilizationByAsset(id, date)
      .then((re) => {
        const message = re.data.message;
        if (message && message === 'Utilization finished') {
          // const { efficiencies } = this.state;
          // efficiencies.forEach((eff) => {
          //   if (eff.id === row.id) {

          //   }
          // });
          this.getAllEfficiencies();
        } else {
          alert('There was a problem with the recalculation, please try again.');
        }
      })
      .catch((err) => console.log(err));
  }

  recalculateByAssetUtilization = () => {
    this.setState({
      isLoading: true,
    });
    const {
      assetUtilizationId, daysToRecalculate,
    } = this.state;

    runUtilizationByAssetUtilization(assetUtilizationId, daysToRecalculate)
      .then((re) => {
        const message = re.data.message;
        if (message && message === 'Utilization finished') {
          this.getAllEfficiencies();
        } else {
          alert('There was a problem with the recalculation, please try again.');
        }
      })
      .catch((err) => console.log(err));
  }

  selectDaysToRecalculate = (e) => {
    this.setState({
      daysToRecalculate: e.target.value,
    });
  }

  selectAssetUtilization = (e) => {
    this.setState({
      assetUtilizationId: e.target.id,
    });
  }

  selectAsset = (e) => {
    this.setState({
      assetId: e.target.id,
    });
  }

  selectAssetType = (e) => {
    this.setState({
      assetTypeId: e.target.id,
    });
  }

  selectShift = (e) => {
    this.setState({
      shiftId: e.target.id,
    });
  }

  resetAsset = () => {
    this.setState({
      assetId: this.defaultFilters.assetId,
    });
    setTimeout(() => {
      this.applyFilters();
      this.saveSortingAndFiltering();
    }, 100);
  }

  resetAssetType = () => {
    this.setState({
      assetTypeId: this.defaultFilters.assetTypeId,
    });
    setTimeout(() => {
      this.applyFilters();
      this.saveSortingAndFiltering();
    }, 100);
  }

  resetShift = () => {
    this.setState({
      shiftId: this.defaultFilters.shiftId,
    });
    setTimeout(() => {
      this.applyFilters();
      this.saveSortingAndFiltering();
    }, 100);
  }

  render() {
    const { t } = this.props;
    const {
      efficiencies,
      isLoading,
      next,
      previous,
      showFilters,
      startDate,
      endDate,
      assetId,
      assetTypeId,
      shiftId,
      assetUtilizationId,
      assetUtilizations,
      assets,
      assetTypes,
      shifts,
      daysToRecalculate,
      count,
    } = this.state;

    let listClass = '';
    if (showFilters) {
      listClass = 'filters-visible';
    }

    let days = 'day';
    if (daysToRecalculate > 1) {
      days = 'days';
    }

    const defaultAssets = [{ id: null, name: t('page_content.efficiency.select_asset_placeholder') }, ...assets];
    const defaultAssetTypes = [{ id: null, description: t('page_content.efficiency.select_asset_type_placeholder') }, ...assetTypes];
    const defaultShifts = [{ id: null, name: t('page_content.efficiency.select_shift_placeholder') }, ...shifts];
    const defaultAssetUtilizations = [{ id: null, name: '---------' }, ...assetUtilizations];

    return (
      <div className="industry-tab efficiency-list fullscreen">
        <main style={{ position: 'relative' }}>
          <div className={`efficiency-list__filter-toggle ${listClass}`}>
            <div className="filter_toggle_controls">
              <div className={showFilters ? 'filter-active' : ''} />
              <img
                src={sliderIcon}
                alt="Filters"
                onClick={() => this.toggleFilters()}
              />
              <img
                src={refreshIcon}
                alt="Refresh"
                onClick={this.getAllEfficiencies}
                style={{ width: '18px', height: '18px' }}
              />
            </div>
          </div>
          {
            showFilters ?
              <div className="table-controls">
                <div className="table-controls__filter">
                  <div className="flexContainer">
                    <div>
                      <ReactDatePicker
                        placeholderText={t('page_content.efficiency.start_date_placeholder')}
                        dateFormat="dd.MM.yyyy"
                        selected={startDate ? moment(startDate).toDate() : null}
                        selectsStart
                        onChange={this.onChangeStart}
                        showTimeSelect={false}
                        disabledKeyboardNavigation
                        onKeyDown={(e) => { e.preventDefault(); }}
                        locale={getLocale(t)}
                      />
                    </div>
                    <div>
                      <ReactDatePicker
                        placeholderText={t('page_content.efficiency.end_date_placeholder')}
                        dateFormat="dd.MM.yyyy"
                        selected={endDate ? moment(endDate).toDate() : null}
                        selectsEnd
                        disabledKeyboardNavigation
                        onChange={this.onChangeEnd}
                        showTimeSelect={false}
                        onKeyDown={(e) => { e.preventDefault(); }}
                        locale={getLocale(t)}
                      />
                    </div>
                    <div style={{ position: 'relative' }}>
                      <Select
                        options={defaultAssets}
                        getOptionLabel={(asset) => asset.name}
                        getOptionValue={(asset) => asset.id}
                        isSearchable={false}
                        onChange={(e) => { this.selectAsset({ target: e }); }}
                        value={defaultAssets.find((d) => d.id === assetId)}
                        styles={{ ...selectStyles, width: '100%' }}
                      />
                      {assetId !== null && <span style={{ position: 'absolute', top: '6px', right: '28px' }} onClick={this.resetAsset}>
                        <button className="clear">&times;</button>
                      </span>}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <Select
                        options={defaultAssetTypes}
                        getOptionLabel={(assetType) => assetType.description}
                        getOptionValue={(assetType) => assetType.id}
                        isSearchable={false}
                        onChange={(e) => { this.selectAssetType({ target: e }); }}
                        value={defaultAssetTypes.find((d) => d.id === assetTypeId)}
                        styles={selectStyles}
                      />
                      {assetTypeId !== null && <span style={{ position: 'absolute', top: '6px', right: '28px' }} onClick={this.resetAssetType}>
                        <button className="clear">&times;</button>
                      </span>}
                    </div>
                    <div style={{ position: 'relative' }}>
                      <Select
                        options={defaultShifts}
                        getOptionLabel={(shift) => shift.name}
                        getOptionValue={(shift) => shift.id}
                        isSearchable={false}
                        onChange={(e) => { this.selectShift({ target: e }); }}
                        value={defaultShifts.find((d) => d.id === shiftId)}
                        styles={selectStyles}
                      />
                      {shiftId !== null && <span style={{ position: 'absolute', top: '6px', right: '28px' }} onClick={this.resetShift}>
                        <button className="clear">&times;</button>
                      </span>}
                    </div>
                    <div className="filter-buttons">
                      <Button
                        disabled={!startDate && !endDate && !assetId && !assetTypeId && !shiftId}
                        onClick={this.applyFilters}
                      >
                        {t('page_content.efficiency.apply_button')}
                      </Button>
                      <Button
                        onClick={this.clearAllFilters}
                      >
                        {t('page_content.efficiency.clear_button')}
                      </Button>
                    </div>
                  </div>
                  <div className="flexContainer" style={{ marginTop: '30px' }}>
                    <Select
                      options={defaultAssetUtilizations}
                      getOptionLabel={(assetUtilization) => assetUtilization.name}
                      getOptionValue={(assetUtilization) => assetUtilization.id}
                      isSearchable={false}
                      onChange={(e) => { this.selectAssetUtilization({ target: e }); }}
                      value={defaultAssetUtilizations.find((d) => d.id === assetUtilizationId) || defaultAssetUtilizations[0]}
                      styles={selectStyles}
                    />
                    <input
                      onChange={this.selectDaysToRecalculate}
                      type="range"
                      min="1"
                      max="30"
                      value={daysToRecalculate}
                    />
                    <div className="filters-recalculate">
                      <input
                        type="text"
                        value={`${daysToRecalculate} ${days}`}
                        readOnly
                        style={{ width: '60px' }}
                        disabled
                      />
                      <Button
                        type="dark-blue"
                        onClick={this.recalculateByAssetUtilization}
                        disabled={isLoading || !assetUtilizationId}
                      >
                        {t('page_content.efficiency.recalculate_button')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div> : <div className="filters-buffer" />
          }
          <div>
            <Table
              style={{ userSelect: 'text' }}
              columns={[
                {
                  Header: () => <span>{t('page_content.efficiency.table_column_date')}</span>,
                  accessor: 'date',
                  Cell: (row) => moment(row.value).format(defaultDateFormat),
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.efficiency.table_column_asset')}</span>,
                  accessor: 'asset.name',
                  Cell: (row) => (row.value ? row.value : '-'),
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.efficiency.table_column_shift')}</span>,
                  accessor: 'shift.name',
                  Cell: (row) => (row.value ? row.value : '-'),
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.efficiency.table_column_expected_time')}</span>,
                  accessor: 'expected_time',
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.efficiency.table_column_utilized_time')}</span>,
                  accessor: 'utilized_time',
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.efficiency.table_column_overtime')}</span>,
                  accessor: 'overtime',
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.efficiency.table_column_efficiency')}</span>,
                  accessor: 'efficiency',
                  Cell: (row) => `${row.value} %`,
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.efficiency.table_column_recalculation')}</span>,
                  accessor: 'asset.id',
                  Cell: (row) => (row.value ? <Button onClick={(e) => { e.stopPropagation(); this.recalculateByAsset(row.original); }}>{t('page_content.efficiency.table_column_recalculate_button')}</Button> : '-'),
                  sortable: false,
                },
              ]}
              data={efficiencies}
              defaultPageSize={100}
              loading={isLoading}
              LoadingComponent={TableLoader}
              handleClick={() => { }}
              minRows={0}
              noDataText=" "
              showPagination={false}
              defaultSorted={[{ id: 'date', desc: true }]}
              onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
            />
            <TableButtons next={next} previous={previous} fetchFunction={this.fetchData} count={count} />
          </div>
        </main>
      </div>
    );
  }
}

IndustryLocationEfficiencyList.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  dispatchDateRange: setDateRange,
  setCurrentUser: getCurrentUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(IndustryLocationEfficiencyList)));
