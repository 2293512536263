import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import 'react-table/react-table.css';

import { Table, StatusPicker, Button, TableButtons } from 'shared';
import { selectStyles } from 'styles/modules/reactSelect';
import { ordersCreatedAndUpdated } from 'shared/constants';
import { checkAccessOnPage, redirectToHomePage, checkModules, numberSeparatorFormat } from 'industry/helpers';
import { getOrders } from '../../actions';
import OrdersGantt from './components/OrdersGantt';

class ProjectOrdersTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      isLoadingOrders: false,
      selectedStatus: null,
      next: null,
      previous: null,
      count: 0,
      selectedAscDesc: 'desc',
      selectedSort: 'updated_at',
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Orders');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only --- check if we need it on order list
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            isReadOnly: true,
          });
        }
      });
    this.getOrders();
  }

  onFilterChange = (key, value) => {
    this.setState({
      [key]: value,
    }, () => {
      this.getOrders();
    });
  }

  getOrders = () => {
    const { companyId, projectId } = this.props;
    const { selectedStatus, selectedAscDesc, selectedSort } = this.state;

    const url = `/api/v1/orders/orders/?company=${companyId}&project=${projectId}`;

    this.setState({ isLoadingOrders: true });

    let filters = '';

    if (selectedStatus && selectedStatus.value && selectedStatus.value !== 'all') {
      filters += `&status=${selectedStatus.value}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort}`;

    getOrders(url, filters)
      .then((res) => {
        this.setState({
          orders: get(res, 'data.results') || [],
          count: get(res, 'data.count'),
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          isLoadingOrders: false,
        });
      })
      .catch((e) => console.error('Error while fetching orders', e));
  }

  redirectToDetails = (row) => {
    const { companyId, locationId } = this.props;
    const orderId = row.original.id || null;
    if (orderId > 0) {
      window.open(`/${companyId}/industry/location/${locationId}/orders/${orderId}`);
    }
  }

  clearFilters = () => {
    this.setState({
      selectedStatus: '',
    }, () => {
      this.getOrders();
    });
  }

  fetchData = (url) => {
    this.setState({ isLoadingOrders: true });
    getOrders(url)
      .then((res) => {
        this.setState({
          orders: get(res, 'data.results') || [],
          count: get(res, 'data.count'),
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          isLoadingOrders: false,
        });
      })
      .catch((e) => console.error('Error while fetching orders', e));
  };

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.getOrders();
    });
  }

  render() {
    const { t, i18n, companyId, projectId } = this.props;
    const {
      orders,
      isLoadingOrders,
      next,
      previous,
      selectedStatus,
      count,
    } = this.state;

    return (
      <div className="project-orders fullscreen">
        <div className="project-orders_filters">
          <StatusPicker
            companyId={companyId}
            changeStatus={(key, value) => this.onFilterChange(key, value)}
            selectedStatus={selectedStatus}
            style={selectStyles}
          />

          <Button onClick={this.clearFilters}>
            {t('shared.clear_button')}
          </Button>
        </div>
        <main style={{ position: 'relative' }}>
          <Table
            style={{ userSelect: 'text' }}
            columns={[
              {
                Header: () => <span>{t('page_content.orders.table_column_name')}</span>,
                accessor: 'external_id',
                Cell: (row) => (<React.Fragment>
                  <span style={{ marginRight: 0, textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>{row.value}</span>
                </React.Fragment>),
                width: 200,
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_product_code')}</span>,
                accessor: 'product_code',
                Cell: (row) => (row.value ? row.value : '-'),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_lines')}</span>,
                accessor: 'lines',
                Cell: (row) => <span style={{ wordBreak: 'word-break' }}>{row.value && Object.keys(row.value).length !== 0 ? Object.values(row.value).join(', ') : '-'}</span>,
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_customer_name')}</span>,
                accessor: 'customer_name',
                Cell: (row) => row.value || '-',
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_created')}</span>,
                accessor: 'created_at',
                Cell: (row) => moment(row.value).format(ordersCreatedAndUpdated),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_updated')}</span>,
                accessor: 'updated_at',
                Cell: (row) => moment(row.value).format(ordersCreatedAndUpdated),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_started')}</span>,
                accessor: 'started_at',
                Cell: (row) => (row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_finished')}</span>,
                accessor: 'finished_at',
                Cell: (row) => (row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_status')}</span>,
                accessor: 'status',
                Cell: (row) => (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span className={`order-list__status ${row.value}`}>{row.value ? t([`page_content.orders.statuses.${row.value}`]) : ''}</span>
                  </div>),
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_done')}</span>,
                accessor: 'items_done',
                Cell: (row) => <div style={{ textAlign: 'right' }}>{`${numberSeparatorFormat(i18n.language, row.value, 0, 0, true)}`}</div>,
                width: 90,
              },
              {
                Header: () => <span>{t('page_content.orders.table_column_total')}</span>,
                accessor: 'items_total',
                Cell: (row) => <div style={{ textAlign: 'right' }}>{`${numberSeparatorFormat(i18n.language, row.value, 0, 0, true)}`}</div>,
                width: 90,
              },
            ]}
            data={orders}
            defaultPageSize={100}
            loading={isLoadingOrders}
            minRows={0}
            noDataText=" "
            showPagination={false}
            handleClick={(rowInfo) => this.redirectToDetails(rowInfo)}
            stickyHeader={!(orders.length <= 1)}
            defaultSorted={[{ id: 'updated_at', desc: true }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />

          <div>
            <TableButtons next={next} previous={previous} fetchFunction={this.fetchData} count={count} />
          </div>
        </main>

        <OrdersGantt
          companyId={companyId}
          projectId={projectId}
        />
      </div>
    );
  }
}

ProjectOrdersTab.propTypes = {
  projectId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object,
};

export default withRouter(withTranslation()(ProjectOrdersTab));
