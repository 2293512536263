import moment from 'moment';
import i18n from 'i18next';
import 'moment/locale/hr';

export const timeRangeEnum = {
  custom: 'custom',
  // nth_week: 'nth_week',
  last_15_minutes: 'last_15_minutes',
  last_30_minutes: 'last_30_minutes',
  last_hour: 'last_hour',
  last_3_hours: 'last_3_hours',
  last_6_hours: 'last_6_hours',
  last_12_hours: 'last_12_hours',
  last_24_hours: 'last_24_hours',
  last_30_days: 'last_30_days',
  last_7_days: 'last_7_days',
  last_month: 'last_month',
  last_week: 'last_week',
  this_month: 'this_month',
  this_week: 'this_week',
  today: 'today',
  yesterday: 'yesterday',
  previous_shift: 'previous_shift',
  current_shift: 'current_shift',
  next_shift: 'next_shift',
  customYesterday: 'customYesterday',
  customToday: 'customToday',
};

export const timeRangeList = [
  timeRangeEnum.custom,
  // timeRangeEnum.nth_week,
  timeRangeEnum.last_24_hours,
  timeRangeEnum.last_30_days,
  timeRangeEnum.last_7_days,
  timeRangeEnum.last_month,
  timeRangeEnum.last_week,
  timeRangeEnum.this_month,
  timeRangeEnum.this_week,
  timeRangeEnum.today,
  timeRangeEnum.yesterday,
  timeRangeEnum.last_12_hours,
  timeRangeEnum.last_6_hours,
  timeRangeEnum.last_3_hours,
  timeRangeEnum.last_hour,
  timeRangeEnum.last_30_minutes,
  timeRangeEnum.last_15_minutes,
  timeRangeEnum.last_5_minutes,
  timeRangeEnum.previous_shift,
  timeRangeEnum.current_shift,
  timeRangeEnum.next_shift,
  timeRangeEnum.customYesterday,
  timeRangeEnum.customToday,
];

export const timeRangeConfig = () => ({
  [timeRangeEnum.custom]: {
    startDate: null,
    endDate: null,
  },
  [timeRangeEnum.customYesterday]: {
    startDate: null,
    endDate: null,
    name: i18n.t('page_content.dashboards.time_range_dropdown.yesterday'),
  },
  [timeRangeEnum.customToday]: {
    startDate: null,
    endDate: null,
    name: i18n.t('page_content.dashboards.time_range_dropdown.today'),
  },
  // [timeRangeEnum.nth_week]: {
  //   startDate: null,
  //   endDate: null,
  // },
  [timeRangeEnum.previous_shift]: {
    startDate: null,
    endDate: null,
  },
  [timeRangeEnum.current_shift]: {
    startDate: null,
    endDate: null,
  },
  [timeRangeEnum.next_shift]: {
    startDate: null,
    endDate: null,
  },
  [timeRangeEnum.last_24_hours]: {
    startDate: moment().add(-1, 'day'),
    endDate: moment().add(0, 'day'),
    name: i18n.t('page_content.dashboards.time_range_dropdown.last_24_hours'),
  },
  [timeRangeEnum.last_30_days]: {
    startDate: moment().add(-30, 'day'),
    endDate: moment().add(0, 'day'),
    name: i18n.t('page_content.dashboards.time_range_dropdown.last_30_days'),
  },
  [timeRangeEnum.last_7_days]: {
    startDate: moment().subtract(7, 'days'),
    endDate: moment().add(0, 'day'),
    name: i18n.t('page_content.dashboards.time_range_dropdown.last_7_days'),
  },
  [timeRangeEnum.last_month]: {
    startDate: moment().subtract(1, 'months').startOf('month'),
    endDate: moment().subtract(1, 'months').endOf('month'),
    name: i18n.t('page_content.dashboards.time_range_dropdown.last_month'),
  },
  [timeRangeEnum.last_week]: {
    startDate: moment().subtract(1, 'week').startOf('isoWeek'),
    endDate: moment().subtract(1, 'week').endOf('isoWeek'),
    name: i18n.t('page_content.dashboards.time_range_dropdown.last_week'),
  },
  [timeRangeEnum.this_month]: {
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    name: i18n.t('page_content.dashboards.time_range_dropdown.this_month'),
  },
  [timeRangeEnum.this_week]: {
    startDate: moment().startOf('isoWeek'),
    endDate: moment().endOf('isoWeek'),
    name: i18n.t('page_content.dashboards.time_range_dropdown.this_week'),
  },
  [timeRangeEnum.yesterday]: {
    startDate: moment().subtract(1, 'days').startOf('day'),
    endDate: moment().subtract(1, 'days').endOf('day'),
    name: i18n.t('page_content.dashboards.time_range_dropdown.yesterday'),
  },
  [timeRangeEnum.today]: {
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
    name: i18n.t('page_content.dashboards.time_range_dropdown.today'),
  },
  [timeRangeEnum.last_12_hours]: {
    startDate: moment().subtract(12, 'hours'),
    endDate: moment(),
    name: i18n.t('page_content.dashboards.time_range_dropdown.last_12_hours'),
  },
  [timeRangeEnum.last_6_hours]: {
    startDate: moment().subtract(6, 'hours'),
    endDate: moment(),
    name: 'Last 6 hours',
  },
  [timeRangeEnum.last_3_hours]: {
    startDate: moment().subtract(3, 'hours'),
    endDate: moment(),
    name: i18n.t('page_content.dashboards.time_range_dropdown.last_3_hours'),
  },
  [timeRangeEnum.last_hour]: {
    startDate: moment().subtract(60, 'minutes'),
    endDate: moment(),
    name: i18n.t('page_content.dashboards.time_range_dropdown.last_hour'),
  },
  [timeRangeEnum.last_30_minutes]: {
    startDate: moment().subtract(30, 'minutes'),
    endDate: moment(),
    name: i18n.t('page_content.dashboards.time_range_dropdown.last_30_minutes'),
  },
  [timeRangeEnum.last_15_minutes]: {
    startDate: moment().subtract(15, 'minutes'),
    endDate: moment(),
    name: i18n.t('page_content.dashboards.time_range_dropdown.last_15_minutes'),
  },
  [timeRangeEnum.last_5_minutes]: {
    startDate: moment().subtract(5, 'minutes'),
    endDate: moment(),
    name: 'Last 5 minutes',
  },
});
export function getLocale(t) {
  const days = t('date_picker_locale.days', { returnObjects: true });
  const months = t('date_picker_locale.months', { returnObjects: true });
  const locale = {
    match: () => '', // Empty callback for match property error
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => 'mm/dd/yyyy',
      time: () => 'HH:mm',
    },
    options: {
      weekStartsOn: i18n.language !== 'en' ? 1 : 0,
      firstWeekContainsDate: 1,
    },
  };
  return locale;
}

export const defaultStartDate = moment().subtract(1, 'days');
export const defaultEndDate = moment();
