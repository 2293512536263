import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
// import api from 'helpers/api';
import * as userActions from 'user/actions';
import * as actions from '../actions';
import LoginPage from './LoginPage/index';
import ForgotPasswordPage from './ForgotPasswordPage/index';
import ResetPasswordPage from './ResetPasswordPage/index';
import localStorageTokenKey from './constants';
import AccountContainer from './../../account/AccountContainer/index';
import './style.scss';
import ResetMessagePage from './ResetMessagePage/index';

class AuthContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      authChecked: false,
    };
  }

  componentDidMount() {
    const {
      history: {
        location: {
          pathname,
        },
      },
      getCurrentUser,
    } = this.props;

    const token = localStorage.getItem(localStorageTokenKey);
    if (token !== null && token.length > 0) {
      // fetch user when auth token exists
      getCurrentUser()
        .then((res) => { return res; })
        .catch((error) => { console.log(error); });
    } else {
      // redirections when no auth token
      const pageSegments = pathname.split('/').filter((segment) => segment !== '');
      if (pageSegments[0] !== 'login' && pageSegments[0] !== 'forgot-password' && pageSegments[0] !== 'reset-password' && pageSegments[0] !== 'reset-message') {
        window.location.href = '/login';
      }
    }
  }

  isValidCurrentUser = () => {
    const {
      currentUser,
    } = this.props;
    return currentUser !== null && typeof currentUser === 'object' && Object.keys(currentUser).length > 0;
  }

  render() {
    return (
      <Fragment>

        {this.isValidCurrentUser() &&
          <Fragment>
            {this.props.children}
          </Fragment>}

        <Switch>
          {this.isValidCurrentUser() === false &&
            <Route exact path="/" component={LoginPage} />}
          {this.isValidCurrentUser() === true &&
            <Route exact path="/" component={AccountContainer} />}
          <Route path="/login" component={LoginPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/reset-message" component={ResetMessagePage} />
          <Route path="/reset-password" component={ResetPasswordPage} />
        </Switch>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.app,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  fetchPageMeta: actions.fetchPageMeta,
  getCurrentUser: userActions.getCurrentUser,
};

AuthContainer.defaultProps = {
  currentUser: null,
};

AuthContainer.propTypes = {
  children: PropTypes.any,
  currentUser: PropTypes.any,
  getCurrentUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthContainer));
