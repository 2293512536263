import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import './styles.scss';

class AdvancedColorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: props.color,
      isPickerShown: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleChange(color) {
    this.setState({
      color: color.hex,
    });
    this.props.onColorUpdate(color.hex);
  }

  handleOpen() {
    const { isPickerShown } = this.state;
    this.setState({ isPickerShown: !isPickerShown });
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  handleClickOutside(e) {
    if (this.popupContainer.contains(e.target)) {
      return;
    }
    this.setState({ isPickerShown: false });
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  render() {
    return (
      <div className="advanced-color-picker-container">
        <div
          className="color"
          onClick={this.handleOpen}
          style={{ background: this.state.color }}
        />
        {
          this.state.isPickerShown ? (
            // eslint-disable-next-line no-return-assign
            <div className="color-picker-popup" ref={(cont) => (this.popupContainer = cont)}>
              <ChromePicker
                color={this.state.color}
                disableAlpha
                onChangeComplete={this.handleChange}
              />
            </div>
          ) : null
        }
      </div>
    );
  }
}

AdvancedColorPicker.propTypes = {
  color: PropTypes.string,
  onColorUpdate: PropTypes.func.isRequired,
};

AdvancedColorPicker.defaultProps = {
  color: '#777',
};

export default AdvancedColorPicker;
