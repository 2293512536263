import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import api from 'helpers/api';
import {
  AssetPicker,
  MetricPicker,
} from 'shared';
import './style.scss';

class CalculatedValueForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asset: props.datapoint && props.datapoint.metric && props.datapoint.metric.asset,
    };
  }

  componentDidMount() {
    this.getShifts();
    this.getProductTypes();
  }

  componentDidUpdate(prevProps) {
    if (this.props && this.props.datapoint && this.props.datapoint.metric && this.props.datapoint.metric.asset && prevProps && prevProps.datapoint && prevProps.datapoint.metric && prevProps.datapoint.metric.asset) {
      if (prevProps.datapoint.metric.asset !== this.props.datapoint.metric.asset) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          asset: this.props.datapoint.metric.asset,
        });
      }
    }
  }

  getShifts = () => {
    const { locationId } = this.props;
    api.get(`/api/v1/shifts/shifts/?location=${locationId}`)
      .then((re) => {
        if (re.status === undefined || re.status === 400 || re.status === 403 || re.status === 404 || re.status === 500 || !re || !re.data || !re.data.results) {
          return Notification('error', 'An error occurred while fetching shifts', 'We could not perform your request, please try again.');
        }
        this.setState({
          shifts: re.data.results,
        });
      });
  }

  getProductTypes = () => {
    const urlSplit = window.location.href.split('/');
    const companyIdIndex = urlSplit.indexOf('industry') - 1;
    const companyId = urlSplit[companyIdIndex];

    api.get(`/api/v1/product_types/?company=${companyId}`)
      .then((re) => {
        if (re.status === undefined || re.status === 400 || re.status === 403 || re.status === 404 || re.status === 500 || !re || !re.data || !re.data.results) {
          return Notification('error', 'An error occurred while fetching product types', 'We could not perform your request, please try again.');
        }
        this.setState({
          productTypes: re.data.results,
        });
      });
  }

  updateField = (field, value) => {
    const {
      onChange,
      datapoint,
    } = this.props;
    if (field === 'alias') {
      datapoint.style.alias = value;
      onChange(datapoint);
      return;
    }

    onChange({ ...datapoint, [field]: value });
  }

  render() {
    const {
      datapoint,
      disabled,
      id,
      t,
    } = this.props;
    const { asset } = this.state;

    const {
      metric,
      style,
    } = datapoint;

    const alias = style.alias;

    const {
      locationId,
    } = this.props;

    return (
      <div className={`calculated-values-form ${disabled ? 'disabled' : ''}`}>
        <table>
          <tbody>
            <tr>
              <td className="label">
                {t('page_content.dashboards.edit_widget_modal.calculated_values_tab.alias')}
              </td>
              <td className="content-td" colSpan="2">
                <input
                  style={{ width: '300px' }}
                  onChange={(e) => this.updateField('alias', e.target.value)}
                  value={alias}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td className="label">
                {t('page_content.dashboards.edit_widget_modal.calculated_values_tab.source')}
              </td>
              <td className="content-td">
                <AssetPicker
                  changeAsset={(val) => this.setState({ asset: val })}
                  resetMetric={() => this.updateField('metric', null)}
                  disabled={disabled}
                  asset={asset}
                  locationId={locationId}
                />
              </td>
              {
                asset !== null &&
                <td className="content-td">
                  <MetricPicker
                    changeMetric={(val) => {
                      this.updateField('metric', val);
                      setTimeout(() => {
                        if (!val) {
                          return;
                        }
                        this.updateField('label', `${asset.name || ''}: ${val.label} [${val.value_type.unit}]`);
                      }, 200);
                    }}
                    disabled={disabled}
                    metric={metric}
                    asset={!!asset && typeof asset === 'object' ? asset.id : asset}
                    id={id}
                  />
                </td>
              }
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

CalculatedValueForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  disabled: PropTypes.any,
  id: PropTypes.number.isRequired,
  datapoint: PropTypes.object,
  metric: PropTypes.any,
  asset: PropTypes.any,
  t: PropTypes.func.isRequired,
};

export default memo(withTranslation()(CalculatedValueForm));
