import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { IconSettings } from 'shared/Icons';
import { checkAccess } from 'industry/helpers';
import { Notification } from 'shared';
import './styles.scss';

class CompanySettingsMenu extends Component {
  handleUserNavigation = (e) => {
    e.preventDefault();
    const { companyId, history } = this.props;
    const source = e.target || e.srcElement;

    const getHref = (el) => {
      if (el.href) {
        return el.href;
      }
      return getHref(el.parentElement);
    };
    const url = new URL(getHref(source));
    const resource = url.pathname;

    checkAccess(resource, companyId)
      .then((re) => {
        const data = re.data;
        const access = data.access;
        if (access === 0) {
          Notification('error', 'Access denied', 'You do not have the authorization to access this resource.');
        } else if (access === 10 || access === 5) {
          // full access
          history.push(resource, { forced: true });
        } else if (access === 1) {
          // read only
          if (!window.resourceReadOnly) {
            window.resourceReadOnly = [];
          }

          window.resourceReadOnly.push(resource);
          history.push(resource, { forced: true });
        }
      });
  }

  render() {
    const { companyId, currentLocation } = this.props;
    return (
      <div className="header-link help-menu">
        <Link
          className="link"
          to={`/${companyId}/industry/location/${currentLocation ? currentLocation.id : null}/settings/`}
          tabIndex="0"
          style={{ padding: '5px' }}
          onClick={this.handleUserNavigation}
        >
          <IconSettings
            height="34px"
            width="34px"
          />
        </Link>
      </div>
    );
  }
}

CompanySettingsMenu.propTypes = {
  companyId: PropTypes.number.isRequired,
  currentLocation: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(CompanySettingsMenu);
