/* eslint-disable import/prefer-default-export */
import api from 'helpers/api';
import { Notification } from 'shared';

export const getCompanyPartners = async (companyId, partnerName) => {
  const urlString = '/api/v1/partners/partners';
  let urlParams = '?limit=30';

  if (companyId) {
    urlParams += `&company=${companyId}`;
  }
  if (partnerName) {
    urlParams += `&name=${partnerName}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching company partners', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedPartners = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching partners', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addCompanyPartner = async (partnerData) => {
  return api.post('/api/v1/partners/partners/', partnerData);
};

export const editCompanyPartner = async (externalId, companyId, partnerData) => {
  return api.put(`/api/v1/partners/partners/${externalId}/?company=${companyId}`, partnerData);
};

export const deleteCompanyPartner = async (externalId, companyId) => {
  return api.delete(`/api/v1/partners/partners/${externalId}/?company=${companyId}`);
};
