import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { IconPopup } from 'shared/Icons';
import { Table, TableButtons, Button } from 'shared';
import { defaultDateTimeFormat } from 'shared/constants';
import { styledAPIEventsOptions } from 'industry/helpers';
import { getAPIEvents, getPaginatedAPIEvents, triggerOrderImport } from '../../actions';

import EventsModal from './components/EventsModal';
import './styles.scss';

const APIEvents = ({ t, companyId, locationId, order, isReadOnly }) => {
  const [tableData, setTableData] = useState({
    events: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [isLoadingTrigger, setIsLoadingTrigger] = useState(false);
  const [selectedAscDesc, setSelectedAscDesc] = useState('desc');
  const [selectedSort, setSelectedSort] = useState('created_at');

  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedEvent: null,
  });

  const fetchEvents = async () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const asc = selectedAscDesc === 'desc' ? '-' : '';
    let filters = `&order_by=${asc}${selectedSort}`;

    if (order?.external_id) {
      filters += `&external_id=${order?.external_id}`;
    }

    const res = await getAPIEvents(companyId, locationId, filters);
    setTableData({
      events: get(res, 'data.results', []),
      next: get(res, 'data.next', null),
      previous: get(res, 'data.previous', null),
      count: get(res, 'data.count', null),
      isLoading: false,
    });
  };

  const fetchPaginatedEvents = async (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const res = await getPaginatedAPIEvents(url);
    setTableData({
      events: get(res, 'data.results', []),
      next: get(res, 'data.next', null),
      previous: get(res, 'data.previous', null),
      count: get(res, 'data.count', null),
      isLoading: false,
    });
  };

  useEffect(() => {
    fetchEvents();
  }, [selectedAscDesc, selectedSort]);

  const handleSorting = (sortData) => {
    setSelectedSort(sortData.id);
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
  };

  const openModal = (row) => {
    setModalData({
      isOpen: true,
      selectedEvent: get(row, 'original', {}),
    });
  };

  const closeModal = () => {
    setModalData({
      isOpen: false,
      selectedCompanyTable: null,
    });
  };

  const handleTriggerImport = () => {
    setIsLoadingTrigger(true);

    const payload = {
      company: companyId,
      external_id: order?.external_id || null,
    };

    triggerOrderImport(payload)
      .then(() => {
        setIsLoadingTrigger(false);
        fetchEvents();
      });
  };

  return (
        <div>
            <div className="action_button">
            <Button
              type="dark-blue"
              onClick={handleTriggerImport}
              loading={isLoadingTrigger}
            >
                {t('page_content.orders.order_details.api_events.order_refresh')}
            </Button>
            </div>

            <Table
              style={{ userSelect: 'text' }}
              columns={[
                {
                  Header: () => <span>{t('page_content.orders.order_details.api_events.action_code')}</span>,
                  accessor: 'action_code',
                  Cell: (row) => (get(row, 'value', '-')),
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.api_events.external_id')}</span>,
                  accessor: 'external_id',
                  Cell: (row) => (get(row, 'value', '-')),
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.api_events.created_at')}</span>,
                  accessor: 'created_at',
                  Cell: (row) => (get(row, 'value') ? moment(row?.value).format(defaultDateTimeFormat) : '-'),
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.api_events.status')}</span>,
                  accessor: 'status',
                  width: 180,
                  Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={styledAPIEventsOptions(row?.value)}>{row?.value ?
                              t([`page_content.orders.order_details.api_events.${row?.value}`]) : ''}</span>
                        </div>,
                  style: {
                    cursor: 'default',
                  },
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.api_events.payload')}</span>,
                  accessor: 'payload',
                  width: 180,
                  sortable: false,
                  Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button disabled={isReadOnly} style={{ width: 55, padding: '5px 8px' }} onClick={() => openModal(row)}>
                                <IconPopup
                                  height="14px"
                                  width="14px"
                                  fill="#4285F4"
                                />
                            </Button>
                        </div>),
                },
              ]}
              data={tableData?.events || []}
              loading={tableData?.isLoading}
              noDataText=" "
              showPagination={false}
              minRows={0}
              defaultPageSize={30}
              selectedRow={null}
              defaultSorted={[{ id: 'desc', desc: true }]}
              onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
            />
            <TableButtons
              next={tableData?.next}
              previous={tableData?.previous}
              fetchFunction={fetchPaginatedEvents}
              count={tableData?.count}
            />

            {modalData?.isOpen &&
                <EventsModal
                  isOpen={modalData.isOpen}
                  selectedEvent={modalData.selectedEvent}
                  handleCloseModal={closeModal}
                />}
        </div>
  );
};

APIEvents.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  order: PropTypes.any,
};

export default (APIEvents);
