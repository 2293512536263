import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const LimitedTextarea = ({ rows, cols, value, limit, resizable, maxHeight, onChange, autoResize, width }) => {
  const [content, setContent] = React.useState(value.slice(0, limit));
  const textareaRef = useRef(null);

  const setFormattedContent = React.useCallback(
    (text) => {
      const newValue = text.slice(0, limit);
      setContent(newValue);
      onChange(newValue);
    },
    [limit, onChange],
  );

  useEffect(() => {
    if (autoResize && textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [content, autoResize]);

  return (
    <div className="limited_text_area">
      <textarea
        ref={textareaRef}
        className="limited_text_area__text"
        rows={rows}
        cols={cols}
        onChange={(event) => setFormattedContent(event.target.value)}
        value={content}
        style={{
          resize: resizable ? 'vertical' : 'none',
          maxHeight,
          width,
        }}
      />
      {limit !== Infinity && (
        <span className="limited_text_area__limit">
          {content.length}/{limit}
        </span>
      )}
    </div>
  );
};

LimitedTextarea.propTypes = {
  rows: PropTypes.number,
  cols: PropTypes.number,
  limit: PropTypes.number,
  resizable: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  autoResize: PropTypes.bool,
  width: PropTypes.string,
};

LimitedTextarea.defaultProps = {
  rows: 0,
  cols: 0,
  value: '',
  limit: 300,
  resizable: true,
  maxHeight: '300px',
  autoResize: false,
  width: '100%',
};

export default LimitedTextarea;
