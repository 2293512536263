import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './styles.scss';
import { Modal } from 'shared';
import { IconWarning, IconInfo } from 'shared/Icons';

const ConfirmationModal = ({ t, showModal, customTitle, description, handleCloseModal, handleConfirmModal, type, itemName, size = 'small' }) => {
  const renderTitle = () => {
    return <div className="confirmation_title">{!customTitle ? t('shared.confirmation_modal.default_title', { itemName }) : customTitle}
        <div className="confirmation_title_icon">
            {type === 'warning' && <IconWarning width="25px" height="25px" color="red" />}
            {type === 'info' && <IconInfo width="25px" height="25px" />}
        </div>
        </div>;
  };

  return (
    <Modal
      isOpen={showModal}
      title={renderTitle()}
      size={size}
      closeText={!handleConfirmModal ? t('shared.confirmation_modal.confirmation_close') : t('shared.confirmation_modal.confirmation_no')}
      saveText={t('shared.confirmation_modal.confirmation_yes')}
      handleClose={handleCloseModal}
      handleSave={handleConfirmModal}
    >
        {
            description &&
            <div className="confirmation_modal_content">
            {description}
        </div>
        }
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  t: PropTypes.func,
  showModal: PropTypes.bool,
  customTitle: PropTypes.string,
  description: PropTypes.string,
  itemName: PropTypes.string,
  handleCloseModal: PropTypes.func,
  handleConfirmModal: PropTypes.func,
  type: PropTypes.oneOf(['warning', 'info']),
  size: PropTypes.string,
};

export default (withTranslation()(ConfirmationModal));
