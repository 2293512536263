import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import {
  Marker,
  Popup,
} from 'react-leaflet';
import './styles.scss';

class CustomMarker extends Component {
  render() {
    const {
      color,
      data,
      position,
    } = this.props;

    const markerContent = `<div style="background: ${color || '#777'}">${data.style.label || ''}</div>`;

    const myIcon = L.divIcon({
      className: `custom-marker ${color}`,
      html: markerContent,
      iconSize: [15, 15],
    });

    return (
      <Marker
        icon={myIcon}
        position={position}
      >
        <Popup>
          <span>{data.metric.name}</span>
        </Popup>
      </Marker>
    );
  }
}

CustomMarker.propTypes = {
  color: PropTypes.string,
  data: PropTypes.object.isRequired,
  position: PropTypes.any.isRequired,
};

export default CustomMarker;
