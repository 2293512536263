/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import { selectStyles } from 'styles/modules/reactSelect';
import { Button, ContentLoader, Table, Modal } from 'shared';
import { defaultDateTimeFormat, modalSizes } from 'shared/constants';
import { withTranslation } from 'react-i18next';
import OrderPartDetails from '../../components/OrderPartDetails';
import './style.scss';
import { TableButtons } from '../../../../../shared/index';

class OrderParts extends React.Component {
  render() {
    const { displaySnowflakeModal, countParts } = this.props;

    const defaultStatusOptions = [{ label: 'All', value: 'all' }, { label: 'Created', value: 'created' },
      { label: 'In progress', value: 'in_progress' }, { label: 'Finished', value: 'finished' }, { label: 'Discarded', value: 'discarded' },
      { label: 'Unknown', value: 'unknown' }];

    return (
      <div className="orders_parts_container">
        <Modal
          isOpen={this.props.isPartsModalOpen}
          handleClose={this.props.closePartsModal}
          size={modalSizes.large}
          title={this.props.t('page_content.orders.order_details.order_parts_tab.order_parts_modal.modal_title')}
        >
          {
            this.props.isLoadingPartsModal &&
            <ContentLoader />
          }
          {
            !this.props.isLoadingPartsModal && this.props.partsModalData &&
            <OrderPartDetails data={this.props.partsModalData} />
          }
        </Modal>
        <div className="filters_row">
          <div className="element_text_wrapper">
            {this.props.t('page_content.orders.order_details.order_parts_tab.status')}:
            <Select
              options={defaultStatusOptions}
              getOptionLabel={(status) => status.label}
              getOptionValue={(status) => status.value}
              menuPosition="fixed"
              onChange={(e) => this.props.onPartsStatusChange({ target: e })}
              value={defaultStatusOptions.find((ds) => ds.value === this.props.selectedPartsStatus)}
              styles={selectStyles}
            />
          </div>
          <div className="element_text_wrapper">
            {this.props.t('page_content.orders.order_details.order_parts_tab.order_name')}:
            <div className="input_container">
              <input type="text" value={this.props.selectedPartsOrderName} onChange={this.props.onPartsOrderNameChange} />
            </div>
          </div>
          <div className="element_text_wrapper">
            {this.props.t('page_content.orders.order_details.order_parts_tab.operator')}:
            <div className="input_container">
              <input type="text" value={this.props.selectedPartsOperator} onChange={this.props.onPartsOperatorChange} />
            </div>
          </div>
          <div className="element_text_wrapper">
            {this.props.t('page_content.orders.order_details.order_parts_tab.snowflake')}:
            <div className="input_container">
              <input type="text" value={this.props.selectedPartsSnowflake} onChange={this.props.onPartsSnowflakeChange} />
            </div>
          </div>
          <div className="clear_all_button">
            <Button
              onClick={this.props.clearAllPartsFilters}
            >
              {this.props.t('page_content.orders.order_details.order_parts_tab.clear_all_button')}:
            </Button>
          </div>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.order_parts_tab.table_column_id')}</span>,
              acessor: 'snowflake',
              Cell: (row) => <span>{row.original.snowflake && row.original.snowflake.snowflake_id ? row.original.snowflake.snowflake_id : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.order_parts_tab.table_column_item_name')}</span>,
              accessor: 'order_item.name',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.order_parts_tab.table_column_position')}</span>,
              accessor: 'order_item.item_num',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.order_parts_tab.table_column_created')}</span>,
              accessor: 'created_at',
              Cell: (row) => moment(row.value).format(defaultDateTimeFormat),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.order_parts_tab.table_column_details')}</span>,
              accessor: 'snowflake.image_url',
              Cell: (row) => (<Button onClick={(e) => { e.stopPropagation(); displaySnowflakeModal(row.value); }}>{this.props.t('page_content.orders.order_details.order_parts_tab.table_column_details_button')}</Button>),
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.order_parts_tab.table_column_status')}</span>,
              accessor: 'status',
              Cell: (row) => <span className={`order-details-table__status ${row.value}`}>{row.value ? this.props.t([`page_content.orders.statuses.${row.value}`]) : ''}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.order_parts_tab.table_column_updated')}</span>,
              accessor: 'updated_at',
              Cell: (row) => moment(row.value).format(defaultDateTimeFormat),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.order_parts_tab.table_column_operators')}</span>,
              accessor: 'operator',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
          ]}
          data={this.props.parts}
          minRows={0}
          defaultPageSize={50}
          noDataText=" "
          showPagination={false}
          sortable
          onSortedChange={(newSorted) => { this.props.handleSortingParts(newSorted[0]); }}
          defaultSorted={[{ id: 'updated_at', desc: true }]}
          loading={this.props.isLoadingParts}
          handleClick={(rowInfo) => this.props.openPartsModal(rowInfo.original)}
        />
        <div style={{ float: 'right' }}>
          <TableButtons
            next={this.props.next}
            previous={this.props.previous}
            fetchFunction={this.props.getPaginatedParts}
            showCount
            count={countParts}
          />
        </div>
      </div>);
  }
}

OrderParts.propTypes = {
  previous: PropTypes.any,
  next: PropTypes.any,
  countParts: PropTypes.number,
  t: PropTypes.any,
  parts: PropTypes.any,
  isLoadingParts: PropTypes.bool,
  selectedPartsOrderName: PropTypes.string,
  selectedPartsOperator: PropTypes.string,
  selectedPartsStatus: PropTypes.string,
  selectedPartsSnowflake: PropTypes.any,
  isPartsModalOpen: PropTypes.bool,
  partsModalData: PropTypes.any,
  isLoadingPartsModal: PropTypes.bool,
  onPartsStatusChange: PropTypes.func,
  closePartsModal: PropTypes.func,
  getPaginatedParts: PropTypes.func,
  openPartsModal: PropTypes.func,
  handleSortingParts: PropTypes.func,
  clearAllPartsFilters: PropTypes.func,
  onPartsSnowflakeChange: PropTypes.func,
  onPartsOperatorChange: PropTypes.func,
  onPartsOrderNameChange: PropTypes.func,
  displaySnowflakeModal: PropTypes.func,
};

export default withTranslation()(OrderParts);
