import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Button, ContentLoader } from 'shared';
import dukatGasti from 'shared/Icons/dukat_gasti_cups.png';
import dukatKronesLOT from 'shared/Icons/dukat_krones_lot.png';
import dukatKronesSticker from 'shared/Icons/dukat_krones_sticker.png';
import dukatKronesPacking from 'shared/Icons/dukat_krones_packing.png';
import dukatKronesFilling from 'shared/Icons/dukat_krones_filling_and_cap.png';
import { getAssets } from './actions';
import './style.scss';
import OCRTab from './components/OCRTab/index';
import Complaints from './components/ComplaintsTab/index';

class PhotoLibraryAssetList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      assets: [],
      isGallery: true,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { locationId },
      },
    } = this.props;

    getAssets(locationId)
      .then((res) => {
        this.setState({
          assets: get(res, 'data.results', []),
          isLoading: false,
        });
      });
  }

  handleGalleryClick = () => {
    this.setState({
      isGallery: true,
    });
  }

  renderAssetCards(assets) {
    const {
      match: {
        params: { locationId, companyId },
      },
      t,
    } = this.props;

    const { isGallery } = this.state;

    if (assets && isGallery) {
      return (
        <div className="asset_cards_wrapper">
          {assets.map((asset, index) => {
            const asset_name = asset && asset.name ? asset.name : '';
            let image_url = '';

            switch (asset_name) {
              case 'Dukat Gasti Cups':
                image_url = dukatGasti;
                break;
              case 'Dukat Krones LOT':
                image_url = dukatKronesLOT;
                break;
              case 'Dukat Krones Filling and Cap':
                image_url = dukatKronesFilling;
                break;
              case 'Dukat Krones Packing':
                image_url = dukatKronesPacking;
                break;
              case 'Dukat Krones Sticker':
                image_url = dukatKronesSticker;
                break;
              default:
                image_url = 'https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png?w=640';
            }

            return (
              asset && asset.enable_photo_library &&
              <div className="asset_card" key={index}>
                <img src={image_url} className="asset_card_image" alt="asset_card" />
                <div className="asset_card_text">
                  <p className="asset_card_text_title">{asset.name}</p>
                </div>
                <Link to={`/${companyId}/industry/location/${locationId}/quality-inspection/asset/${asset.id}/gallery`}>
                  <Button type="dark-blue" onClick={() => { }}>
                    {t('page_content.product_library.view_all_photos_button')}
                  </Button>
                </Link>
              </div>
            );
          })}
        </div>
      );
    }
  }

  render() {
    const {
      match: {
        params: { locationId, companyId },
      },
      t,
      ocrIntegrations,
      qualityInspectionTabs,
    } = this.props;
    const { isLoading, assets } = this.state;

    const defaultQualityInspectionTabs = qualityInspectionTabs || [{ name: 'Gallery' }];

    if (isLoading) {
      return (
        <div className="loaderWrapper">
          <ContentLoader />
        </div>
      );
    }

    return (
      <div className="industry-tab photo_library fullscreen">
        <Tabs>
          <TabList>
            {defaultQualityInspectionTabs?.some((qi) => qi.name === 'Gallery') && <Tab onClick={this.handleGalleryClick}>{t('page_content.product_library.gallery')}</Tab>}
            {defaultQualityInspectionTabs?.some((qi) => qi.name === 'Complaints') && <Tab>{t('page_content.complaints.complaints_title')}</Tab>}
            {ocrIntegrations && <Tab>{t('page_content.product_library.ocr_integrations')}</Tab>}
          </TabList>

          {defaultQualityInspectionTabs?.find((qi) => qi.name === 'Gallery') && <TabPanel>
            {this.renderAssetCards(assets)}
          </TabPanel>}

          {defaultQualityInspectionTabs?.find((qi) => qi.name === 'Complaints') && <TabPanel>
            <Complaints
              t={t}
              locationId={locationId}
            />
          </TabPanel>}

          {
            ocrIntegrations &&
            <TabPanel>
              <OCRTab
                t={t}
                locationId={locationId}
                assets={assets}
                companyId={companyId}
              />
            </TabPanel>
          }

        </Tabs>
      </div>
    );
  }
}

PhotoLibraryAssetList.propTypes = {
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  ocrIntegrations: PropTypes.bool,
  qualityInspectionTabs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ocrIntegrations: get(state, 'app.location.config.ocr_integrations', false),
    qualityInspectionTabs: get(state, 'app.location.config.quality_inspection_tabs', null),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(PhotoLibraryAssetList));
