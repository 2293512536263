import api from 'helpers/api';
import { Notification } from 'shared';

export const getUsers = (companyId) => api.get(`/api/v1/company_members/?company=${companyId}&limit=30`);
export const searchUsers = (companyId, locationId, email = '') => api.get(`/api/v1/company_members/?company=${companyId}&location=${locationId}${email}`);
export const createOrUpdateUser = (companyId, data, isEdit, id) => {
  if (isEdit && id) {
    return api.put(`/api/v1/company_members/${id}/?company=${companyId}`, data);
  }

  return api.post(`/api/v1/company_members/?company=${companyId}`, data);
};
export const getRoles = (url) => api.get(`${url}`);
export const createRole = (companyId, data) => {
  return api.post(`/api/v1/roles/?company=${companyId}`, data);
};
export const editRole = (companyId, data) => {
  return api.patch(`/api/v1/roles/${data.id}/?company=${companyId}`, data);
};
export const getUserRoles = (companyId) => api.get(`/api/v1/role_users/?company=${companyId}`);
export const assignRoleToUser = (companyId, payload) => api.post(`/api/v1/role_users/?company=${companyId}`, payload);
export const deleteUserRole = (companyId, id) => api.delete(`/api/v1/role_users/${id}/?company=${companyId}`);
export const getResources = (companyId) => api.get(`/api/v1/resources/?company=${companyId}`);

export const editResource = (id, companyId, data) => {
  return api.patch(`/api/v1/resources/${id}/?company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Resource was successfully updated.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getRoleResources = (companyId, roleId) => api.get(`/api/v1/role_resources/?company=${companyId}&limit=200&role=${roleId}`);
export const createOrUpdateRoleResource = (companyId, data, id) => {
  if (id) {
    return api.patch(`/api/v1/role_resources/${id}/?company=${companyId}`, data);
  }

  return api.post(`/api/v1/role_resources/?company=${companyId}`, data);
};
export const deleteRoleResource = (companyId, id) => {
  return api.delete(`/api/v1/role_resources/${id}/?company=${companyId}`);
};

export const getLocations = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/sidebar/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching locations', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getUsersUrl = async (url) => {
  try {
    const res = await api
      .get(`${url}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching users', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteUser = async (companyId, id) => {
  try {
    const res = await api
      .delete(`/api/v1/company_members/${id}/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while deleting user', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getWorkerCategories = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/workforce/worker_categories/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching locations', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const sendInvitation = (data) => {
  return api.post('/api/v1/send_invitation/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Invitation sent successfully.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};
