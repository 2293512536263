import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal } from 'shared';
import { modalSizes } from 'shared/constants';

class BookGoodsResponseModal extends Component {
  render() {
    const {
      t,
      isBookGoodsModalOpen,
      closeBookGoodsResponseModal,
      bookGoodsResponse,
    } = this.props;

    return (
      <Modal
        isOpen={isBookGoodsModalOpen}
        handleClose={() => closeBookGoodsResponseModal()}
        closeText={t('shared.modal.close_button_alt')}
        size={modalSizes.large}
      >
        {
          bookGoodsResponse &&
          <div className="message_response">
            <p className="value">{bookGoodsResponse && bookGoodsResponse.message ? bookGoodsResponse.message : '-'}</p>
          </div>
        }
      </Modal>
    );
  }
}

BookGoodsResponseModal.propTypes = {
  t: PropTypes.func.isRequired,
  isBookGoodsModalOpen: PropTypes.bool.isRequired,
  closeBookGoodsResponseModal: PropTypes.func.isRequired,
  bookGoodsResponse: PropTypes.object.isRequired,
};

export default withTranslation()(BookGoodsResponseModal);
