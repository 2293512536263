import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';

import { Table, Button, Modal, ConfirmationModal } from 'shared';
import { IconEdit, IconRemove } from 'shared/Icons';
import { getWorkingPlaces } from '../../actions';
import './styles.scss';
import { addWorkingPlace, deleteWorkingPlace, editWorkingPlace } from './actions';

const WorkingPlacesModal = ({ t, selectedDepartment, showModal, handleCloseModal }) => {
  const [tableData, setTableData] = useState({
    working_places: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [isAddWorkplace, setIsAddWorkplace] = useState(false);
  const [editId, setEditId] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [deleteData, setDeleteData] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const fetchWorkingPlaces = async () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const res = await getWorkingPlaces(selectedDepartment);
    setTableData({
      working_places: get(res, 'data.results', []),
      next: get(res, 'data.next', []),
      previous: get(res, 'data.previous', []),
      count: get(res, 'data.count', []),
      isLoading: false,
    });
  };

  useEffect(() => {
    if (selectedDepartment) {
      fetchWorkingPlaces();
    }
  }, [selectedDepartment]);

  const handleEditButton = (row) => {
    setInputValue(row.original.name);
    setIsAddWorkplace(true);
    setEditId(row.original.id);
  };

  const handleBackButton = () => {
    setInputValue('');
    setIsAddWorkplace(false);
    setEditId(null);
  };

  const handleDeleteButton = async (row) => {
    setShowConfirmationDialog(true);
    setDeleteData(row.original);
  };

  const handleDeleteWorkingPlace = async () => {
    await deleteWorkingPlace(deleteData?.id, selectedDepartment);
    fetchWorkingPlaces();
    setInputValue('');
    setShowConfirmationDialog(false);
    setEditId(null);
  };

  const handleAddWorkspace = async () => {
    if (editId) {
      await editWorkingPlace({ name: inputValue, department: selectedDepartment }, editId);
    } else {
      await addWorkingPlace({ name: inputValue, department: selectedDepartment });
    }
    setInputValue('');
    setIsAddWorkplace(false);
    fetchWorkingPlaces();
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const tableColumns = [
    {
      Header: () => <span>{t('page_content.human_resources.departments.table_column_working_places')}</span>,
      accessor: 'name',
      Cell: (row) => (row && row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.departments.table_column_edit_workplace')}</span>,
      sortable: false,
      width: 100,
      Cell: (row) => <div style={{ margin: 'auto' }}>
        <Button size="small" onClick={() => handleEditButton(row)}>
          <IconEdit
            height="14px"
            width="14px"
            fill="#4285F4"
          />
        </Button>
      </div>,
    },
    {
      Header: () => <span>{t('page_content.human_resources.departments.table_column_delete_workplace')}</span>,
      sortable: false,
      width: 100,
      Cell: (row) => <div style={{ margin: 'auto' }}><Button size="small" type="delete" onClick={() => handleDeleteButton(row)}>
      <IconRemove
        height="14px"
        width="14px"
      />
    </Button></div>,
    },
  ];

  return (
    <Modal title={t('page_content.human_resources.departments.modal_workplaces_title')} isOpen={showModal} handleClose={handleCloseModal} handleSave={isAddWorkplace && handleAddWorkspace}>
      <div style={{ padding: 20 }}>
        <div className="workplace_modal_buttons">
          {isAddWorkplace ?
          <div>
            <Button onClick={handleBackButton} type="dark-blue">{t('page_content.human_resources.departments.modal_workplaces_back_button')}</Button>
          </div> : <div>
            <Button onClick={() => setIsAddWorkplace(true)} type="add">{t('page_content.human_resources.departments.modal_workplaces_add_button')}</Button>
          </div>}
        </div>
        {isAddWorkplace ? <div>
          <div className="modal_row">
            <div className="left_text">
              {t('page_content.human_resources.departments.modal_workplaces_label_name')}
            </div>
            <div className="right_input">
              <input type="text" value={inputValue} onChange={handleInputChange} placeholder={t('page_content.human_resources.departments.modal_workplaces_placeholder_name')} />
            </div>
          </div>
        </div> :
        <Table
          style={{ userSelect: 'text' }}
          columns={tableColumns}
          data={tableData.working_places || []}
          minRows={0}
          defaultPageSize={tableData?.working_places?.length || 100}
          noDataText=" "
          showPagination={false}
          loading={tableData.isLoading}
        />}
      </div>
      <ConfirmationModal
        itemName={deleteData?.name}
        // description={t('page_content.human_resources.departments.modal_workplaces_warning_message')}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleDeleteWorkingPlace}
        type="warning"
      />
    </Modal>
  );
};

WorkingPlacesModal.propTypes = {
  t: PropTypes.func.isRequired,
  selectedDepartment: PropTypes.number,
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
//   companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   locationId: PropTypes.number.isRequired,
};

export default withRouter(withTranslation()(WorkingPlacesModal));
