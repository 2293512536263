import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { defaultDateFormat } from 'shared/constants';
import { Table, TableButtons } from 'shared';

import { styledAssignedEquipmentStatusOptions } from 'industry/helpers';

import { getAssignedEquipment, getPaginatedAssignedEquipment } from '../../../actions';

const EmploymentAssignedEquipmentModalTable = ({ t, companyId, workerId }) => {
  const [assignedEquipment, setAssignedEquipment] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });

  const equipmentStatus = [
    { value: 'taken', name: t('page_content.human_resources.assigned_equipment.status_taken') },
    { value: 'returned', name: t('page_content.human_resources.assigned_equipment.status_returned') },
    { value: 'lost', name: t('page_content.human_resources.assigned_equipment.status_lost') },
    { value: 'damaged', name: t('page_content.human_resources.assigned_equipment.status_damaged') },
  ];

  const fetchAssignedEquipment = () => {
    setAssignedEquipment((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const apiFilters = `&limit=60&order_by=-date_of_take&worker=${workerId}`;

    getAssignedEquipment(companyId, apiFilters)
      .then((res) => {
        setAssignedEquipment({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setAssignedEquipment((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedAssignedEquipment = (url) => {
    setAssignedEquipment((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedAssignedEquipment(url)
      .then((res) => {
        setAssignedEquipment({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setAssignedEquipment((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    if (workerId) fetchAssignedEquipment();
    else setAssignedEquipment({ data: [], next: null, previous: null, count: 0, isLoading: true });
  }, [workerId]);

  return (
    <div className="layoff_form_assigned_equipment">
      <div style={{ padding: '20px 0', fontSize: '14px', fontWeight: 600 }}>
        {t('page_content.human_resources.assigned_equipment_tab')}
      </div>
      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_name')}</span>,
            accessor: 'name',
            Cell: (row) => (row?.value ? row.value : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_quantity')}</span>,
            accessor: 'quantity',
            width: 100,
            Cell: (row) => (row?.value ? row.value : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_taken')}</span>,
            accessor: 'date_of_take',
            width: 100,
            Cell: (row) => (row?.value ? moment(row.value, 'YYYY-MM-DD').format(defaultDateFormat) : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_returned')}</span>,
            accessor: 'date_of_return',
            width: 100,
            Cell: (row) => (row?.value ? moment(row.value, 'YYYY-MM-DD').format(defaultDateFormat) : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_note')}</span>,
            accessor: 'note',
            width: 300,
            Cell: (row) => (row?.value ? row.value : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_status')}</span>,
            accessor: 'status',
            Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span style={row?.value && equipmentStatus?.find((val) => val.value === row?.value)?.name ? styledAssignedEquipmentStatusOptions(row?.value) : {}}>
                {row?.value ? equipmentStatus?.find((val) => val.value === row?.value)?.name : '-'}</span>
            </div>,
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_worker')}</span>,
            accessor: 'worker',
            Cell: (row) => (row?.value?.name && row?.value?.last_name ? `${row.value.name} ${row.value.last_name}` : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_take_clerk')}</span>,
            accessor: 'take_clerk',
            Cell: (row) => (row?.value?.name && row?.value?.last_name ? `${row.value.name} ${row.value.last_name}` : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.assigned_equipment.column_return_clerk')}</span>,
            accessor: 'return_clerk',
            Cell: (row) => (row?.value?.name && row?.value?.last_name ? `${row.value.name} ${row.value.last_name}` : '-'),
            sortable: false,
            style: {
              cursor: 'default',
            },
          },
        ]}
        minRows={0}
        noDataText="No assigned equipment found"
        selectedRow={null}
        defaultPageSize={100}
        showPagination={false}
        data={assignedEquipment?.data || []}
        loading={assignedEquipment?.isLoading}
        defaultSorted={[{ id: 'date_of_take', desc: true }]}
      />
      <div>
        <span style={{ float: 'right', padding: '10px 0' }}>
          <TableButtons
            next={assignedEquipment?.next}
            count={assignedEquipment?.count}
            previous={assignedEquipment?.previous}
            fetchFunction={fetchPaginatedAssignedEquipment}
          />
        </span>
      </div>
    </div>
  );
};

EmploymentAssignedEquipmentModalTable.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  workerId: PropTypes.number.isRequired,
};

export default (withTranslation()(EmploymentAssignedEquipmentModalTable));
