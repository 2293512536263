import React, { Component } from 'react';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import { withTranslation } from 'react-i18next';
import {
  Table,
  Button,
  Notification,
  TableButtons,
  ConfirmationModal,
} from 'shared';
import { deleteFactor } from '../../actions';
import { FactorForm, FactorValuesList } from '../../components';
import '../../styles.scss';

class FactorsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      factors: [],
      next: null,
      previous: null,
      count: 0,
      factorIdEdit: null,
      factorIdValues: null,
      factorIdName: '',
      isLoading: false,
      selectedAscDesc: 'asc',
      selectedSort: 'name',
      deleteData: null,
      showConfirmationDialog: false,
    };
  }

  componentDidMount() {
    this.getFactors(true);
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.getFactors();
    });
  }

  getFactors = (shouldReload) => {
    const { companyId } = this.props;
    const { selectedAscDesc, selectedSort } = this.state;

    if (shouldReload) {
      this.setState({ isLoading: true });
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const sortColumn = `&order_by=${asc}${selectedSort}`;

    api
      .get(`/api/v1/reports/factors/?company=${companyId}${sortColumn}`)
      .then(({ data }) => {
        this.setState({
          isLoading: false,
          factors: data.results,
          next: null,
          previous: null,
          count: 0,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleClickOutside = (e) => {
    if (this.node.contains(e.target) || e.target.classList.contains('rt-td')) {
      return;
    }
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    this.props.handleClose(false);
  }

  handleAdd = () => {
    this.setState({
      factorIdEdit: 0,
    });
  }

  handleEdit = (row) => {
    this.setState({
      factorIdEdit: row.original.id,
    });
  }

  closeEdit = (shouldReload) => {
    this.setState({
      factorIdEdit: null,
    }, () => {
      if (shouldReload) {
        this.getFactors();
      }
    });
  }

  handleShowConfirmationModal = (row) => {
    this.setState({ showConfirmationDialog: true, deleteData: row });
  }

  handleDelete = () => {
    const {
      companyId,
      t,
    } = this.props;

    this.setState({
      isLoading: true,
    });
    const id = this.state.deleteData.original.id;
    deleteFactor(id, companyId)
      .then(() => {
        this.getFactors();
        return Notification('success', 'Delete successful', t('page_content.automatic_reports.factors.success_delete'));
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      })
      .finally(() => this.setState({ showConfirmationDialog: false }));
  }

  handleEditValues = (row) => {
    this.setState({
      factorIdName: row.original.name,
      factorIdValues: row.original.id,
    });
  }

  closeValues = () => {
    this.setState({
      factorIdName: '',
      factorIdValues: null,
    });
  }

  fetchData = (url) => {
    this.setState({ isLoading: true });
    api
      .get(url)
      .then(({ data }) => {
        this.setState({
          isLoading: false,
          factors: data.results,
          next: null,
          previous: null,
          count: 0,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      isLoading,
      factors,
      factorIdEdit,
      factorIdValues,
      factorIdName,
      next,
      previous,
      count,
    } = this.state;

    const {
      companyId,
      t,
    } = this.props;

    return (
      <div className="tab_container">
        <div className="create_button_wrapper">
          <Button type="add" onClick={this.handleAdd}>{t('page_content.automatic_reports.factors.new')}</Button>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.automatic_reports.name')}</span>,
              accessor: 'name',
            },
            {
              Header: () => <span>{t('page_content.automatic_reports.factor_values.title')}</span>,
              Cell: (row) => (<div className="custom_table_row">
                <Button onClick={() => this.handleEditValues(row)}>{t('page_content.automatic_reports.factor_values.button_title')}</Button>
              </div>),
              sortable: false,
            },
            {
              Header: () => <span>{t('page_content.automatic_reports.actions')}</span>,
              Cell: (row) => (<div className="custom_table_row">
                <Button onClick={() => this.handleEdit(row)}>{t('page_content.automatic_reports.edit')}</Button>
                <Button type="delete" onClick={() => this.handleShowConfirmationModal(row)}>{t('page_content.automatic_reports.delete')}</Button>
              </div>),
              sortable: false,
            },
          ]}
          data={(factors) || []}
          defaultPageSize={100}
          minRows={0}
          noDataText=" "
          showPagination={false}
          loading={isLoading}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
        />
        <div style={{ float: 'right' }}>
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchData} count={count} />
        </div>

        <ConfirmationModal
          itemName={this.state.deleteData?.original?.name || ''}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDelete}
          type="warning"
        />

        {factorIdEdit !== null &&
          <FactorForm
            handleClose={this.closeEdit}
            companyId={companyId}
            factorId={factorIdEdit}
          />}

        {factorIdValues !== null &&
          <FactorValuesList
            handleClose={this.closeValues}
            companyId={companyId}
            factorId={factorIdValues}
            factorName={factorIdName}
          />}
      </div>
    );
  }
}

FactorsList.propTypes = {
  companyId: PropTypes.number.isRequired,
  handleClose: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(FactorsList);
