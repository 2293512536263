import React, { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';

import { addEquipmentInfo, editEquipmentInfo } from '../../../actions';

const CompanyValuesModal = ({
  t,
  isOpen,
  companyId,
  currentUser,
  handleCloseModal,
  initialValues = {},
  closeModalAndRefetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);

    const payload = {
      ...formValues,
      company: companyId,
      user: currentUser?.id,
    };

    if (formValues?.id) {
      await editEquipmentInfo(companyId, formValues?.id, payload);
    } else {
      await addEquipmentInfo(companyId, payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  return (
    <Modal
      isOpen={isOpen}
      handleSave={handleSave}
      size={modalSizes.medium}
      handleClose={handleCloseModal}
      disableSave={!formValues?.name}
    >
      {
        isLoading ?
        <ContentLoader /> :
          <div className="asset_form_modal_container">
            <div className="modal_row">
              <div className="left_text">{t('page_content.human_resources.assigned_equipment_and_info.equipment_catalogue.column_name')}:</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'name', '')} onChange={(e) => onChange(e.target.value, 'name')} />
              </div>
            </div>
          </div>
      }
    </Modal>
  );
};

CompanyValuesModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default (withTranslation()(CompanyValuesModal));
