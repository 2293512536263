import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Button } from 'shared';
import ReactDatePicker from 'react-datepicker';
import Select from 'react-select';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { get } from 'lodash';
import { checkAccessOnPage, redirectToHomePage, checkModules } from 'industry/helpers';
import { getLocale } from 'shared/DatePicker/constants';
import { selectStyles } from 'styles/modules/reactSelect';
import { getReports, getReportsUrl } from './actions';
import './styles.scss';
import Reports from './components/Reports';
// import Analysis from './components/Analysis';

class Industry5S extends Component {
  constructor(props) {
    super(props);

    let isReadOnly = false;

    if (window.resourceReadOnly && window.resourceReadOnly.length) {
      window.resourceReadOnly.forEach((resource) => {
        if (window.location.href.includes(resource)) {
          isReadOnly = true;
        }
      });
    }

    this.state = {
      reports: [],
      isLoadingReports: true,
      // eslint-disable-next-line react/no-unused-state
      isReadOnly,
      startDate: null,
      endDate: null,
      selectedStatus: null,
      sortBy: null,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === '5S');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            isReadOnly: true,
          });
        }
      });

    this.fetchReports();
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchReports();
    });
  }

  fetchReports = () => {
    const { companyId, locationId } = this.props;
    const { startDate, endDate, selectedStatus, sortBy, selectedSort, selectedAscDesc } = this.state;

    this.setState({
      isLoadingReports: true,
    });

    let filters = '';
    let orderFilter = '';

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    orderFilter += `&order_by=${asc}${selectedSort || '-updated_at'}`;

    if (startDate) {
      filters += `&created_at_after=${moment(startDate).startOf('day').toISOString()}`;
    }

    if (endDate) {
      filters += `&created_at_before=${moment(endDate).endOf('day').toISOString()}`;
    }

    if (selectedStatus && selectedStatus !== 'all') {
      filters += `&status=${selectedStatus}`;
    }

    if (sortBy) {
      orderFilter = `&order_by=${sortBy}`;
    }

    getReports(companyId, locationId, `${filters}${orderFilter}`)
      .then((res) => {
        this.setState({
          isLoadingReports: false,
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          reports: get(res, 'data.results'),
          count: get(res, 'data.count'),
        });
      });
  }

  fetchReportsByUrl = (url) => {
    this.setState({
      isLoadingReports: true,
    });

    getReportsUrl(url)
      .then((res) => {
        this.setState({
          isLoadingReports: false,
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          reports: get(res, 'data.results'),
          count: get(res, 'data.count'),
        });
      });
  }

  displayUserName = (user) => {
    return (user.first_name !== '' && user.last_name !== '')
      ? `${user.first_name} ${user.last_name}`
      : `${user.email}`;
  }

  handleFilterChange = (key, value) => {
    if (key === 'startDate' || key === 'endDate') {
      const formatedDate = moment(value);
      this.setState({
        [key]: formatedDate._d,
      }, () => {
        this.fetchReports();
      });
    } else {
      this.setState({
        [key]: value,
      }, () => {
        this.fetchReports();
      });
    }
  }

  clearSearch = () => {
    this.setState({
      startDate: null,
      endDate: null,
      selectedStatus: null,
      sortBy: null,
    }, () => {
      this.fetchReports();
    });
  }

  render() {
    const { t, history } = this.props;
    const {
      reports,
      isLoadingReports,
      next,
      previous,
      startDate,
      endDate,
      selectedStatus,
      sortBy,
      count,
      //   isReadOnly,
    } = this.state;

    const statusOptions = [
      { value: 'all', name: t('page_content.5S.statuses.all') },
      { value: 'created', name: t('page_content.5S.statuses.created') },
      { value: 'in_progress', name: t('page_content.5S.statuses.in_progress') },
      { value: 'finished', name: t('page_content.5S.statuses.finished') },
      { value: 'closed', name: t('page_content.5S.statuses.closed') },
      { value: 'graded', name: t('page_content.5S.statuses.graded') },
    ];

    const sortByOptions = [
      { value: 'report_lead', name: t('page_content.5S.reporter') },
      { value: 'created_at', name: t('page_content.5S.created_at') },
    ];

    return (
      <div className="industry-tab industry-location-5S fullscreen">
        <Tabs>
          <TabList>
            <Tab>{t('page_content.5S.reports')}</Tab>
            {/* <Tab>{t('page_content.5S.analysis')}</Tab> */}
          </TabList>

          <div className="reports_tools">
            <div className="reports_datePicker">
              <ReactDatePicker
                placeholderText={t('page_content.maintenance.select_start_date')}
                dateFormat="dd.MM.yyyy"
                selected={startDate ? moment(startDate).toDate() : null}
                maxDate={moment(endDate).toDate()}
                onChange={(e) => this.handleFilterChange('startDate', e)}
                onKeyDown={(e) => { e.preventDefault(); }}
                locale={getLocale(t)}
              />
            </div>
            <div className="reports_datePicker">
              <ReactDatePicker
                placeholderText={t('page_content.maintenance.select_end_date')}
                dateFormat="dd.MM.yyyy"
                minDate={moment(startDate).toDate()}
                selected={endDate ? moment(endDate).toDate() : null}
                onChange={(e) => this.handleFilterChange('endDate', e)}
                onKeyDown={(e) => { e.preventDefault(); }}
                locale={getLocale(t)}
              />
            </div>

            <Select
              options={statusOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              onChange={(e) => this.handleFilterChange('selectedStatus', e.value)}
              value={(statusOptions && statusOptions.find((status) => status.value === selectedStatus)) || ''}
              styles={selectStyles}
              placeholder={t('page_content.5S.status_placeholder')}
            />

            <Select
              options={sortByOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              onChange={(e) => this.handleFilterChange('sortBy', e.value)}
              value={(sortByOptions && sortByOptions.find((o) => o.value === sortBy)) || ''}
              styles={selectStyles}
              placeholder={t('page_content.5S.sort_by_placeholder')}
            />

            <div className="reports_tool_clearButton">
              <Button
                type="plain"
                onClick={this.clearSearch}
              >
                {t('page_content.5S.clear_button')}
              </Button>
            </div>
          </div>

          <TabPanel>
            <Reports
              t={t}
              displayUserName={this.displayUserName}
              reports={reports}
              isLoadingReports={isLoadingReports}
              next={next}
              previous={previous}
              count={count}
              fetchReportsByUrl={this.fetchReportsByUrl}
              history={history}
              handleSorting={this.handleSorting}
            />
          </TabPanel>

          {/* <TabPanel>
            <Analysis />
          </TabPanel> */}
        </Tabs>
      </div>
    );
  }
}

Industry5S.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
});

export default withRouter(connect(mapStateToProps, null)(withTranslation()(Industry5S)));
