import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';
import { IconSearch } from 'shared/Icons';
import { get } from 'lodash';
import './style.scss';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { getCompanyPartners, addCompanyPartner, deleteCompanyPartner, editCompanyPartner, getPaginatedPartners } from './actions';
import PartnersModal from './components/PartnersModal';

class PartnersTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      isLoadingPartners: false,
      showModal: false,
      isReadOnly: false,
      previous: null,
      next: null,
      partners: [],
      partnerData: {
        id: null,
        external_id: '',
        name: '',
        city: '',
        zipcode: '',
        country: '',
        is_customer: false,
        is_supplier: false,
        is_cooperant: false,
        mobile_phone: '',
      },
      selectedSort: 'name',
      selectedAscDesc: 'asc',
      count: 0,
      showConfirmationDialog: false,
      deleteData: null,
    };
    this.timerRef = React.createRef();
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;
    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToSettingsPage(companyId, locationId);
        } else if (access === 1) {
          this.setState({
            isReadOnly: true,
          });
          this.getPartners();
        } else {
          this.getPartners();
        }
      });
  }

  getPartners = () => {
    const { companyId } = this.props;
    const { query, selectedAscDesc, selectedSort } = this.state;

    this.setState({ isLoadingPartners: true });

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const sort = `&order_by=${asc}${selectedSort}`;
    const newFilters = query + sort;

    getCompanyPartners(companyId, newFilters)
      .then((re) => {
        this.setState({
          partners: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingPartners: false,
        });
      })
      .catch((error) => console.log(error));
  }

  handleChange = (e) => {
    const searchValue = e.target.value;
    this.setState({ query: searchValue }, () => {
      this.handleStateQueryChange();
    });
  }

  handleStateQueryChange = () => {
    const { query, selectedAscDesc, selectedSort } = this.state;
    const { companyId } = this.props;
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      if (query.length < 1 || query.length >= 3) {
        this.setState({
          isLoadingPartners: true,
        });
        let apiFilters = '';
        apiFilters += query;
        const asc = selectedAscDesc === 'desc' ? '-' : '';
        apiFilters += `&order_by=${asc}${selectedSort}`;
        getCompanyPartners(companyId, apiFilters)
          .then((re) => this.setState({
            partners: get(re, 'data.results'),
            isLoadingPartners: false,
          }))
          .catch((error) => console.log(error));
      }
    }, 500);
  }

  handleClearSearch = () => {
    this.setState({ query: '' }, () => {
      this.handleStateQueryChange();
    });
  }

  handleModalClose = () => {
    this.setState({
      showModal: false,
      partnerData: {
        id: null,
        external_id: '',
        name: '',
        city: '',
        address: '',
        zipcode: '',
        country: '',
        is_customer: false,
        is_cooperant: false,
        is_supplier: false,
        mobile_phone: '',
      },
    });
  }

  handleEditPartner = (row) => {
    this.setState({
      partnerData: {
        id: row.original.id,
        external_id: row.original.external_id,
        name: row.original.name,
        city: row.original.city,
        address: row.original.address,
        zipcode: row.original.zipcode,
        country: row.original.country,
        is_customer: row.original.is_customer,
        is_cooperant: row.original.is_cooperant,
        is_supplier: row.original.is_supplier,
        mobile_phone: row.original.mobile_phone,
      },
      showModal: true,
    });
  }

  handlePartnerData = (value, field) => {
    const { partnerData } = this.state;
    const updatedData = { ...partnerData };
    updatedData[field] = value;

    this.setState({
      partnerData: updatedData,
    });
  }

  handleCreateOrUpdatePartner = () => {
    const { partnerData } = this.state;
    const { companyId } = this.props;

    if (!partnerData) return;
    this.setState({
      isLoadingPartners: true,
      showModal: false,
    });

    const payload = {
      id: partnerData.id ? partnerData.id : '',
      external_id: partnerData.external_id,
      name: partnerData.name,
      city: partnerData.city,
      address: partnerData.address,
      zipcode: partnerData.zipcode,
      country: partnerData.country,
      mobile_phone: partnerData.mobile_phone,
      is_customer: partnerData.is_customer,
      is_cooperant: partnerData.is_cooperant,
      is_supplier: partnerData.is_supplier,
      company: companyId,
    };

    if (partnerData.id) {
      editCompanyPartner(partnerData.id, companyId, payload)
        .then(() => {
          this.setState({
            showModal: false,
            partnerData: {
              id: null,
              external_id: '',
              name: '',
              city: '',
              address: '',
              zipcode: '',
              country: '',
              is_customer: false,
              is_cooperant: false,
              is_supplier: false,
              mobile_phone: '',
            },
          });
          this.getPartners();
        });
    } else {
      addCompanyPartner(payload)
        .then(() => {
          this.setState({
            showModal: false,
            partnerData: {
              id: null,
              external_id: '',
              name: '',
              city: '',
              address: '',
              zipcode: '',
              country: '',
              is_customer: false,
              is_cooperant: false,
              is_supplier: false,
              mobile_phone: '',
            },
          });
          this.getPartners();
        });
    }
  }

  handleShowConfirmationModal = (row, e) => {
    e.stopPropagation();
    this.setState({ deleteData: row, showConfirmationDialog: true });
  }

  handleDeletePartner = () => {
    const { partnerData, deleteData } = this.state;
    const { companyId } = this.props;
    if (!partnerData && !partnerData.id) return;
    this.setState({
      isLoadingPartners: false,
    });
    const id = deleteData.id;
    deleteCompanyPartner(id, companyId)
      .then(() => {
        this.setState({
          showModal: false,
        });
        this.getPartners();
      }).finally(() => this.setState({ showConfirmationDialog: false }));
  }

  fetchDataForPagination = (url) => {
    this.setState({
      isLoadingPartners: true,
    });

    getPaginatedPartners(url)
      .then((re) => {
        this.setState({
          partners: get(re, 'data.results') || [],
          count: get(re, 'data.count'),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingPartners: false,
        });
      })
      .catch((e) => console.error('Error while fetching orders', e));
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.getPartners();
    });
  }

  render() {
    const { query, isLoadingPartners, isReadOnly, showModal, partnerData, partners, previous, next, count } = this.state;
    const { t } = this.props;

    return (
      <div className="partners-management-list">
        <div className="table-controls">
          <div className="table-controls__search">
            <form className="search" onSubmit={this.handleSubmit} style={{ float: 'left', marginRight: '10px' }}>
              <div style={{ position: 'relative' }}>
                <input
                  onChange={this.handleChange}
                  placeholder={t('page_content.partners.search')}
                  value={query}
                  type="text"
                />
                {
                  query &&
                  <button className="clear" onClick={this.handleClearSearch}>&times;</button>
                }
              </div>
              <button
                className="submit"
                type="submit"
              >
                <IconSearch
                  color="#555"
                  height="26px"
                  width="26px"
                />
              </button>
            </form>
            <Button
              onClick={() => this.setState({
                showModal: true,
              })}
              disabled={isReadOnly}
              style={{ height: 'fit-content' }}
              type="add"
            >
              {t('page_content.partners.add_partner_button')}
            </Button>
          </div>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.partners.table_column_externalId')}</span>,
              accessor: 'external_id',
            },
            {
              Header: () => <span>{t('page_content.partners.table_column_name')}</span>,
              accessor: 'name',
            },
            {
              Header: () => <span>{t('page_content.partners.table_column_city')}</span>,
              accessor: 'city',
            },
            {
              Header: () => <span>{t('page_content.partners.table_column_address')}</span>,
              accessor: 'address',
            },
            {
              Header: () => <span>{t('page_content.partners.table_column_zipcode')}</span>,
              accessor: 'zipcode',
            },
            {
              Header: () => <span>{t('page_content.partners.table_column_country')}</span>,
              accessor: 'country',
            },
            {
              Header: () => <span>{t('page_content.partners.table_column_mobile')}</span>,
              accessor: 'mobile_phone',
            },
            {
              Header: () => <span>{t('page_content.partners.table_column_isCooperator')}</span>,
              accessor: 'is_cooperant',
              Cell: (row) => (<div style={{ margin: 'auto' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>),
            },
            {
              Header: () => <span>{t('page_content.partners.table_column_isCustomer')}</span>,
              accessor: 'is_customer',
              Cell: (row) => (<div style={{ margin: 'auto' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>),
            },
            {
              Header: () => <span>{t('page_content.partners.table_column_isSupplier')}</span>,
              accessor: 'is_supplier',
              Cell: (row) => (<div style={{ margin: 'auto' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>),
            },
            {
              Header: () => <span>{t('page_content.partners.table_column_delete')}</span>,
              Cell: (row) => <Button type="delete" disabled={isReadOnly} onClick={(e) => this.handleShowConfirmationModal(row.original, e)} style={{ zIndex: 0 }}>{t('page_content.partners.delete_button')}</Button>,
            },
          ]}
          data={partners || []}
          defaultPageSize={30}
          loading={isLoadingPartners}
          handleClick={(rowInfo) => (isReadOnly ? null : this.handleEditPartner(rowInfo))}
          minRows={0}
          noDataText=""
          showPagination={false}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
        />
        {
          showModal ?
            <PartnersModal
              modalState={showModal}
              handleModalClose={this.handleModalClose}
              partnerData={partnerData}
              handlePartnerData={this.handlePartnerData}
              handleCreateOrUpdatePartner={this.handleCreateOrUpdatePartner}
              handleDeletePartner={this.handleDeletePartner}
              title={partnerData.id ? t('page_content.partners.edit_partner_button') : t('page_content.partners.add_partner_button')}
              t={t}
            /> : ''
        }
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons previous={previous} next={next} fetchFunction={this.fetchDataForPagination} count={count} />
          </span>
        </div>
        <ConfirmationModal
          itemName={this.state.deleteData?.name || ''}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDeletePartner}
          type="warning"
        />
      </div>
    );
  }
}
PartnersTab.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  t: PropTypes.func.isRequired,
};

export default (withRouter(withTranslation()(PartnersTab)));
