import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Field,
  Form,
  Formik,
  ErrorMessage,
} from 'formik';
import { Notification } from 'shared';
import { ToastContainer } from 'react-toastify';
import * as userActions from 'user/actions';
import { loginWithToken } from './actions';
import * as actions from '../../actions';
import localStorageTokenKey from '../constants';
import './../style.scss';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      token: '',
      show_token_page: false,
    };
  }

  handleLoginWith2FA = () => {
    const { username, password, token } = this.state;
    const { getCurrentUser } = this.props;

    loginWithToken({ username, password, otp_token: token })
      .then((re) => {
        if (re && re.data && re.data.token) {
          localStorage.setItem(localStorageTokenKey, re.data.token);
          getCurrentUser()
            .then(() => { window.location.href = '/'; })
            .catch((error) => { console.log(error); });
        }
      });
  }

  render() {
    const { show_token_page } = this.state;
    const { getCurrentUser } = this.props;

    return (
      <div className="login">
        {
          !show_token_page &&
          <Formik
            initialValues={{ username: '', password: '' }}
            validate={(values) => {
              const errors = {};
              if (!values.username) {
                errors.username = 'Required';
              }
              if (!values.password) {
                errors.password = 'Required';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              userActions.login(values)
                .then((re) => {
                  setSubmitting(false);
                  if (re.response && re.response.status && re.response.status !== 200) {
                    Notification('error', 'Error', re.response.data.non_field_errors);
                  } else {
                    Notification('success', 'Login', 'Successful login!');
                    localStorage.setItem(localStorageTokenKey, re.data.token);
                    getCurrentUser()
                      .then(() => { window.location.href = '/'; })
                      .catch((error) => { console.log(error); });
                  }
                })
                .catch((error) => {
                  if (error && error.response && error.response.data && error.response.data.non_field_errors &&
                    error.response.data.non_field_errors[0] && error.response.data.non_field_errors[0] === 'Send 2FA token') {
                    this.setState({
                      username: values.username,
                      password: values.password,
                      show_token_page: true,
                    });
                  } else {
                    Notification('error', 'Error', error.response.data.non_field_errors);
                    setSubmitting(false);
                  }
                });
            }}
          >
            {({
              values,
              isSubmitting,
            }) => (
              <div className="form-container login-form-container">
                <Form ref={this.formRefLogin} className="form-class">
                  <img src="/ascalia_logo.svg" alt="Ascalia logo" width="140px" />
                  <header>
                    <h1>User Login</h1>
                  </header>
                  <div>
                    <Field
                      type="email"
                      name="username"
                      placeholder="Username"
                      required
                      value={values.username}
                    />
                    <ErrorMessage className="error" name="username" component="p" />
                  </div>
                  <div>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password"
                      required
                      value={values.password}
                    />
                    <ErrorMessage className="error" name="password" component="p" />
                  </div>
                  <p id="forgot-password-link">
                    <button type="button" onClick={() => { window.location.href = '/forgot-password'; }}>Forgot your password?</button>
                  </p>
                  <section className="bottom">
                    <button
                      type="submit"
                      className="submit-button"
                      disabled={isSubmitting}
                    >
                      Login
                    </button>
                  </section>
                </Form>
              </div>
            )}
          </Formik>
        }
        {
          show_token_page &&
          <div className="form-container login-form-container">
            <div className="token-page">
              <img src="/ascalia_logo.svg" alt="Ascalia logo" width="140px" />
              <header>
                <h1>Enter 2FA Token</h1>
              </header>
              <p>Enter generated token from your authenticator application</p>
              <input
                onChange={(e) => this.setState({ token: e.target.value })}
                type="text"
                placeholder="Token"
              />
              <section className="bottom">
                <button
                  onClick={this.handleLoginWith2FA}
                  className="submit-button"
                >
                  Login
                </button>
              </section>
            </div>
          </div>
        }
        <ToastContainer
          autoClose={5000}
          closeButton={false}
          toastClassName="Notification-container"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.app,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  fetchPageMeta: actions.fetchPageMeta,
  getCurrentUser: userActions.getCurrentUser,
};

LoginPage.propTypes = {
  getCurrentUser: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
