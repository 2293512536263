import React, { useState } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './styles.scss';

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div>
        <span>{props.label}</span>
      </div>
    </components.Option>
  );
};

CustomOption.propTypes = {
  label: PropTypes.string.isRequired,
};

const GroupsDropdown = ({ options, onOptionChange, t, styles }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selected) => {
    setSelectedOption(selected);
    if (onOptionChange) {
      onOptionChange(selected);
    }
  };

  const formatOptionLabel = (option) => (
    <div className="formatted-option-label">
      {option.label}
    </div>
  );

  return (
    <div>
      <Select
        options={options}
        value={selectedOption}
        onChange={handleChange}
        isClearable
        formatOptionLabel={formatOptionLabel}
        placeholder={t('page_content.orders.order_details.bookings_tab.dropdown_status_placeholder')}
        components={{ Option: CustomOption }}
        isOptionDisabled={(option) => option.isDisabled}
        styles={styles}
      />
    </div>
  );
};

GroupsDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  styles: PropTypes.object,
};

export default (withTranslation()(GroupsDropdown));
