import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import Select from 'react-select';
import api from 'helpers/api';
import { Notification } from 'shared';

class AssetTypePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAssetType: props.asset_type,
      options: [],
    };
  }

  componentDidMount() {
    const { locationId, companyId } = this.props;

    if (locationId) {
      api.get(`/api/v1/asset_types/?account_type=industry&order_by=description&limit=200&company=${companyId}&location=${locationId}`)
        .then((json) => {
          this.setState({
            options: get(json, 'data.results'),
          });
        })
        .catch((error) => {
          Notification('error', 'An error occurred while fetching asset types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your asset types request, please try again.');
        });
    } else {
      api.get(`/api/v1/asset_types/?account_type=industry&order_by=name&limit=200&company=${companyId}`)
        .then((json) => {
          this.setState({
            options: get(json, 'data.results'),
          });
        });
    }
  }

  componentDidUpdate(prevProps) {
    const { asset_type } = this.props;
    if (prevProps.asset_type !== asset_type) {
      this.setState({ selectedAssetType: asset_type });
    }
  }

  handleChange = (AssetType) => {
    const { handleChange } = this.props;
    this.setState({ selectedAssetType: AssetType });
    handleChange(AssetType);
  }

  render() {
    const { selectedAssetType, options } = this.state;
    const { disabled, htmlId, onBlur, customClassName, t, isMulti, customStyles, isClearable, placeholder } = this.props;

    return (
      <Select
        cacheOptions
        isClearable={isClearable}
        isMulti={isMulti}
        isDisabled={disabled}
        id={htmlId}
        options={options}
        getOptionLabel={(option) => option.description}
        getOptionValue={(option) => option.id}
        onBlur={onBlur}
        onChange={this.handleChange}
        placeholder={placeholder || t('shared.asset_type_picker.placeholder')}
        value={selectedAssetType && (Array.isArray(selectedAssetType) && selectedAssetType.every((item) => typeof item === 'number')) ?
          options.filter((option) => selectedAssetType.includes(option.id)) :
          selectedAssetType ?
            options.find((o) => o.id === selectedAssetType) :
            null}
        className={`AscaliaSelect AssetTypePicker ${customClassName}`}
        styles={customStyles}
      />
    );
  }
}

AssetTypePicker.propTypes = {
  asset_type: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  htmlId: PropTypes.string,
  onBlur: PropTypes.func,
  customClassName: PropTypes.string,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number,
  t: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  customStyles: PropTypes.object,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
};

AssetTypePicker.defaultProps = {
  disabled: false,
  customClassName: '',
  isMulti: false,
  isClearable: true,
  locationId: null,
};

export default withTranslation()(AssetTypePicker);
