import api from 'helpers/api';
import { Notification } from 'shared';

// eslint-disable-next-line
export const getLatestEvents = async (company, location) => {
  try {
    const res = await api
      .get(`/api/v1/industry/events/?company=${company}&location=${location}&limit=20&order_by=-ts`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching latest events', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const updateEvent = (companyId, locationId, data) => {
  return api.put(`/api/v1/industry/events/${data.id}/?company=${companyId}&location=${locationId}`, data)
    .then((re) => {
      Notification('success', 'Save successful', 'Event was successfully updated.');
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
    });
};
