import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal, Button, Notification, Table, ContentLoader } from 'shared';
import moment from 'moment';
import { getLocale } from 'shared/DatePicker/constants';
import ReactDatePicker from 'react-datepicker';
import { verifyFile, importFile } from '../actions';

class UploadModal extends Component {
  constructor() {
    super();
    this.state = {
      is_verifying: false,
      is_verified: false,
      is_error: false,
      start_date: moment().day(8).isoWeekday(1).toDate(),
      is_importing: false,
      overwrite_plan: false,
    };
  }

  onImport = () => {
    const { attachment, start_date, overwrite_plan } = this.state;
    const { companyId, locationId, closeUploadModal } = this.props;
    this.setState({
      is_importing: true,
    });

    if (attachment && attachment.file) {
      const formData = new FormData();
      formData.append('file', attachment.file);
      formData.append('start_date', moment(start_date).format('YYYY-MM-DD'));
      formData.append('company', companyId);
      formData.append('location', locationId);
      formData.append('overwrite_plan', overwrite_plan);

      importFile(formData)
        .then(() => {
          this.setState({
            to_import_response: null,
            is_verified: false,
            is_verifying: false,
            is_importing: false,
            overwrite_plan: false,
          });
          closeUploadModal(true);
        });
    } else {
      return Notification('error', 'An error occurred', 'Error while importing');
    }
  }

  onVerify = () => {
    const { attachment, start_date } = this.state;
    const { companyId, locationId } = this.props;

    if (attachment && attachment.file) {
      this.setState({
        is_verifying: true,
      });

      const formData = new FormData();
      formData.append('file', attachment.file);
      formData.append('start_date', moment(start_date).format('YYYY-MM-DD'));
      formData.append('company', companyId);
      formData.append('location', locationId);

      verifyFile(formData)
        .then((res) => {
          if (res) {
            this.setState({
              to_import_response: res && res.data && res.data.to_import ? res.data.to_import : [],
              is_verified: true,
              is_verifying: false,
            });
          } else {
            this.setState({
              to_import_response: [],
              is_verified: false,
              is_verifying: false,
            });
          }
        });
    } else {
      return Notification('error', 'An error occurred', 'Import file');
    }
  }

  onChangeAttachment = (e) => {
    const file = e.target.files;
    const fileName = file && file[0] && file[0].name ? file[0].name.slice(0, file[0].name.indexOf('.')) : '';

    if (file) {
      this.setState((prevState) => ({
        attachment: {
          ...prevState.attachment,
          file: file && file[0] ? file[0] : null,
          name: fileName,
        },
        is_verified: false,
        is_error: false,
        to_import_response: null,
      }));
    }
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  render() {
    const { is_verified, is_error, start_date, is_verifying, to_import_response, is_importing, overwrite_plan } = this.state;
    const {
      t,
      closeUploadModal,
    } = this.props;

    return (
      <Modal
        isOpen
        saveText={t('page_content.microplanning.import')}
        handleSave={() => (is_verified ? this.onImport() : this.setState({ is_error: true }))}
        disableSave={is_importing}
        handleClose={closeUploadModal}
        title={t('page_content.microplanning.plan_import')}
        error={!is_verified && is_error ? t('page_content.microplanning.verify_msg') : ''}
      >
        {
          is_importing ?
            <ContentLoader text={t('page_content.microplanning.import_msg')} /> :
            <div className="default-form">
              <table>
                <tbody>
                  <tr>
                    <td className="label">
                      <label>
                        {t('page_content.microplanning.upload')}
                      </label>
                    </td>
                    <td className="file_input">
                      <input
                        style={{ border: 'none', width: '200px' }}
                        type="file"
                        onChange={this.onChangeAttachment}
                        multiple={false}
                      />
                      <Button
                        onClick={this.onVerify}
                        type={is_verified ? 'success' : 'dark-blue'}
                      >{is_verifying ? t('page_content.microplanning.loading') : is_verified ? t('page_content.microplanning.verified') : t('page_content.microplanning.verify')}</Button>
                    </td>
                  </tr>

                  <tr>
                    <td className="label">
                      <label htmlFor="name">
                        {t('page_content.microplanning.date')}
                      </label>
                    </td>
                    <td className="input">
                      <ReactDatePicker
                        readOnly={is_verified}
                        dateFormat="dd.MM.yyyy"
                        selected={start_date ? moment(start_date).toDate() : null}
                        onChange={(e) => this.onChange('start_date', e)}
                        showTimeSelect={false}
                        disabledKeyboardNavigation
                        locale={getLocale(t)}
                        timeCaption={t('date_picker_locale.time_translation')}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className="label">
                      <label htmlFor="name">
                        {t('page_content.microplanning.overwrite_current_plan')}
                      </label>
                    </td>
                    <td className="input">
                      <input
                        type="checkbox"
                        onChange={(e) => this.onChange('overwrite_plan', e.target.checked)}
                        checked={overwrite_plan}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              {
                to_import_response && to_import_response.length ?
                  <React.Fragment>
                    <hr />
                    <p style={{ fontSize: '12px', fontWeight: 600, color: '#555' }}>{t('page_content.microplanning.to_import')}</p>
                    <Table
                      style={{ userSelect: 'text' }}
                      columns={[
                        {
                          Header: () => <span>{t('page_content.microplanning.line')}</span>,
                          accessor: 'line',
                          Cell: (row) => (row && row.value ? row.value : '-'),
                        },
                        {
                          Header: () => <span>{t('page_content.microplanning.order')}</span>,
                          accessor: 'order',
                          Cell: (row) => (row && row.value ? row.value : '-'),
                        },
                      ]}
                      data={to_import_response || []}
                      noDataText=" "
                      showPagination
                      nextText={t('pagination_table.next')}
                      previousText={t('pagination_table.previous')}
                      defaultPageSize={20}
                      minRows={0}
                      manual={false}
                    />
                  </React.Fragment> : ''
              }
            </div>
        }
      </Modal>
    );
  }
}

UploadModal.propTypes = {
  t: PropTypes.func.isRequired,
  closeUploadModal: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

export default withTranslation()(UploadModal);
