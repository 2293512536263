import axios from 'axios';
// import { get } from 'lodash';
// import { isProductionEnv } from 'shared/helpers';
import localStorageTokenKey from 'app/AuthContainer/constants';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'x-csrftoken';
axios.defaults.withCredentials = false;
axios.defaults.crossDomain = true;

// Add request interceptor (auth token)
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem(localStorageTokenKey);
  if (token !== null && token !== '') {
    config.headers = {
      Authorization: `Token ${token}`,
    };
  }
  return config;
});

// Add a response interceptor
// axios.interceptors.response.use(undefined, (err) => {
//   // if (isProductionEnv()) {
//   const status = get(err, 'response.status');
//   if (status === 401) {
//     // window.location.href = '/login';
//     console.log(err);
//     return;
//   }
//   // }
//   return err;
// }, (error) => {
//   return Promise.reject(error);
// });

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // This is important, so that unhandled cases make axios throw errors
    return Promise.reject(error);
  },
);

const endpoint = '';

const getPath = (url) => `${endpoint}${url}`;

const api = {
  get: (url, config = undefined) => axios.get(getPath(url), config),
  post: (url, data = undefined, config = undefined) => axios.post(getPath(url), data, config),
  patch: (url, data, config) => axios.patch(getPath(url), data, config),
  put: (url, data, config) => axios.put(getPath(url), data, config),
  delete: (url, config = undefined) => axios.delete(getPath(url), config),
};

export default api;
