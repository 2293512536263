import React from 'react';
import Base from './Base';

export default function IconEdit(props) {
  // eslint-disable-next-line react/prop-types
  const { color, ...otherProps } = props;

  return (
    <Base {...otherProps} fill={color || 'black'}>
      <path d="M77.9,0.6L63.2,15.2l21.5,21.5l14.7-14.7L77.9,0.6z M59.6,18.8L0.6,77.9v21.5h21.5l59.1-59.1L59.6,18.8z" fill={color || 'black'} />
    </Base>
  );
}
