import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import CategoriesTab from './CategoriesTab';
import FilledForms from './FilledForms';
import FormTemplates from './FormTemplates';

const Forms = ({ companyId, t, locationId }) => {
  const [reFetchCategories, setReFetchCategories] = useState(false);

  const fetchCategories = () => {
    setReFetchCategories((prevState) => !prevState);
  };

  return (
    <div className="forms_tab_content">
        <div>
          <Tabs>
            <TabList>
              <Tab>{t('page_content.human_resources.forms.table_label_templates')}</Tab>
              <Tab>{t('page_content.human_resources.forms.table_label_filled_forms')}</Tab>
              <Tab>{t('page_content.human_resources.forms.tab_categories')}</Tab>
            </TabList>
          <TabPanel>
            <FormTemplates companyId={companyId} locationId={locationId} reFetchCategories={reFetchCategories} />
          </TabPanel>
          <TabPanel>
            <FilledForms locationId={locationId} companyId={companyId} />
          </TabPanel>
          <TabPanel>
            <CategoriesTab reFetchCategories={fetchCategories} companyId={companyId} />
          </TabPanel>
      </Tabs>
      </div>
    </div>
  );
};

Forms.propTypes = {
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number,
};

export default (withRouter(withTranslation()(Forms)));
