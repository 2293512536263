import React, { Component } from 'react';
import moment from 'moment';
import { defaultDateFormat, defaultDateTimeFormat, modalSizes } from 'shared/constants';
import { Modal, Table } from 'shared';
import { withTranslation } from 'react-i18next';
import { numberSeparatorFormat } from 'industry/helpers';
import PropTypes from 'prop-types';
// import { IconError, IconCheckmark } from 'shared/Icons';

class ProductivityModal extends Component {
  redirectToOrder = (order) => {
    if (order && order.id) {
      let currentLocation = window.location.href.replace('workforce-management', 'orders/');

      currentLocation += order.id;

      return currentLocation;
    }
    return window.location.href;
  }

  isDataStructureValid(data) {
    // Check if data has the expected properties
    return (
      data &&
      Array.isArray(data) &&
      data.every((item) => typeof item === 'object' &&
        'asset' in item &&
        'availability' in item &&
        'booked_time' in item &&
        'duration' in item &&
        'event' in item &&
        'oee' in item &&
        'order' in item &&
        'overhead_time' in item &&
        'performance' in item &&
        'quality' in item &&
        'score' in item &&
        'ts' in item)
    );
  }

  render() {
    const {
      t,
      i18n,
      isOpen,
      closeModal,
      data,
    } = this.props;

    if (!data) {
      return '';
    }

    const isDataValid = this.isDataStructureValid(data && data.items ? data.items : []);

    const title = data && data.worker && data.worker.name && data.worker.last_name ?
      `${data.worker.name} ${data.worker.last_name}
      ${data && data.worker && data.worker.external_id ? `| ${data.worker.external_id}` : ''}
      ${data.date ? `| ${moment(data.date).format(defaultDateFormat)}` : ''}`
      : '';
    return (
      <Modal
        size={modalSizes.max}
        isOpen={isOpen}
        handleClose={closeModal}
        title={title}
      >
        <Table
          columns={[
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.table_column_asset')}</span>,
              accessor: 'asset',
              Cell: (row) => (row.value || '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.table_column_event')}</span>,
              accessor: 'event',
              Cell: (row) => (row.value || '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.table_column_start')}</span>,
              accessor: 'ts_start',
              Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.table_column_end')}</span>,
              accessor: 'ts_end',
              Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.table_column_order')}</span>,
              accessor: 'order',
              Cell: (row) => (row.value ?
                <a style={{ textDecoration: 'none', color: '#10ac84' }} href={this.redirectToOrder(row.value)} target="_blank" rel="noopener noreferrer">
                  {`${row.value.external_id || ''}${row.value.product ? ` - ${row.value.product}` : ''}`}</a>
                : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.table_column_duration')}</span>,
              accessor: 'duration',
              Cell: (row) => (row.value || '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.table_column_booked_time')}</span>,
              accessor: 'booked_time',
              Cell: (row) => (row.value || '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.quantity')}</span>,
              accessor: 'quantity',
              Cell: (row) => (row.value ? `${numberSeparatorFormat(i18n.language, row.value, 0, 0, true)}` : '-'),
              style: {
                cursor: 'default',
                alignItems: 'end',
              },
            },
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.takt_booking')}</span>,
              accessor: 'takt_booking',
              Cell: (row) => (row.value ? `${numberSeparatorFormat(i18n.language, row.value, 0, 0, true)}` : '-'),
              style: {
                cursor: 'default',
                alignItems: 'end',
              },
            },
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.max_historic_takt')}</span>,
              accessor: 'takt_max',
              Cell: (row) => (row.value ? `${numberSeparatorFormat(i18n.language, row.value, 0, 0, true)}` : '-'),
              style: {
                cursor: 'default',
                alignItems: 'end',
              },
            },
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.table_column_performance')}</span>,
              accessor: 'performance',
              Cell: (row) => (row.value ? `${row.value}%` : '-'),
              style: {
                cursor: 'default',
                alignItems: 'end',
              },
            },
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.table_column_oee')}</span>,
              accessor: 'oee',
              Cell: (row) => (row.value ? `${row.value}%` : '-'),
              style: {
                cursor: 'default',
                alignItems: 'end',
              },
            },
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.oee_limit_102_percent')}</span>,
              accessor: 'oee_100',
              Cell: (row) => (row.value ? `${row.value}%` : '-'),
              style: {
                cursor: 'default',
                alignItems: 'end',
              },
            },
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.oee_limit_107_percent')}</span>,
              accessor: 'oee_105',
              Cell: (row) => (row.value ? `${row.value}%` : '-'),
              style: {
                cursor: 'default',
                alignItems: 'end',
              },
            },
            {
              Header: () => <span>{t('page_content.workforce_management.productivity.oee_points')}</span>,
              accessor: 'oee_score',
              Cell: (row) => (row.value ? `${row.value}%` : '-'),
              style: {
                cursor: 'default',
                alignItems: 'end',
              },
            },
          ]}
          data={isDataValid ? data.items : []}
          defaultPageSize={data.items ? data.items.length : 100}
          minRows={0}
          selectedRow={null}
          noDataText=" "
          showPagination={false}
          sortable={false}
        />
      </Modal>
    );
  }
}

ProductivityModal.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default withTranslation()(ProductivityModal);
