import * as actionTypes from './actionTypes';
import * as deviceActionTypes from '../containers/IndustryLocationDeviceList/actionTypes';

const initialState = {
  dashboards: [],
  devices: [],
};

export default function (state = ({ ...initialState }), action) {
  switch (action.type) {
    case deviceActionTypes.setDevices:
      return {
        ...state,
        devices: action.payload,
      };

    case deviceActionTypes.addOrUpdateDevice:
      const device = action.payload;
      const { devices } = state;
      const deviceIndex = devices.findIndex((x) => x.id === device.id);
      if (deviceIndex >= 0) {
        devices[deviceIndex] = { ...devices[deviceIndex], ...device };
      } else {
        devices.push(device);
      }

      return { ...state, devices: [...devices] };

    case actionTypes.fetchDashboards:
      return Object.assign(state, {
        isLoadingDashboards: true,
      });

    case actionTypes.fetchDashboardsSuccess:
      return Object.assign(state, {
        dashboards: action.payload,
        latestUpdate: action.latestUpdate,
        isLoadingDashboards: false,
      });

    case actionTypes.fetchDashboardsError:
      return Object.assign(state, {
        dashboards: [],
        isLoadingDashboards: false,
      });

    case actionTypes.resetContainer:
      return Object.assign(state, initialState);

    case actionTypes.addOrUpdateDashboardSuccess:
      const dashboards = state.dashboards;
      const dashboard = action.payload;

      const index = dashboards.findIndex((x) => x.id === dashboard.id);
      if (index >= 0) {
        const {
          title,
          description,
          is_favorite,
        } = dashboard;

        dashboards[index] = { ...dashboards[index], title, description, isFavorite: is_favorite };
      } else {
        dashboards.push(dashboard);
      }

      return Object.assign(state, { dashboards: [...dashboards] });
    default:
      return state;
  }
}
