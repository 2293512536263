import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props}>
  <g>
    <path
      d="M77.9,83.9H22.1c-3.3,0-5.9-2.7-5.9-5.9V22.1c0-3.3,2.7-5.9,5.9-5.9h55.9c3.3,0,5.9,2.7,5.9,5.9v55.9
    C83.9,81.2,81.2,83.9,77.9,83.9z M22.1,19.1c-1.6,0-2.9,1.3-2.9,2.9v55.9c0,1.6,1.3,2.9,2.9,2.9h55.9c1.6,0,2.9-1.3,2.9-2.9V22.1
    c0-1.6-1.3-2.9-2.9-2.9H22.1z"
    />
    <g>
      <path d="M45.4,63.6h-7V50c0-1.7,0-3.2,0.1-4.5c-0.4,0.5-1,1.1-1.6,1.7L34,49.5l-3.6-4.4l8.8-7.2h6.3V63.6z" />
      <path d="M53.6,63.6l8.8-19.9H51.3v-5.7h18.5V42l-9.1,21.6H53.6z" />
    </g>
  </g>
</Base>);
