import React from 'react';
import Base from './Base';

export default function IconDown(props) {
  // eslint-disable-next-line react/prop-types
  const { color, ...otherProps } = props;

  return (
    <Base {...otherProps} fill={color || 'black'} viewBox="0, 0, 15, 15">
      <path d="M9.5 12L0.406736 -1.7129e-06L18.5933 -1.22981e-07L9.5 12Z" fill={color || 'black'} />
    </Base>
  );
}
