import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';
import './styles.scss';

import { styledSendDeviceOptions } from 'industry/helpers';

import { getEventSubscribers, getPaginatedEventSubscribers, getEvents, getSubscribers, deleteEventSubscriber } from '../../../../actions';

import EventSubscriberModal from './components/EventSubscriberModal';

const EventSubscribers = ({ t, companyId, locationFilter, notificationChannels, isUserProfile, currentUser, isReadOnly }) => {
  const [eventSubscribers, setEventSubscribers] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [sendDevices, setSendDevices] = useState([]);
  const [filters, setFilters] = useState({
    selectedAscDesc: 'desc',
    selectedSort: 'event',
    selectedLocation: null,
    selectedEvent: null,
    selectedSubscriber: null,
  });
  const [eventFilter, setEventFilter] = useState({
    data: [],
    isLoading: true,
  });
  const [subscriberFilter, setSubscriberFilter] = useState({
    data: [],
    isLoading: true,
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const sendDevicesTemplate = [
    { id: 'send_app', name: t('settings.events_and_subscribers.event_subscribers.send_app') },
    { id: 'send_email', name: t('settings.events_and_subscribers.event_subscribers.send_email') },
    { id: 'send_sms', name: t('settings.events_and_subscribers.event_subscribers.send_sms') },
    { id: 'send_voice', name: t('settings.events_and_subscribers.event_subscribers.send_voice') },
    { id: 'send_webpush', name: t('settings.events_and_subscribers.event_subscribers.send_webpush') },
    { id: 'send_whatsapp', name: t('settings.events_and_subscribers.event_subscribers.send_whatsapp') },
  ];

  const fetchEventSubscribers = () => {
    setEventSubscribers((prevState) => ({ ...prevState, isLoading: true }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    let apiFilters = `&limit=15&order_by=${asc}${filters.selectedSort}`;

    if (filters?.selectedLocation?.id) apiFilters += `&location=${filters.selectedLocation.id}`;

    if (filters?.selectedEvent?.id) apiFilters += `&event=${filters.selectedEvent.id}`;

    if (filters?.selectedSubscriber?.id) apiFilters += `&subscriber=${filters.selectedSubscriber.id}`;

    if (isUserProfile) apiFilters += `&user=${currentUser.id}`;

    getEventSubscribers(companyId, apiFilters)
      .then((res) => {
        setEventSubscribers({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => setEventSubscribers((prevState) => ({ ...prevState, isLoading: false })));
  };

  const fetchPaginatedEventSubscribers = (url) => {
    setEventSubscribers((prevState) => ({ ...prevState, isLoading: true }));

    getPaginatedEventSubscribers(url)
      .then((res) => {
        setEventSubscribers({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => setEventSubscribers((prevState) => ({ ...prevState, isLoading: false })));
  };

  const fetchEvents = () => {
    setEventFilter((prevState) => ({ ...prevState, isLoading: true }));

    const apiFilters = '&limit=9999';

    getEvents(companyId, apiFilters)
      .then((res) => {
        setEventFilter({
          data: get(res, 'data.results', []),
          isLoading: false,
        });
      })
      .catch(() => setEventFilter((prevState) => ({ ...prevState, isLoading: false })));
  };

  const fetchSubscribers = () => {
    setSubscriberFilter((prevState) => ({ ...prevState, isLoading: true }));

    let apiFilters = '&limit=9999';

    if (isUserProfile) apiFilters += `&user=${currentUser.id}`;

    getSubscribers(companyId, apiFilters)
      .then((res) => {
        setSubscriberFilter({
          data: get(res, 'data.results', []),
          isLoading: false,
        });
        if (isUserProfile) setFilters((prevState) => ({ ...prevState, selectedSubscriber: get(res, 'data.results[0]', null) }));
      })
      .catch(() => setSubscriberFilter((prevState) => ({ ...prevState, isLoading: false })));
  };

  useEffect(() => {
    fetchEvents();
    fetchSubscribers();
  }, []);

  useEffect(() => {
    fetchEventSubscribers();
  }, [companyId, filters]);

  useEffect(() => {
    if (Object.keys(notificationChannels).length > 0) {
      const filteredDevices = sendDevicesTemplate.filter((device) => {
        const key = device.id.replace('send_', '');
        return notificationChannels[key];
      });
      setSendDevices(filteredDevices);
    }
  }, [notificationChannels]);

  const handleShowConfirmationDialog = (row) => {
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteEventSubscriber(companyId, deleteData?.id);
    setShowConfirmationDialog(false);
    fetchEventSubscribers();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewEventSubscriber = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchEventSubscribers();
  };

  const setSelectedFilters = (key, value) => {
    setFilters((prevState) => ({ ...prevState, [key]: value }));
  };

  const clearAllFilters = () => {
    setFilters((prevState) => ({
      ...prevState,
      selectedLocation: null,
      selectedEvent: null,
      selectedSubscriber: null,
    }));
  };

  const columns = [
    {
      Header: () => <span>{t('settings.events_and_subscribers.event_subscribers.table_header_subscriber')}</span>,
      accessor: 'subscriber',
      Cell: (row) => <div>{row?.value?.name || '-'}</div>,
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('settings.events_and_subscribers.event_subscribers.table_header_event')}</span>,
      accessor: 'event',
      Cell: (row) => <div>{row?.value?.name || '-'}</div>,
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('settings.events_and_subscribers.event_subscribers.table_header_location')}</span>,
      accessor: 'location',
      Cell: (row) => <div>{row?.value ? locationFilter?.data?.find((loc) => loc?.id === row.value)?.name : '-'}</div>,
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('settings.events_and_subscribers.event_subscribers.table_header_send_devices')}</span>,
      Cell: (row) => {
        const matchingDevices = sendDevicesTemplate?.filter((device) => row.original && row.original[device.id]);
        return (
            <div className="event_subscribers__table__styled_send_devices">
                {matchingDevices.length > 0 ? (matchingDevices.map((device) => (<span key={device.id} style={styledSendDeviceOptions(device.id)}>{device.name}</span>))
                ) : (
                <span>-</span>
                )}
            </div>
        );
      },
      style: { cursor: 'default' },
    },
  ];

  return (
    <div className="event_subscribers">
        <div className="event_subscribers__table">

            <div className="event_subscribers__table__filters">

                <div style={{ width: '200px' }}>
                    <Select
                      options={locationFilter.data}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isLoading={locationFilter.isLoading}
                      isClearable
                      isSearchable
                      menuPosition="fixed"
                      placeholder={t('settings.events_and_subscribers.event_subscribers.filter_by_location')}
                      onChange={(value) => setSelectedFilters('selectedLocation', value)}
                      value={filters?.selectedLocation || ''}
                      styles={selectModalStyles}
                    />
                </div>

                <div style={{ width: '200px' }}>
                    <Select
                      options={eventFilter.data}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isLoading={eventFilter.isLoading}
                      isClearable
                      isSearchable
                      menuPosition="fixed"
                      placeholder={t('settings.events_and_subscribers.event_subscribers.filter_by_event')}
                      onChange={(value) => setSelectedFilters('selectedEvent', value)}
                      value={filters?.selectedEvent || ''}
                      styles={selectModalStyles}
                    />
                </div>

                <div style={{ width: '200px' }}>
                    <Select
                      options={subscriberFilter.data}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isLoading={subscriberFilter.isLoading}
                      isClearable
                      isSearchable
                      menuPosition="fixed"
                      placeholder={t('settings.events_and_subscribers.event_subscribers.filter_by_subscriber')}
                      onChange={(value) => setSelectedFilters('selectedSubscriber', value)}
                      value={filters?.selectedSubscriber || ''}
                      styles={selectModalStyles}
                    />
                </div>

                {
                  !isUserProfile && (
                    <Button onClick={clearAllFilters}>{t('shared.clear_button')}</Button>
                  )
                }

                <div className="event_subscribers__table__filters__action-buttons">
                    <Button type="add" onClick={handleAddNewEventSubscriber} disabled={isReadOnly}>
                        {t('settings.events_and_subscribers.event_subscribers.add_new_event_subscriber')}
                    </Button>
                </div>
            </div>

            <Table
              style={{ userSelect: 'text' }}
              columns={columns}
              minRows={0}
              enableEdit
              enableDelete
              onEdit={(original) => handleTableRowClick(original)}
              onDelete={(original) => handleShowConfirmationDialog(original)}
              isActionsDisabled={isReadOnly}
              data={eventSubscribers.data || []}
              loading={eventSubscribers.isLoading}
              defaultSorted={[{ id: 'event', desc: true }]}
              onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
            />

            <div>
                <span style={{ float: 'right' }}>
                    <TableButtons
                      next={eventSubscribers.next}
                      count={eventSubscribers.count}
                      previous={eventSubscribers.previous}
                      fetchFunction={fetchPaginatedEventSubscribers}
                    />
                </span>
            </div>
        </div>

        {
            modalData.isOpen &&
            <EventSubscriberModal
              sendDevices={sendDevices}
              isOpen={modalData.isOpen}
              events={eventFilter.data}
              handleCloseModal={handleCloseModal}
              locations={locationFilter.data}
              initialValues={modalData.selectedItem}
              subscribers={subscriberFilter.data}
              closeModalAndRefetch={closeModalAndRefetch}
            />
        }

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'subscriber.name')} - ${get(deleteData, 'event.name')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </div>
  );
};

EventSubscribers.propTypes = {
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  isUserProfile: PropTypes.bool.isRequired,
  locationFilter: PropTypes.object.isRequired,
  notificationChannels: PropTypes.object.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    notificationChannels: get(state, 'app.companyConfig.config.notification_channels', []),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(EventSubscribers));
