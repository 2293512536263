import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IconRemove } from 'shared/Icons';
import { Button, Table, Modal, ConfirmationModal } from 'shared';
import { defaultDateTimeFormat } from 'shared/constants';
import { withTranslation } from 'react-i18next';
import { TableButtons } from '../../../../../shared/index';

class OrderAttachements extends React.Component {
  constructor() {
    super();
    this.state = {
      deleteData: {},
      showConfirmationDialog: false,
    };
  }

  handleShowConfirmationModal = (row, e) => {
    e.stopPropagation();
    this.setState({ deleteData: row, showConfirmationDialog: true });
  };

  handleConfirmDelete = () => {
    this.props.handleDeleteAttachment(this.state.deleteData);
    this.setState({ showConfirmationDialog: false });
  };

  render() {
    const { attachments, handleSortingAttachments, isReadOnly } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.props.isAttachmentModalOpen}
          handleClose={this.props.closeAttachmentModal}
          handleSave={this.props.saveAttachmentModal}
          size={this.props.modalSizes.medium}
          error={this.props.formError ? '' : this.props.t('page_content.orders.order_details.attachment_tab.error_msg')}
        >
          <div className="attachments_details">
            <div>
              <p className="title">{this.props.t('page_content.orders.order_details.attachment_tab.modal_file')}*</p>
              {
                !this.props.isAttachmentEdit &&
                <p className="data">
                  <input
                    type="file"
                    onChange={this.props.onChangeAttachment}
                    multiple
                  />
                </p>
              }
            </div>
            <div style={{ marginBottom: '15px' }}>
              {attachments && attachments.length > 0 && attachments[0].id && attachments[0].file && <a href={attachments[0].file}><Button>{this.props.t('page_content.orders.order_details.attachment_tab.modal_download')}</Button></a>}
            </div>
            <div>
              <p className="title">{this.props.t('page_content.orders.order_details.attachment_tab.modal_file_name')}</p>
              {attachments?.length && attachments?.map((attachment, index) => {
                return <p className="data">
                <input
                  type="text"
                  onChange={(e) => this.props.onChangeAttachmentName(index, e.target.value)}
                  value={attachment.name}
                />
              </p>;
              })}
            </div>
            <div>
              <p className="title">{this.props.t('page_content.orders.order_details.attachment_tab.modal_note')}</p>
              <p className="data">
                <textarea
                  rows="5"
                  cols="33"
                  onChange={(e) => this.props.onChangeAttachmentNote(0, e.target.value)}
                  value={attachments && attachments.length > 0 && attachments[0].note}
                />
              </p>
            </div>
          </div>
        </Modal>
        <Button type="add" style={{ marginBottom: '10px' }} disabled={isReadOnly} onClick={this.props.openAttachmentModal}>{this.props.t('page_content.orders.order_details.attachment_tab.add_attachment')}</Button>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.attachment_tab.table_column_created')}</span>,
              accessor: 'created_at',
              Cell: (row) => <span>{row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'}</span>,
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.attachment_tab.table_column_name')}</span>,
              accessor: 'name',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.attachment_tab.table_column_user')}</span>,
              accessor: 'user.email',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.attachment_tab.table_column_note')}</span>,
              accessor: 'note',
              Cell: (row) => <span>{row.value ? row.value : ''}</span>,
            },
            {
              Cell: (row) => <Button disabled={isReadOnly} type="delete" onClick={(e) => this.handleShowConfirmationModal(row, e)}><IconRemove width="12px" height="12px" /></Button>,
              sortable: false,
            },
            {
              Cell: (row) => <a href={row.original.file} onClick={(e) => { e.stopPropagation(); }}><Button style={{ width: '100%' }}>{this.props.t('page_content.orders.order_details.attachment_tab.modal_download')}</Button></a>,
              sortable: false,
            },
          ]}
          data={this.props.orderAttachments || []}
          minRows={0}
          defaultPageSize={100}
          noDataText=" "
          loadingText={this.props.isUploading ? this.props.t('page_content.orders.order_details.attachment_tab.upload_msg') : null}
          showPagination={false}
          sortable
          loading={this.props.isLoadingOrderAttachments}
          handleClick={(rowInfo) => !isReadOnly && this.props.openAttachmentModal(rowInfo)}
          defaultSorted={[{ id: 'created_at', desc: true }]}
          onSortedChange={(newSorted) => { handleSortingAttachments(newSorted[0]); }}
        />
        <div style={{ float: 'right' }}>
          <TableButtons previous={this.props.previousAttachments} next={this.props.nextAttachemnts} fetchFunction={this.props.getPaginatedAttachments} count={this.props.count} />
        </div>
        <ConfirmationModal
          itemName={this.state.deleteData?.original?.name}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleConfirmDelete}
          type="warning"
        />
      </div>
    );
  }
}

OrderAttachements.propTypes = {
  t: PropTypes.any,
  count: PropTypes.number,
  formError: PropTypes.bool,
  modalSizes: PropTypes.any,
  isUploading: PropTypes.bool,
  nextAttachemnts: PropTypes.any,
  orderAttachments: PropTypes.any,
  isAttachmentEdit: PropTypes.bool,
  previousAttachments: PropTypes.any,
  onChangeAttachment: PropTypes.func,
  saveAttachmentModal: PropTypes.func,
  openAttachmentModal: PropTypes.func,
  closeAttachmentModal: PropTypes.func,
  isReadOnly: PropTypes.bool.isRequired,
  isAttachmentModalOpen: PropTypes.bool,
  onChangeAttachmentNote: PropTypes.func,
  onChangeAttachmentName: PropTypes.func,
  handleDeleteAttachment: PropTypes.func,
  getPaginatedAttachments: PropTypes.func,
  attachments: PropTypes.array.isRequired,
  handleSortingAttachments: PropTypes.func,
  isLoadingOrderAttachments: PropTypes.bool,
};

export default withTranslation()(OrderAttachements);
