import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import metrics from 'shared/Mocks/metrics';
import {
  ContentLoader,
  Notification,
} from 'shared';
// import { widgetTypes } from './constants';
import api from 'helpers/api';
import './styles.scss';

import AreaChartForm from '../WidgetForms/AreaChartForm';
import BarChartForm from '../WidgetForms/BarChartForm';
import GaugeForm from '../WidgetForms/GaugeForm';
import LineChartForm from '../WidgetForms/LineChartForm';
import ComposedChartForm from '../WidgetForms/ComposedChartForm';
import ScatterChartForm from '../WidgetForms/ScatterChartForm';
import SankeyChartForm from '../WidgetForms/SankeyChartForm';
import MapForm from '../WidgetForms/MapForm';
import PieChartForm from '../WidgetForms/PieChartForm';
import ScalarForm from '../WidgetForms/ScalarForm';
import TableForm from '../WidgetForms/TableForm';
import TableRangeForm from '../WidgetForms/TableRangeForm';
import HistogramForm from '../WidgetForms/HistogramForm';
import GanttChartForm from '../WidgetForms/GanttChartForm';
import PercentAreaForm from '../WidgetForms/PercentAreaForm';
import RealtimeWidgetForm from '../WidgetForms/RealtimeWidgetForm';
import OrdersTableForm from '../WidgetForms/OrdersTableForm';
import LabelForm from '../WidgetForms/LabelForm';

const ChartTypes = {
  area: AreaChartForm,
  bar: BarChartForm,
  gauge: GaugeForm,
  line: LineChartForm,
  step: LineChartForm,
  composed: ComposedChartForm,
  scatter: ScatterChartForm,
  sankey: SankeyChartForm,
  map: MapForm,
  pie: PieChartForm,
  scalar: ScalarForm,
  table: TableForm,
  tablerange: TableRangeForm,
  histogram: HistogramForm,
  gantt: GanttChartForm,
  percent: PercentAreaForm,
  realtime: RealtimeWidgetForm,
  orderstable: OrdersTableForm,
  label: LabelForm,
};

class EditWidgetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: null,
      loading: true,
    };

    this.generateFormBasedOnType = this.generateFormBasedOnType.bind(this);
  }

  componentDidMount() {
    if (this.props.widgetId) {
      this.fetchWidgetData(this.props.widgetId);
    } else {
      console.error('There is no widget ID passed to editWidgetModal');
    }
  }

  fetchWidgetData = (id) => {
    const {
      dashboardId,
    } = this.props;

    api.get(`/api/v1/dashboards/dashboard/${dashboardId}/widget_configs/${id}/`)
      .then((res) => {
        if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res || !res.data) {
          return Notification('error', 'An error occurred while fetching widget data', 'We could not perform your request, please try again.');
        }
        this.setState({ config: res.data, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  generateFormBasedOnType() {
    const { company, locationId, handleRequestClose } = this.props;

    const Widget = ChartTypes[this.state.config.type];
    return (<Widget
      closeModal={handleRequestClose}
      config={this.state.config}
      onSave={this.props.onSave}
      locationId={locationId}
      companyId={company}
    />);
  }

  render() {
    const {
      config,
      handleRequestClose,
      isOpen,
      t,
    } = this.props;

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={handleRequestClose}
        style={{ content: { padding: '0', top: '20px', left: '0', right: '0', bottom: 'initial', overflow: 'visible' } }}
        shouldCloseOnOverlayClick={false}
      >
        <header>
          {t('page_content.dashboards.edit_widget_modal.title')}
        </header>
        {
          this.state.loading &&
          <ContentLoader />
        }
        {
          !this.state.loading && this.state.config &&
          <main>
            <div className="edit-widget-modal">
              {this.generateFormBasedOnType(config)}
            </div>
          </main>
        }
      </Modal>
    );
  }
}

EditWidgetModal.defaultProps = {
  metrics,
  drone: null,
  isDroneChangeable: true,
  onSave: () => { },
  locationId: null,
};

EditWidgetModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  company: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  config: PropTypes.any,
  isOpen: PropTypes.bool,
  handleRequestClose: PropTypes.func,
  dashboardId: PropTypes.number.isRequired,
  widgetId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(EditWidgetModal);
