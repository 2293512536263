import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ContentLoader } from 'shared';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { modalSizes } from 'shared/constants';
import Select from 'react-select';

class OEEGoalsModal extends Component {
  constructor(props) {
    super();
    let asset = null;
    let low = '';
    let high = '';
    let goal = '';
    let product_filter = '';

    if (props.selectedOee) {
      const { selectedOee } = props;
      asset = (selectedOee && selectedOee.asset && selectedOee.asset.id) || null;
      low = selectedOee.low || '';
      high = selectedOee.high || '';
      goal = selectedOee.goal || '';
      product_filter = selectedOee.product_filter || '';
    }
    this.state = {
      asset,
      low,
      high,
      goal,
      product_filter,
    };
  }

  onFieldChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  handleSaveOrEditOee = () => {
    const { asset, low, high, goal, product_filter } = this.state;
    const { handleSaveOrEditOee, selectedOee, locationId } = this.props;

    const data = {
      asset: asset || null,
      low: low || 0,
      high: high || 0,
      goal: goal || 0,
      product_filter: product_filter || '',
      location: locationId,
    };

    const id = selectedOee && selectedOee.id ? selectedOee.id : null;
    handleSaveOrEditOee(id, data);
  }

  render() {
    const { asset, low, high, goal, product_filter } = this.state;
    const {
      handleCloseModal,
      isModalOpen,
      isLoadingModal,
      t,
      oeeAssets,
    } = this.props;

    const oeeAssetsConfig = [
      { name: t('page_content.oee.modal.asset_option'), id: null },
      ...oeeAssets.sort((a, b) => a.name.localeCompare(b.name)).map((oeeAsset) => ({
        name: oeeAsset.name,
        id: oeeAsset.id,
      })),
    ];

    return (
      <React.Fragment>
        <Modal
          size={modalSizes.medium}
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          handleSave={this.handleSaveOrEditOee}
        >
          {
            isLoadingModal ?
              <ContentLoader /> :
              <div className="default-form">
                <table>
                  <tbody>
                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_asset')}
                      </td>
                      <td className="input">
                        <Select
                          cacheOptions
                          options={oeeAssetsConfig || []}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          menuPosition="fixed"
                          placeholder={t('page_content.oee.modal.asset_placeholder')}
                          onChange={(val) => this.onFieldChange('asset', val.id)}
                          value={(oeeAssetsConfig.find((oeeA) => oeeA.id === asset)) || ''}
                          styles={selectModalStyles}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_low')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('low', e.target.value)}
                          value={low || null}
                          type="number"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_high')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('high', e.target.value)}
                          value={high || null}
                          type="number"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_goal')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('goal', e.target.value)}
                          value={goal || null}
                          type="number"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="label">
                        {t('page_content.oee.table_column_product_filter')}
                      </td>
                      <td className="input">
                        <input
                          onChange={(e) => this.onFieldChange('product_filter', e.target.value)}
                          value={product_filter || null}
                          type="text"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          }

        </Modal>
      </React.Fragment>
    );
  }
}

OEEGoalsModal.propTypes = {
  oeeAssets: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isLoadingModal: PropTypes.bool.isRequired,
  selectedOee: PropTypes.object.isRequired,
  handleSaveOrEditOee: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
};

export default OEEGoalsModal;
