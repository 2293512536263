import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'shared';
import { modalSizes } from 'shared/constants';

const GroupInfoModal = ({
  t,
  groupInfoModalStatus,
  handleGroupInfoModalClose,
  groupInfoModalTitle,
  groupInfoModalData,
}) => {
  return (
    <Modal
      isOpen={groupInfoModalStatus}
      handleClose={handleGroupInfoModalClose}
      size={modalSizes.medium}
      title={t('page_content.orders.order_details.order_groups_tab.modal_title')}
    >
      <div className="parent_doc_table">
        <div className="header">{groupInfoModalTitle}</div>
        {Object.entries(groupInfoModalData).map(([key, value]) => (
          <div className="row" key={key}>
            {key}
            <span>{value}</span>
          </div>
        ))}
      </div>

    </Modal>
  );
};

GroupInfoModal.propTypes = {
  t: PropTypes.func.isRequired,
  groupInfoModalStatus: PropTypes.bool.isRequired,
  handleGroupInfoModalClose: PropTypes.func.isRequired,
  groupInfoModalTitle: PropTypes.string.isRequired,
  groupInfoModalData: PropTypes.object.isRequired,
};

export default GroupInfoModal;
