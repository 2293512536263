import React, { Component } from 'react';
import { numberSeparatorFormat } from 'industry/helpers';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'shared';

class SplitPalletsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error_quantity: false,
      restQuantity: null,
    };
  }

  onSave = () => {
    const { error_quantity, quantity_to_split } = this.state;
    const { palletId, splitPallets } = this.props;

    if (!error_quantity) {
      splitPallets(palletId, { quantity: quantity_to_split });
    }
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    }, () => {
      this.checkRestQuantity();
    });
  }

  checkRestQuantity = () => {
    const { selectedPallet } = this.props;
    const { quantity_to_split } = this.state;

    const currentPalletQuantity = selectedPallet && selectedPallet.quantity ? selectedPallet && selectedPallet.quantity : 0;

    const calculatedValue = currentPalletQuantity - quantity_to_split;

    this.setState({
      restQuantity: calculatedValue,
      error_quantity: calculatedValue <= 0,
    });
  }

  blockInvalidChar = (e) => ['e', 'E', '+', '-', '.', ','].includes(e.key) && e.preventDefault();

  render() {
    const { error_quantity, quantity_to_split, restQuantity } = this.state;
    const { closeSplitPalletsModal, selectedPallet, i18n, t } = this.props;

    const quantityStyle = {};
    if (error_quantity) {
      quantityStyle.borderColor = 'red';
    }

    return (
      <Modal
        isOpen
        handleSave={this.onSave}
        disableSave={error_quantity || !quantity_to_split || quantity_to_split <= 0}
        handleClose={closeSplitPalletsModal}
        title={t('page_content.warehouse.split_pallet')}
      >

        <div className="default-form">
          <table>
            <tbody>
              <tr>
                <td className="label">
                  <label>
                    {t('page_content.warehouse.current_quantity')}:
                  </label>
                </td>
                <td className="input">
                  {restQuantity ? `${restQuantity} ${selectedPallet.product_type.base_unit}` : (selectedPallet && selectedPallet.quantity && selectedPallet.product_type && selectedPallet.product_type.base_unit ?
                    `${numberSeparatorFormat(i18n.language, selectedPallet.quantity, 0, 0, true)} ${selectedPallet.product_type.base_unit}` : '')}
                </td>
              </tr>
              <tr>
                <td className="label">
                  <label>
                    {t('page_content.warehouse.quantity_to_split')}:
                  </label>
                </td>
                <td className="input">
                  <input
                    type="number"
                    min="0"
                    value={quantity_to_split || ''}
                    onChange={(e) => { this.onChange('quantity_to_split', e.target.value); }}
                    onKeyDown={this.blockInvalidChar}
                    style={quantityStyle}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }
}

SplitPalletsModal.propTypes = {
  t: PropTypes.func.isRequired,
  palletId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  i18n: PropTypes.object,
  closeSplitPalletsModal: PropTypes.func.isRequired,
  selectedPallet: PropTypes.object.isRequired,
  splitPallets: PropTypes.func.isRequired,
};

export default withTranslation()(SplitPalletsModal);
