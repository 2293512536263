import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, Modal } from 'shared';
import { IconEdit, IconPopup } from 'shared/Icons';
import { editDepartmentLeaders, getAllDepartmentsTreeView, getAllWorkers, getDepartmentLeaders, getDepartmentShifts, getPaginatedDepartments, saveDepartmentLeaders } from './actions';

import { selectModalStyles } from '../../../../../styles/modules/reactSelect';
import WorkingPlaces from './WorkingPlaces';

const DepartmentsTab = ({ companyId, locationId, t, handleShowWorkers, userIsHR }) => {
  const [tableData, setTableData] = useState({
    departments: [],
    count: null,
    next: null,
    previous: null,
    isLoading: true });
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState(false);
  const [workerOptions, setWorkerOptions] = useState([]);
  const [selectValues, setSelectValues] = useState({});
  const [departmentLeadersData, setDepartmentLeadersData] = useState([]);
  const [departmentsShifts, setDepartmentsShifts] = useState([]);
  const [viewWorkingPlace, setViewWorkingPlaces] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const fetchDepartmentLeaders = async (departmentsData) => {
    const res = await getDepartmentLeaders(locationId);
    const departmentLeaders = get(res, 'data.results', []);
    setDepartmentLeadersData(departmentLeaders);
    const departments = departmentsData || tableData?.departments;
    const newDepartments = departments?.map((d) => {
      const foundDepartment = departmentLeaders.filter((dep) => dep?.department?.id === d.id);
      const data = d;
      if (foundDepartment?.length) {
        const foundManager = foundDepartment.find((leader) => leader.is_manager && leader.is_active);
        if (foundManager) {
          data.leader = {
            id: foundManager.id,
            worker_id: foundManager.worker.id,
            name: foundManager.worker.name,
            last_name: foundManager.worker.last_name,
          };
        }
        const foundDeputyManager = foundDepartment.find((leader) => leader.is_deputy_manager && leader.is_active);
        if (foundDeputyManager) {
          data.substitute = {
            id: foundDeputyManager.id,
            worker_id: foundDeputyManager.worker.id,
            name: foundDeputyManager.worker.name,
            last_name: foundDeputyManager.worker.last_name,
          };
        }
      }
      return data;
    });
    setTableData((prevState) => ({
      ...prevState,
      departments: newDepartments,
    }));
  };

  const fetchDepartments = async () => {
    const resp = await getAllDepartmentsTreeView(locationId);
    const departmentsData = get(resp, 'data', []);
    const updatedData = departmentsData?.map((department) => {
      const data = { ...department };
      const foundShifts = departmentsShifts?.filter((shift) => shift?.department?.id === department?.id);
      const sortedShifts = foundShifts?.sort((a, b) => a?.order - b?.order);
      if (sortedShifts?.length) {
        data.shift1 = sortedShifts[0] || null;
        data.shift2 = sortedShifts[1] || null;
        data.shift3 = sortedShifts[2] || null;
      }
      return data;
    });
    setTableData({
      departments: updatedData,
      count: get(resp, 'data.count'),
      next: get(resp, 'data.next'),
      previous: get(resp, 'data.previous'),
      isLoading: false,
    });
    fetchDepartmentLeaders(updatedData);
  };

  const fetchPaginatedDepartments = async (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const resp = await getPaginatedDepartments(url);
    setTableData({
      departments: get(resp, 'data.results', []),
      count: get(resp, 'data.count'),
      next: get(resp, 'data.next'),
      previous: get(resp, 'data.previous'),
      isLoading: false,
    });
  };

  const fetchWorkers = async () => {
    const res = await getAllWorkers(locationId, companyId);
    setWorkerOptions(get(res, 'data.results', []));
  };

  const fetchDepartmentShifts = async () => {
    const res = await getDepartmentShifts(companyId);
    setDepartmentsShifts(get(res, 'data.results'));
  };

  useEffect(() => {
    fetchDepartmentShifts();
    fetchWorkers();
  }, []);

  useEffect(() => {
    fetchDepartments();
  }, [departmentsShifts]);

  const handleEditButton = (row) => {
    setShowEditModal(true);
    const leader = row?.original?.leader;
    const substitute = row?.original?.substitute;
    const updatedEditData = row.original;
    setSelectValues({
      leader,
      substitute,
    });
    updatedEditData.leader = leader;
    updatedEditData.substitute = substitute;
    setEditData(updatedEditData);
  };

  const handleViewWorkingPlaces = (row) => {
    setViewWorkingPlaces(true);
    setSelectedDepartment(row.original.id);
  };

  const handleSelect = (e, type) => {
    const data = e;
    data.worker_id = e.id;
    setSelectValues((prevState) => ({ ...prevState, [type]: data }));
  };

  const updatePreviousLeaders = async (data, id, workerId) => {
    const newData = {};
    newData.is_active = false;
    newData.active_to = new Date();
    newData.worker = workerId;
    newData.department = data.id;
    await editDepartmentLeaders(locationId, id, newData);
  };

  const handleSaveLeaders = async () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const leaderData = { location: locationId, department: editData.id, active_from: new Date() };
    const substituteData = { location: locationId, department: editData.id, active_from: new Date() };
    if (editData?.leader && (editData?.leader?.worker_id !== selectValues?.leader?.worker_id)) {
      updatePreviousLeaders(editData, editData?.leader?.id, editData?.leader?.worker_id);
    }
    if (editData?.substitute && (editData?.substitute?.worker_id !== selectValues?.substitute?.worker_id)) {
      updatePreviousLeaders(editData, editData?.substitute?.id, editData?.substitute?.worker_id);
    }

    const leader = selectValues?.leader;
    const substitute = selectValues?.substitute;
    if (leader && leader?.id !== editData?.leader?.id) {
      leaderData.worker = leader.worker_id;
      leaderData.is_manager = true;
      leaderData.is_deputy_manager = false;
      leaderData.is_active = true;
      await saveDepartmentLeaders(leaderData);
    }
    if (substitute && substitute?.id !== editData?.substitute?.id) {
      substituteData.worker = substitute.worker_id;
      substituteData.is_deputy_manager = true;
      substituteData.is_manager = false;
      substituteData.is_active = true;
      await saveDepartmentLeaders(substituteData);
    }
    fetchDepartmentLeaders();
    setShowEditModal(false);
    setTableData((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
  };

  const redirectToWorkersList = (row) => {
    const department = { id: row.original.id, name: row.original.name };
    handleShowWorkers(department);
  };

  const tableColumns = [
    {
      Header: () => <span>{t('page_content.human_resources.departments.table_column_sub_departments')}</span>,
      expander: true,
      width: 100,
      // eslint-disable-next-line react/prop-types
      Expander: ({ isExpanded, ...rest }) => {
        return (
          <div>{rest?.original?.children?.length ?
            isExpanded ?
            <span className="parts_modal_showChildren_no">{t('page_content.projects.product_bom_tab.show_parts_collapse')}</span> :
            <span className="parts_modal_showChildren_button_yes">{t('page_content.projects.product_bom_tab.show_parts_yes')}</span> :
            null}</div>
        );
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.departments.table_column_department')}</span>,
      accessor: 'name',
      Cell: (row) => (row?.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.departments.table_column_leader')}</span>,
      accessor: 'leader',
      Cell: (row) => (row?.value ? `${row.value.name} ${row.value.last_name}` : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.departments.table_column_leader_substitute')}</span>,
      accessor: 'substitute',
      Cell: (row) => (row?.value ? `${row.value.name} ${row.value.last_name}` : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.departments.table_column_number_of_workers')}</span>,
      accessor: 'employee_count',
      Cell: (row) => (row?.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.departments.table_column_shift1')}</span>,
      accessor: 'shift1',
      width: 140,
      Cell: (row) => (row?.value ? `${moment(row.value.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(row.value.end_time, 'HH:mm:ss').format('HH:mm')}` : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.departments.table_column_shift2')}</span>,
      accessor: 'shift2',
      width: 140,
      Cell: (row) => (row?.value ? `${moment(row.value.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(row.value.end_time, 'HH:mm:ss').format('HH:mm')}` : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.departments.table_column_shift3')}</span>,
      accessor: 'shift3',
      width: 140,
      Cell: (row) => (row?.value ? `${moment(row.value.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(row.value.end_time, 'HH:mm:ss').format('HH:mm')}` : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.departments.table_column_redirect')}</span>,
      sortable: false,
      width: 130,
      show: userIsHR,
      Cell: (row) => <div style={{ margin: 'auto' }}><Button style={{ width: 55, padding: '5px 8px' }} onClick={() => redirectToWorkersList(row)}>
      <IconPopup
        height="14px"
        width="14px"
        fill="#4285F4"
      />
    </Button></div>,
    },
    {
      Header: () => <span>{t('page_content.human_resources.departments.table_column_working_places')}</span>,
      sortable: false,
      width: 100,
      Cell: (row) => <Button disabled={!userIsHR} style={{ padding: '5px 8px' }} onClick={() => handleViewWorkingPlaces(row)}>
        {t('page_content.human_resources.departments.table_column_view_button')}
    </Button>,
    },
    {
      Header: () => <span>{t('page_content.human_resources.departments.table_column_edit')}</span>,
      sortable: false,
      width: 100,
      Cell: (row) => <div style={{ margin: 'auto' }}><Button disabled={!userIsHR} style={{ width: 55, padding: '5px 8px' }} onClick={() => handleEditButton(row)}>
      <IconEdit
        height="14px"
        width="14px"
        fill="#4285F4"
      />
    </Button></div>,
    },
  ];

  const handleTableChildrenRowStyle = (level) => {
    if (level === 0) {
      return;
    }
    const colorType = level % 2 === 0 ? 'dark' : 'light';
    switch (colorType) {
      case ('light'):
        return {
          background: '#DDF2FD',
        };
      case ('dark'):
        return {
          background: '#9BBEC8',
        };
      default: return {};
    }
  };

  const setLeadersInChildren = (childrenData) => {
    const newData = childrenData.map((d) => {
      const foundDepartment = departmentLeadersData.filter((dep) => dep?.department?.id === d.id);
      const data = d;
      if (foundDepartment?.length) {
        const foundManager = foundDepartment.find((leader) => leader.is_manager && leader.is_active);
        if (foundManager) {
          data.leader = {
            id: foundManager.id,
            worker_id: foundManager.worker.id,
            name: foundManager.worker.name,
            last_name: foundManager.worker.last_name,
          };
        }
        const foundDeputyManager = foundDepartment.find((leader) => leader.is_deputy_manager && leader.is_active);
        if (foundDeputyManager) {
          data.substitute = {
            id: foundDeputyManager.id,
            worker_id: foundDeputyManager.worker.id,
            name: foundDeputyManager.worker.name,
            last_name: foundDeputyManager.worker.last_name,
          };
        }
      }
      return data;
    });

    const shifts = newData?.map((department) => {
      const foundShifts = departmentsShifts.filter((shift) => shift?.department?.id === department?.id);
      const shiftsData = { ...department };
      const sortedShifts = foundShifts?.sort((a, b) => a?.order - b?.order);
      if (sortedShifts?.length) {
        shiftsData.shift1 = sortedShifts[0] || null;
        shiftsData.shift2 = sortedShifts[1] || null;
        shiftsData.shift3 = sortedShifts[2] || null;
      }
      return shiftsData;
    });

    return shifts;
  };

  const renderTable = (tableDataChildrens, level) => {
    const currentLevel = level + 1;
    const dataWithLeader = setLeadersInChildren(tableDataChildrens);
    return (
      <Table
        style={{ userSelect: 'text' }}
        columns={tableColumns}
        data={dataWithLeader}
        loading={tableData.isLoading}
        noDataText=" "
        showPagination={false}
        minRows={0}
        defaultPageSize={dataWithLeader?.length}
        sortable={false}
        expanded={dataWithLeader?.children}
        collapseOnDataChange={false}
        getTrProps={() => {
          return {
            style: handleTableChildrenRowStyle(level),
          };
        }}
        SubComponent={(childrenData) => {
          if (!childrenData?.original?.children?.length) {
            return;
          }
          return <div style={{ paddingLeft: 30, paddingTop: 10 }}>
            {renderTable(childrenData?.original?.children, currentLevel)}
          </div>;
        }}
      />
    );
  };

  return (
    <div>
        {renderTable(tableData?.departments, 0)}
        <div style={{ float: 'right' }}>
          <TableButtons next={tableData.next} previous={tableData.previous} fetchFunction={fetchPaginatedDepartments} count={tableData.count} />
        </div>
        <Modal
          title={`${t('page_content.human_resources.departments.modal_title1')} ${editData?.name} ${t('page_content.human_resources.departments.modal_title2')}`}
          handleSave={handleSaveLeaders}
          isOpen={showEditModal}
          handleClose={() => setShowEditModal(false)}
        >
          <div style={{ padding: 10 }}>
            <div className="modal_row">
              <div className="left_text">
              {t('page_content.human_resources.departments.modal_leader_label')}
              </div>
              <div className="right_select">
                <Select
                  options={workerOptions}
                  placeholder={t('page_content.human_resources.departments.dropdown_default')}
                  getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                  getOptionValue={(user) => user.id}
                  menuPosition="fixed"
                  onChange={(e) => handleSelect(e, 'leader')}
                  value={workerOptions.find((val) => val.id === selectValues?.leader?.worker_id) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
            <div className="modal_row">
              <div className="left_text">
              {t('page_content.human_resources.departments.modal_substitute_label')}
              </div>
              <div className="right_select">
                <Select
                  options={workerOptions}
                  placeholder={t('page_content.human_resources.departments.dropdown_default')}
                  getOptionLabel={(option) => `${option.name} ${option.last_name}`}
                  getOptionValue={(user) => user.id}
                  menuPosition="fixed"
                  onChange={(e) => handleSelect(e, 'substitute')}
                  value={workerOptions.find((val) => val.id === selectValues?.substitute?.worker_id) || ''}
                  styles={selectModalStyles}
                />
              </div>
            </div>
          </div>
        </Modal>
        <WorkingPlaces showModal={viewWorkingPlace} handleCloseModal={() => setViewWorkingPlaces(false)} selectedDepartment={selectedDepartment} />
    </div>
  );
};

DepartmentsTab.propTypes = {
  t: PropTypes.func.isRequired,
  handleShowWorkers: PropTypes.func,
  userIsHR: PropTypes.bool.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

export default (withRouter(withTranslation()(DepartmentsTab)));
