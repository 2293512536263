import initialState from './initialState';

export const addWidget = (state, widgetData) => {
  const { layouts } = state;
  const {
    type,
    title,
    id,
    layouts: widgetLayouts,
  } = widgetData;

  state.widgets.push({
    id: String(id),
    type,
    title,
  });

  // layout update
  const newLayouts = {};

  Object.keys(widgetLayouts).forEach((breakPoint) => {
    const layout = layouts[breakPoint] || [];
    const widgetLayout = widgetLayouts[breakPoint];
    const {
      w,
      h,
      x,
      y,
    } = widgetLayout;

    layout.push({
      i: String(id),
      y,
      x,
      w,
      h,
    });
    newLayouts[breakPoint] = layout;
  });

  state.layouts = newLayouts;

  return { ...state };
};

export const updateWidget = (state, config) => {
  const { widgets } = state;

  const widgetIndex = widgets.findIndex((x) => x.id === String(config.id));
  if (widgetIndex >= 0) {
    // Create a copy of the widget to update
    const updatedWidget = {
      ...widgets[widgetIndex],
      title: config.title,
      settings: config.settings,
      widgetData: config.widget_data || [],
    };

    // Create a new array with the updated widget
    const updatedWidgets = [...widgets];
    updatedWidgets[widgetIndex] = updatedWidget;

    // Return the updated state immutably
    return {
      ...state,
      widgets: updatedWidgets,
    };
  }

  // If widget is not found, return the current state
  return { ...state };
};

export const setDashboard = (_, response = []) => {
  const dashboardConfig = response;
  const layouts = {};
  const widgets = [];

  const widgetConfigs = dashboardConfig.widget_config;
  widgetConfigs.forEach((widgetConfig) => {
    const widgetConfigLayouts = Object.keys(widgetConfig.layouts) || [];
    widgetConfigLayouts.forEach((layout) => {
      layouts[layout] = layouts[layout] || [];
      const currentLayout = widgetConfig.layouts[layout];
      layouts[layout].push({
        i: String(widgetConfig.id),
        x: currentLayout.x,
        y: currentLayout.y,
        w: currentLayout.w || 1,
        h: currentLayout.h || 1,
      });
    });

    widgets.push({
      ...widgetConfig,
      id: String(widgetConfig.id),
      widgetData: widgetConfig.widget_data,
    });
  });

  return Object.assign(initialState, {
    id: dashboardConfig.id,
    isLoading: false,
    layouts,
    title: dashboardConfig.title,
    isLocked: dashboardConfig.is_locked,
    widgets,
  });
};

export const setLayouts = (state, payload) => {
  return {
    ...Object.assign(state, {
      layouts: payload,
    }),
  };
};

export const removeWidget = (state, widgetId) => {
  state.widgets = state.widgets.filter((x) => x.id !== widgetId);
  const layouts = {};
  Object.keys(state.layouts).forEach((breakPoint) => {
    layouts[breakPoint] = state.layouts[breakPoint].filter((x) => x.i !== widgetId);
  });
  state.layouts = layouts;
  return { ...state };
};

export const fetchDashboard = (state) => {
  return Object.assign(state, {
    isLoading: true,
    widgets: [],
    layouts: {
      s: [],
      lg: [],
    },
  });
};

export const fetchDashboardError = (state) => {
  return Object.assign(state, { isLoading: false });
};

export const patchDashboard = (state, data) => {
  const { title, description, is_favorite, public: isPublic } = data;
  return Object.assign(state, { title, description, is_favorite, public: isPublic });
};
