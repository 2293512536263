import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Logo = ({ height, customLogo }) => {
  return (
    <Link
      to={{
        pathname: '/',
        state: { forced: true },
      }}
    >
      <img
        alt="Ascalia logo"
        height={height}
        src={customLogo || '/ascalia-logo.svg'}
      />
    </Link>
  );
};

Logo.propTypes = {
  height: PropTypes.any,
  customLogo: PropTypes.string,
};

export default Logo;
