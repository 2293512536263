import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Modal } from 'shared';
import { defaultDateTimeFormat } from 'shared/constants';
import './style.scss';

class EventForm extends React.Component {
  constructor(props) {
    super();
    const { event } = props;
    const comment = event.comment;
    this.state = {
      comment,
    };
  }

  handleSave = () => {
    const {
      comment,
    } = this.state;
    const {
      saveEvent, event,
    } = this.props;
    if (!comment) {
      return;
    }

    event.comment = comment;
    saveEvent(event);

    setTimeout(() => {
      this.setState({
        comment: '',
      });
    }, 10);
  }

  changeComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  }

  render() {
    const {
      comment,
    } = this.state;
    const {
      isOpen, closeModal, event, isLoadingState, isReadOnly, t,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        handleSave={this.handleSave}
        handleClose={closeModal}
        title="Event details"
      >
        {
          isLoadingState &&
          <div
            className="event-form-loader"
            style={{
              borderLeftColor: 'transparent',
            }}
          />
        }
        <div className="default-form">
          <table className="event-details-table">
            <tbody>
              <tr>
                <td className="label" style={{ width: '50%' }}>
                  {t('page_content.events.events.event_form.title')}
                </td>
                <td className="label">
                  {t('page_content.events.events.event_form.created_on')}
                </td>
              </tr>
              <tr>
                <td className="value">
                  {/* <input type="text" value={event.title} disabled /> */}
                  {event.title}
                </td>
                <td className="value">
                  {/* <input type="text" value={event.description} disabled /> */}
                  {
                    event.ts ? moment(event.ts).format(defaultDateTimeFormat) : '-'
                  }
                  <div className="event-timeline event-timeline__created-on" />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('page_content.events.events.event_form.description')}
                </td>
                <td className="label">
                  {t('page_content.events.events.event_form.updated_on')}
                  <div className="event-timeline-line" />
                </td>
              </tr>
              <tr>
                <td className="value">
                  {/* <input type="text" value={event.description} disabled /> */}
                  {event.description}
                </td>
                <td className="value">
                  {/* <input type="text" value={event.description} disabled /> */}
                  {
                    event.updated_at ? moment(event.ts).format(defaultDateTimeFormat) : '-'
                  }
                  <div className="event-timeline event-timeline__updated-on" />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('page_content.events.events.event_form.status')}
                </td>
                <td className="label">
                  {t('page_content.events.events.event_form.closed_on')}
                  <div className="event-timeline-line" />
                </td>
              </tr>
              <tr>
                <td className="value">
                  {/* <input type="text" value={event.log_level} disabled /> */}
                  {event.status}
                </td>
                <td className="value">
                  {/* <input type="text" value={event.description} disabled /> */}
                  {
                    event.close_ts ? moment(event.ts).format(defaultDateTimeFormat) : '-'
                  }
                  <div className="event-timeline event-timeline__closed-on" />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('page_content.events.events.event_form.product_type')}
                </td>
                <td className="label">
                  {t('page_content.events.events.event_form.resolved_on')}
                  <div className="event-timeline-line" />
                </td>
              </tr>
              <tr>
                <td className="value">
                  {/* <input type="text" value={event.log_level} disabled /> */}
                  {event.product_type ? event.product_type.name : '-'}
                </td>
                <td className="value">
                  {/* <input type="text" value={event.description} disabled /> */}
                  {
                    event.resolved_ts ? moment(event.ts).format(defaultDateTimeFormat) : '-'
                  }
                  <div className="event-timeline event-timeline__resolved-on" />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('page_content.events.events.event_form.asset')}
                </td>
                <td className="label">
                  {t('page_content.events.events.event_form.threshold')}
                </td>
              </tr>
              <tr>
                <td className="value">
                  {/* <input type="text" value={event.asset.name} disabled /> */}
                  {event.asset.name}
                </td>
                <td className="value">
                  {/* <input type="text" value={event.log_level} disabled /> */}
                  {event.threshold}
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('page_content.events.events.event_form.log_level')}
                </td>
                <td className="label">
                  {t('page_content.events.events.event_form.comment')}
                </td>
              </tr>
              <tr>
                <td className="value">
                  {/* <input type="text" value={event.log_level} disabled /> */}
                  {event.log_level}
                </td>
                <td className="value">
                  <input type="text" value={comment} onChange={this.changeComment} readOnly={isReadOnly} />
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            {event.image ?
              <img
                src={event.image}
                alt="asset"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  display: 'block',
                  marginTop: '5%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  border: '0.5px solid #BDBDBD',
                  borderRadius: '8px',
                  padding: '10px',
                  boxSizing: 'border-box',
                }}
              /> : '-'}
          </div>
        </div>
      </Modal>
    );
  }
}

EventForm.propTypes = {
  event: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveEvent: PropTypes.func.isRequired,
  isLoadingState: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(EventForm);
