import React, { Component } from 'react';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { get, sortBy } from 'lodash';
import Tooltip from 'rc-tooltip';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import api from 'helpers/api';
import { Table, Button } from 'shared';
import { IconSearch, IconCheckMarkTrue, IconCheckMarkFalse, IconInfo } from 'shared/Icons';
import { numberSeparatorFormat } from 'industry/helpers';
import ReactDatePicker from 'react-datepicker';
import { getLocale } from 'shared/DatePicker/constants';
import { multiSelectStylesAutoHeight } from 'styles/modules/reactSelect';
import { defaultDateFormat, defaultTimeFormat } from 'shared/constants';
import { getProductivity } from '../actions';
import ProductivityModal from './ProductivityModal';

momentDurationFormatSetup(moment);
class Productivity extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    this.state = {
      isLoading: true,
      productivity_data: [],
      next: null,
      previous: null,
      count: 0,
      startDate: moment().subtract(1, 'days'),
      endDate: null,
      isProductivityModalOpen: false,
      selectedWorker: {},
      filterByDropdownValues: [],
      selectedFilterBy: [],
      searchQuery: '',
      isLoadingExport: false,
      selectedAscDesc: 'desc',
      selectedSort: 'oee',
    };
  }

  componentDidMount() {
    this.getProductivityData();
  }

  setDropdownValuesFromArray = (arrayOfResults) => {
    let filterByDropdownValues = [];
    filterByDropdownValues = arrayOfResults.map((data) => ({ value: data?.worker_category, label: data?.worker_category })).filter((el) => el.value != null);
    filterByDropdownValues = [...new Set(filterByDropdownValues.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));
    return filterByDropdownValues;
  }

  handleStartChange = (date) => {
    this.setState({
      startDate: date,
      filterByDropdownValues: [],
      isLoading: true,
    }, () => {
      this.getProductivityData();
    });
  }

  handleEndChange = (date) => {
    this.setState({
      endDate: date,
      filterByDropdownValues: [],
      isLoading: true,
    }, () => {
      this.getProductivityData();
    });
  }

  clearSearch = () => {
    this.setState({
      startDate: moment().subtract(1, 'days'),
      endDate: null,
      filterByDropdownValues: [],
      selectedFilterBy: [],
      isLoading: true,
      searchQuery: '',

    }, () => {
      this.getProductivityData();
    });
  }

  openProductivityModal = (data) => {
    this.setState({
      isProductivityModalOpen: true,
      selectedWorker: data,
    });
  }

  handleCloseModal = () => {
    this.setState({
      isProductivityModalOpen: false,
    });
  }

  changeFilterBy = (e) => {
    this.setState({
      selectedFilterBy: e,
    });
  }

  exportToExcel = () => {
    const { companyId, locationId } = this.props;
    const { startDate, endDate, count } = this.state;

    let filters = '';

    if (startDate && endDate) {
      filters += `&date__lte=${moment(endDate).format('YYYY-MM-DD')}&date__gte=${moment(startDate).format('YYYY-MM-DD')}`;
    }

    if (startDate && !endDate) {
      filters += `&date=${moment(startDate).format('YYYY-MM-DD')}`;
    }

    this.setState({
      isLoadingExport: true,
    });

    api.get(`/api/v1/workforce/productivity/?company=${companyId}&location_id=${locationId}${filters}&order_by=-total_checkin_seconds&format=xlsx&limit=${count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'productivity_list.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({
          isLoadingExport: false,
        });
      });
  }

  handleQueryChange = (e) => {
    const searchValue = e.target.value;
    this.setState({ searchQuery: searchValue }, () => {
      this.getProductivityData();
    });
  }

  handleQueryClear = () => {
    this.setState({ searchQuery: '' }, () => {
      this.getProductivityData();
    });
  }

  getProductivityData = () => {
    const { searchQuery, startDate, endDate, selectedAscDesc, selectedSort, selectedFilterBy } = this.state;
    const { companyId, locationId } = this.props;

    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      if (searchQuery.length < 1 || searchQuery.length >= 2) {
        this.setState({
          isLoading: true,
        });
        let filters = '';
        if (startDate && endDate) {
          filters += `&date__lte=${moment(endDate).format('YYYY-MM-DD')}&date__gte=${moment(startDate).format('YYYY-MM-DD')}`;
        }

        if (startDate && !endDate) {
          filters += `&date=${moment(startDate).format('YYYY-MM-DD')}`;
        }

        if (searchQuery) {
          filters += `&first_or_last_name=${searchQuery}`;
        }

        const asc = selectedAscDesc === 'desc' ? '-' : '';
        filters += `&order_by=${asc}${selectedSort || 'date'}`;

        getProductivity(companyId, locationId, filters)
          .then((re) => {
            let filterByDropdownValues = [];
            if (re && re.data && re.data.results && re.data.results.length) {
              filterByDropdownValues = this.setDropdownValuesFromArray(re.data.results);
            }

            // Check if all selected values are present in the new filterByDropdownValues
            const oldState = [...selectedFilterBy] || [];
            const validSelectedFilterBy = oldState.filter((selected) => filterByDropdownValues.some((el) => el.value === selected.value));

            this.setState({
              count: re?.data?.count || null,
              isLoading: false,
              productivity_data: re?.data?.results || [],
              next: re?.data?.next,
              previous: re?.data?.previous,
              filterByDropdownValues,
              selectedFilterBy: validSelectedFilterBy.length ? validSelectedFilterBy : [],
            });
          });
      }
    }, 500);
  }

  handleSorting = (sortData) => {
    let column = sortData.id;
    if (column === 'worker.external_id' ||
    column === 'worker.name' ||
    column === 'worker.last_name' ||
    column === 'worker.position' ||
    column === 'worker.department' ||
    column === 'worker_category'
    ) {
      column = column.replace('.', '__');
    } else if (column === 'booked_times') {
      column = 'total_booked_seconds';
    } else if (column === 'total_times') {
      column = 'total_checkin_seconds';
    }
    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.getProductivityData();
    });
  }

  generateTooltipHtml = (dataArray) => {
    if (Array.isArray(dataArray)) {
      if (dataArray && dataArray.length) {
        return dataArray.map((item, index) => {
          const [key, value] = Object.entries(item)[0];
          return <p key={index}>{key}: {value}%</p>;
        });
      }
      return null;
    }
    return null;
  }

  render() {
    const { t, i18n, company_short_code, isReadOnly } = this.props;
    const {
      endDate,
      startDate,
      isLoading,
      searchQuery,
      selectedWorker,
      isLoadingExport,
      selectedFilterBy,
      productivity_data,
      filterByDropdownValues,
      isProductivityModalOpen,
    } = this.state;

    let filtered_productivity_data = [...productivity_data];
    if (selectedFilterBy?.length) {
      const selectedValues = selectedFilterBy.map((filter) => filter.value);
      filtered_productivity_data = filtered_productivity_data.filter((el) => selectedValues.includes(el?.worker_category));
    }

    const filterByDropdownValuesExpanded = [...filterByDropdownValues];

    return (
      <div className="productivity_container">
        <div className="productivity_toolbar">
          <div className="input_container">
            <input onChange={this.handleQueryChange} placeholder={t('page_content.workforce_management.productivity.search_name_lastName')} value={searchQuery} />
            {searchQuery && <button
              onClick={() => {
                this.handleQueryClear();
              }}
            >&times;</button>}
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <div className="datepicker_container">
            <div className="datepicker_selector">
              <ReactDatePicker
                selected={startDate ? moment(startDate).toDate() : null}
                placeholderText={t('page_content.workforce_management.productivity.select_start_date_placeholder')}
                dateFormat="dd.MM.yyyy."
                selectsStart
                maxDate={endDate ? moment(endDate).toDate() : null}
                onChange={this.handleStartChange}
                showTimeSelect={false}
                disabledKeyboardNavigation
                locale={getLocale(t)}
              />
            </div>
            <div className="datepicker_selector">
              <ReactDatePicker
                selected={endDate ? moment(endDate).toDate() : null}
                placeholderText={t('page_content.workforce_management.productivity.select_end_date_placeholder')}
                dateFormat="dd.MM.yyyy."
                selectsEnd
                minDate={startDate ? moment(startDate).toDate() : null}
                onChange={this.handleEndChange}
                showTimeSelect={false}
                disabledKeyboardNavigation
                locale={getLocale(t)}
              />
            </div>
          </div>
          <Select
            options={sortBy(filterByDropdownValuesExpanded, [(opt) => opt.value.toLowerCase()])}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            placeholder={t('page_content.workforce_management.productivity.all_categories')}
            isSearchable
            isMulti
            onChange={(e) => this.changeFilterBy(e)}
            value={selectedFilterBy?.length ? selectedFilterBy : null}
            styles={multiSelectStylesAutoHeight}
          />
          <div className="clear_all_button">
            <Button
              onClick={this.clearSearch}
            >
              {t('page_content.events.events.clear_all_button')}
            </Button>
          </div>
          <div className="export_button">
            <Button
              type="export"
              onClick={this.exportToExcel}
              loading={isLoadingExport}
            >{t('page_content.workforce_management.productivity.export_to_excel_button')}</Button>
          </div>
        </div>
        <div>
          <Table
            columns={[
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.number')}</span>,
                Cell: (row) => `${row?.index + 1}.`,
                width: 45,
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_date')}</span>,
                accessor: 'date',
                Cell: (row) => (row.original && row.original.date_to && row.value ? `${moment(row.value).format(defaultDateFormat)}-\n${moment(row.original.date_to).format(defaultDateFormat)}` : row && row.value ? moment(row.value).format(defaultDateFormat) : ''),
                style: {
                  cursor: 'pointer',
                },
                width: 100,
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_date_physical')}</span>,
                accessor: 'date_physical',
                Cell: (row) => (row.value ? moment(row.value).format(defaultDateFormat) : '-'),
                style: {
                  cursor: 'default',
                },
                show: (company_short_code && company_short_code === 'zito'),
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_id')}</span>,
                accessor: 'worker.external_id',
                Cell: (row) => row.value || '-',
                width: 70,
                style: {
                  cursor: 'pointer',
                },
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_name')}</span>,
                accessor: 'worker.name',
                Cell: (row) => row.value || '-',
                style: {
                  cursor: 'pointer',
                },
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_last_name')}</span>,
                accessor: 'worker.last_name',
                Cell: (row) => row.value || '-',
                style: {
                  cursor: 'pointer',
                },
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_position')}</span>,
                accessor: 'worker.position',
                Cell: (row) => row.value || '-',
                style: {
                  cursor: 'pointer',
                },
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_department')}</span>,
                accessor: 'worker.department',
                Cell: (row) => row.value || '-',
                style: {
                  cursor: 'pointer',
                },
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_category')}</span>,
                accessor: 'worker_category',
                Cell: (row) => row.value || '-',
                style: {
                  cursor: 'pointer',
                },
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_factory_time')}</span>,
                accessor: 'factory_time',
                Cell: (row) => (row.value ? moment.duration(row.value, 'seconds').format(defaultTimeFormat) : '-'),
                style: {
                  cursor: 'pointer',
                },
                width: 90,
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_total_checked_in')}</span>,
                accessor: 'total_times',
                Cell: (row) => row.value || '-',
                style: {
                  cursor: 'pointer',
                },
                width: 90,
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_total_booked')}</span>,
                accessor: 'booked_times',
                Cell: (row) => row.value || '-',
                style: {
                  cursor: 'pointer',
                },
                width: 90,
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_stoppage_times')}</span>,
                accessor: 'stoppage_times',
                Cell: (row) => row.value || '-',
                style: {
                  cursor: 'default',
                },
                show: !(company_short_code && company_short_code === 'zito'),
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_overhead')}</span>,
                accessor: 'overhead_times',
                Cell: (row) => row.value || '-',
                style: {
                  cursor: 'pointer',
                },
                width: 80,
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_performance')}</span>,
                accessor: 'performance',
                Cell: (row) => (row.value ? `${numberSeparatorFormat(i18n.language, row.value, 1, 1)}%` : '-'),
                style: {
                  cursor: 'pointer',
                  alignItems: 'end',
                },
                sortMethod: (a, b) => Number(a.replace(/%/g, '')) - Number(b.replace(/%/g, '')),
                show: !(company_short_code && company_short_code === 'podravka'),
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_quality')}</span>,
                accessor: 'quality',
                Cell: (row) => (row.value ? `${numberSeparatorFormat(i18n.language, row.value, 1, 1)}%` : '-'),
                style: {
                  cursor: 'pointer',
                  alignItems: 'end',
                },
                sortMethod: (a, b) => Number(a.replace(/%/g, '')) - Number(b.replace(/%/g, '')),
                show: !(company_short_code && (company_short_code === 'zito' || company_short_code === 'podravka')),
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_avaliability')}</span>,
                accessor: 'availability',
                Cell: (row) => (row.value ? `${numberSeparatorFormat(i18n.language, row.value, 1, 1)}%` : '-'),
                style: {
                  cursor: 'pointer',
                  alignItems: 'end',
                },
                sortMethod: (a, b) => Number(a.replace(/%/g, '')) - Number(b.replace(/%/g, '')),
                show: !(company_short_code && (company_short_code === 'zito' || company_short_code === 'podravka')),
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_productivity')}</span>,
                accessor: 'productivity',
                Cell: (row) => (row.value ? `${numberSeparatorFormat(i18n.language, row.value, 1, 1)}%` : '-'),
                style: {
                  cursor: 'pointer',
                  alignItems: 'end',
                },
                show: !(company_short_code && (company_short_code === 'zito' || company_short_code === 'podravka')),
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_factory_oee')}</span>,
                accessor: 'oee_factory',
                Cell: (row) => (row.value ? `${numberSeparatorFormat(i18n.language, row.value, 1, 1)}%` : '0%'),
                style: {
                  cursor: 'pointer',
                  alignItems: 'end',
                },
                width: 80,
              },
              {
                Header: () => <span>OEE</span>,
                accessor: 'oee',
                Cell: (row) => (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: row?.original?.oee_tooltip ? 'space-around' : 'start' }}>
                    {row.value ? (
                      <>
                        {`${numberSeparatorFormat(i18n.language, row.value, 1, 1)}%`}
                        {
                          row?.original?.oee_tooltip &&
                          <Tooltip
                            id="tooltip-zoom"
                            trigger={['hover']}
                            placement="left"
                            overlay={<p>{this.generateTooltipHtml(row.original.oee_tooltip)}</p>}
                            overlayClassName="where-filter-tooltip"
                          >
                          <span aria-describedby="tooltip-zoom">
                            <IconInfo color="#2e86de" height="15px" width="17px" />
                          </span>
                        </Tooltip>
                        }
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                ),
                style: {
                  cursor: 'pointer',
                  alignItems: 'end',
                },
                width: 80,
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.oee_points')}</span>,
                accessor: 'oee_score',
                Cell: (row) => (row.value ? `${numberSeparatorFormat(i18n.language, row.value, 1, 1)}%` : '-'),
                style: {
                  cursor: 'pointer',
                  alignItems: 'end',
                },
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.work_injury')}</span>,
                accessor: 'has_injury',
                Cell: (row) => (
                  <div style={{ margin: 'auto' }}>
                    {row.value === undefined
                      ? '-'
                      : (row.value
                        ? <IconCheckMarkTrue
                            color="#FF0000"
                            height="20px"
                            width="20px"
                        />
                        : <IconCheckMarkFalse
                            color="#6BBE66"
                            height="20px"
                            width="20px"
                        />
                      )}
                  </div>
                ),
                style: {
                  cursor: 'pointer',
                },
                show: !(company_short_code && company_short_code === 'zito'),
                width: 100,
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.complaint')}</span>,
                accessor: 'has_claim',
                Cell: (row) => (
                  <div style={{ margin: 'auto' }}>
                    {row.value === undefined
                      ? '-'
                      : (row.value
                        ? <IconCheckMarkTrue
                            color="#FF0000"
                            height="20px"
                            width="20px"
                        />
                        : <IconCheckMarkFalse
                            color="#6BBE66"
                            height="20px"
                            width="20px"
                        />
                      )}
                  </div>
                ),
                style: {
                  cursor: 'pointer',
                },
                width: 100,
                show: !(company_short_code && company_short_code === 'zito'),
              },
              {
                Header: () => <span>{t('page_content.workforce_management.productivity.table_column_score')}</span>,
                accessor: 'score',
                Cell: (row) => (row.value ? `${numberSeparatorFormat(i18n.language, row.value, 1, 1)}%` : '-'),
                style: {
                  cursor: 'pointer',
                  alignItems: 'end',
                },
                sortMethod: (a, b) => Number(a.replace(/%/g, '')) - Number(b.replace(/%/g, '')),
                show: !(company_short_code && company_short_code === 'zito'),
                width: 100,
              },
            ]}
            data={filtered_productivity_data || []}
            defaultPageSize={filtered_productivity_data?.length || 100}
            loading={isLoading}
            minRows={0}
            noDataText=" "
            sortable
            showPagination={false}
            handleClick={(rowInfo) => !isReadOnly && this.openProductivityModal(get(rowInfo, 'original'))}
            defaultSorted={[{ id: 'oee', desc: true }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <div style={{ float: 'right' }}>
            {(filtered_productivity_data?.length && filtered_productivity_data?.length > 0) ? (
              <div className="table_pagination_buttons_wrapper">
                {`${t('pagination_table.count')}: ${filtered_productivity_data?.length} | ${t('pagination_table.page')}: 1 / 1`}
              </div>
            ) : null}
          </div>
        </div>
        <ProductivityModal
          isOpen={isProductivityModalOpen}
          closeModal={this.handleCloseModal}
          data={selectedWorker}
          company_short_code={company_short_code}
        />
      </div>
    );
  }
}

Productivity.propTypes = {
  i18n: PropTypes.object,
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  company_short_code: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
  currentUser: state.currentUser,
  company_short_code: get(state, 'app.companyConfig.short_code'),
});

export default connect(mapStateToProps, null)(withTranslation()(Productivity));
