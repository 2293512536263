import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setDateRange } from 'app/actions';
import { getCurrentUserSuccess } from 'user/actions';
import { saveSortingAndFiltering } from 'industry/actions';
import { withTranslation } from 'react-i18next';
import exportFromJSON from 'export-from-json';
import Select from 'react-select';
import { selectStyles } from 'styles/modules/reactSelect';
import moment from 'moment';
import { get } from 'lodash';
import api from 'helpers/api';
import { getLocale } from 'shared/DatePicker/constants';
import { Button, Table, ContentLoader, Notification, TableButtons } from 'shared';
import { checkAccessOnPage, redirectToHomePage, checkModules, checkActiveFilters } from 'industry/helpers';
import { defaultDateFormat } from 'shared/constants';
import ReactDatePicker from 'react-datepicker';
import { getReports } from './actions';
import './styles.scss';

export class IndustryLocationReportsForSalary extends Component {
  constructor(props) {
    super();

    const sortingAndFiltering = props && props.currentUser && props.currentUser.data ? props.currentUser.data : {};
    let start_date = moment().subtract(7, 'days');
    let end_date = moment();
    let selectedAsset = '';
    let operator = '';
    let selectedLocation = props.locationId;

    this.defaultFilters = {
      start_date,
      end_date,
      selectedAsset,
      operator,
      selectedLocation,
    };

    let filtersActive = false;
    if (sortingAndFiltering.reports_for_salary) {
      const sAndF = sortingAndFiltering.reports_for_salary;
      start_date = sAndF.start_date ? moment(sAndF.start_date) : moment().subtract(7, 'days');
      end_date = sAndF.end_date ? moment(sAndF.end_date) : moment();
      selectedAsset = sAndF.selectedAsset ? sAndF.selectedAsset : '';
      operator = sAndF.operator ? sAndF.operator : '';
      selectedLocation = sAndF.selectedLocation ? sAndF.selectedLocation : props.locationId;
      filtersActive = checkActiveFilters(this.defaultFilters, sAndF);
    }
    this.state = {
      isLoading: true,
      selectedAsset,
      operator,
      start_date,
      end_date,
      selectedLocation,
      filtersActive,
      operatorsList: [],
      assetsList: [],
      locationsList: [],
      exportLoader: false,
    };
  }

  componentDidMount() {
    const {
      companyId,
      locationId,
    } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Reports for salary');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            isReadOnly: true,
          });
        }
      });

    api.get(`/api/v1/company_members/?company=${companyId}`)
      .then((json) => {
        this.setState({
          operatorsList: get(json, 'data.results'),
        });
      });

    api.get(`/api/v1/industry/assets/?location=${locationId}&order_by=name&limit=200&company=${companyId}`)
      .then((json) => {
        this.setState({
          assetsList: get(json, 'data.results'),
        });
      });

    api.get('/api/v1/companies/')
      .then((json) => this.setState({
        locationsList: get(json, 'data.results'),
      }))
      .then(() => {
        const { locationsList } = this.state;
        // eslint-disable-next-line eqeqeq
        const locations = locationsList.filter((el) => el.id == companyId);
        const updatedLocationsList = (locations && locations[0] && locations[0].locations) ? locations[0].locations : [];
        this.setState({ locationsList: updatedLocationsList });
      });

    this.getTimeRangeReports();
  }

  componentWillUnmount() {
    this.saveSortingAndFiltering();
  }

  getTimeRangeReports = () => {
    this.setState({
      isLoadingReports: true,
      isLoading: false,
    });
    const { selectedAsset, start_date, end_date, selectedLocation, operator } = this.state;

    let filters = '';

    filters += `&date_after=${moment(start_date).format('YYYY-MM-DD')}`;
    filters += `&date_before=${moment(end_date).format('YYYY-MM-DD')}`;

    if (selectedAsset) {
      filters += `&asset=${selectedAsset}`;
    }

    if (operator) {
      filters += `&operator=${operator}`;
    }

    const url = `/api/v1/industry/production_report/?location=${selectedLocation}`;
    getReports(url, filters)
      .then((res) => {
        this.setState({
          reports: res.data,
          next: res.next,
          previous: res.previous,
          count: res.count,
          isLoadingReports: false,
        }, () => {
          this.saveSortingAndFiltering();
        });
      });
  }

  saveSortingAndFiltering = () => {
    const {
      start_date,
      end_date,
      selectedAsset,
      operator,
      selectedLocation,
    } = this.state;

    const {
      currentUser,
      setCurrentUser,
    } = this.props;

    if (currentUser) {
      currentUser.data.reports_for_salary = {
        start_date,
        end_date,
        selectedAsset,
        operator,
        selectedLocation,
      };
      setCurrentUser(currentUser);
      saveSortingAndFiltering({ data: currentUser.data });
      this.checkActiveSortingAndFiltering(currentUser.data.reports_for_salary);
    }
  }

  checkActiveSortingAndFiltering = (userFilters) => {
    const filtersActive = checkActiveFilters(this.defaultFilters, userFilters);
    this.setState({ filtersActive });
  }

  handleAssetChange = (val) => {
    this.setState({
      selectedAsset: val,
    }, () => {
      this.saveSortingAndFiltering();
      this.getTimeRangeReports();
    });
  }

  handleOperatorChange = (val) => {
    this.setState({
      operator: val,
    }, () => {
      this.saveSortingAndFiltering();
      this.getTimeRangeReports();
    });
  }

  handleChangeStartDate = (t) => {
    const formatedDate = moment(t);
    this.setState({
      start_date: formatedDate._d,
    }, () => {
      this.saveSortingAndFiltering();
      this.getTimeRangeReports();
    });
  }

  handleChangeEndDate = (t) => {
    const formatedDate = moment(t);
    this.setState({
      end_date: formatedDate._d,
    }, () => {
      this.saveSortingAndFiltering();
      this.getTimeRangeReports();
    });
  }

  clearSearch = () => {
    const { locationId } = this.props;
    this.setState({
      operator: '',
      selectedAsset: '',
      start_date: moment().subtract(7, 'days'),
      end_date: moment(),
      selectedLocation: locationId,
    });

    setTimeout(this.getTimeRangeReports, 100);
  }

  exportToExcel = () => {
    const { selectedAsset, start_date, end_date, selectedLocation, operator } = this.state;
    this.setState({
      exportLoader: true,
    });

    let filters = '';

    filters += `&date_after=${moment(start_date).format('YYYY-MM-DD')}`;
    filters += `&date_before=${moment(end_date).format('YYYY-MM-DD')}`;

    if (selectedAsset) {
      filters += `&asset=${selectedAsset}`;
    }

    if (operator) {
      filters += `&operator=${operator}`;
    }

    getReports(selectedLocation, filters)
      .then((re) => {
        if (re && re.data && re.data.length > 0) {
          const exportReports = re.data;
          for (let i = 0; i < exportReports.length; i++) {
            exportReports[i].asset = exportReports[i].asset.name;
            exportReports[i].shift = exportReports[i].shift.name;
            exportReports[i].sum = parseInt(exportReports[i].sum, 10).toFixed(2);
            exportReports[i].date = exportReports[i].date ? moment(exportReports[i].date).format('DD.MM.YYYY.') : null;
          }

          const fileName = `reports_from${moment(start_date).format('YYYY-MM-DD')}_to_${moment(end_date).format('YYYY-MM-DD')}`;
          const exportType = 'xls';
          exportFromJSON({ data: exportReports, fileName, exportType });
          this.setState({
            exportLoader: false,
          });
        } else {
          this.setState({
            exportLoader: false,
          });
          return Notification('error', 'An error occurred while fetching reports', 'There is no data for selected time range');
        }
      });
  }

  fetchData = (url) => {
    this.setState({ isLoadingReports: true });
    getReports(url)
      .then((res) => {
        this.setState({
          reports: res.data,
          next: res.next,
          previous: res.previous,
          count: res.count,
          isLoadingReports: false,
        }, () => {
          this.saveSortingAndFiltering();
        });
      });
  }

  render() {
    const { t } = this.props;

    const {
      operatorsList,
      assetsList,
      locationsList,
      selectedAsset,
      isLoading,
      reports,
      isLoadingReports,
      start_date,
      end_date,
      operator,
      selectedLocation,
      exportLoader,
      next,
      previous,
      count,
    } = this.state;

    if (isLoading) {
      return (<ContentLoader />);
    }

    return (
      <div className="industry-tab reports_for_salary_container fullscreen">
        <div className="filter_row">
          <Select
            options={operatorsList}
            getOptionLabel={(oper) => oper.email}
            getOptionValue={(oper) => oper.id}
            isSearchable
            placeholder={t('shared.operator_picker.placeholder')}
            onChange={(value) => this.handleOperatorChange(value.id)}
            value={(operatorsList.find((o) => o.id === operator)) || ''}
            styles={selectStyles}
          />
          <Select
            options={assetsList}
            getOptionLabel={(oper) => oper.name}
            getOptionValue={(oper) => oper.id}
            isSearchable
            placeholder={t('shared.asset_picker.placeholder')}
            onChange={(value) => this.handleAssetChange(value.id)}
            value={(assetsList.find((a) => a.id === selectedAsset)) || ''}
            styles={selectStyles}
          />
          <div>
            <ReactDatePicker
              className="datepicker_input"
              placeholderText="Select start date"
              dateFormat="dd.MM.yyyy"
              selected={start_date ? moment(start_date).toDate() : null}
              selectsStart
              onChange={this.handleChangeStartDate}
              locale={getLocale(t)}
            />
          </div>
          <div>
            <ReactDatePicker
              className="datepicker_input"
              placeholderText="Select end date"
              dateFormat="dd.MM.yyyy"
              selected={end_date ? moment(end_date).toDate() : null}
              selectsStart
              onChange={this.handleChangeEndDate}
              locale={getLocale(t)}
            />
          </div>
          <Select
            options={locationsList}
            getOptionLabel={(loc) => loc.name}
            getOptionValue={(loc) => loc.id}
            isSearchable
            placeholder={t('shared.operator_picker.placeholder')}
            onChange={(value) => this.handleAssetChange(value.id)}
            value={(locationsList.find((l) => l.id === selectedLocation)) || ''}
            styles={selectStyles}
          />

          <Button
            onClick={this.clearSearch}
          >{t('page_content.reports_for_salary.clear_button')}</Button>

          <div className="export_button">
            <Button
              type="export"
              onClick={this.exportToExcel}
              loading={exportLoader}
            >{t('page_content.reports_for_salary.export_to_excel_button')}</Button>
          </div>

        </div>

        <Table
          columns={[
            {
              Header: () => <span>{t('page_content.reports_for_salary.table_column_date')}</span>,
              accessor: 'date',
              Cell: (row) => (row.value ? moment(row.value).format(defaultDateFormat) : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.reports_for_salary.table_column_asset')}</span>,
              accessor: 'asset.name',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.reports_for_salary.table_column_operator')}</span>,
              accessor: 'operater',
              Cell: (row) => (row.value ? `${row.value.email}` : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.reports_for_salary.table_column_shift')}</span>,
              accessor: 'shift.name',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },

            },
            {
              Header: () => <span>{t('page_content.reports_for_salary.table_column_cut_length')}</span>,
              accessor: 'sum',
              Cell: (row) => <span>{row.value ? parseInt(row.value, 10).toFixed(2) : '-'}</span>,
              style: {
                cursor: 'default',
              },

            },
          ]}
          data={reports || []}
          loading={isLoadingReports}
          defaultPageSize={30}
          minRows={0}
          noDataText=" "
          manual={false}
          showPagination={false}
        />
        <div style={{ float: 'right' }}>
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchData} count={count} />
        </div>
      </div>
    );
  }
}

IndustryLocationReportsForSalary.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  dispatchDateRange: setDateRange,
  setCurrentUser: getCurrentUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IndustryLocationReportsForSalary));
