const currentUrl = window.location.href || '';

let dsn = 'https://c6384e1d30bfcf65b8aadc5ca73db32c@sentry.ascalia.io/14';

if (currentUrl.includes('podravka')) {
  dsn = 'http://3f0f507d674dacf930899df48073d074@sentry.internal-mes.podravka.hr:9000/7';
}

if (currentUrl.includes('telekomcloud')) {
  dsn = 'https://19b981137281d53aa601403ccacad0bf@sentry.ascalia.io/19';
}

// eslint-disable-next-line
export const getDsn = () => {
  return dsn;
};
