import React from 'react';
import Base from './Base';

export default function IconUp(props) {
  // eslint-disable-next-line react/prop-types
  const { color, ...otherProps } = props;

  return (
    <Base {...otherProps} fill={color || 'black'} viewBox="0, 0, 15, 15">
      <path d="M9.5 0L18.5933 12H0.406734L9.5 0Z" fill={color || 'black'} />
    </Base>
  );
}
