import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { IconSearch } from 'shared/Icons';
import { Table, TableButtons, Button } from 'shared';
import { selectStyles, selectModalStyles } from 'styles/modules/reactSelect';
import { styledStatusOptions } from 'industry/helpers';
import { getOrderItemsList, getPaginatedOrderItemsList, getProjects, getAllDepartments, getAllTechnologies } from '../actions';
import './style.scss';
import CreateOrderFromItemsModal from './CreateOrderFromItemsModal';

class OrderItemSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      orderItems: [],
      filters: {
        query: '',
        search_value: 'product_name',
        project: null,
      },
      selectedOrderItems: [],
      selectedOrderItemsIds: [],
      isCreateOrderFromItemsModalOpen: false,
      listOfProjects: [],
      selectedAscDesc: 'asc',
      selectedSort: 'order__external_id',
      departments: [],
      technologies: [],
    };
  }

  async componentDidMount() {
    const { companyId, locationId } = this.props;

    await this.getDepartments();
    await this.getTechnologies();

    getProjects(companyId, locationId)
      .then((res) => {
        const projectsList = get(res, 'data.results', []);
        this.setState((prevState) => ({
          listOfProjects: projectsList,
          filters: {
            ...prevState.filters,
            project: projectsList.length > 0 ? projectsList[0].id : null,
          },
        }), () => {
          this.fetchOrderItemsList();
        });
      })
      .catch(() => {
        this.setState(
          { listOfProjects: [] },
          () => {
            this.fetchOrderItemsList();
          },
        );
      });
  }

  getDepartments = async () => {
    const { locationId } = this.props;

    return getAllDepartments(locationId)
      .then((re) => {
        this.setState({
          departments: get(re, 'data.results') || [],
        });
      })
      .catch((error) => console.log(error));
  }

  getDepartmentNameById = (departmentId) => {
    const { departments } = this.state;

    const targetDepartment = departments.find((d) => d.id === departmentId);
    if (targetDepartment) {
      return targetDepartment.name;
    }
    return '';
  }

  getTechnologies = async () => {
    const { locationId } = this.props;

    return getAllTechnologies(locationId)
      .then((re) => {
        this.setState({
          technologies: get(re, 'data.results') || [],
        });
      })
      .catch((error) => console.log(error));
  }

  getTechnologyNameById = (technologyId) => {
    const { technologies } = this.state;

    const targetTechnology = technologies.find((t) => t.id === technologyId);
    if (targetTechnology) {
      return targetTechnology.name;
    }
    return '';
  }

  fetchOrderItemsList = () => {
    const { companyId } = this.props;
    const { selectedSort, selectedAscDesc, filters } = this.state;

    let apiFilters = '';

    this.setState({
      isLoading: true,
    });

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    let sortKey = selectedSort;

    if (sortKey === 'order.external_id' ||
      sortKey === 'product_type.name' ||
      sortKey === 'product_type.description' ||
      sortKey === 'project.name' ||
      sortKey === 'product_type.product_type'
    ) {
      sortKey = sortKey.replace('.', '__');
    }

    if (sortKey) {
      apiFilters += `&order_by=${asc}${sortKey || ''}`;
    }

    if (filters?.query && filters?.search_value) {
      apiFilters += `&${filters.search_value}=${filters.query}`;
    }

    if (filters?.project) {
      apiFilters += `&project=${filters.project}`;
    }

    clearTimeout(this.fetchTimeout);

    this.fetchTimeout = setTimeout(() => {
      getOrderItemsList(companyId, apiFilters)
        .then((res) => {
          this.setState({
            orderItems: get(res, 'data.results') || [],
            next: get(res, 'data.next'),
            previous: get(res, 'data.previous'),
            count: get(res, 'data.count'),
            isLoading: false,
          });
        })
        .catch((error) => {
          console.error('Error fetching order items:', error);
          this.setState({ isLoading: false });
        });
    }, 500);
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchOrderItemsList();
    });
  }

  redirectToDetails = (orderId) => {
    const selection = window.getSelection();
    if (selection.toString().length === 0) {
      const { companyId, locationId } = this.props;

      if (orderId) {
        window.open(`/${companyId}/industry/location/${locationId}/orders/${orderId}`, '_blank');
      }
    }
  }

  fetchPaginatedOrderItemsList = (url) => {
    this.setState({
      isLoading: true,
    });
    getPaginatedOrderItemsList(url)
      .then((res) => {
        this.setState({
          orderItems: get(res, 'data.results') || [],
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          count: get(res, 'data.count'),
          isLoading: false,
        });
      });
  }

  onFilterChange = (key, value) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [key]: value,
      },
    }), () => {
      this.fetchOrderItemsList();
    });
  }

  // multipleSelectOrderItems = (e, itemsArray) => {
  //   const { selectedOrderItemsIds, selectedOrderItems } = this.state;
  //   let selectedIdsPool = [...selectedOrderItemsIds];
  //   let selectedItems = [...selectedOrderItems];

  //   if (e.target.checked === true) {
  //     for (const orderItem of itemsArray) {
  //       if (!selectedIdsPool.includes(orderItem.id)) {
  //         selectedIdsPool.push(orderItem.id);
  //         selectedItems.push(orderItem);
  //       }
  //     }
  //   } else {
  //     for (const orderItem of itemsArray) {
  //       selectedIdsPool = selectedIdsPool.filter((x) => parseInt(x, 10) !== orderItem.id);
  //       selectedItems = selectedItems.filter((x) => parseInt(x.id, 10) !== orderItem.id);
  //     }
  //   }
  //   this.setState({ selectedOrderItemsIds: selectedIdsPool, selectedOrderItems: selectedItems });
  // }

  selectOrderItem = (e, rowObject) => {
    const { selectedOrderItemsIds, selectedOrderItems } = this.state;

    e.stopPropagation();

    let selectedIdsPool = [...selectedOrderItemsIds];
    let selectedItems = [...selectedOrderItems];

    const itemId = parseInt(e.target.value, 10);

    if (e.target.checked === true) {
      if (selectedIdsPool.includes(itemId) === false) {
        selectedIdsPool.push(itemId);
        selectedItems.push(rowObject);
      }
    } else {
      selectedIdsPool = selectedIdsPool.filter((x) => parseInt(x, 10) !== itemId);
      selectedItems = selectedItems.filter((x) => parseInt(x.id, 10) !== itemId);
    }
    this.setState({ selectedOrderItemsIds: selectedIdsPool, selectedOrderItems: selectedItems });
  }

  openCreateOrderFromItemsModal = () => {
    this.setState({
      isCreateOrderFromItemsModalOpen: true,
    });
  }

  closeCreateOrderFromItemsModal = () => {
    this.setState({
      isCreateOrderFromItemsModalOpen: false,
    });
  }

  isCheckboxDisabled = (row) => {
    const { selectedOrderItems } = this.state;

    return selectedOrderItems.some((obj) => {
      const rowDept = row.original.product_type?.department;
      const objDept = obj.product_type?.department;

      // If row has no department but selected part has, disable it
      if (!rowDept && objDept) {
        return true;
      }

      // If both have departments but their ids do not match, disable it
      if (rowDept !== objDept) {
        return true;
      }

      return false;
    });
  };

  render() {
    const {
      orderItems,
      isLoading,
      previous,
      next,
      count,
      filters,
      selectedOrderItems,
      selectedOrderItemsIds,
      isCreateOrderFromItemsModalOpen,
      listOfProjects,
    } = this.state;
    const { t, companyId } = this.props;

    const tableColumnConfig = [
      {
        Header: () => (<span>
          {/* <input
            type="checkbox"
            onChange={(e) => this.multipleSelectOrderItems(e, orderItems)}
            checked={orderItems.every((obj) => selectedOrderItemsIds.includes(obj.id))}
            disabled={false}
            style={{ margin: 'auto', display: 'block', marginBottom: '5px' }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          /> */}
          {selectedOrderItemsIds?.length ? `(${selectedOrderItemsIds.length})` : ''}
        </span>),
        width: 60,
        sortable: false,
        Cell: (row) => (
          <span onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              onChange={(e) => this.selectOrderItem(e, row.original)}
              value={row.original.id}
              checked={(selectedOrderItemsIds !== null && selectedOrderItemsIds.length > 0 && selectedOrderItemsIds.includes(row.original.id))}
              disabled={(row?.original?.target_order) || this.isCheckboxDisabled(row)}
            />
          </span>
        ),
      },
      {
        Header: () => <span>{t('page_content.orders.order_items_table.name')}</span>,
        accessor: 'product_type.name',
        Cell: (row) => (row.value ? row.value : '-'),
        style: {
          cursor: 'default',
        },
      },
      {
        Header: () => <span>{t('page_content.orders.order_items_table.order')}</span>,
        accessor: 'order.external_id',
        Cell: (row) => <span className="order-list__id" onClick={() => this.redirectToDetails(row?.original?.order?.id || null)}>{row.value ? row.value : '-'}</span>,
      },
      {
        Header: () => <span>{t('page_content.orders.order_items_table.target_order')}</span>,
        accessor: 'target_order.external_id',
        Cell: (row) => <span className="order-list__id" onClick={() => this.redirectToDetails(row?.original?.target_order?.id || null)}>{row.value ? row.value : '-'}</span>,
      },
      {
        Header: () => <span>{t('page_content.orders.order_items_table.department')}</span>,
        accessor: 'product_type.department',
        Cell: (row) => (this.getDepartmentNameById(row?.value)),
        style: {
          cursor: 'default',
        },
      },
      {
        Header: () => <span>{t('page_content.orders.order_items_table.technology')}</span>,
        accessor: 'product_type.technology',
        Cell: (row) => (this.getTechnologyNameById(row?.value)),
        style: {
          cursor: 'default',
        },
      },
      {
        Header: () => <span>{t('page_content.orders.order_items_table.description')}</span>,
        accessor: 'product_type.description',
        Cell: (row) => (row.value ? row.value : '-'),
        style: {
          cursor: 'default',
        },
      },
      {
        Header: () => <span>{t('page_content.orders.order_items_table.project')}</span>,
        accessor: 'project.name',
        Cell: (row) => (row.value ? row.value : '-'),
        style: {
          cursor: 'default',
        },
      },
      {
        Header: () => <span>{t('page_content.orders.order_items_table.product_type')}</span>,
        accessor: 'product_type.product_type',
        width: 120,
        Cell: (row) => (row.value ? row.value : '-'),
        style: {
          cursor: 'default',
        },
      },
      {
        Header: () => <span>{t('page_content.orders.order_items_table.status')}</span>,
        accessor: 'status',
        width: 120,
        Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span style={styledStatusOptions(row.value)}>{row.value ? t([`page_content.orders.statuses.${row.value}`]) : t('page_content.orders.statuses.no_status')}</span>
        </div>,
        style: {
          cursor: 'default',
        },
      },
      {
        Header: () => <span>{t('page_content.orders.order_items_table.quantity')}</span>,
        accessor: 'quantity',
        width: 120,
        Cell: (row) => (row.value ? row.value : '-'),
        style: {
          cursor: 'default',
        },
      },
      {
        Header: () => <span>{t('page_content.orders.order_items_table.unit_code')}</span>,
        accessor: 'unit_code',
        width: 120,
        Cell: (row) => (row.value ? row.value : '-'),
        style: {
          cursor: 'default',
        },
      },
      {
        Header: () => <span>{t('page_content.orders.order_items_table.quantity_done')}</span>,
        accessor: 'quantity_done',
        width: 120,
        Cell: (row) => (row.value ? row.value : '-'),
        style: {
          cursor: 'default',
        },
      }];

    const searchFilterOptions = [
      { value: 'product_name', name: t('page_content.orders.product_name') },
      { value: 'product_code', name: t('page_content.orders.product_code') },
    ];

    return (
      <div className="order_items_search_container">
        <div className="filters">
          <div className="input_container">
            <input
              onChange={(e) => this.onFilterChange('query', e.target.value)}
              placeholder={t('page_content.orders.search_for_placeholder')}
              value={filters.query || ''}
            />
            {filters.query && (
              <button
                onClick={() => this.onFilterChange('query', '')}
              >
                &times;
              </button>
            )}
            <Select
              options={searchFilterOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              isSearchable={false}
              onChange={(e) => this.onFilterChange('search_value', e.value)}
              value={(searchFilterOptions.find((sOption) => sOption.value === filters.search_value)) || ''}
              styles={selectStyles}
            />
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>

          <div style={{ width: '250px' }}>
            <Select
              options={listOfProjects}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isSearchable
              onChange={(option) => this.onFilterChange('project', option.id)}
              value={(listOfProjects.find((proj) => proj.id === filters?.project)) || ''}
              styles={selectModalStyles}
            />
          </div>

        </div>

        <div className="create_order_button_wrapper">
          <Button
            disabled={selectedOrderItemsIds?.length <= 0}
            onClick={this.openCreateOrderFromItemsModal}
            type="add"
          >{t('page_content.orders.create_order')}</Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={tableColumnConfig}
          data={orderItems}
          minRows={0}
          defaultPageSize={30}
          noDataText=" "
          showPagination={false}
          loading={isLoading}
          defaultSorted={[{ id: 'order.external_id', desc: false }]}
          onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
        />
        <div style={{ float: 'right' }}>
          <TableButtons previous={previous} next={next} fetchFunction={this.fetchPaginatedOrderItemsList} count={count} />
        </div>

        {
          isCreateOrderFromItemsModalOpen &&
          <CreateOrderFromItemsModal
            t={t}
            isCreateOrderFromItemsModalOpen={isCreateOrderFromItemsModalOpen}
            closeCreateOrderFromItemsModal={this.closeCreateOrderFromItemsModal}
            selectedOrderItems={selectedOrderItems}
            selectedOrderItemsIds={selectedOrderItemsIds}
            selectedProject={filters.project}
            companyId={companyId}
          />
        }
      </div>
    );
  }
}

OrderItemSearch.propTypes = {
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isProjectBased: get(state, 'app.companyConfig.config.project_based', false),
  };
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(OrderItemSearch)));
