import api from 'helpers/api';
import { Notification } from 'shared';

export const saveRefreshInterval = (data) => {
  return api.put('/api/v1/me/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Plant floor refresh interval was successfully updated.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const saveFactoryFloor = (data, id = null) => {
  if (id) {
    return api.patch(`/api/v1/workflow/workflows/${id}/`, data)
      .then((res) => {
        if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
          throw new Error('');
        } Notification('success', 'Save successful', 'Plant floor diagram was successfully updated.');
      })
      .catch((error) => {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      });
  }

  return api.post('/api/v1/workflow/workflows/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Plant floor diagram was successfully saved.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getFactoryFloor = async (location) => {
  try {
    const res = await api
      .get(`/api/v1/workflow/workflows/?location=${location}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching factory floor', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAssetGroups = async (location) => {
  try {
    const res = await api
      .get(`/api/v1/industry/asset_groups/?location=${location}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching asset groups', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getDevices = async (companyId, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/devices/?location=${locationId}&company=${companyId}&show_assets`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching devices', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getLastEvents = async (companyId, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/events/?location=${locationId}&company=${companyId}&last=true&closed=false`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching last events', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getLastSeenCounts = async (assetGroups) => {
  try {
    const res = await api
      .get(`/api/v1/workflow/last_position_count/?asset_groups=${assetGroups}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching last seen counts', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
