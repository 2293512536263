import api from 'helpers/api';
import { Notification } from 'shared';

// eslint-disable-next-line
export const loginWithToken = (data) => {
  return api.post('/api-token-auth/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Two-Factor Authentication is succesful');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'Token is not valid, please try again.');
    });
};
