import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, DateFilters } from 'shared';
import { selectStyles } from 'styles/modules/reactSelect';
import { defaultDateFormat } from 'shared/constants';
import { IconSearch } from 'shared/Icons';
import './styles.scss';

import api from 'helpers/api';
import {
  getAllWorkers,
  getEmployments,
  getPaginatedEmployments,
  getTerminationReasons,
} from './actions';

import AddEmploymentModal from './AddEmploymentModal';

const Employments = ({ t, companyId, locationId, userIsHR }) => {
  const debounceTimeoutRef = useRef(null);
  const [tableData, setTableData] = useState({
    isLoading: true,
    next: null,
    previous: null,
    count: null,
    employments: [],
  });

  const filterOptions = [
    { name: t('page_content.human_resources.employments.table_column_first_name'), id: 'name' },
    { name: t('page_content.human_resources.employments.table_column_last_name'), id: 'last_name' },
  ];

  const [workers, setWorkers] = useState([]);
  const [reasons, setReasons] = useState([]);

  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [query, setQuery] = useState(null);
  const [clearDateFilters, setClearDateFilters] = useState(false);
  const [sortingAndFiltering, setSortingAndFiltering] = useState({
    selectedSort: null,
    selectedAscDesc: null,
    selectedFilter: { query: filterOptions[0] },
    dateFilter: null,
  });

  const fetchReasons = async () => {
    const res = await getTerminationReasons(companyId, '&limit=9999');
    const newReasons = [{ id: 'all', name: t('page_content.human_resources.employments.reason_filter_all') }];
    newReasons.push(...get(res, 'data.results', []));
    setReasons(newReasons);
  };

  const fetchEmployments = async () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    let filters = '';
    if (sortingAndFiltering?.selectedFilter?.query && query) {
      filters += `&${sortingAndFiltering?.selectedFilter.query.id}=${query}`;
    }
    if (sortingAndFiltering?.selectedFilter?.reason && sortingAndFiltering?.selectedFilter?.reason?.id !== 'all') {
      filters += `&termination_reason=${sortingAndFiltering?.selectedFilter?.reason?.id}`;
    }
    if (sortingAndFiltering?.dateFilter?.start && sortingAndFiltering?.dateFilter?.end && sortingAndFiltering?.selectedFilter?.date) {
      filters += `&${sortingAndFiltering?.selectedFilter.date}_after=${moment(sortingAndFiltering?.dateFilter.start).format('YYYY-MM-DD')}`;
    }
    if (sortingAndFiltering?.dateFilter?.start && sortingAndFiltering?.dateFilter?.end && sortingAndFiltering?.selectedFilter?.date) {
      filters += `&${sortingAndFiltering?.selectedFilter.date}_before=${moment(sortingAndFiltering?.dateFilter.end).format('YYYY-MM-DD')}`;
    }

    const asc = sortingAndFiltering?.selectedAscDesc ? sortingAndFiltering?.selectedAscDesc === 'desc' ? '-' : '' : '-';
    filters += `&order_by=${asc}${sortingAndFiltering?.selectedSort || 'updated_at'}`;

    const res = await getEmployments(companyId, filters);
    setTableData((prevState) => ({
      ...prevState,
      employments: get(res, 'data.results', []),
      next: get(res, 'data.next', []),
      previous: get(res, 'data.previous', []),
      count: get(res, 'data.count', []),
      isLoading: false,
    }));
  };

  const fetchPaginatedLayoffs = async (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const res = await getPaginatedEmployments(url);
    setTableData({
      employments: get(res, 'data.results', []),
      next: get(res, 'data.next', []),
      previous: get(res, 'data.previous', []),
      count: get(res, 'data.count', []),
      isLoading: false,
    });
  };

  const fetchWorkers = async () => {
    const res = await getAllWorkers(companyId, locationId);
    setWorkers(get(res, 'data.results', []));
  };

  useEffect(() => {
    fetchWorkers();
    fetchReasons();
  }, []);

  useEffect(() => {
    if (query !== null) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        if (sortingAndFiltering?.selectedFilter?.query) {
          fetchEmployments();
        }
      }, 300);
    }
  }, [query]);

  useEffect(() => {
    fetchEmployments();
  }, [
    sortingAndFiltering?.selectedAscDesc,
    sortingAndFiltering?.selectedSort,
    sortingAndFiltering?.selectedFilter,
  ]);

  const handleSorting = (sortData) => {
    setSortingAndFiltering((prevState) => ({
      ...prevState,
      selectedSort: sortData.id,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }));
  };

  const clearAllFilters = () => {
    setSortingAndFiltering((prevState) => ({
      ...prevState,
      selectedFilter: { query: filterOptions[0] },
      dateFilter: null,
    }));
    setQuery('');
    setClearDateFilters((prevState) => !prevState);
  };

  const handleChangeSelect = (key, e) => {
    setSortingAndFiltering((prevState) => ({
      ...prevState,
      selectedFilter: {
        ...prevState.selectedFilter,
        [key]: e,
      },
    }));
  };

  const handleAddLayoff = async () => {
    setShowModal(false);
    fetchEmployments();
  };

  const handleRowClick = (row) => {
    setEditData(row);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEditData(null);
    setShowModal(false);
  };

  const handleSelectedDateFilter = (filter, start, end) => {
    setSortingAndFiltering((prevState) => ({
      ...prevState,
      selectedFilter: {
        ...prevState.selectedFilter,
        date: filter,
      },
      dateFilter: {
        start,
        end,
      },
    }));
  };

  const exportToExcel = () => {
    let filters = '';
    if (sortingAndFiltering?.selectedFilter?.query && query) {
      filters += `&${sortingAndFiltering?.selectedFilter.query.id}=${query}`;
    }
    if (sortingAndFiltering?.selectedFilter?.reason && sortingAndFiltering?.selectedFilter?.reason?.id !== 'all') {
      filters += `&termination_reason=${sortingAndFiltering?.selectedFilter?.reason?.id}`;
    }
    if (sortingAndFiltering?.dateFilter?.start && sortingAndFiltering?.selectedFilter?.date) {
      filters += `&${sortingAndFiltering?.selectedFilter.date}_after=${moment(sortingAndFiltering?.dateFilter.start).format('YYYY-MM-DD')}`;
    }
    if (sortingAndFiltering?.dateFilter?.start && sortingAndFiltering?.dateFilter?.end && sortingAndFiltering?.selectedFilter?.date) {
      filters += `&${sortingAndFiltering?.selectedFilter.date}_before=${moment(sortingAndFiltering?.dateFilter.end).format('YYYY-MM-DD')}`;
    }

    const asc = sortingAndFiltering?.selectedAscDesc ? sortingAndFiltering?.selectedAscDesc === 'desc' ? '-' : '' : '-';
    filters += `&order_by=${asc}${sortingAndFiltering?.selectedSort || 'updated_at'}`;

    api
      .get(`/api/v1/workforce/employments/?company=${companyId}${filters}&format=xlsx&limit=${tableData?.count || 99999}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'Employments.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const tableColumns = [
    {
      Header: () => (<span>{t('page_content.human_resources.employments.table_column_first_name')}</span>),
      accessor: 'worker',
      Cell: (row) => (row?.value?.name ? row.value.name : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => (<span>{t('page_content.human_resources.employments.table_column_last_name')}</span>),
      accessor: 'worker.last_name',
      Cell: (row) => (row?.value ? row.value : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => (<span>{t('page_content.human_resources.employments.table_column_working_start')}</span>),
      accessor: 'start_working',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => (<span>{t('page_content.human_resources.employments.table_column_working_end')}</span>),
      accessor: 'end_working',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => (<span>{t('page_content.human_resources.employments.table_column_reason')}</span>),
      accessor: 'termination_reason',
      Cell: (row) => (row?.value?.name ? row.value.name : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => (<span>{t('page_content.human_resources.employments.table_column_comment')}</span>),
      accessor: 'note',
      Cell: (row) => (row?.value ? row.value : '-'),
      style: { cursor: 'default' },
    },
  ];

  return (
    <div className="layoffs_container">
      <div className="filters_container">
        <div className="filters">
          <div className="input_container_search">
            <div className="input_field_style">
              <input
                onChange={(e) => setQuery(e.target.value)}
                placeholder={t('page_content.orders.search_for_placeholder')}
                value={query || ''}
              />
              {query && <button onClick={() => setQuery('')}>&times;</button>}
            </div>
            <Select
              className="select-style"
              options={filterOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={t(
                'page_content.human_resources.employments.filter_by',
              )}
              onChange={(e) => handleChangeSelect('query', e)}
              value={sortingAndFiltering?.selectedFilter?.query || ''}
              styles={selectStyles}
            />
            <div className="icon_container">
              <IconSearch color="#555" height="26px" width="26px" />
            </div>
          </div>
        </div>
        <div className="filters">
          <Select
            className="select-style"
            options={reasons}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t(
              'page_content.human_resources.employments.filter_by_reason',
            )}
            onChange={(e) => handleChangeSelect('reason', e)}
            value={sortingAndFiltering?.selectedFilter?.reason || ''}
            styles={selectStyles}
          />
          <DateFilters
            selectedFilterProp={handleSelectedDateFilter}
            filterOptions={[
              { label: t('page_content.human_resources.employments.table_column_working_end'), id: 'end_working', showTime: false },
              { label: t('page_content.human_resources.employments.table_column_working_start'), id: 'start_working', showTime: false },
            ]}
            clearFilters={clearDateFilters}
          />
          <div className="clear_button">
            <Button type="plain" onClick={clearAllFilters}>{t('page_content.orders.clear_all_button')}</Button>
          </div>
          <div className="new_layoff_button">
            <Button disabled={!userIsHR} type="add" onClick={() => setShowModal(true)}>{t('page_content.human_resources.employments.button_new_layoff')}</Button>
            <Button disabled={!userIsHR} type="export" onClick={() => exportToExcel()}>{t('page_content.human_resources.rating.individual_goals_tab.button_export_to_excel')}</Button>
          </div>
        </div>
      </div>
      <Table
        style={{ userSelect: 'text' }}
        columns={tableColumns}
        data={tableData.employments}
        loading={tableData.isLoading}
        noDataText=" "
        minRows={0}
        defaultPageSize={30}
        enableEdit
        onEdit={(original) => handleRowClick(original)}
        isActionsDisabled={!userIsHR}
        defaultSorted={[{ id: 'updated_at', desc: true }]}
        onSortedChange={(newSorted) => {
          handleSorting(newSorted[0]);
        }}
      />
      <div style={{ float: 'right' }}>
        <TableButtons
          next={tableData.next}
          previous={tableData.previous}
          fetchFunction={fetchPaginatedLayoffs}
          count={tableData.count}
        />
      </div>
      {
        showModal && (
          <AddEmploymentModal
            workers={workers}
            reasons={reasons}
            companyId={companyId}
            showModal={showModal}
            initialData={editData}
            fetchReasons={fetchReasons}
            handleClose={handleCloseModal}
            handleAddLayoff={handleAddLayoff}
          />)
      }
    </div>
  );
};

Employments.propTypes = {
  t: PropTypes.func.isRequired,
  userIsHR: PropTypes.bool.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

export default withRouter(withTranslation()(Employments));
