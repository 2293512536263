import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Timeline, { TimelineMarkers, CursorMarker, CustomMarker, TimelineHeaders, DateHeader } from 'react-calendar-timeline';

import { defaultDateFormat } from 'shared/constants';
import { ContentLoader, Button } from 'shared';
import { defaultHeaderFormats } from 'shared/helpers';

import { processWorkerSchedules } from './helpers';
import { getWorkerTimeSchedule } from '../../actions';
import './styles.scss';
import WorkerExceptionModal from './components/WorkerExceptionModal';

let visibleStart = moment().startOf('isoWeek').startOf('day');
let visibleEnd = moment().endOf('isoWeek').endOf('day');

const WorkerSchedulesTab = ({ t, i18n, companyId, workerId, workerDetails, isReadOnly }) => {
  const timelineRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [workerSchedules, setWorkerSchedules] = useState([]);
  const [timelineView, setTimelineView] = useState('week');
  const [exceptionModal, setExceptionModal] = useState({ isOpen: false });

  const groups = [
    { id: 1, title: `${workerDetails?.name} ${workerDetails?.last_name}` },
  ];

  const dayTypes = [
    { id: 'working', label: t('page_content.human_resources.workers.working') },
    { id: 'vacation', label: t('page_content.human_resources.workers.vacation') },
    { id: 'holiday', label: t('page_content.human_resources.workers.holiday') },
    { id: 'sick', label: t('page_content.human_resources.workers.sick') },
    { id: 'long-sick', label: t('page_content.human_resources.workers.long-sick') },
  ];

  const fetchWorkerSchedules = () => {
    let apiFilters = `&worker=${workerId}`;

    if (timelineView === 'month') {
      apiFilters += `&from_date=${moment(visibleStart).format('YYYY-MM-DD')}&to_date=${moment(visibleEnd).format('YYYY-MM-DD')}`;
    }

    if (timelineView === 'week') {
      apiFilters += `&from_date=${moment(visibleStart).format('YYYY-MM-DD')}&to_date=${moment(visibleEnd).format('YYYY-MM-DD')}`;
    }

    getWorkerTimeSchedule(companyId, apiFilters)
      .then((res) => {
        setWorkerSchedules(processWorkerSchedules(res?.data || []));
        setIsLoading(false);
        setShowOverlay(false);
      })
      .catch(() => {
        setWorkerSchedules(null);
        setIsLoading(false);
        setShowOverlay(false);
      });
  };

  useEffect(() => {
    fetchWorkerSchedules();
  }, [timelineView]);

  const getStyleByDayType = (day_type) => {
    const styles = {
      working: {
        background: 'rgba(0, 128, 0, 0.4)',
        color: 'darkgreen',
        border: '1px solid darkgreen',
        overflow: 'hidden',
      },
      vacation: {
        background: 'rgba(128, 128, 128, 0.4)',
        color: 'grey',
        border: '1px solid grey',
        overflow: 'hidden',
      },
      holiday: {
        background: 'rgba(128, 0, 128, 0.4)',
        color: 'purple',
        border: '1px solid purple',
        overflow: 'hidden',
      },
      sick: {
        background: 'rgba(255, 140, 0, 0.4)',
        color: 'darkorange',
        border: '1px solid darkorange',
        overflow: 'hidden',
      },
      'long-sick': {
        background: 'rgba(255, 0, 0, 0.4)',
        color: 'darkred',
        border: '1px solid darkred',
        overflow: 'hidden',
      },
    };

    return styles[day_type] || {};
  };

  const itemRenderer = ({ item, itemContext, getItemProps }) => {
    let styles = {};

    if (!itemContext.selected) {
      styles = getStyleByDayType(item?.day_type);
    }

    return (
      <Tooltip
        id={(item.id).toString()}
        trigger={['hover']}
        placement="top"
        overlay={
          !itemContext.dragging ?
            <div className="reacttooltip">
              <span>{item?.day_type ? `${item?.date ? moment(item.date).format(defaultDateFormat) : ''} ${t(`page_content.human_resources.workers.${item.day_type}`)}` : ''}</span><br />
              {
                item?.day_type === 'working' ?
                <>
                <span>
                    <label>{t('page_content.human_resources.workers.shift')}: </label>
                    {item?.start_time ? moment(item.start_time).format('HH:mm') : ''} - {item?.end_time ? moment(item.end_time).format('HH:mm') : ''}
                </span>
                <br />
                <span>
                    <label>{t('page_content.human_resources.workers.break')}: </label>
                    {item?.beginBreak ? moment(item.beginBreak).format('HH:mm') : ''} - {item?.endBreak ? moment(item.endBreak).format('HH:mm') : ''}
                </span>
                </>
                  : null
              }
            </div> : null
        }
      >
        <div
          {...getItemProps({
            style: {
              ...styles,
              zIndex: '999 !important',
            },
          })}
        >

          <div
            style={{
              height: itemContext.dimensions.height,
              overflow: 'hidden',
              paddingLeft: 3,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              position: 'relative',
            }}
          >
            {t(`page_content.human_resources.workers.${item?.day_type}`)}
          </div>
        </div>
      </Tooltip>
    );
  };

  const onTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
    const min = Math.max(visibleTimeStart, visibleStart.valueOf());
    const max = Math.min(visibleTimeEnd, visibleEnd.valueOf());

    updateScrollCanvas(min, max);
  };

  const handleTimelineViewChange = (view) => {
    setShowOverlay(true);

    if (view === 'month') {
      visibleStart = moment().startOf('month').startOf('day');
      visibleEnd = moment().endOf('month').endOf('day');
    }

    if (view === 'week') {
      visibleStart = moment().startOf('isoWeek').startOf('day');
      visibleEnd = moment().endOf('isoWeek').endOf('day');
    }

    timelineRef.current.updateScrollCanvas(visibleStart.valueOf(), visibleEnd.valueOf());
    setTimelineView(view);
  };

  const labelFormater = (
    [timeStart],
    unit,
    labelWidth,
    formatOptions = defaultHeaderFormats,
  ) => {
    moment.locale(i18n.language);
    let format;
    if (labelWidth >= 150) {
      format = formatOptions[unit].long;
    } else if (labelWidth >= 100) {
      format = formatOptions[unit].mediumLong;
    } else if (labelWidth >= 50) {
      format = formatOptions[unit].medium;
    } else {
      format = formatOptions[unit].short;
    }

    return timeStart.format(format);
  };

  const handleNavigationButton = (direction) => {
    setShowOverlay(true);
    if (direction === 'previous') {
      if (timelineView === 'month') {
        setTimelineView('month');
        visibleStart = moment(visibleStart).subtract(1, 'month');
        visibleEnd = moment(visibleEnd).subtract(1, 'month');
      }

      if (timelineView === 'week') {
        setTimelineView('week');
        visibleStart = moment(visibleStart).subtract(1, 'week');
        visibleEnd = moment(visibleEnd).subtract(1, 'week');
      }
    }

    if (direction === 'next') {
      if (timelineView === 'month') {
        setTimelineView('month');
        visibleStart = moment(visibleStart).add(1, 'month');
        visibleEnd = moment(visibleEnd).add(1, 'month');
      }

      if (timelineView === 'week') {
        setTimelineView('week');
        visibleStart = moment(visibleStart).add(1, 'week');
        visibleEnd = moment(visibleEnd).add(1, 'week');
      }
    }

    fetchWorkerSchedules();
    timelineRef.current.updateScrollCanvas(visibleStart.valueOf(), visibleEnd.valueOf());
  };

  const handleAddException = () => {
    setExceptionModal({ isOpen: true, data: null });
  };

  const handleCloseExceptionModal = () => {
    setExceptionModal({ isOpen: false, data: null });
  };

  const closeExceptionModalAndRefetch = () => {
    handleCloseExceptionModal();
    fetchWorkerSchedules();
  };

  if (isLoading) {
    return <ContentLoader />;
  }

  return (
    <div className="worker-schedules">
      <div className="worker-schedules__info">
        <div className="worker-schedules__legend">
          {dayTypes.map((type) => (
            <div key={type.id} className="worker-schedules__legend-item">
              <div className={`worker-schedules__legend-color__type-${type.id}`} />
              <span className="worker-schedules__legend-text">{type.label}</span>
            </div>
          ))}
        </div>
        <div className="worker-schedules__controls">
          <div className="worker-schedules__view-buttons">
            <Button
              onClick={() => handleTimelineViewChange('month')}
              type={timelineView === 'month' ? 'dark-blue' : 'plain'}
              className="worker-schedules__view-button"
            >{t('page_content.human_resources.workers.month_view')}</Button>

            <Button
              onClick={() => handleTimelineViewChange('week')}
              type={timelineView === 'week' ? 'dark-blue' : 'plain'}
              className="worker-schedules__view-button"
            >{t('page_content.human_resources.workers.week_view')}</Button>
          </div>
          <div className="worker-schedules__navigation-buttons">
            <Button
              onClick={() => handleNavigationButton('previous')}
              className="worker-schedules__navigation-button"
            >{t('page_content.human_resources.workers.previous')}</Button>
            <Button
              onClick={() => handleNavigationButton('next')}
              className="worker-schedules__navigation-button"
            >{t('page_content.human_resources.workers.next')}</Button>
          </div>
        </div>
      </div>
      <div className="worker-schedules__actions">
        <Button type="add" disabled={isReadOnly} onClick={handleAddException}>{t('page_content.human_resources.workers.add_exception')}</Button>
      </div>
      <div className="worker-schedules__timeline">
        {showOverlay && <div id="overlay"><ContentLoader /></div>}
        <Timeline
          ref={timelineRef}
          groups={groups}
          items={workerSchedules || []}
          itemRenderer={itemRenderer}
          defaultTimeStart={visibleStart}
          defaultTimeEnd={visibleEnd}
          onTimeChange={onTimeChange}
          stackItems
          fullUpdate={false}
          lineHeight={55}
          itemHeightRatio={0.75}
          canResize={false}
        >
          <TimelineMarkers>
            <CustomMarker date={moment().valueOf()}>
              {({ styles }) => <div style={{ ...styles, backgroundColor: 'red', width: '2px' }} />}
            </CustomMarker>
            <CursorMarker>
              {({ styles }) => <div style={{ ...styles, backgroundColor: 'gray' }} />}
            </CursorMarker>
          </TimelineMarkers>
          <TimelineHeaders className="sticky">
            <DateHeader unit="primaryHeader" />
            <DateHeader labelFormat={labelFormater} />
          </TimelineHeaders>
        </Timeline>
      </div>

      {
        exceptionModal?.isOpen &&
        <WorkerExceptionModal
          dayTypes={dayTypes}
          companyId={companyId}
          isOpen={exceptionModal?.isOpen}
          workerDetails={workerDetails}
          initialValues={exceptionModal?.data}
          handleCloseModal={handleCloseExceptionModal}
          closeModalAndRefetch={closeExceptionModalAndRefetch}
        />
      }
    </div>
  );
};

WorkerSchedulesTab.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  workerId: PropTypes.number.isRequired,
  workerDetails: PropTypes.object.isRequired,
  i18n: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default withTranslation()(WorkerSchedulesTab);
