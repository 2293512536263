/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class ValueToDisplayPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      options: props.options || [
        { label: 'OEE' },
        { label: 'Quality' },
        { label: 'Performance' },
        { label: 'Availability' },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      // eslint-disable-next-line
      this.setState({
        value: this.props.value,
      });
    }
  }

  handleChange = (val) => {
    const { changeValue } = this.props;
    this.setState({ value: val });
    changeValue(val);
  }

  render() {
    const {
      t,
    } = this.props;
    const {
      value,
      options,
    } = this.state;

    return (
      <Select
        getOptionLabel={(option) => `${option.label}`}
        getOptionValue={(option) => option.label}
        options={options}
        defaultValue={{ label: 'OEE' }}
        onChange={this.handleChange}
        placeholder={t('shared.metric_picker.placeholder')}
        value={value}
        cacheOptions
        className="AscaliaSelect ValueToDisplayPicker"
      />
    );
  }
}

ValueToDisplayPicker.propTypes = {
  changeValue: PropTypes.func,
  value: PropTypes.any.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ValueToDisplayPicker);
