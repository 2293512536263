/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Button,
  MultiClassPicker,
} from 'shared';
import selectedIcon from 'shared/Icons/selected.png';
import chevronRight from 'shared/Icons/chevron-right.png';
import chevronLeft from 'shared/Icons/chevron-left.png';
import notMarked from 'shared/Icons/not-marked-color.jpg';

class UploadStepTwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      currentFile: null,
      currentImage: null,
      currentIndex: 0,
      selectedProgram: null,
      selectedColors: [],
      selectedClasses: [],
    };
  }

  componentDidMount() {
    const { files } = this.props;
    let i = 0;
    const limit = files.length - 1;

    const getBase64 = () => {
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = () => {
        const { images } = this.state;
        const { setImage } = this.props;
        setImage(reader.result);
        images.push(reader.result);
        this.setState({
          images,
        }, () => {
          i += 1;
          if (i <= limit) {
            getBase64();
          }
        });
      };
      reader.onerror = (error) => {
        console.log('Error: ', error);
      };
    };

    getBase64();
  }

  onProgramChange = (e) => {
    this.setState({
      selectedProgram: e.target.value,
    });
  }

  getHexByColor = (color) => {
    switch (color) {
      case 'RED':
        return '#EE5249';
      case 'BLUE':
        return '#4285F4';
      case 'GREEN':
        return '#16A765';
      case 'YELLOW':
        return '#FFCC44';
      case 'BLACK':
        return '#000';
      default:
        return 'transparent';
    }
  }

  setCurrentImageAndFile = (isPrevious = false) => {
    const {
      currentIndex,
      images,
    } = this.state;

    const {
      files,
    } = this.props;

    let index = currentIndex;
    if (isPrevious && currentIndex === 0) {
      index = images.length - 1;
    } else if (isPrevious) {
      index -= 1;
    } else if (index === images.length - 1) {
      index = 0;
    } else {
      index += 1;
    }

    this.setState({
      currentImage: images[index],
      currentFile: files[index],
      currentIndex: index,
    });
  }

  addOrRemoveColor = (color) => {
    const { selectedColors } = this.state;

    const index = selectedColors.indexOf(color);
    if (index > -1) {
      selectedColors.splice(index, 1);
    } else {
      selectedColors.push(color);
    }

    this.setState({
      selectedColors,
    });
  }

  upload = () => {
    const {
      uploadData,
    } = this.props;

    const { selectedColors, selectedProgram, selectedClasses } = this.state;

    if (!selectedProgram) {
      alert('Please select a program.');
      return;
    }

    /*
    if (!selectedClasses || !selectedClasses.length) {
      alert('Please select a class.');
      return;
    }
    */

    if (!selectedColors || !selectedColors.length) {
      alert('Please select colors.');
      return;
      // selectedColors.push('NOT_MARKED');
    }

    uploadData(selectedColors, selectedProgram, selectedClasses);
  }

  handleClassChange = (classValue) => {
    this.setState({ selectedClasses: classValue });
  }

  render() {
    const {
      workProcess,
      files,
      resetUpload,
      t,
    } = this.props;

    const {
      images,
      selectedProgram,
      selectedColors,
      currentFile,
      currentImage,
      currentIndex,
      selectedClasses,
    } = this.state;

    const formattedPrograms = [];
    const programs = workProcess.choices.programs;

    let notMarkedComponent = '';
    const hasNotMarked = workProcess.choices.colors.find((color) => color === 'NOT_MARKED');
    if (hasNotMarked) {
      if (selectedColors.includes('NOT_MARKED')) {
        notMarkedComponent = (
          <div onClick={() => { this.addOrRemoveColor('NOT_MARKED'); }} className="work-process__color" style={{ position: 'relative' }}>
            <img src={notMarked} style={{ width: '100%', height: '100%' }} alt="" />
            <img src={selectedIcon} alt="OK" style={{ position: 'absolute', top: '20px', right: '-25px' }} />
          </div>);
      } else {
        notMarkedComponent = (
          <div onClick={() => { this.addOrRemoveColor('NOT_MARKED'); }} className="work-process__color" style={{ position: 'relative' }}>
            <img src={notMarked} style={{ width: '100%', height: '100%' }} alt="" />
          </div>
        );
      }
    }

    Object.keys(programs).forEach((key) => {
      formattedPrograms.push({
        name: key,
        ...programs[key],
      });
    });

    return (
      <div className="work-process__wrapper">
        <h2>
          {t('page_content.upload.step_two.details')}
          <div style={{ fontSize: '14px', fontWeight: '400', color: '#383838', marginTop: '10px' }}>
            {currentIndex + 1}/{images.length} {t('page_content.upload.step_two.uploaded_photos')}.
          </div>
        </h2>
        <div className="work-process">
          <div className="work-process__form">
            <div className="col form-col">
              <div>
                {t('page_content.upload.step_two.name')}
              </div>
              <input type="text" value={currentFile ? currentFile.name : files[0].name} readOnly />
              <div>
                {t('page_content.upload.step_two.programs')}
              </div>
              <select onChange={this.onProgramChange}>
                <option value="" disabled selected>
                  {t('page_content.upload.step_two.choose_an_option_placeholder')}
                </option>
                {
                  formattedPrograms.map((program) => <option value={program.name} selected={selectedProgram === program.name}>{program.name} ({program.width} mm x {program.height} mm)</option>)
                }
              </select>
              <div>
                {t('page_content.upload.step_two.class')}
              </div>
              <div className="class-multipicker">
                <MultiClassPicker
                  classValue={selectedClasses || null}
                  handleChange={(val) => this.handleClassChange(val)}
                  htmlId="type"
                  selectedProgram={selectedProgram}
                  isMultiSelect
                  disabled={!selectedProgram}
                />
              </div>
              <div>
                {t('page_content.upload.step_two.color')}
              </div>
              <div className="work-process__colors">
                {
                  workProcess.choices.colors.filter((color) => color !== 'NOT_MARKED' && color !== 'WHITE').map((color) => {
                    const classes = 'work-process__color';
                    if (selectedColors.includes(color)) {
                      return (
                        <div onClick={() => { this.addOrRemoveColor(color); }} className={classes} style={{ background: this.getHexByColor(color), position: 'relative' }}>
                          <img src={selectedIcon} alt="OK" style={{ position: 'absolute', top: '20px', right: '-25px' }} />
                        </div>);
                    }
                    return <div onClick={() => { this.addOrRemoveColor(color); }} className={classes} style={{ background: this.getHexByColor(color) }} />;
                  })
                }
                {notMarkedComponent}
              </div>
              <div>
                <Button
                  onClick={resetUpload}
                  style={{ height: '50px', width: '170px' }}
                >
                  {t('page_content.upload.step_two.back_button')}
                </Button>
                <Button
                  onClick={this.upload}
                  style={{ background: '#364252', color: 'white', height: '50px', width: '170px', float: 'right' }}
                >
                  {t('page_content.upload.step_two.done_button')}
                </Button>
              </div>
            </div>
            <div className="col">
              <div>
                {t('page_content.upload.step_two.photo')}
              </div>
              <div className="work-process__image-preview">
                {
                  images && images.length && images.length > 1 ?
                    <React.Fragment>
                      <span
                        className="work-process__image-preview-left"
                        onClick={() => { this.setCurrentImageAndFile(true); }}
                      >
                        <img src={chevronLeft} alt="<" />
                      </span>
                      <span
                        className="work-process__image-preview-right"
                        onClick={() => { this.setCurrentImageAndFile(); }}
                      >
                        <img src={chevronRight} alt=">" />
                      </span>
                    </React.Fragment> : ''
                }
                <img src={currentImage || images[0]} alt="Preview" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UploadStepTwo.propTypes = {
  files: PropTypes.array.isRequired,
  workProcess: PropTypes.object.isRequired,
  resetUpload: PropTypes.func.isRequired,
  uploadData: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(UploadStepTwo);
