import React from 'react';
import PropTypes from 'prop-types';

import IndividualGoalsTable from './IndividualGoalsTable';
import IndividualScoresTable from './IndividualScoresTable';

const IndividualGoals = ({ companyId, locationId, userHaveFullAccess }) => {
  return (
    <div className="individual_goals_tab">
      <IndividualGoalsTable companyId={companyId} locationId={locationId} />
      {userHaveFullAccess ? <IndividualScoresTable companyId={companyId} /> : null}
    </div>
  );
};

IndividualGoals.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  userHaveFullAccess: PropTypes.bool.isRequired,
};

export default IndividualGoals;
