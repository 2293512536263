import api from 'helpers/api';
import { Notification } from 'shared';
import i18n from 'i18next';

function handleProductTypeError(error) {
  if (error && error.response && error.response.data && error.response.data.message && error.response.data.message.includes('duplicate key value violates unique constraint')) {
    return i18n.t('settings.product_types.duplicate_error_message');
  }
  const parsedErrors = {};
  const errorText = error.response.request.responseText;
  const cleanedText = errorText.replace(/\\/g, '').replace(/"/g, '');
  const errorArray = cleanedText.split(',');
  errorArray.forEach((er) => {
    const [key, value] = er.split(':');
    parsedErrors[key.trim()] = value.trim();
  });

  let formattedErrors = '';
  for (const key in parsedErrors) {
    // eslint-disable-next-line no-prototype-builtins
    if (parsedErrors.hasOwnProperty(key)) {
      formattedErrors += `${key}: ${parsedErrors[key]}\n`;
    }
  }
  return formattedErrors;
}

export const getProductTypes = async (companyId, filters) => {
  const urlString = '/api/v1/product_types/';
  let urlParams = '?limit=30';
  if (companyId) {
    urlParams += `&company=${companyId}`;
  }
  let targetUrl = urlString + urlParams;
  if (filters) {
    targetUrl += `${filters}`;
  }
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching product types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedProductTypes = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching product types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const saveProductType = (data) => {
  return api.post('/api/v1/product_types/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return { response: res, notification: Notification('success', 'Save successful', 'Product type was successfully created.') };
    })
    .catch((error) => {
      return { response: error, notification: Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? handleProductTypeError(error) : 'We could not perform your request, please try again.') };
    });
};

export const editProductType = (data) => {
  return api.put(`/api/v1/product_types/${data.id}/?company=${data.company}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return { response: res, notification: Notification('success', 'Save successful', 'Product type was successfully updated.') };
    })
    .catch((error) => {
      return { response: error, notification: Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? handleProductTypeError(error) : 'We could not perform your request, please try again.') };
    });
};

export const deleteProductType = async (productTypeId, companyId) => {
  return api.delete(`/api/v1/product_types/${productTypeId}/?company=${companyId}`);
};

export const getProductDepartments = (locationId) => {
  return api.get(`/api/v1/departments/?location=${locationId}`)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
    });
};

export const getProductTypeUnits = async (companyId) => {
  const urlString = '/api/v1/product_type_units/';
  let urlParams = '';
  if (companyId) {
    urlParams += `?company=${companyId}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching product type units', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProductDataTemplate = (companyId) => {
  return api.get(`/api/v1/product_data_templates/?company=${companyId}`)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
    });
};

export const getWarehouses = async (locationId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/warehouses/?location=${locationId}${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching warehouses', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
