import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props} viewBox="0 0 192.287 192.287">
  <path
    d="M122.901,0H19.699v192.287h152.889v-142.6L122.901,0z M146.981,45.299h-19.686V25.612L146.981,45.299z M34.699,177.287V15
    h77.596v37.799c0,4.142,3.357,7.5,7.5,7.5h37.793v116.988H34.699z"
  />
  <rect x="53.141" y="149.004" width="86.006" height="10" />
  <rect x="53.141" y="55.101" width="51.058" height="10" />
  <polygon
    points="121.248,86.935 126.79,86.935 105.371,108.353 88.623,91.605 51.597,128.634 58.667,135.706 88.623,105.748
    105.371,122.495 133.861,94.005 133.861,99.535 143.861,99.535 143.861,76.935 121.248,76.935"
  />
  <rect x="53.141" y="33.283" width="51.058" height="10" />
</Base>);
