import api from 'helpers/api';
import { Notification } from 'shared';

export const getReportDetails = async (companyId, reportId) => {
  try {
    const res = await api
      .get(`/api/v1/fives/report_entries/?company=${companyId}&report_id=${reportId}&last=true`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching reports', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getReportEntryFindings = async (companyId, entryId) => {
  try {
    const res = await api
      .get(`/api/v1/fives/findings/?&company=${companyId}&entry=${entryId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching reports', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getReportScores = async (reportId, companyId) => {
  try {
    const res = await api
      .get(`/api/v1/fives/reports/${reportId}/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching reports', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
