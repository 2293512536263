import api from 'helpers/api';
import { Notification } from 'shared';

export const getAssetUtilizations = (companyId, locationId) => api.get(`/api/v1/industry/asset_utilizations/?company=${companyId}&location=${locationId}`);
export const runUtilizationByAsset = (assetId, date) => api.get(`/api/v1/run_utilization/?asset_id=${assetId}&date=${date}`);
export const runUtilizationByAssetUtilization = (utilizationId, lastDays) => api.get(`/api/v1/run_utilization/?utilization_id=${utilizationId}&last_days=${lastDays}`);

export const getAllEfficiencies = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching efficiencies', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAssets = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/assets/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching locations', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getAssetTypes = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/asset_types/?account_type=industry&company=${companyId}&order_by=name&limit=200`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching locations', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getShifts = async (locationId) => {
  try {
    const res = await api
      .get(`/api/v1/shifts/shifts/?location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching shifts', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
