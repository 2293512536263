import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props}>
  <g>
    <path
      d="M75.1,15.3C66.9,3.8,51.6-0.6,38.5,4.7c-16.9,6.9-24,26.5-16,42.5L47,95.7c1.2,2.5,4.7,2.5,6,0l24.6-48.5
      C82.4,37.5,82.1,25.2,75.1,15.3z M50,53.7c-11.2,0-20.3-9.1-20.3-20.3S38.8,13,50,13c11.3,0,20.4,9.1,20.4,20.4S61.3,53.7,50,53.7
      z"
    />
  </g>
</Base>);
