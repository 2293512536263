import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal } from 'shared';
import { modalSizes } from 'shared/constants';

class FinalBookingModal extends Component {
  render() {
    const {
      t,
      isFinalBookingModalOpen,
      handleBookTT,
    } = this.props;

    return (
      <Modal
        isOpen={isFinalBookingModalOpen}
        handleSave={() => handleBookTT(true)}
        handleClose={() => handleBookTT(false)}
        size={modalSizes.small}
        closeText={t('page_content.orders.order_details.bookings_tab.final_booking_partial')}
        saveText={t('page_content.orders.order_details.bookings_tab.final_booking_final')}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <h4>{t('page_content.orders.order_details.bookings_tab.final_booking')}</h4>
        </div>
      </Modal>
    );
  }
}

FinalBookingModal.propTypes = {
  t: PropTypes.func.isRequired,
  isFinalBookingModalOpen: PropTypes.bool,
  handleBookTT: PropTypes.func.isRequired,
};

export default withTranslation()(FinalBookingModal);
