import React, { Component } from 'react';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import { get } from 'lodash';
import Select from 'react-select';
import { multiSelectStyles } from 'styles/modules/reactSelect';
import { checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import { Table, Button, Notification, TableButtons, ValuesMapper, ConfirmationModal } from 'shared';
import { IconRemove } from 'shared/Icons';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { getReasonGroups, saveReasonGroup, editReasonGroup, getPaginatedReasonGroups } from '../actions';
import ReasonGroupModal from './ReasonGroupModal';

class ReasonGroups extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    this.state = {
      isLoadingModal: false,
      isLoading: true,
      reason_groups: [],
      isReasonGroupModalOpen: false,
      selectedReasonGroup: null,
      previous: null,
      next: null,
      count: null,
      assetOptions: [],
      selectedAssetOptions: [],
      selectedAscDesc: 'asc',
      selectedSort: 'name',
      showConfirmationDialog: false,
      deleteData: null,
    };
  }

  componentDidMount() {
    const { locationId, companyId } = this.props;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          this.setState({
            isReadOnly: true,
          });
        }
      });

    api.get(`/api/v1/industry/assets/?location=${locationId}&order_by=name&limit=9999&company=${companyId}`)
      .then((json) => {
        this.setState({
          assetOptions: get(json, 'data.results', []),
        });
      });

    this.fetchReasonGroups();
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchReasonGroups();
    });
  }

  fetchReasonGroups = () => {
    const { companyId, locationId } = this.props;
    const { selectedAssetOptions, selectedAscDesc, selectedSort } = this.state;

    this.setState({
      isLoading: true,
    });

    let filters = '&limit=30&get_assets=true';
    if (selectedAssetOptions && selectedAssetOptions.length > 0) {
      filters += `&asset__in=${selectedAssetOptions}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort}`;

    getReasonGroups(companyId, filters, locationId)
      .then((re) => {
        this.setState({
          reason_groups: get(re, 'data.results', []),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoading: false,
          count: get(re, 'data.count'),
        });
      });
  }

  fetchPaginatedReasonGroups = (url) => {
    this.setState({
      isLoading: true,
    });
    getPaginatedReasonGroups(url)
      .then((re) => {
        this.setState({
          reason_groups: get(re, 'data.results', []),
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoading: false,
          count: get(re, 'data.count'),
        });
      });
  }

  handleOpenModal = (group) => {
    this.setState({
      isReasonGroupModalOpen: true,
      selectedReasonGroup: group || null,
    });
  }

  handleCloseModal = () => {
    this.setState({
      isReasonGroupModalOpen: false,
      selectedReasonGroup: null,
      isLoadingModal: false,
    });
  }

  handleShowConfirmationModal = (group, e) => {
    e.stopPropagation();
    this.setState({ showConfirmationDialog: true, deleteData: group });
  }

  handleDeleteReasonGroup = () => {
    const { companyId } = this.props;
    this.setState({
      isLoading: true,
    });

    const id = this.state.deleteData.original.id;
    return api
      .delete(`/api/v1/maintenance/reason_groups/${id}/?company=${companyId}`)
      .then(() => {
        this.fetchReasonGroups();
        Notification('success', 'Delete successful');
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        Notification('error', 'An error occurred', error?.response?.data?.message ? error.response.data.message : 'We could not perform your request, please try again.');
      })
      .finally(() => {
        this.setState({ showConfirmationDialog: false });
      });
  }

  handleSaveOrEditReasonGroup = (data, id = null) => {
    const { companyId } = this.props;

    this.setState({
      isLoadingModal: true,
    });

    if (id) {
      editReasonGroup(id, companyId, data)
        .then(() => {
          this.handleCloseModal();
          this.fetchReasonGroups();
        });
    } else {
      saveReasonGroup(data)
        .then(() => {
          this.handleCloseModal();
          this.fetchReasonGroups();
        });
    }
  }

  handleQueryOrFilterChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleStateQueryChange = () => {
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      this.fetchReasonGroups();
    }, 600);
  }

  render() {
    const {
      reason_groups,
      isLoading,
      next,
      previous,
      isReasonGroupModalOpen,
      selectedReasonGroup,
      isLoadingModal,
      count,
      assetOptions,
      selectedAssetOptions,
      isReadOnly,
    } = this.state;
    const { t, companyId, locationId } = this.props;

    return (
      <div className="reason_group_container">
        <div className="reason_filter_row">
          <Select
            options={assetOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            isMulti
            placeholder={t('page_content.production_records.asset_placeholder')}
            onChange={(val) => {
              const assetId = val ? val.map((obj) => obj.id) : [];
              this.handleQueryOrFilterChange('selectedAssetOptions', assetId);
            }}
            styles={multiSelectStyles}
            value={assetOptions.filter((option) => selectedAssetOptions.includes(option.id))}
          />
          <div className="reason_add_new_button">
            <Button type="add" disabled={isReadOnly} onClick={this.handleOpenModal}>{t('page_content.maintenance.reason_groups.add_new_group')}</Button>
          </div>
        </div>

        <div>
          <Table
            columns={[
              {
                Header: () => <span>{t('page_content.maintenance.reason_groups.name')}</span>,
                accessor: 'name',
                Cell: (row) => (row.value || '-'),
              },
              {
                Header: () => <span>{t('page_content.maintenance.reasons.assets')}</span>,
                accessor: 'assets',
                Cell: (row) => (<ValuesMapper data={row.value} accessor="name" sliceNumber={3} />),
                sortable: false,
              },
              {
                Header: () => <span>{t('page_content.maintenance.reason_groups.maintenance_only')}</span>,
                accessor: 'maintenance_only',
                width: 150,
                Cell: (row) => (<div style={{ margin: 'auto' }}>{
                  row?.value ?
                    <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />
                    }</div>),
              },
              {
                Header: () => <span>{t('page_content.maintenance.reason_groups.delete')}</span>,
                Cell: (group) => <div style={{ margin: 'auto' }}><Button type="delete" disabled={isReadOnly} onClick={(e) => this.handleShowConfirmationModal(group, e)}><IconRemove width="12px" height="12px" /></Button></div>,
                sortable: false,
                width: 110,
              },
            ]}
            data={reason_groups}
            defaultPageSize={30}
            minRows={0}
            noDataText=""
            showPagination={false}
            loading={isLoading}
            handleClick={(rowInfo) => isReadOnly || this.handleOpenModal(get(rowInfo, 'original'))}
            defaultSorted={[{ id: 'name', desc: false }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchPaginatedReasonGroups} count={count} />
        </div>

        <ConfirmationModal
          itemName={this.state.deleteData?.original?.name}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDeleteReasonGroup}
          type="warning"
        />

        {
          isReasonGroupModalOpen &&
          <ReasonGroupModal
            t={t}
            isLoadingModal={isLoadingModal}
            companyId={companyId}
            locationId={locationId}
            handleCloseModal={this.handleCloseModal}
            isReasonGroupModalOpen={isReasonGroupModalOpen}
            selectedReasonGroup={selectedReasonGroup}
            handleSaveOrEditReasonGroup={this.handleSaveOrEditReasonGroup}
          />
        }
      </div>
    );
  }
}

ReasonGroups.propTypes = {
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
};

export default ReasonGroups;
