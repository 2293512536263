import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const PageTitle = ({ children, title }) => {
  return (
    <div className="PageTitle">
      <h1>{title}</h1>
      {
        children &&
        <div className="PageTitle-extras">
          {children}
        </div>
      }
    </div>
  );
};

PageTitle.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
};

export default PageTitle;
