import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props}>
  <path
    d="M18.4,96c0,1.5,1.2,2.7,2.7,2.7h59.7c1.5,0,2.7-1.2,2.7-2.7V25.4c0-1.5-1.2-2.7-2.7-2.7H21.1c-1.5,0-2.7,1.2-2.7,2.7V96z
M89,9.2v5.4c0,1.5-1.2,2.7-2.7,2.7H15.7c-1.5,0-2.7-1.2-2.7-2.7V9.2c0-1.5,1.2-2.7,2.7-2.7h15.6c0.3,0,0.9-0.2,1.1-0.5l3-3
C36.5,1.8,38.6,1,40.1,1h21.7c1.5,0,3.6,0.9,4.6,1.9l3,3c0.2,0.2,0.8,0.5,1.1,0.5h15.6C87.8,6.4,89,7.6,89,9.2z M34.7,34.9
c1.5,0,2.7,1.2,2.7,2.7v46.2c0,1.5-1.2,2.7-2.7,2.7c-1.5,0-2.7-1.2-2.7-2.7V37.6C32,36.1,33.2,34.9,34.7,34.9z M51,34.9
c1.5,0,2.7,1.2,2.7,2.7v46.2c0,1.5-1.2,2.7-2.7,2.7c-1.5,0-2.7-1.2-2.7-2.7V37.6C48.2,36.1,49.5,34.9,51,34.9z M67.2,34.9
c1.5,0,2.7,1.2,2.7,2.7v46.2c0,1.5-1.2,2.7-2.7,2.7c-1.5,0-2.7-1.2-2.7-2.7V37.6C64.5,36.1,65.7,34.9,67.2,34.9z"
  />
</Base>);
