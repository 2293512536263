import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { get, cloneDeep } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { selectModalStyles, selectStyles } from 'styles/modules/reactSelect';
import { IconRemove, IconDown, IconUp } from 'shared/Icons';
import { Button, ContentLoader, Modal } from 'shared';
import './styles.scss';

import {
  saveNewForm,
  getQuestions,
  getCategories,
  deleteQuestion,
  saveFormQuestions,
  editFormQuestions,
  getTemplateOptions,
  getQuestionOptions,
  editFormDataValues,
  deleteQuestionOptions,
  saveFormQuestionOptions,
  getTemplateOptionGroups,
  editFormQuestionOptions,
} from './actions';

import ChoiceQuestion from './QuestionTypes/ChoiceQuestion';
import SelectQuestion from './QuestionTypes/SelectQuestion';
import NumberQuestion from './QuestionTypes/NumberQuestion';

const AddNewFormModal = ({
  t,
  showModal,
  companyId,
  onBackClick,
  editFormData,
  handleFormAdded,
  reFetchCategories,
}) => {
  const [formQuestions, setFormQuestions] = useState([]);
  const [oldFormQuestion, setOldFormQuestion] = useState([]);
  const [formMainData, setFormMainData] = useState({
    name: '',
    description: '',
    category: '',
    file_upload: false,
    action_required: false,
  });
  const [newSelectValue, setNewSelectValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [categories, setCategories] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [formValid, setFormValid] = useState(true);
  const [templateOptionGroups, setTemplateOptionGroups] = useState({});

  const fetchInitialData = async (formId) => {
    const res = await getQuestions(formId);
    const questionsData = get(res, 'data.results', []);
    await Promise.all(
      questionsData.map(async (data, i) => {
        const resp = await getQuestionOptions(data.id);
        const respData = get(resp, 'data.results', []);
        if (respData?.length) {
          questionsData[i].answers = respData;
        }
        if (data.number_limit_from || data.number_limit_to) {
          questionsData[i].set_range = true;
        }
      }),
    );
    if (!oldFormQuestion.length) {
      const oldFormQuestionCopy = cloneDeep(questionsData);
      setOldFormQuestion(oldFormQuestionCopy);
    }
    setInitialData(questionsData);
    setIsLoading(false);
    setFormQuestions(questionsData);
  };

  const fetchCategories = async () => {
    const filters = '&order_by=name';
    const res = await getCategories(companyId, filters);
    setCategories(get(res, 'data.results', []));
  };

  useEffect(() => {
    if (reFetchCategories) {
      fetchCategories();
    }
  }, [reFetchCategories]);

  useEffect(() => {
    getTemplateOptionGroups(companyId)
      .then((res) => {
        setTemplateOptionGroups(get(res, 'data.results', []));
      })
      .catch((error) => console.error(error));

    if (editFormData?.id) {
      fetchInitialData(editFormData.id);
      setIsEdit(true);
      setIsLoading(true);
      setFormMainData({
        name: editFormData.name,
        description: editFormData.description,
        category: editFormData.category,
        file_upload: editFormData.file_upload,
        action_required: editFormData.action_required,
        is_published: editFormData.is_published,
      });
    } else {
      setIsEdit(false);
    }
    fetchCategories();
  }, [editFormData]);

  const handleAddNewInput = () => {
    const index = formQuestions.length;
    setFormQuestions((prevState) => [
      ...prevState,
      {
        order: index + 1,
        question: '',
        description: '',
        question_type: '',
        is_required: true,
      },
    ]);
  };

  const handleDeleteRow = (row, index) => {
    const newData = formQuestions.filter((val, i) => i !== index);
    setFormQuestions(newData);
  };

  const handleSelectType = (val, index) => {
    const newData = [...formQuestions];
    const previousType = newData[index].question_type;
    if (
      (previousType === 'choice' || previousType === 'select') &&
      isEdit &&
      newData[index]?.answers?.length &&
      (val.id === 'free_text' || val.id === 'number')
    ) {
      newData[index].show_warning = true;
    }
    if (isEdit && (val.id === 'choice' || val.id === 'select')) {
      newData[index].show_warning = false;
    }
    newData[index].question_type = val.id;
    if (val.id === 'choice') {
      if (!newData[index]?.answers?.length) {
        const defaultValue = newData[index]?.answers?.length + 1 || 1;
        newData[index].answers = [{ label: '', value: defaultValue }];
      } else if (previousType !== 'select') {
        const newAnswers = newData[index]?.answers.map((a, i) => {
          const data = a;
          data.value = i + 1;
          return data;
        });
        newData[index].answers = newAnswers;
      }
    }
    if (val.id === 'select') {
      if (!newData[index]?.answers?.length) {
        newData[index].answers = [];
      } else {
        const validAnswers = [];
        newData[index].answers.forEach((a, i) => {
          if (a?.label) {
            a.value = i;
            validAnswers.push(a);
          }
        });
        if (validAnswers?.length) {
          newData[index].answers = validAnswers;
        } else {
          newData[index].answers = [];
        }
      }
    }
    setFormQuestions(newData);
  };

  const clearValues = () => {
    setFormMainData({
      name: '',
      description: '',
      category: '',
      file_upload: false,
      action_required: false,
    });
    setFormQuestions([]);
  };

  const handleChangeOrder = (row, orderType) => {
    const index = row.order - 1;
    setFormQuestions((prevState) => {
      const formData = [...prevState];
      if (index >= 0 && formData.length) {
        const newIndex = orderType === 'up' ? index - 1 : index + 1;
        if (newIndex >= 0 && newIndex < formData.length) {
          [formData[index], formData[newIndex]] = [
            formData[newIndex],
            formData[index],
          ];

          formData.forEach((item, i) => {
            item.order = i + 1;
          });
        }
      }
      return formData;
    });
  };

  const validateForm = () => {
    let valid = true;
    if (!formMainData?.name) {
      valid = false;
      setFormValid(false);
      return;
    }
    formQuestions.map(async (question) => {
      if (!question.question || !question?.question_type) {
        valid = false;
        return;
      }
      if (
        (question?.question_type === 'select' ||
          question?.question_type === 'choice') &&
        !question?.answers?.length
      ) {
        valid = false;
        return;
      }
      question?.answers?.forEach((answer) => {
        if (
          !answer?.label ||
          answer?.value === undefined ||
          answer?.value === null
        ) {
          valid = false;
        }
      });
    });
    setFormValid(valid);
    return valid;
  };

  const handleSaveForm = async () => {
    if (!validateForm()) {
      return;
    }
    const formData = formMainData;

    if (!formData?.is_published) formData.publish_date = null;
    else if (formData?.is_published) formData.publish_date = moment().toISOString();

    formData.company = companyId;
    formData.category = formMainData?.category?.id;

    const resForm = isEdit
      ? await editFormDataValues(editFormData?.id, companyId, formData)
      : await saveNewForm(formData);
    let orderNumber = 0;
    if (resForm) {
      await Promise.all(
        initialData?.map(async (question) => {
          const questionFound = formQuestions.find((q) => q.id === question.id);
          if (!questionFound) {
            await deleteQuestion(question.id, question.survey);
          }
        }),
      );

      await Promise.all(
        formQuestions.map(async (question) => {
          orderNumber++;

          const data = {
            question: question?.question,
            description: question?.description,
            question_type: question?.question_type,
            survey: resForm?.data?.id,
            order: orderNumber,
            multi_select: question?.multi_select || false,
            number_limit_to: question?.set_range ? question?.number_limit_to : 0,
            number_limit_from: question?.set_range ? question?.number_limit_from : 0,
            is_required: question.is_required,
          };
          let resQuestion;

          if (isEdit && question?.id) resQuestion = await editFormQuestions(question?.id, data, editFormData?.id);
          else resQuestion = await saveFormQuestions(data);

          const foundQuestion = oldFormQuestion.find((q) => q.id === question.id);
          if (
            (foundQuestion?.question_type === 'choice' ||
              foundQuestion?.question_type === 'select') &&
            (question?.question_type === 'free_text' ||
              question?.question_type === 'number') &&
            foundQuestion?.answers?.length
          ) {
            await Promise.all(foundQuestion.answers.map(async (d) => { await deleteQuestionOptions(d?.id, question?.id); }));
          }

          if (resQuestion && (question?.question_type === 'choice' || question?.question_type === 'select') && question?.answers.length) {
            if (oldFormQuestion?.length) {
              if (foundQuestion && foundQuestion?.answers?.length) {
                await Promise.all(foundQuestion.answers.map(async (d) => {
                  const foundAnswer = question?.answers?.find((a) => a.id === d.id);
                  if (!foundAnswer) await deleteQuestionOptions(d?.id, question?.id);
                }));
              }
            }

            await Promise.all(question?.answers.map(async (answer) => {
              const dataAnswer = {
                question: resQuestion?.data?.id,
                label: answer.label,
                value: answer.value,
                order: answer.order,
              };

              if (isEdit && answer?.id) await editFormQuestionOptions(answer?.id, dataAnswer, question?.id);
              else await saveFormQuestionOptions(dataAnswer);
            }));
          }
        }),
      );
    }
    clearValues();
    handleFormAdded();
    onBackClick();
  };

  const handleMainFormDataChange = (val, type) => {
    setFormMainData((prevState) => ({ ...prevState, [type]: val }));
  };

  const handleAddAnswerOptions = (question, index) => {
    const newData = [...formQuestions];
    const lastValue =
      newData[index]?.answers[newData[index]?.answers?.length - 1]?.value;
    const defaultValue = parseInt(lastValue, 10) + 1;
    newData[index].answers = [
      ...newData[index].answers,
      { label: '', value: defaultValue },
    ];
    setFormQuestions(newData);
  };

  const handleChangeAnswerConfig = (
    value,
    questionIndex,
    answerIndex,
    type,
  ) => {
    const newData = [...formQuestions];
    if (type === 'label') {
      newData[questionIndex].answers[answerIndex].label = value;
    }
    if (type === 'value') {
      newData[questionIndex].answers[answerIndex].value = value;
    }
    setFormQuestions(newData);
  };

  const handleRemoveChoice = (questionIndex, answerIndex) => {
    const updatedQuestions = formQuestions.map((question, qIndex) => {
      if (qIndex === questionIndex) {
        const updatedAnswers = question.answers.filter(
          (_, aIndex) => aIndex !== answerIndex,
        );
        return {
          ...question,
          answers: updatedAnswers,
        };
      }
      return question;
    });

    setFormQuestions(updatedQuestions);
  };

  const handleAddSelectAnswer = (questionIndex) => {
    const existingQuestion = formQuestions[questionIndex];
    const inputFound = newSelectValue.find(
      (val) => val.index === questionIndex,
    );

    if (!existingQuestion || !inputFound) {
      return;
    }

    const answerIndex = existingQuestion?.answers?.length || 0;

    const updatedAnswers = [
      ...(existingQuestion.answers || []),
      { label: inputFound.label, value: answerIndex },
    ];
    const updatedQuestions = [...formQuestions];
    updatedQuestions[questionIndex] = {
      ...existingQuestion,
      answers: updatedAnswers,
    };

    setFormQuestions(updatedQuestions);
    setNewSelectValue((prevState) => prevState.filter((val) => val?.index !== questionIndex));
  };

  const handleNewSelectValueInputChange = (e, index) => {
    const label = e.target.value;
    const newValue = [...newSelectValue];
    const existingIndex = newValue.findIndex((val) => val.index === index);

    if (existingIndex !== -1) {
      newValue[existingIndex] = { index, label };
    } else {
      newValue.push({ index, label });
    }

    setNewSelectValue(newValue);
  };

  const handleChangeNumberLimit = (value, key, index) => {
    const tempData = [...formQuestions];
    tempData[index][key] = value;
    setFormQuestions(tempData);
  };

  const handleChangeSetRange = (value, index) => {
    const tempData = [...formQuestions];
    tempData[index].set_range = value;
    setFormQuestions(tempData);
  };

  const handleRemoveSelectOption = (e, questionIndex, selectedAnswer) => {
    e.stopPropagation();

    setFormQuestions((prevQuestions) => {
      const updatedQuestions = prevQuestions.map((question, qIndex) => {
        if (qIndex === questionIndex) {
          const updatedAnswers = question.answers.filter(
            (answer) => answer.label !== selectedAnswer.label,
          );
          return {
            ...question,
            answers: updatedAnswers,
          };
        }
        return question;
      });

      return updatedQuestions;
    });
  };

  const handleMultiSelect = (index) => {
    const tempData = [...formQuestions];
    tempData[index].multi_select = !tempData[index].multi_select;
    setFormQuestions(tempData);
  };

  const renderQuestionInput = (question, type, index) => {
    const selectInputValue = newSelectValue.find((val) => val?.index === index);
    return (
      <div>
        {type === 'choice' && (
          <ChoiceQuestion
            question={question}
            handleAddAnswerOptions={handleAddAnswerOptions}
            handleRemoveChoice={handleRemoveChoice}
            handleChangeAnswerConfig={handleChangeAnswerConfig}
            index={index}
          />
        )}
        {type === 'select' && (
          <SelectQuestion
            index={index}
            question={question}
            selectInputValue={selectInputValue}
            handleMultiSelect={handleMultiSelect}
            handleNewSelectValueInputChange={handleNewSelectValueInputChange}
            handleAddSelectAnswer={handleAddSelectAnswer}
            handleRemoveSelectOption={handleRemoveSelectOption}
          />
        )}
        {type === 'number' && (
          <NumberQuestion
            question={question}
            index={index}
            handleChangeSetRange={handleChangeSetRange}
            handleChangeNumberLimit={handleChangeNumberLimit}
          />
        )}
      </div>
    );
  };

  const handleChangeInput = (value, index) => {
    const tempData = [...formQuestions];
    tempData[index].question = value;
    setFormQuestions(tempData);
  };

  const inputSelectTypes = [
    { id: 'free_text', name: t('page_content.human_resources.forms.question_type_text') },
    { id: 'number', name: t('page_content.human_resources.forms.question_type_number') },
    { id: 'select', name: t('page_content.human_resources.forms.question_type_select') },
    { id: 'choice', name: t('page_content.human_resources.forms.question_type_choice') },
    { id: 'date', name: t('page_content.human_resources.forms.question_type_date') },
  ];

  const handleAnswerRequired = (index) => {
    const tempData = [...formQuestions];
    tempData[index].is_required = !tempData[index].is_required;
    setFormQuestions(tempData);
  };

  const handleSelectGroup = (value, index) => {
    const tempData = [...formQuestions];

    if (value?.id) {
      getTemplateOptions(value.id)
        .then((res) => {
          const results = get(res, 'data.results', []);
          results.forEach((result) => delete result.id);

          tempData[index].group_question = value;
          tempData[index].answers = [
            ...(tempData[index].answers || []),
            ...results,
          ];

          tempData[index]?.answers.sort(
            (a, b) => (b?.value || 0) - (a?.value || 0),
          );
          tempData[index]?.answers.forEach((result, i) => {
            result.order = 1 + i;
          });
          setFormQuestions(tempData);
        })
        .catch((error) => console.error(error));
    } else {
      tempData[index].group_question = value;
      if (tempData[index]?.question_type === 'choice') tempData[index].answers = [{ label: '', value: '1' }];
      else tempData[index].answers = [];
      setFormQuestions(tempData);
    }
  };

  const renderRow = (question, index) => {
    return (
      <div key={question?.id} className="question_type_container">
        <div className="question_type_selection">
          <span className="text_title_style">
            {t('page_content.human_resources.forms.label_select_question_type')}
            :
          </span>
          <div
            className={`${
              !question?.question_type && 'no-question-type-border'
            }`}
            style={{ display: 'flex', alignItems: 'center', gap: 20 }}
          >
            <Select
              options={inputSelectTypes}
              getOptionLabel={(type) => type.name}
              getOptionValue={(type) => type.id}
              menuPosition="fixed"
              placeholder={t(
                'page_content.human_resources.forms.placeholder_question_type',
              )}
              onChange={(e) => handleSelectType(e, index)}
              value={
                inputSelectTypes.find(
                  (val) => val?.id === question?.question_type,
                ) || ''
              }
              styles={selectStyles}
            />
            {templateOptionGroups?.length > 0 &&
              (question?.question_type === 'select' ||
                question?.question_type === 'choice') && (
                <div style={{ width: '100%' }}>
                  <Select
                    options={templateOptionGroups}
                    getOptionLabel={(group) => group.name}
                    getOptionValue={(group) => group.id}
                    menuPosition="fixed"
                    isClearable
                    placeholder={t(
                      'page_content.human_resources.forms.placeholder_question_group',
                    )}
                    onChange={(e) => handleSelectGroup(e || '', index)}
                    value={
                      templateOptionGroups?.find(
                        (val) => val?.id === question?.group_question?.id,
                      ) || ''
                    }
                    styles={selectModalStyles}
                  />
                </div>
            )}
            <div>
              {question.show_warning && (
                <div style={{ color: 'red' }}>
                  {t(
                    'page_content.human_resources.forms.modal_warning_message',
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => handleAnswerRequired(index)}
            className={`answer_required_container ${question?.is_required ? 'answer_required_container-checked' : 'answer_required_container-unchecked'}`}
          >
            {t('page_content.human_resources.forms.answer_required_label')}
            <div className="checkbox-style">
              <input defaultChecked={question?.is_required} type="checkbox" />
            </div>
          </div>
        </div>
        <div className="question_container">
          <div className="question_order_buttons">
            {t('page_content.human_resources.forms.label_order')}
            <div className="question_order_section">
              <div className="order_buttons_column">
                <div
                  className="order_icon"
                  onClick={() => handleChangeOrder(question, 'up')}
                >
                  <IconUp width="20px" height="10px" />
                </div>
                <div
                  className="order_icon"
                  onClick={() => handleChangeOrder(question, 'down')}
                >
                  <IconDown width="20px" height="10px" />
                </div>
              </div>
              <span className="question_index">{index + 1} </span>
            </div>
          </div>
          <div className="question_content">
            <span>
              {t('page_content.human_resources.forms.label_question')}:
            </span>
            <div className="question_header">
              <div
                className={`${
                  !question?.question && 'input-not-valid'
                } question_title`}
              >
                <input
                  value={question?.question || ''}
                  placeholder={t(
                    'page_content.human_resources.forms.placeholder_question_input',
                  )}
                  onChange={(e) => handleChangeInput(e.target.value, index)}
                />
              </div>
              <div className="question_header_icons">
                <div
                  className="icon_box"
                  onClick={() => handleDeleteRow(question, index)}
                >
                  <IconRemove color="#364252" width="14px" height="14px" />
                </div>
              </div>
            </div>
            <div className="answers_container">
              {renderQuestionInput(question, question?.question_type, index)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      size="large"
      title={editFormData ? t('page_content.human_resources.forms.edit_form_title') : t('page_content.human_resources.forms.create_new_form_title')}
      isOpen={showModal}
      handleClose={() => { clearValues(); onBackClick(); }}
      handleSave={handleSaveForm}
      error={!formValid && t('page_content.human_resources.forms.invalid_form_msg')}
      enableSaveOnEnter={false}
    >
      <div style={{ padding: 24 }}>
        {isLoading ? (
          <ContentLoader />
        ) : (
          <div>
            <div className="fixed_inputs_view">
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.forms.modal_form_name_label')}</div>
                <div className={`${!formMainData?.name && 'input-not-valid'} right_input`}>
                  <input
                    type="text"
                    value={formMainData?.name || ''}
                    onChange={(e) => handleMainFormDataChange(e.target.value, 'name')}
                  />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.forms.modal_form_description_label')}</div>
                <div className="right_input">
                  <input
                    type="text"
                    value={formMainData?.description || ''}
                    onChange={(e) => handleMainFormDataChange(e.target.value, 'description')}
                  />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.forms.modal_form_category_label')}</div>
                <div className="right_select">
                  <Select
                    options={categories}
                    getOptionLabel={(type) => type.name}
                    getOptionValue={(type) => type.id}
                    placeholder={t('page_content.human_resources.forms.modal_form_category_placeholder')}
                    menuPosition="fixed"
                    onChange={(value) => handleMainFormDataChange(value, 'category')}
                    value={categories.find((val) => val?.id === formMainData?.category?.id) || ''}
                    styles={selectModalStyles}
                  />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.human_resources.forms.modal_form_publish')}</div>
                <div style={{ width: '100%', marginLeft: 'auto' }}>
                  <input
                    type="checkbox"
                    checked={formMainData?.is_published || false}
                    onChange={(e) => handleMainFormDataChange(e.target.checked, 'is_published')}
                  />
                </div>
              </div>
              <div className="double_modal_row_forms">
                <div className="modal_row">
                  <div className="left_text">{t('page_content.human_resources.forms.modal_allow_files_label')}</div>
                  <div style={{ width: '25%', marginRight: 'auto' }}>
                    <input
                      type="checkbox"
                      checked={formMainData?.file_upload || false}
                      onChange={(e) => handleMainFormDataChange(e.target.checked, 'file_upload')}
                    />
                  </div>
                </div>
                <div className="modal_row">
                  <div className="left_text">{t('page_content.human_resources.forms.modal_action_required_label')}</div>
                  <div style={{ width: '25%', marginRight: 'auto' }}>
                    <input
                      type="checkbox"
                      checked={formMainData?.action_required || false}
                      onChange={(e) => handleMainFormDataChange(e.target.checked, 'action_required')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ ...{ display: 'flex', padding: '10px 0px', margin: '10px 0px' }, ...(formQuestions.length > 0 ? { borderBottom: '1px solid #c9c9c9' } : {}) }}>
              <div style={{ marginLeft: 'auto' }}>
                <Button type="add" onClick={handleAddNewInput}>{t('page_content.human_resources.forms.button_add_question')}</Button>
              </div>
            </div>
            <div className="questions_grid">
              {formQuestions?.map((question, index) => {
                return renderRow(question, index);
              })}
              {formQuestions.length > 1 && (
                <div className="add_question_footer_btn">
                  <Button type="add" onClick={handleAddNewInput}>{t('page_content.human_resources.forms.button_add_question')}</Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

AddNewFormModal.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number,
  onBackClick: PropTypes.func,
  handleFormAdded: PropTypes.func,
  editFormData: PropTypes.object,
  showModal: PropTypes.bool,
  reFetchCategories: PropTypes.bool,
};

export default withRouter(withTranslation()(AddNewFormModal));
