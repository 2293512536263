import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, TableButtons } from 'shared';
import Select from 'react-select';
import { IconSearch, IconWarning } from 'shared/Icons';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { get } from 'lodash';
import { checkActiveFilters, numberSeparatorFormat } from 'industry/helpers';
import { saveSortingAndFiltering } from 'industry/actions';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { colors } from 'shared/colors';
import 'react-table/react-table.css';
import '../styles.scss';
import { getWarehousePositions, getListOfWarehouses, getPaginatedWarehousePositions } from '../actions';

class WarehousePosition extends Component {
  constructor(props) {
    super(props);
    // const sortingAndFiltering = props && props.currentUser && props.currentUser.data ? props.currentUser.data : {};
    const selectedWarehouse = 'all';

    this.defaultFilters = {
      selectedWarehouse,
    };

    // let filtersActive = false;

    // This is commented out because we only want all warehouses at the moment, the logic for saving the user selection is implemented
    // but not used currently.

    // // if (sortingAndFiltering.warehousePosition) {
    // //   const sAndF = sortingAndFiltering.warehousePosition;
    // //   selectedWarehouse = sAndF.selectedWarehouse ? sAndF.selectedWarehouse : 'all';
    // //   filtersActive = checkActiveFilters(this.defaultFilters, sAndF);
    // // }

    this.timerRef = React.createRef();
    this.state = {
      query: '',
      positions: [],
      previous: null,
      next: null,
      count: null,
      // filtersActive,
      listOfWarehouses: [],
      selectedWarehouse: {},
      isLoadingPositions: false,
      selectedAscDesc: 'asc',
      selectedSort: 'warehouse',
    };
  }

  componentDidMount() {
    const { selectedWarehouse } = this.state;
    const { locationId } = this.props;
    getListOfWarehouses(locationId)
      .then((res) => {
        if (selectedWarehouse) {
          this.fetchWarehousePositions();
        }
        this.setState({
          listOfWarehouses: res.data.results || [],
        });
      });
  }

  componentWillUnmount() {
    this.saveSortingAndFiltering();
  }

  fetchWarehousePositions() {
    this.setState({
      isLoadingPositions: false,
    });
    const { selectedWarehouse, query, selectedAscDesc, selectedSort } = this.state;
    const { locationId, companyId } = this.props;
    let filters = '';

    if (selectedWarehouse && selectedWarehouse.id) {
      filters += `&warehouse=${selectedWarehouse.id}`;
    }
    if (query) {
      filters += `&material_code=${query}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    let sortKey = selectedSort;
    if (selectedSort === 'warehouse.name') {
      sortKey = 'warehouse';
    }
    filters += `&order_by=${asc}${sortKey}`;

    getWarehousePositions(companyId, locationId, filters)
      .then((re) => {
        this.setState({
          positions: get(re, 'data.results') || [],
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isLoadingPositions: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoadingPositions: false,
        });
      });
  }

  fetchPaginatedWarehousePositions = (url) => {
    this.setState({
      isLoadingPallets: true,
    });
    getPaginatedWarehousePositions(url)
      .then((re) => {
        this.setState({
          positions: get(re, 'data.results') || [],
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isLoadingPallets: false,
        });
      })
      .catch((e) => console.error('Error while fetching orders', e));
  }

  handleQueryOrFilterChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), () => {
      this.fetchWarehousePositions();
      this.saveSortingAndFiltering();
    });
  }

  saveSortingAndFiltering = () => {
    const {
      selectedWarehouse,
    } = this.state;

    const {
      currentUser,
      setCurrentUser,
    } = this.props;

    if (currentUser) {
      currentUser.data.warehousePosition = {
        selectedWarehouse,
      };
      setCurrentUser(currentUser);
      saveSortingAndFiltering({ data: currentUser.data });
      this.checkActiveSortingAndFiltering(currentUser.data.warehousePosition);
    }
  }

  checkActiveSortingAndFiltering = (userFilters) => {
    const filtersActive = checkActiveFilters(this.defaultFilters, userFilters);
    this.setState({ filtersActive });
  }

  handleQueryChange = (e) => {
    const searchValue = e.target.value;
    this.setState({ query: searchValue }, () => {
      this.handleStateQueryChange();
    });
  }

  handleStateQueryChange = () => {
    const { query } = this.state;
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      if (query.length < 1 || query.length >= 2) {
        this.setState({
          isLoadingPositions: true,
        });
        this.fetchWarehousePositions();
      }
    }, 500);
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchWarehousePositions();
    });
  }

  clearFilters = () => {
    this.setState({
      selectedWarehouse: '',
      positions: [],
      query: '',
    }, () => {
      this.fetchWarehousePositions();
      this.saveSortingAndFiltering();
    });
  }

  render() {
    const { query, positions, isLoadingPositions, selectedWarehouse, listOfWarehouses, previous, next, count } = this.state;
    const { t, companyId, locationId, i18n } = this.props;

    const warehouseOptions = [
      ...listOfWarehouses.sort((a, b) => a.name.localeCompare(b.name)).map((warehouse) => ({
        name: warehouse.name,
        id: warehouse.id,
      })),
    ];

    const selectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '200px',
        minHeight: '34px',
        height: '36px',
        padding: 0,
        fontSize: '13px',
        color: '#555',
        marginRight: '5px',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0px 0px 0px 5px',
      }),
      clearIndicator: (provided) => ({
        ...provided,
        padding: '0px 3px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: 10,
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: 200,
        borderRadius: 0,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    return (
      <div className="warehouse_container">
        <div className="warehouse_toolbar_area">
          <div className="warehouse_toolbar_input_container">
            <input
              onChange={(e) => this.handleQueryChange(e)}
              placeholder={t('page_content.warehouse.input_placeholder')}
              value={query}
              type="text"
              disabled={!this.state.selectedWarehouse}
            />
            <div className="warehouse_toolbar_icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
          <Select
            options={warehouseOptions}
            getOptionLabel={(warehouse) => warehouse.name}
            getOptionValue={(warehouse) => warehouse.id}
            isSearchable
            placeholder={t('page_content.warehouse.warehouse_filter_placeholder')}
            onChange={(value) => this.handleQueryOrFilterChange('selectedWarehouse', value)}
            value={(warehouseOptions.find((whouse) => whouse.id === selectedWarehouse.id)) || ''}
            styles={selectStyles}
          />
          <div className="warehouse_clearFilters_button">
            <Button
              type="plain"
              onClick={this.clearFilters}
            >
              {t('page_content.events.events.clear_all_button')}
            </Button>
          </div>
        </div>
        {!selectedWarehouse && <span className="warehouse_warning">
          <IconWarning color={colors.red} height="16px" width="16px" />
          <p className="warehouse_warning_text">{t('page_content.warehouse.warning_placeholder')}</p>
        </span>}
        <div className="warehouse_table_area">
          <Table
            style={{ userSelect: 'text' }}
            columns={[
              {
                Header: () => <span>{t('page_content.warehouse.warehouse_position.name')}</span>,
                accessor: 'warehouse.name',
                Cell: (row) => (row && row.value ? row.value : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.warehouse_position.externalId')}</span>,
                accessor: 'external_id',
              },
              {
                Header: () => <span>{t('page_content.warehouse.warehouse_position.capacity')}</span>,
                sortable: false,
                Cell: (row) => (row?.original?.palletstock_set?.length > 0 ?
                  <img src={checkMarkTrue} width="18px" height="18px" alt="" /> : <img src={checkMarkFalse} width="18px" height="18px" alt="" />),
              },
              {
                Header: () => <span>{t('page_content.warehouse.warehouse_position.pallet')}</span>,
                sortable: false,
                Cell: (row) => (row.original.palletstock_set.length >= 1 ? row.original.palletstock_set[0].pallet.external_id : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.warehouse_position.product')}</span>,
                sortable: false,
                Cell: (row) => (row.original.palletstock_set.length >= 1 ? row.original.palletstock_set[0].product_type.name : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.warehouse_position.quantity')}</span>,
                sortable: false,
                Cell: (row) => (row.original.palletstock_set.length >= 1 ? `${numberSeparatorFormat(i18n.language, row.original.palletstock_set[0].quantity, 0, 0, true)} ${row.original.palletstock_set[0].product_type.base_unit}` : '-'),
              },
              {
                Header: () => <span>{t('page_content.warehouse.warehouse_position.order')}</span>,
                sortable: false,
                Cell: (row) => (row.original.palletstock_set.length >= 1 ? <Link to={`/${companyId}/industry/location/${locationId}/orders/${row.original.palletstock_set[0].order.id}`}>
                  {row.original.palletstock_set[0].order.external_id}
                </Link> : '-'),
              },
            ]}
            data={positions}
            minRows={0}
            defaultPageSize={30}
            noDataText=" "
            showPagination={false}
            loading={isLoadingPositions}
            defaultSorted={[{ id: 'warehouse.name', desc: false }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <TableButtons next={next} previous={previous} fetchFunction={this.fetchPaginatedWarehousePositions} count={count} />
        </div>
      </div>
    );
  }
}
WarehousePosition.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currentUser: PropTypes.object,
  setCurrentUser: PropTypes.func,
  i18n: PropTypes.object.isRequired,
};

export default (withTranslation()(WarehousePosition));
