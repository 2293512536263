import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Select from 'react-select';
import api from 'helpers/api';

class AssetGroupPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAssetGroup: props.asset_group,
      options: [],
    };
  }

  componentDidMount() {
    const { locationId, companyId } = this.props;
    if (locationId) {
      api.get(`/api/v1/industry/asset_groups/?company=${companyId}&location=${locationId}&order_by=name&limit=200`)
        .then((json) => this.setState({
          options: get(json, 'data.results'),
        }));
    } else {
      api.get(`/api/v1/industry/asset_groups/?company=${companyId}&order_by=name&limit=200`)
        .then((json) => this.setState({
          options: get(json, 'data.results'),
        }));
    }
  }

  componentDidUpdate(prevProps) {
    const { asset_group } = this.props;
    if (asset_group !== prevProps.asset_group) {
      // eslint-disable-next-line
      this.setState({ selectedAssetGroup: asset_group });
    }
  }

  handleChange = (assetGroup) => {
    const { handleChange } = this.props;
    this.setState({ selectedAssetGroup: assetGroup });
    handleChange(assetGroup);
  }

  render() {
    const { selectedAssetGroup, options } = this.state;
    const { disabled, htmlId, customClassName, t } = this.props;

    return (
      <Select
        isDisabled={disabled}
        id={htmlId}
        options={options}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        onChange={this.handleChange}
        placeholder={t('shared.asset_group_picker.placeholder')}
        value={(options === undefined ? [] : options).find((a) => a.id === selectedAssetGroup)}
        className={`AscaliaSelect AssetGroupPicker ${customClassName}`}
      />
    );
  }
}

AssetGroupPicker.propTypes = {
  asset_group: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  htmlId: PropTypes.string,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  customClassName: PropTypes.string,
  t: PropTypes.func.isRequired,
};

AssetGroupPicker.defaultProps = {
  disabled: false,
  customClassName: '',
};

const mapStateToProps = (state) => ({
  locationId: get(state, 'app.location.id'),
  companyId: get(state, 'app.company.id'),
});

export default connect(mapStateToProps)(withTranslation()(AssetGroupPicker));
