import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { IconLocation } from 'shared/Icons';
import api from 'helpers/api';
import './styles.scss';

class LocationSelector extends Component {
  constructor() {
    super();
    this.state = {
      locations: undefined,
      loadingLocations: false,
      showMenu: false,
    };
  }

  componentDidMount() {
    const { currentLocation } = this.props;
    if (currentLocation) {
      window.currentLocation = JSON.parse(JSON.stringify(currentLocation));
    }
  }

  componentDidUpdate() {
    const { currentLocation } = this.props;
    if (currentLocation) {
      window.currentLocation = JSON.parse(JSON.stringify(currentLocation));
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClose, false);
  }

  getLocations = () => {
    const { companyId } = this.props;
    api
      // .get(`/api/v1/locations/?company=${companyId}`)
      .get(`/api/v1/sidebar/?company=${companyId}`)
      .then((json) => {
        this.setState({
          locations: get(json, 'data.items'),
          loadingLocations: false,
        });
      })
      .catch(() => {
        this.setState({
          loadingLocations: false,
        });
      });
  }

  getPosition = (string, subString, index) => {
    return string.split(subString, index).join(subString).length;
  }

  getNewLocationURL = (locationId) => {
    const currentUrl = window.location.href;

    if (currentUrl.includes('/settings/')) {
      const tempArr = currentUrl.split('/');
      const index = tempArr.indexOf('location');

      if (index !== -1 && index + 2 < tempArr.length && tempArr[index + 2] === 'settings') {
        tempArr[index + 1] = `${locationId}`;
        const newUrl = tempArr.join('/');
        return newUrl;
      }
    } else {
      const tempArr = currentUrl.split('/');
      tempArr[6] = `${locationId}`;
      let newUrl = tempArr.join('/');
      const position = this.getPosition(currentUrl, '/', 8);
      newUrl = newUrl.substring(0, position);
      return newUrl;
    }
    return currentUrl;
  }

  handleClose = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.cleanComponentState();
  }

  cleanComponentState = () => {
    this.setState({ showMenu: false });
    document.removeEventListener('mousedown', this.handleClose, false);
  }

  showMenu = () => {
    const { locations } = this.state;
    this.setState({
      showMenu: true,
      loadingLocations: !locations,
    }, () => {
      document.addEventListener('mousedown', this.handleClose, false);
      if (!locations) {
        this.getLocations();
      }
    });
  }

  render() {
    const {
      loadingLocations,
      locations,
      showMenu,
    } = this.state;

    const { currentLocation } = this.props;

    return (
      // eslint-disable-next-line no-return-assign
      <div className="LocationSelector" ref={(node) => (this.node = node)}>
        <div className="LocationSelector-label" onClick={this.showMenu}>
          <IconLocation
            height="20px"
            width="20px"
          />
          <span>
            {currentLocation.name}
          </span>
        </div>
        {
          showMenu &&
          (
            <div className="LocationSelector-menu">
              <header>
                Locations
              </header>
              <main>
                {
                  loadingLocations && <span className="loading">Loading locations</span>
                }
                {
                  !loadingLocations && (
                    locations.map((l) => (
                      // <button
                      //   key={`location-${l.id}`}
                      //   onClick={this.handleLocationClick(l.id)}
                      // >
                      //   {l.name}
                      // </button>
                      <a key={l.id} className="LocationSelector__link" href={this.getNewLocationURL(l.id)}>{l.name}</a>
                    ))
                  )
                }
              </main>
            </div>
          )
        }
      </div>
    );
  }
}

LocationSelector.propTypes = {
  companyId: PropTypes.number.isRequired,
  currentLocation: PropTypes.object.isRequired,
};

export default LocationSelector;
