import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';

import { IconEdit } from 'shared/Icons';
import { Table, Modal } from 'shared';
import { defaultDateFormat } from 'shared/constants';
import './styles.scss';
import { getDepartmentDetails, getShifts } from './actions';
import { selectModalStyles } from '../../../styles/modules/reactSelect';

const IndustryLocationDepartmentDetails = ({ locationId, companyId, history }) => {
  const [departmentId, setDepartmentId] = useState(null);
  const [shifts, setShifts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [leaderType, setLeaderType] = useState(null);
  const [departmentDetails, setDepartmentDetails] = useState({});

  const fetchDepartmentDetails = async () => {
    if (departmentId) {
      const res = await getDepartmentDetails(departmentId, locationId);
      setDepartmentDetails(get(res, 'data.results', {}));
    }
  };

  const fetchShifts = async () => {
    const res = await getShifts(locationId);
    setShifts(get(res, 'data.results', []));
  };

  const getDepartmentId = () => {
    const url = window.location.href;
    const urlParts = url.split('/');
    setDepartmentId(parseInt(urlParts[urlParts.length - 1], 10));
  };

  useEffect(() => {
    fetchShifts();
    if (!departmentId) {
      getDepartmentId();
    } else {
      fetchDepartmentDetails();
    }
  }, [departmentId]);

  const handleEditClick = (type) => {
    setShowModal(true);
    setLeaderType(type);
  };

  return (
    <div className="department_details_container">
        <div className="breadcrumbs"><span
          onClick={() => { history.push(`/${companyId}/industry/location/${locationId}/human-resources/`, { tabIndex: 1 }); }}
        >Departments</span><p>{'>'}</p><div>{departmentDetails[0]?.name}</div></div>
        <div className="info_row">
         <div className="info_card">
         <div className="column">
            <div className="inline">
              <div className="leftColumn">
                Voditelj:
              </div>
              <div className="rightColumn">
                <div className="title">Pero Peric</div>
              </div>
              <div className="icons">
                <div onClick={() => handleEditClick('leader')}>
                  <IconEdit height="18px" width="18px" color="#555" />
                </div>
              </div>
            </div>
            <div className="date">
              Od: {moment(new Date()).format(defaultDateFormat)}
            </div>
          </div>
        </div>
         <div className="info_card">
         <div className="column">
            <div className="inline">
              <div className="leftColumn">
                Zamjena voditelja:
              </div>
              <div className="rightColumn">
                <div className="title">Pero Peric</div>
              </div>
              <div className="icons">
                <div onClick={() => handleEditClick('substitute')}>
                  <IconEdit height="18px" width="18px" color="#555" />
                </div>
              </div>
            </div>
            <div className="date">
              Od: {moment(new Date()).format(defaultDateFormat)}
            </div>
          </div>
        </div>
        </div>
        <div>
            <div className="table_title">
                Smjene
            </div>
            <Table
              style={{ userSelect: 'text' }}
              columns={[
                {
                  Header: () => <span>Smjena</span>,
                  accessor: 'name',
                  Cell: (row) => (row && row.value ? row.value : '-'),
                },
                {
                  Header: () => <span>Od</span>,
                  accessor: 'default_begin',
                  Cell: (row) => (row && row.value ? row.value : '-'),
                },
                {
                  Header: () => <span>Do</span>,
                  accessor: 'default_end',
                  Cell: (row) => (row && row.value ? row.value : '-'),
                },
              ]}
              data={shifts}
              noDataText=" "
              showPagination={false}
              minRows={0}
              selectedRow={null}
            />
        </div>
        <Modal title="Change" isOpen={showModal} handleClose={() => setShowModal(false)} handleSave={() => {}}>
            <div className="modal_container">
                <div className="modal_row">
                    <div className="left_label">
                        {leaderType === 'leader' ? 'Leader' : 'Substitute'}
                    </div>
                    <div className="right_select">
                        <Select styles={selectModalStyles} />
                    </div>
                </div>
            </div>
        </Modal>
        </div>
  );
};

IndustryLocationDepartmentDetails.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  //   t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default (withRouter(withTranslation()(IndustryLocationDepartmentDetails)));
