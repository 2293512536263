/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import update from 'immutability-helper';
import Tooltip from 'rc-tooltip';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { IconRemove, IconInfo } from 'shared/Icons';
import { colors } from 'shared/colors';
import { formatTypes, usingVariablesTooltip } from 'shared/constants';
import { SketchPicker } from 'react-color';
import Select from 'react-select';
import {
  Button,
  AdvancedColorPicker,
} from 'shared';
import DatapointForm from '../DatapointForm';
import { defaultSettings } from '../constants';
import '../styles.scss';
import './styles.scss';

const defaultColors = [colors.red, colors.yellow, colors.green];

const setFallbackColors = (settings) => {
  if (!settings) {
    return settings; // Settings not loaded
  }
  if (settings.colors) {
    return settings; // Colors exist
  }
  const fallbackColors = [colors.red];
  const thresholdsArray = settings.threshold.split(',').filter((t) => !!t);
  while (thresholdsArray.length + 1 > fallbackColors.length) {
    const index = Math.min(fallbackColors.length, 2);
    fallbackColors.push(defaultColors[index]);
  }
  return update(settings, {
    colors: {
      $set: fallbackColors,
    },
  });
};

const setFallbackAppearance = (settings) => {
  if (!settings) {
    return settings; // Settings not loaded
  }
  if (settings.appearance) {
    return settings; // Colors exist
  }

  const appearanceData = {
    title: 'left',
    backgroundColor: 'white',
  };
  return update(settings, {
    appearance: {
      $set: { ...appearanceData },
    },
  });
};

const setFallbackTitlebar = (settings) => {
  if (!settings) {
    return settings; // Settings not loaded
  }

  if (Object.prototype.hasOwnProperty.call(settings, 'showTitlebar')) {
    return settings; // titlebar exist
  }

  const newSettings = settings;
  newSettings.showTitlebar = true;

  return newSettings;
};

class GaugeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.config.id,
      widgetData: props.config.widget_data,
      settings: props.config.settings ? (setFallbackTitlebar(props.config.settings), setFallbackColors(props.config.settings), setFallbackAppearance(props.config.settings)) : defaultSettings.gauge,
      title: props.config.title || '',
      type: props.config.type,
      showDecimal: false,
    };
    this.saveWidget = this.saveWidget.bind(this);
    this.addWidgetDataItem = this.addWidgetDataItem.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleThresholdChange = this.handleThresholdChange.bind(this);
  }

  addWidgetDataItem() {
    const widgetData = this.state.widgetData.slice(0);
    widgetData.push({
      drone: null,
      asset: null,
      metric: null,
      field: null,
      operation: 'last',
      group_by: 'auto',
      style: {
        selectedField: null,
      },
    });
    this.setState({ widgetData });
  }

  updateWidgetDataItem(numberInArray, widgetDataItem) {
    const widgetData = this.state.widgetData.slice(0);
    widgetData[numberInArray] = widgetDataItem;
    this.setState({
      widgetData,
    });
  }

  updateOEE(isOEE) {
    const { settings } = this.state;
    if (isOEE) {
      this.setState({
        settings: update(settings, {
          isOEE: {
            $set: isOEE,
          },
          showUnit: {
            $set: true,
          },
          unit_text: {
            $set: '%',
          },
          threshold: {
            $set: '65, 69',
          },
          customColors: {
            $set: true,
          },
        }),
      }, () => { this.updateColorsBasedOnThreshold(); });
    } else {
      this.setState({
        settings: update(settings, {
          isOEE: {
            $set: isOEE,
          },
        }),
      });
    }
  }

  updateAllItemsMetric(all_items_metric) {
    const { settings } = this.state;
    this.setState({
      settings: update(settings, {
        all_items_metric: {
          $set: all_items_metric,
        },
      }),
    });
  }

  updateBadItemsMetric(bad_items_metric) {
    const { settings } = this.state;
    this.setState({
      settings: update(settings, {
        bad_items_metric: {
          $set: bad_items_metric,
        },
      }),
    });
  }

  updateMaxItemsPerMin(max_items_per_min) {
    const { settings } = this.state;
    this.setState({
      settings: update(settings, {
        max_items_per_min: {
          $set: max_items_per_min,
        },
      }),
    });
  }

  updateUtilizationMetric(utilization_metric) {
    const { settings } = this.state;
    this.setState({
      settings: update(settings, {
        utilization_metric: {
          $set: utilization_metric,
        },
      }),
    });
  }

  updateValueToDisplay(value_to_display) {
    const { settings } = this.state;
    this.setState({
      settings: update(settings, {
        value_to_display: {
          $set: value_to_display || { label: 'OEE' },
        },
        threshold: {
          $set:
            value_to_display.label === 'Quality' ? '95, 98' :
              ((value_to_display.label === 'Performance') || (value_to_display.label === 'Availability')) ? '80, 85' :
                value_to_display.label === 'OEE' ? '65, 69' : '',
        },
      }),
    }, this.updateColorsBasedOnThreshold());
  }

  updateAdjustedValue = (val, metricId) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.adjusted_value)) {
      settings.adjusted_value = [];
    }
    const existingValue = settings.adjusted_value.find((aV) => aV.metricId === metricId);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.adjusted_value.push({ value: val, metricId });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateShiftFilter = (val, id) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.shift)) {
      settings.shift = [];
    }
    const existingValue = settings.shift.find((w) => w.id === id);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.shift.push({ value: val, id });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateProductTypeFilter = (val, id) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.productType)) {
      settings.productType = [];
    }
    const existingValue = settings.productType.find((w) => w.id === id);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.productType.push({ value: val, id });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateWhereFilter = (val, metricId, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.where)) {
      settings.where = [];
    }
    const existingValue = settings.where.find((w) => w.metricId === metricId && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.where.push({ value: val, metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  removeWidgetDataItem(numberInArray) {
    const widgetData = this.state.widgetData.slice(0);
    widgetData.splice(numberInArray, 1);
    this.setState({
      widgetData,
    });
  }

  saveWidget() {
    const data = {
      widget_data: this.state.widgetData.map((x) => {
        x.metric = x.metric.id ? x.metric.id : this.state.settings.all_items_metric.id;
        return x;
      }),
      id: this.state.id,
      title: this.state.title,
      settings: this.state.settings,
    };
    this.props.onSave(data);
  }

  handleColorChange(i, color) {
    const { settings } = this.state;
    const clrs = settings.colors;
    clrs[i] = color;
    this.setState({
      settings: update(settings, {
        colors: {
          $set: clrs,
        },
      }),
    });
  }

  handleThresholdChange(e) {
    const { settings } = this.state;
    const thresholds = e.target.value;
    const clrs = settings.colors;
    const thresholdsArray = thresholds.split(',').filter((t) => !!t);

    if (thresholdsArray.length + 1 > clrs.length) {
      while (thresholdsArray.length + 1 > clrs.length) {
        clrs.push(defaultColors[Math.min(clrs.length, 2)]);
      }
    } else if (thresholdsArray.length + 1 < clrs.length) {
      clrs.splice(1 + thresholdsArray.length);
    }
    this.setState({
      settings: update(settings, {
        threshold: {
          $set: thresholds,
        },
        colors: {
          $set: clrs,
        },
        customColors: {
          $set: !thresholds.length ? false : settings.customColors,
        },
      }),
    });
  }

  updateColorsBasedOnThreshold() {
    const { settings } = this.state;
    const clrs = settings.colors;
    const thresholdsArray = settings && settings.threshold ? settings.threshold.split(',').filter((t) => !!t) : '';

    if (thresholdsArray.length + 1 > clrs.length) {
      while (thresholdsArray.length + 1 > clrs.length) {
        clrs.push(defaultColors[Math.min(clrs.length, 2)]);
      }
    } else if (thresholdsArray.length + 1 < clrs.length) {
      clrs.splice(1 + thresholdsArray.length);
    }
    this.setState({
      settings: update(settings, {
        colors: {
          $set: clrs,
        },
      }),
    });
  }

  updateDepartmentFilter = (value, metricId) => {
    const { settings } = this.state;
    if (!value) {
      if (Array.isArray(settings?.department)) {
        settings.department = settings.department.filter((d) => !(d.id === metricId));
      }
      if (Array.isArray(settings?.workOperation)) {
        settings.workOperation = settings.workOperation.filter((d) => !(d.id === metricId));
      }
      this.setState({
        settings: JSON.parse(JSON.stringify(settings)),
      });
      return;
    }
    if (!Array.isArray(settings.department)) {
      settings.department = [];
    }
    const existingValue = settings.department.find((w) => w.id === metricId);
    if (existingValue) {
      existingValue.value = value;
    } else {
      settings.department.push({ value, id: metricId });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateWorkOperationsFilter = (value, metricId) => {
    const { settings } = this.state;
    if (!value) {
      if (Array.isArray(settings?.workOperation)) {
        settings.workOperation = settings.workOperation.filter((d) => !(d.id === metricId));
      }
      this.setState({
        settings: JSON.parse(JSON.stringify(settings)),
      });
      return;
    }
    if (!Array.isArray(settings.workOperation)) {
      settings.workOperation = [];
    }
    const existingValue = settings.workOperation.find((w) => w.id === metricId);
    if (existingValue) {
      existingValue.value = value;
    } else {
      settings.workOperation.push({ value, id: metricId });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  render() {
    const {
      widgetData,
      settings,
      title,
      showDecimal,
      type,
    } = this.state;

    const { locationId, t } = this.props;
    const decimalPlaces = [1, 2, 3, 4];
    const titleSides = ['left', 'center', 'right'];

    const filteredFormatTypes = { ...formatTypes };

    delete filteredFormatTypes.date;

    return (
      <div className="default-form gauge-form">
        <table className="widget-title">
          <tbody>
            <tr>
              <td>
                <label htmlFor="widget-title">
                  {t('page_content.dashboards.edit_widget_modal.widget_title')}
                </label>
              </td>
              <td style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <input
                  id="widget-title"
                  onChange={(e) => this.setState({ title: e.target.value })}
                  placeholder={t('page_content.dashboards.edit_widget_modal.widget_title_placeholder')}
                  type="text"
                  value={title}
                />
                <Tooltip
                  id="tooltip-zoom"
                  trigger={['hover']}
                  placement="left"
                  overlay={usingVariablesTooltip(t)}
                  overlayClassName="where-filter-tooltip"
                >
                  <span aria-describedby="tooltip-zoom">
                    <IconInfo color="#2e86de" height="15px" width="17px" />
                  </span>
                </Tooltip>
              </td>
            </tr>
          </tbody>
        </table>
        <Tabs>
          <TabList>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.datapoints')}
            </Tab>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.appearance')}
            </Tab>
          </TabList>
          <TabPanel>
            {
              widgetData.map((widgetDataItem, i) => (
                <div key={`datapoint-${i}`} className="datapoint-container">
                  <DatapointForm
                    type={type}
                    key={i}
                    locationId={locationId}
                    datapoint={widgetDataItem}
                    onChange={(item) => this.updateWidgetDataItem(i, item)}
                    changeIsOEE={(isOEE) => this.updateOEE(isOEE)}
                    updateAllItemsMetric={(all_items_metric) => this.updateAllItemsMetric(all_items_metric)}
                    updateBadItemsMetric={(bad_items_metric) => this.updateBadItemsMetric(bad_items_metric)}
                    updateUtilizationMetric={(utilization_metric) => this.updateUtilizationMetric(utilization_metric)}
                    updateMaxItemsPerMin={(max_items_per_min) => this.updateMaxItemsPerMin(max_items_per_min)}
                    updateValueToDisplay={(value_to_display) => this.updateValueToDisplay(value_to_display)}
                    settings={settings}
                    isOEE={settings && settings.isOEE ? settings.isOEE : false}
                    adjustedValue={Array.isArray(settings.adjusted_value) ? settings.adjusted_value.find((aV) => { if (widgetDataItem.metric) { return aV.metricId === widgetDataItem.metric.id; } return false; }) : null}
                    updateAdjustedValue={(val, metricId) => this.updateAdjustedValue(val, metricId)}
                    shift={Array.isArray(settings.shift) ? settings.shift.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id; } return false; }) : null}
                    updateShiftFilter={(val, id) => this.updateShiftFilter(val, id)}
                    productType={Array.isArray(settings.productType) ? settings.productType.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id; } return false; }) : null}
                    updateProductTypeFilter={(val, id) => this.updateProductTypeFilter(val, id)}
                    where={Array.isArray(settings.where) ? settings.where.find((w) => { if (widgetDataItem.metric) { return w.metricId === widgetDataItem.metric.id && w.indexData === i; } return false; }) : null}
                    updateWhereFilter={(val, metricId, indexData) => this.updateWhereFilter(val, metricId, indexData)}
                    updateDepartmentFilter={(value, metricId) => this.updateDepartmentFilter(value, metricId)}
                    department={Array.isArray(settings.department) ? settings.department.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id; } return false; }) : null}
                    updateWorkOperationsFilter={(value, metricId) => this.updateWorkOperationsFilter(value, metricId)}
                    workOperation={Array.isArray(settings.workOperation) ? settings.workOperation.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id; } return false; }) : null}
                  />
                  <Button
                    onClick={() => this.removeWidgetDataItem(i)}
                    size="small"
                    type="delete"
                  >
                    <IconRemove
                      height="14px"
                      width="14px"
                    />
                  </Button>
                </div>
              ))
            }
            {
              widgetData.length === 0 &&
              <Button
                onClick={this.addWidgetDataItem}
                type="add"
              >
                {t('page_content.dashboards.edit_widget_modal.datapoints_tab.new_datapoint_button')}
              </Button>
            }
          </TabPanel>
          <TabPanel>
            <table className="widget-extras">
              <tbody>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.show_titlebar')}
                  </td>
                  <td>
                    <input
                      checked={settings.showTitlebar}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          showTitlebar: {
                            $set: !settings.showTitlebar,
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.show_unit')}
                  </td>
                  <td>
                    <input
                      checked={settings.showUnit}
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          showUnit: {
                            $set: e.target.checked,
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.unit_text')}
                  </td>
                  <td>
                    <input
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          unit_text: {
                            $set: e.target.value,
                          },
                        }),
                      })}
                      placeholder="Enter unit text"
                      type="text"
                      value={settings.unit_text}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.min')}
                  </td>
                  <td>
                    <input
                      className="small"
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          min: {
                            $set: e.target.value,
                          },
                        }),
                      })}
                      placeholder="Enter min value"
                      type="text"
                      value={settings.min}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.max')}
                  </td>
                  <td>
                    <input
                      className="small"
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          max: {
                            $set: e.target.value,
                          },
                        }),
                      })}
                      placeholder="Enter max value"
                      type="text"
                      value={settings.max}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.limit_to_min_max')}
                  </td>
                  <td>
                    <input
                      checked={settings.limitToMinMax}
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          limitToMinMax: {
                            $set: e.target.checked,
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    <label htmlFor="formatting">
                      {t('page_content.dashboards.edit_widget_modal.appearance_tab.value_formatting')}
                    </label>
                  </td>
                  <td className="input">
                    <Select
                      id="formatting"
                      className="AscaliaSelect"
                      onChange={(v) => {
                        if (!v) return;
                        let showDec = false;
                        const value = v.value;
                        if (value !== 'float') {
                          this.setState({
                            settings: update(settings, {
                              decimal_places: {
                                $set: null,
                              },
                            }),
                            showDecimal: showDec,
                          });
                        } else {
                          showDec = true;
                        }
                        this.setState({
                          settings: update(settings, {
                            format: {
                              $set: value,
                            },
                          }),
                          showDecimal: showDec,
                        });
                      }}
                      options={Object.values(filteredFormatTypes).map((formatType) => ({
                        label: formatType,
                        value: formatType,
                      }))}
                      value={settings.format ? Object.values(filteredFormatTypes).map((formatType) => ({
                        label: formatType,
                        value: formatType,
                      })).find((fT) => fT.value === settings.format) : null}
                    />
                  </td>
                </tr>
                {
                  settings.format === 'float' || showDecimal ?
                    <tr>
                      <td className="label">
                        <label htmlFor="decimal_places">
                          {t('page_content.dashboards.edit_widget_modal.appearance_tab.decimal_places')}
                        </label>
                      </td>
                      <td className="input">
                        <Select
                          id="decimal_places"
                          className="AscaliaSelect"
                          onChange={(v) => {
                            const value = v.value;
                            this.setState({
                              settings: update(settings, {
                                decimal_places: {
                                  $set: value,
                                },
                              }),
                            });
                          }}
                          options={decimalPlaces.map((dP) => ({
                            label: dP,
                            value: dP,
                          }))}
                          value={settings.decimal_places ? decimalPlaces.map((dP) => ({
                            label: dP,
                            value: dP,
                          })).find((dP) => dP.value === settings.decimal_places) : null}
                        />
                      </td>
                    </tr> : ''
                }
                <tr>
                  <td className="label">
                    <label htmlFor="side">
                      {t('page_content.dashboards.edit_widget_modal.appearance_tab.align_title_text')}
                    </label>
                  </td>
                  <td className="input">
                    <Select
                      id="side"
                      className="AscaliaSelect"
                      onChange={(v) => {
                        const value = v.value;
                        this.setState({
                          settings: update(settings, {
                            appearance: {
                              title: {
                                $set: value,
                              },
                            },
                          }),
                        });
                      }}
                      options={titleSides.map((dP) => ({
                        label: dP,
                        value: dP,
                      }))}
                      value={settings && settings.appearance && settings.appearance.title ? titleSides.map((dP) => ({
                        label: dP,
                        value: dP,
                      })).find((dP) => dP.value === settings.appearance.title) : null}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.threshold')}
                  </td>
                  <td>
                    <input
                      onChange={this.handleThresholdChange}
                      placeholder="Enter thresholds separated with comma"
                      type="text"
                      value={settings.threshold}
                    />
                  </td>
                </tr>
                {
                  settings.threshold && settings.threshold.length &&
                  <tr>
                    <td>
                      {t('page_content.dashboards.edit_widget_modal.appearance_tab.use_custom_colors')}
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => this.setState({
                          settings: update(settings, {
                            customColors: {
                              $set: e.target.checked,
                            },
                          }),
                        })}
                        checked={settings.customColors}
                      />
                    </td>
                  </tr>
                }
                {
                  settings.customColors &&
                  <tr>
                    <td>
                      {t('page_content.dashboards.edit_widget_modal.appearance_tab.colors')}
                    </td>
                    <td>
                      <div>
                        <AdvancedColorPicker
                          color={settings.colors[0]}
                          onColorUpdate={(color) => this.handleColorChange(0, color)}
                        />
                        {
                          settings.colors.map((x, i) => {
                            if (i === 0) {
                              return null;
                            }
                            return (
                              <AdvancedColorPicker
                                key={i}
                                color={settings.colors[i]}
                                onColorUpdate={(color) => this.handleColorChange(i, color)}
                              />
                            );
                          })
                        }
                      </div>
                    </td>
                  </tr>
                }
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.background_color')}
                  </td>
                  <td>
                    <SketchPicker
                      color={settings.appearance ? settings.appearance.backgroundColor : '#FFFFFF'}
                      onChangeComplete={(backgroundColor) => this.setState({
                        settings: update(settings, {
                          appearance: {
                            backgroundColor: {
                              $set: backgroundColor.hex,
                            },
                          },
                        }),
                      })}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
        <footer className="modal-footer">
          <Button
            onClick={this.saveWidget}
            title="Save widget config"
            type="success"
            disabled={
              (!settings.isOEE && widgetData.some((x) => !x.metric)) ||
              (settings && settings.isOEE && (!settings.all_items_metric || !settings.utilization_metric || !settings.max_items_per_min))
            }
          >
            {t('page_content.dashboards.edit_widget_modal.save_changes_button')}
          </Button>
          <Button
            onClick={this.props.closeModal}
          >
            {t('page_content.dashboards.edit_widget_modal.cancel_button')}
          </Button>
        </footer>
      </div>
    );
  }
}

GaugeForm.propTypes = {
  locationId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(GaugeForm);
