import axios from 'axios';

export const getData = ({
  from,
  to,
  axiosSource,
  operation,
  groupBy,
  id,
  limit,
  fetchEvents,
  adjusted_value,
  shift,
  productType,
}) => {
  const eventsQs = fetchEvents
    ? '&fetch_events=true'
    : '';

  if (adjusted_value && adjusted_value.length) {
    adjusted_value = adjusted_value.find((aV) => aV.metricId === id);
    if (adjusted_value) {
      adjusted_value = encodeURIComponent(adjusted_value.value);
    } else {
      adjusted_value = null;
    }
  }

  if (shift && shift.length) {
    shift = shift.find((s) => s.id === id);
    if (shift) {
      shift = `&shift=${parseInt(shift.value, 10)}`;
    } else {
      shift = '';
    }
  } else {
    shift = '';
  }

  if (productType && productType.length) {
    productType = productType.find((pT) => pT.id === id);
    if (productType) {
      productType = `&product_type=${parseInt(productType.value, 10)}`;
    } else {
      productType = '';
    }
  } else {
    productType = '';
  }

  return axios
    .get(`/api/v1/industry/datapoints/?group_by=${groupBy}&operation=${operation}&metric__in=${id}&from=${from}&to=${to}${eventsQs}&limit=${limit || ''}&adjusted_value=${adjusted_value}&format=csv${productType}${shift}`, {
      cancelToken: axiosSource.source,
    })
    .then((response) => response.data);
};

export const getProductionData = ({
  from,
  to,
  axiosSource,
  operation,
  groupBy,
  id,
  limit,
  fetchEvents,
}) => {
  const eventsQs = fetchEvents
    ? '&fetch_events=true'
    : '';

  return axios
    .get(`/api/v1/industry/production_data/?group_by=${groupBy}&operation=${operation}&metric__in=${id}&from=${from}&to=${to}${eventsQs}&limit=${limit || ''}&format=csv`, {
      cancelToken: axiosSource.source,
    })
    .then((response) => response.data);
};
