/* eslint-disable react/no-access-state-in-setstate */
import React, { Component, Fragment } from 'react';
import { Responsive } from 'react-grid-layout';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import update from 'immutability-helper';
import Tooltip from 'rc-tooltip';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Select from 'react-select';
import { SketchPicker } from 'react-color';
import sizeMe from 'react-sizeme';
import { formatTypes, usingVariablesTooltip } from 'shared/constants';
import { IconRemove, IconSortV, IconInfo } from 'shared/Icons';
import {
  Button,
} from 'shared';
import { sizeMeHack } from './../../WidgetGrid/helpers';
import DatapointForm from '../DatapointForm';
import CalculatedValueForm from '../CalculatedValueForm';
import CalculatedValueUsageForm from '../CalculatedValueUsageForm';

import { defaultSettings } from '../constants';
import '../styles.scss';

const ResponsiveReactGridLayout = sizeMe()(sizeMeHack(Responsive));

const colorIndex = [
  '#396ab1',
  '#da7c30',
  '#3e9651',
  '#cc2529',
  '#535154',
  '#6b4c9a',
  '#922428',
  '#8f8b3d',
];

class HistogramForm extends Component {
  constructor(props) {
    super(props);

    if (props.config.settings && !props.config.settings.annotation) {
      props.config.settings.annotation = {
        value: defaultSettings.histogram.annotation.value,
        name: defaultSettings.histogram.annotation.name,
        color: defaultSettings.histogram.annotation.color,
        thickness: defaultSettings.histogram.annotation.thickness,
        dashed: defaultSettings.histogram.annotation.dashed,
      };
    }

    const widget_data = props.config.widget_data.map((item) => { item.randId = Math.random(); return item; });
    const widgetData = widget_data.filter((wD) => !wD.style.isCalculatedValue && !wD.style.isCalculatedValueUsage);
    const calculatedWidgetData = widget_data.filter((wD) => wD.style.isCalculatedValue).map((item) => { item.randId = Math.random(); return item; }) || [];
    const calculatedUsageWidgetData = widget_data.find((wD) => wD.style.isCalculatedValueUsage);

    this.state = {
      id: props.config.id,
      widgetData,
      calculatedWidgetData,
      calculatedUsageWidgetData,
      settings: props.config.settings || defaultSettings.histogram,
      title: props.config.title || '',
      type: props.config.type,
    };
  }

  addWidgetDataItem = () => {
    const widgetData = this.state.widgetData.slice(0);
    widgetData.push({
      randId: Math.random(),
      metric: null,
      field: null,
      operation: 'avg',
      group_by: 'auto',
      style: {
        selectedField: null,
      },
    });
    this.setState({ widgetData });
  }

  addCalculatedWidgetDataItem = () => {
    const calculatedWidgetData = this.state.calculatedWidgetData.slice(0);
    calculatedWidgetData.push({
      randId: Math.random(),
      metric: null,
      field: null,
      style: {
        selectedField: null,
        alias: '',
        isCalculatedValue: true,
      },
      group_by: 'auto',
      operation: 'avg',
    });

    const calculatedValueUsageWidgetDataItemExists = calculatedWidgetData.find((wD) => wD.style.isCalculatedValueUsage);
    if (!calculatedValueUsageWidgetDataItemExists) {
      this.setState({
        calculatedWidgetData,
        calculatedUsageWidgetData: {
          metric: null,
          field: null,
          style: {
            selectedField: null,
            alias: '',
            isCalculatedValueUsage: true,
          },
          group_by: 'auto',
          operation: 'avg',
        },
      });
    } else {
      this.setState({ calculatedWidgetData });
    }
  }

  updateWidgetDataItem = (numberInArray, widgetDataItem) => {
    const widgetData = this.state.widgetData.slice(0);
    widgetData[numberInArray] = widgetDataItem;
    this.setState({
      widgetData,
    });
  }

  updateCalculatedWidgetDataItem = (numberInArray, widgetDataItem) => {
    const calculatedWidgetData = this.state.calculatedWidgetData.slice(0);
    calculatedWidgetData[numberInArray] = widgetDataItem;
    this.setState({
      calculatedWidgetData,
    });
  }

  updateCalculatedUsageWidgetDataItem = (calculatedUsageWidgetData) => {
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  updateAdjustedValue = (val, metricId, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.adjusted_value)) {
      settings.adjusted_value = [];
    }
    const existingValue = settings.adjusted_value.find((aV) => aV.metricId === metricId && aV.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.adjusted_value.push({ value: val, metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateAdjustedValueForCalc = (val) => {
    const {
      calculatedUsageWidgetData,
    } = this.state;

    calculatedUsageWidgetData.style.adjusted_value = val;
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  updateShiftFilter = (val, id, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.shift)) {
      settings.shift = [];
    }
    const existingValue = settings.shift.find((w) => w.id === id && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.shift.push({ value: val, id, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateShiftFilterForCalc = (val) => {
    const {
      calculatedUsageWidgetData,
    } = this.state;

    calculatedUsageWidgetData.style.shift = val;
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  updateProductTypeFilterForCalc = (val) => {
    const {
      calculatedUsageWidgetData,
    } = this.state;

    calculatedUsageWidgetData.style.productType = val;
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  updateProductTypeFilter = (val, id, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.productType)) {
      settings.productType = [];
    }
    const existingValue = settings.productType.find((w) => w.id === id && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.productType.push({ value: val, id, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateWhereFilter = (val, metricId, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.where)) {
      settings.where = [];
    }
    const existingValue = settings.where.find((w) => w.metricId === metricId && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.where.push({ value: val, metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateWhereFilterForCalc = (val) => {
    const {
      calculatedUsageWidgetData,
    } = this.state;

    calculatedUsageWidgetData.style.where = val;
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  removeWidgetDataItem = (elementRandomId) => {
    const widgetData = this.state.widgetData.slice(0);
    const filteredWidgetData = widgetData.filter((widgetItem) => {
      return widgetItem.randId !== elementRandomId;
    });
    // widgetData.splice(numberInArray, 1);
    this.setState({
      widgetData: filteredWidgetData,
    });
  }

  removeCalculatedWidgetDataItem = (elementRandomId) => {
    const {
      calculatedWidgetData,
    } = this.state;

    const newCalculatedWidgetData = calculatedWidgetData.slice(0);
    const filteredCalculatedWidgetData = newCalculatedWidgetData.filter((widgetItem) => {
      return widgetItem.randId !== elementRandomId;
    });
    // newCalculatedWidgetData.splice(numberInArray, 1);

    if (!filteredCalculatedWidgetData || !filteredCalculatedWidgetData.length) {
      this.setState({
        calculatedUsageWidgetData: null,
        calculatedWidgetData: filteredCalculatedWidgetData,
      });
    } else {
      this.setState({
        calculatedWidgetData: filteredCalculatedWidgetData,
      });
    }
  }

  saveWidget = () => {
    const {
      widgetData,
      calculatedWidgetData,
      calculatedUsageWidgetData,
    } = this.state;

    let calcMetricsOK = true;
    if (calculatedWidgetData && calculatedWidgetData.length) {
      calculatedWidgetData.forEach((cWD) => {
        if (!cWD.metric) {
          calcMetricsOK = false;
        }
      });
    }

    if (!calcMetricsOK) {
      alert('All calculated values must have a selected source and metric.');
      return;
    }

    const widget_data = widgetData.map((x) => {
      if (x.metric && x.metric.id) {
        x.metric = x.metric.id;
      }
      if (Object.prototype.hasOwnProperty.call(x, 'field')) {
        delete x.field;
      }
      return x;
    });

    const cleanedCalculatedWidgetData = calculatedWidgetData.map((x) => {
      if (x.metric && x.metric.id) {
        x.metric = x.metric.id;
      }
      if (Object.prototype.hasOwnProperty.call(x, 'field')) {
        delete x.field;
      }
      return x;
    });

    if (calculatedUsageWidgetData) {
      if (Object.prototype.hasOwnProperty.call(calculatedUsageWidgetData, 'field')) {
        delete calculatedUsageWidgetData.field;
      }
      delete calculatedUsageWidgetData.metric;

      widget_data.push(calculatedUsageWidgetData);
    }

    const data = {
      widget_data: widget_data.concat(cleanedCalculatedWidgetData),
      id: this.state.id,
      title: this.state.title,
      settings: this.state.settings,
    };
    this.props.onSave(data);
  }

  updateColor = (color, widgetDataItem) => {
    const newWidgetData = this.state.widgetData;

    if (!widgetDataItem.style.isCalculatedValue && !widgetDataItem.style.isCalculatedValueUsage) {
      const objIndex = newWidgetData.findIndex(((obj) => obj.id === widgetDataItem.id));
      newWidgetData[objIndex].style.color = color.rgb;

      this.setState({
        widgetData: newWidgetData,
      });
    } else {
      this.updateCalculatedColor(color);
    }
  }

  updateCalculatedColor = (color) => {
    const newWidgetData = this.state.calculatedUsageWidgetData;

    newWidgetData.style.color = color.rgb;

    this.setState({
      calculatedUsageWidgetData: newWidgetData,
    });
  }

  addBucket = () => {
    const { settings } = this.state;
    if (!Array.isArray(settings.buckets)) {
      settings.buckets = [];
    }
    settings.buckets.push({
      randId: Math.random(),
      from: 0,
      to: 0,
    });
    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateBucket = (bucketId, bucketKey, bucketValue) => {
    const { buckets } = this.state.settings;
    const bucketIdx = buckets.findIndex((w) => w.randId === bucketId);
    if (bucketIdx >= 0) {
      buckets[bucketIdx][bucketKey] = bucketValue;
    }
    this.setState({
      settings: update(this.state.settings, {
        buckets: {
          $set: buckets,
        },
      }),
    });
  }

  removeBucket = (bucketId) => {
    const buckets = this.state.settings.buckets.slice(0);
    const filteredBuckets = buckets.filter((bucketItem) => {
      return bucketItem.randId !== bucketId;
    });
    this.setState({
      settings: update(this.state.settings, {
        buckets: {
          $set: filteredBuckets,
        },
      }),
    });
  }

  reorderBuckets = (layout) => {
    const { buckets } = this.state.settings;
    layout.sort((a, b) => a.y - b.y);
    const updatedBuckets = layout.map((layoutItem) => {
      return buckets.find((x) => x.randId === parseFloat(layoutItem.i));
    });
    this.setState({
      settings: update(this.state.settings, {
        buckets: {
          $set: updatedBuckets,
        },
      }),
    });
  }

  hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: 1.0,
    } : null;
  }

  updateDepartmentFilter = (value, metricId, indexData) => {
    const { settings } = this.state;
    if (!value) {
      if (Array.isArray(settings?.department)) {
        settings.department = settings.department.filter((d) => !(d.id === metricId && d.indexData === indexData));
      }
      if (Array.isArray(settings?.workOperation)) {
        settings.workOperation = settings.workOperation.filter((d) => !(d.id === metricId && d.indexData === indexData));
      }
      this.setState({
        settings: JSON.parse(JSON.stringify(settings)),
      });
      return;
    }
    if (!Array.isArray(settings.department)) {
      settings.department = [];
    }
    const existingValue = settings.department.find((w) => w.id === metricId && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = value;
    } else {
      settings.department.push({ value, id: metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateWorkOperationsFilter = (value, metricId, indexData) => {
    const { settings } = this.state;
    if (!value) {
      if (Array.isArray(settings?.workOperation)) {
        settings.workOperation = settings.workOperation.filter((d) => !(d.id === metricId && d.indexData === indexData));
      }
      this.setState({
        settings: JSON.parse(JSON.stringify(settings)),
      });
      return;
    }
    if (!Array.isArray(settings.workOperation)) {
      settings.workOperation = [];
    }
    const existingValue = settings.workOperation.find((w) => w.id === metricId && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = value;
    } else {
      settings.workOperation.push({ value, id: metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  render() {
    const {
      widgetData,
      calculatedWidgetData,
      calculatedUsageWidgetData,
      settings,
      title,
      type,
    } = this.state;

    const colorWd = [...widgetData, calculatedUsageWidgetData].filter((el) => {
      return el != null;
    });

    const { locationId, t } = this.props;
    const sides = ['left', 'right'];
    const titleSides = ['left', 'center', 'right'];

    const filteredFormatTypes = { ...formatTypes };

    delete filteredFormatTypes.date;

    return (
      <div className="default-form">
        <table className="widget-title">
          <tr>
            <td>
              <label htmlFor="widget-title">
                {t('page_content.dashboards.edit_widget_modal.widget_title')}
              </label>
            </td>
            <td style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <input
                id="widget-title"
                onChange={(e) => this.setState({ title: e.target.value })}
                placeholder={t('page_content.dashboards.edit_widget_modal.widget_title_placeholder')}
                type="text"
                value={title}
              />
              <Tooltip
                id="tooltip-zoom"
                trigger={['hover']}
                placement="left"
                overlay={usingVariablesTooltip(t)}
                overlayClassName="where-filter-tooltip"
              >
                <span aria-describedby="tooltip-zoom">
                  <IconInfo color="#2e86de" height="15px" width="17px" />
                </span>
              </Tooltip>
            </td>
          </tr>
        </table>
        <Tabs>
          <TabList>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.datapoints')}
            </Tab>
            <Tab>
              Calculated values
            </Tab>
            <Tab>
              Buckets
            </Tab>
            <Tab>
              Axes
            </Tab>
            <Tab>
              Legend
            </Tab>
            <Tab>
              Appearance
            </Tab>
            <Tab>
              Annotation
            </Tab>
          </TabList>
          <TabPanel>
            {
              widgetData.map((widgetDataItem, i) => (
                <div key={`${widgetDataItem.randId}`} data-reactid={`${widgetDataItem.randId}`} className="datapoint-container">
                  <DatapointForm
                    type={type}
                    key={`widget-data-item-${widgetDataItem.randId}`}
                    indexData={i}
                    datapoint={widgetDataItem}
                    locationId={locationId}
                    onChange={(item) => this.updateWidgetDataItem(i, item)}
                    adjustedValue={Array.isArray(settings.adjusted_value) ? settings.adjusted_value.find((aV) => { if (widgetDataItem.metric) { return aV.metricId === widgetDataItem.metric.id && aV.indexData === i; } return false; }) : null}
                    updateAdjustedValue={(val, metricId, indexData) => this.updateAdjustedValue(val, metricId, indexData)}
                    where={Array.isArray(settings.where) ? settings.where.find((w) => { if (widgetDataItem.metric) { return w.metricId === widgetDataItem.metric.id && w.indexData === i; } return false; }) : null}
                    updateWhereFilter={(val, metricId, indexData) => this.updateWhereFilter(val, metricId, indexData)}
                    shift={Array.isArray(settings.shift) ? settings.shift.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id && w.indexData === i; } return false; }) : null}
                    updateShiftFilter={(val, id, indexData) => this.updateShiftFilter(val, id, indexData)}
                    productType={Array.isArray(settings.productType) ? settings.productType.find((w) => { if (widgetDataItem.metric) { return (w.id === widgetDataItem.metric.id && w.indexData === i); } return false; }) : null}
                    updateProductTypeFilter={(val, id, indexData) => this.updateProductTypeFilter(val, id, indexData)}
                    updateDepartmentFilter={(value, metricId, indexData) => this.updateDepartmentFilter(value, metricId, indexData)}
                    department={Array.isArray(settings.department) ? settings.department.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id; } return false; }) : null}
                    updateWorkOperationsFilter={(value, metricId, indexData) => this.updateWorkOperationsFilter(value, metricId, indexData)}
                    workOperation={Array.isArray(settings.workOperation) ? settings.workOperation.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id; } return false; }) : null}
                  />
                  <Button
                    onClick={() => this.removeWidgetDataItem(widgetDataItem.randId)}
                    size="small"
                    type="delete"
                  >
                    <IconRemove
                      height="14px"
                      width="14px"
                    />
                  </Button>
                </div>
              ))
            }
            {
              widgetData.length < 10 &&
              <Button
                onClick={this.addWidgetDataItem}
                type="add"
              >
                {t('page_content.dashboards.edit_widget_modal.datapoints_tab.new_datapoint_button')}
              </Button>
            }
          </TabPanel>
          <TabPanel>
            {
              calculatedWidgetData.map((widgetDataItem, i) => (
                <div key={`${widgetDataItem.randId}`} data-reactid={`${widgetDataItem.randId}`} className="datapoint-container">
                  <CalculatedValueForm
                    key={`calc-val-${widgetDataItem.randId}`}
                    datapoint={widgetDataItem}
                    onChange={(item) => this.updateCalculatedWidgetDataItem(i, item)}
                    locationId={locationId}
                    alias={widgetDataItem.style.alias}
                  />
                  <Button
                    onClick={() => this.removeCalculatedWidgetDataItem(widgetDataItem.randId)}
                    size="small"
                    type="delete"
                  >
                    <IconRemove
                      height="14px"
                      width="14px"
                    />
                  </Button>
                </div>
              ))
            }
            {
              calculatedWidgetData.length <= 15 &&
              <Button
                onClick={this.addCalculatedWidgetDataItem}
                type="add"
              >
                New calculated value
              </Button>
            }
            {
              calculatedWidgetData.length > 0 &&
              <CalculatedValueUsageForm
                datapoint={calculatedUsageWidgetData}
                onChange={(item) => this.updateCalculatedUsageWidgetDataItem(item)}
                locationId={locationId}
                adjustedValue={calculatedUsageWidgetData.style.adjusted_value}
                updateAdjustedValue={(val) => this.updateAdjustedValueForCalc(val)}
                where={calculatedUsageWidgetData.style.where}
                updateWhereFilter={(val) => this.updateWhereFilterForCalc(val)}
                shift={calculatedUsageWidgetData.style.shift}
                updateShiftFilter={(val) => this.updateShiftFilterForCalc(val)}
                productType={calculatedUsageWidgetData.style.productType}
                updateProductTypeFilter={(val) => this.updateProductTypeFilterForCalc(val)}
                gapFill={calculatedUsageWidgetData.style.gapFill}
                updateGapFill={(val) => this.updateGapFillForCalc(val)}
              />
            }
          </TabPanel>
          <TabPanel>
            <h4>Buckets</h4>
            <table>
              <ResponsiveReactGridLayout
                isDraggable
                isBounded
                isResizable={false}
                cols={{ lg: 1, md: 1, sm: 1, xs: 1, xxs: 1 }}
                rowHeight={40}
                margin={[0, 0]}
                containerPadding={[0, 0]}
                className="buckets-container"
                draggableHandle=".bucket-handle"
                onLayoutChange={this.reorderBuckets}
              >
                {
                  settings.buckets && settings.buckets.map((bucketItem) => (
                    <tr
                      key={`${bucketItem.randId}`}
                      data-reactid={`${bucketItem.randId}`}
                      className="bucket-container"
                      style={{ boxShadow: '0 0 2px rgba(0,0,0,.2)' }}
                    >
                      <td className="label bucket-handle" style={{ width: '40px', padding: '0 10px', cursor: 'move' }}>
                        <IconSortV
                          color="#aaa"
                          height="20px"
                          width="20px"
                        />
                      </td>
                      <td className="label">
                        <label htmlFor="from" style={{ fontSize: '12px', fontWeight: 600 }}>Value &gt;= </label>
                      </td>
                      <td className="input">
                        <input type="number" value={bucketItem.from} onChange={(e) => { this.updateBucket(bucketItem.randId, 'from', e.target.value); }} />
                      </td>
                      <td className="label">
                        <label htmlFor="to" style={{ fontSize: '12px', fontWeight: 600, padding: '0 10px' }}> and &lt;= </label>
                      </td>
                      <td className="input">
                        <input type="number" value={bucketItem.to} onChange={(e) => { this.updateBucket(bucketItem.randId, 'to', e.target.value); }} />
                      </td>
                      <td>
                        <Button
                          onClick={() => this.removeBucket(bucketItem.randId)}
                          size="small"
                          type="delete"
                        >
                          <IconRemove
                            height="14px"
                            width="14px"
                          />
                        </Button>
                      </td>
                    </tr>
                  ))
                }
              </ResponsiveReactGridLayout>
            </table>
            <Button
              onClick={this.addBucket}
              type="add"
            >
              New Bucket
            </Button>
          </TabPanel>
          <TabPanel>
            <h4>
              {t('page_content.dashboards.edit_widget_modal.axes_tab.x_axis')}
            </h4>
            <table>
              <tbody>
                <tr>
                  <td>
                    Show
                  </td>
                  <td>
                    <input
                      checked={settings.axis.x.show}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          axis: {
                            x: {
                              show: {
                                $set: !settings.axis.x.show,
                              },
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <h4>
              {t('page_content.dashboards.edit_widget_modal.axes_tab.y_axis')}
            </h4>
            <table className="widget-extras">
              <tbody>
                <tr>
                  <td className="label">
                    <label htmlFor="side">
                      Side
                    </label>
                  </td>
                  <td className="input">
                    <Select
                      id="side"
                      className="AscaliaSelect"
                      onChange={(v) => {
                        const value = v.value;
                        this.setState({
                          settings: update(settings, {
                            axis: {
                              side: {
                                $set: value,
                              },
                            },
                          }),
                        });
                      }}
                      options={sides.map((dP) => ({
                        label: dP,
                        value: dP,
                      }))}
                      value={settings.axis.side ? sides.map((dP) => ({
                        label: dP,
                        value: dP,
                      })).find((dP) => dP.value === settings.axis.side) : null}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Show
                  </td>
                  <td>
                    <input
                      checked={settings.axis.y1.show}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          axis: {
                            y1: {
                              show: {
                                $set: !settings.axis.y1.show,
                              },
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                {
                  settings.axis.y1.show &&
                  <Fragment>
                    <tr>
                      <td>
                        Min value
                      </td>
                      <td>
                        <input
                          onChange={(e) => this.setState({
                            settings: update(settings, {
                              axis: {
                                y1: {
                                  min: {
                                    $set: e.target.value,
                                  },
                                },
                              },
                            }),
                          })}
                          type="text"
                          value={settings.axis.y1.min}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Max value
                      </td>
                      <td>
                        <input
                          onChange={(e) => this.setState({
                            settings: update(settings, {
                              axis: {
                                y1: {
                                  max: {
                                    $set: e.target.value,
                                  },
                                },
                              },
                            }),
                          })}
                          type="text"
                          value={settings.axis.y1.max}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Label
                      </td>
                      <td>
                        <input
                          onChange={(e) => this.setState({
                            settings: update(settings, {
                              axis: {
                                y1: {
                                  label: {
                                    $set: e.target.value,
                                  },
                                },
                              },
                            }),
                          })}
                          type="text"
                          value={settings.axis.y1.label}
                        />
                      </td>
                    </tr>
                  </Fragment>
                }
              </tbody>
            </table>
            {
              false &&
              <Fragment>
                <h4>
                  Right Y Axis
                </h4>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        Show
                      </td>
                      <td>
                        <input
                          checked={settings.axis.y2.show}
                          onChange={() => this.setState({
                            settings: update(settings, {
                              axis: {
                                y2: {
                                  show: {
                                    $set: !settings.axis.y2.show,
                                  },
                                },
                              },
                            }),
                          })}
                          type="checkbox"
                        />
                      </td>
                    </tr>
                    {
                      settings.axis.y2.show &&
                      <Fragment>
                        <tr>
                          <td>
                            Min value
                          </td>
                          <td>
                            <input
                              onChange={(e) => this.setState({
                                settings: update(settings, {
                                  axis: {
                                    y2: {
                                      min: {
                                        $set: e.target.value,
                                      },
                                    },
                                  },
                                }),
                              })}
                              type="text"
                              value={settings.axis.y2.min}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Max value
                          </td>
                          <td>
                            <input
                              onChange={(e) => this.setState({
                                settings: update(settings, {
                                  axis: {
                                    y2: {
                                      max: {
                                        $set: e.target.value,
                                      },
                                    },
                                  },
                                }),
                              })}
                              type="text"
                              value={settings.axis.y2.max}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Label
                          </td>
                          <td>
                            <input
                              onChange={(e) => this.setState({
                                settings: update(settings, {
                                  axis: {
                                    y2: {
                                      label: {
                                        $set: e.target.value,
                                      },
                                    },
                                  },
                                }),
                              })}
                              type="text"
                              value={settings.axis.y2.label}
                            />
                          </td>
                        </tr>
                      </Fragment>
                    }
                  </tbody>
                </table>
              </Fragment>
            }
          </TabPanel>
          <TabPanel>
            <table>
              <tbody>
                <tr>
                  <td>
                    Show legend
                  </td>
                  <td>
                    <input
                      checked={settings.legend.show}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          legend: {
                            show: {
                              $set: !settings.legend.show,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table className="widget-extras">
              <tbody>
                <tr>
                  <td>
                    Show grid
                  </td>
                  <td>
                    <input
                      checked={settings.appearance.grid}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          appearance: {
                            grid: {
                              $set: !settings.appearance.grid,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Show tooltip
                  </td>
                  <td>
                    <input
                      checked={settings.appearance.tooltip}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          appearance: {
                            tooltip: {
                              $set: !settings.appearance.tooltip,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Stack bars
                  </td>
                  <td>
                    <input
                      checked={settings.appearance.stack_bars}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          appearance: {
                            stack_bars: {
                              $set: !settings.appearance.stack_bars,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    <label htmlFor="side">
                      Align title text
                    </label>
                  </td>
                  <td className="input">
                    <Select
                      id="side"
                      className="AscaliaSelect"
                      onChange={(v) => {
                        const value = v.value;
                        this.setState({
                          settings: update(settings, {
                            appearance: {
                              title: {
                                $set: value,
                              },
                            },
                          }),
                        });
                      }}
                      options={titleSides.map((dP) => ({
                        label: dP,
                        value: dP,
                      }))}
                      value={settings.appearance.title ? titleSides.map((dP) => ({
                        label: dP,
                        value: dP,
                      })).find((dP) => dP.value === settings.appearance.title) : null}
                    />
                  </td>
                </tr>
                {
                  (colorWd && colorWd.length !== 0) ?
                    <tr>
                      <td>
                        Datapoints
                      </td>
                      {colorWd.sort((a, b) => { return a.id - b.id; }).map((widgetDataItem, index) => (
                        <tr>
                          <td style={{ width: '50%' }}>{widgetDataItem.label}</td>
                          <td>
                            <SketchPicker
                              color={
                                (widgetDataItem && widgetDataItem.style && widgetDataItem.style.color) ? widgetDataItem.style.color :
                                  widgetDataItem.style && widgetDataItem.style.isCalculatedValueUsage ? '#2ecc71' :
                                    this.hexToRgb(colorIndex[index])
                              }
                              onChangeComplete={(color) => this.updateColor(color, widgetDataItem)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tr> : ''
                }
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table>
              <tbody>
                <tr>
                  <td>
                    Name
                  </td>
                  <td>
                    <input
                      value={settings.annotation ? settings.annotation.name : null}
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          annotation: {
                            name: {
                              $set: e.target.value,
                            },
                          },
                        }),
                      })}
                      type="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Value
                  </td>
                  <td>
                    <input
                      value={settings.annotation ? settings.annotation.value : null}
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          annotation: {
                            value: {
                              $set: e.target.value,
                            },
                          },
                        }),
                      })}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Line color
                  </td>
                  <td>
                    <SketchPicker
                      color={settings.annotation ? settings.annotation.color : '#3182bd'}
                      onChangeComplete={(color) => this.setState({
                        settings: update(settings, {
                          annotation: {
                            color: {
                              $set: color.hex,
                            },
                          },
                        }),
                      })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Line thickness
                  </td>
                  <td>
                    <input
                      value={settings.annotation ? settings.annotation.thickness : null}
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          annotation: {
                            thickness: {
                              $set: e.target.value,
                            },
                          },
                        }),
                      })}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Dashed line
                  </td>
                  <td>
                    <input
                      checked={settings.annotation ? settings.annotation.dashed : null}
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          annotation: {
                            dashed: {
                              $set: e.target.checked,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
        <footer className="modal-footer">
          <Button
            onClick={this.saveWidget}
            title="Save widget config"
            type="success"
            disabled={!widgetData.length && !calculatedWidgetData.length}
          >
            {t('page_content.dashboards.edit_widget_modal.save_changes_button')}
          </Button>
          <Button
            onClick={this.props.closeModal}
          >
            {t('page_content.dashboards.edit_widget_modal.cancel_button')}
          </Button>
        </footer>
      </div>
    );
  }
}

HistogramForm.propTypes = {
  config: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
};

export default withTranslation()(HistogramForm);
