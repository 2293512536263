import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const NumberQuestion = ({ t, question, index, handleChangeSetRange, handleChangeNumberLimit }) => {
  return (
    <div className="number_question_range">
    <div className="number_set_range">
        {t('page_content.human_resources.forms.set_range')}
      <input
        type="checkbox"
        checked={question?.set_range}
        onChange={(e) => handleChangeSetRange(e.target.checked, index)}
      />
    </div>
    {question?.set_range &&
        <div className="number_set_range-inputs">
            Min
            <input
              type="number"
              value={question?.number_limit_from}
              onChange={(e) => handleChangeNumberLimit(e.target.value, 'number_limit_from', index)}
            />
            Max
            <input
              type="number"
              value={question?.number_limit_to}
              onChange={(e) => handleChangeNumberLimit(e.target.value, 'number_limit_to', index)}
            />
        </div>}
    </div>
  );
};

NumberQuestion.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  index: PropTypes.number,
  handleChangeSetRange: PropTypes.func,
  handleChangeNumberLimit: PropTypes.func,
};

export default withTranslation()(NumberQuestion);
