import React from 'react';
import Base from './Base';

export default (props) => (<Base viewBox="0 0 100 50" {...props}>
  <path
    d="M2.5,23.1c0-0.2,0-0.5,0.1-0.7C2.7,21.8,3,21.3,3.4,21c25.7-25.7,67.3-25.7,93,0c1.1,1,1.1,3.1-0.1,4.1
c-1,0.9-2.8,0.8-3.7-0.2C69,1.2,30.9,1.2,7.3,24.8C5.9,26.5,2.5,25.3,2.5,23.1L2.5,23.1z M13.1,33.6c0-0.1,0.1-0.2,0.1-0.3
c0.1-0.6,0.5-1.1,1-1.4C33.9,12.1,66,12,85.8,31.7c1.1,1,0.9,3-0.2,3.9c-1,0.9-2.7,0.8-3.6-0.1c-17.7-17.7-46.3-17.7-64,0
C16.6,37.6,12.7,36,13.1,33.6L13.1,33.6z M24.5,44.5c0.1-0.6,0.4-1.2,0.8-1.7c13.5-13.5,35.7-13.5,49.2,0c1.1,1,1.1,2.9,0,4
c-1,1-2.9,0.9-3.8-0.1c-11.5-11.5-30-11.5-41.5,0C27.6,48.4,24.2,46.8,24.5,44.5L24.5,44.5z"
  />
</Base>);
