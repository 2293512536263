import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props}>
  <g>
    <path
      d="M28.5,30.9h-6.6v-6.6h6.6V30.9z M78.1,24.9h-46v5.5h46V24.9z M28.5,39.7h-6.6v6.6h6.6V39.7z M78.1,40.3h-46v5.5
h46V40.3z M28.5,55.2h-6.6v6.6h6.6V55.2z M78.1,55.7h-46v5.5h46V55.7z M28.5,70.6h-6.6v6.6h6.6V70.6z M78.1,71.1h-46v5.5h46V71.1z "
    />
  </g>
</Base>);
