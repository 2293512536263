import React, { Component } from 'react';
import Tooltip from 'rc-tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Select from 'react-select';

import { Table, ContentLoader, Button, ConfirmationModal } from 'shared';
import { IconRemove, IconEdit, IconSearch } from 'shared/Icons';
import { selectStyles } from 'styles/modules/reactSelect';
import { numberSeparatorFormat } from 'industry/helpers';
import {
  getOrderItemHistory,
  getLocationDetails,
  saveOrder,
  editProductType,
  deleteOrderGroupItem,
  exportBom,
} from '../../actions';
import { getProductDepartments } from '../../../IndustryProjectDetails/actions';
import OrderGroupItemModal from './components/OrderGroupItemModal';
import ImportBomModal from './components/ImportBomModal';
import OrderGroupsModal from './components/OrderGroupsModal';
import CreateOrderModal from './components/CreateOrderModal';
import GroupInfoModal from './components/GroupInfoModal';
import EditERPModal from './components/EditERPModal';

class OrderGroups extends Component {
  constructor() {
    super();
    this.timerRef = React.createRef();
    this.state = {
      isLoading: false,
      isOrderGroupsModalOpen: false,
      isLoadingOrderGroupsModal: true,
      output: null,
      orderItemHistory: [],
      selectedParts: [],
      locationConfig: {},
      isCreateOrderModalOpen: false,
      departments: [],
      technologies: [],
      groupInfoModalStatus: false,
      groupInfoModalData: {},
      groupInfoModalTitle: '',
      isEditGroupItemModalOpen: false,
      selectedGroupItem: {},
      itemNameQuery: '',
      departmentsFilter: '',
      technologiesFilter: '',
      instancedGroups: [],
      filteredGroups: [],
      isLoadingExportItems: false,
      isLoadingImportBom: false,
      isImportBomModalOpen: false,
      isEditERPModalOpen: false,
      groupSelectedParts: [],
      groupSelectedPartsObj: [],
      showConfirmationDialog: false,
      deleteData: {},
    };
  }

  componentDidMount() {
    const { locationId, order } = this.props;
    if (locationId) {
      this.getLocationDetails(locationId);
      this.fetchProductDepartments(locationId);
      this.prepareGroupsInstance(order);
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.order) !== JSON.stringify(this.props.order)) {
      this.prepareGroupsInstance(this.props.order);
    }
  }

  getLocationDetails = (locationId) => {
    this.setState({ isLoading: true });

    getLocationDetails(locationId)
      .then(({ data }) => {
        this.setState({
          locationConfig: data.config,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  getOrderGroupNameById = (idToFind) => {
    const groups = this.props?.order?.groups || [];

    if (idToFind) {
      if (typeof idToFind === 'number' || typeof idToFind === 'string') {
        return ((obj) => obj && obj.name)(groups.find((obj) => obj.id === idToFind));
      }
    } else {
      return '';
    }
  }

  fetchProductDepartments = (locationId) => {
    this.setState({ isLoading: true });
    getProductDepartments(locationId)
      .then((re) => {
        this.setState({
          departments: get(re, 'data.results') || [],
        }, () => {
          const { departments } = this.state;
          const technologiesArray = [];
          departments.forEach((department) => {
            // eslint-disable-next-line no-prototype-builtins
            if (department.hasOwnProperty('technologies') && Array.isArray(department.technologies)) {
              department.technologies.forEach((technology) => {
                const isDuplicate = technologiesArray.some((existingTechnology) => existingTechnology.id === technology.id);
                if (!isDuplicate) {
                  technologiesArray.push(technology);
                }
              });
            }
          });
          this.setState({ technologies: technologiesArray, isLoading: false });
        });
      })
      .catch((error) => console.log(error));
  }

  prepareGroupsInstance = (order) => {
    this.setState({
      instancedGroups: order && order.groups,
    }, () => {
      this.handleFilterChange();
    });
  };

  openOrderGroupsModal = (rowInfo) => {
    const { getOrderId } = this.props;
    this.setState({
      isOrderGroupsModalOpen: true,
    });
    const itemId = rowInfo.original.id;
    const orderId = getOrderId();
    getOrderItemHistory(orderId, itemId)
      .then((res) => {
        this.setState({
          isLoadingOrderGroupsModal: false,
          output: (rowInfo && rowInfo.original && rowInfo.original.values) ? rowInfo.original.values : null,
          orderItemHistory: get(res, 'data.results') || [],
        });
      })
      .catch((e) => console.error('Error while fetching item history', e));
  }

  closeOrderGroupsModal = () => {
    this.setState({
      isOrderGroupsModalOpen: false,
      isLoadingOrderGroupsModal: true,
    });
  }

  openCreateOrderModal = (group, selectedPartsObj) => {
    const currentGroup = selectedPartsObj.map((p) => group.items.find((item) => item.id === p.id));
    const currentGroupParts = this.state.selectedParts.map((p) => group.items.find((item) => item.id === p));
    const filteredGroup = currentGroup.filter((g) => g !== undefined);
    const filteredGroupParts = currentGroupParts.filter((g) => g !== undefined).map((g) => g.id);
    this.setState({ isCreateOrderModalOpen: true, groupSelectedPartsObj: filteredGroup, groupSelectedParts: filteredGroupParts });
  }

  closeCreateOrderModal = () => {
    this.setState({ isCreateOrderModalOpen: false });
  }

  multipleSelectParts = (e, partsArray) => {
    const { selectedParts } = this.state;
    let selectedIdsPool = [...selectedParts];

    if (e.target.checked === true) {
      for (const productItem of partsArray) {
        if (!selectedIdsPool.includes(productItem.id)) {
          selectedIdsPool.push(productItem.id);
        }
      }
    } else {
      for (const part of partsArray) {
        selectedIdsPool = selectedIdsPool.filter((x) => parseInt(x, 10) !== part.id);
      }
    }
    this.setState({ selectedParts: selectedIdsPool });
  }

  selectPart = (e) => {
    e.stopPropagation();

    const { selectedParts } = this.state;
    let selectedPartsNew = selectedParts;
    const partId = parseInt(e.target.value, 10);
    if (e.target.checked === true) {
      if (selectedPartsNew.includes(partId) === false) {
        selectedPartsNew.push(partId);
      }
    } else {
      selectedPartsNew = selectedPartsNew.filter((x) => parseInt(x, 10) !== partId);
    }
    this.setState({ selectedParts: selectedPartsNew });
  }

  deselectPart = (partId) => {
    const { selectedParts } = this.state;
    let selectedPartsNew = selectedParts;
    selectedPartsNew = selectedPartsNew.filter((x) => parseInt(x, 10) !== partId);
    this.setState({
      selectedParts: selectedPartsNew,
    });
    if (document.querySelector(`input[value="${partId}"]`) !== undefined) {
      document.querySelector(`input[value="${partId}"]`).checked = false;
    }
  }

  createOrder = async (data, isWMSOrder = false) => {
    const { groupSelectedParts, selectedParts } = this.state;
    const {
      companyId,
      order,
      refreshOrderTabs,
    } = this.props;

    let payload = null;

    if (isWMSOrder) {
      payload = data;
    } else {
      const outputArray = [];

      for (const inputObject of data) {
        const selectedPartsProduct = inputObject.product_type.id || null;
        const selectedPartsQuantity = inputObject.quantity || null;
        const newObject = { product: selectedPartsProduct, quantity: selectedPartsQuantity };
        outputArray.push(newObject);
      }

      payload = {
        project: order.project || null,
        company: companyId,
        order: order.id || null,
        items: groupSelectedParts || [],
        products: outputArray,
      };
    }

    await saveOrder(payload);
    await refreshOrderTabs();
    const filterSelectedParts = selectedParts.filter((p) => groupSelectedParts.find((g) => g !== p));
    this.setState({
      selectedParts: filterSelectedParts,
    });
  }

  handleProductTypeChange = (id, payload) => {
    const { companyId, refreshOrderTabs } = this.props;
    editProductType(companyId, id, payload)
      .then(() => refreshOrderTabs());
  }

  handleGroupInfoModalOpen = () => {
    this.setState({
      groupInfoModalStatus: true,
    });
  }

  handleGroupInfoModalClose = () => {
    this.setState({
      groupInfoModalStatus: false,
      groupInfoModalData: [],
    });
  }

  handleERPChange = (id, payload) => {
    const { companyId } = this.props;

    return editProductType(companyId, id, payload)
      .then(() => {
        this.props.refreshOrderTabs();
      })
      .catch(() => {
        this.closeEditERPModal();
      });
  };

  handleEditERP = (e, row) => {
    const { isReadOnly } = this.props;

    e.stopPropagation();

    if (!isReadOnly) {
      this.setState({
        selectedERPItem: get(row, 'original', null),
        isEditERPModalOpen: true,
      });
    }
  }

  closeEditERPModal = () => {
    this.setState({
      selectedERPItem: null,
      isEditERPModalOpen: false,
    });
  }

  redirectToOrder = (orderId) => {
    const url = window.location.href;

    const urlParts = url.split('/');
    urlParts.pop();
    const newUrl = `${urlParts.join('/')}/${orderId}`;

    window.open(newUrl, '_blank');
  }

  handleEditItem = (row, e) => {
    e.stopPropagation();
    this.setState({
      isEditGroupItemModalOpen: true,
      selectedGroupItem: row.original,
    });
  }

  handleShowConfirmationDialog = (row, e) => {
    e.stopPropagation();
    this.setState({ deleteData: row, showConfirmationDialog: true });
  }

  handleDeleteItem = () => {
    const row = this.state.deleteData;
    if (row && row.original && row.original.id) {
      deleteOrderGroupItem(row.original.id)
        .then(() => {
          this.props.refreshOrderTabs();
        });
    }
    this.setState({ showConfirmationDialog: false });
  }

  handleCloseGroupItemModal = () => {
    this.setState({
      isEditGroupItemModalOpen: false,
      selectedGroupItem: {},
    }, () => {
      this.props.refreshOrderTabs();
    });
  }

  handleFilterChange() {
    const { instancedGroups } = this.state;
    const { itemNameQuery, departmentsFilter, technologiesFilter } = this.state;

    const tempFilteredGroups = instancedGroups.map((group) => {
      const filteredGroup = { ...group };
      // eslint-disable-next-line no-prototype-builtins
      if (group.hasOwnProperty('material_code') && group.material_code === 'bom' && Array.isArray(group.items) && group.items.length > 0) {
        if (itemNameQuery !== '') {
          filteredGroup.items = filteredGroup.items.filter((bomItem) => bomItem.name.toLowerCase().includes(itemNameQuery.toLowerCase()));
        }
        if (departmentsFilter !== '') {
          filteredGroup.items = filteredGroup.items.filter((bomItem) => bomItem.product_type.department && bomItem.product_type.department.id === departmentsFilter);
        }
        if (technologiesFilter !== '') {
          filteredGroup.items = filteredGroup.items.filter((bomItem) => bomItem.product_type.technology && bomItem.product_type.technology.id === technologiesFilter);
        }
      }
      return filteredGroup;
    });
    this.setState({ filteredGroups: tempFilteredGroups });
  }

  handleStateQueryChange = () => {
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      this.handleFilterChange();
    }, 600);
  }

  handleClearItemNameQuery = () => {
    this.setState({
      itemNameQuery: '',
    }, () => { this.handleFilterChange(); });
  }

  handleClearAllFilters = () => {
    this.setState({
      itemNameQuery: '',
      departmentsFilter: '',
      technologiesFilter: '',
    }, () => { this.handleFilterChange(); });
  }

  handleExportItems = (groupItems) => {
    const { getOrderId } = this.props;

    this.setState({ isLoadingExportItems: true });

    const groupItemsIds = groupItems.map((item) => item.id);

    const orderId = getOrderId();
    exportBom(orderId, groupItemsIds)
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'bom_export.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({ isLoadingExportItems: false });
      })
      .catch(() => {
        this.setState({ isLoadingExportItems: false });
      });
  }

  returnDepartmentNameFromId = (idToFind) => {
    const { departments } = this.state;
    if (typeof idToFind !== 'object' && (typeof idToFind === 'number' || typeof idToFind === 'string')) {
      return ((obj) => obj && obj.name)(departments.find((obj) => obj.id === idToFind));
    }
    if (typeof idToFind === 'object') {
      return idToFind.name;
    }

    return '';
  }

  returnTechnologiesFromDepartment = (idToFind) => {
    const { departments } = this.state;

    if (typeof idToFind !== 'object' && (typeof idToFind === 'number' || typeof idToFind === 'string')) {
      return ((obj) => obj && obj.technologies)(departments.find((obj) => obj.id === idToFind));
    }
    if (typeof idToFind === 'object') {
      return ((obj) => obj && obj.technologies)(departments.find((obj) => obj.id === idToFind.id));
    }

    return '';
  }

  handleCloseBomModal = async () => {
    const { refreshOrderTabs } = this.props;

    this.setState({
      isImportBomModalOpen: false,
    });
    await refreshOrderTabs();
    this.handleClearAllFilters();
    this.fetchProductDepartments(this.props.locationId);
  }

  handleOpenBomModal = () => {
    this.setState({
      isImportBomModalOpen: true,
    });
  }

  selectedGroupButton = (group, selectedPartsObj) => {
    const groupFound = group.items.find((g) => selectedPartsObj.find((p) => p.id === g.id));
    if (groupFound) {
      if (groupFound.order_group === group.items[0].order_group) {
        return false;
      }
    }
    return true;
  }

  selectedGroupButtonDisabledState = (group, selectedPartsObj) => {
    const groupFiltered = group.items.filter((g) => selectedPartsObj.find((p) => p.id === g.id));
    if (groupFiltered && groupFiltered.length) {
      const foundSome = groupFiltered.some((obj) => (obj.target_order && obj.target_order !== null) || (!obj.product_type.department));
      if (!foundSome) {
        return false;
      }
    }
    return true;
  }

  render() {
    const {
      isOrderGroupsModalOpen,
      output,
      orderItemHistory,
      isLoadingOrderGroupsModal,
      isLoading,
      locationConfig,
      selectedParts,
      isCreateOrderModalOpen,
      departments,
      groupInfoModalStatus,
      groupInfoModalData,
      groupInfoModalTitle,
      isEditGroupItemModalOpen,
      selectedGroupItem,
      itemNameQuery,
      departmentsFilter,
      technologies,
      technologiesFilter,
      filteredGroups,
      isLoadingExportItems,
      isLoadingImportBom,
      isImportBomModalOpen,
      selectedERPItem,
      isEditERPModalOpen,
      groupSelectedParts,
      groupSelectedPartsObj,
      deleteData,
      showConfirmationDialog,
    } = this.state;

    const {
      order,
      order_groups_hidden_columns,
      t,
      i18n,
      company_short_code,
      getLocationName,
      projectsEnabled,
      companyId,
      getOrderId,
      refreshOrderTabs,
      firstLetterUpperCase,
      order_groups_custom_columns,
      isLoadingDetails,
      isReadOnly,
      isProjectBased,
      order_groups_show_waste,
    } = this.props;

    if (isLoading || isLoadingDetails) {
      return <ContentLoader />;
    }

    const itemsWithoutGroups = order.items.filter((item) => !item.order_group);

    const sortGroupItems = ((a, b) => {
      if (a.item_num > b.item_num) {
        return 1;
      } if (a.item_num < b.item_num) {
        return -1;
      }
      return 0;
    });

    const itemsWithGroups = order.items.filter((item) => item.order_group);
    order.items.sort((a, b) => { return a.id - b.id; });
    order.items.forEach((group) => {
      group.items = itemsWithGroups.filter((item) => item.order_group === group.id);
    });

    const selectedPartsObj = [...itemsWithGroups, ...itemsWithoutGroups].filter((x) => selectedParts.includes(parseInt(x.id, 10)));

    return (
      <div>
        {
          filteredGroups.map((group, i) => (
          <div key={i} className="order-details-table">
            <div className="title_button_row">
              <h3>
                {group.name} {group && group.material_code && !isProjectBased ? `- ${group.material_code}` : ''}
              </h3>
              <div className="button_wrapper">
                <Button
                  disabled={Object.keys(group.values).length === 0}
                  onClick={() => {
                    this.setState({
                      groupInfoModalData: group.values,
                      groupInfoModalTitle: `${group.name} ${group && group.material_code ? `- ${group.material_code}` : ''}`,
                    }, () => this.handleGroupInfoModalOpen());
                  }}
                >{t('page_content.orders.order_details.order_groups_tab.group_info_button')}</Button>

                {
                  projectsEnabled && group.material_code !== 'bom' && group.material_code !== 'operations' &&
                  <Button
                    type="export"
                    loading={isLoadingExportItems}
                    onClick={() => this.handleExportItems(group.items)}
                    disabled={!group.items.length}
                  >
                    {t('page_content.orders.order_details.order_groups_tab.export_items')}
                  </Button>
                }

              </div>

            </div>
            {
              locationConfig && locationConfig.order_group_create_order &&
              <div className="bom_filters_row">
                {
                  group.material_code === 'bom' &&
                  <React.Fragment>
                    <div className="input_container">
                      <input placeholder={`${t('page_content.orders.order_details.order_groups_tab.item_name_filter')}...`} value={itemNameQuery} onChange={(e) => this.setState({ itemNameQuery: e.target.value }, () => this.handleStateQueryChange())} />
                      {itemNameQuery &&
                        <button
                          onClick={() => this.handleClearItemNameQuery()}
                        >&times;</button>}
                      <div className="icon_container">
                        <IconSearch
                          color="#555"
                          height="26px"
                          width="26px"
                        />
                      </div>
                    </div>
                    <div className="select_style">
                      <Select
                        options={departments}
                        getOptionLabel={(department) => department.name}
                        getOptionValue={(department) => department.id}
                        isSearchable
                        placeholder={t('page_content.projects.products_tab.department_filter_placeholder')}
                        onChange={(value) => this.setState({ departmentsFilter: value.id }, () => this.handleStateQueryChange())}
                        value={(departments.find((department) => department.id === departmentsFilter)) || ''}
                        styles={selectStyles}
                      />
                    </div>
                    <Select
                      className="select_style"
                      options={technologies}
                      getOptionLabel={(technology) => technology.name}
                      getOptionValue={(technology) => technology.id}
                      isSearchable
                      placeholder={t('page_content.orders.order_details.order_groups_tab.technologies_filter')}
                      onChange={(value) => this.setState({ technologiesFilter: value.id }, () => this.handleStateQueryChange())}
                      value={(technologies.find((technology) => technology.id === technologiesFilter)) || ''}
                      styles={selectStyles}
                    />
                    <Button
                      onClick={() => this.handleClearAllFilters()}
                    >
                      {t('page_content.orders.order_details.order_groups_tab.clear_all_button')}
                    </Button>
                    <Button
                      type="dark-blue"
                      loading={isLoadingImportBom}
                      onClick={() => this.handleOpenBomModal()}
                    >
                      {t('page_content.orders.order_details.order_groups_tab.import_bom')}
                    </Button>
                  </React.Fragment>
                }

                {
                  (group.material_code === 'bom' || group.material_code === 'operation') &&

                  <Tooltip
                    trigger="hover"
                    placement="top"
                    overlayStyle={{ visibility: (selectedParts.length <= 0 || selectedPartsObj.some((obj) => (obj.target_order && obj.target_order !== null) || (obj.order_item !== null && obj.order_item !== undefined))) ? 'visible' : 'hidden' }}
                    overlay={<span>
                      {selectedParts.length <= 0 && t('page_content.orders.order_details.order_groups_tab.tooltip_warning_empty')}
                      {selectedPartsObj.some((obj) => (obj.target_order && obj.target_order !== null) || (obj.order_item !== null && obj.order_item !== undefined)) && t('page_content.orders.order_details.order_groups_tab.tooltip_warning')}
                    </span>}
                  >
                    <div className="button_wrapper">
                      <Button
                        disabled={
                          this.selectedGroupButton(group, selectedPartsObj) ||
                          (selectedParts.length <= 0) ||
                          this.selectedGroupButtonDisabledState(group, selectedPartsObj)
                        }
                        type="add"
                        onClick={() => this.openCreateOrderModal(group, selectedPartsObj)}
                      >
                        {t('page_content.orders.order_details.order_groups_tab.create_order_button')}
                      </Button>
                    </div>
                  </Tooltip>
                }

              </div>
            }
            <Table
              style={{ userSelect: 'text' }}
              columns={[
                {
                  Header: () => (<span>
                    <input
                      type="checkbox"
                      onChange={(e) => this.multipleSelectParts(e, group.items.sort(sortGroupItems))}
                      checked={group.items.sort(sortGroupItems).every((obj) => selectedParts.includes(obj.id))}
                      disabled={false}
                      style={{ margin: 'auto', display: 'block' }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </span>),
                  width: 40,
                  Cell: (row) => (
                    <span onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        onChange={this.selectPart}
                        value={row.original.id}
                        disabled={selectedPartsObj.some((obj) => {
                          if (!obj.product_type.department && row.original.product_type.department) {
                            return true;
                          }
                          if (obj.product_type.department && obj.product_type.department.id) {
                            if (!row.original.product_type.department) {
                              return true;
                            }
                            if (obj.product_type.department && obj.product_type.department.id) {
                              if (!row.original.product_type.department) {
                                return true;
                              }
                            }
                            if (obj.product_type.department && row.original.product_type.department) {
                              return obj.product_type.department.id !== row.original.product_type.department.id;
                            }
                            return false;
                          }
                          return false;
                        })}
                        checked={(selectedParts !== null && selectedParts.length > 0 && selectedParts.includes(row.original.id))}
                      />
                      </span>
                  ),
                  show: !!(group.material_code === 'bom' || group.material_code === 'operation'),
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_item_number')}</span>,
                  accessor: 'item_num',
                  width: 60,
                  show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_item_number', { lng: 'en' })),
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_item_name')}</span>,
                  accessor: 'name',
                  show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_item_name', { lng: 'en' })),
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_next_order_group')}</span>,
                  accessor: 'next_order_group',
                  Cell: (row) => <span>{this.getOrderGroupNameById(row.value)}</span>,
                  show: (group.material_code === 'operation') && isProjectBased && !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_next_order_group', { lng: 'en' })),
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_order')}</span>,
                  Cell: (row) => (
                  <span style={{ textDecoration: 'underline', color: 'blue' }} onClick={() => { this.redirectToOrder(row && row.original && row.original.target_order ? row.original.target_order.id : null); }}>
                        {row && row.original && row.original.target_order ? row.original.target_order.external_id : ''}</span>),
                  show: (group.material_code === 'bom' || group.material_code === 'operation') && !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_order', { lng: 'en' })),
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_quantity')}</span>,
                  accessor: 'quantity',
                  Cell: (row) => <span>{row.original.unit_code && row.original.unit_code === 'PCE' ? numberSeparatorFormat(i18n.language, row.value, 0, 0, true) : numberSeparatorFormat(i18n.language, row.value, 2, 2, true)}</span>,
                  width: 120,
                  show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_quantity', { lng: 'en' })),
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_quantity_tracked')}</span>,
                  accessor: 'quantity_tracked',
                  Cell: (row) => <span>{row.original.unit_code && row.original.unit_code === 'PCE' ? numberSeparatorFormat(i18n.language, row.value, 0, 0, true) : numberSeparatorFormat(i18n.language, row.value, 2, 2, true)}</span>,
                  width: 120,
                  show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_quantity_tracked', { lng: 'en' })) && !(group && group.material_code && group.material_code === 'Resursi'),
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_quantity_final')}</span>,
                  accessor: 'quantity_final',
                  Cell: (row) => <span>{row.original.unit_code && row.original.unit_code === 'PCE' ? numberSeparatorFormat(i18n.language, row.value, 0, 0, true) : numberSeparatorFormat(i18n.language, row.value, 2, 2, true)}</span>,
                  width: 120,
                  show: !projectsEnabled && (group.material_code !== 'bom') && (!order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_quantity_final', { lng: 'en' })) && !(group && group.material_code && (group.material_code === 'BOM' || group.material_code === 'Resursi'))),
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_quantity_done')}</span>,
                  accessor: 'quantity_done',
                  Cell: (row) => <span>{row.original.unit_code && row.original.unit_code === 'PCE' ? numberSeparatorFormat(i18n.language, row.value, 0, 0, true) : numberSeparatorFormat(i18n.language, row.value, 2, 2, true)}</span>,
                  width: 120,
                  show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_quantity_done', { lng: 'en' })),
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_waste')}</span>,
                  accessor: 'quantity_waste',
                  Cell: (row) => <span>{row.original.unit_code && row.original.unit_code === 'PCE' ? numberSeparatorFormat(i18n.language, row.value, 0, 0, true) : numberSeparatorFormat(i18n.language, row.value, 2, 2, true)}</span>,
                  width: 120,
                  show: order_groups_show_waste && !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_waste', { lng: 'en' })) && (group.material_code === 'stavke' || group.material_code === 'operation' || group.material_code === 'items'),
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_unit')}</span>,
                  accessor: 'unit_code',
                  width: 100,
                  show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_unit', { lng: 'en' })),
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_status')}</span>,
                  accessor: 'status',
                  maxWidth: 150,
                  Cell: (row) => <span className={`order-details-table__status ${row.value}`}>{row.value ? t([`page_content.orders.statuses.${row.value}`]) : ''}</span>,
                  show: !order_groups_hidden_columns.includes(t('page_content.orders.order_details.order_groups_tab.table_column_status', { lng: 'en' })) && company_short_code !== 'podravka',
                },
                {
                  Header: () => 'ERP ID',
                  accessor: 'product_type.manuf_id',
                  Cell: (row) => (
                      <div
                        className="products_tab_step_column_wrapper"
                        onClick={(e) => {
                          this.handleEditERP(e, row);
                        }}
                      >
                        <div className="info_column">
                          <div><span>{row.value || '-'}</span></div>
                        </div>
                        {!isReadOnly &&
                        <div className="edit_column">
                          <div>
                            <IconEdit
                              height="14px"
                              width="14px"
                            />
                          </div>
                        </div>}
                    </div>
                  ),
                  width: 80,
                  show: !!(group.material_code === 'bom') && (!order_groups_hidden_columns.includes('ERP ID')),
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_department')}</span>,
                  accessor: 'product_type',
                  Cell: (row) => <span>{row.value && row.value.department ? this.returnDepartmentNameFromId(row.value.department) : <span className="order_groups_red_banner">{t('page_content.orders.order_details.order_groups_tab.no_department_alert')}</span>}</span>,
                  show: projectsEnabled && !!(locationConfig && locationConfig.show_technology_and_department && locationConfig.show_technology_and_department === true) && (group.material_code === 'bom' || group.material_code === 'operation'),
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_technology')}</span>,
                  Cell: (row) => (<span>{row.original.product_type && row.original.product_type.department && this.returnTechnologiesFromDepartment(row.original.product_type.department) && this.returnTechnologiesFromDepartment(row.original.product_type.department).length > 0 ? <select
                    style={{ width: '100%' }}
                    value={(row.original.product_type.technology && row.original.product_type.technology.id) || ''}
                    onChange={(e) => {
                      const payload = {
                        id: row.original.product_type.id,
                        technology: e.target.value,
                        company: row.original.product_type.company,
                        code: row.original.product_type.code,
                      };
                      this.handleProductTypeChange(row.original.product_type.id, payload);
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <option value="" disabled defaultValue>{t('page_content.orders.order_details.order_groups_tab.select_technology_placeholder')}</option>
                    {row.original.product_type.department && departments && this.returnTechnologiesFromDepartment(row.original.product_type.department) ? this.returnTechnologiesFromDepartment(row.original.product_type.department).sort((a, b) => a.name.localeCompare(b.name)).map((technology) => (
                      <option
                        key={technology.id}
                        value={technology.id}
                      >{technology.name}</option>
                    )) : null}
                  </select> : <span className="order_groups_red_banner">{t('page_content.orders.order_details.order_groups_tab.no_technology_alert')}</span>}</span>),
                  show: projectsEnabled && !!(locationConfig && locationConfig.show_technology_and_department && locationConfig.show_technology_and_department === true) && (group.material_code === 'bom' || group.material_code === 'operation'),
                },
                ...order_groups_custom_columns.map((column) => ({
                  Header: () => <span>{column[`title_${i18n.language || 'en'}`] || ''}</span>,
                  accessor: column.accessor,
                  Cell: (row) => <span>{row && row.value && typeof row.value === 'object' ? JSON.stringify(row.value, null, 2) : row.value ? row.value : ''}</span>,
                })),
                {
                  Header: () => '',
                  Cell: (row) => <Button className="remove-btn" onClick={(e) => this.handleEditItem(row, e)}><IconEdit width="12px" height="12px" /></Button>,
                  width: 130,
                  show: !!(locationConfig && locationConfig.order_group_create_order),
                },
                {
                  Header: () => '',
                  Cell: (row) => <Button type="delete" className="remove-btn" onClick={(e) => this.handleShowConfirmationDialog(row, e)}><IconRemove width="12px" height="12px" /></Button>,
                  width: 130,
                  show: !!(locationConfig && locationConfig.order_group_create_order),
                },
              ]}
              data={group.items.sort(sortGroupItems)}
              minRows={group.items.length}
              maxRows={group.items.length}
              defaultPageSize={group.items.length}
              showPagination={false}
              sortable
              manual={false}
              handleClick={(rowInfo) => this.openOrderGroupsModal(rowInfo)}
              loading={isLoading || isLoadingDetails}
              selectedRow={null}
              defaultSorted={[{ id: 'name', desc: false }]}
            />
          </div>))
        }

        {
          itemsWithoutGroups && itemsWithoutGroups.length ?
            <h2>Order Items</h2> : ''
        }

        {
          (itemsWithoutGroups && itemsWithoutGroups.length) ?
            <Table
              style={{ userSelect: 'text' }}
              columns={[
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_item_number')}</span>,
                  accessor: 'item_num',
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_item_name')}</span>,
                  accessor: 'name',
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_quantity')}</span>,
                  accessor: 'quantity',
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_quantity_done')}</span>,
                  accessor: 'quantity_done',
                },
                {
                  Header: () => <span>{t('page_content.orders.order_details.order_groups_tab.table_column_status')}</span>,
                  accessor: 'status',
                  Cell: (row) => <span className={`order-details-table__status ${row.value}`}>{row.value ? t([`page_content.orders.statuses.${row.value}`]) : ''}</span>,
                },
              ]}
              data={itemsWithoutGroups}
              minRows={itemsWithoutGroups.length}
              maxRows={itemsWithoutGroups.length}
              defaultPageSize={itemsWithoutGroups.length}
              showPagination={false}
              sortable
              handleClick={(rowInfo) => this.openOrderGroupsModal(rowInfo)}
              loading={isLoading}
              manual={false}
              defaultSorted={[{ id: 'name', desc: false }]}
            /> : ''
        }

        <OrderGroupsModal
          t={t}
          isOrderGroupsModalOpen={isOrderGroupsModalOpen}
          closeOrderGroupsModal={this.closeOrderGroupsModal}
          output={output}
          isLoadingOrderGroupsModal={isLoadingOrderGroupsModal}
          getLocationName={getLocationName}
          firstLetterUpperCase={firstLetterUpperCase}
          orderItemHistory={orderItemHistory}
          isLoading={isLoading}
        />

        {
          isCreateOrderModalOpen &&
          <CreateOrderModal
            t={t}
            isCreateOrderModalOpen={isCreateOrderModalOpen}
            closeCreateOrderModal={this.closeCreateOrderModal}
            createOrder={this.createOrder}
            selectedPartsObj={groupSelectedPartsObj}
            selectedParts={groupSelectedParts}
            deselectPart={this.deselectPart}
          />
        }

        <GroupInfoModal
          t={t}
          groupInfoModalStatus={groupInfoModalStatus}
          handleGroupInfoModalClose={this.handleGroupInfoModalClose}
          groupInfoModalTitle={groupInfoModalTitle}
          groupInfoModalData={groupInfoModalData}
        />

        {
          isEditGroupItemModalOpen &&
          <OrderGroupItemModal
            t={t}
            isEditGroupItemModalOpen={isEditGroupItemModalOpen}
            selectedGroupItem={selectedGroupItem}
            handleCloseGroupItemModal={this.handleCloseGroupItemModal}
            companyId={companyId}
            refreshOrderTabs={refreshOrderTabs}
          />
        }

        {
          isImportBomModalOpen &&
          <ImportBomModal
            t={t}
            isImportBomModalOpen={isImportBomModalOpen}
            handleCloseBomModal={this.handleCloseBomModal}
            getOrderId={getOrderId}
          />
        }

        {
          selectedERPItem &&
          <EditERPModal
            isEditERPModalOpen={isEditERPModalOpen}
            selectedERPItem={selectedERPItem}
            handleERPChange={this.handleERPChange}
            closeEditERPModal={this.closeEditERPModal}
            getOrderId={getOrderId}
          />
        }
          <ConfirmationModal
            itemName={deleteData?.original?.name}
            showModal={showConfirmationDialog}
            handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
            handleConfirmModal={this.handleDeleteItem}
            type="warning"
          />
      </div>
    );
  }
}

OrderGroups.propTypes = {
  t: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  order_groups_hidden_columns: PropTypes.array.isRequired,
  i18n: PropTypes.object.isRequired,
  company_short_code: PropTypes.string.isRequired,
  firstLetterUpperCase: PropTypes.func.isRequired,
  getLocationName: PropTypes.func.isRequired,
  getOrderId: PropTypes.func.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  projectsEnabled: PropTypes.bool,
  refreshOrderTabs: PropTypes.func.isRequired,
  order_groups_custom_columns: PropTypes.array,
  isLoadingDetails: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool,
  isProjectBased: PropTypes.bool,
  order_groups_show_waste: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    order_groups_custom_columns: get(state, 'app.location.config.order_groups_custom_columns', []),
    order_groups_show_waste: get(state, 'app.location.config.order_groups_show_waste', false),
    isProjectBased: get(state, 'app.companyConfig.config.project_based', false),
  };
};

export default connect(mapStateToProps, null)(OrderGroups);
