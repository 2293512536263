import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Select from 'react-select';
import api from 'helpers/api';

class AssetPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAsset: props.asset,
      options: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { locationId, companyId, extendOptions } = this.props;
    api.get(`/api/v1/industry/assets/?location=${locationId}&order_by=name&limit=200&company=${companyId}`)
      .then((json) => this.setState({
        options: extendOptions ? [...get(json, 'data.results'), ...extendOptions] : get(json, 'data.results'),
      }));
  }

  componentDidUpdate(prevProps, prevState) {
    if ((typeof prevProps.asset === 'number' && typeof this.props.asset === 'number') || (typeof prevProps.asset === 'object' && typeof this.props.asset === 'object')) {
      if (prevProps.asset !== this.props.asset) {
        this.handleChange(this.props.asset);
      }
    }

    if ((typeof prevProps.asset === 'number' && typeof this.props.asset === 'object')) {
      if (prevProps.asset !== this.props.asset.id) {
        this.handleChange(this.props.asset);
      }
    }

    if ((typeof prevProps.asset === 'object' && typeof this.props.asset === 'number')) {
      if (this.props.asset !== prevProps.asset.id) {
        this.handleChange(this.props.asset);
      }
    }

    if (
      !!prevState.selectedAsset && !!this.state.selectedAsset && (
        prevState.selectedAsset !== this.state.selectedAsset ||
        prevState.selectedAsset.id !== this.state.selectedAsset.id
      ) && prevProps.asset !== this.props.asset
    ) {
      this.props.resetMetric();
    }

    if (prevState.selectedAsset && this.state.selectedAsset === null) {
      this.props.resetMetric();
    }
  }

  handleChange(asset) {
    const { changeAsset } = this.props;
    this.setState({ selectedAsset: asset });
    changeAsset(asset);
  }

  render() {
    const { selectedAsset, options } = this.state;
    const { disabled, t, hasError, customStyles, isClearable } = this.props;

    const selectStyles = {
      control: (provided) => ({
        ...provided,
        border: hasError ? '0.5px solid red' : '',
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          marginLeft: '5px',
        };
      },
      singleValue: (provided) => ({
        ...provided,
        marginLeft: '5px',
      }),
      ...customStyles,
    };

    return (
      <Select
        cacheOptions
        isClearable={typeof isClearable === 'boolean' ? isClearable : true}
        isDisabled={disabled}
        id={`asset-picker-${get(selectedAsset, 'id') || 0}`}
        options={options}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        onChange={this.handleChange}
        placeholder={t('shared.asset_picker.placeholder')}
        value={typeof selectedAsset === 'number' ? options.find((o) => o.id === selectedAsset) : selectedAsset}
        className="AscaliaSelect AssetPicker"
        styles={selectStyles}
      />
    );
  }
}

AssetPicker.propTypes = {
  disabled: PropTypes.bool,
  asset: PropTypes.object,
  changeAsset: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  resetMetric: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  customStyles: PropTypes.object,
  isClearable: PropTypes.bool,
  extendOptions: PropTypes.array,
};

AssetPicker.defaultProps = {
  disabled: false,
};

const mapStateToProps = (state) => ({
  locationId: get(state, 'app.location.id'),
  companyId: get(state, 'app.company.id'),
});

export default connect(mapStateToProps)(withTranslation()(AssetPicker));
