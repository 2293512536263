import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal } from 'shared';
import { modalSizes } from 'shared/constants';

class GenerateForERPResponseModal extends Component {
  render() {
    const {
      t,
      isGenerateForERPResponseModalOpen,
      closeGenerateForERPResponseModal,
      generateForERPResponse,
    } = this.props;

    return (
      <Modal
        isOpen={isGenerateForERPResponseModalOpen}
        handleClose={() => closeGenerateForERPResponseModal()}
        closeText={t('shared.modal.close_button_alt')}
        size={modalSizes.large}
      >
        {
          generateForERPResponse &&
          <div className="message_response">
            <p className="value">{generateForERPResponse && generateForERPResponse.message ? generateForERPResponse.message : '-'}</p>
          </div>
        }
      </Modal>
    );
  }
}

GenerateForERPResponseModal.propTypes = {
  t: PropTypes.func.isRequired,
  isGenerateForERPResponseModalOpen: PropTypes.bool.isRequired,
  closeGenerateForERPResponseModal: PropTypes.func.isRequired,
  generateForERPResponse: PropTypes.object.isRequired,
};

export default withTranslation()(GenerateForERPResponseModal);
