import React, { Component } from 'react';
import moment from 'moment';
import { Modal, Button } from 'shared';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import '../style.scss';

class SignModal extends Component {
  constructor(props) {
    super();
    let comment = '';
    if (props.orderToSign) {
      const { orderToSign } = props;
      comment = orderToSign.comment_approval;
    }
    this.state = {
      comment,
    };
  }

  render() {
    const { comment } = this.state;
    const {
      t,
      orderToSign,
      isSignModalOpen,
      handleCloseSignModal,
      handleSaveSignModal,
    } = this.props;

    const recordName = orderToSign && orderToSign.production_record && orderToSign.production_record.title ? orderToSign.production_record.title : '-';
    const orderName = orderToSign && orderToSign.order && orderToSign.order.external_id ? orderToSign.order.external_id : '-';
    const timeCreated = orderToSign && orderToSign.time_created ? moment(orderToSign.time_created).format('DD.MM. - HH:mm') : '-';

    return (
      <Modal
        isOpen={isSignModalOpen}
        handleClose={handleCloseSignModal}
        saveText={t('page_content.production_records.sign')}
      >
        <p style={{ padding: '15px' }}>{t('page_content.production_records.confirmSignRecord', {
          recordName,
          orderName,
          timeCreated,
        })}</p>

        <div className="default-form">
          <table>
            <tbody>
              <tr>
                <td className="label">
                  {t('page_content.production_records.comment')}
                </td>
                <td className="value">
                  <textarea
                    style={{ height: '100px' }}
                    onChange={(e) => this.setState({ comment: e.target.value })}
                    value={comment || ''}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="sign_modal_buttons">
          <Button type="success" onClick={() => handleSaveSignModal(comment, true)}>{t('page_content.production_records.approved')}</Button>
          <Button type="danger" onClick={() => handleSaveSignModal(comment, false)}>{t('page_content.production_records.not_approved')}</Button>
        </div>

      </Modal>
    );
  }
}

SignModal.propTypes = {
  t: PropTypes.func.isRequired,
  isSignModalOpen: PropTypes.bool.isRequired,
  handleCloseSignModal: PropTypes.func.isRequired,
  handleSaveSignModal: PropTypes.func.isRequired,
  orderToSign: PropTypes.object.isRequired,
};

export default withTranslation()(SignModal);
