import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconZoomOut } from 'shared/Icons';
import { buttonDisabledDuration } from './constants';
import './styles.scss';

class ZoomOutButton extends Component {
  constructor() {
    super();
    this.state = {
      disabled: false,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.t);
  }

  enableButton = () => {
    this.t = setTimeout(() => {
      this.setState(() => ({ disabled: false }));
    }, buttonDisabledDuration);
  }

  handleClick = () => {
    const { handleZoomOut } = this.props;
    this.setState(() => ({
      disabled: true,
    }), () => {
      this.enableButton();
      setTimeout(() => {
        handleZoomOut();
      }, 0);
    });
  }

  render() {
    const { animating, disabled } = this.state;
    const { isDisabled } = this.props;

    return (
      <button
        className={`ZoomOutButton ${animating ? 'loading' : ''}`}
        disabled={isDisabled || disabled}
        onClick={this.handleClick}
      >
        <IconZoomOut height="20px" width="20px" />
      </button>
    );
  }
}

ZoomOutButton.propTypes = {
  handleZoomOut: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

ZoomOutButton.defaultProps = {
  isDisabled: false,
};

export default ZoomOutButton;
