/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ContentLoader } from 'shared';
import {
  checkAccessOnPage,
  redirectToHomePage,
  checkModules,
} from 'industry/helpers';
import {
  ReportsList,
  FactorsList,
  ReportLogs,
  ReportRunners,
} from './components';
import { getCompanyData } from './actions';
import './styles.scss';

class IndustryAutomaticReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      company_short_code: null,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Automatic Reports');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            isReadOnly: true,
          });
        }
      });

    getCompanyData(companyId)
      .then((re) => {
        this.setState({
          company_short_code: re.data.short_code || null,
          isLoading: false,
        });
      });
  }

  render() {
    const { company_short_code, isLoading } = this.state;
    const {
      companyId,
      t,
      locationId,
    } = this.props;

    if (isLoading) {
      return (
        <div className="loaderWrapper">
          <ContentLoader />
        </div>
      );
    }

    return (
      <div className="industry-tab automatic_reports_container fullscreen">
        <Tabs>
          <TabList>
            <Tab>{t('page_content.automatic_reports.report_logs.button_title')}</Tab>
            {company_short_code !== 'podravka' && <Tab>{t('page_content.automatic_reports.factors.button_title')}</Tab>}
            <Tab>{t('page_content.automatic_reports.reports.button_title')}</Tab>
            {company_short_code !== 'podravka' && <Tab>{t('page_content.automatic_reports.report_run.button_title')}</Tab>}
          </TabList>
          <TabPanel>
            <ReportLogs
              companyId={companyId}
              locationId={locationId}
            />
          </TabPanel>

          {
            company_short_code !== 'podravka' &&
            <TabPanel>
              <FactorsList
                companyId={companyId}
              />
            </TabPanel>
          }

          <TabPanel>
            <ReportsList
              companyId={companyId}
              locationId={locationId}
            />
          </TabPanel>

          {
            company_short_code !== 'podravka' &&
            <TabPanel>
              <ReportRunners
                companyId={companyId}
              />
            </TabPanel>
          }

        </Tabs>
      </div>
      // <Page classes={['automatic-reports', 'fullscreen']}>
      //   <PageHeader>
      //     <div className="left">
      //       {t('page_content.automatic_reports.title')}
      //     </div>
      //     <div className="right">
      //       <div className="controls">
      //         <Button
      //           type={(showingPage === 'reports') ? 'dark-blue' : 'plain'}
      //           onClick={() => this.showPage('reports')}
      //         >{t('page_content.automatic_reports.reports.button_title')}</Button>
      //         <Button
      //           type={(showingPage === 'factors') ? 'dark-blue' : 'plain'}
      //           onClick={() => this.showPage('factors')}
      //         >{t('page_content.automatic_reports.factors.button_title')}</Button>
      //         <Button
      //           type={(showingPage === 'logs') ? 'dark-blue' : 'plain'}
      //           onClick={() => this.showPage('logs')}
      //         >{t('page_content.automatic_reports.report_logs.button_title')}</Button>
      //         <Button
      //           type={(showingPage === 'runners') ? 'dark-blue' : 'plain'}
      //           onClick={() => this.showPage('runners')}
      //         >{t('page_content.automatic_reports.report_run.button_title')}</Button>
      //       </div>
      //     </div>
      //   </PageHeader>
      //   <PageContent>
      //     <div className="automatic-reports__container">

    //       { showingPage === 'reports' &&
    //         <ReportsList
    //           companyId={companyId}
    //           locationId={locationId}
    //         />
    //       }

    //       { showingPage === 'factors' &&
    //         <FactorsList
    //           companyId={companyId}
    //         />
    //       }

    //       { showingPage === 'logs' &&
    //         <ReportLogs
    //           companyId={companyId}
    //         />
    //       }

    //       { showingPage === 'runners' &&
    //         <ReportRunners
    //           companyId={companyId}
    //         />
    //       }
    //     </div>
    //   </PageContent>
    // </Page>
    );
  }
}

IndustryAutomaticReports.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  // currentUser: PropTypes.object.isRequired,
  // setCurrentUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  // setCurrentUser: getCurrentUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IndustryAutomaticReports));
