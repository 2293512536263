import React from 'react';
import Base from './Base';

export default (props) => (<Base viewBox=" 0 0 128 128" {...props}>
  <g transform="matrix(1.46895,0,0,1,-38.9914,-0.130507)">
    <path d="M70.006,20.115L99.954,59.953L40.059,59.953L70.006,20.115Z" />
  </g>
  <g transform="matrix(-1.46895,-1.79894e-16,1.22465e-16,-1,166.68,128.037)">
    <path d="M70.006,20.115L99.954,59.953L40.059,59.953L70.006,20.115Z" />
  </g>
</Base>);
