/* eslint-disable no-tabs */
import React from 'react';
import Base from './Base';

export default (props) => (<Base viewBox="0 0 512 512" {...props}>
  <g>
    <path d="M432.002,304H416c-4.418,0-8,3.582-8,8s3.582,8,8,8h16.002c4.418,0,8-3.582,8-8S436.42,304,432.002,304z" />
    <path
      d="M336,384H176c-13.233,0-24,10.767-24,24s10.767,24,24,24h160c13.233,0,24-10.767,24-24S349.233,384,336,384z M336,416H176
		c-4.411,0-8-3.589-8-8s3.589-8,8-8h160c4.411,0,8,3.589,8,8S340.411,416,336,416z"
    />
    <path
      d="M88,256c4.418,0,8-3.582,8-8V112h320v136c0,4.418,3.582,8,8,8s8-3.582,8-8V112c0-8.822-7.178-16-16-16V80
		c0-8.822-7.178-16-16-16V48c0-8.822-7.178-16-16-16V16c0-8.822-7.178-16-16-16H144c-8.822,0-16,7.178-16,16v16
		c-8.822,0-16,7.178-16,16v16c-8.822,0-16,7.178-16,16v16c-8.822,0-16,7.178-16,16v136C80,252.418,83.582,256,88,256z M144,16h224
		v16H144V16z M128,48h256v16H128V48z M112,80h288v16H112V80z"
    />
    <path
      d="M511.891,293.741c-0.179-1.91-0.585-3.753-1.187-5.507L479.86,147.997C477.901,136.394,467.991,128,456.196,128H456
		c-4.418,0-8,3.582-8,8s3.582,8,8,8h0.196c3.961,0,7.284,2.84,7.902,6.752c0.025,0.158,0.054,0.315,0.088,0.471l26.6,120.944
		C489.872,272.061,488.943,272,488,272H24c-0.943,0-1.872,0.061-2.787,0.167l26.6-120.944c0.034-0.156,0.063-0.313,0.088-0.471
		C48.52,146.84,51.843,144,55.804,144H56c4.418,0,8-3.582,8-8s-3.582-8-8-8h-0.196c-11.795,0-21.706,8.394-23.664,19.997
		L1.297,288.234c-0.602,1.754-1.008,3.597-1.187,5.507c-0.088,0.549-0.118,1.094-0.094,1.63C0.01,295.581,0,295.789,0,296v32v160
		c0,13.234,10.766,24,24,24h464c13.234,0,24-10.766,24-24V328v-32c0-0.211-0.01-0.419-0.016-0.628
		C512.009,294.835,511.978,294.29,511.891,293.741z M496,488c0,4.411-3.589,8-8,8H24c-4.411,0-8-3.589-8-8V328c0-4.411,3.589-8,8-8
		h359.999c4.418,0,8-3.582,8-8s-3.582-8-8-8H24c-2.804,0-5.496,0.488-8,1.376V296c0-0.057,0.007-0.112,0.009-0.169l0.669-3.041
		c1.24-2.816,4.053-4.79,7.323-4.79h464c3.269,0,6.083,1.974,7.323,4.79l0.669,3.041c0.001,0.057,0.009,0.112,0.009,0.169v9.376
		c-2.504-0.888-5.196-1.376-8-1.376h-24c-4.418,0-8,3.582-8,8s3.582,8,8,8h24c4.411,0,8,3.589,8,8V488z"
    />
    <path d="M232,192c4.418,0,8-3.582,8-8s-3.582-8-8-8h-64c-4.418,0-8,3.582-8,8s3.582,8,8,8H232z" />
    <path d="M288,152c0-4.418-3.582-8-8-8H168c-4.418,0-8,3.582-8,8s3.582,8,8,8h112C284.418,160,288,156.418,288,152z" />
    <path d="M128,160h8c4.418,0,8-3.582,8-8s-3.582-8-8-8h-8c-4.418,0-8,3.582-8,8S123.582,160,128,160z" />
    <path d="M128,192h8c4.418,0,8-3.582,8-8s-3.582-8-8-8h-8c-4.418,0-8,3.582-8,8S123.582,192,128,192z" />
    <path d="M128,240c-4.418,0-8,3.582-8,8s3.582,8,8,8h72c4.418,0,8-3.582,8-8s-3.582-8-8-8H128z" />
    <path d="M264,216c0-4.418-3.582-8-8-8H128c-4.418,0-8,3.582-8,8s3.582,8,8,8h128C260.418,224,264,220.418,264,216z" />
    <path d="M384,208h-40c-4.418,0-8,3.582-8,8s3.582,8,8,8h40c4.418,0,8-3.582,8-8S388.418,208,384,208z" />
    <path d="M288,256h96c4.418,0,8-3.582,8-8s-3.582-8-8-8h-96c-4.418,0-8,3.582-8,8S283.582,256,288,256z" />
  </g>
</Base>);
