import React from 'react';

export const TOOLTIP_TRIGGER_DURATION = 1;

export const formatTypes = {
  integer: 'integer',
  float: 'float',
  date: 'date',
  time: 'time',
};

export const modalSizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  full: 'full',
  fullPlus: 'fullPlus',
  max: 'max',
};

export const defaultDateFormat = 'DD.MM.YYYY.';
export const defaultISODateFormat = 'YYYY-MM-DD';
export const defaultTimeFormat = 'HH:mm:ss';
export const timeFormatWithUnits = 'HH[h] mm[m] ss[s]';
export const ordersCreatedAndUpdated = 'DD.MM.YYYY. HH:mm';
export const defaultDateTimeFormat = 'DD.MM.YYYY. - HH:mm:ss';
export const defaultDateFormatRegex = /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}(\.)$/;

export const interfaceTypeEnum = {
  modbus_tcp: 'modbus_tcp',
  modbus_rtu: 'modbus_rtu',
  mqtt: 'mqtt',
};

export const interfaceTypes = [
  {
    label: 'Modbus/TCP',
    value: 'modbus_tcp',
  },
  {
    label: 'Modbus RTU',
    value: 'modbus_rtu',
  },
  {
    label: 'MQTT',
    value: 'mqtt',
  },
];

export const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
  'Workdays',
];

export const timingOptions = {
  lineTime: 'line_handling_time',
  lineUnit: 'line_handling_unit',
  itemTime: 'item_setup_time',
  itemUnit: 'item_setup_unit',
  cycleTime: 'cycle_time',
  cycleUnit: 'cycle_setup_unit',
  handlingTime: 'handling_time',
  handlingUnit: 'handling_setup_unit',
};

export const usingVariablesTooltip = (t) => {
  return (<p>
    <strong>{t('constants.variables')}</strong>
    <br /><br />
    {'{asset.name}'} - {t('constants.tooltip_asset_name')}
    <br />
    {'{asset.serial_number}'} - {t('constants.tooltip_serial_number')}
    <br />
    {'{label}'} - {t('constants.tooltip_metric_name')}
  </p>);
};
