import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { numberSeparatorFormat } from 'industry/helpers';
import checkStepEnd from 'shared/Icons/OrderTimeline/checkStepEnd.png';
import checkStep1 from 'shared/Icons/OrderTimeline/checkStep1.png';
import checkStep2 from 'shared/Icons/OrderTimeline/checkStep2.png';
import checkStep3 from 'shared/Icons/OrderTimeline/checkStep3.png';
import './style.scss';

class OrderPhasesTimeline extends React.Component {
  getMilestone1Title = () => {
    const { timeline, t } = this.props;
    const styleSecondary = {
      color: '#555',
      fontSize: '.8em',
      padding: 0,
    };

    let lastItemTime = null;

    if (timeline[0]) {
      lastItemTime = timeline[0].last_item_time;
    }

    return (
      <div style={{ padding: 0, textAlign: 'center' }}>
        <div style={{ padding: 0 }}>{t('page_content.orders.order_details.order_details_tab.timeline.sawing')}</div>
        {
          lastItemTime ?
            <div style={styleSecondary}>{moment(lastItemTime).format('HH:mm:ss DD MMM')}</div> : ''
        }
      </div>
    );
  };

  getMilestone2Title = () => {
    const { timeline, t } = this.props;
    const styleSecondary = {
      color: '#555',
      fontSize: '.8em',
      padding: 0,
    };

    let lastItemTime = null;

    if (timeline[1]) {
      lastItemTime = timeline[1].last_item_time;
    }

    return (
      <div style={{ padding: 0, textAlign: 'center' }}>
        <div style={{ padding: 0 }}>{t('page_content.orders.order_details.order_details_tab.timeline.edgebanding')}</div>
        {
          lastItemTime ?
            <div style={styleSecondary}>{moment(lastItemTime).format('HH:mm:ss DD MMM')}</div> : ''
        }
      </div>
    );
  };

  getEndTitle = () => {
    const { t } = this.props;

    return (
      <div style={{ padding: 0, textAlign: 'center' }}>
        <div style={{ padding: 0 }}>{t('page_content.orders.order_details.order_details_tab.timeline.end')}</div>
      </div>
    );
  };

  getMilestone3Title = () => {
    const { timeline, t } = this.props;
    const styleSecondary = {
      color: '#555',
      fontSize: '.8em',
      padding: 0,
    };

    let lastItemTime = null;

    if (timeline[2]) {
      lastItemTime = timeline[2].last_item_time;
    }

    return (
      <div style={{ padding: 0, textAlign: 'center' }}>
        <div style={{ padding: 0 }}>{t('page_content.orders.order_details.order_details_tab.timeline.palleting')}</div>
        {
          lastItemTime ?
            <div style={styleSecondary}>{moment(lastItemTime).format('HH:mm:ss DD MMM')}</div> : ''
        }
      </div>
    );
  };

  render() {
    const {
      timeline,
      t,
      i18n,
    } = this.props;

    const containerStyles = {
      backgroundColor: '#EDF4FD',
      borderRadius: 50,
      margin: 50,
      padding: 0,
      boxShadow: '0 0 5px #ddd',
      width: '33%',
      display: 'inline-block',
    };

    const container1Styles = {
      ...containerStyles,
      width: '28%',
      marginLeft: '43px',
      marginRight: '7px',
    };

    const container2Styles = {
      ...containerStyles,
      width: '34%',
      marginLeft: 0,
      marginRight: '9px',
    };

    const container3Styles = {
      ...containerStyles,
      marginLeft: 0,
      marginRight: '44px',
      width: '27%',
    };

    const fillerStyles = {
      backgroundColor: '#4285F4',
      borderRadius: 'inherit',
      textAlign: 'right',
      padding: '13px',
      boxSizing: 'border-box',
    };

    let completed1 = 0;
    let completed2 = 0;
    let completed3 = 0;

    if (timeline[0]) {
      completed1 = timeline[0].parts / timeline[0].parts_total;
      completed1 = Math.round(completed1 * 100);
    }
    if (timeline[1]) {
      completed2 = timeline[1].parts / timeline[1].parts_total;
      completed2 = Math.round(completed2 * 100);
    }
    if (timeline[2]) {
      completed3 = timeline[2].parts / timeline[2].parts_total;
      completed3 = Math.round(completed3 * 100);
    }

    const filler1Styles = {
      ...fillerStyles,
      width: `${Number.isNaN(completed1) ? 0 : completed1 > 100 ? 100 : completed1}%`,
    };
    const filler2Styles = {
      ...fillerStyles,
      width: `${Number.isNaN(completed2) ? 0 : completed2 > 100 ? 100 : completed2}%`,
    };
    const filler3Styles = {
      ...fillerStyles,
      width: `${Number.isNaN(completed3) ? 0 : completed3 > 100 ? 100 : completed3}%`,
    };

    const labelStyles = {
      padding: 0,
      display: 'block',
      whiteSpace: 'no-wrap',
      textAlign: 'center',
      color: '#16A765',
      fontWeight: 'normal',
      lineHeight: '35px',
      fontSize: '.95em',
    };

    if (completed3 === 100) {
      labelStyles.fontSize = '.85em';
    }

    const endStyles = {
      position: 'absolute',
      display: 'inline-block',
      width: '28px',
      height: '28px',
      right: '46px',
      top: '54px',
      padding: '6px',
      background: '#fff',
      borderRadius: '50px',
      border: '4px solid #4285F4',
      boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.1)',
    };
    const endTitleStyles = {
      position: 'absolute',
      right: '60px',
      display: 'inline-block',
      color: '#4285F4',
      padding: 0,
    };
    const endTitle = this.getEndTitle();

    const startStyles = {
      position: 'absolute',
      display: 'inline-block',
      width: '28px',
      height: '28px',
      left: '46px',
      top: '54px',
      padding: '6px',
      background: '#fff',
      borderRadius: '50px',
      border: '4px solid #4285F4',
      boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.1)',
    };
    const startTitleStyles = {
      position: 'absolute',
      left: completed1 >= 100 && timeline && timeline[0] ? '25px' : '45px',
      display: 'inline-block',
      color: '#4285F4',
      padding: 0,
    };

    const milestoneStyles = {
      position: 'absolute',
      display: 'inline-block',
      width: '28px',
      height: '28px',
      top: '54px',
      padding: '6px',
      background: 'white',
      borderRadius: '50px',
      boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.1)',
    };

    const milestoneTitleStyles = {
      position: 'absolute',
      display: 'inline-block',
      padding: 0,
    };

    const milestone1Styles = {
      ...startStyles,
      border: '4px solid #E5A6EB',
    };
    let milestone1 = '';
    let milestone1Title = t('page_content.orders.order_details.order_details_tab.timeline.sawing');
    const milestone1TitleStyles = {
      ...startTitleStyles,
      color: '#9B16A7',
    };

    const milestone2Styles = {
      ...milestoneStyles,
      left: 'calc(33% - 18px)',
      border: '4px solid #FFE395',
    };
    let milestone2Title = t('page_content.orders.order_details.order_details_tab.timeline.edgebanding');
    const milestone2TitleStyles = {
      ...milestoneTitleStyles,
      color: '#FFCC44',
      left: 'calc(33% - 39px)',
    };
    let milestone2 = '';

    const milestone3Styles = {
      ...milestoneStyles,
      left: 'calc(66% - 18px)',
      border: '4px solid #16A765',
    };
    let milestone3Title = t('page_content.orders.order_details.order_details_tab.timeline.palleting');
    const milestone3TitleStyles = {
      ...milestoneTitleStyles,
      color: '#16A765',
      left: completed3 >= 100 && timeline && timeline[2] ? 'calc(66% - 39px)' : 'calc(66% - 29px)',
    };
    let milestone3 = '';

    if (completed1 || completed2 || completed3) {
      if (completed1 >= 100 && timeline && timeline[0]) {
        if (timeline[0].parts >= timeline[0].parts_total) {
          milestone1Styles.border = '4px solid #9B16A7';
          milestone1TitleStyles.color = '#9B16A7';
          milestone1 = <img src={checkStep1} alt="ok" style={{ marginTop: '6px', marginLeft: '2px', width: '24px' }} />;
        }
        if (timeline && timeline[0] && timeline[0].last_item_time) {
          milestone1Title = this.getMilestone1Title();
        }
      }
      if (completed2 >= 100 && timeline && timeline[1]) {
        if (timeline[1].parts >= timeline[1].parts_total) {
          milestone2Styles.border = '4px solid #FFCC44';
          milestone2TitleStyles.color = '#FFCC44';
          milestone2 = <img src={checkStep2} alt="ok" style={{ marginTop: '6px', marginLeft: '2px', width: '24px' }} />;
        }
        if (timeline && timeline[1] && timeline[1].last_item_time) {
          milestone2TitleStyles.left = 'calc(33% - 39px)';
          milestone2Title = this.getMilestone2Title();
        }
      }
      if (completed3 >= 100 && timeline && timeline[2]) {
        if (timeline[2].parts >= timeline[2].parts_total) {
          // endStyles.border = '4px solid #16A765';
          milestone3 = <img src={checkStep3} alt="ok" style={{ marginTop: '6px', marginLeft: '2px', width: '24px' }} />;
        }
        if (timeline[2].last_item_time) {
          milestone3Styles.left = 'calc(66% - 18px)';
          milestone3Title = this.getMilestone3Title();
        }
      }
    }

    return (
      <React.Fragment>
        <div style={{ position: 'relative', padding: '20px 30px 5px', height: '10px', boxSizing: 'border-box' }}>
          <div style={milestone1TitleStyles}>{milestone1Title}</div>
          <div style={milestone2TitleStyles}>{milestone2Title}</div>
          <div style={milestone3TitleStyles}>{milestone3Title}</div>
          <div style={endTitleStyles}>{endTitle}</div>
        </div>
        <div className="order-timeline-wrapper" style={{ position: 'relative', display: 'flex' }}>
          <div style={container1Styles}>
            <div style={filler1Styles} />
          </div>
          <div style={container2Styles}>
            <div style={filler2Styles} />
          </div>
          <div style={container3Styles}>
            <div style={filler3Styles} />
          </div>
          <div style={endStyles}>
            <span className="end-node" style={labelStyles}>{completed1 >= 100 && completed2 >= 100 && completed3 >= 100 ? <img src={checkStepEnd} alt="OK" style={{ width: '24px', margin: '6px 0 0 2px' }} /> : ''}</span>
          </div>
          <div style={milestone1Styles}>
            <span>{milestone1}</span>
          </div>
          <div style={milestone2Styles}>
            <span>{milestone2}</span>
          </div>
          <div style={milestone3Styles}>
            <span>{milestone3}</span>
          </div>
        </div>
        <div
          style={{ color: '#4285F4', padding: '0 0 20px', marginTop: '-30px', marginBottom: '20px', position: 'relative' }}
        >
          {
            timeline && timeline[0] ?
              <div
                style={{ padding: 0, textAlign: 'center', position: 'absolute', left: '40', display: 'inline-block', marginLeft: '35px' }}
              >
                <div style={{ padding: 0, color: '#9B16A7', marginBottom: '8px' }}>{t('page_content.orders.order_details.order_details_tab.timeline.items')} {numberSeparatorFormat(i18n.language, timeline[0].items, 0, 0)}/{numberSeparatorFormat(i18n.language, timeline[0].items_total, 0, 0)}</div>
                <div style={{ fontSize: '.8em', padding: 0, color: '#555' }}>{t('page_content.orders.order_details.order_details_tab.timeline.parts')} {numberSeparatorFormat(i18n.language, timeline[0].parts, 0, 0)}/{numberSeparatorFormat(i18n.language, timeline[0].parts_total, 0, 0)}</div>
              </div> : ''
          }
          {
            timeline && timeline[1] ?
              <div
                style={{ padding: 0, textAlign: 'center', position: 'absolute', left: 'calc(33% - 30px)', display: 'inline-block' }}
              >
                <div style={{ padding: 0, color: '#FFCC44', marginBottom: '8px' }}>{t('page_content.orders.order_details.order_details_tab.timeline.items')} {numberSeparatorFormat(i18n.language, timeline[1].items, 0, 0)}/{numberSeparatorFormat(i18n.language, timeline[1].items_total, 0, 0)}</div>
                <div style={{ fontSize: '.8em', padding: 0, color: '#555' }}>{t('page_content.orders.order_details.order_details_tab.timeline.parts')} {numberSeparatorFormat(i18n.language, timeline[1].parts, 0, 0)}/{numberSeparatorFormat(i18n.language, timeline[1].parts_total, 0, 0)}</div>
              </div> : ''
          }
          {
            timeline && timeline[2] ?
              <div
                style={{ padding: 0, textAlign: 'center', position: 'absolute', left: 'calc(66% - 30px)', display: 'inline-block' }}
              >
                <div style={{ padding: 0, color: '#16A765', marginBottom: '8px' }}>{t('page_content.orders.order_details.order_details_tab.timeline.items')} {numberSeparatorFormat(i18n.language, timeline[2].items, 0, 0)}/{numberSeparatorFormat(i18n.language, timeline[2].items_total, 0, 0)}</div>
                <div style={{ fontSize: '.8em', padding: 0, color: '#555' }}>{t('page_content.orders.order_details.order_details_tab.timeline.parts')} {numberSeparatorFormat(i18n.language, timeline[2].parts, 0, 0)}/{numberSeparatorFormat(i18n.language, timeline[2].parts_total, 0, 0)}</div>
              </div> : ''
          }
        </div>
      </React.Fragment>
    );
  }
}

OrderPhasesTimeline.propTypes = {
  timeline: PropTypes.array,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object,
};

OrderPhasesTimeline.defaultProps = {
  timeline: [],
};

export default withTranslation()(OrderPhasesTimeline);
