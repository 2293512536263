import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { Modal, AssetPicker, Button, Table, Notification } from 'shared';
import api from 'helpers/api';
import './style.scss';
import { selectModalStyles } from '../../../../../../styles/modules/reactSelect';

class OperatorViewForm extends React.Component {
  constructor(props) {
    super();
    let name = '';
    let operator_type = '';
    if (props.operatorView) {
      const { operatorView } = props;
      name = operatorView.name;
      operator_type = operatorView.view_type;
    }
    this.state = {
      isShowAssetList: false,
      asset: props.datapoint && props.datapoint.metric && props.datapoint.metric.asset,
      name,
      operator_type,
      errorName: false,
      errorType: false,
    };
  }

  componentDidMount() {
    const {
      operatorView,
    } = this.props;

    if (operatorView && operatorView.id) {
      api
        .get(`/api/v1/dashboards/operator-view-assets/?operator_view=${operatorView.id}`)
        .then(({ data }) => {
          this.setState({
            assetData: data.results,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  operatorTypeChange = (e) => {
    this.setState({
      operator_type: e.target.value,
      errorType: false,
    });
  }

  handleSave = () => {
    const {
      locationId, saveOperatorView,
    } = this.props;

    const {
      name, operator_type,
    } = this.state;
    if (!name || !operator_type) {
      this.setState({
        errorName: !name,
        errorType: !operator_type,
      });
      return;
    }

    saveOperatorView({ name, view_type: operator_type, location: locationId });
    setTimeout(() => {
      this.setState({
        name: '',
        operator_type: '',
      });
    }, 10);
  }

  changeViewName = (e) => {
    this.setState({
      name: e.target.value,
      errorName: false,
    });
  }

  handleShowAssetList = () => {
    this.setState((prevState) => ({
      isShowAssetList: !prevState.isShowAssetList,
    }));
  };

  handleSaveAsset = () => {
    const {
      asset,
      assetData,
    } = this.state;

    const {
      operatorView,
    } = this.props;

    if (assetData && asset) {
      const isSame = assetData.some((el) => {
        return JSON.stringify(asset) === JSON.stringify(el.asset);
      });
      if (isSame) {
        return Notification('error', 'Asset is already added', 'We could not perform your request');
      }
      let payload = {
        asset: asset.id,
        operator_view: operatorView.id,
      };

      if (operatorView && operatorView.id) {
        api.post(`/api/v1/dashboards/operator-view-assets/?operator_view=${operatorView.id}`, payload)
          .then(() => {
            api
              .get(`/api/v1/dashboards/operator-view-assets/?operator_view=${operatorView.id}`)
              .then(({ data }) => {
                this.setState({
                  assetData: data.results,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.setState({
        asset: null,
      });
      payload = {};
    }
  }

  render() {
    const {
      isOpen, closeModal, isLoadingState, disabled, locationId, operatorView, t,
    } = this.props;
    const {
      name, operator_type, asset, isShowAssetList, assetData, errorName, errorType,
    } = this.state;

    const nameStyle = {};
    if (errorName) {
      nameStyle.borderColor = 'red';
    }
    const typeStyle = {};
    if (errorType) {
      typeStyle.borderColor = 'red';
    }

    const defaultTypeOptions = [{ value: '', name: t('settings.operator_view.operator_view_form.operator_type_placeholder') },
      { value: 'detailed', name: 'Detailed operator view' }, { value: 'simple', name: 'Simple operator view' }];

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        handleSave={this.handleSave}
        handleClose={closeModal}
        title={operatorView ? t('settings.operator_view.operator_view_form.edit_title') : t('settings.operator_view.operator_view_form.add_title')}
      >
        {
          isLoadingState &&
          <div
            className="reason-form-loader"
            style={{
              borderLeftColor: 'transparent',
            }}
          />
        }
        <div className="default-form">
          <table>
            <tbody>
              <tr>
                <td className="label" style={{ width: '140px' }}>
                  {t('settings.operator_view.operator_view_form.operator_view_name')}
                </td>
                <td className="value">
                  <input type="text" value={name} onChange={this.changeViewName} style={nameStyle} />
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('settings.operator_view.operator_view_form.operator_type')}
                </td>
                <td className="value">
                  <Select
                    options={defaultTypeOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    menuPosition="fixed"
                    placeholder={t('settings.operator_view.operator_view_form.operator_type_placeholder')}
                    onChange={(value) => this.operatorTypeChange({ target: value })}
                    value={(defaultTypeOptions.find((defaultType) => defaultType.value === operator_type)) || ''}
                    styles={selectModalStyles}
                  />
                </td>
              </tr>
              {operatorView &&
                <tr>
                  <td>
                    <Button onClick={() => this.handleShowAssetList()}>{isShowAssetList ? t('settings.operator_view.operator_view_form.hide_asset_list_button') : t('settings.operator_view.operator_view_form.show_asset_list_button')}</Button>
                  </td>
                </tr>}
              {isShowAssetList &&
                <tr>
                  <td className="label">
                    {t('settings.operator_view.operator_view_form.asset')}
                  </td>
                  <td className="value">
                    <AssetPicker
                      changeAsset={(val) => this.setState({ asset: val })}
                      resetMetric={() => this.updateField('metric', null)}
                      disabled={disabled}
                      asset={asset}
                      locationId={locationId}
                    />
                  </td>
                </tr>}
              {asset &&
                <tr>
                  <td className="value">
                    <Button onClick={() => this.handleSaveAsset()}>
                      {t('settings.operator_view.operator_view_form.save_asset_button')}
                    </Button>
                  </td>
                </tr>}
            </tbody>
          </table>
          {isShowAssetList ?
            <Table
              style={{ marginTop: '20px' }}
              columns={[
                {
                  Header: () => <span>{t('settings.operator_view.operator_view_form.table_column_name')}</span>,
                  accessor: 'asset.name',
                },
                {
                  Header: () => <span>{t('settings.operator_view.operator_view_form.table_column_serial_number')}</span>,
                  accessor: 'asset.serial_number',
                },
              ]}
              data={assetData || []}
              defaultPageSize={100}
              minRows={0}
              noDataText=" "
              showPagination={false}
              manual={false}
            /> : ''}
        </div>
      </Modal>
    );
  }
}

OperatorViewForm.propTypes = {
  operatorView: PropTypes.object,
  disabled: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isLoadingState: PropTypes.bool.isRequired,
  locationId: PropTypes.number.isRequired,
  datapoint: PropTypes.object,
  metric: PropTypes.any,
  asset: PropTypes.any,
  saveOperatorView: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(OperatorViewForm);
