import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal } from 'shared';
import { modalSizes } from 'shared/constants';

class BookTTResponseModal extends Component {
  render() {
    const {
      t,
      isBookTTModalOpen,
      closeBookTTResponseModal,
      bookTTResponse,
    } = this.props;

    return (
      <Modal
        isOpen={isBookTTModalOpen}
        handleClose={() => closeBookTTResponseModal()}
        closeText={t('shared.modal.close_button_alt')}
        size={modalSizes.large}
      >
        {
          bookTTResponse &&
          <div className="message_response">
            <p className="value">{bookTTResponse && bookTTResponse.message ? bookTTResponse.message : '-'}</p>
          </div>
        }
      </Modal>
    );
  }
}

BookTTResponseModal.propTypes = {
  t: PropTypes.func.isRequired,
  isBookTTModalOpen: PropTypes.bool.isRequired,
  closeBookTTResponseModal: PropTypes.func.isRequired,
  bookTTResponse: PropTypes.object.isRequired,
};

export default withTranslation()(BookTTResponseModal);
