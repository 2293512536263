import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { get } from 'lodash';
import './styles.scss';
import DocumentFiles from './components/DocumentFiles';
import { getDmsDocuments } from '../IndustryLocationDMS/actions';

class IndustryLocationDMSDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentDocInfo: [],
    };
  }

  componentDidMount() {
    this.fetchParentDocName();
  }

  getDocumentId = () => {
    const url = window.location.href;
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
  }

  fetchParentDocName = () => {
    const { locationId } = this.props;
    const documentId = this.getDocumentId();
    getDmsDocuments(locationId)
      .then((re) => {
        const docsList = get(re, 'data.results') || [];
        const document = docsList.find((doc) => doc.id === parseInt(documentId, 10));
        if (document) {
          this.setState({
            parentDocInfo: document,
          });
        }
      });
  }

  render() {
    const { locationId, companyId, history, t } = this.props;
    const { parentDocInfo } = this.state;

    return (
      <div className="dms_details_main_container">
        <div className="dms_details_breadcrumbs"><span
          onClick={() => {
            history.push(`/${companyId}/industry/location/${locationId}/dms/`);
          }}
        >{t('page_content.dms.tab_document_files.breadcrumb')}</span><p>{'>'}</p><span>{parentDocInfo.name}</span></div>
        <Tabs>
          <TabList>
            <Tab>{t('page_content.dms.tab_list.document_files')}</Tab>
          </TabList>
          <TabPanel>
            <DocumentFiles locationId={locationId} companyId={companyId} parentDocumentInfo={parentDocInfo} refreshDocInfo={this.fetchParentDocName} />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

IndustryLocationDMSDetails.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default (withRouter(withTranslation()(IndustryLocationDMSDetails)));
