import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Table } from 'shared';
import { defaultDateTimeFormat } from 'shared/constants';
import i18n from 'i18next';
import { numberSeparatorFormat } from 'industry/helpers';

const OrderBatchTable = ({ t, batches, isLoadingBatches, handleSelectBatch, users, handleSortingBatches }) => {
  const tableColumnConfig = [
    {
      Header: () => <span>{t('page_content.orders.order_details.batches_tab.table_column_start')}</span>,
      accessor: 'created_at',
      width: 175,
      Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.orders.order_details.batches_tab.table_column_batch_nr')}</span>,
      accessor: 'batch_nr',
      width: 100,
      Cell: (row) => row.value || '-',
    },
    {
      Header: () => <span>{t('page_content.orders.order_details.batches_tab.table_column_batch_worker')}</span>,
      accessor: 'worker',
      Cell: (row) => {
        const userFound = users && users.find((u) => u.id === row.value);
        if (userFound) {
          return `${userFound.name} ${userFound.last_name}`;
        }
        return '-';
      },
    },
    {
      Header: () => <span>{t('page_content.orders.order_details.batches_tab.table_column_batch_quantity')}</span>,
      accessor: 'batch_size',
      width: 100,
      Cell: (row) => numberSeparatorFormat(i18n.language, row.value, 2, 2, true) || '-',
    },
    {
      Header: () => <span>{t('page_content.orders.order_details.batches_tab.table_column_batch_position')}</span>,
      accessor: 'position',
      width: 100,
      Cell: (row) => row.value || '-',
    },
  ];

  return (
    <div>
      <Table
        style={{ userSelect: 'text' }}
        data={batches || []}
        minRows={0}
        columns={tableColumnConfig}
        defaultPageSize={30}
        noDataText=" "
        showPagination={false}
        sortable
        loading={isLoadingBatches}
        handleClick={(row) => handleSelectBatch(row)}
        selectedRow={null}
        defaultSorted={[{ id: 'created_at', desc: true }]}
        onSortedChange={(newSorted) => { handleSortingBatches(newSorted[0]); }}
      />
    </div>
  );
};

OrderBatchTable.propTypes = {
  t: PropTypes.func.isRequired,
  batches: PropTypes.array.isRequired,
  isLoadingBatches: PropTypes.bool.isRequired,
  handleSelectBatch: PropTypes.func.isRequired,
  users: PropTypes.array,
  handleSortingBatches: PropTypes.func,
};

export default withTranslation()(OrderBatchTable);
