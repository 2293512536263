import React from 'react';
import Base from './Base';

export default (props) => (<Base {...props}>
  <g>
    <path
      d="M51.6,46.4c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.2l20.7-18c0.2-0.1,0.3-0.3,0.3-0.6s-0.1-0.4-0.2-0.6
C67.5,21.4,60,18,52,17.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.2,0.3-0.2,0.6v27.4C51.2,46,51.4,46.2,51.6,46.4z"
    />
    <path
      d="M49,49V18.1c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.1-0.4-0.2-0.6-0.2c-17.1,1-30.5,15.1-30.5,32.2c0,6.5,2,12.9,5.8,18.3
c0.1,0.2,0.3,0.3,0.5,0.3c0,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.5-0.2l24.2-18.4C48.9,49.5,49,49.3,49,49z"
    />
    <path
      d="M50.1,52.3c-0.1-0.2-0.3-0.4-0.5-0.4c-0.2-0.1-0.5,0-0.6,0.1L25.7,69.6c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0,0.4,0.2,0.6
c6.2,7.1,15,11.1,24.4,11.1c4.4,0,8.8-0.9,12.9-2.7c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.1-0.4,0-0.6L50.1,52.3z"
    />
    <path
      d="M75.8,30.1c-0.1-0.2-0.3-0.3-0.5-0.3c-0.2,0-0.4,0-0.6,0.2L51.9,49.9c-0.3,0.2-0.3,0.6-0.2,0.9l13.4,26.4
c0.1,0.2,0.3,0.3,0.5,0.4c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4-0.1c10-5.8,16.2-16.5,16.2-28C82.3,42.1,80.2,35.7,75.8,30.1z"
    />
  </g>
</Base>);
