import api from 'helpers/api';
import { Notification } from 'shared';

export const getWarehouseData = async (companyId, locationId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/stocks/?company=${companyId}&location=${locationId}${filters}&limit=30`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching warehouse data', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getWarehouseDataURL = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching warehouse data', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getListOfWarehouses = async (locationId) => {
  try {
    const res = await api
      .get(`/api/v1/orders/warehouses/?location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching warehouses list', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getWarehousePallets = async (companyId, locationId, filters) => {
  const urlString = '/api/v1/wms/pallets/';
  let urlParams = `?limit=30&get_stock=${true}`;
  if (companyId) {
    urlParams += `&company=${companyId}`;
  }
  if (locationId) {
    urlParams += `&location=${locationId}`;
  }
  let targetUrl = urlString + urlParams;

  if (filters) {
    targetUrl += `${filters}`;
  }
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching pallets', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedWarehousePallets = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching warehouse pallets', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getWarehousePositions = async (companyId, locationId, filter) => {
  const urlString = '/api/v1/wms/warehousepositions/';
  let urlParams = '?limit=30';
  if (companyId) {
    urlParams += `&company=${companyId}`;
  }
  if (locationId) {
    urlParams += `&location=${locationId}`;
  }
  urlParams += `&expand=${true}`;
  let targetUrl = urlString + urlParams;
  if (filter) {
    targetUrl += `${filter}`;
  }
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching positions', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedWarehousePositions = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching positions', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPartners = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/partners/partners?company=${companyId}&limit=9999`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching partners', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrders = async (companyId, location = '', filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/orders/?company=${companyId}&location=${location}${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching orders', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const issuePallets = (companyId, locationId, data) => {
  return api.post(`/api/v1/wms/pallets/${data.pallet}/issue/?location=${locationId}&company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', error?.response?.data?.message || 'We could not perform your request, please try again.');
    });
};

export const issueToProduction = (companyId, locationId, data) => {
  return api.post(`/api/v1/wms/pallets/${data.pallet}/allocate/?location=${locationId}&company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', error?.response?.data?.message || 'We could not perform your request, please try again.');
    });
};

export const getGoodsMovement = async (companyId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/wms/goods_movements/?company=${companyId}${filters}&limit=30`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching goods data', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedGoodsMovement = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching goods data', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProductTypes = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/product_types/?company=${companyId}&order_by=name&limit=9999`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching product types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const splitPallet = (companyId, locationId, id, data) => {
  return api.post(`/api/v1/wms/pallets/${id}/split/?location=${locationId}&company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const addPallet = (data) => {
  return api.post('/api/v1/wms/pallets/bulk_create/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};
