import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import {
  ComposedChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { formatTooltip } from '../helpers';

class MaintenanceHistogram extends Component {
  customizedAxisTick = (props) => {
    // eslint-disable-next-line react/prop-types
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-45)"
          fontSize="11px"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  convertSecondsToHHMMSS = (seconds) => {
    const days = Math.floor(seconds / 86400); // Number of seconds in a day (24 * 60 * 60)
    const remainingSeconds = seconds % 86400;

    const hours = Math.floor(remainingSeconds / 3600);
    const minutes = Math.floor((remainingSeconds % 3600) / 60);
    const secondsLeft = remainingSeconds % 60;

    const formattedTime = [
      String(days).padStart(2, '0'),
      [String(hours).padStart(2, '0'), String(minutes).padStart(2, '0'), String(secondsLeft).padStart(2, '0')].join(':'),
    ].join(' - ');

    return formattedTime;
  }

  render() {
    const { data, height, chartTitle, showValuesAsDate } = this.props;
    return (
      <React.Fragment>
        {chartTitle && <p>{chartTitle}</p>}
        <ResponsiveContainer width="95%" height={height}>
          <ReBarChart
            data={data}
            barSize={20}
          >
            <XAxis tick={this.customizedAxisTick} height={240} dataKey="name" scale="band" interval={0} />
            <YAxis
              dataKey="value"
              tickFormatter={(value) => (showValuesAsDate ? this.convertSecondsToHHMMSS(value) : value)}
              tick={{ fontSize: 12 }}
            />
            <Tooltip
              contentStyle={{ fontSize: 12 }}
              formatter={(value) => (showValuesAsDate ? this.convertSecondsToHHMMSS(value) : `${formatTooltip(value)}`)}
              labelStyle={{ fontSize: 12 }}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar dataKey="value" fill="#364252" fillOpacity="0.9" background={{ fill: '#eee' }} />
          </ReBarChart>
        </ResponsiveContainer>
      </React.Fragment>
    );
  }
}

MaintenanceHistogram.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  chartTitle: PropTypes.string,
  showValuesAsDate: PropTypes.bool,
};

export default MaintenanceHistogram;
