import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Notification } from 'shared';
// import Select from 'react-select';
import api from 'helpers/api';

const keyLabelRows = 'rows';
const keyLabelCols = 'cols';
const keyLabelLimiter = '-';

class MetricGridPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      optionsFiltered: [],
      metricTemplate: this.props.settings.metricTemplate || '',
      metricRows: this.props.settings.metricRows || '',
      metricCols: this.props.settings.metricCols || '',
    };
  }

  componentDidMount() {
    this.getOptions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.asset !== this.props.asset) {
      this.getOptions();
    }
    if (this.props.asset === null && this.state.options.length > 0) {
      // eslint-disable-next-line
      this.setState({
        options: [],
        optionsFiltered: [],
      });
    }
  }

  getOptions = () => {
    const { asset, type } = this.props;
    const assetString = typeof asset !== 'undefined'
      ? `&asset=${asset}`
      : '';

    return api.get(`/api/v1/industry/metrics/?order_by=label${assetString}&limit=200`)
      .then((json) => {
        if (json.status === undefined || json.status === 400 || json.status === 403 || json.status === 404 || json.status === 500 || !json || !json.data || !json.data.results) {
          return Notification('error', 'An error occurred while fetching metrics', 'We could not perform your request, please try again.');
        }
        this.setState({
          options: (type === 'scalar' || type === 'gauge') ? [...get(json, 'data.results'), { label: 'OEE', value_type: { unit: '' } }] : get(json, 'data.results'),
        }, () => {
          if (asset !== null && this.state.metricTemplate !== '') {
            this.getSelectedOptions();
          }
        });
      });
  }

  getSelectedOptions = () => {
    const {
      updateField,
      changeMetrics,
    } = this.props;
    const {
      options,
      metricTemplate,
      metricRows,
      metricCols,
    } = this.state;

    // eslint-disable-next-line
    let optionsFiltered = [];

    const optRowsArr = metricRows.split(keyLabelLimiter) || [0, 0];
    const optColsArr = metricCols.split(keyLabelLimiter) || [0, 0];

    for (let row = optRowsArr[0]; row <= optRowsArr[1]; row++) {
      for (let col = optColsArr[0]; col <= optColsArr[1]; col++) {
        let query = metricTemplate.replace(`{${keyLabelRows}}`, row);
        query = query.replace(`{${keyLabelCols}}`, col);
        const filtered = options.filter((opt) => opt.code === query);
        filtered.forEach((optFiltered) => {
          optionsFiltered.push(optFiltered);
        });
      }
    }

    updateField('metricTemplate', metricTemplate);
    updateField('metricCols', metricCols);
    updateField('metricRows', metricRows);

    changeMetrics(optionsFiltered);

    this.setState({
      optionsFiltered,
    });
  }

  handleMetricTemplate = (evt) => {
    this.setState({
      metricTemplate: evt.target.value,
    }, () => {
      this.getSelectedOptions();
    });
  }

  handleMetricRows = (evt) => {
    this.setState({
      metricRows: evt.target.value,
    }, () => {
      this.getSelectedOptions();
    });
  }

  handleMetricCols = (evt) => {
    this.setState({
      metricCols: evt.target.value,
    }, () => {
      this.getSelectedOptions();
    });
  }

  toggleOptionsList = () => {
    const el = document.querySelector('.options-list');
    const computedStyle = window.getComputedStyle(el);
    if (el) {
      if (computedStyle.getPropertyValue('display') && el.style.display === 'none') {
        el.style.display = 'flex';
      } else {
        el.style.display = 'none';
      }
    }
  }

  render() {
    const {
      settings,
      // disabled,
      // id,
      // t,
      // clearable,
    } = this.props;

    const {
      // metric,
      // options,
      optionsFiltered,
    } = this.state;

    /*
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderTop: state.data && state.data.label && state.data.label === 'OEE' ? '2px solid #3184ff' : '',
      }),
    };
    */

    return (
      <table>
        <tbody>
          <tr>
            <td>
              <label>Metric Template</label>
            </td>
            <td>
              <input
                onKeyUp={(e) => { this.handleMetricTemplate(e); }}
                type="text"
                defaultValue={settings.metricTemplate || ''}
                style={{ maxWidth: '250px' }}
                placeholder={`ie. label1{${keyLabelRows}}_label2{${keyLabelCols}}`}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Rows range {`{${keyLabelRows}}`}:</label>
            </td>
            <td>
              <input
                onKeyUp={(e) => { this.handleMetricRows(e); }}
                type="text"
                defaultValue={settings.metricRows || ''}
                style={{ maxWidth: '250px' }}
                placeholder={`min${keyLabelLimiter}max (ie. 1${keyLabelLimiter}10)`}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label>Cols range {`{${keyLabelCols}}`}:</label>
            </td>
            <td>
              <input
                onKeyUp={(e) => { this.handleMetricCols(e); }}
                type="text"
                defaultValue={settings.metricCols || ''}
                style={{ maxWidth: '250px' }}
                placeholder={`min${keyLabelLimiter}max (ie. 1${keyLabelLimiter}5)`}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label
                onClick={() => { this.toggleOptionsList(); }}
              >
                Metrics
              </label>
            </td>
            <td>
              <button
                className={`btn normal ${(optionsFiltered.length > 0) ? 'success' : 'disabled'}`}
                onClick={() => { this.toggleOptionsList(); }}
              >
                {optionsFiltered.length}
              </button>
              <div className="options-list" style={{ display: 'none', flexWrap: 'wrap' }}>
                {optionsFiltered
                  .sort((a, b) => { return (a.code < b.code) ? -1 : 1; })
                  .map((x, idx) => {
                    return (<div
                      key={`metric_${idx}`}
                      style={{ margin: '3px', padding: '3px', fontSize: '11px', background: '#eee', color: '#222' }}
                    >
                      {x.code}
                    </div>
                    );
                  })}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

MetricGridPicker.propTypes = {
  // disabled: PropTypes.bool,
  asset: PropTypes.number,
  changeMetrics: PropTypes.func,
  updateField: PropTypes.func,
  type: PropTypes.string,
  settings: PropTypes.object,
};

MetricGridPicker.defaultProps = {
  changeMetric: null,
  disabled: false,
};

export default withTranslation()(MetricGridPicker);
