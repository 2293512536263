import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { modalSizes } from 'shared/constants';
import { Modal, ContentLoader } from 'shared';
import { getLocale } from 'shared/DatePicker/constants';
import { multiSelectStylesAutoHeight } from 'styles/modules/reactSelect';
import './styles.scss';

import { addExceptionDay, editExceptionDay, getDepartmentShifts } from '../actions';

const ExceptionModal = ({
  t,
  isOpen,
  companyId,
  handleCloseModal,
  initialValues = {},
  closeModalAndRefetch,
  departments,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingShifts, setIsLoadingShifts] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [shifts, setShifts] = useState([]);

  const fetchAllShifts = () => {
    let apiFilters = '&limit=9999&order_by=order';

    if (formValues?.department?.length > 0) {
      formValues?.department?.forEach((dep) => {
        if (shifts?.some((shift) => shift.department?.id === dep?.id)) return;

        setIsLoadingShifts(true);
        getDepartmentShifts(companyId, apiFilters + `&department=${dep?.id}`)
          .then((res) => {
            const response = get(res, 'data.results', []);
            if (response) setShifts((prevState) => ([ ...prevState, ...response ]));
          })
          .catch(() => {
            setShifts([]);
            setIsLoadingShifts(false);
          });
        });
        setIsLoadingShifts(false);
    } else if (formValues?.department) {
      const departmentId = typeof formValues?.department === 'object' ? formValues?.department?.id : formValues?.department;
      if (departmentId) {
        apiFilters += `&department=${departmentId}`;
  
        setIsLoadingShifts(true);
        getDepartmentShifts(companyId, apiFilters)
          .then((res) => {
            setShifts(get(res, 'data.results', []));
            setIsLoadingShifts(false);
          })
          .catch(() => {
            setShifts([]);
            setIsLoadingShifts(false);
          });
      }
    }
  };

  useEffect(() => {
    if (formValues?.department) fetchAllShifts();
  }, [formValues?.department]);

  const onChange = (key, value) => {
    if (key === 'is_working_day' && !value) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [key]: value,
        begin_time: null,
        end_time: null,
        begin_break: null,
        end_break: null,
      }));
    } else if (key === 'department' && !initialValues?.id) {
      const updatedDepShifts = formValues?.department_shift?.filter((shift) => value?.some((dep) => dep.id === shift.department?.id));
      const updatedShifts = shifts?.filter((shift) => value?.some((dep) => dep.id === shift.department?.id));

      setFormValues((prevValues) => ({
        ...prevValues,
        department: value,
        department_shift: updatedDepShifts,
      }));
      setShifts(updatedShifts);
    } else if (key === 'department' && initialValues?.id) {
      setFormValues((prevValues) => ({
        ...prevValues,
        department: value,
        department_shift: null,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (formValues?.id) {
      const payload = {
        ...formValues,
        company: companyId,
        date: moment(formValues.date).format('YYYY-MM-DD'),
        department: typeof formValues?.department === 'object' ? formValues?.department?.id : formValues?.department,
        department_shift: typeof formValues?.department_shift === 'object' ? formValues?.department_shift?.id : formValues?.department_shift,
      };
      await editExceptionDay(formValues?.id, companyId, payload);
    } else {
      const addDayPromises = (formValues?.department ?? []).flatMap((dep) => {
        return (formValues?.department_shift?.filter((shift) => shift.department?.id === dep?.id) ?? []).map((shift) => {
          const payload = {
            ...formValues,
            company: companyId,
            date: moment(formValues.date).format('YYYY-MM-DD'),
            department: dep?.id,
            department_shift: shift?.id,
          };
          return addExceptionDay(companyId, payload);
        });
      });

      await Promise.all(addDayPromises);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  const customFormatOptionLabel = (option, { context }) => {
    const style = {};
    let label = `${option?.name} (${moment(option?.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(option?.end_time, 'HH:mm:ss').format('HH:mm')}) - ${option?.department?.name}`;
    const depShiftFound = formValues?.department_shift?.find((shift) => shift.id === option.id);
    if (depShiftFound && context === 'menu') {
      style.color = '#10ac84';
      label += ` (${t('page_content.human_resources.shifts.exceptions.selected')})`;
    }

    return <div style={style}>{label}</div>;
  };

  const multiSelectStylesAutoHeightCustom = {
    control: (provided) => ({
      ...provided,
      borderRadius: 0,
      maxWidth: '100%',
      minHeight: '34px',
      padding: 0,
      fontSize: '13px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      minHeight: 34,
      maxHeight: 220,
      height: 'fit-content',
      padding: '0px 0px 0px 5px',
      overflowY: 'scroll',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0px 3px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
      paddingRight: 10,
      color: 'black',
      svg: {
        width: '15px',
        height: '15px',
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 0,
      zIndex: 2,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '13px',
      fontWeight: 500,
      padding: '6px 12px',
      background: state.isSelected ? 'rgba(16, 172, 132, 0.15)' : provided.background,
      color: state.isSelected ? '#10ac84' : provided.color,
    }),
  };

  return (
        <Modal
          isOpen={isOpen}
          handleSave={handleSave}
          size={modalSizes.medium}
          handleClose={handleCloseModal}
          disableSave={!formValues?.date || ((formValues?.id && !formValues?.department) || (!formValues?.id && formValues?.department?.length === 0 && formValues?.department_shift?.length === 0))}
        >
            {isLoading ? (
                <ContentLoader />
            ) : (
                <div className="exception_modal_container">
                    <div className="modal_row">
                      <div className="left_text">{t('page_content.human_resources.shifts.exceptions.date')}:*</div>
                      <div className="right_datePicker">
                        <DatePicker
                          dateFormat="dd.MM.yyyy"
                          selected={get(formValues, 'date', '') ? moment(formValues.date, 'HH:mm').toDate() : null}
                          onChange={(e) => onChange('date', e)}
                          showTimeSelect={false}
                          disabledKeyboardNavigation
                          locale={getLocale(t)}
                        />
                      </div>
                    </div>
                    <div className="modal_row">
                      <div className="left_text">{t('page_content.human_resources.shifts.exceptions.is_working_day')}:</div>
                      <div className="right_checkbox">
                        <input
                          type="checkbox"
                          checked={get(formValues, 'is_working_day', false)}
                          onChange={(e) => onChange('is_working_day', e.target.checked)}
                        />
                      </div>
                    </div>
                    <div className="modal_row">
                      <div className="left_text">{t('page_content.human_resources.shifts.exceptions.begin_time')}:</div>
                      <div className="right_datePicker">
                        <DatePicker
                          selected={get(formValues, 'begin_time', '') ? moment(formValues.begin_time, 'HH:mm').toDate() : null}
                          onChange={(time) => onChange('begin_time', time ? moment(time).format('HH:mm') : null)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeCaption={t('date_picker_locale.time_translation')}
                          timeFormat="HH:mm"
                          dateFormat="HH:mm"
                          disabled={!get(formValues, 'is_working_day', false)}
                        />
                      </div>
                    </div>
                    <div className="modal_row">
                    <div className="left_text">{t('page_content.human_resources.shifts.exceptions.end_time')}:</div>
                      <div className="right_datePicker">
                        <DatePicker
                          selected={get(formValues, 'end_time', '') ? moment(formValues.end_time, 'HH:mm').toDate() : null}
                          onChange={(time) => onChange('end_time', time ? moment(time).format('HH:mm') : null)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeCaption={t('date_picker_locale.time_translation')}
                          timeFormat="HH:mm"
                          dateFormat="HH:mm"
                          disabled={!get(formValues, 'is_working_day', false)}
                        />
                      </div>
                    </div>
                    <div className="modal_row">
                    <div className="left_text">{t('page_content.human_resources.shifts.exceptions.begin_break')}:</div>
                      <div className="right_datePicker">
                        <DatePicker
                          selected={get(formValues, 'begin_break', '') ? moment(formValues.begin_break, 'HH:mm').toDate() : null}
                          onChange={(time) => onChange('begin_break', time ? moment(time).format('HH:mm') : null)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeCaption={t('date_picker_locale.time_translation')}
                          timeFormat="HH:mm"
                          dateFormat="HH:mm"
                          disabled={!get(formValues, 'is_working_day', false)}
                        />
                      </div>
                    </div>
                    <div className="modal_row">
                    <div className="left_text">{t('page_content.human_resources.shifts.exceptions.end_break')}:</div>
                      <div className="right_datePicker">
                        <DatePicker
                          selected={get(formValues, 'end_break', '') ? moment(formValues.end_break, 'HH:mm').toDate() : null}
                          onChange={(time) => onChange('end_break', time ? moment(time).format('HH:mm') : null)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeCaption={t('date_picker_locale.time_translation')}
                          timeFormat="HH:mm"
                          dateFormat="HH:mm"
                          disabled={!get(formValues, 'is_working_day', false)}
                        />
                      </div>
                    </div>
                    <div className="modal_row">
                    <div className="left_text">{t('page_content.human_resources.shifts.exceptions.department')}:*</div>
                      <div className="right_select">
                        <Select
                          options={departments}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isSearchable
                          isClearable
                          isMulti={!initialValues?.id}
                          menuPosition="fixed"
                          onChange={(val) => onChange('department', val)}
                          value={get(formValues, 'department', null) ?? departments?.find((val) => val.id === formValues?.department?.id)}
                          styles={multiSelectStylesAutoHeight}
                        />
                      </div>
                    </div>
                    <div className="modal_row">
                    <div className="left_text">{t('page_content.human_resources.shifts.exceptions.department_shift')}:*</div>
                      <div className="right_select">
                        <Select
                          options={shifts}
                          getOptionLabel={(option) =>`${option?.name} (${moment(option?.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(option?.end_time, 'HH:mm:ss').format('HH:mm')})${!initialValues?.id ? ` - ${option?.department?.name}` : ''}`}
                          getOptionValue={(option) => option.id}
                          isSearchable
                          isClearable
                          isMulti={!initialValues?.id}
                          formatOptionLabel={!initialValues?.id && customFormatOptionLabel}
                          menuPosition="fixed"
                          onChange={(val) => onChange('department_shift', val)}
                          value={get(formValues, 'department_shift', null) ?? (shifts?.find((val) => val.id === formValues?.department_shift?.id) || null)}
                          styles={!initialValues?.id ? multiSelectStylesAutoHeightCustom : multiSelectStylesAutoHeight}
                          isDisabled={!get(formValues, 'department', false)}
                          isLoading={isLoadingShifts}
                          hideSelectedOptions={!!initialValues?.id}
                          closeMenuOnSelect={!!initialValues?.id}
                        />
                      </div>
                    </div>
                </div>
            )}
        </Modal>
  );
};

ExceptionModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  departments: PropTypes.array,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  companyId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(ExceptionModal));
