import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { widgetTypes } from '../constants';
import './styles.scss';

const renderIcon = (widgetType) => {
  const widget = widgetTypes.find((w) => w.type === widgetType);
  return widget ? (<div>{widget.icon}<span>{widget.name}</span></div>) : null;
};

class WidgetPlaceholder extends Component {
  render() {
    const { type } = this.props;

    return (
      <div className="widget-placeholder" style={{ height: '100%' }}>
        {renderIcon(type)}
      </div>
    );
  }
}

WidgetPlaceholder.propTypes = {
  type: PropTypes.string,
};

export default WidgetPlaceholder;
