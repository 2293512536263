import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Notification } from 'shared';
import { checkAccess } from 'industry/helpers';

class AccessControlledNavLink extends Component {
  getPermissionForModule = (module) => {
    const {
      match: {
        params: { locationId, companyId },
      },
      permissionList,
      isSettings,
    } = this.props;

    let path = `/${companyId}/industry/location/${locationId}`;

    if (isSettings) {
      path += '/settings';
    }

    if (module) {
      path += `/${module}`;
    }

    return permissionList[path] ? permissionList[path] : 0;
  }

  handleUserNavigation = (e) => {
    e.preventDefault();
    const {
      match: {
        params: { companyId },
      },
      history,
    } = this.props;
    const source = e.target || e.srcElement;

    const getHref = (el) => {
      if (el.href) {
        return el.href;
      }
      return getHref(el.parentElement);
    };
    const url = new URL(getHref(source));

    let resource;
    if (url.pathname.includes('parquet-counting')) {
      resource = url.pathname.replace('parquet-counting', 'upload');
    } else {
      resource = url.pathname;
    }

    checkAccess(resource, companyId)
      .then((re) => {
        const data = re.data;
        const access = data.access;
        if (access === 0) {
          Notification('error', 'Access denied', 'You do not have the authorization to access this resource.');
        } else if (access === 10 || access === 5) {
          // full access
          history.push(url.pathname, { forced: true });
        } else if (access === 1) {
          // read only
          if (!window.resourceReadOnly) {
            window.resourceReadOnly = [];
          }

          window.resourceReadOnly.push(resource);
          history.push(url.pathname, { forced: true });
        }
      });
  }

  hasAccessToModule(module, moduleUrlName, checkAccessToModule) {
    const { currentUser, userIsCompanyAdmin, modules, isSettings } = this.props;

    if (isSettings) {
      if (currentUser && currentUser.is_admin) {
        return true;
      }

      if ((window.userIsCompanyAdmin || userIsCompanyAdmin)) {
        return true;
      }

      if (this.getPermissionForModule(moduleUrlName) !== 0) {
        return true;
      }
    } else {
      if (!checkAccessToModule) {
        return true;
      }

      if (checkAccessToModule && modules[module] && currentUser && currentUser.is_admin) {
        return true;
      }

      if (checkAccessToModule && modules[module] && (window.userIsCompanyAdmin || userIsCompanyAdmin)) {
        return true;
      }

      if (checkAccessToModule && modules[module] && this.getPermissionForModule(moduleUrlName) !== 0) {
        return true;
      }
    }

    return false;
  }

  render() {
    const { module, moduleUrlName, linkTo, navText, checkAccessToModule, icon, activeClassName, isSettings } = this.props;

    if (this.hasAccessToModule(module, moduleUrlName, checkAccessToModule, isSettings)) {
      return (
        <NavLink
          exact
          to={linkTo}
          activeClassName={activeClassName}
          onClick={this.handleUserNavigation}
        >
          <span>
            {icon}
          </span>
          {navText}
        </NavLink>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    userIsCompanyAdmin: state.app.userIsCompanyAdmin,
    company: state.app.company,
    currentUser: state.currentUser,
  };
};

AccessControlledNavLink.defaultProps = {
  isSettings: false,
  activeClassName: 'active',
  checkAccessToModule: true,
  module: '',
  modules: {},
  icon: '',
  navText: '',
};

AccessControlledNavLink.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  userIsCompanyAdmin: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  module: PropTypes.string,
  modules: PropTypes.object,
  moduleUrlName: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  navText: PropTypes.string.isRequired,
  permissionList: PropTypes.object.isRequired,
  checkAccessToModule: PropTypes.bool.isRequired,
  icon: PropTypes.node,
  activeClassName: PropTypes.string,
  isSettings: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps, null)((AccessControlledNavLink)));
