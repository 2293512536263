import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import Select from 'react-select';
import api from 'helpers/api';

class AssetGroupTypePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAssetGroupType: props.asset_group_type,
      options: [],
    };
  }

  componentDidMount() {
    const { companyId } = this.props;
    api.get(`/api/v1/asset_group_types/?company=${companyId}`)
      .then((json) => {
        this.setState({
          options: get(json, 'data.results'),
        });
      });
  }

  componentDidUpdate(prevProps) {
    const { asset_group_type } = this.props;
    if (asset_group_type !== prevProps.asset_group_type) {
      // eslint-disable-next-line
      this.setState({ selectedAssetGroupType: asset_group_type });
    }
  }

  handleChange = (assetGroupType) => {
    const { handleChange } = this.props;
    this.setState({ selectedAssetGroupType: assetGroupType });
    handleChange(assetGroupType);
  }

  render() {
    const { selectedAssetGroupType, options } = this.state;
    const { htmlId, customClassName, t } = this.props;

    return (
      <Select
        cacheOptions
        isClearable
        id={htmlId}
        options={options}
        getOptionLabel={(option) => option.description}
        getOptionValue={(option) => option.id}
        onChange={this.handleChange}
        placeholder={t('shared.asset_group_type_picker.placeholder')}
        value={(options === undefined ? [] : options).find((option) => option.id === selectedAssetGroupType)}
        className={`AscaliaSelect AssetGroupTypePicker ${customClassName}`}
      />
    );
  }
}

AssetGroupTypePicker.propTypes = {
  asset_group_type: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  htmlId: PropTypes.string,
  customClassName: PropTypes.string,
  companyId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

AssetGroupTypePicker.defaultProps = {
  customClassName: '',
};

export default withTranslation()(AssetGroupTypePicker);
