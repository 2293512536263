import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import api from 'helpers/api';
import {
  Field,
  Form,
  Formik,
  ErrorMessage,
} from 'formik';
import { Notification } from 'shared';
import { ToastContainer } from 'react-toastify';
import * as userActions from 'user/actions';
import * as actions from '../../actions';
import './../style.scss';

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  render() {
    const {
      currentUser,
    } = this.props;

    const redirect_url = `${window.location.origin}/reset-password`;

    return (
      <div className={`Auth ${currentUser ? 'authed' : ''}`}>
        <Formik
          initialValues={{ email: '', redirect_url }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Required';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            userActions.forgotPassword(values)
              .then((re) => {
                setSubmitting(false);
                if (re.response && re.response.status && re.response.status !== 200) {
                  Notification('error', 'Error', re.response.data.non_field_errors);
                } else {
                  localStorage.setItem('reset_email', values.email);
                  window.location.href = '/reset-message';
                }
              })
              .catch((error) => {
                Notification('error', 'Error', error.response.data.non_field_errors);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            isSubmitting,
          }) => (
            <div className="form-container login-form-container">
              <Form ref={this.formRef} className="form-class">
                <img src="/ascalia_logo.svg" alt="Ascalia logo" width="140px" />
                <header>
                  <h1>Password Recovery</h1>
                </header>
                <div>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                    value={values.email}
                  />
                  <ErrorMessage className="error" name="email" component="p" />
                </div>
                <p id="forgot-password-link">
                  <button type="button" onClick={() => { window.location.href = '/login'; }}>Back to Login</button>
                </p>
                <section className="bottom">
                  <button
                    type="submit"
                    className="submit-button"
                    disabled={isSubmitting}
                  >
                    Reset Password
                  </button>
                </section>
              </Form>
            </div>
          )}
        </Formik>
        <ToastContainer
          autoClose={5000}
          closeButton={false}
          toastClassName="Notification-container"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.app,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  fetchPageMeta: actions.fetchPageMeta,
  getCurrentUser: userActions.getCurrentUser,
};

ForgotPasswordPage.propTypes = {
  currentUser: PropTypes.any,
  // history: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage));
