import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import './style.scss';

const Button = ({
  buttonType,
  children,
  disabled,
  onClick,
  size,
  style,
  title,
  tooltip,
  type,
  font,
  loading,
  loadingText,
  onMouseOver,
}) => {
  return (
    <Tooltip
      mouseEnterDelay={0.5}
      mouseLeaveDelay={0}
      overlay={tooltip || ''}
      placement="bottom"
      trigger={tooltip ? ['hover'] : []}
    >
      <button
        className={`btn ${size} ${type} ${font}`}
        disabled={disabled}
        onClick={!disabled && onClick}
        size={size}
        title={title}
        type={buttonType}
        alt={title}
        style={style}
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        onMouseOver={onMouseOver}
      >
        {loading ? <div className="button_loader_wrapper">
          {loadingText}
          <div className="button_loader" />
        </div> : children}
      </button>
    </Tooltip>
  );
};

Button.propTypes = {
  buttonType: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['tiny', 'small', 'normal', 'large']),
  font: PropTypes.oneOf(['normal', 'bold']),
  style: PropTypes.object,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  children: PropTypes.any,
  type: PropTypes.oneOf(['transparent', 'plain', 'success', 'warning', 'info', 'danger', 'icon', 'dark-blue', 'add', 'export', 'delete']),
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  onMouseOver: PropTypes.func,
};

Button.defaultProps = {
  buttonType: 'button',
  disabled: false,
  size: 'normal',
  style: undefined,
  title: '',
  type: 'plain',
  font: 'bold',
  loading: false,
  loadingText: '',
};

export default Button;
