import React, { Component } from 'react';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { Button, Notification } from 'shared';
import { checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import DashboardList from '../../components/DashboardList';
import AddDashboardModal from './components/AddDashboardModal';

class IndustryLocationDashboardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddDashboardModal: false,
      isReadOnly: false,
      permissionList: [],
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;
    this.getPermissionList();

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });
  }

  getPermissionList = () => {
    const { companyId } = this.props;

    api.get(`/api/v1/permission_list/?company=${companyId}`)
      .then((res) => {
        this.setState({
          permissionList: res.data || {},
        });
      })
      .catch((error) => Notification('error', 'An error occurred while fetching permission list', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.'));
  }

  // This function returns all of the ids from permissionList object that user have access to
  getDashboardsIds = (data) => {
    const dashboards = Object.keys(data).filter((key) => key.includes('dashboards/'))
      .reduce((cur, key) => { return Object.assign(cur, { [key]: data[key] }); }, {});

    const asArray = Object.entries(dashboards);

    // eslint-disable-next-line no-unused-vars
    const filtered = asArray.filter(([key, value]) => value !== 0);

    const justStrings = Object.fromEntries(filtered);

    const keys = Object.keys(justStrings);

    const ids = [];

    for (let i = 0; i < keys.length; i++) {
      ids.push(Number(keys[i].split('dashboards/')[1]));
    }

    return ids;
  }

  render() {
    const {
      showAddDashboardModal,
      isReadOnly,
      permissionList,
    } = this.state;

    const {
      dashboards,
      locationId,
      companyId,
      addOrUpdateDashboardSuccess,
      history,
      t,
      userIsCompanyAdmin,
      currentUser,
    } = this.props;

    let dashboardsList = null;
    if (currentUser && currentUser.is_admin === false && !userIsCompanyAdmin) {
      dashboardsList = permissionList ? this.getDashboardsIds(permissionList) : {};
    }

    const hasFavs = dashboards.filter((d) => d.is_favorite);
    const dashboardsForSearch = dashboards.map((d) => { return { label: d.title, value: d.id }; });

    let filteredDashboardsForSearch = [];

    if (currentUser && currentUser.is_admin === false && !userIsCompanyAdmin) {
      filteredDashboardsForSearch = dashboardsForSearch.filter((el) => {
        return dashboardsList.indexOf(el.value) >= 0;
      });
    }

    return (
      <div className="industry-tab industry-location-dashboard-list fullscreen">
        <header>
          <div className="controls">
            &nbsp;
          </div>
          <div className="meta">
            <Button
              type="add"
              onClick={() => this.setState({ showAddDashboardModal: true })}
              title="Add new dashboard"
              disabled={isReadOnly}
            >
              {t('page_content.dashboards.add_dashboard_button')}
            </Button>
          </div>
        </header>
        <main style={{ marginBottom: '30px' }}>
          <Select
            options={filteredDashboardsForSearch && filteredDashboardsForSearch.length ? filteredDashboardsForSearch : dashboardsForSearch}
            isSearchable
            name="dashboard"
            placeholder={t('page_content.dashboards.find_a_dashboard_placeholder')}
            onChange={(d) => history.push(`/${companyId}/industry/location/${locationId}/dashboards/${d.value}`, { forced: true })}
          />
          <div style={{ margin: '10px 0' }} />
          {
            hasFavs && hasFavs.length ?
              <div style={{ marginBottom: '30px' }}><DashboardList
                dashboards={dashboards.filter((d) => d.is_favorite)}
                companyId={companyId}
                locationId={locationId}
                isFavorite
              /> </div> : ''
          }
          <DashboardList
            dashboards={dashboards.filter((d) => !d.is_favorite)}
            companyId={companyId}
            locationId={locationId}
            isFavorite={false}
            totalDashboardsCount={dashboards.length}
          />
        </main>
        {
          showAddDashboardModal &&
          <AddDashboardModal
            onClose={() => this.setState({ showAddDashboardModal: false })}
            addOrUpdateDashboardSuccess={addOrUpdateDashboardSuccess}
            locationId={locationId}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userIsCompanyAdmin: state.app.userIsCompanyAdmin,
    currentUser: state.currentUser,
  };
};

IndustryLocationDashboardList.defaultProps = {
  dashboards: [],
  addOrUpdateDashboardSuccess: () => { },
};

IndustryLocationDashboardList.propTypes = {
  dashboards: PropTypes.array.isRequired,
  addOrUpdateDashboardSuccess: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  userIsCompanyAdmin: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(IndustryLocationDashboardList)));
