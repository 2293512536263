import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table } from 'shared';
import { defaultDateTimeFormat } from 'shared/constants';
import { withTranslation } from 'react-i18next';
import { TableButtons } from '../../../../../shared/index';

class OrderSawData extends React.Component {
  render() {
    return (
      <div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.saw_data_tab.table_column_time')}</span>,
              accessor: 'datetime',
              Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.saw_data_tab.table_column_asset')}</span>,
              accessor: 'asset.name',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.saw_data_tab.table_column_schedule')}</span>,
              accessor: 'schedule',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.saw_data_tab.table_column_pattern_number')}</span>,
              accessor: 'pattern_number',
              Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.saw_data_tab.table_column_parts')}</span>,
              accessor: 'parts',
              Cell: (row) => <span>{row.value ? row.value : '0'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.saw_data_tab.table_column_rests')}</span>,
              accessor: 'rests',
              Cell: (row) => <span>{row.value ? row.value : '0'}</span>,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{this.props.t('page_content.orders.order_details.saw_data_tab.table_column_waste')}</span>,
              accessor: 'waste',
              Cell: (row) => <span>{row.value ? row.value : '0'}</span>,
              style: {
                cursor: 'default',
              },
            },
          ]}
          data={this.props.sawData || []}
          minRows={0}
          defaultPageSize={30}
          noDataText=" "
          showPagination={false}
          sortable
          loading={this.props.isLoadingSawData}
          defaultSorted={[{ id: 'datetime', desc: true }]}
          onSortedChange={(newSorted) => { this.props.handleSortingSawData(newSorted[0]); }}
        />
        <div style={{ float: 'right' }}>
          <TableButtons previous={this.props.previousSawData} next={this.props.nextSawData} fetchFunction={this.props.getPaginatedSawData} count={this.props.count} />
        </div>
      </div>
    );
  }
}

OrderSawData.propTypes = {
  t: PropTypes.any,
  sawData: PropTypes.array,
  isLoadingSawData: PropTypes.bool,
  previousSawData: PropTypes.any,
  nextSawData: PropTypes.any,
  getPaginatedSawData: PropTypes.func,
  count: PropTypes.number,
  handleSortingSawData: PropTypes.func,
};

export default withTranslation()(OrderSawData);
