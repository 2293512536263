/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button } from 'shared';
import notMarked from 'shared/Icons/not-marked-color.jpg';

class UploadStepThree extends React.Component {
  getFormattedData = (data) => {
    const formattedData = [];
    Object.keys(data).forEach((key) => {
      formattedData.push({
        class: key,
        result: data[key],
      });
    });

    return formattedData;
  }

  getHexByColor = (color) => {
    switch (color) {
      case 'RED':
        return '#EE5249';
      case 'BLUE':
        return '#4285F4';
      case 'GREEN':
        return '#16A765';
      case 'YELLOW':
        return '#FFCC44';
      case 'BLACK':
        return '#000';
      default:
        return '#fff';
    }
  }

  exportTable = (uploadId) => {
    const {
      data, files, program, programDetails, selectedClasses, selectedColors,
    } = this.props;
    const tableData = this.getFormattedData(data[uploadId]);
    const csvContent = 'data:text/csv;charset=utf-8,';

    const classesArr = selectedClasses && selectedClasses.length ? selectedClasses.split(',') : [];
    const colorsArr = selectedColors && selectedColors.length ? selectedColors.split(',') : [];

    const formattedTableData = [
      [
        Array.from(files).find((f) => f.uploadId === parseInt(uploadId, 10)).name, `${program} (${programDetails && programDetails.width ? programDetails.width : '/'} mm x ${programDetails && programDetails.height ? programDetails.height : '/'} mm)`,
      ],
      [
        ' ', ' ',
      ],
      [
        'Selected classes',
      ],
      ...classesArr.map((item) => [
        item,
      ]),
      [
        ' ', ' ',
      ],
      [
        'Selected colors',
      ],
      ...colorsArr.map((item) => [
        item,
      ]),
      [
        ' ', ' ',
      ],
      [
        'Class',
        'Result',
      ],
      ...tableData.map((item) => [
        item.class,
        item.result,
      ]),
    ]
      .map((e) => e.join(';'))
      .join('\n');

    const encodedUri = encodeURI(csvContent + formattedTableData);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${Array.from(files).find((f) => f.uploadId === parseInt(uploadId, 10)).name}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  exportTableAll = () => {
    const {
      data, files, program, programDetails, selectedClasses, selectedColors,
    } = this.props;

    const classesArr = selectedClasses && selectedClasses.length ? selectedClasses.split(',') : [];
    const colorsArr = selectedColors && selectedColors.length ? selectedColors.split(',') : [];

    const csvContent = 'data:text/csv;charset=utf-8,';
    let formattedTableData = [];

    Object.keys(data).forEach((key) => {
      const tableData = this.getFormattedData(data[key]);
      formattedTableData += [
        [
          Array.from(files).find((f) => f.uploadId === parseInt(key, 10)).name, `${program} (${programDetails && programDetails.width ? programDetails.width : '/'} mm x ${programDetails && programDetails.height ? programDetails.height : '/'} mm)`,
        ],
        [
          ' ', ' ',
        ],
        [
          'Selected classes',
        ],
        ...classesArr.map((item) => [
          item,
        ]),
        [
          ' ', ' ',
        ],
        [
          'Selected colors',
        ],
        ...colorsArr.map((item) => [
          item,
        ]),
        [
          ' ', ' ',
        ],
        [
          'Class',
          'Result',
        ],
        ...tableData.map((item) => [
          item.class,
          item.result,
        ]),
        [
          ' ',
        ],
        [
          ' ',
        ],
      ]
        .map((e) => e.join(';'))
        .join('\n');
    });

    const encodedUri = encodeURI(csvContent + formattedTableData);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Multiple files.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  render() {
    const {
      data,
      colorsData,
      program,
      programDetails,
      files,
      showList,
      photos,
      toUpload,
      t,
      selectedClasses,
      selectedColors,
    } = this.props;

    let colorsArray = selectedColors;

    if (selectedColors && selectedColors.length && !Array.isArray(colorsArray)) {
      colorsArray = selectedColors.split(',');
    }

    if (!Object.keys(data).length) {
      return (
        <div className="result" style={{ display: 'block' }}>
          <h3>{t('page_content.upload.step_three.results')}</h3>
          <p>
            {t('page_content.upload.step_three.calculating_message')}
            <div
              className="calc-loader"
              style={{
                borderLeftColor: 'transparent',
              }}
            />
          </p>
        </div>
      );
    }

    let exportAllComponent = '';
    if (Object.keys(data).length > 1) {
      exportAllComponent = (
        <div style={{ textAlign: 'right', width: '100%', margin: '0 auto' }}>
          <Button type="export" style={{ background: '#364252', color: 'white', height: '50px', width: '170px', fontSize: '18px' }} onClick={() => { this.exportTableAll(); }}>
            {t('page_content.upload.step_three.export_all_button')}
          </Button>&nbsp;&nbsp;&nbsp;
          <Button onClick={toUpload}>
            {t('page_content.upload.step_three.back_to_upload_button')}
          </Button>
        </div>
      );
    } else {
      exportAllComponent = (
        <div style={{ textAlign: 'right', width: '100%', margin: '0 auto' }}>
          <Button onClick={toUpload}>
            {t('page_content.upload.step_three.back_to_upload_button')}
          </Button>
        </div>
      );
    }

    return (
      <React.Fragment>
        {exportAllComponent}
        {Object.keys(data).map((key, index) => {
          const formattedData = this.getFormattedData(data[key]);
          const cData = colorsData[key];
          const cKeys = Object.keys(cData);
          let sum = 0;
          // eslint-disable-next-line no-return-assign
          cKeys.forEach((k) => (sum += cData[k]));

          let isMultiple = false;
          if (Object.keys(data).length > 1) {
            isMultiple = true;
          }

          return (
            <div className="result">
              <div className="col">
                <div>
                  <h3 style={{ fontSize: '24px' }}>{t('page_content.upload.step_three.results')} {isMultiple ? index + 1 : ''}</h3>
                  <div className="result__subtitle result__subtitle--text">{t('page_content.upload.step_three.results_description')}</div>
                  <div className="result__subtitle">{t('page_content.upload.step_three.name')}</div>
                  <p className="result__data" style={{ marginTop: '7px' }}><strong>{files && files.length ? Array.from(files).find((f) => f.uploadId === parseInt(key, 10)).name : ''}</strong></p>
                  <div className="result__subtitle">{t('page_content.upload.step_three.program')}</div>
                  <p className="result__data" style={{ marginTop: '7px' }}><strong>{program}<span style={{ marginLeft: '10px', border: selectedClasses && selectedClasses.length ? '1px solid #ddd' : 'none' }}>{selectedClasses || ''}</span></strong></p>
                  <div className="result__subtitle">{t('page_content.upload.step_three.selected_colors')}</div>
                  <p className="result__data" style={{ marginTop: '7px' }}>
                    <div style={{ display: 'flex' }}>
                      {
                        colorsArray && colorsArray.length ? colorsArray.map((color) => {
                          if (color === 'NOT_MARKED') {
                            return (
                              <div>
                                <div style={{ position: 'relative', width: '48px', height: '48px', border: '2px solid #eee', marginRight: '15px' }}>
                                  <img src={notMarked} style={{ width: '100%', height: '100%' }} alt="" />
                                </div>
                              </div>
                            );
                          }
                          return (
                            <div style={{ background: this.getHexByColor(color), position: 'relative', width: '48px', height: '48px', border: '2px solid #eee', marginRight: '15px' }} />
                          );
                        }) : 'No selected colors'
                      }
                    </div>
                  </p>
                  <table className="result__table">
                    <thead>
                      <tr>
                        <th className="result__subtitle">{t('page_content.upload.step_three.width')}</th>
                        <th className="result__subtitle">{t('page_content.upload.step_three.thickness')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="result__data"><strong>{programDetails && programDetails.width ? programDetails.width : '/'} mm</strong></td>
                        <td className="result__data"><strong>{programDetails && programDetails.height ? programDetails.height : '/'} mm</strong></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="result__subtitle">{t('page_content.upload.step_three.color')}</div>
                  <div className="work-process__colors">
                    {
                      Object.keys(colorsData[key]).filter((color) => color !== 'WHITE').map((color) => {
                        const classes = 'work-process__color';
                        if (color === 'NOT_MARKED') {
                          let result_not_marked = ((colorsData[key][color] / sum) * 100).toFixed(1);
                          if (Number.isNaN(result_not_marked)) {
                            result_not_marked = 0;
                          }
                          return (
                            <div>
                              <div className={classes} style={{ position: 'relative' }}>
                                <img src={notMarked} style={{ width: '100%', height: '100%' }} alt="" />
                                <span style={{ position: 'absolute', fontSize: '.8em', top: '30%', left: 0, right: 0, textAlign: 'center', color: '#666' }}>
                                  {result_not_marked} %
                                </span>
                              </div>
                              <div className="color-value">
                                {colorsData[key][color]}
                              </div>
                            </div>
                          );
                        }
                        let result_colors = ((colorsData[key][color] / sum) * 100).toFixed(1);
                        if (Number.isNaN(result_colors)) {
                          result_colors = 0;
                        }
                        return (
                          <div>
                            <div className={classes} style={{ background: this.getHexByColor(color), position: 'relative' }}>
                              <span style={{ position: 'absolute', fontSize: '.8em', top: '30%', left: 0, right: 0, textAlign: 'center', color: '#666' }}>
                                {result_colors} %
                              </span>
                            </div>
                            <div className="color-value">{colorsData[key][color]}</div>
                          </div>);
                      })
                    }
                  </div>
                  <div className="result__subtitle">{t('page_content.upload.step_three.class')}</div>
                  <table className="result__table result__table--main">
                    <tbody>
                      <tr>
                        <td className="result__subtitle">
                          {t('page_content.upload.step_three.class')}
                        </td>
                        {formattedData.map((d) => <td className="result__subtitle">{d.class}</td>)}
                      </tr>
                      <tr>
                        <td className="result__data">
                          <strong>{t('page_content.upload.step_three.results')}</strong>
                        </td>
                        {formattedData.map((d) => <td className="result__data"><strong>{d.result}</strong></td>)}
                      </tr>
                    </tbody>
                  </table>
                  <p style={{ marginTop: '30px', textAlign: 'center' }}>
                    <Button style={{ height: '50px', width: '170px', fontSize: '18px', marginRight: '50px' }} onClick={showList}>{t('page_content.upload.step_three.list_button')}</Button>
                    <Button type="export" style={{ background: '#364252', color: 'white', height: '50px', width: '170px', fontSize: '18px' }} onClick={() => { this.exportTable(key); }}>{t('page_content.upload.step_three.export_button')}</Button>
                  </p>
                </div>
              </div>
              <div className="col" style={{ paddingLeft: '20px' }}>
                <div>
                  {t('page_content.upload.step_three.photo')}
                </div>
                <div className="work-process__image-preview">
                  <img
                    src={photos && photos[key] ? photos[key] : ''}
                    alt=""
                  />
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

UploadStepThree.propTypes = {
  data: PropTypes.object,
  colorsData: PropTypes.object,
  program: PropTypes.string.isRequired,
  programDetails: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
  showList: PropTypes.func.isRequired,
  photos: PropTypes.object,
  toUpload: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  selectedClasses: PropTypes.string,
  selectedColors: PropTypes.string,
};

export default withTranslation()(UploadStepThree);
