/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import api from 'helpers/api';
import {
  ContentLoader,
  Page,
  PageContent,
  Notification,
} from 'shared/index';
import './styles.scss';

import CompanyList from '../components/CompanyList';

class AccountContainer extends Component {
  constructor() {
    super();
    this.state = {
      companies: [],
      isLoadingCompanies: false,
    };

    this.fetchCompanies = this.fetchCompanies.bind(this);
  }

  componentDidMount() {
    const { currentUser } = this.props;
    this.fetchCompanies();
    if (currentUser && currentUser.is_admin === false) {
      if (currentUser.roles && !currentUser.roles.length) {
        Notification('error', 'You have no access', 'Please contact your admin');
      }
    }
  }

  fetchCompanies() {
    this.setState({ isLoadingCompanies: true });
    api
      .get('/api/v1/companies/')
      .then((response) => {
        this.setState({
          companies: response.data.results,
          isLoadingCompanies: false,
        });
      })
      .catch(() => this.setState({ isLoadingCompanies: false }));
  }

  render() {
    const {
      companies,
      isLoadingCompanies,
    } = this.state;
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;

    if (isLoadingCompanies) {
      return (<ContentLoader />);
    }

    return (
      <Page classes={['no-subheader']}>
        <PageContent>
          <div className="account-container">
            <h1>{t('landing_page.welcome_message')}</h1>
            <p>
              {t('landing_page.description')}
            </p>
            {
              !isLoadingCompanies &&
              <main>
                <CompanyList companies={companies} />
                <div className="aside">
                  {/* <img src={`${process.env.NODE_ENV === 'development' ? '' : '/static/'}customer-icon.svg`} alt="Customer support icon" />
                  <p>
                    If you have any questions feel free to <a href="mailto:info@ascalia.io">contact us</a>
                  </p> */}
                </div>
              </main>
            }
          </div>
        </PageContent>
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(AccountContainer));
