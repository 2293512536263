import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import api from 'helpers/api';
import { modalSizes } from 'shared/constants';
import { IconRemove, IconEdit } from 'shared/Icons';
import { numberSeparatorFormat } from 'industry/helpers';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { Modal, Notification, Table, TableButtons, Button, ConfirmationModal } from 'shared';
import { getProductTypes, addNewRastep } from '../../actions';

const PodravkaRastepModal = ({
  selectedCompanyTable,
  handleClose,
  isOpen,
  t,
  isReadOnly,
  i18n,
  locationId,
  companyId,
}) => {
  const [currentView, setCurrentView] = useState('table');

  const [tableData, setTableData] = useState({
    isLoading: false,
    data: [],
    count: 0,
    next: null,
    previous: null,
    currentUrl: null,
  });

  const [selectedRow, setSelectedRow] = useState(null);
  const [isSelectedRowUpdated, setIsSelectedRowUpdated] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const [newRow, setNewRow] = useState(null);
  const [productTypes, setProductTypes] = useState(null);

  const [selectedSort, setSelectedSort] = useState('material.code');
  const [selectedAscDesc, setSelectedAscDesc] = useState('asc');

  const getTable = (endpoint) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    const tableFilter = `&order_by=${asc}${selectedSort}`;

    api.get(`${endpoint}?location=${locationId}&limit=15${tableFilter}`)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      })
      .catch(() => {
        return Notification('error', 'An error occurred');
      });
  };

  useEffect(() => {
    if (selectedCompanyTable && selectedCompanyTable.endpoint) {
      getTable(selectedCompanyTable.endpoint);
      getProductTypes(companyId, null).then((re) => {
        setProductTypes(get(re, 'data.results', []));
      });
    }
  }, [selectedCompanyTable, selectedAscDesc, selectedSort]);

  const onChange = (value, field) => {
    setSelectedRow({
      ...selectedRow,
      [field]: value,
    });
    setIsSelectedRowUpdated(true);
  };

  const onAddChange = (value, field) => {
    setNewRow((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const fetchPaginatedTable = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    api.get(`${url}`)
      .then((re) => {
        setTableData({
          isLoading: false,
          data: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
          currentUrl: url,
        });
      })
      .catch(() => {
        return Notification('error', 'An error occurred');
      });
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setCurrentView('table');
    setIsSelectedRowUpdated(false);
    handleClose();
  };

  const handleEditItem = (row) => {
    setSelectedRow(get(row, 'original', null));
    setCurrentView('form');
  };

  const handleSorting = (sortData) => {
    const column = sortData.id;
    setSelectedSort(column);
    setSelectedAscDesc(sortData.desc ? 'desc' : 'asc');
  };

  const handleShowConfirmationModal = (row, e) => {
    e.stopPropagation();
    setDeleteData(row);
    setShowConfirmationDialog(true);
  };

  const handleDeleteItem = () => {
    const row = deleteData;
    if (row && row.original && row.original.id && selectedCompanyTable && selectedCompanyTable.endpoint) {
      setTableData((prevState) => ({
        ...prevState,
        isLoading: true,
        data: [],
      }));
      setCurrentView('table');
      api.delete(`${selectedCompanyTable.endpoint}${row.original.id}/`)
        .then(() => {
          getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
          return Notification('success', 'Delete successful', 'Item was successfully deleted.');
        })
        .catch(() => {
          return Notification('error', 'An error occurred');
        })
        .finally(() => setShowConfirmationDialog(false));
    }
  };

  const handleBack = () => {
    setSelectedRow(null);
    setCurrentView('table');
    setIsSelectedRowUpdated(false);
    setNewRow(null);
  };

  const handleAddButton = () => {
    setSelectedRow(null);
    setCurrentView('add');
    setIsSelectedRowUpdated(false);
  };

  const handleInputChange = (val) => {
    if (val?.length >= 3) {
      getProductTypes(companyId, val);
    }
  };

  const updateItem = () => {
    if (selectedCompanyTable && selectedCompanyTable.endpoint) {
      setTableData((prevState) => ({
        ...prevState,
        isLoading: true,
        data: [],
      }));
      setCurrentView('table');
      const selectedRowData = JSON.parse(JSON.stringify(selectedRow));
      selectedRowData.component = selectedRow.component.id;
      selectedRowData.material = selectedRow.material.id;
      api.patch(`${selectedCompanyTable.endpoint}${selectedRow.id}/`, selectedRowData)
        .then(() => {
          handleBack();
          getTable(tableData.currentUrl || selectedCompanyTable.endpoint);
          return Notification('success', 'Save successful', 'Item was successfully updated.');
        })
        .catch(() => {
          handleBack();
          return Notification('error', 'An error occurred');
        });
    }
  };

  const addItem = () => {
    const data = {
      ...newRow,
      location: locationId,
    };
    if (selectedCompanyTable && selectedCompanyTable.endpoint) {
      addNewRastep(selectedCompanyTable.endpoint, data).then(() => { handleBack(); getTable(selectedCompanyTable.endpoint); }).catch((e) => console.log(e));
    }
  };

  const renderTable = () => {
    return (
      <div style={{ paddingBottom: '30px' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <Button
            type="add"
            style={{ margin: '10px' }}
            onClick={handleAddButton}
          >
            {t('settings.custom_data.new_data')}
          </Button>
        </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('settings.custom_data.material_code')}</span>,
              accessor: 'material.code',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.material_name')}</span>,
              accessor: 'material.name',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.component_code')}</span>,
              accessor: 'component.code',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.component_name')}</span>,
              accessor: 'component.name',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.quantity')}</span>,
              accessor: 'quantity',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.unit')}</span>,
              accessor: 'unit',
              Cell: (row) => (get(row, 'value', '-')),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('settings.custom_data.scrap_percentage')}</span>,
              accessor: 'scrap_percentage',
              Cell: (row) => (row.value ? `${numberSeparatorFormat(i18n.language, row.value, 1, 1)}%` : '-'),
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              Cell: (row) => <Button disabled={isReadOnly} className="remove-btn" onClick={(e) => handleEditItem(row, e)}><IconEdit width="12px" height="12px" /></Button>,
              width: 80,
            },
            {
              Header: () => '',
              Cell: (row) => <Button type="delete" disabled={isReadOnly} className="remove-btn" onClick={(e) => handleShowConfirmationModal(row, e)}><IconRemove width="12px" height="12px" /></Button>,
              width: 80,
            },
          ]}
          data={tableData.data || []}
          defaultPageSize={15}
          loading={tableData.isLoading}
          minRows={0}
          noDataText=""
          showPagination={false}
          selectedRow={null}
          defaultSorted={[{ id: 'material.code', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <div>
          <span style={{ float: 'right', paddingBottom: '10px', paddingRight: '10px' }}>
            <TableButtons
              previous={tableData.previous}
              next={tableData.next}
              fetchFunction={fetchPaginatedTable}
              count={tableData.count}
            />
          </span>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="default-form">
        <Button onClick={handleBack}>{t('settings.custom_data.back')}</Button>
        <div className="custom_data_modal_container">

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.material_code')}:</div>
            <div className="right_input">
              <input type="text" disabled value={get(selectedRow, 'material.code', '')} onChange={(e) => onChange(e.target.value, 'material.code')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.material_name')}:</div>
            <div className="right_input">
              <input type="text" disabled value={get(selectedRow, 'material.name', '')} onChange={(e) => onChange(e.target.value, 'material.name')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.component_code')}:</div>
            <div className="right_input">
              <input type="text" disabled value={get(selectedRow, 'component.code', '')} onChange={(e) => onChange(e.target.value, 'component.code')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.component_name')}:</div>
            <div className="right_input">
              <input type="text" disabled value={get(selectedRow, 'component.name', '')} onChange={(e) => onChange(e.target.value, 'component.name')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.quantity')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'quantity', '')} onChange={(e) => onChange(e.target.value, 'quantity')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.unit')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'unit', '')} onChange={(e) => onChange(e.target.value, 'unit')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.scrap_percentage')}:</div>
            <div className="right_input">
              <input type="text" value={get(selectedRow, 'scrap_percentage', '')} onChange={(e) => onChange(e.target.value, 'scrap_percentage')} />
            </div>
          </div>

        </div>
      </div>
    );
  };

  const renderAddForm = () => {
    return (
      <div className="default-form">
        <Button onClick={handleBack}>{t('settings.custom_data.back')}</Button>
        <div className="custom_data_modal_container">

          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.component_name')}:</div>
            <div className="right_select">
              <div style={{ width: '100%' }}>
                <Select
                  options={productTypes}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isClearable
                  menuPosition="fixed"
                  placeholder={t('settings.custom_data.search_placeholder')}
                  onChange={(val) => { onAddChange(val?.id, 'component'); }}
                  onInputChange={handleInputChange}
                  value={productTypes.find((d) => d.id === get(newRow, 'component', '') || '')}
                  styles={selectModalStyles}
                />
              </div>
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.material_name')}:</div>
            <div className="right_select">
              <div style={{ width: '100%' }}>
                <Select
                  options={productTypes}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isClearable
                  menuPosition="fixed"
                  placeholder={t('settings.custom_data.search_placeholder')}
                  onChange={(val) => { onAddChange(val?.id, 'material'); }}
                  onInputChange={handleInputChange}
                  value={productTypes.find((d) => d.id === get(newRow, 'material', '') || '')}
                  styles={selectModalStyles}
                />
              </div>
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.quantity')}:</div>
            <div className="right_input">
              <input type="text" value={get(newRow, 'quantity', '')} onChange={(e) => onAddChange(e.target.value, 'quantity')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.unit')}:</div>
            <div className="right_input">
              <input type="text" value={get(newRow, 'unit', '')} onChange={(e) => onAddChange(e.target.value, 'unit')} />
            </div>
          </div>
          <div className="modal_row">
            <div className="left_text">{t('settings.custom_data.scrap_percentage')}:</div>
            <div className="right_input">
              <input type="text" value={get(newRow, 'scrap_percentage', '')} onChange={(e) => onAddChange(e.target.value, 'scrap_percentage')} />
            </div>
          </div>

        </div>
      </div>
    );
  };

  return (
    <Modal
      size={modalSizes.full}
      handleClose={handleCloseModal}
      handleSave={currentView === 'form' ? updateItem : currentView === 'add' ? addItem : null}
      disableSave={(currentView === 'form' && !isSelectedRowUpdated) || (currentView === 'add' && !(newRow?.component && newRow?.material && newRow?.scrap_percentage && newRow?.unit && newRow?.quantity))}
      isOpen={isOpen}
      title={get(selectedCompanyTable, 'name', '')}
    >
      {currentView === 'table' && renderTable()}
      {currentView === 'form' && renderForm()}
      {currentView === 'add' && renderAddForm()}
      <ConfirmationModal
        itemName={`${get(deleteData, 'original.material.name')} | ${get(deleteData, 'original.component.name')}`}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleDeleteItem}
        type="warning"
      />
    </Modal>
  );
};

PodravkaRastepModal.propTypes = {
  selectedCompanyTable: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  i18n: PropTypes.object,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
};

export default withTranslation()(PodravkaRastepModal);
